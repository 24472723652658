import { Component, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, fromEvent } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { CustomerService } from 'src/app/shared/proxy-services/customer.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { WrapperService } from 'src/app/shared/proxy-services/wrapper.service';
import { ApiTokenResponsePayload, AryaAPIKycResp } from 'src/app/shared/interface/utilityApi';
import { environment } from 'src/environments/environment';
import { KycDetailsResponse } from 'src/app/shared/interface/kycDetails';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupButton, popupButtonAction, popupDescriptionMsg, popupImgPath, popupType } from 'src/app/shared/enums/popupEnums';
import { PopupService } from 'src/app/shared/services/popup.service';
import { MatDialog } from '@angular/material/dialog';
import { FetchCustomerDetailsV1RequestPayload, FetchCustomerDetailsV1ResponseStructure } from 'src/app/shared/interface/customer';
import { UtilityApiService } from 'src/app/shared/proxy-services/utility-api.service';
import { IsKycPilotUserResponsePayload } from 'src/app/shared/interface/wrapper';
declare let $: any;
declare const AJL: any;

@Component({
  selector: 'app-update-kyc',
  templateUrl: './update-kyc.component.html',
  styleUrls: ['./update-kyc.component.scss'],
})
export class UpdateKycComponent {
  @ViewChild('externalUrlFrame') externalUrlFrame!: ElementRef<HTMLIFrameElement>;
  // @ViewChild('externalUrlModal') externalUrlModal!: TemplateRef<HTMLIFrameElement>;
  private subscription: Subscription[] = [];
  externalUrl: SafeResourceUrl;
  customerList = [];
  customerDetails: FetchCustomerDetailsV1ResponseStructure;
  updateKYCForm: FormGroup;
  phoneNumberRequired: boolean;
  nameRequired: boolean;
  isSubmitted: boolean;
  showCustomer: boolean;
  isPilotUser: boolean = false;
  titleList = [
    { "value": 0, "label": "Mrs.", },
    { "value": 1, "label": "Mr.", },
    { "value": 2, "label": "Ms.", },
    { "value": 21, "label": "Mx.", }];

  maritalStatusList = [
    { "label": "SINGLE", "value": "1", },
    { "label": "MARRIED", "value": "2", }
  ];
  constitutionOfBusinessList = [
    { "value": "1", "label": "Non Resident Entity" },
    { "value": "2", "label": "Foreign company registered in India" },
    { "value": "3", "label": "Foreign LLP" },
    { "value": "4", "label": "Government Department" },
    { "value": "5", "label": "Hindu Undivided Family" },
    { "value": "6", "label": "LLP Partnership" },
    { "value": "7", "label": "Local Authorities" },
    { "value": "8", "label": "Partnership" },
    { "value": "9", "label": "Private Limited Company" },
    { "value": "10", "label": "Proprietorship" },
    { "value": "11", "label": "Others" }
  ];
  customerTypeList = [
    { "value": "21", "label": "General" },
    { "value": "22", "label": "EOU/STP/EHTP" },
    { "value": "23", "label": "Government" },
    { "value": "24", "label": "Overseas" },
    { "value": "25", "label": "Related parties" },
    { "value": "26", "label": "SEZ" },
    { "value": "27", "label": "Others" }
  ];
  gstRegistrationStatusList = [
    { "value": "41", "label": "ARN Generated" },
    { "value": "42", "label": "Provision ID Obtained" },
    { "value": "43", "label": "To be commenced" },
    { "value": "44", "label": "Enrolled" },
    { "value": "45", "label": "Not applicable" }
  ];

  updateKYCImg = this.sanitizer.bypassSecurityTrustHtml(
    `<svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2383_4)">
    <path d="M32.15 6.97998H13.15C10.3886 6.97998 8.15002 9.21856 8.15002 11.98V33.98C8.15002 36.7414 10.3886 38.98 13.15 38.98H32.15C34.9114 38.98 37.15 36.7414 37.15 33.98V11.98C37.15 9.21856 34.9114 6.97998 32.15 6.97998Z" fill="#EC6608" fill-opacity="0.19"/>
    </g>
    <g clip-path="url(#clip1_2383_4)">
    <path d="M13.5426 35.0633V35.1066L11.6492 19.3672L13.5426 35.0633Z" fill="#F68F6F"/>
    <path d="M18.2832 35.0777V35.1066L18.0519 36.9855H13.7738L13.557 35.1933L13.5426 35.0777L14.9012 21.564L14.6988 20.2488C15.118 20.3066 15.5516 20.321 15.9852 20.321C16.3754 20.321 16.7656 20.3066 17.1414 20.2632L16.9391 21.564L17.8352 30.2359L17.8785 30.7562L18.2832 35.0777Z" fill="#014E89"/>
    <path d="M20.1766 19.425L19.902 21.6653L18.2832 35.1067V35.0778L19.902 21.6797L20.1766 19.425Z" fill="#F68F6F"/>
    <path d="M3.32423 36.9711H2.86172V23.8333C2.86172 20.5524 4.92852 17.5895 8.00704 16.4622L8.16602 16.8958C5.2754 17.9508 3.32423 20.7403 3.32423 23.8333V36.9711Z" fill="#014E89"/>
    <path d="M28.3281 26.0735H27.8656V36.9711H28.3281V26.0735Z" fill="#014E89"/>
    <path d="M7.1254 28.1257H6.6629V36.971H7.1254V28.1257Z" fill="#014E89"/>
    <path d="M24.5125 28.1257H24.05V36.971H24.5125V28.1257Z" fill="#014E89"/>
    <path d="M17.416 19.9742C16.9391 20.032 16.4621 20.0753 15.9852 20.0753C15.566 20.0753 15.1469 20.0464 14.7277 20.0031L14.4242 19.9597L14.6844 21.7808H17.1414L17.3582 20.321L17.3727 20.191L17.416 19.9742ZM16.7512 21.3183H15.1035L14.9879 20.4945C15.3203 20.5234 15.6672 20.5378 15.9996 20.5378C16.3031 20.5378 16.5633 20.5234 16.8813 20.4945L16.7512 21.3183Z" fill="#014E89"/>
    <path d="M13.4992 37L13.2969 35.3668L13.3258 35.15L14.6844 21.3184H17.1414L17.1848 21.5352L18.0664 30.207L18.5867 35.7715L18.4277 37L18.1965 36.9711H17.9652V36.9422L18.1098 35.757L17.6039 30.2504L16.7367 21.7809H15.1035L13.7738 35.3523L13.9617 36.9422L13.4992 37Z" fill="#014E89"/>
    <path d="M11.7793 22.1711L11.3457 19.0059L11.7215 19.1793C12.2852 19.4395 12.8777 19.6418 13.4848 19.7863L13.8605 19.873L11.7793 22.1711ZM11.9238 19.7574L12.1117 21.116L13.0223 20.1332C12.6465 20.032 12.2707 19.902 11.9238 19.7574ZM20.2344 22.3301L17.9652 19.8875L18.3555 19.8008C18.9625 19.6562 19.5551 19.4539 20.1043 19.2082L20.1477 19.1938C20.191 19.1648 20.2488 19.1504 20.2922 19.1215L20.6824 18.9336L20.2344 22.3301ZM18.8469 20.1621L19.902 21.3039L20.1187 19.7285C19.7141 19.8875 19.2949 20.032 18.8469 20.1621Z" fill="#014E89"/>
    <path d="M13.9549 36.949L11.8588 19.3394L11.3995 19.394L13.4956 37.0037L13.9549 36.949Z" fill="#014E89"/>
    <path d="M20.6503 19.3509L20.1914 19.2931L17.9677 36.9454L18.4265 37.0032L20.6503 19.3509Z" fill="#014E89"/>
    <path d="M15.9852 19.7719C13.3402 19.7719 10.8688 18.7457 8.98985 16.8813C5.13087 13.0223 5.13087 6.76406 8.98985 2.90508C10.8543 1.02617 13.3402 0 15.9852 0C18.6301 0 21.1016 1.02617 22.966 2.89063C26.825 6.74961 26.825 13.0078 22.966 16.8668C21.1016 18.7313 18.6301 19.7719 15.9852 19.7719ZM15.9852 0.4625C13.4703 0.4625 11.1 1.44531 9.32227 3.22305C5.65118 6.89414 5.65118 12.8633 9.32227 16.5344C11.1 18.3121 13.4703 19.2949 15.9852 19.2949C18.5 19.2949 20.8703 18.3121 22.6481 16.5344C26.3191 12.8633 26.3191 6.89414 22.6481 3.22305C20.8703 1.44531 18.5 0.4625 15.9852 0.4625ZM15.9852 16.6211C14.1785 16.6211 12.4875 15.9273 11.2156 14.641C9.94376 13.3691 9.25001 11.6781 9.25001 9.88594C9.25001 8.09375 9.95821 6.38828 11.2301 5.11641C12.502 3.84453 14.193 3.13633 15.9996 3.13633C17.7918 3.13633 19.4973 3.84453 20.7691 5.11641C23.3996 7.74688 23.3996 12.0105 20.7691 14.641C19.4684 15.9129 17.7774 16.6211 15.9852 16.6211ZM15.9852 3.61328C14.3086 3.61328 12.7332 4.26367 11.5481 5.44883C10.3629 6.63399 9.71251 8.20938 9.71251 9.88594C9.71251 11.5625 10.3629 13.1379 11.5481 14.323C12.7332 15.5082 14.3086 16.1586 15.9852 16.1586C17.6617 16.1586 19.2371 15.5082 20.4223 14.323C22.8649 11.8805 22.8649 7.89141 20.4223 5.44883C19.2371 4.26367 17.6617 3.61328 15.9852 3.61328Z" fill="#014E89"/>
    <path d="M29.4266 26.6371L22.6336 19.8442C21.723 18.9336 21.723 17.4449 22.6336 16.5344C23.0816 16.0864 23.6598 15.8551 24.2812 15.8551C24.9027 15.8551 25.4953 16.1008 25.9289 16.5344L32.7219 23.3274L29.4266 26.6371ZM24.2957 16.3176C23.7898 16.3176 23.3273 16.5055 22.966 16.8668C22.2434 17.5895 22.2434 18.7891 22.966 19.5117L29.4266 25.9723L32.0715 23.3274L25.6109 16.8668C25.2641 16.5199 24.7871 16.3176 24.2957 16.3176Z" fill="#014E89"/>
    <path d="M31.3344 28.039C31.0164 28.039 30.7129 27.9089 30.4961 27.6921L29.1086 26.3046L32.4183 22.9949L33.8058 24.3824C34.0371 24.6136 34.1527 24.9027 34.1527 25.2207C34.1527 25.5386 34.0226 25.8421 33.8058 26.0589L32.1871 27.6777C31.9414 27.9234 31.6523 28.039 31.3344 28.039ZM29.759 26.3046L30.8141 27.3597C30.9441 27.4898 31.132 27.5765 31.3199 27.5765C31.5078 27.5765 31.6957 27.5042 31.8258 27.3597C32.4579 26.7276 32.8124 26.3731 33.4445 25.741C33.5746 25.6109 33.6613 25.423 33.6613 25.2351C33.6613 25.0472 33.5891 24.8593 33.4445 24.7292L32.3894 23.6742L29.759 26.3046Z" fill="#014E89"/>
    <path d="M32.5 23.5L29.5 26.5L31.5 28L34 25.5L33.5 24.5L32.5 23.5Z" fill="#014E89"/>
    <path d="M16.2887 11.3168H15.8262V11.1578C15.8262 10.2473 16.2598 9.36562 17.0113 8.75859C17.3871 8.45507 17.575 8.00702 17.5605 7.53007C17.5316 6.76406 16.8957 6.14257 16.1441 6.11367C15.725 6.09921 15.3348 6.24374 15.0457 6.53281C14.7422 6.82187 14.5832 7.19765 14.5832 7.61679H14.1207C14.1207 7.08203 14.3375 6.57617 14.7277 6.20038C15.118 5.8246 15.6383 5.63671 16.173 5.66562C17.1703 5.70898 17.9941 6.53281 18.0375 7.53007C18.0664 8.15156 17.8062 8.74413 17.3148 9.13437C16.6789 9.65468 16.3031 10.4062 16.3031 11.1723V11.3168H16.2887Z" fill="#014E89" stroke="#014E89"/>
    <path d="M16.0574 14.7739C15.6383 14.7739 15.2914 14.427 15.2914 14.0078C15.2914 13.5887 15.6383 13.2418 16.0574 13.2418C16.4766 13.2418 16.8234 13.5887 16.8234 14.0078C16.8234 14.427 16.4766 14.7739 16.0574 14.7739ZM16.0574 13.7188C15.8984 13.7188 15.7539 13.8489 15.7539 14.0223C15.7539 14.1813 15.884 14.3258 16.0574 14.3258C16.2164 14.3258 16.3609 14.1957 16.3609 14.0223C16.3609 13.8489 16.2164 13.7188 16.0574 13.7188Z" fill="#014E89" stroke="#014E89"/>
    </g>
    <defs>
    <clipPath id="clip0_2383_4">
    <rect width="34.15" height="38.98" fill="white" transform="translate(3)"/>
    </clipPath>
    <clipPath id="clip1_2383_4">
    <rect width="37" height="37" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    `)
  constructor(private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private customerService: CustomerService,
    private utilityService: UtilityService,
    private wrapperService: WrapperService,
    private popupService: PopupService,
    private dialog: MatDialog,
    private utilityApiService: UtilityApiService) { }

  ngOnInit() {
    this.createUpdateKYCForm();
    this.fetchIsPilotUser();
    this.subscription.push(fromEvent(window, 'message')
      .subscribe((event) => {
        this.receiveMessage(event);
      }));
    this.updateKYCForm.valueChanges.subscribe(() => {
      if (this.isSubmitted && this.showCustomer) {
        this.isSubmitted = false;
        this.showCustomer = false;
      }
    })
  }

  fetchIsPilotUser() {
    this.wrapperService.IsKycPilotUser(Constants.NysaHealth).subscribe({
      next: (resp: IsKycPilotUserResponsePayload) => {
        if (resp.StatusCode == Constants.statusCode_success) {
          this.isPilotUser = this.utilityService.isEmptyOrNull(resp.Data.is_pilot_user) ? false : resp.Data.is_pilot_user;
        }
        else {
          this.isPilotUser = false;
        }
      }
    })
  }

  createUpdateKYCForm() {
    this.updateKYCForm = this.fb.group({
      CustomerName: ['', [Validators.pattern("^[a-zA-Z\\s]+$")]],
      Email: ['', [Validators.email]],
      MobileNumber: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      PFCustomerId: ['', [Validators.pattern("^[0-9]{12}$")]],
    })
  }

  get updateForm() {
    return this.updateKYCForm.controls;
  }

  searchCondition() {
    return ((this.updateForm['CustomerName'].value && !this.updateForm['CustomerName'].errors) &&
      (this.updateForm['MobileNumber'].value && !this.updateForm['MobileNumber'].errors ||
        this.updateForm['Email'].value && !this.updateForm['Email'].errors)) || (this.updateForm['PFCustomerId'].value && !this.updateForm['PFCustomerId'].errors)
  }

  onSearch() {
    this.isSubmitted = true;
    if (this.searchCondition()) {
      if (!this.updateForm['PFCustomerId'].value) {
        const request = {
          CustomerName: this.updateKYCForm.value.CustomerName ? this.updateKYCForm.value.CustomerName : '',
          CustomerEmail: this.updateKYCForm.value.Email ? this.updateKYCForm.value.Email : '',
          CustomerMobile: this.updateKYCForm.value.MobileNumber ? this.updateKYCForm.value.MobileNumber : '',
          PfCustomerId: this.updateKYCForm.value.PFCustomerId ? this.updateKYCForm.value.PFCustomerId : ''
        }
        this.subscription.push(this.customerService.searchCustomerData(request).subscribe({
          next: response => {
            if (response.StatusCode == Constants.statusCode_success) {
              if (response.Data) {
                this.customerList = response.Data;
                this.showCustomer = true;
              }
            }
          },
        }))
      } else if (!this.updateForm['PFCustomerId'].errors) {
        this.fetchCustomerFromPfId(this.updateForm['PFCustomerId'].value);
      }
    }
  }

  onUpdate(row) {
    this.fetchCustomerFromPfId(row.PfCustomerId);
  }

  fetchCustomerFromPfId(pfId: number | string) {
    let request: FetchCustomerDetailsV1RequestPayload = {
      PfCustomerID: (pfId).toString()
    }
    this.subscription.push(this.customerService.fetchCustomerDetailsV1(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.customerDetails = response.Data;
          if (!this.utilityService.isEmptyOrNull(this.customerDetails.CKYCID) || !this.utilityService.isEmptyOrNull(this.customerDetails.ReferenceNo) || !this.utilityService.isEmptyOrNull(this.customerDetails.EKYCID)) {
            this.callKYCSuccessPopup('KYC Already Updated');
          } else {
            this.callKYC();
          }
        }
      },
    }))
  }

  aryaApiKycCalling() {
    const getAryaApiResp = (res, status) => {
      if (status.toLowerCase() == Constants.statusMsg_Success) {
        let kycData: KycDetailsResponse = this.kycResponseMapping(res);
        this.getKYCDetails(kycData);
      }
      else {
        this.utilityService.popUpDialog('Kindly complete E-KYC/C-KYC to proceed further');
      }
    }


    this.subscription.push(this.utilityApiService.getApiToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          let kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? (res.Data).split(' ')[1] : '';
          let environmentType: string = '';
          environmentType = environment.environment == 'production' ? 'production' : 'uat';
          AJL.aryafns.initMod(kycToken, getAryaApiResp, environmentType);
        }
      }
    }));
  }

  kycResponseMapping(oldKycResp: AryaAPIKycResp): KycDetailsResponse {
    let statusCode: number = 0;
    let alternateAddressPresent: boolean = false;
    alternateAddressPresent = !this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.alternate_address);
    if (((oldKycResp.api_response.statusMessage).toLowerCase() == Constants.statusMsg_Success) || (oldKycResp.api_response.statusMessage == Constants.statusMsg_Manual)) {
      statusCode = 1;
    }
    else {
      statusCode = 0;
    }
    let kycResp: KycDetailsResponse = {
      StatusCode: statusCode,
      StatusMessage: oldKycResp.api_response.statusMessage,
      data: {
        correlationId: oldKycResp.api_response.correlationId,
        customer_type: oldKycResp.api_response.kyc_details.customer_type,
        pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        status: oldKycResp.api_response.status,
        statusMessage: oldKycResp.api_response.statusMessage,
        message: oldKycResp.api_response.message,
        kyc_details: {
          il_kyc_ref_no: oldKycResp.api_response.kyc_details.il_kyc_ref_no,
          certificate_type: oldKycResp.api_response.kyc_details.certificate_type,
          certificate_number: oldKycResp.api_response.kyc_details.certificate_number,
          certificate_type_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_type_poa) ? oldKycResp.api_response.kyc_details.certificate_type_poa : '',
          certificate_number_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_number_poa) ? oldKycResp.api_response.kyc_details.certificate_number_poa : '',
          first_name: oldKycResp.api_response.kyc_details.first_name,
          middle_name: '', //Not present
          last_name: oldKycResp.api_response.kyc_details.last_name,
          full_name: oldKycResp.api_response.kyc_details.full_name,
          gender: oldKycResp.api_response.kyc_details.gender,
          dob: oldKycResp.api_response.kyc_details.dob,
          mobile_number: oldKycResp.api_response.kyc_details.mobile_number,
          email: oldKycResp.api_response.kyc_details.email,
          ckyc_number: oldKycResp.api_response.kyc_details.ckyc_number,
          il_customer_id: '',
          permanent_address: {
            address_line_1: oldKycResp.api_response.kyc_details.permanent_address.address_line_1,
            address_line_2: oldKycResp.api_response.kyc_details.permanent_address.address_line_2,
            pin_code: oldKycResp.api_response.kyc_details.permanent_address.pin_code,
            city: oldKycResp.api_response.kyc_details.permanent_address.city,
            state: oldKycResp.api_response.kyc_details.permanent_address.state,
          },
          alternate_address: {
            address_line_1: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.address_line_1 : '',
            address_line_2: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.address_line_2 : '',
            pin_code: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.pin_code : '',
            city: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.city : '',
            state: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.state : '',
          },
          udf_1: '',
          udf_2: '',
          udf_3: '',
          udf_4: '',
          udf_5: '',
          pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        }
      }
    }
    return kycResp;
  }

  // callKYC() {
  //   this.aryaApiKycCalling()
  // }

  callKYC() {
    this.subscription.push(this.wrapperService.createKYCToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          const kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? res.Data.split("=")[1] : '';
          const source = this.utilityService.identifyEnvironmentType(kycToken);
          const resourceParams = JSON.stringify(source);
          this.externalUrlFrame.nativeElement.src = environment.kycUrl + '?rsrc=' + resourceParams;
          $('#externalUrlModal').modal('show');
        }
      },
    }));
  }

  receiveMessage(event) {
    $('#externalUrlModal').modal('hide');
    if (!this.utilityService.isEmptyOrNull(event)) {
      this.dialog.closeAll();
      const res = typeof (event.data) == 'string' ? JSON.parse(event.data) : event.data;
      this.getKYCDetails(res);
    }
  }

  getKYCDetails(res: KycDetailsResponse) {
    if (res.StatusCode == Constants.statusCode_success) {
      const tempResponse: KycDetailsResponse = typeof (res) == 'string' ? JSON.parse(res) : res;
      const ilKycReferenceNumber = tempResponse.data.hasOwnProperty('kyc_details') ? tempResponse.data.kyc_details.il_kyc_ref_no : '';
      const cKycId = tempResponse.data.hasOwnProperty('kyc_details') ? tempResponse.data.kyc_details.ckyc_number : '';
      const isKYCDoneSuccessfully = tempResponse.data.hasOwnProperty('kyc_details') && ((!this.utilityService.isEmptyOrNull(cKycId) || !this.utilityService.isEmptyOrNull(ilKycReferenceNumber))) ? true : false;
      // add ovd flow condition res.data.statusMessage == Constants.statusMsg_Manual
      if (tempResponse.data.statusMessage == Constants.statusMsg_Manual) {
        if (!this.isPilotUser) {
          this.callSaveEditCustomer(tempResponse.data.kyc_details);
        } else {
          const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.pilotUserMsg);
          this.popupService.openGenericPopup(errorPopupData);
        }
      } else if (isKYCDoneSuccessfully) {
        this.callSaveEditCustomer(tempResponse.data.kyc_details);
      }
    }
  }

  getTitleText(value: string): string {
    return this.utilityService.truncateAfterWhiteSpace(value);
  }

  getTitleValue(title: string) {
    for (let i of this.titleList) {
      if (i.label.includes(title)) {
        return i.value;
      }
    }
    return 1;
  }

  getMaritalValue(status: string): number {
    for (let i of this.maritalStatusList) {
      if (i.label.includes(status.toUpperCase())) {
        return Number(i.value);
      }
    }
    return 1;
  }

  getConstitutionOfBusinessId(constitutionOfBusiness) {
    for (let i of this.constitutionOfBusinessList) {
      if (i.label == constitutionOfBusiness) {
        return i.value;
      }
    }
    return 1;
  }

  getCustomerTypeId(customerType) {
    for (let i of this.customerTypeList) {
      if (i.label == customerType) {
        return i.value;
      }
    }
    return 1;
  }

  getGstRegistrationStatusId(gstRegistrationStatus) {
    for (let i of this.gstRegistrationStatusList) {
      if (i.label == gstRegistrationStatus) {
        return i.value;
      }
    }
    return 1;
  }

  constructSaveEditCustomerRequest(res) {
    const maritalValue = this.getMaritalValue(this.customerDetails.MARITAL_STATUS);
    const constitutionOfBusinessId = this.getConstitutionOfBusinessId(this.customerDetails.CONSTITUTION_OF_BUSINESS);
    const customerTypeId = this.getCustomerTypeId(this.customerDetails.CUSTOMER_TYPE);
    const gstRegistrationStatusId = this.getGstRegistrationStatusId(this.customerDetails.GST_REGISTRATION_STATUS);
    const request = {
      "CustomerID": 0, // no point of sending this,
      "PfCustomerID": this.customerDetails.PfCustomerID,
      "HasAddressChanged": true,// This is going by default true
      "SetAsDefault": true,// This is going by default true
      "TitleText": "Mr.",
      "TitleValue": 1,
      "Name": this.customerDetails.UserName,
      "DateOfBirth": this.customerDetails.DOB,
      "MaritalStatusValue": maritalValue,
      "MaritalStatusText": this.customerDetails.MARITAL_STATUS,
      // Unused params starts
      "OccupationValue": 0,
      "OccupationText": "",
      "AnnualIncomeValue": 0,
      "AnnualIncomeText": "",
      "IdentityProofText": "",
      "IdentityNumber": "",
      "IdentityProofValue": 0,
      // unused params end
      "PresentAddrLine1": this.customerDetails.Address1,
      "PresentAddrLine2": this.customerDetails.Address2,
      "PresentAddrLandmark": '',
      "PresentAddrCityValue": Number(this.customerDetails.CityID),
      "PresentAddrCityText": this.customerDetails.City,
      "PresentAddrStateValue": Number(this.customerDetails.StateID),
      "PresentAddrStateText": this.customerDetails.State,
      "PresentAddrPincode": this.customerDetails.PinCode,
      "EmailAddress": this.customerDetails.EmailID,
      "MobileNumber": this.customerDetails.Mobile,
      "LandlineNumber": null, // not required,
      "PANNumber": this.customerDetails.PANNumber,
      "isPermanentAsPresent": true,
      "PermanentAddrLine1": this.customerDetails.Address1,
      "PermanentAddrLine2": this.customerDetails.Address2,
      "PermanentAddrLandmark": '',
      "PermanentAddrPincode": this.customerDetails.PinCode,
      "PermanentAddrStateValue": Number(this.customerDetails.StateID),
      "PermanentAddrStateText": this.customerDetails.State,
      "PermanentAddrCityValue": Number(this.customerDetails.CityID),
      "PermanentAddrCityText": this.customerDetails.City,
      "IsPanNumberExist": false, // by default
      "isUINApplicable": this.customerDetails.IS_UIN_APPLICABLE,
      "isGSTINApplicable": this.customerDetails.GSTApplicable,
      "CKYCID": res.ckyc_number,
      "SkipDedupeLogic": "true", // by default
      "pepFlag": this.customerDetails.PEPFLAG,
      "ilkycReferenceNumber": res.il_kyc_ref_no,
      "EKYCid": this.customerDetails.EKYCID,
      "GSTDetails": this.customerDetails.CONSTITUTION_OF_BUSINESS && this.customerDetails.CUSTOMER_TYPE && this.customerDetails.GST_REGISTRATION_STATUS ? {
        "CONSTITUTION_OF_BUSINESS": Number(constitutionOfBusinessId),
        "CONSTITUTION_OF_BUSINESS_TEXT": this.customerDetails.CONSTITUTION_OF_BUSINESS,
        "CUSTOMER_TYPE": Number(customerTypeId),
        "CUSTOMER_TYPE_TEXT": this.customerDetails.CUSTOMER_TYPE,
        "PAN_NO": this.customerDetails.PAN_NO,
        "GST_REGISTRATION_STATUS": Number(gstRegistrationStatusId),
        "GST_REGISTRATION_STATUS_TEXT": this.customerDetails.GST_REGISTRATION_STATUS,
        "GSTIN_NO": this.customerDetails.GSTIN_NO,
        "GST_STATE_NAME": this.customerDetails.GST_STATE_NAME
      } : undefined
    }
    //compare with request in kyc
    return request;
  }

  callSaveEditCustomer(response) {
    const request = this.constructSaveEditCustomerRequest(response);
    this.subscription.push(this.customerService.saveEditCustomer(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.callKYCSuccessPopup('KYC Updated Successfully');
        }
      }
    }))
  }

  callKYCSuccessPopup(value: string) {
    let popupData: PopupModal = {
      popupType: popupType.generic,
      imgName: popupImgPath.successGif,
      header: value,
      description: ' ',
      buttonLabel: popupButton.ok,
      buttonAction: popupButtonAction.close
    };
    this.showCustomer = false;
    this.popupService.openGenericPopup(popupData);
  }

  ngOnDestroy() {
    this.subscription.forEach(subs => {
      subs.unsubscribe();
    })
  }

}
