import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { concatMap, forkJoin, Observable, of, Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { healthMembers, healthProducts, healthProposalProductCode, patterns } from 'src/app/shared/enums/healthEnums';
import { HealthDetails, SaveEditQuoteV1Request } from 'src/app/shared/interface/transactionHealthAdvantege';
import { StateDetails } from 'src/app/shared/interface/moterMaster';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { HAPModal } from '../hap/hap-modal';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { HealthAdvantageResponsePayload, IncomeRange, PolicyPlan, StepperCheckDataQuote } from 'src/app/shared/interface/health-advantedge';
import { RelationshipRequestPayload, RelationshipResponsePayload } from 'src/app/shared/interface/healthMaster';

import { MatStepper } from '@angular/material/stepper';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { MatSelect } from '@angular/material/select';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { HealthDataStorage, PremiumDetailsData } from '../../health.modal';
import { HealthDataStorageService } from '../../health.service';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { CritiShieldService } from 'src/app/shared/proxy-services/criti-shield.service';
import { popupType, popupImgPath, popupHeader, popupDescriptionMsg, popupButton, popupButtonAction } from 'src/app/shared/enums/popupEnums';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { ConvertSavedQuotesResponsePayload, SavedQuote } from 'src/app/shared/interface/savedQuotes';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-criti-shield',
  templateUrl: './criti-shield.component.html',
  styleUrls: ['./criti-shield.component.scss']
})

export class CritiShieldComponent implements OnInit {
  @ViewChild('textCreate', { static: false }) textCreateComponent: ElementRef;
  @Output() QuoteValueEmitter = new EventEmitter<{ Quote: boolean, Portability: boolean, StatusCode: boolean }>();
  critiShieldForm: FormGroup;
  formSubmitted: boolean = false;
  formReset: boolean = false;
  healthProducts = healthProducts; // health enums
  hapDataModal: HAPModal = new HAPModal(); //hap data modal 
  csFormData: HealthDataStorage;
  riskCategoryArray: any;
  hapMasterData: HealthAdvantageResponsePayload;
  annualIncome: IncomeRange[] = []
  adultCountData = this.hapDataModal.adultCount;
  childCountData = this.hapDataModal.childCount;
  adultData = [];
  childData = [];
  selectedAdult = [];
  selectedChild = [];
  totalAdultChildData = [];
  quoteDetails: ConvertSavedQuotesResponsePayload;
  subscription: Subscription[] = [];
  states: StateDetails[] = [];
  sumInsuredDetails: PolicyPlan[] = [];
  cityData = [];
  tenurePeriod: String[] = [];
  premiumDetails: PremiumDetailsData;
  stateDetails = this.hapDataModal.stateDetails;
  zoneMappedWithPincode: string = '';
  invalidPinCodeMessage: string;
  transparentSpinner: boolean = false;
  stateFetched: boolean = false;
  success: boolean = false;
  oneMemberRequired: boolean = false;
  multipleServiceError: boolean = false;
  refreshPopupActivated: boolean = false;
  loadFormContent: boolean = true;
  patchingDone: boolean = false;
  tenureDuration: number = 0;
  previousAdultValue: string = '';
  previousChildValue: string = '';
  errorPopupData: PopupModal;
  reload = false;
  insuredMinMaxDate: { maxDate: Date; minDate: Date; };
  selectedOption = 'No';
  statuscode: boolean;
  stepperCheckData: Array<StepperCheckDataQuote> = [];
  QuoteDetails;
  @ViewChild('quotePanel') quotePanel: MatExpansionPanel;
  @ViewChild('premiumQuotePanel') premiumQuotePanel: MatExpansionPanel;
  disableButton: boolean = false;
  minValue: number = 0;
  maxValue: number;
  stepValue: number;
  selectedValue: number;
  @ViewChild(MatStepper) stepper: MatStepper;
  formValid: boolean = false;
  isInvalid: boolean = false;
  dealId: any;
  riskCategoryList = [];
  riskCategoryListObj = {};
  showRiskCategory = false;
  salarySelected = false;
  occupationSelected = false;
  coversArray = [];
  tabIndex = 0;
  tabIndexChild = 0;
  tabGroup = [];
  tabGroupChild = [];
  tabGroup2 = [{
    "Adult":[],
    "Child":[]
  }
  ]

  tabGroup3 = [{
    'memberDetails':[]
  }];

  Occupation = [['Salaried', 'Self-Employed'], ['Salaried', 'Self-Employed', 'No Active Source of Income']];
  primaryMemberSIValue= 0;
  maxSIperCover: number = 10000000;
  annualIncomeCritisield = [];
  siLookUpRangeCritishield = [];
  masterData: any;
  selectedAdultIndex: any;
  selectedCoverIndex: any;
  showCovers = false;
  channelData: ChannelData;
  mySavedQuoteData;
  saveQuoteCounter = 0;
  quoteConvertData: SavedQuote;
  adultCount: number = 1;
  childCount: number = 0;

  constructor(private fb: FormBuilder,
    public utilityService: UtilityService,
    private healthAdvantedgeService: HealthAdvantedgeService,
    private healthMasterService: HealthMasterService,
    private rtoListService: RTOListService,
    private popupService: PopupService,
    private healthDataStorageService: HealthDataStorageService,
    private renderer: Renderer2,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    private el: ElementRef,
    private storageService: StorageService,
    private _critishieldService: CritiShieldService,
    private sessionStorageService: SessionStorageService,
    private _utilityService: UtilityService,
    private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    // this.showPremiumDetails(false);
    this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);
    window.scrollTo(0, 0);
    this.onLoadSteps();
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
  }

  onLoadSteps() {
    this.premiumDetails = this.healthDataStorageService.initializePremiumData()
    this.csFormData = this.healthDataStorageService.getHealthDataStorage();
    // if (this.csFormData.quoteDataSet) {
    //   this.patchingDone = false;
     
    // }
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    this.mySavedQuoteData = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    if(this.mySavedQuoteData != null){
        this.csFormData.quoteDataSet = true;
        this.csFormData['quoteFormData'] = this.mySavedQuoteData['Data']['CritiShield']['savedQuoteData'];
        this.csFormData.premiumFormData = this.mySavedQuoteData.Data.CritiShield.premiumFormData;
        this.healthDataStorageService.setHealthDataStorage(this.csFormData);
    }
    if (this.csFormData.quoteDataSet ) {
      this.patchingDone = false;
    }
    this.createCSForm();
    this.fetchHAPDetails();
    this.csMasterData();
    if(!this.critiShieldForm.getRawValue()['memberCoverDetails'].length){
      this.constructMemberDetails('Adult',0);
    }
    this.constructMemberDetailsChild('Child',0);
    // this.constructMemberDetails2('Adult',0);
  }

  // constructMemberDetails1(memberType,memberIndex){
  //     this.tabGroup2.map((obj,ind)=>{
  //               for(let key in obj){
  //                     if(key == memberType){
  //                           obj[key] = [];
  //                           for(let i = 0  ; i <= memberIndex; i++){
  //                                 obj[key].push({
  //                                   'tabLabel': `${memberType}` + (i + 1),
  //                                 })
  //                           }
  //                     }
  //               }
  //     })


  //     let obj = {};
  //     if(memberType == 'Adult'){
  //             if(this.tabGroup3[0]['memberDetails'].length > 0){
  //                   this.tabGroup3[0]['memberDetails'].push({
  //                     'memberName': memberType + (memberIndex + 1)
  //                   })
  //             }else{
  //               this.tabGroup3[0]['tabLabel'] = memberType;
  //               this.tabGroup3[0]['memberDetails'] = [];
  //               this.tabGroup3[0]['memberDetails'].push({
  //                 'memberName': memberType + (memberIndex + 1)
  //               })
  //             }
  //     }else if(memberType == 'Child'){
  //             if(this.tabGroup3[1]['memberDetails'].length > 0){
  //                   this.tabGroup3[1]['memberDetails'].push({
  //                     'memberName': memberType + (memberIndex + 1)
  //                   })
  //             }else{
  //               this.tabGroup3[1]['tabLabel'] = memberType;
  //               this.tabGroup3[1]['memberDetails'] = [];
  //               this.tabGroup3[1]['memberDetails'].push({
  //                 'memberName': memberType + (memberIndex + 1)
  //               })
  //             }
  //     }

  // }

  constructMemberDetails2(memberType,memberIndex){
    
    if(memberType == 'Adult'){
            if(this.tabGroup3[0]['memberDetails'].length > 0){
                  this.tabGroup3[0]['memberDetails'].push({
                    'memberName': memberType + (memberIndex + 1)
                  })
            }else{
              this.tabGroup3[0]['tabLabel'] = memberType;
              this.tabGroup3[0]['memberDetails'] = [];
              this.tabGroup3[0]['memberDetails'].push({
                'memberName': memberType + (memberIndex + 1)
              })
            }
    }else if(memberType == 'Child'){
            if(this.tabGroup3[1]['memberDetails'].length > 0){
                  this.tabGroup3[1]['memberDetails'].push({
                    'memberName': memberType + (memberIndex + 1)
                  })
            }else{
              this.tabGroup3[1]['tabLabel'] = memberType;
              this.tabGroup3[1]['memberDetails'] = [];
              this.tabGroup3[1]['memberDetails'].push({
                'memberName': memberType + (memberIndex + 1)
              })
            }
    }

}

  

  isSelected(event: any, fromvalue: string): boolean {
    event.preventDefault();
    event.stopPropagation();
    return fromvalue === event.value;
  }

  //form construction
  createCSForm() {
    // patch annual sum insured if quote data is already present
    this.critiShieldForm = this.fb.group({
      productName: [healthProducts.CS],
      productType: [this.csFormData.quoteDataSet ? this.csFormData.quoteFormData.productType : this.hapDataModal.productType[0]],
      pincode: [this.csFormData.quoteFormData.pincode, [Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6)]],
      cityState: ['', Validators.required],
      adultDetails: this.fb.array([]),
      childDetails: this.fb.array([]),
      policyTenure: [this.csFormData.quoteDataSet ? this.csFormData.quoteFormData.policyTenure : this.hapDataModal.tenurePeriod[0], Validators.required],
      riskCategory : this.fb.array([]),
      covers: this.fb.array([]),
      memberCoverDetails: this.fb.array([]),
      memberCoverDetailsChild: this.fb.array([])
    })
    // Creating adult and child controls and patching existing data if present
    // if(this.mySavedQuoteData != null){
      
    // }
    this.patchExistingMemberData();

    this.critiShieldForm.valueChanges.subscribe((data) => {
      // Below logic works when data is already patched .OR. User comes back and changes value. Only when premium is set
      // Now when user tries to change the value the stepper should reset to quote page
      // And premium should hide

      if (this.patchingDone) {
        // premium data set flag is used beacsuse user can come back and try to change the values.
        // In that case also we need to reset the stepper

        // And if user refreshes that means existing value is getting patched. In that case we are using the patchingDone flag
        // and resetting the premium footer
        this.showPremiumDetails(false);
        // Here we are resetting the stepper. Just to be sure if the quote is changed.
        // User needs to traverse through each steps
        // if(!this.mySavedQuoteData) {
        //   this.customStepperService.resetStepper(true);
        // }
        if (this.quoteDetails) {
          // Below block is written to identify value changes due to save quote
          if (this.saveQuoteCounter == 0) {
            this.customStepperService.saveQuoteDataChanges(true);
            this.sessionStorageService.remove(userEnums.ConverToProposal);
            this.saveQuoteCounter++;
            this.quoteDetails = null;
          }
        }
        this.customStepperService.resetStepper(true);
      }
    })
  }

  

  patchExistingMemberData() {
    if (this.csFormData.quoteDataSet) {
      this.adultCount = this.csFormData.quoteFormData.adultDetails.length;
      this.childCount = this.csFormData.quoteFormData.childDetails.length;
      if (this.csFormData.quoteFormData.adultDetails.length > 0) {
        this.csFormData.quoteFormData.adultDetails.forEach((data, index) => {
          this.adultDetails.push(this.createAdultDetails(index));
          this.riskCategory.push(this.createRiskCategory(index));
          // Patch values of adults
          // Below block is written because when there is self data that get's patched from kyc is not in date format
          // So to handle it we need to convert in into date type
          for (let adultKeys in data) {
            if (adultKeys.includes('dob')) {
              let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
              this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
            }
            else {
              if(adultKeys.includes('CoverDetails')){
                this.patchCoverForCSAdult(this.csFormData.quoteFormData['memberCoverDetails'],index,'Adult')
              }else{
                if(adultKeys != 'RiskCategory'){
                  if(adultKeys == `adultOccupation${index + 1}`){
                      this.occupationSelected = data[adultKeys].length > 0 ? true : false;
                  }

                  if(adultKeys == `adultIncome${index + 1}`){
                    if(index != 1){
                      this.salarySelected = data[adultKeys].length > 0 ? true : false;
                    }
                  }
                  this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
                }
              }
            }
          }

        })

        // We need to highlight the selected adult also
        let selectedAdultIndex = this.csFormData.quoteFormData.adultDetails.length - 1;
        this.adultCountData.forEach((adultCount, index) => {
          if (index == selectedAdultIndex) {
            adultCount.selected = true;
          }
          else {
            adultCount.selected = false;
          }
        })
      }
      if (this.csFormData.quoteFormData.childDetails.length > 0) {
        this.csFormData.quoteFormData.childDetails.forEach((data, index) => {
          this.childDetails.push(this.createChildDetails(index));
          // Patch values of adults
          for (let childKeys in data) {
            if(childKeys.includes('CoverDetails')){
              this.patchCoverForCSAdult(this.csFormData.quoteFormData['memberCoverDetailsChild'],index,'Child')

            }else{
              this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);
            }

          }
        })

        // We need to highlight the selected child also
        // If user has selected only one child then other child needs to hide that would be in case of 0 adults
        let selectedChildIndex = this.csFormData.quoteFormData.childDetails.length - 1;
        this.childCountData.forEach((childCount, index) => {
          if (this.csFormData.quoteFormData.adultDetails.length == 0) {
            // Disabling adult selected
            this.adultCountData[0].selected = false;
            this.adultCountData[1].selected = false;
            if (index == 0) {
              childCount.selected = true;
              childCount.hide = false;
            }
            else {
              childCount.selected = false;
              childCount.hide = true;
            }
          }
          else {
            if (index == selectedChildIndex) {
              childCount.selected = true;
              childCount.hide = false;
            }
            else {
              childCount.selected = false;
            }
          }
        })
      }

      if(this.csFormData.quoteFormData['riskCategory'].length > 0){
        this.csFormData.quoteFormData['riskCategory'].map((riskObj,riskIndex)=>{
                this.riskCategory.at(riskIndex).get(`adult${riskIndex + 1}`).setValue(riskObj[`adult${riskIndex + 1}`]);
                this.showCovers = true;
        })
      }

      if(this.csFormData.quoteFormData['pincode'].length > 0){
        let pincodeObj = {
          target : {
              value: this.csFormData.quoteFormData['pincode']
          }
        }
        this.fetchDataAsPerPincode(pincodeObj,null)
      }
      // IPAA-176: Patch totalMemeber object for insured page Convert API
      if(this.mySavedQuoteData && (this.adultDetails.length > 0 || this.childDetails.length > 0)) {
        this.csFormData.quoteFormData.totalMemberData = this.getMemberRequest();  
        this.healthDataStorageService.setHealthDataStorage(this.csFormData);
      }
    }
    else {
      this.adultDetails.push(this.createAdultDetails(0));
      this.riskCategory.push(this.createRiskCategory(0));
    }
  }

  get adultDetails(): FormArray {
    return this.critiShieldForm.get('adultDetails') as FormArray;
  }

  get childDetails(): FormArray {
    return this.critiShieldForm.get('childDetails') as FormArray;
  }

  get riskCategory(): FormArray{
    return this.critiShieldForm.get('riskCategory') as FormArray;
  }

  openRiskPopup() {
    this.popupService.openRiskCategoryPopUp(this.riskCategoryArray)
  }

  createRiskCategory(index?): FormGroup {
    return new FormGroup({
      ['adult' + `${index + 1}`]: new FormControl('')
    })
  }

  value(){
    this.showCovers = true;
  }

  createAdultDetails(index?): FormGroup {
    return new FormGroup({
      ['dobAdult' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultOccupation' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultIncome' + `${index + 1}`]: new FormControl(''),
      ['adultRiskCategory' + `${index + 1}`] : new FormControl(''),
      ['MemberType'] : new FormControl('Adult')
    })
  }

  createChildDetails(index?): FormGroup {
    return new FormGroup({
      ['dobChild' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['MemberType'] : new FormControl('Child')
    })
  }

  // on load service calls
  fetchHAPDetails() {
    this.transparentSpinner = false;
    // If below solution does not work try calling api individually
    this.subscription.push(this.requestDataFromMultipleSources().subscribe({
      next: (responseList: [HealthAdvantageResponsePayload, RelationshipResponsePayload]) => {
        this.multipleServiceError = false;
        this.handleMultipleResponse(responseList)

      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    })
    )
  }

  csMasterData(){
    this.transparentSpinner = false;
    this._critishieldService.getMasterData().subscribe((res:any)=>{

                if(res.StatusCode && res.StatusMsg == 'Success'){
                    this.masterData = res.Data;
                    this.patchingDone = true ;
                    this.coversArray = this.masterData.bucketDetails;
                    this.annualIncomeCritisield = this.masterData.AnnualIncome;
                    this.siLookUpRangeCritishield = this.masterData.SILookupRange;
                    this.riskCategoryArray = this.masterData.riskCategory;
                    if(!this.critiShieldForm.getRawValue()['memberCoverDetails'].length){
                      this.createAddonForm(0,this.coversArray);
                    }
                    // this.createAddonForm(1,this.coversArray);
                    this.masterData.riskCategory.map((ele,ind)=>{
                          ele['Name'] = 'Risk Category ' + (ind + 1);
                          // this.masterData.PLAN_DETAILS[ind].CS_PlanCode_RiskCatgry.find(riskCat=> {
                          //   if(riskCat.RISK_CATEGORY == ele['Name']) {
                          //     ele['PlanCode'] = riskCat.PLAN_CODE
                          //   }
                          // })
                          this.riskCategoryList.push(ele);
                    })
                }

                
    })

  }

  handleMultipleResponse(responseList: [HealthAdvantageResponsePayload, RelationshipResponsePayload]) {
    if (responseList[0].StatusCode != Constants.statusCode_success || responseList[1].StatusCode != Constants.statusCode_success) {
      this.multipleServiceError = true;
    }
    else {
      this.multipleServiceError = false;
      this.manageHAPMasterData(responseList[0]);
    }
  }

  requestDataFromMultipleSources(): Observable<[HealthAdvantageResponsePayload, RelationshipResponsePayload]> {
    let proposalData: RelationshipRequestPayload = { product: Constants.healthProposalProductCode };
    const agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.dealId = agentDetails.MappedProduct.Health.HealthASPDealId;
    const UserID: string = String(agentDetails.AgentID);
    // let masterRequest:HealthAdvantageRequestPayload = {IsOrion:this.storageService.getAESDecryptedData('IsOrion')  == 'true'? true: false};
    return this.healthAdvantedgeService.getHealthAdvantagePlusMasterData().pipe(
      // concatMap operator to sequentially call getHealthAdvantagePlusMasterData
      concatMap(healthResponse => {
        // forkJoin to concurrently call the other two methods
        // The responses from these three requests are combined into an array and emitted as a single observable
        return forkJoin([
          // of(healthResponse) within the forkJoin to include the result of the first API call in the final array of responses. 
          of(healthResponse),
          this.healthMasterService.getHealthProposalRelationships(proposalData)
        ]);
      })
    );
  }

  // handles hap master data
  manageHAPMasterData(hapResponse: HealthAdvantageResponsePayload) {
    if (hapResponse.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      this.hapMasterData = hapResponse;
      if (this.hapMasterData.Data.PolicyTenure.length > 0) {
        // This needs to be changed from BE .
        let tempTenure: String[] = this.hapMasterData.Data.PolicyTenure[0].split(',').map((data, index) => data + ' ' + (index == 0 ? 'Year' : 'Years'));
        if (tempTenure.length > 0) {
          this.tenurePeriod = tempTenure;
        }
      }
      this.annualIncome = this.hapMasterData.Data.IncomeRange && this.hapMasterData.Data.IncomeRange.length > 0 ? this.hapMasterData.Data.IncomeRange : [];
    }
  }

  hasError(controlName: string, errorCode: string): boolean {
    return !this.critiShieldForm.valid && this.critiShieldForm.hasError(errorCode, [controlName]);
  }

  // Fetch statename according to pincode
  fetchDataAsPerPincode(event, existingPincode) {
    if (existingPincode == null) {
      this.csFormData.quoteDataSet = false;
    }

    this.formReset = false;
    this.stateFetched = false;
    this.invalidPinCodeMessage = '';
    this.cityData = [];
    if ((existingPincode != null ? existingPincode : event.target.value.length == 6) && !this.critiShieldForm.controls['pincode'].hasError('pattern')) {
      let data: RTOListRequestPayload = {
        "Pincode": existingPincode != null ? existingPincode.toString() : event.target.value,
        "CityID": ""
      }
      this.transparentSpinner = true;
      this.invalidPinCodeMessage = '';
      this.critiShieldForm.controls['cityState'].reset();
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.length > 0) {
              this.stateDetails.stateId = response.Data[0].StateId ? response.Data[0].StateId : '';
              this.stateDetails.stateName = response.Data[0].StateName ? response.Data[0].StateName : '';
              this.stateDetails.isBefitApplicable = response.Data[0].IsBefitApplicable ? "true" : "false";
              this.stateDetails.pincode = existingPincode != null ? existingPincode : event.target.value;
              response.Data.forEach(cityList => {
                this.cityData.push({ 'cityLabel': cityList['CityName'], 'stateLabel': this.stateDetails.stateName, 'value': cityList['CityID'] });
              });
            }
            if (this.cityData.length == 1) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.critiShieldForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
            }
            // Need to check below in backward patching
            if (this.csFormData.quoteDataSet) {
              this.zoneMappedWithPincode = this.csFormData.quoteFormData.zone;
              this.critiShieldForm.controls['cityState'].setValue(this.csFormData.quoteFormData.cityState);
              this.changeCityName(this.csFormData.quoteFormData.cityId, false);
              if (this.csFormData.quoteDataSet && !this.patchingDone && this.cityData.length > 1) {
                this.patchingDone = true;
              }
            }
            this.stateFetched = true;
          }

          else if (response.StatusCode == Constants.statusCode_failure) {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            this.stateFetched = false;
            this.invalidPinCodeMessage = response.StatusDesc;
          }

          else {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          }
        },
        error: error => {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.invalidPinCodeMessage = '';
          this.stateFetched = false;
        }
      })
      )
    }
    this.utilityService.numberOnly(event);
    this.utilityService.restrictSpace(event);
  }

  changeCityName(cityValue: string, showSpinner: boolean) {
    this.stateDetails.cityId = cityValue;
    this.stateDetails.cityName = this.cityData.length > 0 ? (this.cityData.find(data => data.value == cityValue).cityLabel) : '';
  }

  showPremiumDetails(data: boolean) {
    this.customFooterService.showPremiumDetails(data);
  }

  openSnackBar(msg: string) {
    this.snackBar.open(msg, 'Ok', {
      duration: 2000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
    });
  }

  incrementMember(memberData:any, event) {
    let flag = this.checkCoverSelection();
    
    if (!flag['flag']) {
      if(memberData.label == 'Adult') {
          if(this.adultCount < 2) {
            this.adultCount +=1;
          }
          this.adultCountSelected(this.adultCount, event, flag);
      } else if(memberData.label == 'Child') {
        this.childCount+=1;
        if(this.adultCount < 2 && this.childCount > 2){
          this.childCount -=1;
          this.openSnackBar(`3C can be selected on 2A only. To uncheck Please select 1A`)
          return;
        }
        if(this.childCount > 3) {
          this.childCount -=1;
          return;
        }
        this.childCountSelected(this.childCount, event);
      }
    } else {
        this.openSnackBar(`Please select cover for Member ${this.adultCount}`)
    }
  }

  decrementMember(memberData:any, event) {
    // let flag = this.checkCoverSelection();
    if(memberData.label == 'Adult') {
      if(this.adultCount == 1) {
        this.openSnackBar("Alteast one Adult is required");
        return;
      }
      if(this.adultCount >1) {
        this.adultCount -=1;
        this.adultCountSelected(this.adultCount, event);
      }
    } else if(memberData.label == 'Child') {
      if(this.childCount == 1) {
        this.childCountSelected(this.childCount, event);
        this.childCount -=1;
        return;
      }
      if(this.childCount >= 2) {
        this.childCount -=1;
        this.childCountSelected(this.childCount, event);
      }
    }
  }


  // Adult count selected
  adultCountSelected(adultValue: number, event, incrementFlag?) {
    // adult count entire logic is set on adult data present in hap modal
    // toggle is handled by selected sttribute for each object
    let flag = incrementFlag || this.checkCoverSelection();
    let index = adultValue - 1;
    let selectedChild = this.fetchSelectedChild();
    if(!flag['flag']){
        switch (adultValue) {

        // If adult 1 is selected then we need to set selected attribute true
        case 1: {
          if (!this.adultCountData[index].selected) {
            this.adultCountData[index].selected = true;
            this.adultCountData[index + 1].selected = false;

              // this.constructMemberDetails('Adult',0,0,'hideOtherAdults');
              // this.constructMemberDetails2('Adult',1);
              // this.createAddonForm(1,this.coversArray);

              // Also enable second child because we have a condition for 0A 1C only should be there
              // SO if it's hidden unhide it
              if (this.childCountData[1].hide) {
                this.childCountData[1].hide = false;
                this.childCountData[1].selected = false;
              }

              // If two adults present in form array remove second adult as only 1A needed
              if (this.adultDetails.length > 1) {
                this.adultDetails.removeAt(this.adultDetails.length - 1);
                this.memberCoverDetails().removeAt(this.adultDetails.length);
                this.tabGroup.pop();
              }

              // If no adults presents in form array as again 1A is selected then we need to push in array
              // Push one adult in form array
              else if (this.adultDetails.length == 0) {
                this.adultDetails.push(this.createAdultDetails(0));
                this.riskCategory.push(this.createRiskCategory(0));
                // this.createAddonForm(0)

              }

              // If one adult is selected then make third child hidden. SO for 1A 2C can be selected and also unselect them
              this.childCountData[2].hide = true;
              this.childCountData[2].selected = false;

              // if 3C is presnt in form array then remove third child from form array
              if (this.childDetails.length == 3) {
                // If third child was selected then we need to remove all child details from form array
                if (selectedChild[0].value == '3') {
                  this.childCount=0;
                  this.childDetails.clear();
                  this.memberCoverDetailsChild().removeAt(0);
                  this.memberCoverDetailsChild().removeAt(1);
                  this.memberCoverDetailsChild().removeAt(2);
                  this.tabGroupChild.pop();
                  this.tabGroupChild.pop();
                  this.tabGroupChild.pop();
                }
                else {
                  this.childDetails.removeAt(this.childDetails.length - 1);
                }
              }

              this.constructMemberDetails('Adult',0,0,'hideOtherAdults');

            }
            // But if user tries to click again on adult 1 and if it is already selected that means if selected is true
            // then stop checkbox behaviour
            else {
              // Now if he tries to uncheck adult. We need to allow uncheck only when any of the child is selcted otherwise we won't allow
              // Uncheck to be allowed only when child is present
              if (selectedChild.length == 0) {
                event.preventDefault();
                event.stopPropagation();
              this.utilityService.popUpDialog('Atleast one member is required');
              }
              // If uncheck can happen set the value of that adult as false.
              else {
                // Remove all adult details
                this.adultDetails.clear();
                this.adultCountData[index].selected = false;
                // If no adult is selected then only 1 child should be visible
                this.childCountData.forEach((childData, index) => {
                  childData.hide = index == 0 ? false : true;
                  childData.selected = index == 0 ? true : false;
                })

                // Remove child elements if present
                if (this.childDetails.length == 2) {
                  this.childDetails.removeAt(this.childDetails.length - 1);
                }

              }
            }
            break;
          }
          // If adult 2 is selected then we need to set selected attribute true
          case 2: {
            if (!this.adultCountData[index].selected) {
              this.adultCountData[index].selected = true;
              this.adultCountData[index - 1].selected = false;

              // Push one adult in form array if one adult is already present
              if (this.adultDetails.length == 1) {
                this.adultDetails.push(this.createAdultDetails(1));
                this.riskCategory.push(this.createRiskCategory(1));
                this.constructMemberDetails('Adult',1,1);
                // this.constructMemberDetails2('Adult',1);
                // this.createAddonForm(1,this.coversArray);
              }

              // If no adult is present then push two adults
              else if (this.adultDetails.length == 0) {
                this.adultDetails.push(this.createAdultDetails(0));
                this.adultDetails.push(this.createAdultDetails(1));
                this.riskCategory.push(this.createRiskCategory(0));
                this.riskCategory.push(this.createRiskCategory(1));
              }

              // If two adult is selected then make one child visible. SO for 2A 3C can be selected and also unselect them
              this.childCountData[2].selected = false;
              this.childCountData[2].hide = false;

              // If first child is hidden unhide it
              if (this.childCountData[1].hide) {
                this.childCountData[1].hide = false;
                this.childCountData[1].selected = false;
              }
            }
            // But if user tries to click again on adult 2 and if it is already selected that means if selected is true
            // then stop checkbox behaviour
            else {
              // Now if he tries to uncheck adult. We need to allow uncheck only when any of the child is selcted otherwise we won't allow
              // Uncheck to be allowed only when child is present
              if (selectedChild.length == 0) {
                event.preventDefault();
                event.stopPropagation();
                this.utilityService.popUpDialog('Atleast one member is required');
              }
              // If user tries to uncheck 2A but he has selected 3C. Then we can't allow uncheck
              else if (selectedChild.length > 0 && selectedChild[0].value >= '3') {
                event.preventDefault();
                event.stopPropagation();
                this.utilityService.popUpDialog('3C can be selected on 2A only. To uncheck Please select 1A');
              }
              else if (selectedChild.length > 0) {
                // Remove all adult details
                this.adultDetails.clear();
                this.adultCountData[index].selected = false;
                // If no adult is selected then only 1 child should be visible
                this.childCountData.forEach((childData, index) => {
                  childData.selected = index == 0 ? true : false;
                  childData.hide = index == 0 ? false : true;
                })
                // Remove child elements if present
                if (this.childDetails.length == 2) {
                  this.childDetails.removeAt(this.childDetails.length - 1);
                }
              }
            }
            break;
          }
          default : {
            break;
          }
        }
    }else{
        event.preventDefault();
        event.stopPropagation();
        this.adultCountData[index - 1].selected = true;
        this.adultCountData[index].selected = false;
        setTimeout(()=>{
      this.utilityService.popUpDialog(`${flag['desc']}`);
        },50)
       
    }
   
  }
  // Child count selected
  childCountSelected(childValue: number, event) {
    // child count entire logic is set on child data present in hap modal
    // toggle is handled by selected attribute for each object
    let flag = this.checkCoverSelection();
    let index = childValue - 1;
    let selectedAdult = this.fetchSelectedAdult();
    if(!flag['flag']){
      switch (childValue) {
        // If child 1 is selected then we need to set selected attribute true
        case 1: {
          if (!this.childCountData[index].selected) {
            // this.constructMemberDetails('Child',0,Number(childValue) + 1);
            // this.constructMemberDetailsChild('Child',0,Number(childValue) + 1);
  
            // this.constructMemberDetails2('Child',0);
            // this.createAddonForm(Number(childValue) + 1,this.coversArray);
            this.childCountData[index].selected = true;
  
            // As it is check box and 1C is selected we need to ensure other 2C & 3C is unchecked
            this.childCountData[index + 1].selected = false;
            this.childCountData[index + 2].selected = false;
  
            // If 0C is present push one data in child array
            if (this.childDetails.length == 0) {
              this.childDetails.push(this.createChildDetails(0));
            }
  
            // If 2C is present in child details form array remove second child
            else if (this.childDetails.length == 2) {
              this.childDetails.removeAt(this.childDetails.length - 1);
              // remove child from tab group and remove from child covers;
              this.tabGroupChild.pop();
              this.memberCoverDetailsChild().removeAt(this.childDetails.length);
            }
  
            // If 3C is present then remove last two child
            // remove child from tab group and remove from child covers;
            else if (this.childDetails.length == 3) {
              this.childDetails.removeAt(this.childDetails.length - 1);
              this.memberCoverDetailsChild().removeAt(this.childDetails.length);
              this.tabGroupChild.pop();
              this.childDetails.removeAt(this.childDetails.length - 1);
              this.memberCoverDetailsChild().removeAt(this.childDetails.length);
              this.tabGroupChild.pop();
            }
  
            this.constructMemberDetailsChild('Child',0,Number(childValue) + 1);
  
          }
  
          // And if no adult is selected then child can't be unchecked otherwise uncheck can happen
          else if (selectedAdult.length == 0) {
            event.preventDefault();
            event.stopPropagation();
            this.utilityService.popUpDialog('Atleast one member is required');
          }
          // if it can be unchecked set value as false of that unchecked child
          else if (selectedAdult.length > 0) {
            this.childCountData[index].selected = false;
            // clear child details
            this.childDetails.clear();
          }
          break;
        }
  
        // If child 2 is selected then we need to set selected attribute true
        case 2: {
          if (!this.childCountData[index].selected) {
            this.childCountData[index].selected = true;
            // this.constructMemberDetails('Child',1,Number(childValue) + 1);
            // this.constructMemberDetailsChild('Child',1,Number(childValue) + 1);
            // As it is check box and 2C is selected we need to ensure other 1C & 3C is unchecked
            this.childCountData[index - 1].selected = false;
            this.childCountData[index + 1].selected = false;
  
            // If 0C is present push two data in child array
            if (this.childDetails.length == 0) {
              this.childDetails.push(this.createChildDetails(0));
              this.childDetails.push(this.createChildDetails(1));
            }
  
            // If 1C is present push one data in child array
            else if (this.childDetails.length == 1) {
              this.childDetails.push(this.createChildDetails(1));
            }
  
            // If 3C is present in child details form array remove third child
            else if (this.childDetails.length == 3) {
              this.childDetails.removeAt(this.childDetails.length - 1);
              this.memberCoverDetailsChild().removeAt(this.childDetails.length);
              this.tabGroupChild.pop();
            }
  
            for(let i = 0 ; i<Number(childValue);i++){
              this.constructMemberDetailsChild('Child',i,Number(childValue) + 1);
            }
  
          }
  
          // And if no adult is selected then child can't be unchecked otherwise uncheck can happen
          else if (selectedAdult.length == 0) {
            event.preventDefault();
            event.stopPropagation();
            this.utilityService.popUpDialog('Atleast one member is required');
          }
          // if it can be unchecked set value as false of that unchecked child
          else if (selectedAdult.length > 0) {
            this.childCountData[index].selected = false;
            // clear child details
            this.childDetails.clear();
          }
          break;
        }
  
        // If child 3 is selected then we need to set selected attribute true
        case 3: {
          if (!this.childCountData[index].selected) {
            // this.constructMemberDetails('Child',2,Number(childValue) + 1);
            // this.constructMemberDetailsChild('Child',2,Number(childValue) + 1);
            this.childCountData[index].selected = true;
            // As it is check box and 3C is selected we need to ensure other 1C & 2C is unchecked
            this.childCountData[index - 2].selected = false;
            this.childCountData[index - 1].selected = false;
  
            // If 0C is present then push 3 child data in form array
            if (this.childDetails.length == 0) {
              this.childDetails.push(this.createChildDetails(0));
              this.childDetails.push(this.createChildDetails(1));
              this.childDetails.push(this.createChildDetails(2));
            }
            // If 1C is present push two data in child array
            else if (this.childDetails.length == 1) {
              this.childDetails.push(this.createChildDetails(1));
              this.childDetails.push(this.createChildDetails(2));
            }
            // If 2C is present push one data in child array
            else if (this.childDetails.length == 2) {
              this.childDetails.push(this.createChildDetails(2));
            }
  
            for(let i = 0 ; i< Number(childValue); i++){
              this.constructMemberDetailsChild('Child',i,Number(childValue) + 1);
            }
          }
          // But if user tries to click again on child 3 and if it is already selected that means if selected is true
          // then stop checkbox behaviour
          //In this case 2A is required and if it is checked it can't be unchecked
          else {
            event.preventDefault();
            event.stopPropagation();
            this.utilityService.popUpDialog('3C can be selected on 2A only. To uncheck Please select 1A');
          }
          break;
        }
        default : {
          break;
        }
      }
    }else{
      event.preventDefault();
      event.stopPropagation();
      this.utilityService.popUpDialog(`${flag['desc']}`);
    }
    
  }

  fetchSelectedAdult() {
    return this.adultCountData.filter(element => element.selected);
  }

  fetchSelectedChild() {
    return this.childCountData.filter(element => element.selected);
  }

  getMinMaxDate(type: string, minMaxType: string) {
    let noOfAdult = this.adultDetails.length;
    let minYear = type == healthMembers.Child ? 26 : 66;
    let maxYear = type != healthMembers.Child ? 18 : type == healthMembers.Child && noOfAdult == 0 ? 6 : 0;
    let maxMonth = type == healthMembers.Child && noOfAdult != 0 ? 3 : '';
    let minMonth = type == healthMembers.Child && noOfAdult != 0 ? 9 : '';
    let insuredMaxYear = (type != healthMembers.Child) || (type == healthMembers.Child && noOfAdult == 0) ? new Date().getFullYear() - maxYear : new Date().getFullYear();
    let insuredMinYear = new Date().getFullYear() - minYear;
    this.insuredMinMaxDate = this.utilityService.getMinMaxDate(insuredMaxYear, insuredMinYear, maxMonth, minMonth);
    return minMaxType == 'min' ? this.insuredMinMaxDate.minDate : this.insuredMinMaxDate.maxDate;
  }

  getInsuredMinMaxDate(memberType: string, minMaxType: string) {
    if (memberType == 'Adult') {
      let adultMaxDate = this.utilityService.subtractYears(new Date(), 18);

      let yearsdate = this.utilityService.subtractYears(new Date(), 65);
      let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
      let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

      return minMaxType == 'min' ? adultMinDate : adultMaxDate;
    }
    else {
      let noOfAdults = this.adultDetails.length;
      if (noOfAdults > 0) {
        //depedent child
        let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);

        let yearDate = this.utilityService.subtractYears(new Date(), 17);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? depChildMinDate : depChildMaxDate;
      }
    }
  }

  onChangeEmitValue() {
    // this.QuoteValueEmitter.emit({ Quote: false, Portability: true, StatusCode: false });
  }

  getQuote(event: EmitButtonData) {
    this.formReset = false;
    this.transparentSpinner = false;
    this.success = false;
    this.formSubmitted = true;
    let myFormValue = this.critiShieldForm.getRawValue();
    this.critiShieldForm.markAllAsTouched();
    // If child details length is only 1 . we need to check dob present in child is valid or not
    if (this.childDetails.length == 1 && this.adultDetails.length == 0) {
      // Read Dob value if it's invalid then reset
      if (this.childDetails.at(0).get('dobChild1').status == 'INVALID') {
        this.childDetails.at(0).get('dobChild1').reset();
        this.childDetails.at(0).get('dobChild1').setValue('');
        this.childDetails.at(0).get('dobChild1').setValidators(Validators.required);
        this.childDetails.at(0).get('dobChild1').updateValueAndValidity();
      }
    }
    if (!this.critiShieldForm.valid || this.invalidPinCodeMessage !== '') {
      for (const key of Object.keys(this.critiShieldForm.controls)) {
        if (this.critiShieldForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
          invalidControl.focus();
          break;
        }
      }
      this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
    }

    else if (this.invalidPinCodeMessage == '') {
      if(myFormValue.riskCategory.length){
        let flag = false;
        myFormValue.riskCategory.map((riskObj,ind)=>{
              if(riskObj[`adult${ind + 1 }`] == ""){
                flag = true;
            this.utilityService.popUpDialog(`Please select Risk Category for Member ${ind + 1}`);
              }
        })

        if(flag){
          return ;
        }
    }

    if(myFormValue.memberCoverDetails.length>0){
          let showCoverErrorMsg = true;
          let showSumInsuredErrorMsg = false;
          let memberInd;
          myFormValue.memberCoverDetails.map((memberObj,memberIndex)=>{
                  memberObj['covers'].map((coverObj,coverInd)=>{
                          if(coverObj.coverSelected){
                            showCoverErrorMsg = false;
                            coverObj['base'].map((baseCover,baseCoverInd)=>{
                                if(baseCover['baseCoverInputValue']<1){
                                  memberInd = memberIndex
                                  showSumInsuredErrorMsg = true;
                                }
                            })
                          }
                  })  
          })
  
          if (showSumInsuredErrorMsg) {
            this.utilityService.popUpDialog(`Please select an SI value greater than 0 for Member ${memberInd + 1}`);
            return
          }

          if(showCoverErrorMsg){

            this.utilityService.popUpDialog('Please select cover for Member 1');
            return
          }
    }

    // if(myFormValue.memberCoverDetailsChild.length>0){
    //   let showCoverErrorMsgChild = true;
    //   let showSumInsuredErrorMsgChild = false;
    //   let memberIndChild;
    //   myFormValue.memberCoverDetailsChild.map((memberObj,memberIndex)=>{
    //           memberIndChild = memberIndex;
    //           memberObj['covers'].map((coverObj,coverInd)=>{
    //                   if(coverObj.coverSelected){
    //                     showCoverErrorMsgChild = false;
    //                     coverObj['base'].map((baseCover,baseCoverInd)=>{
    //                         if(baseCover['baseCoverInputValue']<1){
    //                           memberIndChild = memberIndex;
    //                           showSumInsuredErrorMsgChild = true;
    //                         }
    //                     })
    //                   }
    //           })  
    //   })

    //   if(showCoverErrorMsgChild){

    //     this.snackBar.open(`Please select cover for Child`, 'Ok', {
    //       duration: 2000,
    //       verticalPosition: 'bottom',
    //       horizontalPosition: 'center',
    //     });
    //     return
    //   }

    //   if(showSumInsuredErrorMsgChild){
    //     this.snackBar.open(`Please select an SI value greater than 0 for Child`, 'Ok', {
    //       duration: 2000,
    //       verticalPosition: 'bottom',
    //       horizontalPosition: 'center',
    //     });
    //     return
    //   }
    // }
      this.formSubmitted = false;
      // let request: SaveEditQuoteV1Request = this.constructRequest();
      let critishieldRequestPayload = this.csRequestPayload();
      this.subscription.push(this._critishieldService.saveEditQuoteData(critishieldRequestPayload).subscribe({
        next: response => {

          if (response.StatusCode == Constants.statusCode_success) {
            // this.patchingDone = true;
            if(response.Data.CorrelationId) {
              this.csFormData.quoteFormData.corelationId = response.Data.CorrelationId;
              this.healthDataStorageService.setHealthDataStorage(this.csFormData);
            }
            this.statuscode = true;
            if(!this.mySavedQuoteData) {
              this.customStepperService.resetStepper(true);
            }
            if( response.Data.TotalPremiumOfMembers<500){
              let popupData: PopupModal = {
                popupType: popupType.generic,
                imgName: popupImgPath.alertGif,
                header: popupHeader.error,
                description: 'The premium can not be less than 500. please select a diffrent combination of covers and/or SI',
                buttonLabel: popupButton.ok,
                buttonAction: popupButtonAction.close
              }
              this.popupService.openGenericPopup(popupData);
              return
            }
            this.setCSFormData(response?.Data);
            this.disableButton = true;
            this.success = true;
            this.patchingDone = true;
            // If premium exists then only allow user to proceed forward
            if (!this.utilityService.isEmptyOrNull(this.csFormData.premiumFormData.totalPremium) && this.csFormData.premiumFormData.totalPremium != '0') {
              this.customStepperService.emitStepData({ status: true, buttonId: 'f1' });
            }
            else {
              this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            }
            this.showPremiumDetails(true);
          }
          else if (response.StatusCode != Constants.statusCode_success) {

            this.success = false;
            this.disableButton = false;
            this.statuscode = false;
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          }
        },
        error: (err) => {
          this.success = false;
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
        }
      }))
      // this.subscription.push(this.healthAdvantedgeService.saveEditQuoteData(request).subscribe({
      //   next: response => {

      //     if (response.StatusCode == Constants.statusCode_success) {
      //       this.statuscode = true
      //       this.setCSFormData(response?.Data);
      //       this.disableButton = true;
      //       this.success = true;
      //       this.patchingDone = true;
      //       // If premium exists then only allow user to proceed forward
      //       if (!this.utilityService.isEmptyOrNull(this.csFormData.premiumFormData.totalPremium) && this.csFormData.premiumFormData.totalPremium != '0') {
      //         this.customStepperService.emitStepData({ status: true, buttonId: 'f1' });
      //       }
      //       else {
      //         this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
      //       }
      //       this.showPremiumDetails(true);
      //     }
      //     else if (response.StatusCode != Constants.statusCode_success) {

      //       this.success = false;
      //       this.disableButton = false;
      //       this.statuscode = false;
      //       this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
      //     }
      //   },
      //   error: (err) => {
      //     this.success = false;
      //     this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
      //   }
      // }))
    }
    
  }
  getPlanCode() {
    let planCode;
    this.masterData.PLAN_DETAILS[this.selectedCoverIndex].CS_PlanCode_RiskCatgry.find(riskCat=> {
      if(riskCat.RISK_CATEGORY == this.critiShieldForm.value.riskCategory[this.selectedAdultIndex][`adult${this.selectedAdultIndex+1}`]) {
        planCode = riskCat.PLAN_CODE;
      }
    });
    return planCode;
  }

  constructRequest(): SaveEditQuoteV1Request {
    let tenure = (this.critiShieldForm.value.policyTenure).split(' ')[0];
    this.tenureDuration = parseInt(tenure);
    let vaccinationtaken = this.adultDetails.value.filter((vaccinationStatus, i) =>
      vaccinationStatus['adultVaccinationStatus' + (i + 1)] == 'Yes'
    )
    let adult1DOB = this.adultDetails.value.length > 0 ? this.utilityService.formatDate(this.adultDetails.value[0]['dobAdult1']) : '';
    let child1DOB = this.childDetails.value.length > 0 ? this.utilityService.formatDate(this.childDetails.value[0]['dobChild1']) : '';
    let adult1Age = this.adultDetails.value.length > 0 ? this.utilityService.calculateAge(this.adultDetails.value[0]['dobAdult1']) : '';
    let child1Age = this.childDetails.value.length > 0 ? this.utilityService.calculateAge(this.childDetails.value[0]['dobChild1']) : '';
    let requestData: SaveEditQuoteV1Request = {
      "ProductType": this.critiShieldForm.value.productType == 'New' ? 'N' : 'R',
      "UserType": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "NoOfAdults": (this.adultDetails.length).toString(),
      "NoOfKids": (this.childDetails.length).toString(),
      "NoOfInLaws": null,
      "AgeGroup": null,
      "SubProductType": this.hapDataModal.subProductType.hap,
      "SubLimit": null,
      "Tenure": this.tenureDuration,
      "IsJammuKashmir": this.checkIsGSTRegistered(),
      "VolDedutible": this.hapDataModal.volDedutible,
      "GSTStateCode": this.stateDetails.stateId,
      "CityCode": this.stateDetails.cityId,
      "SumInsured": this.critiShieldForm.value.applicantAnnualSum,
      "GSTStateName": this.stateDetails.stateName ? this.stateDetails.stateName : '',
      "isGSTRegistered": this.checkIsGSTRegistered(),
      "CalculateHigherSI": false,
      "SaveQuote": false,//as discussed we don't want to save it . So false
      "Members": this.getMemberRequest(),
      "IpAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      "IsOtherLoadingDiscount": ((this.adultDetails.value.length !== 0) && (this.adultDetails.value.length == vaccinationtaken.length)) ? true : false,
      "DobOfEldestMember": this.adultDetails.value.length == 0 ? child1DOB : adult1DOB,
      "EldestMemberAge": this.adultDetails.value.length == 0 ? (child1Age).toString() : (adult1Age).toString(),
      "City": this.stateDetails.cityName,
      "Pincode": (this.critiShieldForm.value.pincode).toString()
    }
    Object.assign(requestData);
    return requestData;

  }

  csRequestPayload(){

    this.selectedCoverIndex = this.critiShieldForm.value['memberCoverDetails'][0]['covers'].findIndex(x => x.coverSelected ===true);

    let obj ={};
    obj['CustomerGstState'] = this.stateDetails['stateName'];
    obj['PlanCode'] = this.getPlanCode();
    // obj['Product'] = 'Critishield';
    obj['Tenure'] = Number(this.critiShieldForm.value.policyTenure.split(" ")[0]),
    obj['UserType'] = "Agent";
    obj['MemberDetails'] = [];
    let riskCategoryArray = this.critiShieldForm.value.riskCategory;
    // add adults data in memberdetails array;
    this.critiShieldForm.value['adultDetails'].map((adult,adultInd)=>{
                let adultObj = {};
                adultObj['InsuredDOB'] = this.modifyDate(this.utilityService.formatDate(adult[`dobAdult${adultInd + 1}`]));
                adultObj['OccupationType'] = adult[`adultOccupation${adultInd + 1}`];
                adultObj['PlanCode'] =  this.getPlanCode();
                adultObj['PreExistingIllness'] = false;
                adultObj['RiskCategory'] = ((riskCategoryArray[adultInd][`adult${adultInd + 1}`] == "Risk Category 1") ? 1 : (riskCategoryArray[adultInd][`adult${adultInd + 1}`] == "Risk Category 2") ? 2 : 3);
                adultObj['CoverDetails'] = [];

                this.critiShieldForm.value['memberCoverDetails'].map((adultCover,adultCoverInd)=>{

                              if(adultInd == adultCoverInd){

                                  adultCover['covers'].map((cover,coverInd)=>{
                                          if(cover['coverSelected']){

                                                cover['base'].map((base,baseInd)=>{
                                                        if(base['baseCoverSelected']){
                                                            let baseCoverDetails = {
                                                              'Column1' : '',
                                                              'CoverName': base['baseCoverName'],
                                                              'CoverSI': Number(base['baseCoverInputValue'])
                                                            };
                                                            adultObj['CoverDetails'].push(baseCoverDetails);
                                                        }
                                                })

                                                cover['addon'].map((addon,addonInd)=>{
                                                      if(addon['addonCoverSelected']){
                                                          let addonCoverDetails = {
                                                            'Column1' : '',
                                                            'CoverName': addon['addonCoverName'],
                                                            'CoverSI': Number(addon['addonCoverInputValue'])
                                                          };
                                                          adultObj['CoverDetails'].push(addonCoverDetails);
                                                      }
                                                })
                                          }
                                  })

                                  

                              }
                });

                obj['MemberDetails'].push(adultObj);
    })

    // add child data in child array;

    this.critiShieldForm.value['childDetails'].map((child,childInd)=>{
      let childObj = {};
      childObj['InsuredDOB'] = this.modifyDate(this.utilityService.formatDate(child[`dobChild${childInd + 1}`]));
      childObj['OccupationType'] = "No Active Source Of Income";
      // childObj['PlanCode'] = this.getPlanCode();
      childObj['PreExistingIllness'] = false;
      childObj['RiskCategory'] = 1;
      childObj['CoverDetails'] = [];

      this.critiShieldForm.value['memberCoverDetailsChild'].map((childCover,childCoverInd)=>{

                    if(childInd == childCoverInd){

                      childCover['covers'].map((cover,coverInd)=>{
                                if(cover['coverSelected']){

                                      cover['base'].map((base,baseInd)=>{
                                              if(base['baseCoverSelected']){
                                                  let baseCoverDetails = {
                                                    'Column1' : '',
                                                    'CoverName': base['baseCoverName'],
                                                    'CoverSI': base['baseCoverInputValue']
                                                  };
                                                  childObj['CoverDetails'].push(baseCoverDetails);
                                              }
                                      })

                                      cover['addon'].map((addon,addonInd)=>{
                                            if(addon['addonCoverSelected']){
                                                let addonCoverDetails = {
                                                  'Column1' : '',
                                                  'CoverName': addon['addonCoverName'],
                                                  'CoverSI': addon['addonCoverInputValue']
                                                };
                                                childObj['CoverDetails'].push(addonCoverDetails);
                                            }
                                      })
                                }
                        })

                        

                    }
      });

      obj['MemberDetails'].push(childObj);
    })


    obj && obj['MemberDetails'].map((ele,ind)=>{
                ele['MemberNo'] = ind + 1;
    });

    return obj
  }

  // Quote request formation 
  checkIsGSTRegistered() {
    return this.stateDetails.stateId != this.hapDataModal.stateId ? false : true;
  }

  getMemberRequest() {
    let members = [];
    if (this.adultDetails.length > 0) {
      this.adultDetails.value.forEach((element, i) => {
        members.push({ DOB: this.utilityService.formatDate(element['dobAdult' + (i + 1)]), MemberType: healthMembers.Adult })
      });
    }
    if (this.childDetails.length > 0) {
      this.childDetails.value.forEach((element, j) => {
        members.push({ DOB: this.utilityService.formatDate(element['dobChild' + (j + 1)]), MemberType: healthMembers.Child })
      });
    }

    return members;
  }

  setCSFormData(premiumData: HealthDetails) {
    let resetFlag: boolean = this.checkMemberDetails();
    
   // this.csFormData.quoteFormData.addonList = this.hapMasterData.Data.AddonDetails;
    // this.csFormData.insuredFormData.befitCovers = this.hapMasterData.Data.befitCovers;
    // this.csFormData.insuredFormData.BefitApplicableCities = this.hapMasterData.Data.BefitApplicableCities;

    if (this.critiShieldForm.value.productType != 'New') {
      this.csFormData.portabilityDataSet = true;
      this.csFormData.quoteFormData.InstallmentApplicable = "No"
      this.csFormData.quoteFormData.InstallmentFrequency = ""
    }
    else {
      this.csFormData.portabilityDataSet = false;
    }

    this.csFormData.quoteFormData = this.critiShieldForm.value;
    this.csFormData.quoteFormData['policyRange'] = '';

    // this.csFormData.quoteFormData.productName = this.critiShieldForm.controls['productName'].value;
    // this.csFormData.quoteFormData.productType = this.critiShieldForm.controls['productType'].value;
    // this.csFormData.quoteFormData.pincode = this.critiShieldForm.controls['pincode'].value;
    // this.csFormData.quoteFormData.adultDetails = this.adultDetails.value;
    // this.csFormData.quoteFormData.childDetails = this.childDetails.value;
    this.csFormData.quoteFormData.cityId = this.stateDetails.cityId;
    // this.csFormData.quoteFormData.cityName = this.stateDetails.cityName;
    this.csFormData.quoteFormData.stateId = this.stateDetails.stateId;
    this.csFormData.quoteFormData.totalMemberData = this.getMemberRequest();
    this.csFormData.quoteFormData.productCode = this.masterData.PRODUCT_CODE;
    this.csFormData.quoteFormData.PlanCode = this.getPlanCode();
    this.csFormData.quoteFormData.policyTenure = this.critiShieldForm.controls['policyTenure'].value;
    // this.csFormData.quoteFormData.policyRange = this.critiShieldForm.controls['policyRange'].value;
    this.csFormData.quoteFormData.applicantAnnualSum = '';
    this.csFormData.quoteFormData.correlationId = premiumData.CorrelationId;
    // this.csFormData.quoteFormData.pedWaitingPeriod = this.critiShieldForm.controls['pedWaitingPeriod'].value;
    // this.csFormData.quoteFormData.conditionWaitingPeriod = this.critiShieldForm.controls['conditionWaitingPeriod'].value;
    // this.csFormData.quoteFormData.preHospitalDuration = this.critiShieldForm.controls['preHospitalDuration'].value;
    // this.csFormData.quoteFormData.postHospitalDuration = this.critiShieldForm.controls['postHospitalDuration'].value;
    // this.csFormData.quoteFormData.cityState = this.critiShieldForm.controls['cityState'].value;
    // this.csFormData.quoteFormData.applicantAnnualIncome = this.critiShieldForm.controls['applicantAnnualIncome'].value;
    // this.csFormData.quoteFormData.softCopyDiscount = this.critiShieldForm.controls['softCopyDiscount'].value;
    // this.csFormData.quoteFormData.pedWaitingPeriod = this.critiShieldForm.controls['pedWaitingPeriod'].value;
    // this.csFormData.quoteFormData.copaymentPercentTaken = this.critiShieldForm.controls['copaymentPercentTaken'].value.replace('%', '');
    // this.csFormData.quoteFormData.underWritterApproved = premiumData.Policystatus == "CONDITIONALAPPROVE" ? 'true' : 'false';
    // this.csFormData.quoteFormData.healthProposalProductType = healthProposalProductCode.hap;
    // this.csFormData.quoteFormData.isBefitApplicable = this.stateDetails.isBefitApplicable;
    // this.csFormData.policyDetails.DealId = this.dealId;
    // if (this.critiShieldForm.controls['EMI'].value == 'Yes') {
    //   this.csFormData.quoteFormData.InstallmentApplicable = 'Yes';
    //   this.csFormData.quoteFormData.InstallmentFrequency = this.critiShieldForm.controls['EMIInstallment'].value;
    // }
    // else {
    //   this.csFormData.quoteFormData.InstallmentApplicable = 'No';
    // }
    this.csFormData.quoteFormData.quoteSummary = [];
    this.getQuoteSummaryData();
    this.csFormData.quoteFormData.subProductType = 38;
    this.csFormData.quoteDataSet = true;
    if (resetFlag) {
      this.setOtherFormFlags();
    }

    this.constructPremiumData(premiumData);
    this.healthDataStorageService.setHealthDataStorage(this.csFormData);
    // this.fetchPremiumDetailsForCritishield(); 
  }


  checkMemberDetails(): boolean {
    let nonEqualMembers = [];
    let resetFlag: boolean = false;
    // Adult Details
    // If new adult details and child details length are same then we need to check the info
    if (this.adultDetails.length == this.csFormData.quoteFormData.adultDetails.length) {
      let existingAdultDetails = this.csFormData.quoteFormData.adultDetails;
      existingAdultDetails.forEach((adultData, index) => {
        let tempAdultDetails = adultData;
        // When there is self detected there is change in format of dob.
        if (this.csFormData.kycStatus.ovdSelf || this.csFormData.kycStatus.normalSelf) {
          // convert dob in respected format
          for (let keys in tempAdultDetails) {
            // There are chances date is patched along with orignal time stamp
            if (keys.includes('dob') && !tempAdultDetails[keys].includes('T')) {
              tempAdultDetails[keys] = (new Date(tempAdultDetails[keys]));
            }
          }
        }
        // Looping through keys
        if (JSON.stringify(tempAdultDetails)[index]['dob'] != JSON.stringify(this.adultDetails.value[index]['dob'] )) {
          nonEqualMembers.push(index);
        }
      });
    }
    // If the length is only not equal that means adult data is been modified
    else if (this.adultDetails.length != this.csFormData.quoteFormData.adultDetails.length) {
      nonEqualMembers.push(0);
    }

    // Child Details

    if (this.childDetails.length == this.csFormData.quoteFormData.childDetails.length) {
      let existingChildDetails = this.csFormData.quoteFormData.childDetails;
      existingChildDetails.forEach((childData, index) => {
        // Looping through keys
        if (JSON.stringify(childData) != JSON.stringify(this.childDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }

    // If the length is only not equal that means adult data is been modified
    else if (this.childDetails.length != this.csFormData.quoteFormData.childDetails.length) {
      nonEqualMembers.push(0);
    }

    else {
      // Adult members found to be equal
      nonEqualMembers = [];
    }

    // If there is mismatch of data in terms of length or details
    if (nonEqualMembers.length > 0) {
      resetFlag = true;
    }
    else {
      resetFlag = false;
    }
    return resetFlag;
  }

  setOtherFormFlags() {
    this.csFormData.insuredDataSet = false;
    this.csFormData.kycDataSet = false;
    this.csFormData.kycStatus.ekycMandatory = false;
    this.csFormData.kycStatus.normal = false;
    this.csFormData.kycStatus.normalSelf = false;
    this.csFormData.kycStatus.ovd = false;
    this.csFormData.kycStatus.ovdSelf = false;
    this.csFormData.kycStatus.isKYCDoneSuccessfully = false;
    this.csFormData.kycStatus.idType = false;
    this.csFormData.kycStatus.certNumber = '';
    this.csFormData.kycStatus.isPilotUser = false;
    this.csFormData.kycFormData = this.healthDataStorageService.resetKYCdata();
  }

  constructPremiumData(response:any) {
    this.csFormData.premiumFormData.totalPremium = response.TotalPremiumOfMembers.toString();
    this.csFormData.premiumFormData.basicPremium = response.TotalBasicPremiumOfMembers.toString();
    this.csFormData.premiumFormData.totalTax = response.TotalTaxOfMembers.toString();
    this.csFormData.premiumFormData.tenure = response.PolicyTenure;
    this.csFormData.premiumFormData.QuoteId = response.QuoteId;
    this.csFormData.premiumFormData.SubProductCode = '38';
    // this.csFormData.premiumFormData.PfQuoteId = response.PFQuoteID;
    this.csFormData.premiumFormData.InstallmentNetPremium = response.TotalSiOfMembers;
    // this.csFormData.premiumFormData.InstallmentTaxAmount = response.InstallmentTaxAmount;
    // this.csFormData.premiumFormData.InstallmentTotalPremium = response.InstallmentTotalPremium;
    // let c = response.Zone == "Zone_III" ? 'C' : '';
    // let c1 = response.Zone == "Zone_II" ? 'B' : c;
    // this.csFormData.premiumFormData.zone = response.Zone == "Zone_I" ? 'A' : c1;
    this.csFormData.premiumDataSet = true;
    this.formValid = true;
  }

  resetForm() {
    this.formReset = true;
    this.success = false;
    this.formSubmitted = false;
    if (this.quotePanel.expanded) {
      this.quotePanel.close();
    }
    this.resetFormData();
  }

  resetFormData() {
    this.critiShieldForm.controls['pincode'].reset();
    this.critiShieldForm.controls['policyRange'].reset();
    this.critiShieldForm.controls['applicantAnnualSum'].reset();
    this.critiShieldForm.controls['state'].reset();
    this.critiShieldForm.controls['city'].reset();
    this.critiShieldForm.controls['applicantAnnualIncome'].reset();
    this.adultData = [];
    this.childData = [];
  }

  goToSecondStep() {
    this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
  }


  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  onRefresh(status) {
    if (status) {
      this.fetchHAPDetails();
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  occupationOptionSelected(val,ind){
      let value = val.srcElement.outerText;
      if(value.length){
          this.occupationSelected = true;
          if(value = "No Active Source of Income"){
            this.critiShieldForm.get('adultDetails')['controls'][ind].get(`adultIncome${ind + 1}`).clearValidators();
            this.critiShieldForm.get('adultDetails')['controls'][ind].get(`adultIncome${ind + 1}`).updateValueAndValidity();
          }else{
            this.critiShieldForm.get('adultDetails')['controls'][ind].get(`adultIncome${ind + 1}`).setValidators(Validators.required);
            this.critiShieldForm.get('adultDetails')['controls'][ind].get(`adultIncome${ind + 1}`).updateValueAndValidity();
          }
      }
  }

  salaryOptionSelected(val){
    let value = val.srcElement.outerText;
    if(value.length){
        this.salarySelected = true;
    }
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  baseSelected(ev,memberIndex,coverIndex,subCoverIndex,val){
    this.baseCover(coverIndex,memberIndex).at(subCoverIndex).get('baseCoverSelected').setValue(true);
  }

  addonSelected(ev,memberIndex,coverIndex,subCoverIndex,val){
    if(val.value['addonCoverName'] == "Value Added Services"){
      this.addonCover(coverIndex,memberIndex).at(subCoverIndex).get('addonCoverSelected').setValue(true);
    }else{
      this.addonCover(coverIndex,memberIndex).at(subCoverIndex).get('addonCoverSelected').setValue(ev);
      if(!ev){
        this.addonCover(coverIndex,memberIndex).at(subCoverIndex).get('addonCoverInputValue').setValue(val.value['addonCoverInputValue']);
      }
    }
    
  }

  baseSelectedChild(ev,memberIndex,coverIndex,subCoverIndex,val){
    this.baseCoverChild(coverIndex,memberIndex).at(subCoverIndex).get('baseCoverSelected').setValue(true);
  }

  addonSelectedChild(ev,memberIndex,coverIndex,subCoverIndex,val){
    if(val.value['addonCoverName'] == "Value Added Services"){
      this.addonCoverChild(coverIndex,memberIndex).at(subCoverIndex).get('addonCoverSelected').setValue(true);
    }else{
      this.addonCoverChild(coverIndex,memberIndex).at(subCoverIndex).get('addonCoverSelected').setValue(ev);
      if(!ev){
      this.addonCoverChild(coverIndex,memberIndex).at(subCoverIndex).get('addonCoverInputValue').setValue(val.value['addonCoverInputValue']);
      }
    }
  }

  createAddonForm(index,addonArray){
        // this.addControlToCovers(index,'');
        let addons = this.critiShieldForm.get('memberCoverDetails') as FormArray;
        this.addMemberCoverControl();


        let finalAddonArray = [];
        let selectedCoverIndex;
        let selectedCoverName;
        let selectedAdultCoverLen = addons.length;
        if(selectedAdultCoverLen){
                let coversLength = addons.value[0].covers.length;
                if(coversLength>0){
                    addons.value[0]['covers'].map((cover,ind)=>{
                              if(cover['coverSelected']){
                                  selectedCoverIndex = ind;
                                  selectedCoverName = cover['coverName'];
                              }
                    })
                }
        }

        if(index > 0){
          addonArray.map((ele,ind)=>{
            if((selectedCoverIndex == ind) && (selectedCoverName == ele['bucketName'])){
              let coverIndex = ind;
              let obj ={};
              obj['coverName'] = ele['bucketName'];
              ele['coverDetails'].map((coverDetailsObj,ind2)=>{
                        if(coverDetailsObj.CoverType == 'base'){
                            if(Object.keys(obj).includes('base')){
                              obj['base'].push({
                                                "baseCoverName" : coverDetailsObj.CoverName,
                                                "baseCoverInputValue":0,
                                                "MaxSI":coverDetailsObj.MaxSI,
                                                "MinSI":coverDetailsObj.MinSI,
                                                "MaxSILimit":coverDetailsObj.MaxSILimit,
                                                "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                                "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                                "CvrId":coverDetailsObj.CvrId
                                              });
                            }else{
                              obj['base'] = [];
                              obj['base'].push({
                                                "baseCoverName" : coverDetailsObj.CoverName,
                                                "baseCoverInputValue":0,
                                                "MaxSI":coverDetailsObj.MaxSI,
                                                "MinSI":coverDetailsObj.MinSI,
                                                "MaxSILimit":coverDetailsObj.MaxSILimit,
                                                "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                                "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                                "CvrId":coverDetailsObj.CvrId
                                            });
                            }
                        }else if(coverDetailsObj.CoverType == "addon"){
                            if(Object.keys(obj).includes('addon')){
                              obj['addon'].push({
                                                "addonCoverName" : coverDetailsObj.CoverName,
                                                "addonCoverInputValue":(coverDetailsObj['CoverName'] == "Value Added Services") ? 1 : 0,
                                                "MaxSI":coverDetailsObj.MaxSI,
                                                "MinSI":coverDetailsObj.MinSI,
                                                "MaxSILimit":coverDetailsObj.MaxSILimit,
                                                "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                                "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                                "CvrId":coverDetailsObj.CvrId
                                            });
                            }else{
                              obj['addon'] = [];
                              obj['addon'].push({
                                                "addonCoverName" : coverDetailsObj.CoverName,
                                                "addonCoverInputValue":(coverDetailsObj['CoverName'] == "Value Added Services") ? 1 : 0,
                                                "MaxSI":coverDetailsObj.MaxSI,
                                                "MinSI":coverDetailsObj.MinSI,
                                                "MaxSILimit":coverDetailsObj.MaxSILimit,
                                                "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                                "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                                "CvrId":coverDetailsObj.CvrId
                                              });
                            }
                        }
              })

              // this.newCover(index,obj);
              this.addControlToCovers(addons.length -1,obj);
              for(let key in obj){
                      if(key.includes('addon')){
                        for(let addonCover of obj[`addon`]){
                          this.addAddonCoverControl(0,addons.length -1,addonCover);
                        }
                      }else if(key.includes('base')){
                        for(let baseCover of obj[`base`]){
                          this.addBaseCoverControl(0,addons.length -1,baseCover);
                        }
                      }
              }
              // addons.push(this.fb.group(obj));
              finalAddonArray.push(obj);
            }
            
          })
        }else if(index == 0){
          addonArray.map((ele,ind)=>{
            // if((selectedCoverIndex == ind) && (selectedCoverName == ele['bucketName'])){
              let coverIndex = ind;
              let obj ={};
              obj['coverName'] = ele['bucketName'];
              ele['coverDetails'].map((coverDetailsObj,ind2)=>{
                if(coverDetailsObj.CoverType == 'base'){
                  if(Object.keys(obj).includes('base')){
                    obj['base'].push({
                                      "baseCoverName" : coverDetailsObj.CoverName,
                                      "baseCoverInputValue":0,
                                      "MaxSI":coverDetailsObj.MaxSI,
                                      "MinSI":coverDetailsObj.MinSI,
                                      "MaxSILimit":coverDetailsObj.MaxSILimit,
                                      "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                      "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                      "CvrId":coverDetailsObj.CvrId
                                    });
                  }else{
                    obj['base'] = [];
                    obj['base'].push({
                                      "baseCoverName" : coverDetailsObj.CoverName,
                                      "baseCoverInputValue":0,
                                      "MaxSI":coverDetailsObj.MaxSI,
                                      "MinSI":coverDetailsObj.MinSI,
                                      "MaxSILimit":coverDetailsObj.MaxSILimit,
                                      "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                      "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                      "CvrId":coverDetailsObj.CvrId
                                  });
                  }
              }else if(coverDetailsObj.CoverType == "addon"){
                  if(Object.keys(obj).includes('addon')){
                    obj['addon'].push({
                                      "addonCoverName" : coverDetailsObj.CoverName,
                                      "addonCoverInputValue":(coverDetailsObj['CoverName'] == "Value Added Services") ? 1 : 0,
                                      "MaxSI":coverDetailsObj.MaxSI,
                                      "MinSI":coverDetailsObj.MinSI,
                                      "MaxSILimit":coverDetailsObj.MaxSILimit,
                                      "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                      "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                      "CvrId":coverDetailsObj.CvrId
                                  });
                  }else{
                    obj['addon'] = [];
                    obj['addon'].push({
                                      "addonCoverName" : coverDetailsObj.CoverName,
                                      "addonCoverInputValue":(coverDetailsObj['CoverName'] == "Value Added Services") ? 1 : 0,
                                      "MaxSI":coverDetailsObj.MaxSI,
                                      "MinSI":coverDetailsObj.MinSI,
                                      "MaxSILimit":coverDetailsObj.MaxSILimit,
                                      "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                      "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                      "CvrId":coverDetailsObj.CvrId
                                    });
                  }
              }
              })

              // this.newCover(index,obj);
              this.addControlToCovers(addons.length -1,obj);
              for(let key in obj){
                      if(key.includes('addon')){
                        for(let addonCover of obj[`addon`]){
                          this.addAddonCoverControl(coverIndex,addons.length -1,addonCover);
                        }
                      }else if(key.includes('base')){
                        for(let baseCover of obj[`base`]){
                          this.addBaseCoverControl(coverIndex,addons.length -1,baseCover);
                        }
                      }
              }
              // addons.push(this.fb.group(obj));
              finalAddonArray.push(obj);
            // }
            
          })
        }
       
  }

  createAddonFormChild(index,addonArray){

    // this.addControlToCovers(index,'');
    let addons = this.critiShieldForm.get('memberCoverDetailsChild') as FormArray;
    let addonsAdult = this.critiShieldForm.get('memberCoverDetails') as FormArray;

    this.addMemberCoverControlChild();


    let finalAddonArray = [];
    let selectedCoverIndex;
    let selectedCoverName;

    let selectedAdultCoverLen = addonsAdult.length;
    if(selectedAdultCoverLen){
            let coversLength = addonsAdult.value[0].covers.length;
            if(coversLength>0){
              addonsAdult.value[0]['covers'].map((cover,ind)=>{
                          if(cover['coverSelected']){
                              selectedCoverIndex = ind;
                              selectedCoverName = cover['coverName'];
                          }
                })
            }
    }
    addonArray.map((ele,ind)=>{
      if((selectedCoverIndex == ind) && (selectedCoverName == ele['bucketName'])){
                let coverIndex = ind;
                let obj ={};
                obj['coverName'] = ele['bucketName'];
                ele['coverDetails'].map((coverDetailsObj,ind2)=>{
                          if(coverDetailsObj.CoverType == 'base'){
                              if(Object.keys(obj).includes('base')){
                                obj['base'].push({
                                                "baseCoverName" : coverDetailsObj.CoverName,
                                                "baseCoverInputValue":0,
                                                "MaxSI":coverDetailsObj.MaxSI,
                                                "MinSI":coverDetailsObj.MinSI,
                                                "MaxSILimit":coverDetailsObj.MaxSILimit,
                                                "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                                "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                                "CvrId":coverDetailsObj.CvrId
                                });
                              }else{
                                obj['base'] = [];
                                obj['base'].push({
                                                "baseCoverName" : coverDetailsObj.CoverName,
                                                "baseCoverInputValue":0,
                                                "MaxSI":coverDetailsObj.MaxSI,
                                                "MinSI":coverDetailsObj.MinSI,
                                                "MaxSILimit":coverDetailsObj.MaxSILimit,
                                                "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                                "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                                "CvrId":coverDetailsObj.CvrId
                                });
                              }
                          }else if(coverDetailsObj.CoverType == "addon"){
                              if(Object.keys(obj).includes('addon')){
                                obj['addon'].push({
                                                "addonCoverName" : coverDetailsObj.CoverName,
                                                "addonCoverInputValue":(coverDetailsObj['CoverName'] == "Value Added Services") ? 1 : 0,
                                                "MaxSI":coverDetailsObj.MaxSI,
                                                "MinSI":coverDetailsObj.MinSI,
                                                "MaxSILimit":coverDetailsObj.MaxSILimit,
                                                "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                                "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                                "CvrId":coverDetailsObj.CvrId
                                            });
                              }else{
                                obj['addon'] = [];
                                obj['addon'].push({
                                                "addonCoverName" : coverDetailsObj.CoverName,
                                                "addonCoverInputValue":(coverDetailsObj['CoverName'] == "Value Added Services") ? 1 : 0,
                                                "MaxSI":coverDetailsObj.MaxSI,
                                                "MinSI":coverDetailsObj.MinSI,
                                                "MaxSILimit":coverDetailsObj.MaxSILimit,
                                                "BaseSiPercentage":coverDetailsObj.BaseSiPercentage,
                                                "SliderOptionForSI":coverDetailsObj.SliderOptionForSI,
                                                "CvrId":coverDetailsObj.CvrId
                                            });
                              }
                          }
                })

                // this.newCover(index,obj);
                this.addControlToCoversChild(addons.length -1,obj);
                for(let key in obj){
                        if(key.includes('addon')){
                          for(let addonCover of obj[`addon`]){
                            this.addAddonCoverControlChild(0,addons.length -1,addonCover);
                          }
                        }else if(key.includes('base')){
                          for(let baseCover of obj[`base`]){
                            this.addBaseCoverControlChild(0,addons.length -1,baseCover);
                          }
                        }
                }
                // addons.push(this.fb.group(obj));
                finalAddonArray.push(obj);
      }
              
    })
}

  memberCoverDetails():FormArray{
    return this.critiShieldForm.get('memberCoverDetails') as FormArray;
  }

  memberCoverDetailsChild(): FormArray{
    return this.critiShieldForm.get('memberCoverDetailsChild') as FormArray;
  }

  covers(adultIndex): FormArray{  
    return this.memberCoverDetails().at(adultIndex).get('covers') as FormArray;
    // return this.critiShieldForm.get('covers') as FormArray;
  }

  coversChild(childIndex): FormArray{
    return this.memberCoverDetailsChild().at(childIndex).get('covers') as FormArray;
  }

  

  baseCover(coverIndex,adultIndex): FormArray{
    // return this.memberCoverDetails().at(adultIndex).covers().at(coverIndex).get(`baseAdult${adultIndex+1}`) as FormArray;
    return this.covers(adultIndex).at(coverIndex).get(`base`) as FormArray
  }

  baseCoverChild(coverIndex,childIndex): FormArray{
    // return this.memberCoverDetails().at(adultIndex).covers().at(coverIndex).get(`baseAdult${adultIndex+1}`) as FormArray;
    return this.coversChild(childIndex).at(coverIndex).get(`base`) as FormArray
  }

  addonCover(coverIndex,adultIndex): FormArray{
    return this.covers(adultIndex).at(coverIndex).get(`addon`) as FormArray
  }

  addonCoverChild(coverIndex,childIndex): FormArray{
    return this.coversChild(childIndex).at(coverIndex).get(`addon`) as FormArray
  }

  createMemberCoverDetails():FormGroup{
    return this.fb.group({
      ['covers']:this.fb.array([])
    })
  }

  createMemberCoverDetailsChild():FormGroup{
    return this.fb.group({
      ['covers']:this.fb.array([])
    })
  }

  createCoverForm(adultIndex,val):FormGroup{
    return this.fb.group({
      [`coverName`] : [val?.[`coverName`]],
      [`coverSelected`]:[false],
      [`base`] : this.fb.array([]),
      [`addon`] : this.fb.array([]),
      ['memberName']:[`Adult${adultIndex + 1}`]
    })
  }

  createCoverFormChild(childIndex,val):FormGroup{
    return this.fb.group({
      [`coverName`] : [val?.[`coverName`]],
      [`coverSelected`]:[false],
      [`base`] : this.fb.array([]),
      [`addon`] : this.fb.array([]),
      ['memberName']:[`Child${childIndex + 1}`]
    })
  }

  createBaseCover(val) : FormGroup{
    return this.fb.group({
        [`baseCoverSelected`] :[true],
        [`baseCoverName`] :(val?.baseCoverName),
        [`baseCoverInputValue`]:(val?.baseCoverInputValue),
        ['SliderOptionForSI'] : [val?.SliderOptionForSI],
        ['MinSI'] :[val?.MinSI],
        ['MaxSI'] : [val?.MaxSI],
        ['BaseSiPercentage']:[val?.BaseSiPercentage],
        ['MaxSILimit'] : [val?.MaxSILimit],
        ['CvrId'] : [val?.CvrId]
    })
  }

  createBaseCoverChild(val) : FormGroup{
    return this.fb.group({
        [`baseCoverSelected`] :[true],
        [`baseCoverName`] :(val?.baseCoverName),
        [`baseCoverInputValue`]:(val?.baseCoverInputValue),
        ['SliderOptionForSI'] : [val?.SliderOptionForSI],
        ['MinSI'] :[val?.MinSI],
        ['MaxSI'] : [val?.MaxSI],
        ['BaseSiPercentage']:[val?.BaseSiPercentage],
        ['MaxSILimit'] : [val?.MaxSILimit],
        ['CvrId'] : [val?.CvrId]
    })
  }

  createAddonCover(val) : FormGroup{
    return this.fb.group({
        [`addonCoverSelected`]:[(val?.addonCoverName == "Value Added Services") ? true : false],
        [`addonCoverName`] :(val?.addonCoverName),
        [`addonCoverInputValue`]:(val?.addonCoverInputValue),
        ['SliderOptionForSI'] : [val?.SliderOptionForSI],
        ['MinSI'] :[val?.MinSI],
        ['MaxSI'] : [val?.MaxSI],
        ['BaseSiPercentage'] : [val?.BaseSiPercentage],
        ['MaxSILimit']:[val?.MaxSILimit],
        ['CvrId'] : [val?.CvrId]
    })
  }

  createAddonCoverChild(val) : FormGroup{
    return this.fb.group({
          [`addonCoverSelected`]:[(val?.addonCoverName == "Value Added Services") ? true : false],
          [`addonCoverName`] :(val?.addonCoverName),
          [`addonCoverInputValue`]:(val?.addonCoverInputValue),
          ['SliderOptionForSI'] : [val?.SliderOptionForSI],
          ['MinSI'] :[val?.MinSI],
          ['MaxSI'] : [val?.MaxSI],
          ['BaseSiPercentage'] : [val?.BaseSiPercentage],
          ['MaxSILimit']:[val?.MaxSILimit],
          ['CvrId'] : [val?.CvrId]
    })
  }

  addMemberCoverControl(){
    this.memberCoverDetails().push(this.createMemberCoverDetails())
  }

  addMemberCoverControlChild(){
    this.memberCoverDetailsChild().push(this.createMemberCoverDetailsChild())
  }

  addControlToCovers(adultIndex,val){
    this.covers(adultIndex).push(this.createCoverForm(adultIndex,val))
  }

  addControlToCoversChild(childIndex,val){
    this.coversChild(childIndex).push(this.createCoverFormChild(childIndex,val))
  }

  addBaseCoverControl(cInd,aInd,obj){
    this.baseCover(cInd,aInd).push(this.createBaseCover(obj))
  }

  addBaseCoverControlChild(cInd,childIndex,obj){
    this.baseCoverChild(cInd,childIndex).push(this.createBaseCoverChild(obj))
  }

  addAddonCoverControl(cInd,aInd,obj){
    this.addonCover(cInd,aInd).push(this.createAddonCover(obj))
  }

  addAddonCoverControlChild(cInd,childIndex,obj){
    this.addonCoverChild(cInd,childIndex).push(this.createAddonCoverChild(obj))
  }

  checkCondition(data){
    let checkCount = 0;
    for(let key in data){
          if(key == 'coverNameAdult1'){
            checkCount+= 1;
          }
    }
    return checkCount ? true : false;
  }

  memberTypeSelected(index){
     this.tabIndex = index.index;
  }

  memberTypeSelectedChild(index){
    this.tabIndexChild = index.index;

 }

  constructMemberDetails(type?,index?,memberInd?,flag?) {
    let adultLen = this.critiShieldForm.get('adultDetails').getRawValue().length;
    // this.tabGroup = [];
    // if (this.healthFormData.quoteFormData.adultDetails.length > 0) {

    if(this.tabGroup.length){
                let flag = true;
          // let flagValue = new Promise((resolve,reject)=>{
          //                   this.tabGroup.map((ele,ind)=>{
          //                     if(ele['tabLabel'] == `${type} + ${index+1}`){
          //                         flag = false;
          //                         return resolve(flag);
          //                     }
          //                   })
          //                   return reject(flag)
          // })

                    this.tabGroup.forEach((ele,ind)=>{
                      let values = Object.values(ele);
                      let memberType = `${type}${index+1}`;
                      if(values.includes(memberType)){
                          flag = false;

                          // return resolve(flag);
                      }
                    })

                    if(flag){
                      this.tabGroup.push({
                        'show' : true,
                        'tabLabel': `${type}` + (index + 1)
                      })
                      this.createAddonForm(memberInd,this.coversArray);
                    }
    }else{
        this.tabGroup.push({
          'show': true,
          'tabLabel': `${type}` + (index + 1)
        })
    }
  }


  constructMemberDetailsChild(type?,index?,memberInd?,flag?) {
    let childLen = this.critiShieldForm.get('childDetails').getRawValue().length;
    // this.tabGroup = [];
    // if (this.healthFormData.quoteFormData.adultDetails.length > 0) {

    if(childLen){
                let flag = true;
          // let flagValue = new Promise((resolve,reject)=>{
          //                   this.tabGroup.map((ele,ind)=>{
          //                     if(ele['tabLabel'] == `${type} + ${index+1}`){
          //                         flag = false;
          //                         return resolve(flag);
          //                     }
          //                   })
          //                   return reject(flag)
          // })

                    this.tabGroupChild && this.tabGroupChild.map((ele,ind)=>{
                      let values = Object.values(ele);
                      let memberType = `${type}${index+1}`;
                      if(values.includes(memberType)){
                          flag = false;

                          // return resolve(flag);
                      }
                    })

                    if(flag){
                      this.tabGroupChild.push({
                        'show' : true,
                        'tabLabel': `${type}` + (index + 1)
                      })
                      this.createAddonFormChild(memberInd,this.coversArray);
                    }
    }
    
    
    // else{
    //     this.tabGroupChild.push({
    //       'show': true,
    //       'tabLabel': `${type}` + (index + 1)
    //     })
    // }


    // if(this.childDetails.length == 1){
    //     this.tabGroupChild.map((ele,ind)=>{
    //             if(ele['tabLabel'] == 'Adult2'){
    //                 ele['show'] = false;
    //             }
    //     })
    // }

      // this.tabGroup.push({
      //   'tabLabel': `${type}` + (index + 1),
      //   'disabled': false,
      //   'hide': false,
      //   'selected': 0,
      //   'imgPath': "../../../../assets/icons/adults.png",
      //   'memberDetails': []
      // })
    // }
  }

  adultSelectedCover(adultIndex,CoverIndex,cover){
    this.selectedAdultIndex = adultIndex;
    this.masterData.PLAN_DETAILS.forEach((element,i) => {
      if(element.PLAN_NAME===cover.value.coverName){
        this.selectedCoverIndex = i;
      }
    });
  
        let formData = this.critiShieldForm.getRawValue();
        this.covers(adultIndex).at(CoverIndex).get('coverSelected').setValue(true);
        let coversLength = this.covers(adultIndex).length;
        let addons = this.critiShieldForm.get('memberCoverDetails') as FormArray;
        for(let i = 0 ; i<coversLength; i++){
              if(i != CoverIndex){
               this.covers(adultIndex).at(i).get('coverSelected').setValue(false);
              }
        }
        if((addons.length>1) && (adultIndex == 0)){
          this.memberCoverDetails().removeAt(addons.length - 1);
          this.createAddonForm(1,this.coversArray);
        }

        if(this.childDetails.length){
          let childLen = this.childDetails.length;
          for(let j = childLen -1 ; j>=0  ; j--){
            this.memberCoverDetailsChild().removeAt(j);
          }
          for(let i = 0 ; i< childLen ; i++){
                  this.createAddonFormChild(i+1,this.coversArray);
          }
        }

        let baseLen = cover.value['base'].length;
        let adultAge = this.calculateAge(formData['adultDetails'][adultIndex][`dobAdult${adultIndex + 1}`]);
        let adultAnnualIncome = formData['adultDetails'][adultIndex][`adultIncome${adultIndex + 1}`];
        let adultOccupation = formData['adultDetails'][adultIndex][`adultOccupation${adultIndex + 1}`];

        if(baseLen > 1){
          let incomeBasedSI = this.rangeModifier(adultAge,adultAnnualIncome,adultOccupation,adultIndex);
          this.maxSIperCover = 20000000 / baseLen
          this.maxSIperCover = this.maxSIperCover > incomeBasedSI ? incomeBasedSI : this.maxSIperCover;
          for(let i = 0 ; i < baseLen ; i++){
            this.baseCover(CoverIndex,adultIndex).at(i).get('MaxSI').setValue(this.maxSIperCover);
          }
          // const newOptions: Options = Object.assign({}, this.baseOptions);
          // newOptions.ceil =  this.maxSIperCover;
          // newOptions.stepsArray = this.createStepFunction(this.maxSIperCover)
          // this.baseOptions = newOptions;
        }else{
          let incomeBasedSI = this.rangeModifier(adultAge,adultAnnualIncome,adultOccupation,adultIndex);
          this.maxSIperCover = 10000000
          this.maxSIperCover = this.maxSIperCover > incomeBasedSI ? incomeBasedSI : this.maxSIperCover;
          // this.covers(adultIndex).at(CoverIndex).get('MaxSi').setValue(this.maxSIperCover);
          this.baseCover(CoverIndex,adultIndex).at(baseLen - 1).get('MaxSI').setValue(this.maxSIperCover);

          // const newOptions: Options = Object.assign({}, this.baseOptions);
          // newOptions.ceil = this.maxSIperCover;
          // newOptions.stepsArray = this.createStepFunction(this.maxSIperCover)
          // this.baseOptions = newOptions;
        }
        

  }

  childSelectedCover(childIndex,CoverIndex,cover){
    this.coversChild(childIndex).at(CoverIndex).get('coverSelected').setValue(true);
    let formData = this.critiShieldForm.getRawValue();
    let coversLength = this.coversChild(childIndex).length;
    let addons = this.critiShieldForm.get('memberCoverDetails') as FormArray;
    this.coversChild(childIndex).at(0).get('coverSelected').setValue(true);

    let baseLen = cover.value['base'].length;
        let childAge = this.calculateAge(formData['childDetails'][childIndex][`dobChild${childIndex + 1}`]);
        let adultAnnualIncome = formData['adultDetails'][0][`adultIncome1`];
        let childOccupation = "No Active Source Of Income";
     
        
        if(baseLen > 1){
          let incomeBasedSI = this.rangeModifier(childAge,adultAnnualIncome,childOccupation,childIndex);
          this.maxSIperCover = 20000000 / baseLen
          this.maxSIperCover = this.maxSIperCover > incomeBasedSI ? incomeBasedSI : this.maxSIperCover;
          for(let i = 0 ; i < baseLen ; i++){
            this.baseCoverChild(CoverIndex,childIndex).at(i).get('MaxSI').setValue(this.maxSIperCover);
          }
          // const newOptions: Options = Object.assign({}, this.baseOptions);
          // newOptions.ceil =  this.maxSIperCover;
          // newOptions.stepsArray = this.createStepFunction(this.maxSIperCover)
          // this.baseOptions = newOptions;
        }else{
          let incomeBasedSI = this.rangeModifier(childAge,adultAnnualIncome,childOccupation,childIndex);
          this.maxSIperCover = 10000000
          this.maxSIperCover = this.maxSIperCover > incomeBasedSI ? incomeBasedSI : this.maxSIperCover;
          // this.covers(adultIndex).at(CoverIndex).get('MaxSi').setValue(this.maxSIperCover);
          this.baseCoverChild(0,childIndex).at(baseLen - 1).get('MaxSI').setValue(this.maxSIperCover);
        }
    

}

modifyDate(data){
  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let date = data.split('-');
  let index = monthNames.indexOf(date[1]);
  let month;
  if(index < 9){
    month = '0' + (index + 1);
  }else{
    month = index + 1;
  }
  let finalDate = date[2] + '-' + month + '-' + date[0]; 
  return finalDate;
}

calculateAge(DOB) {
  var today = new Date();
  var birthDate = new Date(DOB);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }    
  return age;
}

rangeModifier(age, income,occupation,adultIndex) {  
  // if (this._utilityService.isUndefinedORNull(income)) {
  //   this._alertService.presentAlert("Income multiplier not configured")
  //   return 10000000
  // }
  // else 
  if (age < 18){
            return this.primaryMemberSIValue * 0.5 >= 1000000 ? 1000000 : this.primaryMemberSIValue * 0.5;
  }else if (adultIndex > 0) { // for second adult
            if (occupation === "No Active Source of Income"){
                      return this.primaryMemberSIValue * 0.5 >= 1500000 ? 1500000 : this.primaryMemberSIValue * 0.5;
            }else{
                      return this.adultSumInsured(age,income);
            }
  }else{
            return this.adultSumInsured(age,income);
  }
}
adultSumInsured(age,income){
  if (age >=18 && age <= 50){
    let lowerLimit = this.annualIncomeCritisield.find(element=> element.Name === income)?.INCOME_LOWER_LIMIT
    lowerLimit = JSON.parse(lowerLimit)
    let SILookupRange = this.siLookUpRangeCritishield[0].Lookup
    let SI = SILookupRange.find(ele => ele.Income == lowerLimit)?.SI
    if (this._utilityService.isUndefinedORNull(SI))
      SI = 10000000
    else 
      return SI
  }
  else if (age >=51 && age <= 65){
    let lowerLimit = this.annualIncomeCritisield.find(element=> element.Name === income)?.INCOME_LOWER_LIMIT
    lowerLimit = JSON.parse(lowerLimit)
    let SILookupRange = this.siLookUpRangeCritishield[1].Lookup
    let SI = SILookupRange.find(ele => ele.Income == lowerLimit)?.SI
    if (this._utilityService.isUndefinedORNull(SI))
      SI = 10000000
    else 
      return SI
  }
  else {
    let popupData: PopupModal = {
      popupType: popupType.generic,
      imgName: popupImgPath.alertGif,
      header: popupHeader.error,
      description: 'Age not configured',
      buttonLabel: popupButton.ok,
      buttonAction: popupButtonAction.close
    }
    this.popupService.openGenericPopup(popupData);
    // return
    // alert("Age not configured")
    return 100000;
  }
}

getQuoteSummaryData(){
  let adultArray = this.csFormData.quoteFormData['memberCoverDetails'];
  let childArray = this.csFormData.quoteFormData['memberCoverDetailsChild'];
  this.getCoverList(adultArray,'Adult');
  this.getCoverList(childArray,'Child');
}
getCoverList(data,flag){
  if(data.length){
    data.map((member,memberInd)=>{
          if(member['covers'].length){
            member['covers'].map((cover,coverIndex)=>{
                if(cover['coverSelected']){
                    
                    if(flag == 'Adult'){
                      this.csFormData.quoteFormData.adultDetails[memberInd]['CoverDetails'] = cover;
                      this.csFormData.quoteFormData.adultDetails[memberInd]['RiskCategory'] = ((this.csFormData.quoteFormData['riskCategory'][memberInd][`adult${memberInd+1}`] == "Risk Category 1") ? 1 :(this.csFormData.quoteFormData['riskCategory'][memberInd][`adult${memberInd+1}`] == "Risk Category 2") ? 2 : 3);
                    }else if(flag == 'Child'){
                      this.csFormData.quoteFormData.childDetails[memberInd]['CoverDetails'] = cover;
                    }
                }
              })
          }  
    })
  }


  if(flag == 'Adult'){
    this.csFormData.quoteFormData['adultDetails'].forEach((ele,ind)=>{
      this.csFormData.quoteFormData['quoteSummary'].push(ele);
    })
  }else if(flag == 'Child'){
    this.csFormData.quoteFormData['childDetails'].forEach((ele,ind)=>{
      this.csFormData.quoteFormData['quoteSummary'].push(ele);
    })
  }

}

sliderValue(value,memberIndex,coverIndex,subCoverIndex,baseCoverDetails,coverDetails,type,memberType){


  let val = value.target.value;
  if(memberType == 'adult' && memberIndex == 0){
    this.primaryMemberSIValue = val;
  }

  let addonDetails =  coverDetails.value.addon;
  let coverName = coverDetails.value.coverName;
  let baseDetails = coverDetails.value.base;
  if((type == 'base') && (baseDetails.length > 1)){
    for(let i = 0 ; i< baseDetails.length; i++){
          if(i != subCoverIndex){
              if(memberType == 'adult'){
                this.baseCover(coverIndex,memberIndex).at(i).get('baseCoverInputValue').setValue(val);
              }else if(memberType == 'child'){
                this.baseCoverChild(coverIndex,memberIndex).at(i).get('baseCoverInputValue').setValue(val);
              }
          }
    }
  }
  

  this.coversArray.forEach((ele,ind)=>{
      if(ele['bucketName'] == coverName){
                  ele['coverDetails'].map((cover,coverInd)=>{
                      if(cover['CoverType'] == 'addon'){
                          let newCeil;
                          if(cover['MaxSI'] == 0){  
                            newCeil = cover['BaseSiPercentage'] * val;
                            // this.addonCover(coverIndex,memberIndex).at(ind).setValue(newMaxSI);
                            if(cover['MaxSILimit'] !== 0){
                              newCeil = newCeil > cover['MaxSILimit'] ? cover['MaxSILimit'] : newCeil
                            }
    
                            if (newCeil < 100000 && newCeil >= 50000){
                              newCeil = 100000
                            }
                            if (newCeil < 50000){
                              newCeil = 0;
                            }

                            if(cover['CoverName'] !== "Personal Accident"){
                              let addonIndex;
                              addonDetails.map((addon,addonInd)=>{
                                    if(cover['CvrId'] == addon['CvrId']){
                                      addonIndex = addonInd;
                                      if(memberType == 'adult'){
                                          this.addonCover(coverIndex,memberIndex).at(addonIndex).get('addonCoverInputValue').setValue(newCeil);
                                      }else if(memberType == 'child'){
                                          this.addonCoverChild(coverIndex,memberIndex).at(addonIndex).get('addonCoverInputValue').setValue(newCeil);
                                      }
                                    }
                              })
                            }

                            if(cover['CoverName'] == 'Personal Accident'){

                                let addonIndex;
                                addonDetails.map((addon,addonInd)=>{
                                      if(cover['CvrId'] == addon['CvrId']){
                                        addonIndex = addonInd;
                                        if(memberType == 'adult'){
                                          this.addonCover(coverIndex,memberIndex).at(addonIndex).get('MaxSI').setValue(newCeil);
                                        }else if(memberType == 'child'){
                                          this.addonCoverChild(coverIndex,memberIndex).at(addonIndex).get('MaxSI').setValue(newCeil);
                                        }
                                        // if(val < 100000){
                                        //   this.addonCover(coverIndex,memberIndex).at(addonIndex).get('addonCoverInputValue').setValue(0)

                                        // }else if(val === 100000){
                                        //   this.addonCover(coverIndex,memberIndex).at(addonIndex).get('').setValue(100000)
        
                                        // }else if(val < 4){
                                        //   this.addonCover(coverIndex,memberIndex).at(addonIndex).get('').setValue(val)
                                        // }
                                      }
                                })
                                
                            }
                          }
                      }     
            })
      }
  })

 
  
}

checkCoverSelection(){
  let myFormValue = this.critiShieldForm.getRawValue();
  let showCoverErrorMsg = true;
  // let showSumInsuredErrorMsg = false;
  let errorMsgDescripton = 'Please select cover for Member 1';
  let memberInd;
  if(myFormValue.memberCoverDetails.length>0){
    myFormValue.memberCoverDetails.map((memberObj,memberIndex)=>{
            if(memberIndex == 0 ){
              memberObj['covers'].map((coverObj,coverInd)=>{
                if(coverObj.coverSelected){
                    showCoverErrorMsg = false;
                    coverObj['base'].map((baseCover,baseCoverInd)=>{
                      if(baseCover['baseCoverInputValue']<1){
                        memberInd = memberIndex
                        showCoverErrorMsg = true;
                        errorMsgDescripton = `Please select an SI value greater than 0 for Member ${memberInd + 1}`
                      }
                  })
                }
              })  
            }
           
    });
    return {flag :showCoverErrorMsg, desc:errorMsgDescripton};

    // if(showErrorMsg){

    //   let popupData: PopupModal = {
    //     popupType: "confirmation",
    //     imgName: '',
    //     header:"Please select atleast one cover for member" ,
    //     description: '',
    //     buttonLabel: '',
    //     buttonAction: ''
    //   }
    //   this.popupService.openGenericPopup(popupData);
    //         // event.pre;
    //         // event.stopPropagation();
    //       //    this.snackBar.open(`Please select atleast one cover for member`, 'Ok', {
    //       //   duration: 2000,
    //       //   verticalPosition: 'bottom',
    //       //   horizontalPosition: 'center',
    //       // });
    // }
  }
   return {flag :showCoverErrorMsg, desc:errorMsgDescripton};;
}

patchCoverForCSAdult(data,index,type){
    if(data.length){
        if(type == 'Adult'){
          this.tabGroup = [];
        }else if(type == 'Child'){
          this.tabGroupChild = []
        }
        data.map((member,memberIndex)=>{
            if(type == 'Adult'){
              this.tabGroup.push({
                'show': true,
                'tabLabel': type + (memberIndex + 1)
              })
            }else if(type == 'Child'){
              this.tabGroupChild.push({
                'show': true,
                'tabLabel': type + (memberIndex + 1)
              })
            }
           
            if(index == memberIndex){
              
              if(type == 'Adult'){
                this.addMemberCoverControl();
              }else if(type == 'Child'){
                this.addMemberCoverControlChild();
              }
              member['covers'].map((cover,coverIndex)=>{
                if(type == 'Adult'){
                  this.addControlToCovers(memberIndex,{});
                }else if(type == 'Child'){
                  this.addControlToCoversChild(memberIndex,{});
                }
                  for(let key in cover){
                        if(key == 'base' || key == 'addon'){
                            cover[key].map((subCover,subCoverIndex)=>{
                                  if(key == 'base'){
                                    if(type == 'Adult'){
                                      this.addBaseCoverControl(coverIndex,memberIndex,{})
                                    }else if(type == 'Child'){
                                      this.addBaseCoverControlChild(coverIndex,memberIndex,{})
                                    }
                                  }else if(key == 'addon'){
                                    if(type == 'Adult'){
                                      this.addAddonCoverControl(coverIndex,memberIndex,{})
                                    }else if(type == 'Child'){
                                      this.addAddonCoverControlChild(coverIndex,memberIndex,{}) 
                                    }
                                  }
                                  for(let subKey in subCover){
                                        if(key == 'base'){
                                            if(type == 'Adult'){
                                              if(index == 0 && (cover['coverSelected']) && (subKey == 'baseCoverInputValue')){
                                                  this.primaryMemberSIValue = subCover[subKey];
                                              }
                                              this.baseCover(coverIndex,memberIndex).at(subCoverIndex).get(subKey).setValue(subCover[subKey]);
                                            }else if(type == 'Child'){
                                              this.baseCoverChild(coverIndex,memberIndex).at(subCoverIndex).get(subKey).setValue(subCover[subKey]);  
                                            }
                                        }else if(key == 'addon'){
                                          if(type == 'Adult'){
                                            this.addonCover(coverIndex,memberIndex).at(subCoverIndex).get(subKey).setValue(subCover[subKey]);
                                          }else if(type == 'Child'){
                                            this.addonCoverChild(coverIndex,memberIndex).at(subCoverIndex).get(subKey).setValue(subCover[subKey]);
                                          }
                                        }
                                  }
                                 
                            })
                        }else{
                          if(type == 'Adult'){
                            if(key == 'coverSelected' && cover[key]){
                              this.selectedCoverIndex = coverIndex;
                              this.selectedAdultIndex = memberIndex;
                            }
                            this.covers(memberIndex).at(coverIndex).get(key).setValue(cover[key]);
                          }else if(type == 'Child'){
                            this.coversChild(memberIndex).at(coverIndex).get(key).setValue(cover[key]);
                          }
                        }
                  }
              })
            }
           
        })
    }
}

  ngOnDestroy() {
    this.unsubscribeSubscription();
  }

}