<header class="container-fluid fixed-top">
    <div class="row">
        <div class="col-6 dash_humberger">
            <span class="humberspan" id="hamburger" *ngIf="isDeveloper" (click)="toggleSidenav()">
                <img src="../../../assets/icons/hamburger_menu.svg" alt="" style="cursor: pointer;">
            </span>
            <h1>
                <a title="ICICI Lombard" class="logo" style="cursor: pointer;">ICICI Lombard</a>
            </h1>
        </div>
        <div class="col-6" style="display: flex; justify-content: flex-end;" *ngIf="showHeader && !isDeveloper && showHamburger">
            <div class="profile-menu header-home" *ngIf="!hideDashboard && showHomeIcon">
                <button class="profile-button home-button" (click)="navigateToDashboard()">
                    <img src="../../../assets/images/dashboard/header_home.svg" style="width: 27px;" alt="">
                </button>
            </div>
            <lib-notification-ui [creds]="credential" [updatedData]="updatedNotificationData" [isProfileOpen]="showMenu"
                ></lib-notification-ui>
            <!-- PROFILE SECTION -->
            <div class="profile-menu">
                <button class="profile-button" (click)="toggleMenu()">
                    <img src="../../../assets/images/dashboard/SvgjsG1102.svg" alt="">
                </button>
                <ul class="options" *ngIf="showMenu" (click)="closeMenu()">
                    <li>
                        <table>
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr>
                                <td><img src="../../../assets/images/dashboard/Group 168403.svg" alt=""></td>
                                <td><b>Hi, {{agentInfo?.AgentName ? agentInfo?.AgentName: ''}}</b>
                                    <br>{{this.imid}}</td>
                            </tr>
                        </table>
                    </li>
                    <mat-divider *ngIf="!hideDashboard"></mat-divider>
                    <li (click)="navigateToDashboard()" *ngIf="!hideDashboard && showHamburger" class="d-block d-md-none">
                        <table>
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr>
                                <td><img src="../../../assets/images/dashboard/SvgjsG1084.svg" alt=""></td>
                                <td>Dashboard</td>
                            </tr>
                        </table>
                    </li>
                    <mat-divider></mat-divider>
                    <li (click)="logout()">
                        <table>
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr>
                                <td><img src="../../../assets/images/dashboard/logout-svgrepo-com.svg" alt=""></td>
                                <td>Logout</td>
                            </tr>
                        </table>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>
<app-sidebar *ngIf="openSidenav" #sidebar></app-sidebar>