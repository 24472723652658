import { Injectable } from '@angular/core';

import { GenericService } from '../services/generic.service';
import { environment } from '../../../environments/environment';
import { SummitReportMasterResponse } from '../interface/summit-report';

@Injectable({
  providedIn: 'root'
})
export class SummitReportService {

  constructor(private generic: GenericService) { }

  readonly baseURI = `${environment.baseURL}Wrapper/GetSummitReportRedirectionLink`;
  readonly summitData = `${environment.baseURL}Wrapper/GetSummitReportData`;

  getSummitData(data) {
    return this.generic.genericService<SummitReportMasterResponse>(this.summitData, data);
  }

  getRedirectionUrlApi() {
    return this.generic.genericService(this.baseURI, {});
  }
}
