import { DatePipe } from '@angular/common';
import { Component, OnDestroy, Renderer2, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { PaymentService } from 'src/app/shared/proxy-services/payment.service';
import { PaymodePaymentService } from 'src/app/shared/proxy-services/paymode-payment.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { DeclarationsService } from 'src/app/shared/services/declarations.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { SaveProposalDetails1RequestPayload ,SaveEditCustomerRequestPayload} from 'src/app/shared/interface/travel';
import { TravelDataStorageService } from '../travel.service';
import { TravelDataStorage, TravelModal } from '../travel.modal';
import { Constants } from 'src/app/shared/constants/constants';
import { HAPModal } from '../../hap/hap-modal';
import { TravelService } from 'src/app/shared/services/travel.service';
import { MatSelect } from '@angular/material/select';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { healthMembers, insuredDiseaseID, remarkList } from 'src/app/shared/enums/healthEnums';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { AgentMasterStructure } from 'src/app/shared/interface/agentV1';
import { PaymentStatusRequestPayload } from 'src/app/shared/interface/payment';
import { popupType, popupImgPath, popupHeader, popupButton, popupButtonAction } from 'src/app/shared/enums/popupEnums';

@Component({
  selector: 'app-travel-summary-details',
  templateUrl: './travel-summary-details.component.html',
  styleUrls: ['./travel-summary-details.component.scss'],
  providers: [DatePipe]
})
export class TravelSummaryDetailsComponent implements OnDestroy {

  TravelSummary: FormGroup;
  private subscription: Subscription[] = [];
  invalid: boolean = false;
  travelFormData: TravelDataStorage;
  errorPopupData: PopupModal;
  hapDataModal: HAPModal = new HAPModal();
  SaveProposalDetail: any;
  rnAgent: any;
  isBMIValid: boolean;
  MemberDetailsData = [];
  MemberData = [{
    'applicantRelationship': '',
    'dob': '',
    'passportNumber': '',
    'FullName': '',
    'relationshipWithNominee': '',
    'nomineeName': '',
  }]

  channelData: ChannelData;
  travelDataModal: TravelModal = new TravelModal(); //travel data modal
  agentDetails: AgentMasterStructure;

  constructor(private fb: FormBuilder,
    private storageService: StorageService,
    public utilitySerice: UtilityService,
    public router: Router,
    private popupService: PopupService,
    private agentV1Service: AgentV1Service,
    public paymentService: PaymentService,
    private customStepperService: CustomStepperService,
    private renderer: Renderer2,
    private utilityService: UtilityService,
    private paymode_payment_service: PaymodePaymentService,
    private declarationService: DeclarationsService,
    private travelDataStorageService: TravelDataStorageService,
    private travelService: TravelService,
  ) {
  }


  ngOnInit() {
    this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);
    window.scrollTo(0, 0);
    this.createForm();
    this.onLoadSteps();
    setTimeout(() => {
      this.constructInsuredMembers();
    }, 0);
    this.subscription.push(this.popupService.paymentValue$.subscribe(x => {
      if (x == 'PaymentLink') {
        this.travelFormData = this.travelDataStorageService.getTravelDataStorage();
      }
      this.proposalCreation(x);
    }))
  }

  onLoadSteps() {
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    this.travelFormData = this.travelDataStorageService.getTravelDataStorage();
    this.agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.rnAgent = this.agentDetails.AgentName;
    this.travelFormData.quoteDataSet = true;
    this.travelFormData.insuredDataSet = true;
    this.travelFormData.kycDataSet = true;
  }

  createForm() {
    this.TravelSummary = this.fb.group({
      medicalDeclaration1: [true, [Validators.required]],
      medicalDeclaration2: [true, [Validators.required]],
    })
    this.travelFormData = this.travelDataStorageService.getTravelDataStorage();
  }



  constructInsuredMembers() {
    if (this.travelFormData.quoteFormData.totalMemberData.length > 0) {
      this.travelFormData.quoteFormData.totalMemberData.forEach((data, index) => {
        let tempMemberObj: any = {}
        this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'].forEach(controlData => {

          if (controlData.controlName == "title" || controlData.controlName == "applicantRelationship" || controlData.controlName == "relationshipWithNominee") {
            tempMemberObj[controlData.controlName] = controlData.drpName;
            tempMemberObj[`${controlData.controlName}_id`] = controlData.drpId;
          } else {
            tempMemberObj[controlData.controlName] = controlData[controlData.controlName];
          }
        })
        this.MemberDetailsData.push(tempMemberObj);
      })
    }
  }

  paymentModeSelected(buttonData: EmitButtonData) {
    switch (buttonData['label']) {
      case 'Payment Link': {
          let popupData: PopupModal = {
            popupType: popupType.travelPaymentLink,
            imgName: popupImgPath.successGif,
            header: popupHeader.success,
            description: '',
            buttonLabel: popupButton.ok,
            buttonAction: popupButtonAction.emitSendPaymentLink
          }
          this.popupService.openGenericPopup(popupData);
        break;
      }
      case 'Pay Now': {
        this.proposalCreation('PayNow');
        break;
      }
      case 'Pay Later': {
        this.proposalCreation('PayLater');
        break;
      }
      default:{
        break;
      }
    }
  }

  proposalCreation(payMode: string) {
    if (this.TravelSummary.controls['medicalDeclaration1'].value && this.TravelSummary.controls['medicalDeclaration2'].value) {
      this.createCustomer(payMode);
    }
  }

  createCustomer(paymode) {
    let request2: SaveEditCustomerRequestPayload = this.constructSaveditCustomerRequest();

    this.subscription.push(this.travelService.saveEditcustomer(request2).subscribe({
      next: saveEditResponse => {
        if (saveEditResponse.StatusCode == Constants.statusCode_success) {
          this.setsaveEditData(saveEditResponse);
          this.commonProposal(paymode);
        }
      },
      error: error => {
      }
    }))
  }

  constructSaveditCustomerRequest() {
    let title = this.travelDataModal.title.filter(x => x.value == this.travelFormData.kycFormData.applicantDetails.applicantTitle);
    let gstDetails = this.travelFormData.kycFormData.otherDetails;
    let isPermanentAsPresent = this.travelFormData.kycFormData.permanentAddress.isPermanentAddressSame;
    let isUINApplicable = gstDetails.isGstRegistered == 'Yes' ? this.travelFormData.kycFormData.otherDetails.isGstApplicable == 'UIN' : false
    let isGSTINApplicable = gstDetails.isGstRegistered == 'Yes' ? this.travelFormData.kycFormData.otherDetails.isGstApplicable == 'GSTIN' : false
    let request2: any = {
      CustomerID: 0,
      HasAddressChanged: true,
      SetAsDefault: true,
      TitleText: title[0].label,
      TitleValue: this.travelFormData.kycFormData.applicantDetails.applicantTitle,
      Name: this.travelFormData.kycFormData.applicantDetails.applicantFullName,
      DateOfBirth: this.utilityService.getFormattedDate(this.travelFormData.kycFormData.applicantDetails.applicantDateOfBirth, 'dd-MMM-yyyy'),
      MaritalStatusValue: 1,
      MaritalStatusText: "SINGLE",
      OccupationValue: 0,
      OccupationText: "",
      AnnualIncomeValue: 0,
      AnnualIncomeText: "",
      IdentityProofText: "",
      IdentityNumber: "",
      IdentityProofValue: 0,
      PresentAddrLine1: !isPermanentAsPresent ? this.travelFormData.kycFormData.correspondenceAddress.correspondenceAddress1 : this.travelFormData.kycFormData.permanentAddress.permanentAddress1,
      PresentAddrLine2: !isPermanentAsPresent ? this.travelFormData.kycFormData.correspondenceAddress.correspondenceAddress2 : this.travelFormData.kycFormData.permanentAddress.permanentAddress2,
      PresentAddrLandmark: !isPermanentAsPresent ? this.travelFormData.kycFormData.correspondenceAddress.correspondenceLandmark : this.travelFormData.kycFormData.permanentAddress.permanentLandmark,
      PresentAddrCityText: !isPermanentAsPresent ? this.travelFormData.kycFormData.correspondenceAddress.correspondenceCity : this.travelFormData.kycFormData.permanentAddress.permanentCity,
      PresentAddrCityValue: !isPermanentAsPresent ? this.travelFormData.kycFormData.correspondenceAddress.correspondenceCityId : this.travelFormData.kycFormData.permanentAddress.permanentCityId,
      PresentAddrStateValue: !isPermanentAsPresent ? this.travelFormData.kycFormData.correspondenceAddress.correspondenceStateId : this.travelFormData.kycFormData.permanentAddress.permanentStateId,
      PresentAddrStateText: !isPermanentAsPresent ? this.travelFormData.kycFormData.correspondenceAddress.correspondenceState : this.travelFormData.kycFormData.permanentAddress.permanentState,
      PresentAddrPincode: !isPermanentAsPresent ? this.travelFormData.kycFormData.correspondenceAddress.correspondencePincode : this.travelFormData.kycFormData.permanentAddress.permanentPincode,
      EmailAddress: this.travelFormData.kycFormData.permanentAddress['applicantEmailAddress'],
      MobileNumber: this.travelFormData.kycFormData.permanentAddress['applicantMobileNumber'],
      LandlineNumber: null,
      PANNumber: "",
      isPermanentAsPresent: isPermanentAsPresent,
      PermanentAddrLine1: this.travelFormData.kycFormData.permanentAddress.permanentAddress1,
      PermanentAddrLine2: this.travelFormData.kycFormData.permanentAddress.permanentAddress2,
      PermanentAddrLandmark: this.travelFormData.kycFormData.permanentAddress.permanentLandmark,
      PermanentAddrPincode: this.travelFormData.kycFormData.permanentAddress.permanentPincode,
      PermanentAddrStateText: this.travelFormData.kycFormData.permanentAddress.permanentState,
      PermanentAddrStateValue: this.travelFormData.kycFormData.permanentAddress.permanentStateId,
      PermanentAddrCityText: this.travelFormData.kycFormData.permanentAddress.permanentCity,
      PermanentAddrCityValue: this.travelFormData.kycFormData.permanentAddress.permanentCityId,
      IsPanNumberExist: false,
      isUINApplicable: isUINApplicable,
      isGSTINApplicable: isGSTINApplicable,
      GSTDetails: this.getGSTDetails(),
      CKYCID:this.travelFormData.kycFormData.kycDetails.CKYCID,
      EKYCid:this.travelFormData.kycFormData.kycDetails.EKYCID,
      ilkycReferenceNumber:this.travelFormData.kycFormData.kycDetails.ilKycReferenceNumber,
      pepFlag:this.travelFormData.kycFormData.kycDetails.pepFlag
      
    }
    return request2;
  }

  getGSTDetails() {
    let gstDetails = this.travelFormData.kycFormData.otherDetails;
    let request = {
      "CONSTITUTION_OF_BUSINESS": gstDetails.isGstRegistered == 'Yes' ? gstDetails.constitutionOfBusinessId : 0,
      "CONSTITUTION_OF_BUSINESS_TEXT": gstDetails.isGstRegistered == 'Yes' ? gstDetails.constitutionOfBusiness : '',
      "CUSTOMER_TYPE": gstDetails.isGstRegistered == 'Yes' ? gstDetails.customerTypeId : 0,
      "CUSTOMER_TYPE_TEXT": gstDetails.isGstRegistered == 'Yes' ? gstDetails.customerType : '',
      "PAN_NO": gstDetails.isGstRegistered == 'Yes' ? gstDetails.panNumber : '',
      "GST_REGISTRATION_STATUS": gstDetails.isGstRegistered == 'Yes' ? gstDetails.gstStatusId : 0,
      "GST_REGISTRATION_STATUS_TEXT": gstDetails.isGstRegistered == 'Yes' ? gstDetails.gstStatus : ''
    }
    if (gstDetails.isGstRegistered == 'Yes') {
      request['GSTIN_NO'] = gstDetails.isGstApplicable == 'GSTIN' ? gstDetails.gstin : gstDetails.uin
    }
    return request;
  }

  setsaveEditData(data) {
    this.travelFormData.kycFormData.CustomerID = data.Data.CustomerID;
    this.travelFormData.kycFormData.PFCustomerID = data.Data.PFCustomerID;
  }

  commonProposal(payMode) {
    let request: SaveProposalDetails1RequestPayload = this.constructProposalRequest(this.travelFormData, payMode);
    this.subscription.push(this.travelService.saveProposalDetail(request).subscribe({
      next: proposalResponse => {
        if (proposalResponse.StatusCode == Constants.statusCode_success) {
          let req = {
            proposalNumber: proposalResponse.Data.PF_ProsalNumber,
            policyNumber: proposalResponse.Data.PolicyID,
            totalPremium: proposalResponse.Data.TotalPremium,
          }
          this.paymentLink(payMode, req);
        }
      },
      error: error => {
      }
    }))
  }

  // common payment
  paymentLink(payMode: string, req) {
    let data: PaymentStatusRequestPayload = {
      paymode: payMode,
      befitPlan: false,
      homePlan: false,
      zeroTatProduct: false,
      proposalPolicyID: req.policyNumber,
      bundalPolicyID: '',
      totalPremium: req.totalPremium,
      proposalNumber: req.proposalNumber,
      bundleProposalNumber: '',
      emailID: this.travelFormData.tempEmail,
      mobileNo:(this.travelFormData.tempMobileNo).toString()
    }
    if (payMode == 'PayNow') {
      //common payment service paynow
      this.paymode_payment_service.CreateBundlePayment(data);
    }
    else {
      //common payment service paylater/payment link
      this.paymode_payment_service.commonPayLaterRequest(data);
    }
  }

  constructProposalRequest(saveEditResponse: TravelDataStorage, payMode) {
    let universityDetails, countryDetails;
    if (this.travelFormData?.quoteFormData?.productName == 'Student Travel') {
      universityDetails = this.travelFormData.kycFormData.universityDetails;
      countryDetails = universityDetails.instituteCountryList.find(x => x['label'] == universityDetails.instituteCountry);
    }
    let request: any = {
      CKYCId:this.travelFormData.kycFormData.kycDetails.CKYCID,
      EKYCid:this.travelFormData.kycFormData.kycDetails.EKYCID,
      ILKYCReferenceNumber:this.travelFormData.kycFormData.kycDetails.ilKycReferenceNumber,
      UserType: this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      ipaddress: this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.travelDataModal.ipAddress : this.channelData.CIP,
      Product: "TRAVEL",
      payMode: payMode.toLowerCase(),
      ProductType: this.travelFormData.quoteFormData.productName == 'International Travel' ? "International" : 'Student',
      QuoteID: this.travelFormData.premiumFormData.quoteId,
      CustomerID: this.travelFormData.kycFormData.CustomerID.toString(),
      GSTStateCode: this.travelFormData.quoteFormData.stateId.toString(),
      GSTStateName: this.travelFormData.quoteFormData.state,
      MailPolicyCopy: "NA",
      SourcingCode: "NA",
      PanCardNo: this.travelFormData.kycFormData.applicantDetails.applicantPanCardNumber,
      GSTApplicable: false,
      HasIbankRelationship: false,
      BankRelationship: {
        "IsIbankRelationship": false
      },
      PF_CUSTOMERID: this.travelFormData.kycFormData.PFCustomerID,
      Members: this.constructmemberDetails(),
      SponserDetails: {
        "SponsorTitle": this.travelFormData.kycFormData.sponsorDetails.sponsorTitle,
        "SponsorName": this.travelFormData.kycFormData.sponsorDetails.sponsorName,
        "SponserType": this.travelFormData.kycFormData.sponsorDetails.sponsorType,
        "SponserNominee": ""
      },
      UniversityDetails: {
        "UniversityName": this.travelFormData.kycFormData.universityDetails.instituteName,
        "UniversityAddressLine1": this.travelFormData.kycFormData.universityDetails.instituteAddress1,
        "UniversityAddressLine2": this.travelFormData.kycFormData.universityDetails.instituteAddress2,
        "UniversityCity": this.travelFormData.kycFormData.universityDetails.instituteCity,
        "UniversityPinCode": this.travelFormData.kycFormData.universityDetails.institutePincode.toString(),
        "UniversityState": this.travelFormData.kycFormData.universityDetails.instituteState,
        "CountryID": this.travelFormData?.quoteFormData?.productName == 'Student Travel' ? countryDetails['value'] : ''
      },
    }
    return request;
  }

  constructmemberDetails() {
    let membersData = [];
    if (this.travelFormData.quoteFormData.totalMemberData.length > 0) {
      this.travelFormData.quoteFormData.totalMemberData.forEach((data, index) => {
        membersData.push({
          "TitleID": this.MemberDetailsData[index]['title_id'],
          "TitleName": this.MemberDetailsData[index]['title'],
          "InsuredName": this.MemberDetailsData[index]['fullName'],
          "PassportNumber": this.MemberDetailsData[index]['passportNumber'],
          "RelationshipName": this.MemberDetailsData[index]['relationshipWithNominee'],
          "DateOfBirth": this.utilityService.getFormattedDate(this.MemberDetailsData[index]['dob'], 'dd-MMM-yyyy'),
          "AilmentName": this.MemberDetailsData[index]['ailment'],
          "RelationshipwithApplicant": this.MemberDetailsData[index]['applicantRelationship'],
          "RelationshipID": this.MemberDetailsData[index]['applicantRelationship_id'].toString(),
          "NomineeName": this.travelFormData.quoteFormData.productName != 'Student Travel' ? this.MemberDetailsData[index]['nomineeName'] : this.travelFormData.kycFormData.nomineeDetails['nomineeName'],
          "MotherMaidenName": this.travelFormData.quoteFormData.productName == 'Student Travel' ? this.MemberDetailsData[index]['motherName'] : ''
        })
      })
    }
    return membersData;
  }
  getAilments(memberCount: string) {
    let ailmentList: any = [];
    if (this.travelFormData.insuredFormData.preExistingDisease.length > 0 && this.travelFormData.insuredFormData.existingDisease.length > 0) {
      this.travelFormData.insuredFormData.existingDisease.forEach(disease => {
        this.travelFormData.insuredFormData.preExistingDisease.forEach(preExistingControls => {
          if (preExistingControls[disease.Value + memberCount]) {
            ailmentList.push({
              "AilmentID": 0,  // by default
              "Month": "1", // by default
              "Year": "1", // by default
              "AilmentName": disease.Name,
              "PEDCode": (disease.Value).toString(),
              "PEDRemarks": (memberCount.includes(healthMembers.Adult) && disease.Value == insuredDiseaseID.Fourty || disease.Value == insuredDiseaseID.FourtyOne) ? this.findRemark(preExistingControls, memberCount, disease.Value) : ''
            });
          }
        })
      })
    }
    return ailmentList;
  }

  isPedSelected() {
    let memberData = this.travelFormData.insuredFormData;
    let countPed = 0;
    memberData.preExistingDisease.forEach(element => {
      if (!this.utilitySerice.isEmptyOrNull(element)) {
        countPed += element ? 1 : countPed;
      }
    });
    return countPed > 0 ? true : false;
  }

  isUnderwritterApproval() {
    return ((this.isBMIValid) && !this.isPedSelected());

  }

  findRemark(preExistingControls: {}, memberCount: string, id: number): string {
    let remarks = '';
    if (id == insuredDiseaseID.Fourty || id == insuredDiseaseID.FourtyOne) {
      for (let keys in preExistingControls) {
        if (keys == `${remarkList.Remark1 + memberCount}` || keys == `${remarkList.Remark2 + memberCount}`) {
          remarks = (id == insuredDiseaseID.Fourty) ? preExistingControls[remarkList.Remark1 + memberCount] : preExistingControls[remarkList.Remark2 + memberCount]
          return remarks;
        }
      }
    }
    return remarks;
  }

  requestDataFromMultipleSources() {
    this.subscription.push(this.agentV1Service.agentAppMasterData().subscribe({
      next: (agentAppResponse) => {
        if (agentAppResponse.StatusCode != Constants.statusCode_success) {
          this.errorPopupData = this.popupService.fetchErrorPopupMsg(agentAppResponse.StatusDesc);
          this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: err => {
      }
    }))
  }

  getSubProductName() {
    return this.travelFormData.quoteFormData.coverageType == 'individualPlan' ? 'Individual Plan' : 'Family Plan';
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }
  navigateToPage(value: string) {
    this.customStepperService.redirectToStep(value, this.travelFormData.portabilityDataSet);
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  openDeclarationPopup(declarationType: string) {
    let data = {
      rnAgent: this.rnAgent
    }
    this.declarationService.openDeclarationPopup(declarationType, data);
  }

  ngOnDestroy() {
    this.travelFormData = undefined;
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
