<div class="container">
<div class="row">
    <div class="col-sm-12">
        <div class="row">
            <div class="col-sm-11">
        <div style="padding: 10px; border-radius: 5px;background-color: #eaeaea; margin-top: 5px; display: flex; margin-right: -45px;">
            <h5 style="text-align: center;">Risk Category Information            
            </h5>
        </div>
    </div>
    <div class="col-sm-1">
       <span (click)="Onclose()" style="cursor: pointer;    position: absolute;    margin-top: 37%;
       left: -15%;"> <button >Close</button></span>
    </div>
    </div>
        <div style="padding: 10px;">
            <dl *ngFor="let info of infodata;let i=index" style="list-style-type: none;margin-bottom:0.5rem; ">
                <ul><li>{{i+1}}.  {{info}}</li></ul>
            </dl>
        </div>
</div>
</div>
</div>