<form [formGroup]="ZerotatSummary">
    <mat-card class="innerbox plandetailPanel marginbox">
        <h3>Plan Details
            <span class="float-right">
                <img src="../../../../../assets/images/edit.png" alt="" (click)="navigateToPage('f1')" class="edit">
            </span>
        </h3>
        <div class="fieldsdiv">
            <div class="row customDiv planrowData">
                <div class="col-lg-3 col-md-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Product Name</mat-label>
                    <p>
                        <i class="health"></i>
                        {{this.healthFormData.quoteFormData.productName }}
                    </p>
                </div>
                <div class="col-md-2 col-sm-2 form-group">
                    <mat-label class="mtlabel" for="myInput">Policy Tenure</mat-label>
                    <p>{{this.healthFormData.quoteFormData.policyTenure }}</p>
                </div>

                <div class="col-md-2 col-sm-2 form-group" *ngIf="healthFormData.quoteFormData.subProductType == 38 ">
                    <mat-label class="mtlabel" for="myInput">Total SI</mat-label>
                    <p>{{'₹' + this.healthFormData.premiumFormData.InstallmentNetPremium }}</p>
                </div>
            </div>

        </div>
        <mat-divider></mat-divider>
    </mat-card>

    <mat-card class="innerbox reverinnerbox">
        <h3 class="pt-3">Insured details
            <span class="float-right">
                <img src="../../../../assets/images/edit.png" alt="" (click)="navigateToPage('f3')" alt="" class="edit">
            </span>
        </h3>
        <div class="fieldsdiv" *ngFor="let memberData of memberDetails;let i=index;">
            <div class="row customDiv mb-0">
                <div class=" col-sm-12">
                    <span class="subheading">{{memberData.memberTypeLabel}}</span>
                </div>
                <div class="col-sm-3 form-group width100 order-1 order-sm-1">
                    <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                    <p class="titlecase">{{memberData.fullName.toLowerCase()}}</p>
                </div>
                <div class="col-lg-2 col-sm-2 form-group width50 order-3 order-sm-3 ">
                    <mat-label class="mtlabel" for="myInput">Relationship</mat-label>
                    <p class="titlecase">{{memberData.Relationship.toLowerCase()}}</p>
                </div>
                <div class="col-lg-2 col-sm-2 form-group width50 order-2 order-sm-2">
                    <mat-label class="mtlabel" for="myInput">DOB</mat-label>
                    <p>{{utilitySerice.formattedDate(memberData.dob)}}</p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group width50 order-4 order-sm-4"
                    *ngIf="healthFormData.quoteFormData.subProductType == 38 ">
                    <mat-label class="mtlabel" for="myInput">Pre-existing disease & habit</mat-label>
                    <p>{{memberData.preExistingDisease}}</p>
                </div>

                <div class="col-lg-2 col-sm-2 form-group width50 order-4 order-sm-4"
                    *ngIf="healthFormData.quoteFormData.subProductType == 23 ">
                    <mat-label class="mtlabel" for="myInput">Risk category</mat-label>
                    <p>{{memberData.riskcategory}}</p>
                </div>
                <div class="col-lg-3 form-group width50 order-6 order-sm-6 "
                    *ngIf="healthFormData.quoteFormData.subProductType == 23 ">
                    <mat-label class="mtlabel" for="myInput">Total SI</mat-label>
                    <p>₹ {{memberData.totalsumOfCoverSI}}</p>
                </div>

                
            </div>
            <mat-divider></mat-divider>
            
            <div class="row customDiv pt-3 addonsSum bgCoverDiv ml-md-1 mr-md-1 mb-2"
                *ngIf="memberData.coverName!=undefined && memberData.coverName!= null && memberData.coverName.length > 0 && healthFormData.quoteFormData.subProductType == 23">
                <div class=" col-sm-12 ">
                    <span class="subheading">{{memberData.memberTypeLabel}} Cover & SI</span>
                </div>
                <div class="col-sm-4 form-group" *ngFor="let coverdetails of memberData.coverName">
                    <span class="coverAddTick miniTick"><img src="../../../assets/images/miniTick.svg" alt=""></span>
                    <span>
                        <mat-label class="mtlabel" for="myInput">{{coverdetails.CoverName}}</mat-label>
                        <p class="pl-3">₹{{coverdetails.CoverSI}}</p>
                    </span>
                </div>
            </div>
        </div>
    </mat-card>
    <mat-card class="innerbox borderinnerbox">
        <h3 class="pt-3">Applicant Details
            <span class="float-right">
                <img src="../../../../../assets/images/edit.png" alt="" (click)="navigateToPage('f4')" class="edit">
            </span>
        </h3>
        <div class="fieldsdiv">
            <div class="row customDiv">
                <div class="col-sm-3 form-group width100">
                    <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                    <p class="titlecase">{{this.healthFormData.kycFormData.applicantDetails.applicantFullName.toLowerCase()}}</p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group width50">
                    <mat-label class="mtlabel" for="myInput">Mobile No.</mat-label>
                    <p class="titlecase">{{this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber.toLowerCase()}}
                    </p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Email ID</mat-label>
                    <p>{{this.healthFormData.kycFormData.permanentAddress.permanentEmail }}</p>
                </div>
                <div class="col-sm-6 form-group width100">
                    <mat-label class="mtlabel" for="myInput">Address</mat-label>
                    <p class="titlecase">{{this.healthFormData.kycFormData.permanentAddress.permanentAddress1.toLowerCase()  }}
                        {{this.healthFormData.kycFormData.permanentAddress.permanentAddress2.toLowerCase()  }}
                        {{this.healthFormData.kycFormData.permanentAddress.permanentLandmark.toLowerCase()  }}
                        {{this.healthFormData.kycFormData.permanentAddress.permanentPincode.toLowerCase()  }}
                        {{this.healthFormData.kycFormData.permanentAddress.permanentState.toLowerCase()  }}
                        {{this.healthFormData.kycFormData.permanentAddress.permanentCity.toLowerCase()  }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Nominee Details -->
        <h3 class="pt-3">Nominee Details</h3>
        <div class="fieldsdiv">
            <div class="row customDiv">
                <div class="col-sm-3 form-group width100">
                    <mat-label class="mtlabel" for="myInput">Full Name</mat-label>
                    <p class="titlecase">{{this.healthFormData.kycFormData.nomineeDetails.nomineeFullName.toLowerCase() }}</p>
                </div>
                <div class="col-lg-3 col-sm-3 form-group width50">
                    <mat-label class="mtlabel" for="myInput">DOB</mat-label>
                    <p>{{this.utilitySerice.formatDateWithSpace(this.healthFormData.kycFormData.nomineeDetails.nomineeDOB)}}
                    </p> 
                </div>
                <div class="col-lg-3 col-sm-3 form-group">
                    <mat-label class="mtlabel" for="myInput">Relationship</mat-label>
                    <p>{{this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship }}</p>
                </div>

            </div>
        </div>
        <mat-divider></mat-divider>
    </mat-card>
    <mat-card class="innerbox borderinnerbox" *ngIf="healthFormData.quoteFormData.subProductType == 38">
        <h3>Medical Test Guidelines
        </h3>
        <div class="fieldsdiv">
            <div class="row">
                <div class="col-sm-10 form-group">
                    <label class="mediTest" for="">{{this.healthFormData.quoteFormData.productName}}</label>
                    <table>
                        <caption class="hidden-caption">Details</caption>
                        <!-- <thead></thead> -->
                        <th class="hidden-caption">Details</th>
                        <tr>
                            <td class="tickAlign pr-2" *ngIf="isUnderwritterApproval()"><img class="tickIcon"
                                    src="../../../../../assets/images/miniTick.svg" alt=""></td>
                            <td *ngIf="isUnderwritterApproval()">Warning! Policy will be insured on the
                                basis of underwriter approval.
                            </td>
                        </tr>
                        <tr>
                            <td class="tickAlign pr-2" *ngIf="isPedSelected()"><img class="tickIcon"
                                    src="../../../../../assets/images/miniTick.svg" alt=""></td>
                            <td class="tickAlign pr-2" *ngIf="isPedSelected()">Warning! Basis the information
                                shared, you may receive a call from our underwriters. The policy will get issued basis
                                underwriter's approval and additional premium (if any).
                            </td>
                        </tr>
                        <tr class="tickAlign pr-2">
                            <td class="tickAlign pr-2"><img class="tickIcon"
                                    src="../../../../../assets/images/miniTick.svg" alt=""></td>
                            <td>Medical test at ICICI Lombard GIC Ltd. designated diagnostic center.</td>
                        </tr>
                        <tr class="tickAlign pr-2">
                            <td class="tickAlign pr-2"><img class="tickIcon"
                                    src="../../../../../assets/images/miniTick.svg" alt=""></td>
                            <td>{{this.medicaltest}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
    </mat-card>
    <mat-card class="innerbox borderinnerbox">
        <h3>Declarations
        </h3>
        <div class="fieldsdiv mb-4">
            <div class="row">
                <div class="col-sm-12">
                    <table class="mediTestTable">
                        <caption class="hidden-caption">Details</caption>
                        <th class="hidden-caption">Declarations</th>
                        <td class="hidden-caption">Agent Declaration</td>
                        <tr>
                            <td class="customCheckslist" style="vertical-align: top;">
                                <ul>
                                    <li>
                                        <mat-checkbox class="cstmCheckbox" [checked]="true"
                                            formControlName="medicalDeclaration1"></mat-checkbox>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ng-container *ngIf="productType == 38">
                                    <p>
                                        I {{rnAgent}} accept <a
                                            (click)="openDeclarationPopup('agentConfidentialityReport')">
                                            <span class="custom-anchor">Agent Declaration
                                            </span> </a>
                                    </p>
                                </ng-container>
                                <ng-container *ngIf="productType != 38">
                                    <p>
                                        I {{rnAgent}} accept <a
                                            (click)="openDeclarationPopup('agentConfidentialityReport')">
                                            <span class="custom-anchor">Agent's Confidentiality Report
                                            </span> </a> Declaration
                                    </p>
                                </ng-container>

                            </td>
                        </tr>
                    </table>
                    <mat-error class="ml-4 error-margin"
                        *ngIf="this.ZerotatSummary.controls['medicalDeclaration1'].value==false">
                        Please accept Declaration.
                    </mat-error>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <table class="mediTestTable">
                        <caption class="hidden-caption">Details</caption>
                        <th class="hidden-caption">Declarations</th>
                        <td class="hidden-caption">Agent Declaration</td>
                        <tr>
                            <td class="customCheckslist" style="vertical-align: top;">
                                <ul>
                                    <li>
                                        <mat-checkbox class="cstmCheckbox" [checked]="true"
                                            formControlName="medicalDeclaration2"></mat-checkbox>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <p>
                                    I accept the <a (click)="openDeclarationPopup('generalDeclaration')"> <span
                                            class="custom-anchor">Declaration</span></a> and agree to
                                    the <a (click)="openDeclarationPopup('tncDeclaration')"><span
                                            class="custom-anchor">Terms & Conditions</span></a> and
                                    <a (click)="openDeclarationPopup('privacyPolicy')"><span
                                            class="custom-anchor">Privacy
                                            Policy </span></a>
                                </p>
                            </td>
                        </tr>
                    </table>
                    <mat-error class="ml-4 error-margin"
                        *ngIf="this.ZerotatSummary.controls['medicalDeclaration2'].value==false">
                        Please accept the declaration and agree to the Terms & Conditions and Privacy Policy
                    </mat-error>
                </div>
            </div>
        </div>
    </mat-card>

</form>

<!-- IPAA-176: Saved Quotes Button -->
<div class="saved-quotes floatdiv floatingedit" *ngIf="saveButtonShow">
    <img class="imgScss" src="../../../../../assets/images/MyTransactionIcon/save-quote.svg" alt=""
        (click)="saveQuotesButtonClick()">
</div>

<app-custom-footer [normalButton]="false" [buttonData]="[
{'label': 'Pay Now', 'type': 'secondary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
{'label': 'Pay Later', 'type': 'secondary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
{'label': 'Payment Link', 'type': 'primary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
{'label': 'Choose Payment', 'type': 'primary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': false },
]" (buttonClicked)="paymentModeSelected($event)">
</app-custom-footer>