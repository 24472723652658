<div class="cardScroll" *ngIf="pagedList.length > 0">
    <mat-card class="innerbox borderinnerbox"
        *ngFor="let list of pagedList.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)">
        <div class="paddingDiv">
            <div class="row customDiv">
                <ng-container *ngFor="let item of displayedColumns">
                    <div class="col-sm-3" *ngIf="banColumns(item.columnDef)">
                        <ng-container *ngIf="['BundleProposalNo','BundlePolicyNo'].includes(item.columnDef)">
                            <mat-label class="mtlabel">{{item.header}}</mat-label>
                            <p>
                                <span class="downloadSpan"
                                    *ngIf="!utilityService.isEmptyOrNull(list[item.col1]) && !utilityService.isEmptyOrNull(list[item.col2])">
                                    <img [matTooltip]="'Download'" [matTooltipPosition]="'left'"
                                        src="../../../../../../../assets/images/BBR/noBorderDownloadBtn.svg"
                                        alt="download" />
                                </span>
                                <span (click)="emitData(evalFunction(list, item.col1Expression), item.label)"
                                    [matTooltip]="!utilityService.isEmptyOrNull(list[item.col1]) && !utilityService.isEmptyOrNull(list[item.col2]) ? item?.tooltip1 : null"
                                    [matTooltipPosition]="'left'"
                                    [ngClass]="{'numberSpan': !utilityService.isEmptyOrNull(list[item.col1]) && !utilityService.isEmptyOrNull(list[item.col2]),
                                        'proposalColumn': item.columnDef === 'BundleProposalNo'
                                     }">
                                    {{!utilityService.isEmptyOrNull(list[item.col1]) &&
                                    !utilityService.isEmptyOrNull(list[item.col2]) ? list[item.col1] : '-'}}
                                </span>

                                <!-- Copy Icon -->
                                <span class="copySpan" *ngIf="!utilityService.isEmptyOrNull(list[item.col1]) && !utilityService.isEmptyOrNull(list[item.col2])"
                                    (click)="copyText(list[item.col1], tooltip)">
                                    <img [matTooltip]="isCopied ? 'Text copied successfully!' : 'Copy Text'"
                                    [matTooltipPosition]="'left'" src="../../../../../../../assets/images/BBR/copyText.svg"
                                        alt="copy" #tooltip="matTooltip"/>
                                </span>
                            </p>
                            <p>
                                <span class="downloadSpan"
                                    *ngIf="!utilityService.isEmptyOrNull(list[item.col2]) && !utilityService.isEmptyOrNull(list[item.col1])">
                                    <img [matTooltip]="'Download'" [matTooltipPosition]="'left'"
                                        src="../../../../../../../assets/images/BBR/noBorderDownloadBtn.svg"
                                        alt="download" />
                                </span>
                                <span (click)="emitData(evalFunction(list, item.col2Expression), item.label)"
                                    [matTooltip]="!utilityService.isEmptyOrNull(list[item.col2]) && !utilityService.isEmptyOrNull(list[item.col1]) ? item?.tooltip2 : null"
                                    [matTooltipPosition]="'left'"
                                    [ngClass]="{'numberSpan': !utilityService.isEmptyOrNull(list[item.col2]) && !utilityService.isEmptyOrNull(list[item.col1]),
                                        'proposalColumn': item.columnDef === 'BundleProposalNo'
                                    }">
                                    {{!utilityService.isEmptyOrNull(list[item.col2]) &&
                                    !utilityService.isEmptyOrNull(list[item.col1]) ? list[item.col2] : '-'}}
                                </span>
                                <!-- Copy Icon -->
                                <span class="copySpan" *ngIf="!utilityService.isEmptyOrNull(list[item.col1]) && !utilityService.isEmptyOrNull(list[item.col2])"
                                    (click)="copyText(list[item.col2], tooltip)">
                                    <img [matTooltip]="isCopied ? 'Text copied successfully!' : 'Copy Text'"
                                    [matTooltipPosition]="'left'" src="../../../../../../../assets/images/BBR/copyText.svg"
                                        alt="copy" #tooltip="matTooltip"/>
                                </span>
                            </p>
                        </ng-container>
                        <ng-container
                            *ngIf="['PFProposalNo','PFPolicyNo','ProposalNumber','PolicyNumber','policy_number','NewPolicyNo','PFPaymentID'].includes(item.columnDef)">
                            <mat-label class="mtlabel">{{item.header}}</mat-label>
                            <p>
                                <span class="downloadSpan"
                                    *ngIf="evalConditionForMyPolicy(list, item.expression ?? true) && !utilityService.isEmptyOrNull(list[item.columnDef])">
                                    <img [matTooltip]="evalConditionForMyPolicy(list, item.expression ?? true) && !utilityService.isEmptyOrNull(list[item.columnDef]) ? 'Download' : null"
                                        [matTooltipPosition]="'left'"
                                        src="../../../../../../../assets/images/BBR/noBorderDownloadBtn.svg"
                                        alt="download" />
                                </span>
                                <span (click)="emitData(list, item.label)"
                                    [matTooltip]="evalConditionForMyPolicy(list, item.expression ?? true) && !utilityService.isEmptyOrNull(list[item.columnDef]) ? item.header : null"
                                    [matTooltipPosition]="'left'"
                                    [ngClass]="{'numberSpan': evalConditionForMyPolicy(list, item.expression ?? true) && !utilityService.isEmptyOrNull(list[item.columnDef]),
                                        'proposalColumn': ['ProposalNumber','PFProposalNo','PFPaymentID'].includes(item.columnDef)
                                    }">
                                    {{utilityService.isEmptyOrNull(list[item.columnDef]) ? '-': list[item.columnDef]}}
                                </span>

                                <!-- Copy Icon -->
                                <span class="copySpan" *ngIf="evalConditionForMyPolicy(list, item.expression ?? true) && !utilityService.isEmptyOrNull(list[item.columnDef])"
                                    (click)="copyText(list[item.columnDef], tooltip)">
                                    <img [matTooltip]="isCopied ? 'Text copied successfully!' : 'Copy Text'"
                                    [matTooltipPosition]="'left'" src="../../../../../../../assets/images/BBR/copyText.svg"
                                        alt="copy" #tooltip="matTooltip" />
                                </span>
                            </p>
                        </ng-container>
                        <ng-container
                            *ngIf="!['PFProposalNo','PFPolicyNo','ProposalNumber','PolicyNumber','policy_number','NewPolicyNo','PFPaymentID', 'BundleProposalNo','BundlePolicyNo'].includes(item.columnDef)">
                            <ng-container *ngIf="evalConditionForMyPolicy(list, item.expression ?? true)">
                                <mat-label class="mtlabel">{{item.header}}</mat-label>
                                <p
                                    [ngClass]="{'status': item.columnDef == 'Status' && list[item.columnDef] == 'Failed Payment'}">
                                    {{(item.columnDef != 'Amount') && (item.columnDef !=
                                    'TotalPremium') && (item.columnDef !=
                                    'ComboPremium') && (item.columnDef != 'Premium') ? (list[item.columnDef] == '' || list[item.columnDef] == null) ?
                                    '-' :
                                    item.columnDef == 'ProposalStatus' ?
                                    addSpace(list[item.columnDef]) : list[item.columnDef] : '₹' +
                                    utilityService.convertStringtoCurrency(list[item.columnDef])}}</p>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="buttonData.length > 0">
                <mat-divider></mat-divider>
                <div class="pt-3 overflow-auto">
                    <div class="buttonDiv">
                        <ng-container *ngFor="let b of buttonData">
                            <button class="checkboxPending downloadPending" (click)="emitData(list, b.label)"
                                *ngIf="b.label != 'Add To Pay' && evalConditionForMyPolicy(list, b.expression ?? true)">
                                <div class="myDownload">
                                    <span class="webdownload"
                                        [ngClass]="{'pendingPaymentMobileButtons' : !b.imgPath}">{{b.label}}</span>
                                </div>
                                <span *ngIf="b.imgPath" class="mobiledownload">
                                    <img src="{{b.imgPath}}" alt="{{b.label}}">
                                </span>
                            </button>
                            <span
                                *ngIf="b.label == 'Add To Pay' && evalConditionForMyPolicy(list, b.expression ?? true)">
                                <mat-checkbox class="cstmCheckbox checkboxPending" [checked]="list.isChecked"
                                    (change)="emitData({selected: $event.checked, rowData: list, id: list.id}, 'Add To Pay')">
                                    Add To Pay
                                </mat-checkbox>&nbsp;
                            </span>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-card>
</div>
<div *ngIf="pagedList.length == 0" class="NoRecordFoundMainDiv">
    <mat-card class="NoRecordFound">
        <div class="NoRecordFoundData">
            <img src=" ../../../../../../../assets/images/BBR/NoRecordFound.svg" alt="No Record Found"
                class="noRecordImg mb-3">
            <h3 class="text-center mb-2 paraMessage"> No data to show</h3>
            <h4 class="text-center mb-0 messagePara"> Try adjusting your search or filter to find your report</h4>
        </div>
    </mat-card>
</div>
<div class="cardPag" *ngIf="pagedList.length != 0">
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChange($event)">
    </mat-paginator>
</div>