import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { BrandingComponent } from '../shared/components/widgets/branding.component';
import { PopupComponent } from './popups/popup.component';
import { LoaderComponent } from './loader/loader.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { ErrorScreenComponent } from './error-screen/error-screen.component';
import { CustomStepperComponent } from './custom-stepper/custom-stepper.component';
import { CustomFooterComponent } from './custom-footer/custom-footer.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { IPushnotificationModule } from 'projects/i-pushnotification/src/public-api';
@NgModule({
  declarations: [
    HeaderComponent,
    BrandingComponent,
    PopupComponent,
    LoaderComponent,
    SidebarComponent,
    ErrorScreenComponent,
    CustomStepperComponent,
    CustomFooterComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    IPushnotificationModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.5)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
      fullScreenBackdrop: false,
    }),
  ],

  exports: [
    HeaderComponent,
    SidebarComponent,
    BrandingComponent,
    LoaderComponent,
    ErrorScreenComponent,
    CustomStepperComponent,
    CustomFooterComponent,
    MaterialModule,
    IPushnotificationModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutModule { }