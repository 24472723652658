import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PincodeByIdResponsePayload, RTOListRequestPayload, RTOListResponsePayload } from '../interface/rto-list';
import { GenericService } from '../services/generic.service';
import { StorageService } from '../services/storage.service';
import { userEnums } from '../enums/userEnums';

@Injectable({
  providedIn: 'root'
})
export class RTOListService {

  constructor(private generic: GenericService,
    private storageService: StorageService) {
  }

  readonly baseURI = `${environment.baseURL}RTOList/`;
  readonly StatesCityByPin = 'GetStatesCityByPin';
  readonly PinCodeById = 'GetPincodeID';

  getStatesCityByPin(data: RTOListRequestPayload): Observable<RTOListResponsePayload> {
    const endpoint = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'true' ? `${environment.baseURL}OrionHealthMaster/${this.StatesCityByPin}` : `${this.baseURI}${this.StatesCityByPin}`;
    return this.generic.genericService<RTOListResponsePayload>(endpoint, data);
  }

  getPincodeById(data: RTOListRequestPayload): Observable<PincodeByIdResponsePayload> {
    const endpoint = `${this.baseURI}${this.PinCodeById}`;
    return this.generic.genericService<PincodeByIdResponsePayload>(endpoint, data);
  }
  
}
