import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoverHomeRequestPayload, CreateHomeResponsePayload, IsKycPilotUserRequestPayload, IsKycPilotUserResponsePayload, KycTokenResponsePayload } from '../interface/wrapper';
import { GenericService } from '../services/generic.service';
import { CreateBefitProposalRequestPayload, CreateBefitProposalResponsePayload } from '../interface/wrapper';
import { userEnums } from '../enums/userEnums';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class WrapperService {

  constructor(private generic: GenericService,
    private storageService: StorageService) { }

  readonly baseURI = `${environment.baseURL}Wrapper/`;
  readonly homeProposal = 'HomeProposalCreate';
  readonly createBefit = 'ZerotatProposalCreate';
  readonly createKycToken = 'CreateKYCTokenV1';
  readonly isKYCPilotUser = 'IsKYCPilotUser';


  createHomeProposal(data: CoverHomeRequestPayload): Observable<CreateHomeResponsePayload> {
    const endpoint = `${this.baseURI}${this.homeProposal}`;
    return this.generic.genericService<CreateHomeResponsePayload>(endpoint, data);
  }

  createBefitProposal(data: CreateBefitProposalRequestPayload): Observable<CreateBefitProposalResponsePayload> {
    const endpoint = `${this.baseURI}${this.createBefit}`;
    return this.generic.genericService<CreateBefitProposalResponsePayload>(endpoint, data);
  }

  createKYCToken(): Observable<KycTokenResponsePayload> {
    const endpoint = `${this.baseURI}${this.createKycToken}`;
    return this.generic.genericService<KycTokenResponsePayload>(endpoint, '');
  }

  IsKycPilotUser(appNameData: string): Observable<IsKycPilotUserResponsePayload> {
    let request:IsKycPilotUserRequestPayload = {
      userName: this.storageService.getAESDecryptedData(userEnums.UopqksmlN),
      appName: appNameData
    }
    const endpoint = `${this.baseURI}${this.isKYCPilotUser}`;
    return this.generic.genericService<IsKycPilotUserResponsePayload>(endpoint, request);
  }
}
