import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { LayoutModule } from './layout/layout.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptorService } from './shared/interceptors/api-interceptor.service';
import { ApiRequestInterceptorInterceptor } from './shared/interceptors/api-request-interceptor.interceptor';
import { provideUserIdleConfig } from 'angular-user-idle';
import { Constants } from './shared/constants/constants';
import { SwapModule } from './modules/swap/swap.module';
import { DialogBoxModule } from './modules/dialog-box/dialog-box.module';
import { SidenavRoutingModule } from './layout/sidebar/sidebar-routing.module';
import { SharedModule } from './shared/shared.module';
import { WindowResizeService } from './shared/services/window-resize.service';
import { HealthModule } from './modules/health/health.module';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { PromptComponent } from './layout/prompt/prompt.component';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { PagenotfoundComponent } from './layout/pagenotfound/pagenotfound.component';
import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TabCloseService } from './shared/services/tab-close.service';

export class CaseInsensitiveUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    // Split the URL into path and query parts
    const [path, queryString] = url.split('?');

    // Convert only the path to lowercase before parsing
    const lowerCasePath = path.toLowerCase();

    // Reassemble the URL with the lowercase path and original query string
    const newUrl = queryString ? `${lowerCasePath}?${queryString}` : lowerCasePath;

    return super.parse(newUrl);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    PromptComponent,
    PagenotfoundComponent],

  imports: [
    BrowserModule,
    AppRoutingModule,
    SidenavRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule,
    HttpClientModule,
    MaterialModule,
    DialogBoxModule,
    SwapModule,
    SharedModule,
    HealthModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: true,
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: UrlSerializer, useClass: CaseInsensitiveUrlSerializer },
  // {
  //   provide: SwRegistrationOptions,
  //   useFactory: () => ({ enabled: true }),
  // },
    // {provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true},
    WindowResizeService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiRequestInterceptorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptorService,
    multi: true,
  },
  { provide: MAT_DATE_LOCALE,
    useValue: 'en-GB'
  },

  provideUserIdleConfig({ idle: Constants.idle, timeout: Constants.timeOut, ping: Constants.ping }),
    DatePipe
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
