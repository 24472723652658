<!-- BBR UI Implementation  -->
<section class="container-fluid">
  <div class="row">
    <div class="container panaMaincontainer">
      <div class="floatdiv floatingedit" *ngIf="scrolling">
        <img class="imgScss" src="../../../../../../assets/images/BBR/ScrollToTop.svg" alt="" (click)="scrollToTop()">
      </div>

      <div class="marginbox">
        <!-- Icon and title start -->
        <div class="myTransactionTitle" *ngIf="!isSearchVisible">
          <div class="bbrheading">
            <span><img src="../../../../../assets/images/MyTransactionIcon/Payinslip.svg" alt=""></span>
            <span>
              <h3>Pay In Slip</h3>
            </span>
          </div>
          <div class="d-md-none">
            <span (click)="searchFilter()">
              <img class="headerImg" src="../../../../../../assets/images/BBR/Search.svg" alt="" /></span>
          </div>
        </div>
        <div class="mobilesearch__fieldBBr" *ngIf="isSearchVisible">
          <div class="mobileinputDivbbr">
            <input matInput type="text" class="input-group__input placeholder"
              [cdkTrapFocusAutoCapture]="isSearchVisible" [cdkTrapFocus]="isSearchVisible"
              [placeholder]="searchPlaceHolder" (input)="onSearch()" [(ngModel)]="searchTerm">
            <span class="crossclick" (click)="closeClear()">
              <img src="../../../../../../assets/images/BBR/Close.svg" alt="">
            </span>
          </div>
        </div>
        <!-- Icon and title end -->

        <mat-card class="innerbox borderinnerbox">
          <div class="fieldsdiv">
            <div class="productmainDiv">
              <div class="productdiv">
                <div class="align-items-center">
                  <span>Records: <span class="font-weight-bold"
                      [ngStyle]="{ 'color': '#ec6608' }">{{payInSlipsVisibleData ? payInSlipsVisibleData.length : 0
                      }}</span></span>
                </div>
              </div>
              <div class="filterdiv">
                <div class="d-none d-md-block">
                  <div class="search__fieldBBr">
                    <div class="inputDivbbr" [ngClass]="{'searchBorder': showCancel}">
                      <input matInput type="text" class=" placeholder" [placeholder]="searchPlaceHolder"
                        (input)="onSearch()" [(ngModel)]="searchTerm" (focus)="inputFocus()" (blur)="inputBlur()">
                      <span class="crossclick pr-2" (click)="clearInputField()">
                        <img src="../../../../../../assets/images/BBR/desktopCross.svg" alt="">
                      </span>
                    </div>
                  </div>
                </div>

                <!-- ends here -->
                <div class=" d-flex align-items-center">
                  <mat-button-toggle-group class="custom-toggle-group" (change)="onToggle($event)"
                    [value]="toggleValue">
                    <mat-button-toggle matTooltip="Table View" class="custom_togglebtn" value="table"><img
                        src="../../../../../../assets/images/BBR/tablelist.svg" alt=""></mat-button-toggle>
                    <mat-button-toggle matTooltip="Card View" class="custom_togglebtn" value="card"><img
                        src="../../../../../../assets/images/BBR/cardList.svg" alt=""></mat-button-toggle>
                  </mat-button-toggle-group>
                </div>

              </div>
            </div>
          </div>
        </mat-card>

        <!-- Table format start -->
        <app-custom-table *ngIf="toggleValue == 'table'" [reportList]="payInSlipsVisibleData"
          [buttonData]="tableBtnInfo" [displayedColumns]="displayCol" (policyNo)="receiveDataAndCall($event)"
          [tableSize]="'1440px'"></app-custom-table>
        <!-- Table format end -->

        <!-- Card format start -->
        <app-custom-card *ngIf="toggleValue == 'card'" [reportList]="payInSlipsVisibleData" [buttonData]="cardBtnInfo"
          [displayedColumns]="displayCol" (policyNo)="receiveDataAndCall($event)"></app-custom-card>
        <!-- Card format end -->

      </div>
    </div>
  </div>
</section>