import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { map, catchError, concatMap, finalize } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { StorageService, SessionStorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';
import { userEnums } from '../enums/userEnums';
import { Constants } from '../constants/constants';
import { AgentServiceA } from '../proxy-services/AuthService/agent.service';
import { CreateLoginTokenResponse } from '../interface/token';
import { LoaderService } from 'src/app/layout/loader/loader.service';
import { PopupService } from '../services/popup.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { AuthService } from '../authentication/auth.service';
import { ChannelData } from '../interface/swapPrameters';
import { UtilityService } from '../services/utility.service';
import { DeveloperLoginService } from 'src/app/developer-dashboard/services/developer-login.service';

@Injectable({
  providedIn: 'root'
})

export class ApiInterceptorService {
  static accessToken = '';
  ExcludeURLList = [
    "auth/Auth/CreateLoginToken",
  ];
  errorPopupData: PopupModal;
  channelData: ChannelData;

  constructor(
    private encryptDecryptService: EncryptDecryptService,
    private storageService: StorageService,
    private sessionService: SessionStorageService,
    private spinner: LoaderService,
    private agentService_A: AgentServiceA,
    private popupService: PopupService,
    private authService: AuthService,
    private _encryDecryService: EncryptDecryptService,
    private utilityService: UtilityService,
    private developerLoginService: DeveloperLoginService,
    private _utilityservics: UtilityService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let reqUrl = request.url.replace(environment.baseURL, "");
    reqUrl = reqUrl.split("?")[0];
    let notifyReqUrl = reqUrl.toLowerCase();
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    if (!request.url.includes('GetRenewalCount') && !request.url.includes('CreateKYCTokenV1')) {
      this.spinner.show();
    }

    let response;
    const req = !(notifyReqUrl.includes('viewnotificationhistory') || notifyReqUrl.includes('savetoken')) ? this.getHeaders(request) : request;
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          response = event.body;
          if ((event.body.hasOwnProperty('StatusCode') && event.body.StatusCode == Constants.statusCode_success)) {
            // this.spinner.hide();
            // if (!this.ExcludeURLList.includes(url)) {
            if (event.body.Data == undefined || event.body.Data == "" || event.body.Data == null) {
              return event;
            }

            else {
              if (reqUrl.includes('Intranet')) {
                let decryptedData = event.body.Data;
                event = event.clone({
                  body: {
                    ...event.body,
                    'Data': decryptedData,
                  }
                });
                return event;
              } else {
                let decryptedData = JSON.parse(this.encryptDecryptService.finalRsaDecryption(event.body.Data));
                event = event.clone({
                  body: {
                    ...event.body,
                    'Data': decryptedData,
                  }
                });
                return event;
              }
            }
            // }

            // If the request is from create login token directly return the event
            // else {
            //   return event;
            // }
          }
          // If any other issue arises
          // else {
          //   this.errorCheckService.checkErrorCode(event.body.StatusCode)
          //   // Here will come our something went wrong popup
          // }
        }
        return event;
      }),
      finalize(() => {
        const urlArray = ['GetRenewalCount', 'Authenticate', 'ViewNotificationHistory', 'SaveToken', 'SasBookedBusiness', 'GetBancsPartyCode', 'MySavedQuotesV1','GetSummitReportData'];
        if (!urlArray.some(x => request.url.includes(x))) {
          this.spinner.handleError(response);
        }
        this.spinner.hide();
      }),
      catchError((error: HttpErrorResponse) => {
        this.spinner.hide();
        if (error.status === Constants.statusCode_unauthorized) {
          let credentials = this.storageService.getAESDecryptedData(userEnums.LoginCredential);
          let sessionReference = this.storageService.getAESDecryptedData(userEnums.SessionReference);
          let swapParameters = this.storageService.getAESDecryptedData(userEnums.SwapParameters);
          let formData = this.storageService.getAESDecryptedData(userEnums.HealthFormData);
          let agentData = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
          let subProductCode = this.storageService.getAESDecryptedData(userEnums.SubProductCode);
          let renewalData = this.storageService.getAESDecryptedData(userEnums.RenewalFormData);
          let channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
          let productType = this.storageService.getAESDecryptedData(userEnums.ProductType);
          let isOrionJourney = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney);
          let devDashboard;
          let quotePDF;

          if (!this.utilityService.isEmptyOrNull(sessionStorage.getItem(userEnums.IsDeveloper))) {
            devDashboard = this.storageService.getAESDecryptedData(userEnums.IsDeveloper);
          }

          else if (!this.utilityService.isEmptyOrNull(sessionStorage.getItem(userEnums.QuotePDFData))) {
            quotePDF = this.storageService.getAESDecryptedData(userEnums.QuotePDFData);
          }

          if (this.sessionService.has(userEnums.AuthToken)) {
            this.sessionService.clear();
          }

          this.storageService.setAESEncryptedDataLocal(userEnums.ChannelData, this.channelData);
          this.storageService.setAESEncryptedData(userEnums.SessionReference, sessionReference);
          this.storageService.setAESEncryptedData(userEnums.LoginCredential, credentials);
          this.storageService.setAESEncryptedData(userEnums.SwapParameters, swapParameters);
          this.storageService.setAESEncryptedData(userEnums.HealthFormData, formData);
          this.storageService.setAESEncryptedData(userEnums.AgentDetails, agentData);
          this.storageService.setAESEncryptedData(userEnums.SubProductCode, subProductCode);
          this.storageService.setAESEncryptedData(userEnums.RenewalFormData, renewalData);
          this.storageService.setAESEncryptedData(userEnums.ChannelData, channelData);
          this.storageService.setAESEncryptedData(userEnums.ProductType, productType);
          this.storageService.setAESEncryptedData(userEnums.IsOrionJourney, isOrionJourney);
          if (!this.utilityService.isEmptyOrNull(devDashboard)) {
            this.storageService.setAESEncryptedData(userEnums.IsDeveloper, devDashboard);
          }

          else if (!this.utilityService.isEmptyOrNull(quotePDF)) {
            this.storageService.setAESEncryptedData(userEnums.QuotePDFData, quotePDF);
          }

          if (!this.utilityService.isEmptyOrNull(sessionStorage.getItem(userEnums.IsDeveloper))) {
            return this.developerLoginService.login(credentials).pipe(
              concatMap((res: CreateLoginTokenResponse) => {
                if (res.StatusCode == Constants.statusCode_success) {
                  this.storageService.setAESEncryptedData(userEnums.AuthToken, res.Data.Token);
                  this.storageService.setAESEncryptedData(userEnums.UopqksmlN, credentials.Uowkjsdjssrme);

                  let updatedRequest = this.getHeaders(request);
                  return next.handle(updatedRequest).pipe(
                    map((event: HttpEvent<any>) => {
                      if (event instanceof HttpResponse) {
                        if (event.body.Data == undefined || event.body.Data == "" || event.body.Data == null) {
                          return event;
                        }
                        else {
                          let decryptedData = JSON.parse(this.encryptDecryptService.finalRsaDecryption(event.body.Data));
                          event = event.clone({
                            body: {
                              ...event.body,
                              'Data': decryptedData,
                            }
                          });
                          this.spinner.hide();
                          return event;
                        }
                      }
                      this.authService.check();
                      return event;
                    })
                  )
                }
                else {
                  this.spinner.hide();
                  this.developerLoginService.logoutRedirectToLogin();
                  // If login api only fails then redirect to previous dashboard
                  // window.location.href = '';
                  return null;
                }
              }),
              finalize(() => {
                this.spinner.handleError(response);
                this.spinner.hide();
              }),
              catchError((refreshError) => {
                // If login fails
                // If this also fails redirect it to dashboard
                // window.location.href  = '';
                this.spinner.hide();
                return throwError(() => refreshError);
              }),
            )
          }

          else {
            let loginRequest = Object.assign(credentials, {sessionId:channelData.SID});
            return this.agentService_A.login(loginRequest).pipe(
              concatMap((res: CreateLoginTokenResponse) => {

                if (!request.url.includes('GetRenewalCount') && !request.url.includes('CreateKYCTokenV1')) {
                  this.spinner.show();
                }

                if (res.StatusCode == Constants.statusCode_success) {

                  this.storageService.setAESEncryptedData(userEnums.AuthToken, res.Data.Token);
                  this.storageService.setAESEncryptedData(userEnums.UopqksmlN, credentials.Uowkjsdjssrme);

                  let updatedRequest = this.getHeaders(request);

                  return next.handle(updatedRequest).pipe(
                    map((event: HttpEvent<any>) => {
                      if (event instanceof HttpResponse) {

                        if (event.body.Data == undefined || event.body.Data == "" || event.body.Data == null) {
                          return event;
                        }

                        else {
                          if (reqUrl.includes('Intranet')) {
                            let decryptedData = event.body.Data;
                            event = event.clone({
                              body: {
                                ...event.body,
                                'Data': decryptedData,
                              }
                            });
                            this.spinner.hide();
                            return event;
                          }
                          else {
                            let decryptedData = JSON.parse(this.encryptDecryptService.finalRsaDecryption(event.body.Data));
                            event = event.clone({
                              body: {
                                ...event.body,
                                'Data': decryptedData,
                              }
                            });
                            this.spinner.hide();
                            return event;
                          }
                        }
                      }
                      this.authService.check();
                      return event;
                    })
                  )
                }
                else {
                  this.spinner.hide();
                  this.agentService_A.logout();
                  // If login api only fails then redirect to previous dashboard
                  // window.location.href = '';
                  return null;
                }
              }),
              finalize(() => {
                this.spinner.handleError(response);
                this.spinner.hide();
              }),
              catchError((refreshError) => {
                // If login fails
                // If this also fails redirect it to dashboard
                // window.location.href  = '';
                this.spinner.hide();
                return throwError(() => refreshError);
              }),
            )
          }
        }
        else {
          this.spinner.hide();
        }
        return throwError(() => error);
      })
    );
  }
  getHeaders(request) {
    let uid = 'Uowkjsdjssrme';
    let req = request.clone({
      'Authorization': '',
      'Access-Control-Allow-Origin':  'GET,POST'
    });
    let authToken = this.storageService.getAESDecryptedData(userEnums.AuthToken);
    let userName = this.storageService.getAESDecryptedData(userEnums.UopqksmlN);
 
    let headers = {
      'Content-Type': "application/json",
      'Access-Control-Allow-Methods': 'GET,POST',
      [`${uid}`]: req.url.includes('NysaRedirection') ? this.encryptDecryptService.finalRsaEncryption(req.body.IMID) : this.encryptDecryptService.finalRsaEncryption(userName),
      'AppName': this.encryptDecryptService.finalRsaEncryption('critishield'),
      'SessionReference': this.storageService.getAESDecryptedData(userEnums.SessionReference)
    };
    let conditionalData;
    if (req.url.includes('NysaRedirection')) {
      let utcTime = this.utilityService.getUTCTime();
      let encryptionText = `NysaHealth|NysaHealth@123| ${utcTime}`;
      let token = this._encryDecryService.PayloadencryptAES(encryptionText);
      this.storageService.setAESEncryptedData(userEnums.EncryptionText, token);
      conditionalData = {
        'NRedirectionAuthorization': this.storageService.getAESDecryptedData(userEnums.EncryptionText)
      }
    } else {
      conditionalData = {
        'Authorization': authToken || ''
      }
    }
    Object.assign(headers, conditionalData)
 
    if (req.url.includes('DocUpload')) {
      delete headers['Content-Type'];
    }
    if (userName) {
      req = request.clone({
        setHeaders: headers
      })
    }
    return req;
  }

}