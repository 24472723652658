import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { GenericService } from 'src/app/shared/services/generic.service';
import { EndorsedPolicyDownloadRequest, EndorsedPolicyRequest, FetchEndoredPolicyRequest } from '../interface/endorsement';

@Injectable({
  providedIn: 'root'
})
export class EndorsementService {

  constructor(private generic : GenericService) { }

  readonly baseURI = `${environment.baseURL}`;
  readonly syncEndorsedDetails = 'Health/EndorsedPolicy';
  readonly fetchEndorsedPolicy = 'Policy/FetchEndorsePolicy';
  readonly downloadEndorsePolicyURL = 'File/DownloadEndorsePolicy';

  setEndorsedPolicy(data: EndorsedPolicyRequest): Observable<any> {
    const endpoint = `${this.baseURI}${this.syncEndorsedDetails}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  fetchEndorsedPolicyData(data: FetchEndoredPolicyRequest): Observable<any> {
    const endpoint = `${this.baseURI}${this.fetchEndorsedPolicy}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  downloadEndorsePolicy(data: EndorsedPolicyDownloadRequest) {
    const endpoint = `${this.baseURI}${this.downloadEndorsePolicyURL}`;
    return this.generic.genericService<any>(endpoint, data);
  }

}
