import { Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { TravelDataStorageService } from '../travel.service';
import { Subscription } from 'rxjs';
import { TravelDataStorage, TravelModal } from '../travel.modal';
import { MatSelect } from '@angular/material/select';
import { healthToggle, patterns } from 'src/app/shared/enums/healthEnums';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { TravelService } from 'src/app/shared/services/travel.service';
import { Constants } from 'src/app/shared/constants/constants';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupButton, popupButtonAction, popupHeader, popupImgPath, popupType } from 'src/app/shared/enums/popupEnums';
import * as _ from 'underscore';
import { WrapperService } from 'src/app/shared/proxy-services/wrapper.service';
import { IsKycPilotUserResponsePayload } from 'src/app/shared/interface/wrapper';

@Component({
  selector: 'app-travel-applicant-details',
  templateUrl: './travel-applicant-details.component.html',
  styleUrls: ['./travel-applicant-details.component.scss']
})
export class TravelApplicantDetailsComponent implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  otherDetailsMapped = false;
  numberOfRecords: number;
  isEKYCMandatory: boolean = false;
  customerType: string = "New";
  isKYCDoneSuccessfully: boolean = false;
  ekycMandatory: boolean = false;
  applicantForm: FormGroup;
  travelModal: TravelModal = new TravelModal();
  titleMaster: any = [];
  isSubmitted: boolean = false;
  travelFormData: TravelDataStorage;
  isCorrespondenceAddressVisible: boolean = false;
  healthFormData: TravelDataStorage;
  disabled: boolean = false;
  kycDetails: any;
  countryList: any[] = [];
  patchingDone: boolean = true;
  datepickerMobile: boolean = false;
  kycData: any;
  showCorrespondenceStateCityDetails: boolean = false;
  showPermanentStateCityDetails: boolean = false;
  applicantMinMaxDate: { maxDate: Date; minDate: Date; };
  maxDate: Date;
  isPilotUser: boolean = false;
  isKYCDoneSuccessfullyForPilotuser: boolean = true;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private rtoListService: RTOListService,
    private popupService: PopupService,
    public utilityService: UtilityService,
    private customStepperService: CustomStepperService,
    private renderer: Renderer2,
    private travelDataStorageService: TravelDataStorageService,
    private travelService: TravelService,
    private el: ElementRef,
    private wrapperService: WrapperService
  ) { }

  ngOnInit() {
    this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);
    this.onLoadSteps();
    if (window.innerWidth < 768) {
      this.datepickerMobile = true;
    } else {
      this.datepickerMobile = false;
    }
  }

  onLoadSteps() {
    this.travelFormData = this.travelDataStorageService.getTravelDataStorage();
    this.titleMaster = this.travelModal.title;
    this.fetchIsPilotUser();
    if (!this.travelFormData.kycDataSet) {
      this.customerType = "New";
      this.isKYCDoneSuccessfully = false;
      this.ekycMandatory = false;
    } else {
      this.isPilotUser = this.travelFormData.kycStatus.isPilotUser;
      this.isKYCDoneSuccessfully = this.travelFormData.kycStatus.isKYCDoneSuccessfully;
      this.ekycMandatory = this.travelFormData.kycStatus.ekycMandatory;
    }
    this.createApplicantForm();
    if (this.travelFormData.quoteFormData.productName == 'Student Travel') {
      this.fetchAllCountriesDetails();
    }
  }

  fetchIsPilotUser() {
    this.wrapperService.IsKycPilotUser(Constants.NysaTravel).subscribe({
      next: (resp: IsKycPilotUserResponsePayload) => {
        if (resp.StatusCode == Constants.statusCode_success) {
          this.isPilotUser = this.utilityService.isEmptyOrNull(resp.Data.is_pilot_user) ? false : resp.Data.is_pilot_user;
        }
        else {
          this.isPilotUser = false;
        }
        this.travelFormData.kycStatus.isPilotUser = this.isPilotUser;

      }
    })
  }

  getApplicantFormData() {
    let obj: any = {};
    let kycFormData = this.travelFormData.kycFormData;
    let isKycDataSet = this.travelFormData.kycDataSet;
    if (isKycDataSet) {
      obj = {
        panCardNumber: kycFormData.applicantDetails.applicantPanCardNumber,
        title: kycFormData.applicantDetails.applicantTitle,
        fullName: kycFormData.applicantDetails.applicantFullName,
        dateOfBirth: kycFormData.applicantDetails.applicantDateOfBirth,
        correspondenceAddressDetails: {
          address1: kycFormData?.correspondenceAddress?.correspondenceAddress1,
          address2: kycFormData?.correspondenceAddress?.correspondenceAddress2,
          landmark: kycFormData?.correspondenceAddress?.correspondenceLandmark,
          pincode: kycFormData?.correspondenceAddress?.correspondencePincode,
          state: kycFormData?.correspondenceAddress?.correspondenceState,
          stateId: kycFormData?.correspondenceAddress?.correspondenceStateId,
          city: kycFormData?.correspondenceAddress?.correspondenceCity,
          cityId: kycFormData?.correspondenceAddress?.correspondenceCityId,
          cityList: kycFormData?.correspondenceAddress?.correspondenceCityList
        },
        permanentAddressDetails: {
          address1: kycFormData?.permanentAddress?.permanentAddress1,
          address2: kycFormData?.permanentAddress?.permanentAddress2,
          landmark: kycFormData?.permanentAddress?.permanentLandmark,
          pincode: kycFormData?.permanentAddress?.permanentPincode,
          state: kycFormData?.permanentAddress?.permanentState,
          stateId: kycFormData?.permanentAddress?.permanentStateId,
          city: kycFormData?.permanentAddress?.permanentCity,
          cityId: kycFormData?.permanentAddress?.permanentCityId,
          cityList: kycFormData?.permanentAddress?.permanentCityList,
          email: kycFormData?.permanentAddress?.applicantEmailAddress,
          mobileNumber: kycFormData?.permanentAddress?.applicantMobileNumber,
          maritalStatus: kycFormData?.permanentAddress?.applicantMaritalStatus,
          isPermanentAddressSame: kycFormData?.permanentAddress?.isPermanentAddressSame
        },
        gstDetails: {
          isGstRegistered: kycFormData?.otherDetails?.isGstRegistered,
          constitutionOfBusiness: kycFormData?.otherDetails?.constitutionOfBusiness,
          panNumber: kycFormData?.otherDetails?.panNumber,
          customerType: kycFormData?.otherDetails?.customerType,
          gstStatus: kycFormData?.otherDetails?.gstStatus,
          isGstApplicable: kycFormData?.otherDetails?.isGstApplicable,
          gstin: kycFormData?.otherDetails?.gstin,
          uin: kycFormData?.otherDetails?.uin,
        },
        universityDetails: {
          instituteName: kycFormData?.universityDetails?.instituteName,
          instituteAddress1: kycFormData?.universityDetails?.instituteAddress1,
          instituteAddress2: kycFormData?.universityDetails?.instituteAddress2,
          instituteCountry: kycFormData?.universityDetails?.instituteCountry,
          instituteCountryList: kycFormData?.universityDetails.instituteCountryList,//this.countryList || JSON.parse(localStorage.getItem('instituteCountryList')),
          instituteState: kycFormData?.universityDetails?.instituteState,
          instituteCity: kycFormData?.universityDetails?.instituteCity,
          institutePincode: kycFormData?.universityDetails?.institutePincode,
        },
        sponsorDetails: {
          sponsorType: kycFormData?.sponsorDetails?.sponsorType,
          sponsorTitle: kycFormData?.sponsorDetails?.sponsorTitle,
          sponsorName: kycFormData?.sponsorDetails?.sponsorName,
        },
        nomineeDetails: {
          nomineeTitle: kycFormData?.nomineeDetails?.nomineeTitle,
          nomineeName: kycFormData?.nomineeDetails?.nomineeName,
          isNomineeSameAsSponsor:
            kycFormData?.nomineeDetails?.isNomineeSameAsSponsor,
        },
      };
      this.kycData = kycFormData.kycDetails;
    } else {
      obj = {
        panCardNumber: "",
        title: "",
        fullName: "",
        dateOfBirth: "",
        correspondenceAddressDetails: {
          address1: "",
          address2: "",
          landmark: "",
          pincode: "",
          state: "",
          stateId: "",
          city: "",
          cityId: ""
        },
        permanentAddressDetails: {
          address1: "",
          address2: "",
          landmark: "",
          pincode: "",
          state: "",
          stateId: "",
          city: "",
          cityId: "",
          gender: "",
          email: "",
          mobileNumber: "",
          maritalStatus: "",
          isPermanentAddressSame: false
        },
        gstDetails: {
          isGstRegistered: 'No',
          constitutionOfBusiness: "",
          panNumber: "",
          customerType: "",
          gstStatus: "",
          isGstApplicable: "GSTIN",
          gstin: "",
          uin: "",
        },
        universityDetails: {
          instituteName: "",
          instituteAddress1: "",
          instituteAddress2: "",
          instituteCountry: "",
          instituteCountryList: [],
          instituteState: "",
          instituteCity: "",
          institutePincode: "",
        },
        sponsorDetails: {
          sponsorType: "Individual",
          sponsorTitle: "",
          sponsorName: "",
        },
        nomineeDetails: {
          nomineeTitle: "",
          nomineeName: "",
          isNomineeSameAsSponsor: "No",
        },
      };
    }
    return obj;
  }

  createApplicantForm() {
    let obj = this.getApplicantFormData();

    let fields = {
      applicantPanCardNumber: [{ value: obj.panCardNumber, disabled: false }],
      applicantTitle: [obj.title, [Validators.required]],
      applicantFullName: [{ value: obj.fullName, disabled: false }, [Validators.required]],
      applicantDateOfBirth: [{ value: obj.dateOfBirth, disabled: false }, [Validators.required]],
      correspondenceAddress: this.fb.group({
        correspondenceAddress1: [obj.correspondenceAddressDetails.address1, [Validators.required]],
        correspondenceAddress2: [obj.correspondenceAddressDetails.address2],
        correspondenceLandmark: [obj.correspondenceAddressDetails.landmark],
        correspondencePincode: [obj.correspondenceAddressDetails.pincode, [Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6)]],
        correspondencePincodeId: [obj.correspondenceAddressDetails.correspondenceAddressDetails],
        correspondenceState: [obj.correspondenceAddressDetails.state, [Validators.required]],
        correspondenceStateId: [obj.correspondenceAddressDetails.stateId],
        correspondenceCityList: [obj.correspondenceAddressDetails.cityList],
        correspondenceCity: [obj.correspondenceAddressDetails.city, [Validators.required]],
        correspondenceCityId: [obj.correspondenceAddressDetails.cityId]
      }),
      permanentAddress: this.fb.group({
        permanentAddress1: [{ value: obj.permanentAddressDetails.address1, disabled: false }, [Validators.required]],
        permanentAddress2: [{ value: obj.permanentAddressDetails.address2, disabled: false }],
        permanentLandmark: [{ value: obj.permanentAddressDetails.landmark, disabled: false }],
        permanentPincode: [{ value: obj.permanentAddressDetails.pincode, disabled: false }, [Validators.required, Validators.pattern(patterns.pincode), Validators.maxLength(6), Validators.minLength(6)]],
        permanentPincodeId: [obj.permanentAddressDetails.address1],
        permanentState: [{ value: obj.permanentAddressDetails.state, disabled: false }, [Validators.required]],
        permanentStateId: [obj.permanentAddressDetails.stateId],
        permanentCityList: [obj.permanentAddressDetails.cityList],
        permanentCity: [{ value: obj.permanentAddressDetails.city, disabled: false }, [Validators.required]],
        permanentCityId: [obj.permanentAddressDetails.cityId],
        applicantMaritalStatus: [""],
        applicantMaritalStatusName: [""],
        applicantEmailAddress: [obj.permanentAddressDetails.email, [Validators.required, Validators.pattern(patterns.email)]],
        applicantMobileNumber: [obj.permanentAddressDetails.mobileNumber, [Validators.required, Validators.pattern(patterns.phoneNumber)]],
        isPermanentAddressSame: [obj.permanentAddressDetails.isPermanentAddressSame, [Validators.required]]
      }),
      otherDetails: this.fb.group({
        isGstRegistered: [obj.gstDetails.isGstRegistered, [Validators.required]],
        constitutionOfBusiness: [obj.gstDetails.constitutionOfBusiness],
        panNumber: [obj.gstDetails.panNumber, [Validators.pattern(patterns.panNo)]],
        customerType: [obj.gstDetails.customerType],
        gstStatus: [obj.gstDetails.gstStatus],
        isGstApplicable: [{ value: obj.gstDetails.isGstApplicable, disabled: false }],
        gstin: [{ value: obj.gstDetails.gstin, disabled: false }],
        uin: [{ value: obj.gstDetails.uin, disabled: false }]
      }),
    }
    if (this.travelFormData.quoteFormData.productName == 'Student Travel') {
      Object.assign(fields, this.conditionalObject(obj));
    }
    this.applicantForm = this.fb.group(fields);
    this.patchingDone = true;

    if (this.travelFormData.kycDataSet) {
      this.showCorrespondenceStateCityDetails = fields['correspondenceAddress'].value.correspondencePincode.length == 6;
      this.showPermanentStateCityDetails = fields['permanentAddress'].value.permanentPincode.length == 6;
      let applicantDetails = this.travelFormData.kycFormData;
      this.applicantForm.controls["permanentAddress"]["controls"]["permanentAddress1"].disable();
      this.applicantForm.controls["permanentAddress"]["controls"]["permanentAddress2"].disable();
      this.applicantForm.controls["permanentAddress"]["controls"]["permanentPincode"].disable();
      this.applicantForm.controls["permanentAddress"]["controls"]["permanentState"].disable();
      if (applicantDetails.otherDetails.isGstRegistered) {
        this.changeAreRegisteredWithGST('Yes');
        this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].disable();
        this.applicantForm.controls["otherDetails"]["controls"]["gstin"].disable();
        this.applicantForm.controls["otherDetails"]["controls"]["uin"].disable();
      } else {
        this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].enable();
        this.applicantForm.controls["otherDetails"]["controls"]["gstin"].enable();
        this.applicantForm.controls["otherDetails"]["controls"]["uin"].enable();
      }
    } else {
      this.enableApplicantBasicControls();
    }
  }
  conditionalObject(obj) {
    return {
      universityDetails: this.fb.group({
        instituteName: [obj.universityDetails.instituteName, [Validators.required]],
        instituteAddress1: [obj.universityDetails.instituteAddress1, [Validators.required]],
        instituteAddress2: [obj.universityDetails.instituteAddress2],
        instituteCountry: [obj.universityDetails.instituteCountry],
        instituteCountryList: [obj.universityDetails.instituteCountryList],
        instituteState: [obj.universityDetails.instituteState, [Validators.required]],
        instituteCity: [obj.universityDetails.instituteCity, [Validators.required]],
        institutePincode: [obj.universityDetails.institutePincode, [Validators.required, Validators.pattern(patterns.alphaNumric)]],
      }),
      sponsorDetails: this.fb.group({
        sponsorType: [obj.sponsorDetails.sponsorType, [Validators.required]],
        sponsorTitle: [obj.sponsorDetails.sponsorTitle, [Validators.required]],
        sponsorName: [obj.sponsorDetails.sponsorName, [Validators.required]],
      }),
      nomineeDetails: this.fb.group({
        nomineeTitle: [obj.nomineeDetails.nomineeTitle, [Validators.required]],
        nomineeName: [obj.nomineeDetails.nomineeName, [Validators.required]],
        isNomineeSameAsSponsor: [obj.nomineeDetails.isNomineeSameAsSponsor, [Validators.required]],
      })
    }
  }
  enableApplicantBasicControls() {
    this.applicantForm.controls["applicantTitle"].enable();
    this.applicantForm.controls["applicantFullName"].enable();
    this.applicantForm.controls["applicantDateOfBirth"].enable();
    this.enablepermanentAddress()
  }

  enablepermanentAddress() {
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentAddress1"].enable();
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentAddress2"].enable();
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentPincode"].enable();
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentState"].enable();
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentCity"].enable();
  }

  changeCustomerType(event) {
    this.isEKYCMandatory = event.ekycMandatory;
    this.travelFormData.kycFormData = this.travelDataStorageService.resetKYCdata();
    this.travelFormData.kycFormData.customerType = event.customerType;
    this.isKYCDoneSuccessfully = event.isKYCDoneSuccessfully;
    this.enableApplicantBasicControls();
  }

  fetchKycDetails(event) {
    this.kycDetails = event;
    this.isKYCDoneSuccessfully = event.isKYCDoneSuccessfully;
    this.enableApplicantBasicControls();
    let kycDetails = event.applicantDetails.kycDetails;
    if (kycDetails.ilKycReferenceNumber !== "" || this.utilityService.isEmptyOrNull(kycDetails.CKYCID)) {
      if (kycDetails.statusMessage == 'ACCEPTED_FOR_MANUAL_QC') { //Constants.statusMsg_Manual
        if (!this.isPilotUser) {
          this.patchKYCDetails(event);
          let isSelf = this.travelFormData.quoteFormData.members.find(x => x['RelationshipwithApplicant'].toUpperCase() === 'SELF')
          if (isSelf) {
            this.patchValuesOnSelf(event); this.enablepermanentAddress();
          } else {
            this.enableApplicantBasicControls();
          }
        }
        else {
          this.isKYCDoneSuccessfully = false;
          this.isKYCDoneSuccessfullyForPilotuser = false;
          // this.utilityService.popUpDialog('Kindly complete E-KYC/C-KYC to proceed further.');
        }

      }
      else if ((kycDetails.statusMessage).toLowerCase() == 'success') { //Constants.statusMsg_Success
        this.patchKYCDetails(event); this.patchApplicantValuesOnOtherForms(event);
      }
    } else {
      this.patchKYCDetails(event); this.patchApplicantValuesOnOtherForms(event);
    }
  }

  fetchExistingCustomerDetails(event) {
    this.kycDetails = event;
    this.isKYCDoneSuccessfully = event.isKYCDoneSuccessfully;

    this.patchKYCDetails(event);
  }

  patchValuesOnSelf(data) {
    let selfMemberIndex = this.travelFormData.quoteFormData.members.findIndex(x => x['RelationshipwithApplicant'].toUpperCase() === 'SELF')
    let selfInsuredMemberDetails = this.travelFormData.insuredFormData.insuredMemberDetails[selfMemberIndex]['controls'];
    selfInsuredMemberDetails.forEach((control, index) => {
      let controlName = control['controlName'];
      if (controlName == 'title') {
        this.applicantForm.controls["applicantTitle"].setValue(control[controlName]); //applicantDetails.title
      } else if (controlName == 'fullName') {
        this.applicantForm.controls["applicantFullName"].setValue(control[controlName]); //applicantDetails.fullName
        this.applicantForm.controls["applicantFullName"].disable();
      } else if (controlName == 'dob') {
        this.applicantForm.controls["applicantDateOfBirth"].setValue(control[controlName]); //applicantDetails.applicantDateOfBirth
        this.applicantForm.controls["applicantDateOfBirth"].disable();
      }
    })
    this.travelDataStorageService.setTravelDataStorage(this.travelFormData);
  }

  patchApplicantValuesOnOtherForms(data) {
    this.disabled = true;
    let applicantDetails = data.applicantDetails;
    let selfMemberIndex = this.travelFormData.quoteFormData.members.findIndex(x => x['RelationshipwithApplicant'].toUpperCase() === 'SELF')
    if (selfMemberIndex != -1) {
      let selfInsuredMemberDetails = this.travelFormData.insuredFormData.insuredMemberDetails[selfMemberIndex]['controls'];
      selfInsuredMemberDetails.forEach((control, index) => {
        let controlName = control['controlName'];
        if (controlName == 'title') {
          this.travelFormData.insuredFormData.insuredMemberDetails[selfMemberIndex]['controls'][index][controlName] = applicantDetails.title;
          this.applicantForm.controls["applicantTitle"].setValue(applicantDetails.title);
        } else if (controlName == 'fullName') {
          this.travelFormData.insuredFormData.insuredMemberDetails[selfMemberIndex]['controls'][index][controlName] = applicantDetails.fullName;
          this.applicantForm.controls["applicantFullName"].setValue(applicantDetails.fullName);
          this.applicantForm.controls["applicantFullName"].disable();
        } else if (controlName == 'dob') {
          this.travelFormData.insuredFormData.insuredMemberDetails[selfMemberIndex]['controls'][index][controlName] = applicantDetails.dateOfBirth;
          this.travelFormData.quoteFormData.members[selfMemberIndex]['DOB'] = applicantDetails.dateOfBirth;
          this.applicantForm.controls["applicantDateOfBirth"].setValue(applicantDetails.dateOfBirth);
          this.applicantForm.controls["applicantDateOfBirth"].disable();
        }
      })
      this.travelDataStorageService.setTravelDataStorage(this.travelFormData);
    }
  }

  isApplicantPanCardNoVisible() {
    if (parseInt(this.travelFormData.premiumFormData.totalPremium) > 80000) {
      this.applicantForm.controls["applicantPanCardNumber"].setValidators([Validators.required]);
      this.applicantForm.controls["applicantPanCardNumber"].updateValueAndValidity();
      return true;
    } else {
      this.applicantForm.controls["applicantPanCardNumber"].clearValidators();
      this.applicantForm.controls["applicantPanCardNumber"].updateValueAndValidity();
      return false;
    }
  }

  patchKYCDetails(data) {
    this.isEKYCMandatory = true;
    this.disabled = true;
    this.isKYCDoneSuccessfully = data.isKYCDoneSuccessfully;
    let applicantDetails = data.applicantDetails;
    let date = new Date(); date.setMonth(date.getMonth() - parseInt('216'));
    this.maxDate = date;
    if (applicantDetails.kycDetails.ekycMode == 'PAN') {
      this.applicantForm.controls["applicantPanCardNumber"].setValue(applicantDetails.panCardNumber);
      this.applicantForm.controls["applicantPanCardNumber"].disable()
    }

    this.applicantForm.controls["applicantTitle"].setValue(applicantDetails.title);
    this.applicantForm.controls["applicantFullName"].setValue(applicantDetails.fullName);
    this.applicantForm.controls["applicantFullName"].disable();
    this.applicantForm.controls["applicantDateOfBirth"].setValue(applicantDetails.dateOfBirth);
    this.applicantForm.controls["applicantDateOfBirth"].disable();

    this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceAddress1"].patchValue(applicantDetails.correspondenceAddressDetails.address1);
    this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceAddress2"].patchValue(applicantDetails.correspondenceAddressDetails.address2);
    this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceLandmark"].patchValue(applicantDetails.correspondenceAddressDetails.landmark);
    this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondencePincode"].patchValue(applicantDetails.correspondenceAddressDetails.pincode);
    this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceState"].patchValue(applicantDetails.correspondenceAddressDetails.state);
    this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceCity"].patchValue(applicantDetails.correspondenceAddressDetails.city);
    this.changePincode(applicantDetails.correspondenceAddressDetails.pincode, "correspondenceAddress");

    this.applicantForm.controls["permanentAddress"]["controls"]["permanentAddress1"].patchValue(applicantDetails.permanentAddressDetails.address1);
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentAddress1"].disable();
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentAddress2"].patchValue(applicantDetails.permanentAddressDetails.address2);
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentAddress2"].disable();
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentLandmark"].patchValue(applicantDetails.permanentAddressDetails.landmark);
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentPincode"].patchValue(applicantDetails.permanentAddressDetails.pincode);
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentPincode"].disable();
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentState"].patchValue(applicantDetails.permanentAddressDetails.state);
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentState"].disable();
    this.applicantForm.controls["permanentAddress"]["controls"]["permanentCity"].patchValue(applicantDetails.permanentAddressDetails.city);
    this.applicantForm.controls["permanentAddress"]["controls"]["applicantEmailAddress"].patchValue(applicantDetails.permanentAddressDetails.email);
    this.applicantForm.controls["permanentAddress"]["controls"]["applicantMobileNumber"].patchValue(applicantDetails.permanentAddressDetails.mobileNumber);
    this.applicantForm.controls["permanentAddress"]["controls"]["isPermanentAddressSame"].patchValue(applicantDetails.permanentAddressDetails.isPermanentAddressSame);

    this.changePincode(applicantDetails.permanentAddressDetails.pincode, "permanentAddress");
    //need to check
    // this.isPermanentSameAddress(applicantDetails.permanentAddressDetails.isPermanentAddressSame);

    this.applicantForm.controls["otherDetails"]["controls"]["isGstRegistered"].patchValue(applicantDetails.gstDetails.isGstRegistered);
    this.applicantForm.controls["otherDetails"]["controls"]["constitutionOfBusiness"].patchValue(applicantDetails.gstDetails.constitutionOfBusiness);
    this.applicantForm.controls["otherDetails"]["controls"]["panNumber"].patchValue(applicantDetails.gstDetails.panNumber);
    this.applicantForm.controls["otherDetails"]["controls"]["customerType"].patchValue(applicantDetails.gstDetails.customerType);
    this.applicantForm.controls["otherDetails"]["controls"]["gstStatus"].patchValue(applicantDetails.gstDetails.gstStatus);
    this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].patchValue(applicantDetails.gstDetails.isGstApplicable);
    this.applicantForm.controls["otherDetails"]["controls"]["gstin"].patchValue(applicantDetails.gstDetails.gstin);
    this.applicantForm.controls["otherDetails"]["controls"]["uin"].patchValue(applicantDetails.gstDetails.uin);
    if (data.customerType == "Existing") {
      if (applicantDetails.gstDetails.isGstRegistered) {
        this.changeAreRegisteredWithGST('Yes');
        this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].patchValue(applicantDetails.gstDetails.isGstApplicable);
        this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].disable();
        this.changeIsGstApplicable(applicantDetails.gstDetails.isGstApplicable);
        this.applicantForm.controls["otherDetails"]["controls"]["gstin"].patchValue(applicantDetails.gstDetails.gstin);
        this.applicantForm.controls["otherDetails"]["controls"]["gstin"].disable();
        this.applicantForm.controls["otherDetails"]["controls"]["uin"].patchValue(applicantDetails.gstDetails.uin);
        this.applicantForm.controls["otherDetails"]["controls"]["uin"].disable();
      } else {
        this.changeAreRegisteredWithGST('No');
        this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].enable();
        this.applicantForm.controls["otherDetails"]["controls"]["gstin"].enable();
        this.applicantForm.controls["otherDetails"]["controls"]["uin"].enable();
      }
    }
  }

  constructToFectchStateCityRequest(pincode): RTOListRequestPayload {
    return { Pincode: pincode, CityID: "" };
  }

  changePincode(value, formGroupName: string) {
    let field = formGroupName == "correspondenceAddress" ? 'showCorrespondenceStateCityDetails' : 'showPermanentStateCityDetails';
    if (value.length == 6) {
      this[field] = true;
      let request = this.constructToFectchStateCityRequest(value);
      this.subscription.push(
        this.rtoListService.getStatesCityByPin(request).subscribe({
          next: (response: RTOListResponsePayload) => {
            if (response.StatusCode == Constants.statusCode_success) {
              if (response.Data.length >= 1) {
                let cityDetails = [];
                response.Data.forEach((cityList) => {
                  cityDetails.push({
                    label: cityList["CityName"],
                    value: cityList["CityID"],
                  });
                });
                let stateName = formGroupName == "correspondenceAddress" ? "correspondenceState" : "permanentState";
                let stateId = formGroupName == "correspondenceAddress" ? "correspondenceStateId" : "permanentStateId";
                let cityList = formGroupName == "correspondenceAddress" ? "correspondenceCityList" : "permanentCityList";
                let city = formGroupName == "correspondenceAddress" ? "correspondenceCity" : "permanentCity";
                let cityId = formGroupName == "correspondenceAddress" ? "correspondenceCityId" : "permanentCityId";

                this.applicantForm.controls[formGroupName]["controls"][stateName].patchValue(response.Data[0].StateName);
                this.applicantForm.controls[formGroupName]["controls"][stateId].patchValue(response.Data[0].StateId);
                this.applicantForm.controls[formGroupName]["controls"][cityList].patchValue(cityDetails);
                this.applicantForm.controls[formGroupName]["controls"][city].patchValue(response.Data[0].CityName);
                this.applicantForm.controls[formGroupName]["controls"][cityId].patchValue(response.Data[0].CityID);

                if (formGroupName == "permanentAddress") {
                  if (response.Data.length > 1) {
                    this.applicantForm.controls["permanentAddress"]["controls"]["permanentCity"].enable();
                  } else {
                    this.applicantForm.controls["permanentAddress"]["controls"]["permanentCity"].disable();
                  }
                }
              }
            }
          },
          error: (error) => { },
        })
      );
    } else {
      this[field] = false;
    }
  }

  changeAreRegisteredWithGST(isRegisteredWithGST) {
    if (isRegisteredWithGST == 'Yes') {
      this.applicantForm.controls["otherDetails"]["controls"]["isGstRegistered"].patchValue(isRegisteredWithGST);
      this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].patchValue("GSTIN");
      this.applicantForm.controls["otherDetails"]["controls"]["isGstRegistered"].setValidators(Validators.required);
      this.applicantForm.controls["otherDetails"]["controls"]["isGstRegistered"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["constitutionOfBusiness"].setValidators(Validators.required);
      this.applicantForm.controls["otherDetails"]["controls"]["constitutionOfBusiness"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["panNumber"].setValidators([Validators.required, Validators.pattern(patterns.panNo)]);
      this.applicantForm.controls["otherDetails"]["controls"]["panNumber"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["customerType"].setValidators(Validators.required);
      this.applicantForm.controls["otherDetails"]["controls"]["customerType"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["gstStatus"].setValidators(Validators.required);
      this.applicantForm.controls["otherDetails"]["controls"]["gstStatus"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].setValidators(Validators.required);
      this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["gstin"].setValidators(Validators.required);
      this.applicantForm.controls["otherDetails"]["controls"]["gstin"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["uin"].clearValidators();
      this.applicantForm.controls["otherDetails"]["controls"]["uin"].updateValueAndValidity();
    } else {
      this.applicantForm.controls["otherDetails"]["controls"]["isGstRegistered"].patchValue(isRegisteredWithGST);
      this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].patchValue("UIN");
      this.applicantForm.controls["otherDetails"]["controls"]["isGstRegistered"].clearValidators();
      this.applicantForm.controls["otherDetails"]["controls"]["isGstRegistered"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["constitutionOfBusiness"].clearValidators();
      this.applicantForm.controls["otherDetails"]["controls"]["constitutionOfBusiness"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["panNumber"].clearValidators();
      this.applicantForm.controls["otherDetails"]["controls"]["panNumber"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["customerType"].clearValidators();
      this.applicantForm.controls["otherDetails"]["controls"]["customerType"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["gstStatus"].clearValidators();
      this.applicantForm.controls["otherDetails"]["controls"]["gstStatus"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].clearValidators();
      this.applicantForm.controls["otherDetails"]["controls"]["isGstApplicable"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["gstin"].clearValidators();
      this.applicantForm.controls["otherDetails"]["controls"]["gstin"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["uin"].clearValidators();
      this.applicantForm.controls["otherDetails"]["controls"]["uin"].updateValueAndValidity();
    }
  }

  changeIsGstApplicable(isGstApplicable) {
    if (isGstApplicable == "GSTIN") {
      this.applicantForm.controls["otherDetails"]["controls"]["gstin"].patchValue("");
      this.applicantForm.controls["otherDetails"]["controls"]["gstin"].setValidators(Validators.required, Validators.pattern(patterns.GSTIN));
      this.applicantForm.controls["otherDetails"]["controls"]["gstin"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["uin"].clearValidators();
      this.applicantForm.controls["otherDetails"]["controls"]["uin"].updateValueAndValidity();
    } else {
      this.applicantForm.controls["otherDetails"]["controls"]["uin"].patchValue("");
      this.applicantForm.controls["otherDetails"]["controls"]["gstin"].clearValidators();
      this.applicantForm.controls["otherDetails"]["controls"]["gstin"].updateValueAndValidity();
      this.applicantForm.controls["otherDetails"]["controls"]["uin"].setValidators(Validators.required, Validators.pattern(patterns.UIN));
      this.applicantForm.controls["otherDetails"]["controls"]["uin"].updateValueAndValidity();
    }
  }

  changeSponsorType(type) {
    if (type == "Individual") {
      this.applicantForm.controls["sponsorDetails"]["controls"]["sponsorTitle"].setValidators([Validators.required]);
      this.applicantForm.controls["sponsorDetails"]["controls"]["sponsorTitle"].updateValueAndValidity();
      this.applicantForm.controls["sponsorDetails"]["controls"]["sponsorName"].setValidators([Validators.required]);
      this.applicantForm.controls["sponsorDetails"]["controls"]["sponsorName"].updateValueAndValidity();

      this.applicantForm.controls["nomineeDetails"]["controls"]["isNomineeSameAsSponsor"].patchValue("No");
      this.applicantForm.controls["nomineeDetails"]["controls"]["nomineeTitle"].setValidators([Validators.required]);
      this.applicantForm.controls["nomineeDetails"]["controls"]["nomineeTitle"].updateValueAndValidity();
      this.applicantForm.controls["nomineeDetails"]["controls"]["nomineeName"].setValidators([Validators.required]);
      this.applicantForm.controls["nomineeDetails"]["controls"]["nomineeName"].updateValueAndValidity();
    } else {
      this.applicantForm.controls["sponsorDetails"]["controls"]["sponsorTitle"].clearValidators();
      this.applicantForm.controls["sponsorDetails"]["controls"]["sponsorTitle"].updateValueAndValidity();
      this.applicantForm.controls["sponsorDetails"]["controls"]["sponsorName"].setValidators(Validators.required);
      this.applicantForm.controls["sponsorDetails"]["controls"]["sponsorName"].updateValueAndValidity();
    }
  }

  changeIsPermanentAddressSame(event) {
    this.isCorrespondenceAddressVisible = event.checked;
    let permanentAddress = this.applicantForm['controls']['permanentAddress']['controls'];
    if (event.checked) {
      this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceAddress1"].patchValue(permanentAddress.permanentAddress1.value);
      this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceAddress2"].patchValue(permanentAddress.permanentAddress2.value);
      this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceLandmark"].patchValue(permanentAddress.permanentLandmark.value);
      this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondencePincode"].patchValue(permanentAddress.permanentPincode.value);
      this.applicantForm.controls['correspondenceAddress']["controls"]['correspondenceState'].patchValue(permanentAddress.permanentState.value);
      this.applicantForm.controls['correspondenceAddress']["controls"]['correspondenceStateId'].patchValue(permanentAddress.permanentStateId.value);
      this.applicantForm.controls['correspondenceAddress']["controls"]['correspondenceCityList'].patchValue(permanentAddress.permanentCityList.value);
      this.applicantForm.controls['correspondenceAddress']["controls"]['correspondenceCity'].patchValue(permanentAddress.permanentCity.value);
      this.applicantForm.controls['correspondenceAddress']["controls"]['correspondenceCityId'].patchValue(permanentAddress.permanentCityId.value);
    } else {
      this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceAddress1"].patchValue('');
      this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceAddress2"].patchValue('');
      this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceLandmark"].patchValue('');
      this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondencePincode"].patchValue('');
      this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceState"].patchValue('');
      this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceCity"].patchValue('');
      this.applicantForm.controls["correspondenceAddress"]["controls"]["correspondenceCityList"].patchValue([]);
    }
    this.showCorrespondenceStateCityDetails = this.applicantForm.value['correspondenceAddress'].correspondencePincode.length == 6;
  }

  changeIsNomineeSameAsSponsor(isNomineeSameAsSponsor) {
    let sponsorDetails = this.applicantForm.controls["sponsorDetails"].value;
    if (isNomineeSameAsSponsor == healthToggle.Yes) {
      this.applicantForm.controls["nomineeDetails"]["controls"]["nomineeTitle"].patchValue(sponsorDetails.sponsorTitle);
      this.applicantForm.controls["nomineeDetails"]["controls"]["nomineeName"].patchValue(sponsorDetails.sponsorName);
    } else {
      this.applicantForm.controls["nomineeDetails"]["controls"]["nomineeTitle"].patchValue("");
      this.applicantForm.controls["nomineeDetails"]["controls"]["nomineeName"].patchValue("");
    }
  }

  setApplicantFormData() {
    let constitutionOfBusinessId, customerTypeId, gstStatusId;
    let applicantDetails = this.applicantForm.controls;
    let applicantPanCardNumber = applicantDetails['applicantPanCardNumber'].disabled ? applicantDetails['applicantPanCardNumber'].value : this.applicantForm.value.applicantPanCardNumber;
    let applicantTitle = applicantDetails['applicantTitle'].disabled ? applicantDetails['applicantTitle'].value : this.applicantForm.value.applicantTitle;
    let applicantFullName = applicantDetails['applicantFullName'].disabled ? applicantDetails['applicantFullName'].value : this.applicantForm.value.applicantFullName;
    let applicantDateOfBirth = applicantDetails['applicantDateOfBirth'].disabled ? applicantDetails['applicantDateOfBirth'].value : this.applicantForm.value.applicantDateOfBirth;

    let permanentAddress = this.applicantForm.controls['permanentAddress']['controls'];
    let permanentAddress1 = permanentAddress['permanentAddress1'].disabled ? permanentAddress['permanentAddress1'].value : this.applicantForm.value.permanentAddress.permanentAddress1;
    let permanentAddress2 = permanentAddress['permanentAddress2'].disabled ? permanentAddress['permanentAddress2'].value : this.applicantForm.value.permanentAddress.permanentAddress2;
    let permanentLandmark = permanentAddress['permanentLandmark'].disabled ? permanentAddress['permanentLandmark'].value : this.applicantForm.value.permanentAddress.permanentLandmark;
    let permanentPincode = permanentAddress['permanentPincode'].disabled ? permanentAddress['permanentPincode'].value : this.applicantForm.value.permanentAddress.permanentPincode;
    let permanentState = permanentAddress['permanentState'].disabled ? permanentAddress['permanentState'].value : this.applicantForm.value.permanentAddress.permanentState;
    let permanentCity = permanentAddress['permanentCity'].disabled ? permanentAddress['permanentCity'].value : this.applicantForm.value.permanentAddress.permanentCity;
    this.travelFormData.kycFormData.applicantDetails.applicantPanCardNumber = applicantPanCardNumber;
    this.travelFormData.kycFormData.applicantDetails.applicantTitle = applicantTitle;
    this.travelFormData.kycFormData.applicantDetails.applicantFullName = applicantFullName;
    this.travelFormData.kycFormData.applicantDetails.applicantDateOfBirth = applicantDateOfBirth;

    this.travelFormData.kycFormData.correspondenceAddress = this.applicantForm.value.correspondenceAddress;
    this.travelFormData.kycFormData.permanentAddress = this.applicantForm.value.permanentAddress;
    this.travelFormData.kycFormData.permanentAddress.permanentAddress1 = permanentAddress1;
    this.travelFormData.kycFormData.permanentAddress.permanentAddress2 = permanentAddress2;
    this.travelFormData.kycFormData.permanentAddress.permanentLandmark = permanentLandmark;
    this.travelFormData.kycFormData.permanentAddress.permanentPincode = permanentPincode;
    this.travelFormData.kycFormData.permanentAddress.permanentState = permanentState;
    this.travelFormData.kycFormData.permanentAddress.permanentCity = permanentCity;

    let otherDetails = this.applicantForm.controls['otherDetails']['controls'];
    this.travelFormData.kycFormData.otherDetails = this.applicantForm.value.otherDetails;
    if (this.applicantForm?.controls['otherDetails'].value.isGstRegistered == 'Yes') {
      constitutionOfBusinessId = this.travelModal.businessData.filter(x => x['label'].toLowerCase() == this.applicantForm.value.otherDetails.constitutionOfBusiness.toLowerCase())[0].value;
      customerTypeId = this.travelModal.customerTypeData.filter(x => x['label'].toLowerCase() == this.applicantForm.value.otherDetails.customerType.toLowerCase())[0].value;
      gstStatusId = this.travelModal.gstStatusData.filter(x => x['label'].toLowerCase() == this.applicantForm.value.otherDetails.gstStatus.toLowerCase())[0].value;

      this.travelFormData.kycFormData.otherDetails.constitutionOfBusinessId = constitutionOfBusinessId;
      this.travelFormData.kycFormData.otherDetails.customerTypeId = customerTypeId;
      this.travelFormData.kycFormData.otherDetails.gstStatusId = gstStatusId;
      this.travelFormData.kycFormData.otherDetails.isGstApplicable = otherDetails['isGstApplicable'].disabled ? otherDetails['isGstApplicable'].value : this.applicantForm.value.otherDetails.isGstApplicable;
      this.travelFormData.kycFormData.otherDetails.gstin = otherDetails['gstin'].disabled ? otherDetails['gstin'].value : this.applicantForm.value.otherDetails.gstin;
      this.travelFormData.kycFormData.otherDetails.uin = otherDetails['uin'].disabled ? otherDetails['uin'].value : this.applicantForm.value.otherDetails.uin;
    }

    this.travelFormData.kycFormData.kycDetails = this.travelFormData.kycDataSet ? this.kycData : this.kycDetails.applicantDetails.kycDetails;

    if (this.travelFormData.quoteFormData.productName == 'Student Travel') {
      this.travelFormData.kycFormData.universityDetails = this.applicantForm.value.universityDetails;
      this.travelFormData.kycFormData.sponsorDetails = this.applicantForm.value.sponsorDetails;
      this.travelFormData.kycFormData.nomineeDetails = this.applicantForm.value.nomineeDetails;
    }

    this.travelFormData.kycStatus.ekycMandatory = this.travelFormData.kycDataSet ? true : this.kycDetails.ekycMandatory;
    this.travelFormData.kycStatus.isKYCDoneSuccessfully = this.travelFormData.kycDataSet ? true : this.kycDetails.isKYCDoneSuccessfully;

    this.travelFormData.kycDataSet = true;
    this.travelDataStorageService.setTravelDataStorage(this.travelFormData);
    this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
  }

  fetchAllCountriesDetails() {
    this.subscription.push(
      this.travelService.fetchAllCountriesDetails("").subscribe({
        next: (response: any) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.Values.length > 1) {
              response.Data.Values.forEach((x) => {
                this.countryList.push({
                  label: x["Name"],
                  value: x["Value"],
                });
              });
              this.applicantForm.controls['universityDetails'].patchValue({ instituteCountryList: this.countryList });

              let isKycDataSet = this.travelFormData.kycDataSet;
              if (isKycDataSet) {
                this.applicantForm.controls['universityDetails'].patchValue({ instituteCountryList: this.countryList });
              }

            }
          }
        },
        error: (error) => { },
      })
    );
  }

  isPermanentSameAddress(event) {
  }

  kycButtonClicked(event) {
    this.isSubmitted = true;
    if (!this.applicantForm.valid) {
      for (const key of Object.keys(this.applicantForm.controls)) {
        if (_.isObject(this.applicantForm.controls[key]['controls'])) {
          for (const subKey of Object.keys(this.applicantForm.controls[key]['controls'])) {
            if (this.applicantForm.controls[key]['controls'][subKey].invalid) {
              const invalidControl = this.el.nativeElement.querySelector('#' + subKey + '.ng-invalid');
              invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
              invalidControl.focus();
              break;
            }
          }
        } else {
          if (this.applicantForm.controls[key].invalid) {
            const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
            invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
            invalidControl.focus();
            break;
          }
        }

      }
    } else {
      this.setApplicantFormData();
      this.travelDataStorageService.setTravelDataStorage(this.travelFormData);
      this.customStepperService.emitStepData({ status: true, buttonId: 'f4' });
    }
  }

  validPermanentPincode() {
    return this.applicantForm?.value?.permanentAddress?.permanentPincode?.length == 6 ||
      this.applicantForm.controls['permanentAddress'].value.permanentPincode.length == 6
  }

  isDisabled() {
    return this.disabled ? true : false;
  }

  showGenericPopup(msg): Promise<any> {
    return new Promise((resolve: any) => {
      let popupData: PopupModal = {
        popupType: popupType.generic,
        imgName: popupImgPath.warningGif,
        header: popupHeader.error,
        description: msg,
        buttonLabel: popupButton.ok,
        buttonAction: popupButtonAction.close
      };
      this.popupService.openGenericPopup(popupData);
      resolve();
    })
  }

  onRefresh(status) {
    if (status) {
      this.onLoadSteps();
    }
  }

  validationCheck(eventData, alphaCheck: boolean, numberCheck: boolean) {
    this.utilityService.dynamicControlsAlphaOnly(eventData, alphaCheck);
    this.utilityService.dynamicControlsNumberOnly(eventData, numberCheck);
  }

  private selectScrollListener: () => void;
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen("window", "scroll", () => {
      this.selects.forEach((select) => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((subs) => {
      subs.unsubscribe();
    });
  }
}
