import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HealthAdvantageResponsePayload, ZoneRequestPayload, ZoneResponsePayload, ZoneRefillingRequestPayload, ZoneRefillingResponsePayload, SaveQuoteResponsePayload } from '../interface/health-advantedge';
import { GenericService } from '../services/generic.service';
import { SaveEditQuoteHapV1Request, SaveEditQuoteV1Request } from '../interface/transactionHealthAdvantege';
import { StorageService } from '../services/storage.service';
import { userEnums } from '../../shared/enums/userEnums';
import { UtilityService } from '../services/utility.service';

@Injectable({
  providedIn: 'root'
})
export class HealthAdvantedgeService {

  constructor(private generic: GenericService,
    private utilityService: UtilityService,
    private storageService: StorageService) {

  }
  readonly baseURI = environment.baseURL
  readonly HealthAdvantage = 'HealthAdvantedgePlusMasterData';
  readonly ZoneFromCity = 'GetZonefromCity';
  readonly SaveEditQuoteV1 = 'SaveEditQuoteV1';
  readonly SaveEditQuote = 'SaveEditQuote';
  transactionURI = '';
  masterURI = '';

  getParentURI() {
    this.transactionURI = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'true' ? `${environment.baseURL}OrionHealth/` : `${environment.baseURL}HealthAdvantedge/`;
    this.masterURI = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'true' ? `${environment.baseURL}OrionHealthMaster/` : `${environment.baseURL}HealthAdvantedge/`;
  }

  getHealthAdvantagePlusMasterData(): Observable<HealthAdvantageResponsePayload> {
    this.getParentURI();
    const endpoint = `${this.transactionURI}${this.HealthAdvantage}`;
    // Is refiling added temproary
    return this.generic.genericService<HealthAdvantageResponsePayload>(endpoint);
  }

  getZoneFromCityData(data: ZoneRequestPayload): Observable<ZoneResponsePayload> {
    this.getParentURI();
    const endpoint = `${this.masterURI}${this.ZoneFromCity}`;
    return this.generic.genericService<ZoneResponsePayload>(endpoint, data);
  }

  getZoneFromCityRefillingData(data: ZoneRefillingRequestPayload): Observable<ZoneRefillingResponsePayload> {
    this.getParentURI();
    const endpoint = `${this.masterURI}${this.ZoneFromCity}`;
    return this.generic.genericService<ZoneRefillingResponsePayload>(endpoint, data);
  }

  saveEditQuoteData(data: SaveEditQuoteV1Request | SaveEditQuoteHapV1Request): Observable<SaveQuoteResponsePayload> {
    this.getParentURI();
    const endpoint = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'true' ? `${this.transactionURI}${this.SaveEditQuote}` : `${this.transactionURI}${this.SaveEditQuoteV1}`;
    return this.generic.genericService<SaveQuoteResponsePayload>(endpoint, data);
  }
}