import { Injectable } from '@angular/core';
import { GenericService } from '../services/generic.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HealthElitePlusResponsePayload, GetAddonResponse } from '../interface/healthElitePlus';

@Injectable({
  providedIn: 'root'
})
export class HealthElitePlusService {
  readonly baseURI = `${environment.baseURL}`;
  readonly CHIMasterData = 'Health/MasterData';
  readonly HEPAddonData = 'RenewalHealth/GetAddonApplicablityBySubProductCodeFromILESB';
  readonly SaveEditQuoteV1 = 'Health/SaveEditQuoteV1';
  constructor(private generic:GenericService) { }
  
  getHEPMasterData(data): Observable<HealthElitePlusResponsePayload> {
    const endpoint = `${this.baseURI}${this.CHIMasterData}`;
    return this.generic.genericService<HealthElitePlusResponsePayload>(endpoint, data);
  }

  getHEPAddonList(data):Observable<GetAddonResponse>{
    const endpoint = `${this.baseURI}${this.HEPAddonData}`;
    return this.generic.genericService<GetAddonResponse>(endpoint,data)
  }
  saveEditQuoteData(data): Observable<any> {
    const endpoint = `${this.baseURI}${this.SaveEditQuoteV1}`;
    return this.generic.genericService<any>(endpoint, data);
  }
}
