<app-header [dashBoardStatus]="true"></app-header>

<div class="paymentconfirmation" *ngIf="loadContent">
  <div class="paymentDiv">
    <div class="paymentmaindiv">
      <img src="../../../../assets/images/pay_animate.gif" alt="" class="paymentIcon">
    </div>

    <div class="paymentInformation">
      <div class="message">
        <span>Congratulations!</span>
        <strong>Thank you! Your payment of <div class="paymentValue">
            ₹{{utilityService.convertStringtoCurrency(this.payConf.TotalPremium)}}
          </div>
          has been received</strong>
      </div>

      <mat-divider></mat-divider>

      <div class="transactionDetails">
        <div>
          <span class="title">Payment ID</span>
          <span class="value">{{this.payConf.PfTransactionId}}</span>
        </div>

        <div class="border"></div>

        <div [ngClass]="{'basePolicy': this.payConf.PolicyNumber[0]}" *ngIf="this.responseLength == 1">
          <span class="title">Policy Number</span>
          <div class="singlePolicy">
            <span class="value" [matTooltip]="this.payConf.PolicyNumber[0] ? 'Download' : null"
              [matTooltipPosition]="'left'"
              (click)="downloadPolicy(this.payConf.EPolicyID[0] ? this.payConf.EPolicyID[0] : '', this.payConf.PolicyNumber[0] ? this.payConf.PolicyNumber[0] : '')">
              <img *ngIf='this.payConf.PolicyNumber[0]'
                src="../../../../../../../assets/images/BBR/noBorderDownloadBtn.svg" alt="download" class="download" />
              <span class="valueNum">{{this.payConf.PolicyNumber[0] ? this.payConf.PolicyNumber[0] : '-'}}</span>
            </span>

            <!-- Copy Icon -->
            <span class="copySpan" *ngIf="!utilityService.isEmptyOrNull(this.payConf.PolicyNumber[0])"
              (click)="copyText(this.payConf.PolicyNumber[0], tooltip)">
              <img [matTooltip]="isCopied ? 'Text copied successfully!' : 'Copy Text'" [matTooltipPosition]="'left'"
                src="../../../../../../../assets/images/BBR/copyText.svg" alt="copy" #tooltip="matTooltip" />
            </span>
          </div>
        </div>

        <!-- Conditional Display -->
        <div *ngIf="this.responseLength == 2">
          <div [ngClass]="{'basePolicy': this.payConf.PolicyNumber[0] && this.payConf.PolicyNumber[1]}">
            <span class="title">Base Policy</span>
            <div class="doublePolicy">
              <span class="value"
                [matTooltip]="this.payConf.PolicyNumber[0] && this.payConf.PolicyNumber[1] ? 'Download' : null"
                [matTooltipPosition]="'left'"
                (click)="downloadPolicy(this.payConf.EPolicyID[0] ? this.payConf.EPolicyID[0] : '', this.payConf.PolicyNumber[0] ? this.payConf.PolicyNumber[0] : '')">
                <img *ngIf='this.payConf.PolicyNumber[0] && this.payConf.PolicyNumber[1]'
                  src="../../../../../../../assets/images/BBR/noBorderDownloadBtn.svg" alt="download"
                  class="download" />
                <span class="valueNum">{{this.payConf.PolicyNumber[0] && this.payConf.PolicyNumber[1] ?
                  this.payConf.PolicyNumber[0] : '-' }} </span>
              </span>

              <!-- Copy Icon -->
              <span class="copySpan" *ngIf="!utilityService.isEmptyOrNull(this.payConf.PolicyNumber[0]) && !utilityService.isEmptyOrNull(this.payConf.PolicyNumber[1])"
                (click)="copyText(this.payConf.PolicyNumber[0], tooltip)">
                <img [matTooltip]="isCopied ? 'Text copied successfully!' : 'Copy Text'" [matTooltipPosition]="'left'"
                  src="../../../../../../../assets/images/BBR/copyText.svg" alt="copy" #tooltip="matTooltip" />
              </span>
            </div>
          </div>


          <div
            [ngClass]="{'superTopupPolicy': this.payConf.PolicyNumber[0] && this.payConf.PolicyNumber[1] , 'nullPolicy': !this.payConf.PolicyNumber[1]}">
            <span class="title">Super Topup Policy</span>
            <div class="doublePolicy">
              <span class="value"
                [matTooltip]="this.payConf.PolicyNumber[0] && this.payConf.PolicyNumber[1] ? 'Download' : null"
                [matTooltipPosition]="'left'"
                (click)="downloadPolicy(this.payConf.EPolicyID[1] ? this.payConf.EPolicyID[1] : '', this.payConf.PolicyNumber[1] ? this.payConf.PolicyNumber[1] : '')">
                <img *ngIf='this.payConf.PolicyNumber[0] && this.payConf.PolicyNumber[1]'
                  src="../../../../../../../assets/images/BBR/noBorderDownloadBtn.svg" alt="download"
                  class="download" />
                <span class="valueNum">{{ this.payConf.PolicyNumber[0] && this.payConf.PolicyNumber[1] ?
                  this.payConf.PolicyNumber[1] : '-' }}</span>
              </span>

              <!-- Copy Icon -->
              <span class="copySpan" *ngIf="!utilityService.isEmptyOrNull(this.payConf.PolicyNumber[0]) && !utilityService.isEmptyOrNull(this.payConf.PolicyNumber[1])"
                (click)="copyText(this.payConf.PolicyNumber[1], tooltip)">
                <img [matTooltip]="isCopied ? 'Text copied successfully!' : 'Copy Text'" [matTooltipPosition]="'left'"
                  src="../../../../../../../assets/images/BBR/copyText.svg" alt="copy" #tooltip="matTooltip" />
              </span>
            </div>
          </div>
        </div>

        <div class="border border-2"></div>

        <div *ngIf="this.responseLength == 1">
          <span class="title">Proposal Number</span>
          <span class="value">{{this.payConf.PfProposalNumber[0]}}</span>
        </div>

        <div *ngIf="this.responseLength == 2">
          <div>
            <span class="title">Base Proposal</span>
            <span class="value">{{this.payConf.PfProposalNumber[0]}}</span>
          </div>

          <div class="superTopupProposal">
            <span class="title">Super Topup Proposal</span>
            <span class="value">{{this.payConf.PfProposalNumber[1]}}</span>
          </div>
        </div>

        <div class="border"></div>

        <div>
          <span class="title">Start Date</span>
          <span class="value">{{this.payConf.StartDate}}</span>
        </div>

        <div class="border"></div>

        <div>
          <span class="title">End Date</span>
          <span class="value">{{this.payConf.EndDate}}</span>
        </div>
      </div>
    </div>

    <div class="actionButton" *ngIf="((bflag == 'true')?false:true)">
      <div class="buttonDiv"
        *ngIf="(this.payConf.TransFor[0] == 'R' ||  this.payConf.NRIApplicable[0] == 'Yes') && this.responseLength == 1">
        <button mat-raised-button class="primary-button" (click)="goToUploadDocument()">Upload Document</button>
      </div>

      <!-- <div class="buttonDiv"
        *ngIf="this.healthFormData.paymentConfirmationDetails.PolicyNumber && this.healthFormData.paymentConfirmationDetails.TransFor != 'R' && this.healthFormData.paymentConfirmationDetails.NRIApplicable != 'Yes'">
        <button mat-raised-button class="primary-button" (click)="downloadPolicy()">Download Policy</button>
      </div> -->

      <!-- <div class="buttonDiv"
        *ngIf="((bflag == 'true')?false:true) && this.healthFormData.paymentConfirmationDetails.PolicyNumber && !hideDashboard">
        <button mat-raised-button class="primary-button" (click)="goToDashboard()">Dashboard</button>
      </div> -->

      <div class="buttonDiv"
        *ngIf="(this.payConf.TransFor[0] == 'R' ||  this.payConf.NRIApplicable[0] == 'Yes' || this.payConf.TransFor[1] == 'R' ||  this.payConf.NRIApplicable[1] == 'Yes') && this.responseLength == 2">
        <button mat-raised-button class="primary-button" (click)="goToUploadDocument()">Upload Document</button>
      </div>
    </div>


    <div class="my_Policy"  *ngIf="((bflag == 'true')?false:true)">
      <span>Kindly view your policy on my policies
        <a style="cursor: pointer;" (click)="goToDashboard()">dashboard</a>
      </span>
    </div>

  </div>
</div>

<app-error-screen *ngIf="!loadContent"></app-error-screen>
