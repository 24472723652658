<!-- BBR FORMAT -->
<section class="container-fluid">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="floatdiv floatingedit" *ngIf="scrolling">
                <img class="imgScss" src="../../../../../../assets/images/BBR/ScrollToTop.svg" alt="" (click)="scrollToTop()">
            </div>

            <div class="marginbox">
                <!-- Icon and title start -->
                <div class="myTransactionTitle" *ngIf="!isSearchVisible">
                    <div class="bbrheading">
                        <span><img src="../../../../../../assets/images/MyTransactionIcon/RenewalDue.svg" alt=""></span>
                        <span>
                            <h3>Renewal Due</h3>
                        </span>
                    </div>
                    <div class="d-md-none headerImgSection">
                        <span (click)="openFilterBottomSheet()">
                            <img class="headerImg" src="{{filterImage}}" alt="">
                        </span>
                        <span (click)="openSearchBottomSheet()">
                            <img class="headerImg" src="{{editImage}}" alt="" /></span>
                        <span (click)="searchFilter()">
                            <img class="headerImg" src="../../../../../../assets/images/BBR/Search.svg" alt="" /></span>
                    </div>
                </div>
                <div class="mobilesearch__fieldBBr" *ngIf="isSearchVisible">
                    <div class="mobileinputDivbbr">
                        <input matInput type="text" class="input-group__input placeholder"
                            [cdkTrapFocusAutoCapture]="isSearchVisible" [cdkTrapFocus]="isSearchVisible"
                            [placeholder]="searchPlaceHolder" (input)="queryData()" [(ngModel)]="inputValue">
                        <span class="crossclick" (click)="closeClear()">
                            <img src="../../../../../../assets/images/BBR/Close.svg" alt="">
                        </span>
                    </div>
                </div>
                <!-- Icon and title end -->

                <!-- Desktop View (above 767px) start -->
                <div class="d-none d-md-block">
                    <!-- Input fields card start -->
                    <mat-card class="innerbox borderinnerbox">
                        <div class="row fieldsdiv" [formGroup]="renewalDueForm">

                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label" for="myInput">Product Type</mat-label>
                                <mat-select class="input-group__input cstm_select" id="productType"
                                    placeholder="Product Type" formControlName="productType"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                    (selectionChange)="productListListner($event.value)">
                                    <ng-container *ngFor="let item of productList; let i = index; let last = last">
                                        <mat-option [value]="item.productCode">
                                            {{item.productName}}
                                        </mat-option>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                </mat-select>
                            </div>

                            <div class="col-sm-3 form-group" *ngIf="selectList.length > 0">
                                <mat-label class="input-group__label" for="myInput">Product Name</mat-label>
                                <mat-select class="input-group__input cstm_select" id="productName"
                                    placeholder="Product Name" formControlName="productName"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                    <ng-container *ngFor="let p of selectList;let i = index; let last = last ">
                                        <mat-option [value]="p.productCode">
                                            {{p.productName}}
                                        </mat-option>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                </mat-select>
                            </div>

                            <div class="col form-group customCAl"
                                [ngClass]="{'customTop': this.selectList.length == 0 }">
                                <div class="logCalender">
                                    <mat-select class="input-group__input renewalSelect" placeholder="Select month"
                                        formControlName="month" (click)="handleMatSelectClick()"
                                        (blur)="removeMatSelectScrollListener()">
                                        <ng-container *ngFor="let item of healthModal.months; ; let last = last">
                                            <mat-option [value]="item.key">
                                                {{item.value}}
                                            </mat-option>
                                            <mat-divider *ngIf="!last"></mat-divider>
                                        </ng-container>
                                    </mat-select>
                                    <div class="TotalCount">
                                        {{filteredData == undefined? 0 : filteredData?.length}}
                                    </div>
                                    <div class="yearlyCount">
                                        <mat-select class="input-group__input renewalSelect" placeholder="Select year"
                                            formControlName="year" (click)="handleMatSelectClick()"
                                            (blur)="removeMatSelectScrollListener()">
                                            <ng-container *ngFor="let item of previousYears; let last = last">
                                                <mat-option [value]="item">
                                                    {{item}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>

                            <div class="col form-group">
                                <button class=" search-button" (click)="searchDetails()">
                                    <span>Submit</span>
                                </button>
                            </div>

                        </div>
                    </mat-card>
                    <!-- Input fields card end -->

                </div>
                <!-- Desktop View (above 767px) end -->

                <mat-card class="innerbox borderinnerbox">
                    <div class="fieldsdiv">
                        <div class="productmainDiv">
                            <div class="productdiv">
                                <div class="align-items-center">
                                    <span>Product: <span class="font-weight-bold">{{renewDueProduct}}</span></span>
                                </div>
                            </div>
                            <div class="filterdiv">
                                <div class="d-none d-md-block">
                                    <div class="search__fieldBBr">
                                        <div class="inputDivbbr" [ngClass]="{'searchBorder': showCancel}">
                                            <input matInput type="text" class=" placeholder"
                                                [placeholder]="searchPlaceHolder" (input)="queryData()"
                                                [(ngModel)]="inputValue" (focus)="inputFocus()" (blur)="inputBlur()">
                                            <span class="crossclick pr-2" (click)="clearInputField()">
                                                <img src="../../../../../../assets/images/BBR/desktopCross.svg" alt="">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- filter functionality -->
                                <div>
                                    <div class="dropdown d-none d-md-flex ">
                                        <button matTooltip="Filter" class="dropdown-toggle customDropdown" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <div class="toggIcon">
                                                <img src="../../../../../../assets/images/BBR/Filter.svg" alt="">
                                            </div>
                                        </button>
                                        <ul class="dropdown-menu customDropItems " aria-labelledby="dropdownMenuButton">
                                            <li class="dropdownHeading">
                                                <h4>Status Type</h4>
                                            </li>
                                            <li class="dropdown-item cs_dropdown-item"
                                                *ngFor="let value of policyFilterList">
                                                <mat-checkbox [checked]="policyFilter.includes(value.toLowerCase())"
                                                    (change)="applyPolicyFilter($event.checked, value)">
                                                    {{ value }}
                                                </mat-checkbox>
                                            </li>
                                        </ul>

                                    </div>
                                </div>

                                <!-- ends here -->
                                <div class=" d-flex align-items-center">
                                    <mat-button-toggle-group class="custom-toggle-group" (change)="onToggle($event)"
                                        [value]="toggleValue">
                                        <mat-button-toggle matTooltip="Table View" class="custom_togglebtn"
                                            value="table"><img src="../../../../../../assets/images/BBR/tablelist.svg"
                                                alt=""></mat-button-toggle>
                                        <mat-button-toggle matTooltip="Card View" class="custom_togglebtn"
                                            value="card"><img src="../../../../../../assets/images/BBR/cardList.svg"
                                                alt=""></mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-card>

                <!-- Table format start -->
                <app-custom-table *ngIf="toggleValue == 'table'" [reportList]="filteredData" [buttonData]="tableBtnInfo"
                    [displayedColumns]="displayCol" (policyNo)="receiveDataAndCall($event)"
                    [tableSize]="'1445px'"></app-custom-table>
                <!-- Table format end -->

                <!-- Card format start -->
                <app-custom-card *ngIf="toggleValue == 'card'" [reportList]="filteredData" [buttonData]="cardBtnInfo"
                    [displayedColumns]="displayCol" (policyNo)="receiveDataAndCall($event)"></app-custom-card>
                <!-- Card format end -->

            </div>
        </div>
    </div>
</section>