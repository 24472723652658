export class GoldenShieldModal{
    productType = ['New', 'Portability'];
    options = ['Yes', 'No'];
    copaymentPercentTaken = ['No', '10%', '20%'];
    tenurePeriod = ['1 Year', '2 Years', '3 Years'];
    emiInstallments = ['Monthly', 'Quarterly', 'Half Yearly']
    zoneCode = '3828';
    zoneData = ['ZONE 1', 'ZONE 2', 'ZONE 3'];
    yes = 'Yes';
    no = 'No';
    maxSumInsured = 5000000;
    agent = 'Agent';
    subProductType = 40;
    volDedutible = 0;
    ipartnerMsite = 'NYSAHEALTH'
    addOnCoverIds = {
        twentyseven: 27,
        twentyeight: 28,
        twentynine: 29,
        thirty: 30,
        thirtyone: 31   
    }
    title = [
        {
            "value": "0",
            "label": "Mrs."
        },

        {
            "value": "1",
            "label": "Mr."
        },

        {
            "value": "2",
            "label": "Ms."
        },

        {
            "value": "21",
            "label": "Mx."
        }
    ]

      // Number of adult dropdown
    // by default 1A should be shown
    adultCount = [
        { 'label': '1 Adult', 'value': '1', 'type': 'adult', 'selected': true, 'hide': false },
        { 'label': '2 Adult', 'value': '2', 'type': 'adult', 'selected': false, 'hide': false },
    ]
    stateId = '62';
    stateDetails = {
        'stateId': '',
        'cityId': '',
        'cityName': '',
        'stateName': '',
        'pincode': ''
    }
    memberButtonData = [
        { 'label': 'Adult', 'imgSrc': '../../../../assets/icons/Single Adult.svg', minusImgSrc: '../../../../assets/icons/minus.svg', plusImgSrc: '../../../../assets/icons/plus.svg', 'selected': false, 'hide': false, 'incDec': true },
        // { 'label': 'Child', 'imgSrc': '../../../../assets/icons/Single Child.svg', minusImgSrc: '../../../../assets/icons/minus.svg', plusImgSrc: '../../../../assets/icons/plus.svg', 'selected': false, 'hide': false, 'incDec': true }
    ]
}

export interface AddonStructure {
    checkboxValue: CheckBoxData,
    coverCode: string,
    coverId: number,
    isForAdult: boolean,
    label: string,
    position: number,
    type: string,
    visible: boolean,
    visiblityAsPerService: boolean
}
export interface CheckBoxData {
    disabledAddon: boolean, checkedAddon: boolean
}