<mat-tab-group class="tabInnerdiv marginbox" (selectedTabChange)="memberTypeSelected($event)"
   *ngIf="loadFormContent && tabGroup!=undefined  && !serviceError">
   <!-- Creation of tabs -->
   <mat-tab class="" *ngFor="let tabData of tabGroup">
      <!-- Adult & Child tab -->
      <ng-template mat-tab-label>
         <!-- For Adult & Child step -->
         {{tabData.tabLabel}}
      </ng-template>
      <!-- Creation of Adult & Child buttons -->
      <div class="fieldsdiv" *ngIf="tabData.memberDetails.length > 1">
         <div class="row">
            <div class="col-sm-12 mt-3 form-group groupingbtn">
               <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="policyToggle_group">
                  <mat-button-toggle class="PolicyBtn" (click)="memberChanged(tabData.tabLabel,i)"
                     *ngFor="let data of tabData.memberDetails; let i = index" [checked]="data.selected">
                     {{ data.label}}
                  </mat-button-toggle>
               </mat-button-toggle-group>
            </div>
         </div>
      </div>
      <!-- Details Of Members -->
      <mat-divider></mat-divider>
      <form [formGroup]="insuredDetailsForm">
         <div class="scrollRow">
            <div class="fieldsdiv">
               <div class="row" formArrayName="memberDetails">
                  <ng-container class="col-md-3 col-sm-3 form-group"
                     *ngFor="let insuredMember of memberDetails.controls; let j = index;" [formGroupName]="j">
                     <ng-container formArrayName="controls">
                        <ng-container *ngIf="memberDataIndex==j">
                           <div class="col-md-3 col-sm-3 form-group form-group"
                              *ngFor="let control of insuredControlDetails(memberDataIndex).controls; let k = index"
                              [formGroupName]="k">
                              <ng-container *ngIf="control.value.type == 'input'">
                                 <mat-label class="input-group__label">{{control.value.label}}</mat-label>
                                 <input type="text" class="input-group__input" [type]="control.value.inputType" matInput
                                    [id]="control.value.controlName" formControlName="{{control.value.controlName}}"
                                    placeholder="{{control.value.placeholder}}" readonly="{{control.value.disabled}}"
                                    [max]="control.value.maxNumber" [min]="control.value.minNumber"
                                    [maxlength]="control.value.maxLength" autocomplete="off"
                                    (paste)="utilityService.onPaste($event,control.value.onlyAlphabets,control.value.onlyNumbers)"
                                    (input)="feetHeightFormatting($event,control.value,j,k, control.value.onlyAlphabets, control.value.onlyNumbers)"
                                    *ngIf="control.value.controlName == 'passportNumber'"
                                    onkeypress="return /[0-9a-zA-Z]/i.test(event.key)">

                                 <input type="text" class="input-group__input" [type]="control.value.inputType" matInput
                                    [id]="control.value.controlName" formControlName="{{control.value.controlName}}"
                                    placeholder="{{control.value.placeholder}}" readonly="{{control.value.disabled}}"
                                    [max]="control.value.maxNumber" [min]="control.value.minNumber"
                                    [maxlength]="control.value.maxLength"
                                    (paste)="utilityService.onPaste($event,control.value.onlyAlphabets,control.value.onlyNumbers)"
                                    (input)="feetHeightFormatting($event,control.value,j,k, control.value.onlyAlphabets, control.value.onlyNumbers)"
                                    *ngIf="control.value.controlName != 'dob' && control.value.controlName != 'passportNumber'" onkeypress="return /^[a-zA-Z0-9 ]*$/i.test(event.key)">
                                 <input type="text" class="input-group__input" [type]="control.value.inputType" matInput
                                    [id]="control.value.controlName"
                                    value="{{ control.value[control.value.controlName] | date:'dd/MM/yyyy' }}"
                                    placeholder="{{control.value.placeholder}}" readonly="{{control.value.disabled}}"
                                    *ngIf="control.value.controlName == 'dob'">
                                 <mat-error
                                    *ngIf="insuredDetailsForm.controls['memberDetails']['controls'][j]['controls']['controls']['controls'][k]['controls'][control.value.controlName].hasError('required') && isSubmit">
                                    {{control.value.label}} is required
                                 </mat-error>
                              </ng-container>
                              <ng-container *ngIf="control.value.type == 'dropdown'">
                                 <mat-label class="input-group__label">{{control.value.label}}</mat-label>
                                 <mat-select formControlName="{{control.value.controlName}}"
                                    class="input-group__input cstm_select"
                                    formControlName="{{control.value.controlName}}" [id]="control.value.controlName"
                                    placeholder="{{control.value.placeholder}}"
                                    (selectionChange)="changeInDropdownValue($event,control.value)">
                                    <ng-container *ngFor="let item of control.value.value; let last = last">
                                       <mat-option value={{item.value}}>
                                          {{item.label}}
                                       </mat-option>
                                       <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                 </mat-select>
                                 <mat-error
                                    *ngIf="insuredDetailsForm.controls['memberDetails']['controls'][j]['controls']['controls']['controls'][k]['controls'][control.value.controlName].hasError('required') && isSubmit">
                                    {{control.value.label}} is required
                                 </mat-error>
                              </ng-container>
                           </div>
                        </ng-container>
                     </ng-container>
                  </ng-container>
               </div>
            </div>
         </div>
      </form>
   </mat-tab>
</mat-tab-group>

<app-error-screen (refreshService)="onRefresh($event)" *ngIf="serviceError">
</app-error-screen>

<app-custom-footer [normalButton]="true"
   [buttonData]="[{'label': 'Save & Proceed', 'type': 'primary', 'source': 'insured', 'id': 'f3','disabled': false , 'hide': false }]"
   (buttonClicked)="onSubmit($event)">
</app-custom-footer>