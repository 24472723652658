import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { CreateLoginTokenRequest, CreateLoginTokenResponse } from 'src/app/shared/interface/token';
import { Observable } from 'rxjs/internal/Observable';
import { EncryptDecryptService } from 'src/app/shared/services/encrypt-decrypt.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { LocalStorageService, SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';
import { GenericService } from 'src/app/shared/services/generic.service';
import { Router } from '@angular/router';
import { routeEnums } from 'src/app/shared/enums/routeEnums';

@Injectable({
  providedIn: 'root'
})
export class DeveloperLoginService {

  readonly baseURI = environment.baseURL + 'Auth/';
  readonly serviceName = 'CreateDevLoginToken';
  userType = '';

  constructor(private encryptDecryptService: EncryptDecryptService,
    private storageService: StorageService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private generic: GenericService,
    private router: Router,) { }

  login(data: CreateLoginTokenRequest): Observable<CreateLoginTokenResponse> {
    const headers = this.setUserHeaders(data);
    // Here we need to store password and uname in local storage service.
    //  We need to clear it in payment confirmation screen
    // Below uname and pwd should be set only at time of login. And it should be cleared at journey end
    this.storageService.setAESEncryptedDataLocal(userEnums.UopqksmlN, data.Uowkjsdjssrme);
    this.storageService.setAESEncryptedDataLocal(userEnums.Pqsxkpo, data.Powkjsdjssrd);
    // Also set user Type to hide dashboatd button in paayment confirmation
    // this.storageService.setAESEncryptedDataLocal(data.username, data.password);
    const endpoint = `${this.baseURI}${this.serviceName}`;
    return this.generic.genericService<CreateLoginTokenResponse>(endpoint, '', 'json', headers);
  }

  setUserType(user: string) {
    this.userType = user;
  }

  isAdminORBE() {
    return this.userType === 'BE_DEVELOPER' || this.userType === 'ADMIN' || this.userType === 'SUPPORT';
  }

  setUserHeaders(data?) {
    let headers = new HttpHeaders();
    let uid = environment.environment == 'sanity' ? 'Uowkjsdjssrme' : 'UserName';
    let pid = environment.environment == 'sanity' ? 'Powkjsdjssrd' : 'Password';
    headers = headers.set(uid, this.encryptDecryptService.finalRsaEncryption(data[uid]));
    headers = headers.set(pid, this.encryptDecryptService.finalRsaEncryption(data[pid]));
    return headers;
  }

  logoutRedirectToLogin() {
    // Logout functionality for session logout
    this.sessionStorageService.clear();
    this.localStorageService.clearLocal();
    this.router.navigate(['/', `${routeEnums.DEVELOPER}`]);

  }
}
