import { Component, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { SavedQuotesService } from 'src/app/shared/services/saved-quotes.service';
import { SavedQuotesResponsePayload } from 'src/app/shared/interface/savedQuotes';
import { HAPModal } from 'src/app/modules/health/products/hap/hap-modal';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { MatSelect } from '@angular/material/select';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { PopupComponent } from 'src/app/layout/popups/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { popupType } from 'src/app/shared/enums/popupEnums';
import { Constants } from 'src/app/shared/constants/constants';
import { fromEvent, Subscription } from 'rxjs';
@Component({
  selector: 'app-saved-quotes',
  templateUrl: './saved-quotes.component.html',
  styleUrls: ['./saved-quotes.component.scss']
})
export class SavedQuotesComponent implements OnInit {
  savedQuotesObj;
  savedQuotesList = [];
  convObj;
  hapDataModal: HAPModal = new HAPModal(); //hap data modal 
  savedQuoteEmpty: boolean = false;
  savedQuotesData = [];
  productName: string = '';

  private interval: any;
  reportList = [];
  filteredData = [];
  convertObj;
  searchTerm: string = '';
  toggleValue: 'table' | 'card' = 'card';
  i: number = 0;
  phText: string[] = ['Search for Quote ID',
    'Search for Product Name',
    'Search for Total Premium'];
  searchPlaceHolder: string;
  showCancel: boolean = false;
  scrolling: boolean = false;
  isSearchVisible: boolean = false;
  cardBtnInfo = [{
    label: 'Convert To Policy',
    imgPath: '../../../../../../../assets/images/MyTransactionIcon/convertToPolicy.svg',
  }]
  tableBtnInfo = [{
    label: 'Convert To Policy',
    imgPath: '../../../../../../../assets/images/BBR/convertToPolicy.svg',
    tooltip: 'Convert To Policy'
  }]
  minMax: { maxDate: Date; minDate: Date; };

  displayCol = [
    {
      columnDef: "QuoteID",
      header: 'Quote ID',
      cell: (element: any) => `${element.QuoteID}`,
    },
    {
      columnDef: "ProductName",
      header: 'Product Name',
      cell: (element: any) => `${element.ProductName}`,
    },
    {
      columnDef: "QuoteDate",
      header: 'Quote Date',
      cell: (element: any) => `${element.QuoteDate}`,
    },
    {
      columnDef: "ValidityDate",
      header: 'Validity Date',
      cell: (element: any) => `${element.ValidityDate}`,
    },
    {
      columnDef: "Premium",
      header: 'Total Premium',
      cell: (element: any) => `${element.Premium}`,
    },
    {
      columnDef: 'Action',
      header: 'Action',
      cell: '',
    }
  ]
  saveQuoteForm: FormGroup;
  selectList = [];
  subscription: Subscription[] = [];

  constructor(
    private storageService: StorageService,
    private savedQuotesService: SavedQuotesService,
    private router: Router,
    private healthDataStorageService: HealthDataStorageService,
    public utilityService: UtilityService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private agentV1Service: AgentV1Service,
    private dialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    this.setCalendarDates();
    this.getAllProducts();
    this.createForm();
    this.getSavedQuoteList();
    this.setPlaceHolderText();
    this.interval = setInterval(() => { this.setPlaceHolderText(); }, 2000);
  }

  createForm() {
    const SD = this.utilityService.subtractDays(new Date(), 7);
    this.saveQuoteForm = this.formBuilder.group({
      productName: [42, [Validators.required]],
      startDate: [SD, [Validators.required]],
      endDate: [new Date(), [Validators.required]],
    })
  }

  setDOB(value: string | Date): string {
    const date = new Date(value);
    const now = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  constructSaveQuotePayload() {
    const data = {
      ProductType: String(this.saveQuoteForm.value.productName),
      PolicyType: '2',
      StartDate: this.setDOB(this.saveQuoteForm.value.startDate),
      EndDate: this.setDOB(this.setDOB(this.saveQuoteForm.value.endDate))
    }
    return data;
  }

  getSavedQuoteList() {
    this.clearInputField();
    const request = this.constructSaveQuotePayload();
    this.savedQuotesService.fetchSavedQuotesData(request).subscribe({
      next: (data: SavedQuotesResponsePayload) => {
        if (data.StatusCode == Constants.statusCode_success) {
          this.reportList = data.Data.Health.map(item => {
            return {
              QuoteID: item.QuoteID,
              ProductName: item.ProductType.includes('Criti') ? 'Criti Shield' : item.ProductType,
              Premium: item.Premium,
              QuoteDate: this.utilityService.formatDate(item.ProposalDate),
              ValidityDate: this.utilityService.formatDate(item.ValidityDate),
            }
          });
          this.filteredData = this.reportList;
        } else {
          this.reportList = [];
          this.filteredData = [];
        }
      }
    });
  }

  setCalendarDates() {
    let minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10));
    let maxDate = new Date(new Date().setFullYear(new Date().getFullYear())); //removing +1
    this.minMax = { maxDate: maxDate, minDate: minDate }
  }

  setPlaceHolderText() {
    this.searchPlaceHolder = this.phText[this.i];
    this.i++;
    if (this.i == this.phText.length) {
      this.i = 0;
    }
  }

  onWindowScroll() {
    this.scrolling = true;

    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  queryData() {
    if (!this.searchTerm) {
      this.filteredData = this.reportList;
    }
    this.filteredData = this.reportList.filter(item => {
      return Object.values(item).some(val =>
        val.toString().toLowerCase().includes(this.searchTerm.trim().toLowerCase())
      );
    });
  }

  // searchDetails() {
  //   this.searchTerm = '';
  //   this.filteredData = this.reportList.filter(data => {
  //     return data.ProductName.toLowerCase() == this.saveQuoteForm.value.productName.toLowerCase() && this.utilityService.compareDates(data.QuoteDate, this.saveQuoteForm.value.startDate) && !this.utilityService.compareDates(data.QuoteDate, this.saveQuoteForm.value.endDate);
  //   })
  // }

  getAllProducts() {
    this.selectList = this.agentV1Service.getAllProducts('saveQuote');
  }

  editImage: string = 'assets/images/BBR/Edit.svg'; // Initial image

  openSearchBottomSheet() {
    if (this.editImage === 'assets/images/BBR/Edit.svg') {
      this.editImage = 'assets/images/BBR/EditActive.svg';
    } else {
      this.editImage = 'assets/images/BBR/Edit.svg';
    }
    const searchBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      // height: '38%',
      disableClose: true,
      data: {
        popupType: popupType.search,
        data: {
          controls: [{
            type: 'myDropdown',
            label: 'Product Name',
            list: this.selectList,
            value: this.saveQuoteForm.value.productName
          },
          {
            type: 'rangepicker',
            label: 'Select date range',
            startDate: this.saveQuoteForm.value.startDate,
            endDate: this.saveQuoteForm.value.endDate,
          }],
          dates: {
            minDate: this.minMax.minDate,
            maxDate: this.minMax.maxDate,
          },
        }
      }
    }
    );
    searchBottomSheet.afterClosed().subscribe((data) => {
      this.editImage = 'assets/images/BBR/Edit.svg';
      this.patchSearchParameters(data);
    });
  }

  patchSearchParameters(data) {
    if (data) {
      for (let i of data) {
        if (i.type == 'myDropdown') {
          this.saveQuoteForm.controls['productName'].setValue(i.value);
        }
        else if (i.type == 'rangepicker') {
          this.saveQuoteForm.controls['startDate'].setValue(i.startDate);
          this.saveQuoteForm.controls['endDate'].setValue(i.endDate);
        }
      }
      this.getSavedQuoteList();
    }
  }

  // Search Proposal
  onSearchProposal(event: any) {
    this.savedQuotesList = this.savedQuotesData.filter((item: any) => {
      return item.QuoteID.toString().includes(event.target.value);
    });
  }

  convertProposalToQuote(event) {
    const convertPayload = {
      QuoteId: event.data.QuoteID.toString(),
    };
    // Call Convert Quote API
    this.savedQuotesService.convertSavedQuotes(convertPayload).subscribe({
      next: (data) => {
        this.convertObj = data;
        this.healthDataStorageService.resetHealthDataStorage();
        this.storageService.setAESEncryptedData(userEnums.ConverToProposal, this.convertObj);
        // Call Convert Proposal API
        setTimeout(() => {
          const productName = this.productType(data.Data.ProductName);
          this.router.navigate([routeEnums.HEALTH + '/' + productName]);
        }, 0);

      }
    });
  }

  // Convert quote fucntionality
  // convertProposalToQuote(policyId) {
  //   let convertPayload: ConvertSavedQuotesRequestPayload = {
  //     QuoteId: policyId.toString(),
  //     // PolicyStatus: "Quote",
  //     // SubProductType: subtype,
  //     // UserType: this.hapDataModal.agent
  //   };
  //   // Call Convert Quote API
  //   this.savedQuotesService.convertSavedQuotes(convertPayload).subscribe({
  //     next: (data: ConvertSavedQuotesResponsePayload) => {
  //       this.convertObj = data;
  //       this.healthDataStorageService.resetHealthDataStorage();
  //       this.storageService.setAESEncryptedData(userEnums.ConverToProposal, this.convertObj);
  //       // Call Convert Proposal API
  //       setTimeout(() => {
  //         this.productName = this.productType(data.Data.ProductName);
  //         this.router.navigate([routeEnums.HEALTH + '/' + this.productName]);
  //         // window.location.href = "/health/hap";
  //       }, 0);
  //     }
  //   });
  // }

  productType(product: string) {
    let productRoute;
    let productSubcode;
    switch (product) {
      case 'Health AdvantEdge Plus': {
        productRoute = routeEnums.HAP;
        productSubcode = 'hap';
        break;
      }
      case 'Health Elite Plus': {
        productRoute = routeEnums.HealthElitePlus;
        productSubcode = 'hep';
        break;
      }
      case 'Arogya Sanjeevani': {
        productRoute = routeEnums.ASP;
        productSubcode = 'asp';
        break;
      }
      case 'Health Booster': {
        productRoute = routeEnums.HEALTH_BOOSTER;
        productSubcode = 'hb';
        break;
      }
      case 'Golden Shield': {
        productRoute = routeEnums.GoldenShield;
        productSubcode = 'gs';
        break;
      }
      case 'Max Protect': {
        productRoute = routeEnums.MaxProtect;
        productSubcode = 'max';
        break;
      }
      case 'Criti-Shield': {
        productRoute = routeEnums.CritiShield;
        productSubcode = 'cs';
        break;
      }

      case 'Family Shield': {
        productRoute = routeEnums.Familyshield;
        productSubcode = 'fs';
        break;
      }
      default: {
        break;
      }
    }
    this.utilityService.mapSubProductCode(productSubcode);
    return productRoute;

  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  searchFilter() {
    this.isSearchVisible = true;
  }

  inputFocus() {
    this.showCancel = true;
  }

  inputBlur() {
    this.showCancel = false;
  }

  closeClear() {
    this.isSearchVisible = false;
    this.clearInputField();
  }

  clearInputField() {
    this.searchTerm = '';
    this.queryData();
  }

  onToggle(event) {
    this.toggleValue = event.value;
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription.forEach(subs => {
      subs.unsubscribe();
    })
  }

}
