import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AgentMasterResponsePayload, AmlRequestPayload, OrionData } from '../interface/agentV1';
import { GenericService } from '../services/generic.service';
import { UtilityService } from '../services/utility.service';


@Injectable({
  providedIn: 'root'
})
export class AgentV1Service {

  constructor(private generic: GenericService, private utilityService: UtilityService) { }

  readonly baseURI = environment.baseURL + 'AgentV1/';
  readonly serviceName = 'AgentAppMasterData';
  readonly orionBaseURI = environment.baseURL + 'OrionHealthMaster/';
  readonly getBancsPartyCodeURI = 'GetBancsPartyCode';
  // https://pana.insurancearticlez.com/health/api/OrionHealthMaster/OrionValidIM%27
  readonly amlCodeOfConduct =  'AML';

  agentAml(data: AmlRequestPayload): Observable<any>{
    const endpoint = `${this.baseURI}${this.amlCodeOfConduct}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  agentAppMasterData(): Observable<AgentMasterResponsePayload> {
    const endpoint = `${this.baseURI}${this.serviceName}`;
    return this.generic.genericService<AgentMasterResponsePayload>(endpoint, null);
  }

  getBancsPartyCode(): Observable<OrionData> {
    const orionEndPoint = `${this.orionBaseURI}${this.getBancsPartyCodeURI}`;
    return this.generic.genericService<OrionData>(orionEndPoint, null);
  }

  productTypeList(data, tag) {
    let productTypeList = [];
    const healthProducts = this.productList(data, tag).filter(item => item.isProductMapped);
    const travelProducts = this.travelList(data, '').filter(item => item.isProductMapped);
    if (healthProducts.length > 0) {
      productTypeList.push({ productName: 'Health', productCode: 2, productList: healthProducts });
    }
    if (data?.MappedProduct?.Home?.isHomeMapped && data?.MappedProduct?.Home?.HomeDealId) {
      productTypeList.push({ productName: 'Home', productCode: 4, productList: [] });
    }
    if (travelProducts.length > 0) {
      productTypeList.push({ productName: 'Travel', productCode: 3, productList: travelProducts });
    }
    return productTypeList;
  }

  //common productlist data
  productList(data, tag) {
    let isCHIMapped = data?.MappedProduct?.Health?.isHealthCHIMapped;
    let isBoosterMapped = data?.MappedProduct?.Health?.isHealthCHIMapped;
    let chiMapped: boolean = false;
    if (!this.utilityService.isEmptyOrNull(data.MappedProduct.Health.HealthCHISubProducts)) {
      chiMapped = isCHIMapped && data.MappedProduct.Health.HealthCHISubProducts?.Visible_HealthElitePlus || data.MappedProduct.Health.HealthCHISubProducts?.Visible_HealthShield
    }
    else {
      chiMapped = false;
    }

    let productDetails = [
      { productName: 'Elevate', productCode: 45, isProductMapped: data?.MappedProduct?.Health?.HealthCHISubProducts?.Visible_HealthAdvantedge_Plus, dealId: data?.MappedProduct?.Health?.HealthAdvantedgeDealID },
      { productName: 'Health Advantedge Plus', productCode: 42, isProductMapped: data?.MappedProduct?.Health?.HealthCHISubProducts?.Visible_HealthAdvantedge_Plus, dealId: data?.MappedProduct?.Health?.HealthAdvantedgeDealID },
      { productName: 'Health Booster', productCode: 20, isProductMapped: isBoosterMapped && data.MappedProduct?.Health?.HealthBoosterSubProducts?.Visible_SuperTopup, dealId: data?.MappedProduct?.Health?.HealthBoosterDealId },
      { productName: 'Arogya Sanjeevani', productCode: 22, isProductMapped: data.MappedProduct.Health.isHealthASPMapped, dealId: data?.MappedProduct?.Health?.HealthASPDealId },
      { productName: 'Family Shield', productCode: 23, isProductMapped: data.MappedProduct.Health.isFamilyShield, dealId: data?.MappedProduct?.Health?.FamilyShieldDealId },
      // { productName: 'Health Shield Plus', productCode: 31, isProductMapped: isCHIMapped && data.MappedProduct.Health.HealthCHISubProducts.Visible_HealthShieldPlus, dealId: data?.MappedProduct?.Health?.HealthCHIDealId },
      // { productName: 'Health Elite', productCode: 32, isProductMapped: isCHIMapped && data.MappedProduct.Health.HealthCHISubProducts.Visible_HealthElite ,dealId:data?.MappedProduct?.Health?.HealthBoosterDealId},
      { productName: 'Max Protect', productCode: 34, isProductMapped: isCHIMapped && data.MappedProduct.Health.HealthCHISubProducts.Visible_HealthElitePlus, dealId: data?.MappedProduct?.Health?.HealthCHIDealId },
      { productName: 'Golden Shield', productCode: 40, isProductMapped: data.MappedProduct.Health.isGoldenShield, dealId: data?.MappedProduct?.Health?.GoldenShieldDealID },
      // { productName: 'i-Shield', productCode: 44, isProductMapped: data.MappedProduct.Health.isIShieldMapped ,dealId:data?.MappedProduct?.Health?.HealthBoosterDealId},
      { productName: 'Criti Shield', productCode: 38, isProductMapped: data.MappedProduct.Health.isCritiShieldMapped, dealId: data?.MappedProduct?.Health?.CritiShieldDealID },
    ]
    if (tag == 'myPolicy') {
      // use below condition for health chi mapped
      // isProductMapped: isCHIMapped && !this.utilityService.isEmptyOrNull(data.MappedProduct.Health.HealthCHISubProducts) && data.MappedProduct.Health.HealthCHISubProducts?.Visible_HealthElitePlus || data.MappedProduct.Health.HealthCHISubProducts?.Visible_HealthShield, dealId: data?.MappedProduct?.Health?.HealthCHIDealId 
      productDetails.push({ productName: 'CHI', productCode: 33, isProductMapped: chiMapped, dealId: data?.MappedProduct?.Health?.HealthCHIDealId });
      productDetails.push({ productName: 'Befit Rider', productCode: 37, isProductMapped: true, dealId: '' });
      // productDetails.push({ productName: 'Home', productCode: 1, isProductMapped: true, dealId: data?.MappedProduct?.Home?.HomeDealId });
      // productDetails.push({ productName: 'Health Shield', productCode: 30, isProductMapped: isCHIMapped && data.MappedProduct.Health.HealthCHISubProducts.Visible_HealthShield, dealId: data?.MappedProduct?.Health?.HealthCHIDealId });
      // productDetails.push({ productName: 'Health Elite Plus', productCode: 33, isProductMapped: isCHIMapped && data.MappedProduct.Health.HealthCHISubProducts.Visible_HealthElitePlus, dealId: data?.MappedProduct?.Health?.HealthCHIDealId });
      return productDetails;
    }
    if (tag == 'renewals' || tag == 'pendingPolicy') {
      productDetails.push({ productName: 'CHI', productCode: 33, isProductMapped: chiMapped, dealId: data?.MappedProduct?.Health?.HealthCHIDealId })
      return productDetails;
    }
    // else if (tag == 'BBR') {
    //   productDetails.push({ productName: 'All Products', productCode: 0, isProductMapped: true, dealId: '' });
    //   return productDetails;
    // }
    else {
      return productDetails;
    }
  }

  travelList(data, tag) {
    let productDetails = [
      { productName: 'Student Medical Travel', productCode: 3, isProductMapped: data?.MappedProduct?.Travel?.isTRAVELSTUDENTMapped },
      { productName: 'International RoundTrip', productCode: 4, isProductMapped: data?.MappedProduct?.Travel?.isTRAVELINTERNATIONALMapped },
      { productName: 'International MultiTrip', productCode: 5, isProductMapped: data?.MappedProduct?.Travel?.isTRAVELINTERNATIONALMapped }
    ]
    return productDetails;
  }

  getAllProducts(tag) {
    let productList = [];

    if (tag == 'renewalDue') {
      const healthProducts = [
        { productName: 'Health Advantedge Plus', productCode: 42 },
        { productName: 'Health Booster', productCode: 20 },
        { productName: 'Arogya Sanjeevani', productCode: 22 },
        { productName: 'Family Shield', productCode: 23 },
        { productName: 'Max Protect', productCode: 34 },
        { productName: 'Golden Shield', productCode: 40 },
        { productName: 'Criti Shield', productCode: 38 },
        { productName: 'CHI', productCode: 33 }
      ]

      productList.push({ productName: 'Health', productCode: 2, productList: healthProducts });
      productList.push({ productName: 'Home', productCode: 4, productList: [] });

      return productList;
    }

    let healthProducts = [];
    healthProducts.push({ productName: 'Elevate', productCode: 45 })
    
    healthProducts.push({ productName: 'Health Advantedge Plus', productCode: 42 })
    healthProducts.push({ productName: 'Health Booster', productCode: 20 })
    healthProducts.push({ productName: 'Arogya Sanjeevani', productCode: 22 })
    healthProducts.push({ productName: 'Family Shield', productCode: 23 })
    healthProducts.push({ productName: 'Max Protect', productCode: 34 })
    healthProducts.push({ productName: 'Golden Shield', productCode: 40 })
    healthProducts.push({ productName: 'Criti Shield', productCode: 38 })
    
    if (tag == 'saveQuote') {
      healthProducts.push({ productName: 'Health Elite Plus', productCode: 33 })
      return healthProducts;
    }

    if (tag == 'myPolicy') {
      healthProducts.push({ productName: 'CHI', productCode: 33 });
      healthProducts.push({ productName: 'Befit Rider', productCode: 37 });
    }

    if (tag == 'renewals' || tag == 'pendingPolicy') {
      healthProducts.push({ productName: 'CHI', productCode: 33 });
    }
    
    if (tag == 'myPolicy' || tag == 'pendingPolicy') {
      healthProducts.push({ productName: 'Activate Booster (Combo*)', productCode: 46 });
      healthProducts.push({ productName: 'Activate Booster', productCode: 47 });
    }
    
    productList.push({ productName: 'Health', productCode: 2, productList: healthProducts });

    let travelProducts = [
      { productName: 'TripSecure+', productCode: 6 },
      { productName: 'Student Medical Travel', productCode: 3 },
      { productName: 'International RoundTrip', productCode: 4 },
      { productName: 'International MultiTrip', productCode: 5 },
    ]

    productList.push({ productName: 'Travel', productCode: 3, productList: travelProducts });

    productList.push({ productName: 'Home', productCode: 4, productList: [] });

    return productList;
  }

}
