<form [formGroup]="hepForm" *ngIf="!multipleServiceError && loadFormContent">

    <!-- Product Details Card Starts -->

    <div class="marginbox">
        <mat-card class="innerbox borderinnerbox producttype">
            <h3>Product Name & Type</h3>
            <div class="fieldsdiv">
                <div class="row">

                    <div class="col-sm-3 form-group order-1 order-sm-1 mb0">
                        <mat-label class="input-group__label" for="myInput"> Product
                            name</mat-label>

                        <input type="text" matInput class="input-group__input health planselect"
                            placeholder="Enter product" type="text" formControlName="productName" id="productName" autocomplete="off"
                            readonly>
                    </div>

                    <div class="col-sm-2 form-group mt-4 order-2 order-sm-2 mb0">
                        <div class="productTypeDiv">
                            <mat-button-toggle-group class="custom-toggle-group" formControlName="productType"
                                name="productType" (click)="changeInProductType()">
                                <mat-button-toggle class="custom_togglebtn"
                                    *ngFor="let option of hepDataModal.productType" [value]="option">
                                    {{option}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>


                    <div class="col-sm-3 form-group order-3 order-sm-3">
                        <mat-label class="input-group__label" for="myInput">PIN
                            code</mat-label>
                        <input matInput class="input-group__input" placeholder="Enter pincode" type="text"
                            formControlName="pincode" id="pincode" [minlength]="6" [maxlength]="6" autocomplete="off"
                            (input)="fetchDataAsPerPincode($event,null)">

                        <mat-error *ngIf="hasError('pincode', 'required') && formSubmitted">
                            Pincode is required
                        </mat-error>

                        <mat-error *ngIf="(hasError('pincode','maxlength') || hasError('pincode','minlength')) && formSubmitted">
                            Atleast 6 digit pincode is required
                        </mat-error>

                        <mat-error *ngIf="!hasError('pincode','minlength') && hasError('pincode','pattern')  && formSubmitted">
                            Please enter only numbers
                        </mat-error>

                        <mat-error *ngIf="invalidPinCodeMessage!== ''">
                            {{invalidPinCodeMessage}}
                        </mat-error>

                    </div>

                    <div class="col-xl-3 col-md-4 col-sm-3 form-group order-4 order-sm-4 stateCititeswidth"
                        *ngIf="this.stateFetched">
                        <mat-label class="input-group__label" for="myInput">State & Cities</mat-label>
                        <!-- If length is 1 -->
                        <input type="text" matInput class="input-group__input" placeholder="Enter pincode" type="text"
                            formControlName="cityState" readonly *ngIf="cityData.length == 1" autocomplete="off">


                        <mat-select formControlName="cityState" id="cityState" required
                            class="input-group__input  cstm_select" placeholder="Select state and city"
                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                            (selectionChange)="changeCityName($event.value,true)" *ngIf="cityData.length > 1">
                            <ng-container *ngFor="let item of cityData; let last = last">
                                <mat-option value={{item.value}}>
                                    {{item.stateLabel}} - {{item.cityLabel}}
                                </mat-option>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="hasError('cityState', 'required') && formSubmitted">
                            Please select atleast one option
                        </mat-error>
                    </div>

                    <div class="col-sm-3 form-group order-4 order-sm-4" *ngIf="upgradeZoneFlag && this.stateFetched">
                        <label class="singlelabel emiToggle mb-0" style="width: 100%;">Do you want to upgrade
                            zone?</label>
                        <div class="group_btn">
                            <mat-button-toggle-group formControlName="upgradeZoneOption" name="fontStyle"
                                aria-label="Font Style" class="custom-toggle-group"
                                (click)="onclickUpgradeZoneOption($event)">
                                <mat-button-toggle class="custom_togglebtn" *ngFor="let option of hepDataModal.options"
                                    [value]="option">
                                    {{ option }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>

                    <div class="col-sm-2 form-group order-5 order-sm-5"
                        *ngIf="upgradeZoneFlag && hepForm.controls['upgradeZoneOption'].value==hepDataModal.yes && this.stateFetched">
                        <label class="singlelabel emiToggle mb-0" style="width: 100%;">Select zone</label>
                        <div [ngClass]="{'group_btn': zoneList.length == 1}">
                            <mat-button-toggle-group formControlName="selectedZone" id="zoneDetails"
                                class="custom-toggle-group">
                                <mat-button-toggle class="custom_togglebtn" *ngFor="let option of zoneList"
                                    [value]="option">
                                    {{ option }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <!-- Product Details Card Ends -->

    <!-- Member Detail Card Starts -->
    <mat-card class="innerbox reverinnerbox pt-2">
        <h3>Member Details</h3>
        <div class="fieldsdiv">
            <div class="row">

                <!-- Adult Panel -->
                <div class="col-sm-12 form-group">
                    <div class="memberbtnrefilling">
                        <button class="refilingbtn" *ngFor="let memberData of hepModal.memberButtonData"
                            (click)="memberTabClicked($event)">
                            <!-- Member Icons -->
                            <div class="refilimg">
                                <span>
                                    <img src="{{memberData.imgSrc}}" alt="{{memberData.label}}">
                                </span>
                                <strong>{{memberData.label}}</strong>
                            </div>

                            <!-- Increment Decryment member operations -->
                            <div class="Numberbtn" *ngIf="memberData.incDec" (click)="stopPropogation($event)">
                                <span (click)="decrymentMember()">
                                    <img src="{{memberData.minusImgSrc}}" alt="minus">
                                </span>
                                <!-- Member Count -->
                                <strong>{{adultValue}}</strong>

                                <span (click)="incrementMember()">
                                    <img src="{{memberData.plusImgSrc}}" alt="plus">
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <h3 *ngIf="adultDetails.length > 0">Adults</h3>
        <!-- Adult fields Construction -->
        <div class="fieldsdiv " *ngIf="adultDetails.length > 0" formArrayName="adultDetails">
            <div class="row" *ngFor="let data of adultDetails.controls; let i = index" [formGroupName]="i">
                <div class="col-sm-12">
                    <span class="subheading">Adult {{i+1}}</span>
                </div>
                <div class="col-sm-3">
                    <mat-label class="input-group__label">DOB</mat-label>
                    <input class="input-group__input dob" matInput [matDatepicker]="adultDOB"
                        [formControlName]="'dobAdult' + (i+1)" id="adultDetails"
                        [max]="getInsuredMinMaxDate('Adult','max')" [min]="getInsuredMinMaxDate('Adult','min')"
                        (click)="adultDOB.open()" placeholder="DD/MM/YYYY" readonly
                        (dateInput)="adultsDateComparison()">
                    <mat-datepicker-toggle matSuffix [for]="adultDOB">
                    </mat-datepicker-toggle>
                    <mat-datepicker #adultDOB touchUi>
                    </mat-datepicker>
                    <mat-error class="error-margin"
                        *ngIf="adultDetails.value[i]['dobAdult' + (i+1)] == '' && formSubmitted">
                        Adult {{i+1}} DOB is required
                    </mat-error>
                    <mat-error class="error-margin" *ngIf="adult2DateError">
                        Adult2 age can not be greater than Adult1
                    </mat-error>
                </div>

                <div class="col-sm-3 form-group">
                    <mat-label class="input-group__label" for="myInput">Relationship</mat-label>
                    <mat-select class="input-group__input cstm_select" formControlName="{{'adultRealtionship' + (i+1)}}"
                        id="adultDetails" required (click)="handleMatSelectClick()"
                        (blur)="removeMatSelectScrollListener()" placeholder="Select Relationship">
                        <ng-container *ngFor="let item of adultRelationShips; let last = last">
                            <mat-option value={{item.RelationshipName}}>
                                {{item.RelationshipName}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="adultDetails.value[i]['adultRealtionship' + (i+1)] == '' && formSubmitted">
                        Adult {{i+1}} Relationship is required
                    </mat-error>
                </div>

                <div class="col-sm-3 form-group" *ngIf="isVaccinationDiscountEnabled">
                    <label class="singlelabel vaccineLabel mb-0">Pneumococcal
                        vaccine taken in last 1 year?</label>
                    <div class="group_btn">
                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="custom-toggle-group"
                            formControlName="{{'adultVaccinationStatus' + (i+1)}}">
                            <mat-button-toggle class="custom_togglebtn" (change)="vaccinationDateValidation(i)"
                                *ngFor="let option of hepDataModal.options" [value]="option">
                                {{ option }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <div class="col-sm-3 form-group">
                    <div class="vaccineMr" *ngIf="adultDetails.value[i]['adultVaccinationStatus' + (i+1)] == 'Yes'">
                        <label class="input-group__label" style="top: 13px;" for="myInput">Date of
                            Vaccination</label>
                        <!-- mat date picker starts here -->
                        <input class="input-group__input dob" matInput [matDatepicker]="vaccinationDate"
                            formControlName="{{'adultVaccinationDate' + (i+1)}}" id="adultDetails"
                            placeholder="DD/MM/YYYY" [max]="getMinMaxDateVaccine('max')"
                            [min]="getMinMaxDateVaccine('min')" (click)="vaccinationDate.open()" readonly>
                        <mat-datepicker-toggle matSuffix [for]="vaccinationDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #vaccinationDate touchUi>
                        </mat-datepicker>
                        <mat-error class="error-margin"
                            *ngIf="adultDetails.value[i]['adultVaccinationDate' + (i+1)] == '' && formSubmitted">
                            Adult {{i+1}} Vaccination Date is required
                        </mat-error>
                        <!-- mat date picker ends here -->
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <!-- Premium Details Seection Starts -->
        <h3>Premium Details</h3>
        <div class="fieldsdiv ">
            <div class="row ">
                <!-- Policy Tenure -->
                <div class="col-lg-3 col-md-4 col-sm-6 policyTenureWidth form-group">
                    <!-- Policy Tenure -->
                    <label for="" class="singlelabel mb-0" style="width:100%; margin-bottom: 6px !important">Policy Tenure</label>
                    <div class="policyTenureDiv"><br />
                        <mat-button-toggle-group formControlName="policyTenure" name="fontStyle" aria-label="Font Style"
                            class="custom-toggle-group">
                            <mat-button-toggle class="custom_togglebtn"
                                *ngFor="let tenureData of hepDataModal.tenurePeriod; let j = index" [value]="tenureData"
                                (change)="changePolicyTenure(tenureData)">
                                {{ tenureData }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <mat-error *ngIf="hepForm.controls['policyTenure']?.errors?.['required'] && formSubmitted">
                            Policy Tenure is required
                        </mat-error>
                    </div>
                </div>

                <!-- Policy Range -->
                <div class="col-sm-6 col-lg-3 form-group appIncome">
                    <label for="" class="singlelabel width100 mb-0">SI Range</label>
                    <div class="group_btn">
                        <mat-button-toggle-group formControlName="policyRange" name="fontStyle" aria-label="Font Style"
                            class="custom-toggle-group">
                            <mat-button-toggle class="custom_togglebtn" *ngFor="let range of policyRangeData;let i = index"
                                [value]="range" (change)="changePolicyRange(range)">
                                {{ range }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <mat-error *ngIf="hepForm.controls['policyRange']?.errors?.['required'] && formSubmitted">
                            Policy Plan is required
                        </mat-error>
                    </div>
                </div>

                <!-- Annual Sum Insured -->
                <div class="col-sm-3 form-group appIncome" *ngIf="this.hepForm.controls['policyRange'].value !=''">
                    <label class="input-group__label" style="top: 13px;">Sum Insured</label>
                    <mat-select class="input-group__input cstm_select"
                        (selectionChange)="annualSumChanged($event.value)" placeholder="Select applicant sum insured"
                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                        formControlName="applicantAnnualSum" id="applicantAnnualSum" required>
                        <ng-container *ngFor="let item of sumInsuredDetails; let i = index; let last = last">
                            <mat-option value="{{item.SumAmount}}">
                                {{utilityService.easyAmountReader(item.SumAmount)}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="hepForm.controls['applicantAnnualSum']?.errors?.['required'] && formSubmitted">
                        Sum Insured is required
                    </mat-error>
                </div>
            </div>
        </div>

    </mat-card>
    <!-- Member Detail Card Ends -->

    <!-- Additional Details Start -->
    <mat-card class="innerbox borderinnerbox">
        <h3>Additional Details</h3>
        <div class="fieldsdiv">
            <div class="row">

                <div class="col-sm-3 form-group appIncome">
                    <label class="singlelabel width100 mb-0">Co-Payment</label>
                    <div style="overflow: auto">
                        <mat-button-toggle-group formControlName="copaymentPercentTaken" name="fontStyle"
                            aria-label="Font Style" class="custom-toggle-group">
                            <mat-button-toggle class="custom_togglebtn"
                                *ngFor="let option of hepDataModal.copaymentPercentTaken.hep" [value]="option">
                                {{ option }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-3 form-group"
                    *ngIf="hepForm.controls['policyTenure'].value=='1 Year' && hepForm.controls['productType'].value!='Portability'">
                    <label class="singlelabel emiToggle mb-0" style="width: 100%;">Do you want to opt
                        EMI facility?</label>
                    <div class="group_btn">
                        <mat-button-toggle-group formControlName="EMI" name="fontStyle" aria-label="Font Style"
                            class="custom-toggle-group" (click)="emiValueChanged()">
                            <mat-button-toggle class="custom_togglebtn" *ngFor="let option of hepDataModal.options"
                                [value]="option">
                                {{ option }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <div class="col-sm-3 form-group"
                    *ngIf="hepForm.controls['EMI'].value == 'Yes' && hepForm.controls['productType'].value!='Portability' && hepForm.controls['policyTenure'].value =='1 Year'">
                    <label class="singlelabel emiToggle mb-0" style="width: 100%;">EMI Tenure</label>
                    <div>
                        <mat-button-toggle-group formControlName="EMIInstallment" class="custom-toggle-group">
                            <mat-button-toggle class="custom_togglebtn"
                                *ngFor="let option of hepDataModal.emiInstallments" [value]="option">
                                {{ option }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>                 
            </div>

            <div class="row mt-2">
                <div class="col-sm-12 customaddons pedAddons">
                    <span>
                        <img src=".../../../../../../../assets/images/Waiting Period.svg"
                            alt="Waiting period & pre / post hospital duration" class="waiting">
                        <label for="" class="waitinglabel">Waiting period & pre / post hospital duration</label>
                    </span>
                    <ul>
                        <li>
                            <span>
                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                            </span>
                            <span class="waitingperiodSpan">PED waiting period is 2 years</span>
                        </li>
                        <li>
                            <span>
                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                            </span>
                            <span class="waitingperiodSpan">Specific condition waiting period is 2 years</span>
                        </li>
                        <li>
                            <span>
                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                            </span>
                            <span class="waitingperiodSpan">Pre hospital duration is 60 days</span>
                        </li>
                        <li>
                            <span>
                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                            </span>
                            <span class="waitingperiodSpan">Post hospital duration is 180 days</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </mat-card>
    <!-- Additional Details End -->

    <!-- Additional Covers Start -->
    <mat-card class="innerbox borderinnerbox" *ngIf="hepForm.controls['applicantAnnualSum'].value != ''">
        <h3>Additional Cover</h3>

        <!-- Non member based addons -->
        <div class="fieldsdiv mt-1 mb-2">
            <div class="row">
                <div class="col-sm-12 customCheckslist">
                    <ul>
                        <li *ngFor="let addons of nonMemberBasedAddons">
                            <fieldset [disabled]="addons.disable">
                                <legend class="hidden-caption">Covers</legend>
                                <mat-checkbox *ngIf="addons.visiblityAsPerService && addons.visible"
                                    class="cstmCheckbox" [checked]="addons['checkboxValue']['checkedAddon']"
                                    formControlName="{{addons.label}}">{{addons.label}}</mat-checkbox>
                            </fieldset>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div class=" col-lg-3 col-md-6 col-sm-6 form-group appIncome" *ngIf="befitAddons.length > 0">
            <label class="input-group__label" style="top: 13px;">Choose plan</label>
            <mat-select required class="input-group__input cstm_select"
                (selectionChange)="onbefitAddonSelection($event.value)" placeholder="Select Befit Plan"
                (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                formControlName="BefitSelectionAddon" id="BefitSelectionAddon" required>
                <ng-container *ngFor="let item of befitAddons; let i = index; let last = last">
                    <mat-option value="{{item.label}}" selected>
                        {{item.label}}
                    </mat-option>
                    <mat-divider *ngIf="!last"></mat-divider>
                </ng-container>
            </mat-select>
        </div>

    </mat-card>
    <!-- Additional Covers Ends -->
</form>

<app-error-screen *ngIf="multipleServiceError" (refreshService)="onRefresh($event)">
</app-error-screen>

<app-custom-footer *ngIf="!multipleServiceError && loadFormContent" [normalButton]="true"
    [buttonData]="[{'label': 'Generate Quote', 'type': 'primary', 'source': 'quote', 'id': 'f1','disabled': false , 'hide': false}]"
    (buttonClicked)="getQuote($event)">
</app-custom-footer>