import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthRoutingModule } from './health-routing.module';
import { HapComponent } from './products/hap/hap.component';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoldenShieldComponent } from './products/golden-shield/golden-shield.component';
import { InsuredDetailsComponent } from './health-shared-components/insured-details/insured-details.component';
import { PortabilityComponent } from './health-shared-components/portability-details/portability-details.component';
import { SummaryPageComponent } from './health-shared-components/summary-page/summary-page.component';
import { KycDetailsComponent } from 'src/app/shared/components/kyc-details/kyc-details.component';
import { DocumentUploadComponent } from './document-upload/document-upload/document-upload.component';
import { LayoutModule } from 'src/app/layout/layout.module';
import { HealthBoosterComponent } from './products/health-booster/health-booster.component';
import { CritiShieldComponent } from './products/criti-shield/criti-shield.component';
import { ProductStepmanagerComponent } from './products/product-stepmanager/product-stepmanager.component';
import { AspComponent } from './products/asp/asp.component';
import { MaxProtectComponent } from './products/max-protect/max-protect.component';
import { FamilyshieldComponent } from './products/family-shield/familyshield.component';
import { HealthElitePlusComponent } from './products/health-elite-plus/health-elite-plus.component';
import { ZeroTatInsuredDetailsComponent } from './zero-tat-health-shared-component/zero-tat-insured-details/zero-tat-insured-details.component';
import { ZeroTatSummaryDetailsComponent } from './zero-tat-health-shared-component/zero-tat-summary-details/zero-tat-summary-details.component';
import { OrionHapComponent } from './products/hap/orion-hap/orion-hap/orion-hap.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ElevateComponent } from './products/elevate/elevate.component';
import { QuickQuoteComponent } from './quick-quote/quick-quote/quick-quote.component';
import { OldUploadDocumentComponent } from './document-upload/old-upload-document/old-upload-document.component';
import { SaverBoosterComponent } from './products/elevate/saver-booster/saver-booster.component';

@NgModule({
  declarations: [
    HapComponent,
    PortabilityComponent,
    KycDetailsComponent,
    SummaryPageComponent,
    GoldenShieldComponent,
    DocumentUploadComponent,
    OldUploadDocumentComponent,
    HealthBoosterComponent,
    ProductStepmanagerComponent,
    AspComponent,
    CritiShieldComponent,
    ProductStepmanagerComponent,
    DocumentUploadComponent,
    MaxProtectComponent,
    FamilyshieldComponent,
    HealthElitePlusComponent,
    ZeroTatInsuredDetailsComponent,
    ZeroTatSummaryDetailsComponent,
    InsuredDetailsComponent,
    OrionHapComponent,
    LandingPageComponent,
    ElevateComponent,
    QuickQuoteComponent,
    SaverBoosterComponent
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HealthRoutingModule,
    LayoutModule
  ],

  exports: [
    MaterialModule,
    HapComponent,
    GoldenShieldComponent,
    ProductStepmanagerComponent,
    HealthBoosterComponent,
    InsuredDetailsComponent,
  ],

})
export class HealthModule { }
