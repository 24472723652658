import { Component, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { StorageService } from 'src/app/shared/services/storage.service';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/layout/popups/popup.component';
import {  popupType } from 'src/app/shared/enums/popupEnums';
import { Constants } from 'src/app/shared/constants/constants';
import { HealthClaimService } from './services/health-claim.service';
import { HealthClaimData } from './interface/health-claim';
import { HealthClaimStatusComponent } from './components/health-claim-status/health-claim-status.component';
import { Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'app-health-claim',
  templateUrl: './health-claim.component.html',
  styleUrls: ['./health-claim.component.scss']
})
export class HealthClaimComponent {

  private interval: any;
  bookFormGroup: FormGroup;
  agentIMID: string;
  productTypeList: { productName: string; productCode: number }[];
  selectList: any = [];
  isSelected: boolean = false;
  isSubmitted: boolean = false;
  isSearchVisible: boolean = false;
  toggleValue: 'table' | 'card' = 'card';
  minMax: { minDate: Date, maxDate: Date };
  reportList: any = [];
  filteredData: HealthClaimData[] = [];
  product: string = '';
  startDate: Date;
  endDate: Date;
  phText: string[] = ['Search for Claim Number',
    'Search for Policy Number',]
  searchPlaceHolder: string;
  i: number = 0;
  inputValue: string = '';
  scrolling: boolean = false;
  showCancel: boolean = false;
  policyCount: string;
  businessAmount: string;
  cardBtnInfo = [{
    label: 'Check Status',
    imgPath: '../../../assets/images/myPolicy/checkStatus.svg',
  }]
  tableBtnInfo = [{
    label: 'Check Status',
    imgPath: '../../../../../../../assets/images/BBR/checkstatus.svg',
    tooltip: 'Check status'
  }]

  displayCol = [
    {
      columnDef: 'POLICY_NUMBER',
      header: 'Policy Number',
      cell: (element: any) => `${element.POLICY_NUMBER}`,
    },
    {
      columnDef: 'CUSTOMER_NAME',
      header: 'Customer Name',
      cell: (element: any) => `${element.CUSTOMER_NAME}`,
    },
    {
      columnDef: 'CLAIM_NUMBER',
      header: 'Claim Number',
      cell: (element: any) => `${element.CLAIM_NUMBER}`,
    },
    {
      columnDef: 'PRODUCT_TYPE',
      header: 'Product Type',
      cell: (element: any) => `${element.PRODUCT_TYPE}`,
    },
    {
      columnDef: 'Action',
      header: 'Action',
      cell: '',
    },
    {
      columnDef: 'POLICY_START_DATE',
      header: 'Policy Start Date',
      cell: (element: any) => `${element.POLICY_START_DATE}`,
    },
    {
      columnDef: 'POLICY_END_DATE',
      header: 'Policy End Date',
      cell: (element: any) => `${element.POLICY_END_DATE}`,
    },
    {
      columnDef: 'HOSPITAL_NAME',
      header: 'Hospital Name',
      cell: (element: any) => `${element.HOSPITAL_NAME}`,
    },
    {
      columnDef: 'HOSPITAL_LOCATION',
      header: 'Hospital Location',
      cell: (element: any) => `${element.HOSPITAL_LOCATION}`,
    },
    {
      columnDef: 'INTIMATION_DT',
      header: 'Intimation Date',
      cell: (element: any) => `${element.INTIMATION_DT}`,
    },
    {
      columnDef: 'CARD_NUMBER_UHID',
      header: 'Card Number UHID',
      cell: (element: any) => `${element.CARD_NUMBER_UHID}`,
    }
  ]
  subscription: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private storageService: StorageService,
    private agentV1Service: AgentV1Service,
    public utilityService: UtilityService,
    private dialog: MatDialog,
    private healthClaimService: HealthClaimService) { }

  ngOnInit() {
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    this.getProductList();
    this.initFormGroup();
    this.getInitialDates();
    this.setPlaceHolderText();
    this.onSubmit();
    this.interval = setInterval(() => { this.setPlaceHolderText(); }, 2000);
  }

  onWindowScroll() {
    this.scrolling = true;

    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  setPlaceHolderText() {
    this.searchPlaceHolder = this.phText[this.i];
    this.i++;
    if (this.i == this.phText.length) {
      this.i = 0;
    }
  }

  inputFocus() {
    this.showCancel = true;
  }

  inputBlur() {
    this.showCancel = false;
  }

  queryData() {
    const query = this.inputValue.trim();

    if (!query) {
      this.filteredData = this.reportList;
      return;
    }
    this.filteredData = this.reportList.filter(item => {
      return Object.values(item).some(val =>
        val.toString().toLowerCase().includes(query.toLowerCase())
      );
    });
  }

  searchFilter() {
    this.isSearchVisible = true;
  }

  preventInput(event: KeyboardEvent): void {
    const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  openSearchBottomSheet() {
    const searchBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      height: '40%',
      disableClose: true,
      data: {
        popupType: popupType.search, data: {
          controls: [{
            type: 'input',
            label: 'Product Type',
            readonly: true,
            value: 'Health'
          },
          {
            type: 'rangepicker',
            label: 'Select date range',
            startDate: this.bookFormGroup.value.startDate,
            endDate: this.bookFormGroup.value.endDate,
          }],
          dates: {
            maxDate: this.minMax.maxDate,
          },
        }
      },
    }
    );
    searchBottomSheet.afterClosed().subscribe((data) => this.patchSearchParameters(data));
  }

  patchSearchParameters(data) {
    if (data) {
      for (let i of data) {
        if (i.type == 'rangepicker') {
          this.bookFormGroup.controls['startDate'].setValue(i.startDate);
          this.bookFormGroup.controls['endDate'].setValue(i.endDate);
        }
      }
      //API call for search function
      this.onSubmit();
    }
  }

  closeClear() {
    this.isSearchVisible = false;
    this.clearInputField();
  }

  getProductList() {
    const loginCredentials = this.storageService.getAESDecryptedData(userEnums.LoginCredential);
    this.agentIMID = loginCredentials.username;
    const agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.productTypeList = this.agentV1Service.productTypeList(agentDetails, '');
  }

  getInitialDates() {
    const currentDate = new Date();
    const min = new Date(currentDate.getFullYear() - 3, 0, 1);
    const max = currentDate;
    this.minMax = { minDate: min, maxDate: max };
  }

  initFormGroup() {
    const SD = this.utilityService.subtractYears(new Date(), 3);
    this.bookFormGroup = this.formBuilder.group({
      IMID: [this.agentIMID],
      productType: ["Health"],
      startDate: [SD],
      endDate: [new Date()]
    })
    this.updateInfoCard();
  }


  updateInfoCard() {
    this.product = this.bookFormGroup.value.productType;
    this.startDate = this.bookFormGroup.value.startDate;
    this.endDate = this.bookFormGroup.value.endDate
  }

  onSubmit() {
    this.isSubmitted = true;
    this.startDate = this.bookFormGroup.value.startDate;
    this.endDate = this.bookFormGroup.value.endDate
    const payload = {
      "Startdt": this.utilityService.formatDateWithDash(this.startDate),
      "Enddt": this.utilityService.formatDateWithDash(this.endDate),
      "ClaimNumber": ""
    }
    this.healthClaimService.getHealthClaimDetails(payload).subscribe(
      (response) => {
        this.policyCount = response.Data.length;
        if (response.Data.length > 0) {
          // Show Data
          this.filteredData = response.Data;
          this.reportList = this.filteredData;
        } else {
          // No Data
          this.reportList = [];
          this.filteredData = [];
        }
      })
  }

  getClaimStatus(response) {
    let payload = {
      "CLAIM_NUMBER": response.data.CLAIM_NUMBER,
      "LOMB_NAME": "HEALTH"
    }
    this.healthClaimService.getHealthClaimStatus(payload).subscribe(response => {
      if (response.StatusCode === Constants.statusCode_success) {
        this.dialog.open(HealthClaimStatusComponent, { panelClass: 'my-custom-popup', data: response.Data, height: '80%', width: '90%', maxWidth: '100%', autoFocus: false });
      }
    })
  }

  onToggle(event) {
    this.toggleValue = event.value;
  }

  clearInputField() {
    this.inputValue = '';
    this.queryData();
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  handleMatSelectClick() {
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  onRefresh(status) {
    if (status) {
      this.onSubmit();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }
}
