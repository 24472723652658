import { Component, ElementRef, EventEmitter, Output, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { Subscription, Observable, concatMap, forkJoin, of, catchError } from 'rxjs';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { HealthDataStorage, PremiumDetailsData } from 'src/app/modules/health/health.modal';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { Constants } from 'src/app/shared/constants/constants';
import { healthProducts, patterns, healthYears, healthDays, healthMembers, RefiledHapAddons, zoneRefillingDetails, healthToggle, RelationShipGender, healthProposalProductCode, insuredControls } from 'src/app/shared/enums/healthEnums';
import { popupType, popupImgPath, popupHeader, popupDescriptionMsg, popupButton } from 'src/app/shared/enums/popupEnums';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { GetCibilScoreRequestPayload } from 'src/app/shared/interface/agent';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { HealthAdvantageResponsePayload, PolicyPlan, ZoneRefillingRequestPayload, ZoneRefillingResponsePayload, SaveQuoteResponseStructure, AddOnCover, ElevateAddonCover } from 'src/app/shared/interface/health-advantedge';
import { InsuredRelationship, RelationshipResponsePayload, RelationshipRequestPayload, GetBefitApplicabityPayload } from 'src/app/shared/interface/healthMaster';
import { IncomeRange } from 'src/app/shared/interface/max-protect';
import { RTOListRequestPayload } from 'src/app/shared/interface/rto-list';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { SaveEditQuoteHapV1Request } from 'src/app/shared/interface/transactionHealthAdvantege';
import { AgentService } from 'src/app/shared/proxy-services/agent.service';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { HealthElitePlusService } from 'src/app/shared/proxy-services/health-elite-plus.service';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AddonStructure, HAPModal, ParentAddonDetails } from '../../hap-modal';
import { ConvertSavedQuotesResponsePayload, SavedQuote } from 'src/app/shared/interface/savedQuotes';

@Component({
  selector: 'app-orion-hap',
  templateUrl: './orion-hap.component.html',
  styleUrls: ['./orion-hap.component.scss']
})
export class OrionHapComponent {
  @Output() QuoteValueEmitter = new EventEmitter<{ Quote: boolean, Portability: boolean, StatusCode: boolean }>();
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  healthAdvantageForm: FormGroup;
  formSubmitted: boolean = false;
  formReset: boolean = false;
  healthProducts = healthProducts; // health enums
  hapDataModal: HAPModal = new HAPModal(); //hap data modal 
  hapFormData: HealthDataStorage;
  occupationMaster = [];
  policyRangeData = [];
  hapMasterData: HealthAdvantageResponsePayload;
  annualIncome: IncomeRange[] = [];
  adultRelationShips: InsuredRelationship[] = [];
  childRelationShips: InsuredRelationship[] = [];
  adultData = [];
  childData = [];
  subscription: Subscription[] = [];
  sumInsuredDetails: PolicyPlan[] = [];
  refiledAddons: ParentAddonDetails[] = [];
  cityData = [];
  addOns = [];
  tenurePeriod: string[] = [];
  addonsFromService: AddOnCover[] = [];
  personalAccidentAddons: Array<AddonStructure> = [];
  maternityAddons: Array<AddonStructure> = [];
  criticalAddons: Array<AddonStructure> = [];
  premiumDetails: PremiumDetailsData;
  stateDetails = this.hapDataModal.stateDetails;
  zoneMappedWithPincode: string = '';
  invalidPinCodeMessage: string;
  stateFetched: boolean = false;
  selfMemberExists: boolean = false;
  success: boolean = false;
  isVaccinationDiscountEnabled: boolean = false;
  multipleServiceError: boolean = false;
  isVoluntaryDeductibleDisabled: boolean = false;
  loadFormContent: boolean = false;
  patchingDone: boolean = false;
  tenureDuration: number = 0;
  previousAdultValue: string = '';
  previousChildValue: string = '';
  errorPopupData: PopupModal;
  previousChildRelation: string = '';
  insuredMinMaxDate: { maxDate: Date; minDate: Date; };
  vaccineMinMaxDate: { maxDate: Date; minDate: Date; };
  childMinMaxDate: Array<{ maxDate: Date; minDate: Date; }> = [];
  selectedOption = 'No';
  statuscode: boolean;
  disableButton: boolean = false;
  portability: boolean = false;
  // datepickerMobile: boolean = false;
  reviseZone = [];
  maternityAdultData: Array<{ 'id': number, 'label': string }> = [];
  criticalAdultData: Array<{ 'id': number, 'label': string }> = [];
  dealId: string = '';
  channelData: ChannelData;
  adultValue: number = 1;
  childValue: number = 0;
  revisedZone = [];
  befitData = [];
  isUpdatingControls: boolean = false;
  showVoluntaryDeductible: boolean = true;
  showEMIValue: boolean = false;
  onCibilSave: boolean = false;
  hasPedAddons: boolean = false;
  bgColor: string = '#EC6608';
  textColor: string = '#282828';
  showSave: boolean = false;
  cibilScore: number;
  emiPremium: string = '';
  netPremium: string = '';
  taxAmount: string = '';
  cibilToggle: boolean = false;
  summaryAddons = [];
  isEMIdisabled: boolean = false;
  quoteID: number;
  adultDOBValid: boolean = true;
  quoteConvertData: SavedQuote;
  quoteDetails: ConvertSavedQuotesResponsePayload;

  constructor(private fb: FormBuilder,
    public utilityService: UtilityService,
    private healthAdvantedgeService: HealthAdvantedgeService,
    private healthMasterService: HealthMasterService,
    private rtoListService: RTOListService,
    private popupService: PopupService,
    private healthDataStorageService: HealthDataStorageService,
    private renderer: Renderer2,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    // private windowResizeService: WindowResizeService,
    private el: ElementRef,
    private healthElitePlusService: HealthElitePlusService,
    private storageService: StorageService,
    public router: Router,
    private agentService: AgentService) {
  }

  ngOnInit() {
    // this.popupService.closeAllDialog(true);
    // setTimeout(() => {
    //   this.popupService.closeAllDialog(false);
    // }, 0);
    // window.scrollTo(0, 0);
    // this.windowResizeService.getResizeObservable().subscribe(data => {
    //   this.datepickerMobile = data.width < 768;
    // })
    this.onLoadSteps();
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
  }

  onLoadSteps() {
    this.premiumDetails = this.healthDataStorageService.initializePremiumData()
    this.hapFormData = this.healthDataStorageService.getHealthDataStorage();

    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    if (this.quoteDetails) {
      this.quoteConvertData = this.quoteDetails.Data;
      this.updateSavedQuotesConvertData();
      // this.changeInProductType();
      this.customFooterService.showPremiumDetails(true);
    }

    //check condition for save quote as well
    if (this.hapFormData.quoteDataSet) {
      this.patchingDone = false;
      this.onCibilSave = true;
      this.showSave = true;
    }
    this.createHAPForm();
    this.fetchHAPDetails();
  }

  updateSavedQuotesConvertData() {
    this.success = true;
    this.hapFormData.quoteDataSet = true;
    let quoteFormData = this.hapFormData.quoteFormData;
    // let GSTData = this.quoteConvertData.CustomerDetails.GSTDetails;
    quoteFormData.productType = this.quoteConvertData.ProductType;
    quoteFormData.pincode = this.quoteConvertData.Pincode;
    quoteFormData.policyTenure = this.tenureSelectionAsPerNumber(this.quoteConvertData.Tenure);
    quoteFormData.policyRange = this.quoteConvertData.PolicyRange;
    quoteFormData.applicantAnnualSum = this.quoteConvertData.Members[0].SumInsured.toString();
    quoteFormData.applicantAnnualIncome = this.quoteConvertData.ProposalAnnualIncomeRange;
    quoteFormData.InstallmentApplicable = this.quoteConvertData.InstallmentApplicable;
    quoteFormData.InstallmentFrequency = this.quoteConvertData.InstallmentFrequency;
    quoteFormData.copaymentPercentTaken = this.quoteConvertData.VoluntaryCopaymentPercentage;
    // quoteFormData.addonsFormdata = this.quoteConvertData.hepAddonsFormData;
    quoteFormData.zone = this.quoteConvertData.Zone;
    quoteFormData.cityState = this.quoteConvertData.CityState;
    // quoteFormData.copaymentPercentTaken = this.quoteConvertData.VoluntaryCopaymentPercentage;
    this.zoneMappedWithPincode = this.hapFormData.quoteFormData.zone;
    this.hapFormData.premiumFormData.InstallmentTotalPremium = parseInt(this.quoteConvertData.InstallmentTotalPremium);
    quoteFormData.productType = this.quoteConvertData.ProductType;
    quoteFormData.zoneUpgrade = this.quoteConvertData.ZoneUpgrade;
    quoteFormData.reviseZone = this.quoteConvertData.RevisedZone;
    quoteFormData.productName = healthProducts.hap;
    quoteFormData.healthProposalProductType = healthProposalProductCode.hap;
    quoteFormData.maternityAddons = this.quoteConvertData.hapAddonsFormData.maternityAddons;
    quoteFormData.personalAccidentAddons = this.quoteConvertData.hapAddonsFormData.personalAccidentAddons;
    quoteFormData.nonMemberBasedAddons = this.quoteConvertData.hapAddonsFormData.nonMemberBasedAddons;
    quoteFormData.showMaternityDivider = this.quoteConvertData.hapAddonsFormData.showMaternityDivider;
    quoteFormData.showPersonalAccidentDivider = this.quoteConvertData.hapAddonsFormData.showPersonalAccidentDivider;
    quoteFormData.addonsFormdata = this.quoteConvertData.hapAddonsFormData.addonsFormdata;
    quoteFormData.addons = this.quoteConvertData.hapAddonsFormData.addons;
    quoteFormData.postHospitalDuration = this.quoteConvertData.PostHospitalization;
    quoteFormData.preHospitalDuration = this.quoteConvertData.PreHospitalization;
    quoteFormData.pedWaitingPeriod = this.quoteConvertData.PEDWaitingPeriod;
    quoteFormData.conditionWaitingPeriod = this.quoteConvertData.SpecificConditionWaiting
    let personalAccidentAddons = this.quoteConvertData.hapAddonsFormData.addons.AddOn8Members;
    if (personalAccidentAddons) {
      personalAccidentAddons.filter(addon => {
        if (addon.AddOnName == 'Adult1') {
          quoteFormData.occupationForAdult1 = this.quoteConvertData.hapAddonsFormData.occupationForAdult1;
          // this.healthAdvantageForm.controls['occupationForAdult1'].setValue(quoteFormData.occupationForAdult1);
        } else {
          quoteFormData.occupationForAdult2 = this.quoteConvertData.hapAddonsFormData.occupationForAdult2;
          // this.healthAdvantageForm.controls['occupationForAdult2'].setValue(this.hapFormData.quoteFormData.occupationForAdult2);
        }
      })
    }
    quoteFormData.adultRelationShips = this.quoteConvertData.AdultRelationShips;
    quoteFormData.childRelationShips = this.quoteConvertData.ChildRelationShips;
    quoteFormData.subProductType = this.hapDataModal.subProductType.hap;
    this.hapFormData.premiumFormData.PfQuoteId = this.quoteConvertData.GenericData.pfQuoteId;
    quoteFormData.corelationId = this.quoteConvertData.CustomerDetails.CorelationId;
    quoteFormData.addonsData = this.quoteConvertData.hapAddonsFormData.addOnData;
    quoteFormData.isCibilApplicable = this.quoteConvertData.hapAddonsFormData.cibilApplicable;
    quoteFormData.proposerName = this.quoteConvertData.hapAddonsFormData.cibilProposerName;
    quoteFormData.mobileNo = this.quoteConvertData.hapAddonsFormData.cibilMobileNo;
    quoteFormData.DOB = this.quoteConvertData.hapAddonsFormData.cibilDOB;
    quoteFormData.pinCode = this.quoteConvertData.hapAddonsFormData.cibilPincode;
    quoteFormData.occupationdData = this.quoteConvertData.hapAddonsFormData.occupationdData;
    quoteFormData.cibilScoreDiscount = this.quoteConvertData.hapAddonsFormData.cibilScoreDiscount;
    quoteFormData.maternityForAdult = this.quoteConvertData.hapAddonsFormData.maternityForAdult;
    quoteFormData.criticalIllnessForAdult = this.quoteConvertData.hapAddonsFormData.criticalIllnessForAdult;
    quoteFormData.criticalAdultData = this.quoteConvertData.hapAddonsFormData.criticalAdultData;
    quoteFormData.maternityAdultData = this.quoteConvertData.hapAddonsFormData.maternityAdultData;
    quoteFormData.totalMemberData = this.quoteConvertData.hapAddonsFormData.totalMemberData;
    quoteFormData.hapRefillingSummary = this.quoteConvertData.hapAddonsFormData.hapRefillingSummary;
    quoteFormData.subProductCode = this.quoteConvertData.hapAddonsFormData.subProductCode;
    quoteFormData.netPremium = this.quoteConvertData.hapAddonsFormData.netPremium;
    quoteFormData.taxAmount = this.quoteConvertData.hapAddonsFormData.taxAmount;
    quoteFormData.emiPremium = this.quoteConvertData.hapAddonsFormData.emiPremium;
    quoteFormData.PlanCode = this.quoteConvertData.hapAddonsFormData.PlanCode;
    quoteFormData.PlanName = this.quoteConvertData.hapAddonsFormData.PlanName;
    this.hapFormData.premiumFormData.PortabilitySubProductCode = this.quoteConvertData.hapAddonsFormData.PortabilitySubProductCode;
    quoteFormData.proposerDobCi = this.quoteConvertData.hapAddonsFormData.proposerDobCi;
    this.healthDataStorageService.setHealthDataStorage(this.hapFormData);

    const req = this.constructDownloadPDFRequest();
    this.storageService.setAESEncryptedData(userEnums.QuotePDFData, req);
  }

  getAddonsForDownloadPDF() {
    const members = this.quoteConvertData.Members.map(item => {
      return {
        "DOB": item.DOB,
        "MemberType": item.MemberType,
        "RelationshipwithApplicant": item.RelationshipName,
        "VaccinationDate": item.VaccinationDate,
        "Occupation": item.Occupation,
        "AddonCoverDetails": item.AddOnCoverDetails
      }
    })
    return members;
  }

  constructDownloadPDFRequest() {
    const requestData: SaveEditQuoteHapV1Request = {
      "ProductType": this.quoteConvertData.ProductType == 'New' ? 'N' : 'R',
      "UserType": this.utilityService.isEmptyOrNull(this.channelData) ? this.hapDataModal.agent : this.channelData.UT,
      "NoOfAdults": (this.quoteConvertData.AdultDetails.length).toString(),
      "NoOfKids": (this.quoteConvertData.ChildDetails.length).toString(),
      "NoOfInLaws": null,
      "AgeGroup": null,
      "SubProductType": this.quoteConvertData.SubProductType,
      "SubLimit": null,
      "Tenure": this.quoteConvertData.Tenure,
      "IsJammuKashmir": this.quoteConvertData.CustomerDetails.StateCode.toString() == this.hapDataModal.stateId ? true : false,
      "GSTStateCode": this.quoteConvertData.CustomerDetails.StateCode.toString(),
      "CityCode": this.quoteConvertData.CustomerDetails.CityCode.toString(),
      "SumInsured": this.quoteConvertData.Members[0].SumInsured.toString(),
      "GSTStateName": this.quoteConvertData.CustomerDetails.State ? this.quoteConvertData.CustomerDetails.State : '',
      "ProposerDOB": this.quoteConvertData.hapAddonsFormData.proposerDobCi.length > 0 ? this.utilityService.formatDate(this.quoteConvertData.hapAddonsFormData.proposerDobCi) : '',
      "isGSTRegistered": this.quoteConvertData.CustomerDetails.StateCode.toString() == this.hapDataModal.stateId ? true : false,
      "CalculateHigherSI": false,
      "SaveQuote": false,
      "Members": this.getAddonsForDownloadPDF(),
      "SubProductCode": this.quoteConvertData.ProductType == 'New' ? this.quoteConvertData.hapAddonsFormData.PortabilitySubProductCode : this.quoteConvertData.hapAddonsFormData.subProductCode,
      "IpAddress": this.quoteConvertData.IpAddress,
      "IsOtherLoadingDiscount": this.quoteConvertData.IsOtherLoadingDiscount,
      "City": this.quoteConvertData.CustomerDetails.City,
      "Pincode": this.quoteConvertData.Pincode,
      "PreHospitalization": this.quoteConvertData.PreHospitalization,
      "PostHospitalization": this.quoteConvertData.PostHospitalization,
      "ProposalAnnualIncome": this.quoteConvertData.ProposalAnnualIncomeRange,
      "PEDWaitingPeriod": this.quoteConvertData.PEDWaitingPeriod,
      "SpecificConditionWaiting": this.quoteConvertData.SpecificConditionWaiting,
      "ZoneUpgrade": this.quoteConvertData.ZoneUpgrade,
      "Zone": this.quoteConvertData.Zone,
      "RevisedZone": this.quoteConvertData.RevisedZone,
      "VoluntaryCopaymentPercentage": this.quoteConvertData.VoluntaryCopaymentPercentage.replace('%', ''),
      "InstallmentApplicable": this.quoteConvertData.InstallmentApplicable,
      "InstallmentFrequency": this.quoteConvertData.InstallmentFrequency,
      "CibilScorePercentage": this.quoteConvertData.hapAddonsFormData.cibilScoreDiscount,
      "CibilDiscountRequest": {
        "userName": this.quoteConvertData.hapAddonsFormData.cibilProposerName,
        "userMobile": this.quoteConvertData.hapAddonsFormData.cibilMobileNo,
      },
    }
    return requestData;
  }

  tenureSelectionAsPerNumber(data: number) {
    let tenureSelect;
    if (data == 1) {
      tenureSelect = this.hapDataModal.tenurePeriod[0];
    } else if (data == 2) {
      tenureSelect = this.hapDataModal.tenurePeriod[1];
    } else {
      tenureSelect = this.hapDataModal.tenurePeriod[2];
    }
    return tenureSelect;
  }

  //form construction
  createHAPForm() {
    // patch annual sum insured if quote data is already present
    this.healthAdvantageForm = this.fb.group({
      productName: [healthProducts.hap],
      productType: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.productType : this.hapDataModal.productType[0]],
      pincode: [this.hapFormData.quoteFormData.pincode, [Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6)]],
      cityState: ['', Validators.required],
      adultDetails: this.fb.array([]),
      childDetails: this.fb.array([]),
      policyTenure: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.policyTenure : this.hapDataModal.tenurePeriod[0], Validators.required],
      policyRange: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.policyRange : this.hapFormData.quoteFormData.policyRange[0], Validators.required],
      applicantAnnualSum: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.applicantAnnualSum : '', [Validators.required]],
      pedWaitingPeriod: [healthYears.TwoYear],
      conditionWaitingPeriod: [healthYears.TwoYear],
      preHospitalDuration: [healthDays.SixtyDays],
      postHospitalDuration: [healthDays.OneEightyDays],
      applicantAnnualIncome: [this.hapFormData.quoteFormData.applicantAnnualIncome, [Validators.required]],
      EMI: [{ value: this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.InstallmentApplicable : 'No', disabled: true }],
      EMIInstallment: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.InstallmentFrequency : 'Monthly'],
      cibilDiscountSection: this.fb.group({
        cibilDiscount: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.isCibilApplicable : this.hapDataModal.options[1]],
        proposerName: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.proposerName : ''],
        mobileNo: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.mobileNo : ''],
        DOB: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.DOB : ''],
        pinCode: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.pinCode : '']
      }),
      zoneUpgrade: [this.hapFormData.quoteDataSet && this.hapFormData.quoteFormData.zoneUpgrade ? 'Yes' : 'No'],
      reviseZone: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.reviseZone : ''],
      revisedZone: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.revisedZone : ''],
      copaymentPercentTaken: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.copaymentPercentTaken : this.hapDataModal.copaymentPercentTaken.hapRefiling[0]],
      voluntaryDeductible: [this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.voluntaryDeductible : 0],
      zoneDowngrade: [this.hapFormData.quoteDataSet && this.hapFormData.quoteFormData.zoneDowngrade ? 'Yes' : 'No'],
      occupation: this.fb.array([]),
    })

    this.healthAdvantageForm.get('copaymentPercentTaken').valueChanges.subscribe(value => {
      if (!this.isUpdatingControls) {
        this.isUpdatingControls = true;
        this.healthAdvantageForm.get('voluntaryDeductible').setValue(value ? null : '');
        this.isUpdatingControls = false;
      }
    });


    this.healthAdvantageForm.get('voluntaryDeductible').valueChanges.subscribe(value => {
      if (!this.isUpdatingControls) {
        this.isUpdatingControls = true;
        this.healthAdvantageForm.get('copaymentPercentTaken').setValue(value ? null : '');
        this.isUpdatingControls = false;
      }
    });

    this.healthAdvantageForm.get('cibilDiscountSection').valueChanges.subscribe(value => {
      if (this.patchingDone) {
        this.showSave = false;
        this.onCibilSave = false;
      }
    });

    // Creating adult and child controls and patching existing data if present
    this.patchExistingMemberData();
    this.healthAdvantageForm.valueChanges.subscribe((data) => {
      // Below logic works when data is already patched .OR. User comes back and changes value. Only when premium is set
      // Now when user tries to change the value the stepper should reset to quote page
      // And premium should hide
      console.log(data);
      
      if (this.patchingDone) {
        // premium data set flag is used beacsuse user can come back and try to change the values.
        // In that case also we need to reset the stepper
        // And if user refreshes that means existing value is getting patched. In that case we are using the patchingDone flag
        // and resetting the premium footer
        this.showPremiumDetails(false);
        // Here we are resetting the stepper. Just to be sure if the quote is changed.
        // User needs to traverse through each steps
        this.customStepperService.resetStepper(true);
      }
    })

  }

  patchExistingMemberData() {
    if (this.hapFormData.quoteDataSet) {
      // IPAA-176: Patch AdultDetails and ChildDetails for Convert API
      if (this.quoteConvertData && this.quoteConvertData.AdultDetails) {
        // this.hepFormData.quoteFormData.adultDetails = this.quoteConvertData.AdultDetails;
        this.hapFormData.quoteFormData.adultDetails = this.quoteConvertData.AdultDetails.map((data: any, i) => ({
          [`dobAdult${(i + 1)}`]: data.dob,
          [`adultRealtionship${(i + 1)}`]: data.relationship,
          [`adultVaccinationStatus${(i + 1)}`]: data.vaccineStatus,
          [`adultVaccinationDate${(i + 1)}`]: data.vaccineDate ? new Date(data.vaccineDate) : null,
        }));
      }
      if (this.quoteConvertData && this.quoteConvertData.ChildDetails) {
        this.hapFormData.quoteFormData.childDetails = this.quoteConvertData.ChildDetails.map((data: any, i) => ({
          [`dobChild${(i + 1)}`]: new Date(data.dob),
          [`childRelationship${(i + 1)}`]: data.relationship
        }));
      }
      this.showVoluntaryDeductible = Number(this.hapFormData.quoteFormData.applicantAnnualSum) <= 2000000
      // this.showEMIValue = Number(this.hapFormData.quoteFormData.applicantAnnualSum) <= 2000000

      this.cibilChange();
      this.adultValue = this.hapFormData.quoteFormData.adultDetails.length;
      this.childValue = this.hapFormData.quoteFormData.childDetails.length;
      if (this.hapFormData.quoteFormData.adultDetails.length > 0) {
        this.hapFormData.quoteFormData.adultDetails.forEach((data, index) => {
          this.adultDetails.push(this.utilityService.createAdultDetails(index));
          // Patch values of adults
          // Below block is written because when there is self data that get's patched from kyc is not in date format
          // So to handle it we need to convert in into date type
          for (let adultKeys in data) {
            if (adultKeys.includes('dob')) {
              let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
              this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
            }
            else {
              this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
            }
          }
        })
      }

      if (this.hapFormData.quoteFormData.childDetails.length > 0) {
        this.hapFormData.quoteFormData.childDetails.forEach((data, index) => {
          this.childDetails.push(this.utilityService.createChildDetails(index));
          this.childMinMaxDate.push({ minDate: new Date(), maxDate: new Date() });
          // Patch values of adults
          for (let childKeys in data) {
            this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);
            if (childKeys.includes('Relationship')) {
              // We need to set min andd max dateof child as per relationship
              this.setResetChildDOB(data[childKeys], index);
            }
          }
        })
      }
      // IPAA-176: Patch totalMemeber object for insured page Convert API
      if (this.quoteConvertData && (this.adultDetails.length > 0 || this.childDetails.length > 0)) {
        this.hapFormData.quoteFormData.totalMemberData = this.getMemberRequest();
        this.healthDataStorageService.setHealthDataStorage(this.hapFormData);
      }
    }
    else if (this.hapFormData.quickquoteDataSet) {
      this.getadultchilddataforquickquote();
    }
    else {
      // Initially there will be only 1 adult and 0 child
      this.adultDetails.push(this.utilityService.createAdultDetails(0));
      // Disable adult dob initially
      this.enbleDisableChildDob(healthMembers.Adult);
    }
  }

  get adultDetails(): FormArray {
    return this.healthAdvantageForm.get('adultDetails') as FormArray;
  }

  get childDetails(): FormArray {
    return this.healthAdvantageForm.get('childDetails') as FormArray;
  }

  get occupation(): FormArray {
    return this.healthAdvantageForm.get('occupation') as FormArray;
  }

  createOccupation(index?, memberLabel?): FormGroup {
    return new FormGroup({
      ['occupationFor' + `${memberLabel}` + `${index + 1}`]: new FormControl(''),
      ['occLabel']: new FormControl(memberLabel),
      ['memberIndex']: new FormControl((index + 1))
    })
  }

  createAddons(control, defaultValue): FormGroup {
    return new FormGroup({
      [`${control}`]: new FormControl(defaultValue)
    })
  }

  // on load service calls
  fetchHAPDetails() {
    // If below solution does not work try calling api individually
    this.subscription.push(this.requestDataFromMultipleSources().subscribe({
      next: (responseList: [HealthAdvantageResponsePayload, RelationshipResponsePayload]) => {
        this.multipleServiceError = false;
        this.handleMultipleResponse(responseList)

      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    })
    )
  }

  handleMultipleResponse(responseList: [HealthAdvantageResponsePayload, RelationshipResponsePayload]) {
    if (responseList[0].StatusCode != Constants.statusCode_success || responseList[1].StatusCode != Constants.statusCode_success) {
      this.multipleServiceError = true;
    }
    else {
      this.multipleServiceError = false;
      this.manageHAPMasterData(responseList[0]);
      this.manageHealthRelationShipsData(responseList[1]);
    }
  }

  requestDataFromMultipleSources(): Observable<[HealthAdvantageResponsePayload, RelationshipResponsePayload]> {
    let proposalData: RelationshipRequestPayload = { product: Constants.healthProposalProductCode };
    const agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.dealId = agentDetails.MappedProduct.Health.HealthAdvantedgeDealID;
    const UserID: string = String(agentDetails.AgentID);
    // let masterRequest: HealthAdvantageRequestPayload = { IsOrion: this.storageService.getAESDecryptedData('IsOrion') == 'true' ? true : false };
    return this.healthAdvantedgeService.getHealthAdvantagePlusMasterData().pipe(
      // concatMap operator to sequentially call getHealthAdvantagePlusMasterData
      concatMap(healthResponse => {
        // forkJoin to concurrently call the other two methods
        // The responses from these three requests are combined into an array and emitted as a single observable
        return forkJoin([
          // of(healthResponse) within the forkJoin to include the result of the first API call in the final array of responses. 
          of(healthResponse),
          this.healthMasterService.getHealthProposalRelationships(proposalData)
        ]);
      })
    );
  }

  // handles hap master data
  manageHAPMasterData(hapResponse: HealthAdvantageResponsePayload) {
    if (hapResponse.StatusCode == Constants.statusCode_success) {
      this.fetchAddOnDetails(hapResponse.Data.AddonDetails)
      // this.constructAddOnDetails(hapResponse.Data.AddonDetails);
      this.multipleServiceError = false;
      this.policyRangeData = hapResponse.Data.PolicyRange ? hapResponse.Data.PolicyRange.split(",") : this.hapDataModal.hapPolicyRange;
      this.hapMasterData = hapResponse;
      if (this.hapMasterData.Data.PolicyTenure.length > 0) {
        // This needs to be changed from BE .
        let tempTenure: string[] = this.hapMasterData.Data.PolicyTenure[0].split(',').map((data, index) => data + ' ' + (index == 0 ? 'Year' : 'Years'));
        if (tempTenure.length > 0) {
          this.tenurePeriod = tempTenure;
        }
      }
      if (!this.hapFormData.quoteDataSet) {
        this.healthAdvantageForm.controls['policyRange'].setValue(this.policyRangeData[0]);
      }
      this.changePolicyRange(this.hapFormData.quoteDataSet ? this.hapFormData.quoteFormData.policyRange : this.policyRangeData[0]);
      if (this.hapFormData.quoteDataSet) {
        this.healthAdvantageForm.controls['applicantAnnualSum'].setValue(this.hapFormData.quoteFormData.applicantAnnualSum);
        this.fetchDataAsPerPincode(null, this.hapFormData.quoteFormData.pincode)
      }

      this.isVaccinationDiscountEnabled = this.hapMasterData.Data.IsVaccinationDiscountEnabled;

      let occupationMaster = this.hapMasterData.Data.Occupation ? this.hapMasterData.Data.Occupation : '';
      for (let i in occupationMaster) {
        this.occupationMaster.push({ key: occupationMaster[i], value: i });
      }
      this.occupationMaster = this.utilityService.sortDetails(this.occupationMaster, "value");
      this.annualIncome = this.hapMasterData.Data.IncomeRange && this.hapMasterData.Data.IncomeRange.length > 0 ? this.hapMasterData.Data.IncomeRange : [];
    }
  }

  // handles member relationships 
  manageHealthRelationShipsData(response: RelationshipResponsePayload) {
    let adultRelations = [];
    let childRelations = [];
    if (response.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      if (response.Data.InsuredRelationship.length > 0) {
        if (this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'false') {
          adultRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Adult);
          childRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Child);
        }
        else if (this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'true') {
          adultRelations = response.Data.InsuredRelationship;
          childRelations = response.Data.InsuredRelationship;
        }

        this.adultRelationShips = adultRelations.length > 0 ? adultRelations.sort(function (a, b) {
          return a.RelationshipName.localeCompare(b.RelationshipName)
        }) : [];
        this.childRelationShips = childRelations.length > 0 ? childRelations.sort(function (a, b) {
          return a.RelationshipName.localeCompare(b.RelationshipName)
        }) : [];
        this.loadFormContent = true;
      }
      else {
        this.adultRelationShips = [];
        this.childRelationShips = [];
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    }
  }

  adultRelationShip(relationShipName: string, id: number) {
    let criticalAddon: ParentAddonDetails[] = this.refiledAddons.filter(data => data.category == this.hapDataModal.groupCategory.Critical)
    this.enbleDisableChildDob(healthMembers.Adult);
    if (!this.utilityService.isEmptyOrNull(this.adultDetails.value[id]['dobAdult' + (id + 1)])) {
      let age: number = this.utilityService.calculateAge(this.adultDetails.value[id]['dobAdult' + (id + 1)]);
      this.checkForMaternityAddon(age, id, healthMembers.Adult)
    }
    if (this.criticalAdultData.length > 0 && this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].value) {
      this.checkProposerDobCI(criticalAddon[0]);
    }
  }


  patchExistingRefiledAddons() {
    // Check for SI, CP, Nursing at home, Compassionate Visit
    // Get Non Member Based Addons
    this.hapFormData.quoteFormData.addonsData.forEach((addonsData, index) => {
      if (addonsData['AddonCoverDetails'].length > 0) {
        addonsData['AddonCoverDetails'].forEach((selectedAddons, selectedAddonIndex) => {

          this.refiledAddons.forEach(refiledData => {
            // Non member Based Addons
            // If refiled category is sum insured then it will include all non member baseed addons
            if (refiledData.category == this.hapDataModal.groupCategory.SumInsured) {
              // If addon selected found
              // And run below block only if once the non member based addons is selected. As it will be applicable for all members

              if (!(selectedAddons.CoverId == RefiledHapAddons.PersonalAccident || selectedAddons.CoverId == RefiledHapAddons.CriticalIllness || selectedAddons.CoverId == RefiledHapAddons.Maternity || selectedAddons.CoverId == RefiledHapAddons.VaccinationNewBorn || selectedAddons.CoverId == RefiledHapAddons.NewBorn)) {
                if (index == 0) {
                  // If in child Addons there are addons which is selected then mark it as selected.
                  // But before that check for Si conditions for below addons
                  refiledData.childAddons.forEach(childAddons => {
                    // For above cover id check si conditions existing
                    // this.checkSumInsuredBasedAddons(childAddons, this.hapFormData.quoteFormData.applicantAnnualSum);

                    // Then check if the addon was taken mark it as true
                    if (childAddons.coverId == selectedAddons.CoverId) {
                      if (childAddons.coverId != RefiledHapAddons.SumInsured) {
                        this.checkSumInsuredBasedAddons(childAddons, this.hapFormData.quoteFormData.applicantAnnualSum);
                      }
                      childAddons.checkboxValue.checkedAddon = true;
                      // childAddons.visiblityAsPerService = true;
                      // Set form group value
                      this.healthAdvantageForm.controls[selectedAddons.CoverText].setValue(true);
                    }
                  })
                }
              }
            }

            // If user has befit then it will be applicable for all memebers so we can just check for first member
            else if (refiledData.category == this.hapDataModal.groupCategory.Befit && index == 0) {
              if (selectedAddons.CoverText.includes('BeFit')) {
                refiledData.childAddons[0].checkboxValue.checkedAddon = true;
                refiledData.showBefitFields = true;
                this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Befit].setValue(true);
                // Patch it in set befit dropdown
                this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].setValue(selectedAddons.CoverText);
              }
            }

            // For maternity , New Born and vaccination it can be applicable to any adult/child
            else if (refiledData.category == this.hapDataModal.groupCategory.Maternity && (selectedAddons.CoverId == RefiledHapAddons.Maternity || selectedAddons.CoverId == RefiledHapAddons.NewBorn || selectedAddons.CoverId == RefiledHapAddons.VaccinationNewBorn)) {
              if (selectedAddons.CoverId == RefiledHapAddons.Maternity) {
                this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].setValue(this.hapFormData.quoteFormData.maternityForAdult);
                if (this.maternityAdultData.length == 1) {
                  this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].disable();
                }
                // this.maternityAdultData = this.hapFormData.quoteFormData.maternityAdultData;
                refiledData.showMaternityFields = true;
                // Setting data in child addons as well
                refiledData.childAddons.forEach(childData => {
                  childData.checkboxValue.checkedAddon = true;
                  // New Born and vaccination should set as true for disabled property
                  if (childData.coverId == RefiledHapAddons.NewBorn || childData.coverId == RefiledHapAddons.VaccinationNewBorn) {
                    childData.checkboxValue.disabledAddon = true;
                    childData.visiblityAsPerService = true;
                  }
                  // Maternity Adult data dropdown patching needed

                })
              }

              else {
                this.healthAdvantageForm.controls[selectedAddons.CoverText].disable();
              }

              this.healthAdvantageForm.controls[selectedAddons.CoverText].setValue(true);
            }

            // Check For PA and Critical Illness
            if (refiledData.category == this.hapDataModal.groupCategory.Personal || refiledData.category == this.hapDataModal.groupCategory.Critical) {

              // For Critical Illness
              if (refiledData.category == this.hapDataModal.groupCategory.Critical && selectedAddons.CoverId == RefiledHapAddons.CriticalIllness) {
                // if (selectedAddons.CoverId == RefiledHapAddons.CriticalIllness) {
                this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].setValue(this.hapFormData.quoteFormData.criticalIllnessForAdult);
                // just to satisfy our self member condition
                this.selfMemberExists = true;
                this.checkProposerDobCI(refiledData);
                this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].setValue(this.hapFormData.quoteFormData.proposerDobCi);
                if (this.criticalAdultData.length == 1) {
                  this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].disable();
                }
                this.healthAdvantageForm.controls[selectedAddons.CoverText].setValue(true);
                refiledData.showCriticalFields = true;
                refiledData.childAddons[0].checkboxValue.checkedAddon = true;
                // this.criticalAdultData = this.hapFormData.quoteFormData.criticalAdultData;
                // }
              }

              // For Personal Accident
              else if (refiledData.category == this.hapDataModal.groupCategory.Personal && selectedAddons.CoverId == RefiledHapAddons.PersonalAccident) {
                // if (selectedAddons.CoverId == RefiledHapAddons.PersonalAccident) {
                this.healthAdvantageForm.controls[selectedAddons.CoverText].setValue(true);
                refiledData.showOccupationFields = true;
                refiledData.childAddons[0].checkboxValue.checkedAddon = true;
              }
            }
          })
        })
      }
    })
  }

  hasError(controlName: string, errorCode: string): boolean {
    return !this.healthAdvantageForm.valid && this.healthAdvantageForm.hasError(errorCode, [controlName]);
  }

  // Fetch statename according to pincode
  fetchDataAsPerPincode(event, existingPincode) {
    this.reviseZone = [];
    if (existingPincode == null) {
      this.hapFormData.quoteDataSet = false;
    }

    this.formReset = false;
    this.stateFetched = false;
    this.invalidPinCodeMessage = '';
    this.cityData = [];
    if ((existingPincode != null ? existingPincode : event.target.value.length == 6) && !this.healthAdvantageForm.controls['pincode'].hasError('pattern')) {
      let data: RTOListRequestPayload = {
        "Pincode": existingPincode != null ? existingPincode.toString() : event.target.value,
        "CityID": ""
      }
      let getBefitApplicabilityPayload: GetBefitApplicabityPayload = {
        "pincode": existingPincode != null ? existingPincode.toString() : event.target.value
      }
      this.invalidPinCodeMessage = '';
      this.reviseZone = [];
      this.revisedZone = [];
      this.healthAdvantageForm.controls['cityState'].reset();
      forkJoin({
        orionPinCodeResponse: this.rtoListService.getStatesCityByPin(data).pipe(
          catchError(error => {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            this.invalidPinCodeMessage = '';
            this.stateFetched = false;
            return of(error);
          })
        ),
        befitApplicabilityResponse: this.healthMasterService.getBefitApplicability(getBefitApplicabilityPayload).pipe(
          catchError(error => of(error))
        )
      }).subscribe(({ orionPinCodeResponse, befitApplicabilityResponse }) => {
        if (orionPinCodeResponse.StatusCode == Constants.statusCode_success) {
          if (orionPinCodeResponse.Data.length > 0) {
            this.stateDetails.stateId = orionPinCodeResponse.Data[0].StateId ? orionPinCodeResponse.Data[0].StateId : '';
            this.stateDetails.stateName = orionPinCodeResponse.Data[0].StateName ? orionPinCodeResponse.Data[0].StateName : '';
            this.stateDetails.isBefitApplicable = orionPinCodeResponse.Data[0].IsBefitApplicable ? "true" : "false";
            this.stateDetails.pincode = existingPincode != null ? existingPincode : event.target.value;
            orionPinCodeResponse.Data.forEach(cityList => {
              this.cityData.push({ 'cityLabel': cityList['CityName'], 'stateLabel': this.stateDetails.stateName, 'value': cityList['CityID'] });
            });
          }
          if (this.cityData.length == 1) {
            this.stateDetails.cityName = this.cityData[0].cityLabel;
            this.stateDetails.cityId = this.cityData[0].value;
            this.getZoneData();
            this.healthAdvantageForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
          }

          // Need to check below in backward patching
          if (this.hapFormData.quoteDataSet) {
            this.zoneMappedWithPincode = this.hapFormData.quoteFormData.zone;
            this.healthAdvantageForm.controls['cityState'].setValue(this.hapFormData.quoteFormData.cityState);
            this.changeCityName(this.hapFormData.quoteFormData.cityId, false);
            this.setZoneData();
          }
          
          this.stateFetched = true;
        } else if (orionPinCodeResponse.StatusCode == Constants.statusCode_failure) {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.stateFetched = false;
          this.invalidPinCodeMessage = orionPinCodeResponse.StatusDesc;
        } else {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          // this.popupService.openGenericPopup(this.errorPopupData);
        }

        if (befitApplicabilityResponse.StatusCode == Constants.statusCode_success) {
          if (befitApplicabilityResponse.Data.isBefitApplicable) {
            this.refiledAddons.find(addon => addon.category === this.hapDataModal.groupCategory.Befit).hide = false;
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Befit].setValue(true);
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].setValidators(Validators.required);
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].updateValueAndValidity();
          } else {
            this.refiledAddons.find(addon => addon.category === this.hapDataModal.groupCategory.Befit).hide = true;
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Befit].setValue(false);
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].setValue('');
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].clearValidators();
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].updateValueAndValidity();
          }

          if (this.hapFormData.quoteDataSet && !this.patchingDone) {
            this.patchingDone = true;
          }
          
        } else {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.stateFetched = false;
        }

      });

    }
    this.utilityService.numberOnly(event);
    this.utilityService.restrictSpace(event);
  }

  getZoneData() {
    this.reviseZone = [];
    this.revisedZone = [];
    let params: ZoneRefillingRequestPayload = {
      "subproductcode": this.hapMasterData.Data.SubProductCode,
      "State": this.stateDetails.stateName,
      "City": this.stateDetails.cityName,
      "productcode": this.hapFormData.quoteFormData.subProductType.toString(),
      'Pincode': this.healthAdvantageForm.value.pincode
    }

    this.subscription.push(this.healthAdvantedgeService.getZoneFromCityRefillingData(params).subscribe({
      next: (response: ZoneRefillingResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          this.zoneMappedWithPincode = response.Data.zone;
          this.setZoneData();
        }
        else {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.zoneMappedWithPincode = '';
        }
      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
      }
    }))
  }

  fetchAddOnDetails(addOnDetails) {
    this.addonsFromService = addOnDetails;
    // This function to be called only when there are no addons present
    if (this.refiledAddons.length == 0) {
      this.constructAddOnDetails(this.addonsFromService);

      if (this.hapFormData.quoteDataSet) {
        this.setAddonDependency();
      }
      this.checkOnLoadAddonVisiblity();
      if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.value.applicantAnnualSum)) {
        this.checkStatusOfSumInsuredAddons();
      }
      this.hideMaternitySubAddons();
      if (this.hapFormData.quoteDataSet) {
        this.patchExistingRefiledAddons();
      }
    } else if (this.refiledAddons.length > 0) {
      let befitGroupPresentID = this.refiledAddons.findIndex(data => data.category == this.hapDataModal.groupCategory.Befit);
      // Now we need to check whether befit was returned or not from this pincode
      let befitDataFromService = this.addonsFromService.filter(data => data.CoverText.includes('BeFit'));
      // If refiled addons length is greater that means addons are already prsent
      // need to check for befit

      // Case1: If befit is already present in existing addons but api has not returned any befit
      if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Befit)) && befitDataFromService.length == 0 && befitGroupPresentID != -1) {
        // Splice from existing refiled addons
        this.refiledAddons.splice(befitGroupPresentID, 1);
        // Also reset the control name
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Befit].setValue(false);
        if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.SelectedBefitData))) {
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].setValue('');
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].clearValidators();
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].updateValueAndValidity();
        }
      }
      // Case 2: If befit was not present in existing api . But Noww api has returned befit
      if (befitDataFromService.length > 0 && befitGroupPresentID == -1) {

        //Filter Befit data
        let tempBefitData = befitDataFromService.filter(data => data.CoverText.includes('BeFit'));
        let befitGroup = tempBefitData.length > 0 ? tempBefitData[0].groups : 'fifthgroup';

        // Now we need to push befit data into our refiled addons group
        if (tempBefitData.length > 0) {

          let subBefitData = [];

          // For pushing befit sub data
          tempBefitData.forEach(data => {
            let tempBefitObj = {
              checkboxValue: { checkedAddon: data.IsAddonSelected, disabledAddon: data.disabled },
              coverCode: data.OrionCoverCode,
              coverId: data.CoverId,
              isForAdult: data.IsForAdult,
              isForChild: data.IsForChild,
              isForFemale: data.IsForFemaleAdult,
              label: data.CoverText,
              position: data.position,
              type: 'checkbox',
              visiblityAsPerService: data.IsVisible,
              group: data.groups
            }
            subBefitData.push(tempBefitObj)
          })
          // Filter based on position
          subBefitData.sort((a, b) => a.position - b.position);
          this.befitData = subBefitData;
          // Enable divider for n-1 data . So we can have divider for brfit data
          this.refiledAddons[this.refiledAddons.length - 2].showDivider = true;
          // Also add controls for befit
          if (this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Befit))) {
            //  Patch Befit data and disable it
            this.healthAdvantageForm.addControl(this.hapDataModal.groupCategory.Befit, this.fb.control(true));
            this.healthAdvantageForm.addControl(this.hapDataModal.groupCategory.SelectedBefitData, this.fb.control(this.befitData[0].label));
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Befit].disable();
          }
          else {
            // Patch Befit A by default and make it disabled if befit controls alreday present
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Befit].setValue(true);
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Befit].disable();
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].setValue(this.befitData[0].label);
          }

          // Construct refiled addons for befit
          // For assigning befit group category data
          let befitData = {
            checkboxValue: { checkedAddon: this.befitData[0].checkboxValue.checkedAddon, disabledAddon: this.befitData[0].checkboxValue.disabledAddon },
            coverCode: '',
            coverId: 0,
            isForAdult: true,
            isForChild: true,
            isForFemale: false,
            label: 'Befit',
            position: 1,
            type: 'checkbox',
            visiblityAsPerService: true,
            group: befitGroup,
          }

          // Constructing befitgroup ctaegory
          this.refiledAddons.push({
            'groupName': 'fifthgroup',
            'category': 'Befit',
            'hide': false,
            'showDivider': false,
            'showMaternityFields': false,
            'showOccupationFields': false,
            'showCriticalFields': false,
            'showCriticalDatePicker': false,
            'showBefitFields': true,
            childAddons: [befitData]
          })
        }
        else {
          this.befitData = [];
        }
      }
    }
  }

  setAddonDependency() {
    this.maternityAdultData = this.hapFormData.quoteFormData.maternityAdultData.length > 0 ? this.hapFormData.quoteFormData.maternityAdultData : [];
    this.criticalAdultData = this.hapFormData.quoteFormData.criticalAdultData.length > 0 ? this.hapFormData.quoteFormData.criticalAdultData : [];

    if (this.hapFormData.quoteFormData.occupationdData.length > 0) {
      this.hapFormData.quoteFormData.occupationdData.forEach((data, index) => {
        // Extract occupation keys
        const occKeys = Object.keys(data);
        let dynamicKey = 'occupationFor' + data[occKeys[1]] + data[occKeys[2]];
        this.occupation.push(this.createOccupation((data[occKeys[2]] - 1), data[occKeys[1]]));
        this.occupation.at(index).get(dynamicKey).setValue(data[dynamicKey]);
      }
      )
    }
  }

  checkOnLoadAddonVisiblity() {
    // Check for Personal Accident
    // Hide if there is no occupation present
    this.refiledAddons.forEach(data => {

      if (data.category == this.hapDataModal.groupCategory.Personal && this.occupation.value.length == 0) {
        data.showDivider = false;
        data.showOccupationFields = false;
        data.hide = true;
        data.childAddons[0].visiblityAsPerService = false;
        data.childAddons[0].checkboxValue.checkedAddon = false;
      }
      else if (data.category == this.hapDataModal.groupCategory.Critical && this.criticalAdultData.length == 0) {
        data.showDivider = false;
        data.showCriticalFields = false;
        data.hide = true;
        data.childAddons[0].visiblityAsPerService = false;
        data.childAddons[0].checkboxValue.checkedAddon = false;
      }
      else if (data.category == this.hapDataModal.groupCategory.Maternity && this.maternityAdultData.length == 0) {
        data.showDivider = false;
        data.showMaternityFields = false;
        data.hide = true;
        data.childAddons.forEach(childData => {
          childData.visiblityAsPerService = false;
        })
      }
    })
  }

  checkStatusOfSumInsuredAddons() {
    let sumInsuredGroup: ParentAddonDetails[] = this.refiledAddons.filter(data => data.category == this.hapDataModal.groupCategory.SumInsured);
    sumInsuredGroup[0].childAddons.forEach(data => {
      if (data.coverId == RefiledHapAddons.CompassionateVisit || data.coverId == RefiledHapAddons.NursingAtHome || data.coverId == RefiledHapAddons.ClaimProtector) {
        this.checkSumInsuredBasedAddons(data, this.healthAdvantageForm.value.applicantAnnualSum);
      }
    })
  }

  hideMaternitySubAddons() {
    // Due to renewal we need to explicitly hide the new born and vaccination addon on load
    this.refiledAddons.forEach(parentData => {
      if (parentData.category == this.hapDataModal.groupCategory.Maternity) {
        if (parentData.childAddons.length > 0) {
          parentData.childAddons.forEach(data => {
            if (data.coverId != RefiledHapAddons.Maternity) {
              data.visiblityAsPerService = false;
              data.disable = true;
            }
          })
        }
      }

    })
  }

  setZoneData() {
    this.reviseZone = [];
    this.revisedZone = [];
    if (!this.hapFormData.quoteDataSet) {
      this.healthAdvantageForm.controls['zoneUpgrade'].setValue(healthToggle.No);
      this.healthAdvantageForm.controls['zoneDowngrade'].setValue(healthToggle.No);
    } if (this.zoneMappedWithPincode == zoneRefillingDetails.Zone_1) {
      this.revisedZone.push(zoneRefillingDetails.Zone_2);
      this.revisedZone.push(zoneRefillingDetails.Zone_3);
    }
    else if (this.zoneMappedWithPincode == zoneRefillingDetails.Zone_2) {
      this.reviseZone.push(zoneRefillingDetails.Zone_1);
      this.revisedZone.push(zoneRefillingDetails.Zone_3);
    }
    else if (this.zoneMappedWithPincode == zoneRefillingDetails.Zone_3) {
      this.reviseZone.push(zoneRefillingDetails.Zone_1);
      this.reviseZone.push(zoneRefillingDetails.Zone_2);
    } else {
      this.reviseZone = [];
      this.revisedZone = [];
    }
  }

  changePolicyTenure(tenure) {
    // If policy Tenure is more than 1 year then Emi Values need to be reset
    if (tenure != this.hapDataModal.tenurePeriod[0]) {
      this.healthAdvantageForm.controls['EMI'].patchValue("No")
      this.healthAdvantageForm.controls['EMIInstallment'].setValue('');
      this.emiValueChanged();
    }
  }

  changeCityName(cityValue: string, showSpinner: boolean) {
    this.stateDetails.cityId = cityValue;
    this.stateDetails.cityName = this.cityData.length > 0 ? (this.cityData.find(data => data.value == cityValue).cityLabel) : '';
    if (showSpinner) {
      this.getZoneData();
    }
  }

  changePolicyRange(range: string) {
    this.healthAdvantageForm.controls['applicantAnnualSum'].reset();
    if (this.hapMasterData.Data) {
      let plans = this.hapMasterData.Data.PolicyPlan;
      let si = plans.filter(data => data.POLICY_PLAN === range);
      this.sumInsuredDetails = si;
    }
  }

  emiValueChanged() {
    this.healthAdvantageForm.controls['EMIInstallment'].setValue('');
    if (this.healthAdvantageForm.controls['EMI'].value == healthToggle.Yes) {
      this.healthAdvantageForm.controls['EMIInstallment'].setValidators(Validators.required);
      this.healthAdvantageForm.controls['EMIInstallment'].updateValueAndValidity();
    }
    else {
      this.healthAdvantageForm.controls['EMI'].setValue(healthToggle.No);
      this.healthAdvantageForm.controls['EMIInstallment'].clearValidators();
      this.healthAdvantageForm.controls['EMIInstallment'].updateValueAndValidity();
    }
  }

  changeInProductType() {
    // Check if portability selected reset emi values
    if (this.healthAdvantageForm.value.productType != 'New') {
      this.healthAdvantageForm.controls['EMI'].setValue(healthToggle.No);
      this.healthAdvantageForm.controls['EMIInstallment'].setValue('');
      this.healthAdvantageForm.controls['EMIInstallment'].clearValidators();
      this.healthAdvantageForm.controls['EMIInstallment'].updateValueAndValidity();
      this.emiValueChanged();
      this.portabilityDataPresent(true);
    }
    else {
      this.portabilityDataPresent(false);
    }
  }

  portabilityDataPresent(data: boolean) {
    this.customStepperService.portabilityPresent(data);
  }

  showPremiumDetails(data: boolean) {
    this.customFooterService.showPremiumDetails(data);
  }

  annualSumChanged(value) {
    // this.showEMIValue = value <= 2000000;
    if (!this.showEMIValue) {
      this.healthAdvantageForm.controls['EMI'].setValue(healthToggle.No);
      this.emiValueChanged()
    }
    let nonMemberAddonsGroup: ParentAddonDetails[] = this.refiledAddons.filter(data => data.category == this.hapDataModal.groupCategory.SumInsured);
    // If annual sum is SI 1CR, 2 CR and 3 CR then compassionate visit and nursing at home will be applicable
    if (nonMemberAddonsGroup.length > 0 && nonMemberAddonsGroup[0].childAddons.length > 0) {
      nonMemberAddonsGroup[0].childAddons.forEach(data => {
        if (data.coverId == RefiledHapAddons.CompassionateVisit || data.coverId == RefiledHapAddons.NursingAtHome || data.coverId == RefiledHapAddons.ClaimProtector) {
          this.checkSumInsuredBasedAddons(data, value);
        }
      })
    }
  }

  checkSumInsuredBasedAddons(addonObject: AddonStructure, sumInsuredValue: string) {
    if (!this.utilityService.isEmptyOrNull(sumInsuredValue)) {
      switch (addonObject.coverId) {
        case RefiledHapAddons.ClaimProtector: {
          if (parseInt(sumInsuredValue) >= this.hapDataModal.croreValues.fifteenLaks) {
            addonObject.disable = true;
            addonObject.checkboxValue.checkedAddon = true;
            addonObject.checkboxValue.disabledAddon = true;
            if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(addonObject.label))) {
              this.healthAdvantageForm.controls[addonObject.label].disable();
              this.healthAdvantageForm.controls[addonObject.label].setValue(true);
            }
          }
          else {
            addonObject.disable = false;
            addonObject.checkboxValue.checkedAddon = false;
            addonObject.checkboxValue.disabledAddon = false;
            if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(addonObject.label))) {
              this.healthAdvantageForm.controls[addonObject.label].enable();
              // data.visiblityAsPerService = false;
              this.healthAdvantageForm.controls[addonObject.label].setValue(false);
            }
          }
          break;
        }
        case RefiledHapAddons.CompassionateVisit:
        case RefiledHapAddons.NursingAtHome: {
          if ((parseInt(sumInsuredValue)) >= this.hapDataModal.croreValues.oneCrore) {
            addonObject.disable = true;
            addonObject.checkboxValue.checkedAddon = true;
            addonObject.checkboxValue.disabledAddon = true;
            if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(addonObject.label))) {
              this.healthAdvantageForm.controls[addonObject.label].disable();
              this.healthAdvantageForm.controls[addonObject.label].setValue(true);
            }
          }
          else {
            addonObject.disable = false;
            addonObject.checkboxValue.checkedAddon = false;
            addonObject.checkboxValue.disabledAddon = false;
            if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(addonObject.label))) {
              this.healthAdvantageForm.controls[addonObject.label].enable();
              this.healthAdvantageForm.controls[addonObject.label].setValue(false);
            }
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  enbleDisableChildDob(type: string) {
    // Enable disable child dob
    if (type == healthMembers.Child) {
      this.childDetails.controls.forEach((childGroup: FormGroup, index) => {
        const relationshipControl = childGroup.get('childRelationship' + (index + 1));
        const dobControl = childGroup.get('dobChild' + (index + 1));
        // Reset the dob of child if relation is changed
        // dobControl.setValue('');
        if (relationshipControl && dobControl) {
          const relationshipPresent = !this.utilityService.isEmptyOrNull(relationshipControl.value);
          // Disable child dob if child relation is not selected. 
          if (!relationshipPresent) {
            dobControl.disable();
          }
          // Enable when the relation is present
          else {
            dobControl.enable();
          }
        }
      })
    }
    // Enable disable adult dob 
    else if (type == healthMembers.Adult) {
      this.adultDetails.controls.forEach((adultGroup: FormGroup, index) => {
        const relationshipControl = adultGroup.get('adultRealtionship' + (index + 1));
        const dobControl = adultGroup.get('dobAdult' + (index + 1));
        // Reset the dob of adult if relation is changed
        // dobControl.setValue('');
        if (relationshipControl && dobControl) {
          const relationshipPresent = !this.utilityService.isEmptyOrNull(relationshipControl.value);
          // Disable child dob if adult relation is not selected. 
          if (!relationshipPresent) {
            dobControl.disable();
          }
          // Enable when the relation is present
          else {
            dobControl.enable();
          }
        }
      })
    }
  }

  // New member logic starts
  memberClicked(memberData) {
    let previousAdultValue = this.adultValue;
    let previousChildValue = this.childValue;
    let data = this.utilityService.memberTabClicked(memberData, this.adultDetails, this.childDetails, this.adultValue, this.childValue, true);
    this.adultValue = data.adultInc;
    this.childValue = data.childInc;
    if (this.adultValue == 0) {
      this.resetDOBZeroAdult();
      // this.addonsValidationAsPerAdults();
    }
    else if (this.childValue == 0) {
      this.childMinMaxDate = [];
    }
    this.decrymentMembersAddons(previousAdultValue, previousChildValue, this.adultValue, this.childValue, memberData.label)
  }

  memberTabClicked(event) {
    this.stopPropogation(event);
  }

  incrementMember(memberData, event) {
    this.stopPropogation(event);
    let prevsAdultValue = this.adultValue;
    let prevsChildValue = this.childValue;

    //  Bind the value returned from common method
    let data = this.utilityService.memberIncrement(memberData.label, this.adultDetails, this.childDetails, this.adultValue, this.childValue, 6, 3, true);
    this.adultValue = data.adultInc;
    this.childValue = data.childInc;

    this.enbleDisableChildDob(memberData.label);
    // Below condition will be applied only when adult is incremented from 0 to 1 and child Value should be equal to 1
    if (this.adultValue == 0 && this.childValue == 1) {
      this.setResetChildDOB(this.childDetails.value[0]['childRelationship1'], 0);
    } else if (prevsAdultValue == 0 && prevsChildValue == 1) {
      this.setResetChildDOB(this.childDetails.value[0]['childRelationship1'], 0);
    }

    // if child value is incremented then push the min max date for child
    if (prevsChildValue <= this.childValue && memberData.label == healthMembers.Child) {
      this.childMinMaxDate.push({ minDate: new Date(), maxDate: new Date() });
    }
    this.checkAddons();
  }

  decrymentMember(memberData, event) {
    this.stopPropogation(event);
    let previousAdultValue: number = 0;
    let previousChildValue: number = 0;
    // get the previous decrymented adult value
    if (memberData.label == healthMembers.Adult) {
      previousAdultValue = this.adultValue;
    }
    else if (memberData.label == healthMembers.Child) {
      previousChildValue = this.childValue;
    }

    let data = this.utilityService.memberDecryment(memberData.label, this.adultDetails, this.childDetails, this.adultValue, this.childValue, 6, 3, true);
    //  Bind the value returned from common method
    this.adultValue = data.adultInc;
    this.childValue = data.childInc;

    this.enbleDisableChildDob(memberData.label);

    if (this.adultValue == 0) {
      this.resetDOBZeroAdult();
      if (this.childValue == 1) {
        this.setResetChildDOB(this.childDetails.value[0]['childRelationship1'], 0);
      }
      // this.addonsValidationAsPerAdults();
    }

    // If adult decryment then if critical illness or maternity is selected then that value needs to be removed from dropdown
    if (memberData.label == healthMembers.Adult) {
      if (this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].value) {
        if (this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical] && this.criticalAddons.length > 0) {
          let criticalDeletedMemberIndex = this.criticalAdultData.findIndex(data => data.id == previousAdultValue);
          let deletedData = this.criticalAdultData.splice(criticalDeletedMemberIndex, 1);
          // If the deleted value contains the same value selected for critical illness then reset it
          if (deletedData[0].label == this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].value) {
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].setValue('');
          }
        }

        // Remove that member from maternity
        if (this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Maternity] && this.maternityAddons.length > 0) {
          let maternityDeletedMemberIndex = this.maternityAdultData.findIndex(data => data.id == previousAdultValue);
          let deletedData = this.maternityAdultData.splice(maternityDeletedMemberIndex, 1);

          //  If the deleted value contains the same value selected for critical illness then reset it
          if (deletedData[0].label == this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Maternity].value) {
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Maternity].setValue('');
          }
        }
      }

    }
    // If member type is child then delete that particular child min max date
    if (memberData.label == healthMembers.Child) {
      if (previousChildValue > this.childValue) {
        this.childMinMaxDate.splice(previousChildValue - 1, 1);
      }
    }
    this.decrymentMembersAddons(previousAdultValue, previousChildValue, this.adultValue, this.childValue, memberData.label);
  }

  decrymentMembersAddons(previousAdultValue: number, previousChildValue: number, presentAdultValue: number, presentChildValue: number, memberType: string) {

    let previousMemberValue = memberType == healthMembers.Adult ? previousAdultValue : previousChildValue;
    let presentMemberValue = memberType == healthMembers.Adult ? presentAdultValue : presentChildValue;
    // Over here we need to ensure that previous member value should always be greater than current member value that means it has decrymented
    if (previousMemberValue > presentMemberValue) {
      // Now find out the member that was removed is present in critical data or not
      // Check for critical data
      if (this.criticalAdultData.length > 0) {
        let previousCriticalDataMemberID = 0;
        let criticalAddonGroup = this.refiledAddons.filter(addonData => addonData.category == this.hapDataModal.groupCategory.Critical)[0];

        let criticalMemberData = memberType + ' ' + previousMemberValue;
        // If adult is made directly 0 then there are child that will be dependent on them. Simply mark it as empty
        if (memberType == healthMembers.Adult && presentAdultValue == 0) {
          this.criticalAdultData = [];
          criticalAddonGroup.hide = true;
          criticalAddonGroup.showDivider = false;
          criticalAddonGroup.showCriticalFields = false;
          criticalAddonGroup.childAddons[0].visiblityAsPerService = false;
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].setValue(false);
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].setValue([]);
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].clearValidators();
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].updateValueAndValidity();
        }
        else if (presentMemberValue == 0 && healthMembers.Child && presentAdultValue > 0) {
          // / Check if previous member was more and now it is made 0
          previousCriticalDataMemberID = -2;
          // So from maternity data all the members w.r.t adult needs to be removed
          let criticalData = [];
          criticalData = this.criticalAdultData.filter(data => !data.label.includes(memberType));
          this.criticalAdultData = [...criticalData];
        }

        else if (presentMemberValue > 0) {
          previousCriticalDataMemberID = this.criticalAdultData.findIndex(data => data.label == criticalMemberData);
        }

        if (previousCriticalDataMemberID != -1) {
          // previousCriticalDataMemberID = this.criticalAdultData.findIndex(data => data.label == criticalMemberData);
          // if the member that is decrymented is present then remove it from that particular index from member addon
          if (previousCriticalDataMemberID != -2) {
            this.criticalAdultData.splice(previousCriticalDataMemberID, 1);
          }
          // Now check whether the critical member data addon was selected or not.
          // If selected check for selected critical data that was choosed
          if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Critical)) && this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].value) {
            // Check if the value that is supposed to be deleted if it is the actual value of the critical adult
            //  Below condition only needs to be checked if there are more than 1 members present in critical adult data
            if (this.criticalAdultData.length > 1) {
              if (this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].value.includes(criticalMemberData)) {
                let selectedCriticalMembers = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].value;
                if (selectedCriticalMembers.length > 0) {
                  selectedCriticalMembers.forEach((data, index) => {
                    if (data == criticalMemberData) {
                      this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].value.splice(index, 1);
                    }
                  })
                }
              }

              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].setValidators(Validators.required);
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].updateValueAndValidity();

            }
            // But if only one is left then we need to set by default value 
            else if (this.criticalAdultData.length == 1) {
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].setValue([this.criticalAdultData[0].label]);
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].disable();
            }
          }
        }
      }

      // Personal Accident
      if (this.occupation.value.length > 0) {

        let personalAccidentAddonGroup = this.refiledAddons.filter(addonData => addonData.category == this.hapDataModal.groupCategory.Personal)[0];
        // If adult is removed completely then only one child is left hide the pa cover also
        if (memberType == healthMembers.Adult && presentAdultValue == 0) {
          this.occupation.clear();
          personalAccidentAddonGroup.hide = true;
          personalAccidentAddonGroup.showDivider = false;
          personalAccidentAddonGroup.showOccupationFields = false;
          personalAccidentAddonGroup.childAddons[0].visiblityAsPerService = false;
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Personal].setValue(false);
        }
        // If all child is removed but adult is present then remove child
        else if (presentMemberValue == 0 && memberType == healthMembers.Child && presentAdultValue > 0) {
          for (let i = this.occupation.length - 1; i >= 0; i--) {
            const group = this.occupation.at(i) as FormGroup;
            const childKeys = Object.keys(group.controls);
            if (childKeys.length > 0) {
              const keyFound = childKeys.some(key => key.includes(memberType));
              if (keyFound) {
                this.occupation.removeAt(i);
              }
            }
          }
        }

        // If user is ddecrymenting the value then find that control name and delete it irrespective of adult/child
        else if (presentMemberValue > 0) {
          let occupationMember = 'occupationFor' + memberType + previousMemberValue;
          for (let i = 0; i < this.occupation.length; i++) {
            const group = this.occupation.at(i) as FormGroup;
            const keys = Object.keys(group.controls);
            const keyFound = keys.some(key => key.includes(occupationMember));
            if (keyFound) {
              this.occupation.removeAt(i);
              break;
            }
          }
        }
      }


      // Check for maternity
      if (this.maternityAdultData.length > 0) {
        let previousMaternityID = 0;
        let maternityMemberData = memberType + ' ' + previousMemberValue;
        let maternityGroup = this.refiledAddons.filter(data => data.category == this.hapDataModal.groupCategory.Maternity);

        // Get the memeber of previous adult present
        // Here we need to check if either entire member is removed or not
        // Check if previous member was more and now it is made 0
        if (presentMemberValue == 0) {
          previousMaternityID = -2;
          // So from maternity data all the members w.r.t adult needs to be removed
          let maternityData = [];
          maternityData = this.maternityAdultData.filter(data => !data.label.includes(memberType));
          this.maternityAdultData = [...maternityData];
          // If adult is made 0 then we need to remove maternity data also provided only 1 child is there
          if (memberType == healthMembers.Adult && presentAdultValue == 0 && presentChildValue == 1) {
            this.maternityAdultData = [];
          }
        }
        else if (presentMemberValue > 0) {
          previousMaternityID = this.maternityAdultData.findIndex(data => data.label == (memberType + ' ' + previousMemberValue));
        }
        //  If that member is present then remove that member
        if (previousMaternityID != -1) {
          if (previousMaternityID != -2) {
            this.maternityAdultData.splice(previousMaternityID, 1);
          }
          // If maternity value is already selected and maternity data is already selected
          // And if that value is similar then reset that data
          if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Maternity)) && this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Maternity].value) {

            if (this.maternityAdultData.length == 1) {
              // If length is one and adult or child is also one thens imply patch the value
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].setValue([this.maternityAdultData[0].label]);
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].disable();
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].clearValidators();
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].updateValueAndValidity();
            }
            // Remove if user has already selected maternitty data which got deleted
            else if (this.maternityAdultData.length > 1) {
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].setValidators(Validators.required);
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].updateValueAndValidity();
              if ((this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].value).includes(maternityMemberData)) {
                let selectedMaternityMembers = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].value;
                if (selectedMaternityMembers.length > 0) {
                  selectedMaternityMembers.forEach((data, index) => {
                    if (data == maternityMemberData) {
                      this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].value.splice(index, 1);
                    }
                  })
                }
              }
            }
            // If no maternity data is present then unselect the maternity
            if (this.maternityAdultData.length == 0) {
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Maternity].setValue(false);
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].setValue([]);
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].clearValidators();
              this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].updateValueAndValidity();
              this.maternityAdultData = [];
              // Now find the maternity group and hide maternity div

              maternityGroup[0].showMaternityFields = false;
              maternityGroup[0].showDivider = false;
              maternityGroup[0].hide = false;
              // Hide new born and vaccination cover
              maternityGroup[0].childAddons.forEach(data => {
                data.visiblityAsPerService = false;
              })
            }

          }
        }
      }
    }
  }

  stopPropogation(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  checkAddons() {
    // If personal accident addons is selected or deselected we need to update occupation form array
    if (this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Personal]) {
      let personalAccidentAddons = this.refiledAddons.filter(data => data.category == this.hapDataModal.groupCategory.Personal)[0];
      this.changeInAddonCover(personalAccidentAddons, personalAccidentAddons.childAddons[0], false);
    }
  }


  resetDOBZeroAdult() {
    if (this.adultValue == 0) {
      // Whenever adult is 0 then child will be only 1 by default
      this.depIndChildRelationship(this.childDetails.value[0]['dobChild1'], 0);
    }
  }

  constructAddOnDetails(addOns: AddOnCover[] | ElevateAddonCover[]) {
    // Initial group will always be first
    let dividerGroup: string = 'firstGroup';
    let uniqueGroups: Array<{ label: string, hide: boolean, groupName: string }> = [];
    let totalAddons: AddonStructure[] = [];
    // IPAA-176: Saved quotes addon patching
    if (this.quoteConvertData && this.quoteConvertData.hapAddonsFormData) {
      let hapAddons = this.quoteConvertData.hapAddonsFormData.addons[0].AddonCoverDetails;
      hapAddons.forEach(hapAddOn => {
        addOns.forEach(addOn => {
          if (addOn.CoverText.includes('BeFit')) {
            addOn.IsAddonSelected = false;
          }
          if (addOn.CoverId == hapAddOn.CoverId) {
            addOn.IsAddonSelected = hapAddOn.IsAddonSelected;
          }
          // if(addOn.CoverText.includes('BeFit') && addOn.CoverId == hapAddOn.CoverId) {
          //   this.hapDataModal.groupCategory.SelectedBefitData = addOn.CoverText;
          // }
        });
      });
    }

    if (addOns.length > 0) {
      addOns.forEach((details) => {
        // Firstly we will cater for on load addons
        let checkBoxData: { disabledAddon: boolean, checkedAddon: boolean } = { disabledAddon: false, checkedAddon: false };
        // If addons are by default selected then it will be checked
        checkBoxData.checkedAddon = details.IsAddonSelected ? true : false;
        // If addons are by default checked then it will be disabled
        checkBoxData.disabledAddon = details.disabled ? true : false;

        // Below logic is used to create controls of the labels 
        // Here we need to push only one befit controls
        let tempCoverText = details.CoverText.toLowerCase();
        if (tempCoverText.includes((this.hapDataModal.groupCategory.Befit).toLowerCase()) && this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get('Befit'))) {
          this.healthAdvantageForm.addControl(this.hapDataModal.groupCategory.Befit, this.fb.control(checkBoxData.checkedAddon ? true : false));
          this.healthAdvantageForm.addControl(this.hapDataModal.groupCategory.SelectedBefitData, this.fb.control(''));
        }
        // Add all other addons controls other than befit
        else if (!tempCoverText.includes((this.hapDataModal.groupCategory.Befit).toLowerCase())) {
          this.healthAdvantageForm.addControl(details.CoverText, this.fb.control(checkBoxData.checkedAddon ? true : false));
        }

        // Unique group filteration
        let uniqueGroupFound: boolean = false;
        // if both the group does not matches that means it is unique group.
        // Initially it would be firstgroup
        if (dividerGroup != details.groups) {
          uniqueGroupFound = true;
          uniqueGroups.push({ 'label': details.groups, 'hide': details.IsVisible ? false : true, groupName: details.CoverText });
        }
        else if (dividerGroup == details.groups) {
          uniqueGroupFound = false;
        }

        totalAddons.push({
          'label': details.CoverText,
          'checkboxValue': checkBoxData,
          'type': 'checkbox',
          'coverCode': details.CoverCode,
          'coverId': details.CoverId,
          'isForAdult': details.IsForAdult,
          'isForChild': details.IsForChild,
          'isForFemale': details.IsForFemaleAdult,
          'visiblityAsPerService': details.IsVisible,
          'position': details.position,// This helps to determine the position of the addons for placing
          'group': details.groups
        })

        // Below condition is written. Such that if unique group is found then that should be the compairing value
        if (uniqueGroupFound) {
          dividerGroup = details.groups;
        }
      });
      // All addons construction ends here
      // Adding controls for maternity and critical illness for member dropdown
      this.healthAdvantageForm.addControl(this.hapDataModal.groupCategory.MaternityForAdultValue, this.fb.control([]));
      this.healthAdvantageForm.addControl(this.hapDataModal.groupCategory.criticalIllnessForAdult, this.fb.control([]));
      this.healthAdvantageForm.addControl(this.hapDataModal.groupCategory.proposerDobCi, this.fb.control([]));
      this.sortBasedOnGroup(totalAddons, uniqueGroups);
    }
  }

  sortBasedOnGroup(addonData: Array<AddonStructure>, groupsData: Array<{ label: string, hide: boolean, groupName: string }>) {
    // Now we need to sort based on groups we receive
    // First Group, Second Group, Third Group, Fourth Group
    // And then on position
    let groupOrder = this.hapDataModal.groupOrder;
    const sortedAddons = addonData.slice().sort((a, b) => {
      const groupA = a['group'];
      const groupB = b['group'];
      // Compare groups first
      const groupComparison = groupOrder[groupA] - groupOrder[groupB];
      if (groupComparison === 0) {
        // If groups are the same, compare positions
        return a['position'] - b['position'];
      }
      return groupComparison;
    });

    // Also sort unique groups data 
    //  Define the order in which groups should appear
    const customOrder = ['firstGroup', 'secondGroup', 'thirdGroup', 'fourthGroup', 'fifthGroup'];
    let sortedGroupsData = groupsData.slice().sort((a, b) => {
      return customOrder.indexOf(a['label']) - customOrder.indexOf(b['label']);
    });

    // Based on group we need to make updated modal to cater html
    let newAddons: ParentAddonDetails[] = [];
    sortedGroupsData.forEach((uniqueGroups, index) => {
      // Filtering addons based on group
      let specificGroupAddons = sortedAddons.filter(data => data['group'] == uniqueGroups['label']);
      if (specificGroupAddons.length > 0) {
        let nextIndex = index + 1;
        // If we want to show divider we need to check only when second group needs to be showcased or not 
        let showDividerFlag: boolean = false;
        if (nextIndex < sortedGroupsData.length) {
          showDividerFlag = !sortedGroupsData[nextIndex]['hide']
        }
        else {
          showDividerFlag = false;
        }

        let befitData: AddonStructure[] = [{
          checkboxValue: { checkedAddon: specificGroupAddons[0].checkboxValue.checkedAddon, disabledAddon: specificGroupAddons[0].checkboxValue.disabledAddon },
          coverCode: '',
          coverId: 0,
          isForAdult: true,
          isForChild: true,
          isForFemale: false,
          label: 'Befit',
          position: 1,
          type: 'checkbox',
          visiblityAsPerService: true,
          group: uniqueGroups['label'],
        }]

        newAddons.push({
          'groupName': uniqueGroups['label'],
          'category': uniqueGroups.groupName.includes('BeFit') ? 'Befit' : uniqueGroups.groupName,
          'hide': uniqueGroups.groupName.includes('BeFit') ? false : uniqueGroups['hide'],
          'showDivider': showDividerFlag,
          'showMaternityFields': false,
          'showOccupationFields': false,
          'showCriticalFields': false,
          'showCriticalDatePicker': false,
          'showBefitFields': true,
          childAddons: uniqueGroups.groupName.includes('BeFit') ? befitData : specificGroupAddons
        })
      }

      // Assign befit data to befit dropdown
      if (uniqueGroups.groupName.includes('BeFit')) {
        this.befitData = specificGroupAddons;

        // Patch Befit A by default and make it disabled
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Befit].setValue(true);
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Befit].disable();
        if (!this.quoteDetails) {
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].setValue(this.befitData[0].label);
        }
      }
      specificGroupAddons = [];
    })
    this.refiledAddons = newAddons;
  }

  getDynamicClasses(index: number): Object {
    return {
      'mt-1 mb-2': index == 0,
      'mb-2 mt-2': index == 2
    };
  }


  changeInAddonCover(addonDetails: ParentAddonDetails, childAddonDetails: AddonStructure, source: boolean, event?) {
    //  source --> true(comes from html)
    // source ---> false(comes from ts)
    // Maternity Cover
    if (childAddonDetails.coverId == RefiledHapAddons.Maternity) {

      // If no relation is yet selected then don't allow adult to select maternity cover
      if (this.maternityAdultData.length == 0) {
        addonDetails.showMaternityFields = false;
        addonDetails.hide = true;
        addonDetails.showDivider = false;
        childAddonDetails.visiblityAsPerService = false;
        this.healthAdvantageForm.controls[childAddonDetails.label].setValue(false);
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].setValue([]);
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].clearValidators();
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].updateValueAndValidity();

        // hide the cover vaccination and new born baby cover
        addonDetails.showMaternityFields = false;
        this.hideUnhideCovers(addonDetails, childAddonDetails);
        // }
        if ((!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Maternity)) && this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Maternity].value)) {
          let message = source ? 'Maternity cover is not applicable' : 'Please select appropriate relationship and age for opting Maternity Cover';
          this.utilityService.popUpDialog(message);
        }

      }

      // If relation is selected then only perform below operation
      // For showcasing or hiding other maternity related covers
      else if (this.maternityAdultData.length > 0) {
        // If relationship value is present then only allow members to select relationship from maternity dropdown or input
        addonDetails.showMaternityFields = true;
        addonDetails.hide = false;
        addonDetails.showDivider = true;
        childAddonDetails.visiblityAsPerService = true;
        this.hideUnhideCovers(addonDetails, childAddonDetails);
        if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Maternity)) && this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Maternity].value)
          if (this.maternityAdultData.length == 1) {
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].setValue([this.maternityAdultData[0].label]);
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].disable();
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].clearValidators();
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].updateValueAndValidity();
          }
          else if (this.maternityAdultData.length > 1) {
            // If maternity adult value is already present and the maternity adult data value also includes the value then don't reset
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].enable();
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].setValidators(Validators.required);
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].updateValueAndValidity();
          }
      }
    }

    // Critical Illness
    else if (childAddonDetails.coverId == RefiledHapAddons.CriticalIllness) {
      // Critical illness not selected scenario
      if (!this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].value) {
        // case 1 : If there are no critical members present then we need to show critical addon
        if (this.criticalAdultData.length == 0) {
          childAddonDetails.visiblityAsPerService = false;
          addonDetails.hide = true;
          addonDetails.showDivider = false;
        }
        // Case 2: If critical members are applicable we need to show critical addon
        else if (this.criticalAdultData.length > 0) {
          childAddonDetails.visiblityAsPerService = true;
          addonDetails.hide = false;
          addonDetails.showDivider = true;
        }
        addonDetails.showCriticalFields = false;
        addonDetails.showCriticalDatePicker = false
        this.healthAdvantageForm.controls[childAddonDetails.label].setValue(false);
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].setValue([]);
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].clearValidators();
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].updateValueAndValidity();
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].setValue('');
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].clearValidators();
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].updateValueAndValidity();
      }

      // Scenario 2: If critical illness addon is selected
      // If critical illness addon is not present then show message to select appropriate age
      else {
        if (this.criticalAdultData.length == 0) {
          addonDetails.showCriticalFields = false;
          addonDetails.showCriticalDatePicker = false;
          addonDetails.hide = true;
          addonDetails.showDivider = false;
          childAddonDetails.visiblityAsPerService = false;
          let message = '';
          this.healthAdvantageForm.controls[childAddonDetails.label].setValue(false);
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].setValue([]);
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].clearValidators();
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].updateValueAndValidity();
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].setValue('');
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].clearValidators();
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].updateValueAndValidity();
          if ((this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Critical) && this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].value)) {
            message = source ? 'Please select dob of adult between specified range 18 - 50 years.' : 'Please select appropriate dob for opting Critical Illness Cover';
            this.utilityService.popUpDialog(message);
          }
        }
        // If  critical addons data is present then show the dropdown/input field
        else if (this.criticalAdultData.length > 0) {
          addonDetails.hide = false;
          addonDetails.showDivider = true;
          addonDetails.showCriticalFields = true;
          childAddonDetails.visiblityAsPerService = true;

          // check for critical illness proposer dob
          this.checkProposerDobCI(addonDetails);
          if (this.criticalAdultData.length == 1) {
            let memberData = [];
            memberData.push(this.criticalAdultData[0].label)
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].setValue(memberData);
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].disable();
          }
          else if (this.criticalAdultData.length > 1) {
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].enable();
          }
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].setValidators(Validators.required);
          this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].updateValueAndValidity();
        }
      }
    }

    // Personal Accident
    else if (childAddonDetails.coverId == RefiledHapAddons.PersonalAccident) {
      // If no occupation is present then simply hide the personal accident cover
      if (this.occupation.length == 0 || (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(childAddonDetails.label)) && !this.healthAdvantageForm.controls[childAddonDetails.label].value && this.occupation.length > 0)) {
        addonDetails.hide = true;
        addonDetails.showOccupationFields = false;
        addonDetails.showDivider = false;
        childAddonDetails.visiblityAsPerService = false;
        childAddonDetails.checkboxValue.checkedAddon = false;
      }

      if (this.occupation.length > 0) {
        if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(childAddonDetails.label)) && this.occupation.length > 0) {
          addonDetails.hide = false;
          addonDetails.showOccupationFields = false;
          addonDetails.showDivider = true;
          childAddonDetails.visiblityAsPerService = true;
          childAddonDetails.checkboxValue.checkedAddon = false;
          if (this.healthAdvantageForm.controls[childAddonDetails.label].value) {
            addonDetails.showOccupationFields = true;
          }
          else if (!this.healthAdvantageForm.controls[childAddonDetails.label].value) {
            addonDetails.showOccupationFields = false;
            // Reset all occupation data
            this.occupation.value.forEach((data, index) => {
              const keys = Object.keys(data);
              this.occupation.at(index).get(keys[0]).setValue('');
            })
          }
        }
      }
    }

    // Befit Cover
    else if (childAddonDetails.coverId == RefiledHapAddons.Befit) {
      if (this.healthAdvantageForm.controls[childAddonDetails.label].value) {
        addonDetails.showBefitFields = true;
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].setValidators(Validators.required);
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].updateValueAndValidity();
      }
      else if (!this.healthAdvantageForm.controls[childAddonDetails.label].value) {
        addonDetails.showBefitFields = false;
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].setValue('');
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].clearValidators();
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].updateValueAndValidity();
      }
    }
  }

  checkProposerDobCI(addonDetails: ParentAddonDetails) {
    let selfIndex: number = -1;
    let previousMemberValue = this.selfMemberExists;
    if (this.adultDetails.value.length > 0) {
      selfIndex = this.adultDetails.value.findIndex((data, index) => data['adultRealtionship' + (index + 1)] == 'SELF');
    }
    previousMemberValue = this.selfMemberExists;
    this.selfMemberExists = selfIndex == -1 ? false : true;

    // Case 1: If self found then proposer dob will be hidden and we need to pass self 
    if (this.selfMemberExists) {
      addonDetails.showCriticalDatePicker = false;
      let selfMember = this.adultDetails.value[selfIndex]['dobAdult' + (selfIndex + 1)]
      this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].setValue(selfMember);
      this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].clearValidators();
      this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].updateValueAndValidity();
    }
    // Case 2: If self does not exists then proposer dob needs to be asked
    else if (!this.selfMemberExists) {
      addonDetails.showCriticalDatePicker = true;
      if (previousMemberValue) {
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].setValue('');
      }
      this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].setValidators(Validators.required);
      this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].updateValueAndValidity();
    }
  }

  hideUnhideCovers(addonDetails: ParentAddonDetails, childAddonDetails: AddonStructure) {
    addonDetails.childAddons.forEach(data => {
      switch (data.coverId) {
        // New Born and Vaccination Cover
        case RefiledHapAddons.NewBorn:
        case RefiledHapAddons.VaccinationNewBorn: {
          // Case 1: If maternity value is selected
          if (this.healthAdvantageForm.controls[childAddonDetails.label].value) {
            // New born and vaccination will be enabled
            data.visiblityAsPerService = true;
            // If we get disabled addon as true then only we will disable
            if (data.checkboxValue.disabledAddon) {
              this.healthAdvantageForm.controls[data.label].disable();
            }
          }
          // Case 2: If not selected
          else if (!this.healthAdvantageForm.controls[childAddonDetails.label].value) {
            if (this.maternityAdultData.length == 0) {
              childAddonDetails.visiblityAsPerService = false;
              addonDetails.showDivider = false;
              addonDetails.hide = true;
            }
            else if (this.maternityAdultData.length > 0) {
              childAddonDetails.visiblityAsPerService = true;
              addonDetails.showDivider = true;
              addonDetails.hide = false;
            }
            addonDetails.showMaternityFields = false;

            data.visiblityAsPerService = false;
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].setValue([]);
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].reset();
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].clearValidators();
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].updateValueAndValidity();

          }
          break;
        }
        default: {
          break;
        }
      }
    })
  }

  // Here if add delete addon covers based on group and applicabilityfor members
  addonsValidationAsPerAdults() {
    this.refiledAddons.forEach(data => {
      // Every group has specific addons which indicates it's applicability for adult/female/ all members
      data.childAddons.forEach(childAddons => {
        // Firstly we need to check that it should not be applicable to child addons
        if (!childAddons.isForChild) {
          if (childAddons.isForFemale || childAddons.isForAdult) {
            // Any addon appplicable to female or adult should be hidden
            if (this.adultDetails.length == 0) {
              childAddons.visiblityAsPerService = false;
              data.hide = true;
              data.showDivider = false;

            }
            else if (this.adultDetails.length > 0) {
              childAddons.visiblityAsPerService = true;
              data.hide = false;
              data.showDivider = true;
            }

            // data.showMaternityFields = false;
            data.showCriticalFields = false;
            data.showOccupationFields = false;
            // Specific covers controls reeds to be reset
            this.healthAdvantageForm.controls[data.category].reset();
            this.healthAdvantageForm.controls[data.category].setValue(false);
            this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].reset();
            this.criticalAdultData = [];
            this.occupation.clear();
          }
        }
      })
    })
  }


  adultsDateComparison(value: string, id: number, controlName: string) {
    let age: number = this.utilityService.calculateAge(this.adultDetails.value[id][controlName]);
    this.checkForMaternityAddon(age, id, healthMembers.Adult);
    this.checkForCriticalAddon(age, id, healthMembers.Adult);
    this.checkForPersonalAccidentAddon(age, id, healthMembers.Adult);
  }

  childDateComparison(value: string, id: number, controlName: string) {
    let age: number = this.utilityService.calculateAge(this.childDetails.value[id][controlName]);
    if (!this.utilityService.isEmptyOrNull(this.childDetails.value[id]['childRelationship' + (id + 1)])) {
      this.checkForMaternityAddon(age, id, healthMembers.Child);
      // Critical addon is applicable for child as well
      this.checkForCriticalAddon(age, id, healthMembers.Child);
    }
    this.checkForPersonalAccidentAddon(age, id, healthMembers.Child);
  }

  checkForMaternityAddon(age: number, memberIndex: number, type: string) {
    let relationshipControl = type == healthMembers.Adult ? 'adultRealtionship' : 'childRelationship';
    let dobControl = type == healthMembers.Adult ? 'dobAdult' : 'dobChild';

    let relationShipName = '';
    let genderData: InsuredRelationship[] = [];

    if (type == healthMembers.Adult) {
      relationShipName = this.adultDetails.value[memberIndex][relationshipControl + (memberIndex + 1)];
      genderData = this.adultRelationShips.filter(relationData => relationData.RelationshipName == relationShipName);
    }

    else {
      relationShipName = this.childDetails.value[memberIndex][relationshipControl + (memberIndex + 1)];
      genderData = this.childRelationShips.filter(relationData => relationData.RelationshipName == relationShipName);
    }
    let index = 0;

    if (genderData.length > 0) {
      let memberName = type + ' ' + (memberIndex + 1);
      if (genderData[0].Gender == (RelationShipGender.Male).toUpperCase() || (genderData[0].Gender == (RelationShipGender.Neutral).toUpperCase() && relationShipName == 'EMPLOYEE')) {
        if (this.maternityAdultData.length > 0) {

          index = this.maternityAdultData.findIndex(data => data.label == memberName);

          if (index != -1) {
            let memberPresentAtIndex = this.maternityAdultData[index].label;
            this.maternityAdultData.splice(index, 1);

            // if the addon value is selected and it same as deleted value then reset
            if ((!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Maternity))) && this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Maternity].value) {
              // Now maternity for member is a form control which can contain multiple value
              let selectedMaternityMembers = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].value;
              if (selectedMaternityMembers.length > 0) {
                selectedMaternityMembers.forEach((data, index) => {
                  if (data == memberPresentAtIndex) {
                    this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].value.splice(index, 1);
                  }
                })
              }
            }
          }
        }
      }
      // Gender should be male female and neutral and age 
      // fORs CHILD ONLY daughter and employee daughter should be checked as maternity is applicable for dependent kid
      else if (((type == healthMembers.Adult && (genderData[0].Gender == (RelationShipGender.Female).toUpperCase() || (genderData[0].Gender == (RelationShipGender.Neutral).toUpperCase())) && relationShipName != 'EMPLOYEE') || (type == healthMembers.Child && (relationShipName == 'DAUGHTER' || relationShipName == 'EMPLOYEE DAUGHTER')))) {
        // let memberName = type + (memberIndex + 1);
        index = this.maternityAdultData.findIndex(data => data.label == memberName);
        //  If it satisfies the age criteria
        if (age >= 18 && age <= 45) {
          // If the data is not presnt then only push the value
          if (index == -1) {
            this.maternityAdultData.push({ 'id': memberIndex, 'label': type + ' ' + (memberIndex + 1) });
          }
        }
        // even if it is female but the age is not satisfied
        else {
          if (index != -1) {
            let memberPresentAtIndex = this.maternityAdultData[index].label;
            // If it is already presnt then remove it
            this.maternityAdultData.splice(index, 1);
            // if the addon value is selected and it same as deleted value then reset
            if ((!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Maternity))) && !this.utilityService.isEmptyOrNull(this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Maternity].value)) {
              // Now maternity for member is a form control which can contain multiple value
              if ((!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.MaternityForAdultValue))) && !this.utilityService.isEmptyOrNull(this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].value)) {
                let selectedMaternityMembers = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].value;
                if (selectedMaternityMembers.length > 0) {
                  selectedMaternityMembers.forEach((data, index) => {
                    if (data == memberPresentAtIndex) {
                      this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].value.splice(index, 1);
                    }
                  })
                }
              }
            }
          }
        }
      }

    }
    // Now sort adult bases on increasing order in maternity data
    this.maternityAdultData = this.sortAdultChildAddonData(this.maternityAdultData);
    // Now here we need to check if the relationship of members are modified then maternity needs to be checked
    // if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Maternity)) && this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Maternity].value) {
    let maternityGroup: ParentAddonDetails[] = this.refiledAddons.filter(data => data.category == this.hapDataModal.groupCategory.Maternity);
    if (maternityGroup.length > 0) {
      let maternityAddon: AddonStructure = maternityGroup[0].childAddons.filter(childAddon => childAddon.label == this.hapDataModal.groupCategory.Maternity)[0];
      this.changeInAddonCover(maternityGroup[0], maternityAddon, false)
    }
    // }
  }

  checkForCriticalAddon(age: number, memberIndex: number, type: string) {
    let index = 0;
    let memberTypeCount = type + ' ' + (memberIndex + 1);

    // For child critical addon can be pushed only for dependent kid
    // Critical illness dropdown will be applicable for below age criteria
    if ((age >= 18 && age <= 50) && ((type == healthMembers.Child && this.hapDataModal.dependentChildRelationship.includes(this.childDetails.at(memberIndex).get('childRelationship' + (memberIndex + 1)).value)) || type == healthMembers.Adult)) {

      if (this.criticalAdultData.length > 0) {
        // We need to push critical data only when no such member is present
        index = this.criticalAdultData.findIndex(data => data.label == memberTypeCount);
        if (index == -1) {
          this.criticalAdultData.push({ 'id': memberIndex, 'label': type + ' ' + (memberIndex + 1) });
        }
      }
      // There is no critical adult data as of now then just push the value
      else {
        this.criticalAdultData.push({ 'id': memberIndex, 'label': type + ' ' + (memberIndex + 1) });
      }
    }

    // if age is less than 50 or age is less than equal to 18 then remove that member
    else if (age > 50 || age <= 18) {
      if (this.criticalAdultData.length > 0) {
        index = this.criticalAdultData.findIndex(data => data.label == memberTypeCount);
        if (index != -1) {
          let memberPresentAtIndex = this.criticalAdultData[index].label;
          this.criticalAdultData.splice(index, 1);
          // if the addon value is selected and it same as deleted value then reset
          if (!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Critical)) && this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].value) {
            // Now critical illnes for member is a form control which can contain multiple value
            let selectedCriticalMembers = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].value;
            if (selectedCriticalMembers.length > 0) {
              selectedCriticalMembers.forEach((data, index) => {
                if (data == memberPresentAtIndex) {
                  this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].value.splice(index, 1);
                }
              })
            }
          }
        }
      }
      // This means no critical data was present 
      else {
        this.criticalAdultData = [];
        this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].setValue(false);
      }
    }
    // Sort in order if there are multiple critical data
    if (this.criticalAdultData.length > 0) {
      this.criticalAdultData = this.sortAdultChildAddonData(this.criticalAdultData);
    }
    // If critical illness value is set to true then call change in addon cover method
    let criicalGroup: ParentAddonDetails[] = this.refiledAddons.filter(data => data.category == this.hapDataModal.groupCategory.Critical);
    if (criicalGroup.length > 0) {
      let criticalAddon: AddonStructure = criicalGroup[0].childAddons.filter(childAddon => childAddon.label == this.hapDataModal.groupCategory.Critical)[0];
      this.changeInAddonCover(criicalGroup[0], criticalAddon, false);
    }
  }

  checkForPersonalAccidentAddon(age: number, id: number, type: string) {
    // Pa cover to be pushed for member whose age is between 18 to 65
    let memberIndex: number = id;
    if (age >= 18 && age <= 65) {

      if (type == healthMembers.Adult || (type == healthMembers.Child && this.hapDataModal.dependentChildRelationship.includes(this.childDetails.at(memberIndex).get('childRelationship' + (memberIndex + 1)).value))) {
        if (this.occupation.length == 0) {
          this.occupation.push(this.createOccupation(memberIndex, type));
        }
        // If that occupation exists for that member then remove it or push the values
        else if (this.occupation.length > 0) {
          let memberFound: boolean = false;
          let occupationMember = 'occupationFor' + type + (memberIndex + 1);
          for (let i = 0; i < this.occupation.length; i++) {
            const group = this.occupation.at(i) as FormGroup;
            const formKeys = Object.keys(group.controls);
            const keyFound = formKeys.some(key => key.includes(occupationMember));
            if (keyFound) {
              memberFound = true;
              break;
            }
          }
          // If member not found push it in occ array
          if (!memberFound) {
            this.occupation.push(this.createOccupation(memberIndex, type));
          }

        }
      }
    }
    // If age is more simply remove the member
    else if (age < 18 || age > 65) {
      let occupationMember = 'occupationFor' + type + (memberIndex + 1);
      for (let i = 0; i < this.occupation.length; i++) {
        const group = this.occupation.at(i) as FormGroup;
        const formKeys = Object.keys(group.controls);
        const keyFound = formKeys.some(key => key.includes(occupationMember));
        if (keyFound) {
          this.occupation.removeAt(i);
          break;
        }
      }
    }
    let personalAccidentGroup: ParentAddonDetails[] = this.refiledAddons.filter(data => data.category == this.hapDataModal.groupCategory.Personal);
    if (personalAccidentGroup.length > 0) {
      this.changeInAddonCover(personalAccidentGroup[0], personalAccidentGroup[0].childAddons[0], false);
    }
  }


  sortAdultChildAddonData(memberData: Array<{ id: number, label: string }>): Array<{ id: number, label: string }> {
    // Now sort adult bases on increasing order 
    if (memberData.length > 0) {
      let adultData = memberData.filter(data => data.label.includes(healthMembers.Adult))

      adultData = adultData.sort((a, b) => parseInt(a['label'].split(' ')[1]) - parseInt(b['label'].split(' ')[1])).map((data, index) => {
        return { ...data, id: index };
      });
      // And child id will start after from adult id
      let childData = memberData.filter(data => data.label.includes(healthMembers.Child))

      childData = childData.sort((a, b) => parseInt(a['label'].split(' ')[1]) - parseInt(b['label'].split(' ')[1])).map((data, index) => {
        return { ...data, id: (index + adultData.length) };
      });

      memberData = [];
      memberData = adultData.concat(childData);
    }
    return memberData;

  }

  selectOccupationOption(data, index) {
    // There are certain occupations which should not be allowed to get selected
    let risk = this.occupationMaster.find(d => d.key === data).key;
    if (risk == '3' || risk == '4') {
      this.utilityService.popUpDialog('The selected occupation is not eligible for personal accident cover.');
      let controlName = this.occupation.value[index]['occLabel'] + this.occupation.value[index]['memberIndex'];
      this.occupation.at(index).get(['occupationFor' + controlName]).setValue('');
    }
  }

  getMinMaxDate(type: string, minMaxType: string) {
    let noOfAdult = this.adultDetails.length;
    let minYear = type == healthMembers.Child ? 26 : 66;
    let maxYear = type != healthMembers.Child ? 18 : type == healthMembers.Child && noOfAdult == 0 ? 6 : 0;
    let maxMonth = type == healthMembers.Child && noOfAdult != 0 ? 3 : '';
    let minMonth = type == healthMembers.Child && noOfAdult != 0 ? 9 : '';
    let insuredMaxYear = (type != healthMembers.Child) || (type == healthMembers.Child && noOfAdult == 0) ? new Date().getFullYear() - maxYear : new Date().getFullYear();
    let insuredMinYear = new Date().getFullYear() - minYear;
    this.insuredMinMaxDate = this.utilityService.getMinMaxDate(insuredMaxYear, insuredMinYear, maxMonth, minMonth);
    return minMaxType == 'min' ? this.insuredMinMaxDate.minDate : this.insuredMinMaxDate.maxDate;
  }

  getInsuredMinMaxDate(memberType: string, minMaxType: string) {
    if (memberType == 'Adult') {
      let adultMaxDate = this.utilityService.subtractYears(new Date(), 18);

      let yearsdate = this.utilityService.subtractYears(new Date(), 65);
      let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
      let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

      return minMaxType == 'min' ? adultMinDate : adultMaxDate;
    }
  }


  depIndChildRelationship(relationShipName: string, index: number) {
    // Reset specific child dob in every relation change as min and max child dob criteria changes
    this.childDetails.at(index).get('dobChild' + (index + 1)).setValue('');
    //  As the relation changes we are resetting the dob pass age as 0
    this.checkForMaternityAddon(0, index, healthMembers.Child);
    this.checkForPersonalAccidentAddon(0, index, healthMembers.Child);
    if (!this.utilityService.isEmptyOrNull(relationShipName)) {
      this.setResetChildDOB(relationShipName, index);
    }
    this.enbleDisableChildDob(healthMembers.Child);

  }

  setResetChildDOB(relationShipName: string, index: number) {
    let minDate, maxDate;
    // Max Date
    if (this.adultDetails.length > 0) {
      // Child max date will be 3 months
      maxDate = this.utilityService.subtractMonths(new Date(), 3);
    }
    // If no adult is present then child max date will be 6 years
    else if (this.adultDetails.length == 0) {
      maxDate = this.utilityService.subtractYears(new Date(), 6);
    }
    // Min Date
    // Dependent Kid
    if (relationShipName == 'SON' || relationShipName == 'DAUGHTER' || relationShipName == 'EMPLOYEE SON' || relationShipName == 'EMPLOYEE DAUGHTER') {
      let yearDate = this.utilityService.subtractYears(new Date(), 25);
      let monthDate = this.utilityService.subtractMonths(yearDate, 11);
      minDate = this.utilityService.subtractDays(monthDate, 25);
    }
    else {
      minDate = this.utilityService.subtractYears(new Date(), 18);
    }
    this.childMinMaxDate[index].minDate = minDate;
    this.childMinMaxDate[index].maxDate = maxDate;
  }

  getMinMaxDateVaccine(minMaxType: string) {
    let minYear = 1;
    let maxYear = 0;
    let maxMonth = '';
    let minMonth = '';
    let vaccineMaxYear = new Date().getFullYear() - maxYear
    let vaccineMinYear = new Date().getFullYear() - minYear;
    this.vaccineMinMaxDate = this.utilityService.getMinMaxDate(vaccineMaxYear, vaccineMinYear, maxMonth, minMonth);
    return minMaxType == 'min' ? this.vaccineMinMaxDate.minDate : this.vaccineMinMaxDate.maxDate;
  }

  vaccinationDateValidation(index: number) {
    let adultControls = this.adultDetails.controls[index] as FormGroup;
    if (this.adultDetails.value[index]['adultVaccinationStatus' + (index + 1)] == 'Yes') {
      adultControls.controls['adultVaccinationDate' + (index + 1)].setValidators([Validators.required]);
      adultControls.controls['adultVaccinationDate' + (index + 1)].updateValueAndValidity();
    }
    else {
      adultControls.controls['adultVaccinationDate' + (index + 1)].setValue('');
      adultControls.controls['adultVaccinationDate' + (index + 1)].clearValidators();
      adultControls.controls['adultVaccinationDate' + (index + 1)].updateValueAndValidity();
    }
  }



  getQuote(event: EmitButtonData) {
    this.formReset = false;
    this.success = false;
    this.formSubmitted = true;
    this.healthAdvantageForm.markAllAsTouched();
    let memberBasedValidation = this.checkMemberBasedAddonsValidation();

    //self case validation for more than 1 adult/child
    if (this.adultDetails.length > 0 || this.childDetails.length > 0) {
      let adultRelationshipArrCount = 0;
      let childRelationshipArrCount = 0;
      if (this.adultDetails.value.length > 0) {
        this.adultDetails.value.map((ele, index) => {
          if (ele[`adultRealtionship${index + 1}`] == 'SELF') {

            adultRelationshipArrCount++;
          }
        })
      }

      if (this.childDetails.value.length > 0) {
        this.childDetails.value.map((ele, index) => {
          if (ele[`childRelationship${index + 1}`] == 'SELF') {
            childRelationshipArrCount++;
          }
        })
      }

      if ((adultRelationshipArrCount > 1 || childRelationshipArrCount > 1) || (adultRelationshipArrCount > 0 && childRelationshipArrCount > 0)) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('SELF can be selected only for one Insured');
        this.popupService.openGenericPopup(this.errorPopupData);
        return;
      }
    }

    // If child details length is only 1 . we need to check dob present in child is valid or not
    if (this.childDetails.length == 1 && this.adultDetails.length == 0) {
      // Read Dob value if it's invalid then reset
      if (this.childDetails.at(0).get('dobChild1').status == 'INVALID') {
        this.childDetails.at(0).get('dobChild1').reset();
        this.childDetails.at(0).get('dobChild1').setValue('');
        this.childDetails.at(0).get('dobChild1').setValidators(Validators.required);
        this.childDetails.at(0).get('dobChild1').updateValueAndValidity();
      }
    }
    if (!this.healthAdvantageForm.valid || !this.adultDOBValid || this.invalidPinCodeMessage !== '') {
      for (const key of Object.keys(this.healthAdvantageForm.controls)) {
        if (this.healthAdvantageForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
          invalidControl.focus();
          break;
        }
      }
      this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
    }

    else if (this.invalidPinCodeMessage == '' && !memberBasedValidation) {
      this.formSubmitted = false;
      if (!this.onCibilSave && this.cibilToggle) {
        this.popupService.closeAllDialog(false);
        let popupData: PopupModal = {
          popupType: popupType.generic,
          imgName: popupImgPath.alertGif,
          header: popupHeader.cibilPopup,
          description: popupDescriptionMsg.cibilDiscount,
          buttonLabel: popupButton.ok
        }
        this.popupService.openGenericPopup(popupData);
      }
      else {
        let request: SaveEditQuoteHapV1Request = this.constructRefiledRequest();
        this.subscription.push(this.healthAdvantedgeService.saveEditQuoteData(request).subscribe({
          next: response => {

            if (response.StatusCode == Constants.statusCode_success) {
              this.storageService.setAESEncryptedData(userEnums.QuotePDFData, request);
              this.cibilScore = response.Data.CibilScore;
              this.emiPremium = response.Data.InstallmentTotalPremium;
              this.netPremium = response.Data.InstallmentNetPremium;
              this.taxAmount = response.Data.InstallmentTaxAmount;
              this.quoteID = response.Data.IqmsNo;
              this.customStepperService.resetStepper(true);
              this.statuscode = true
              if (response.Data.Policystatus == "CONDITIONALAPPROVE" && this.healthAdvantageForm.controls['EMI'].value == 'Yes') {
                this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
                this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.emiNotApplicable);
                this.popupService.openGenericPopup(this.errorPopupData);
                this.healthAdvantageForm.controls['EMI'].patchValue("No")
                this.healthAdvantageForm.controls['EMIInstallment'].setValue('');
                this.emiValueChanged();
              }
              else {
                this.setHapFormData(response?.Data);
                this.disableButton = true;
                this.success = true;
                this.patchingDone = true;
                // If premium exists then only allow user to proceed forward
                if (!this.utilityService.isEmptyOrNull(this.hapFormData.premiumFormData.totalPremium) && this.hapFormData.premiumFormData.totalPremium != '0') {
                  this.customStepperService.emitStepData({ status: true, buttonId: 'f1' });
                }
                else {
                  this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
                }
                this.showPremiumDetails(true);
              }
            }
            else if (response.StatusCode != Constants.statusCode_success) {
              this.success = false;
              this.disableButton = false;
              this.statuscode = false;
              this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            }
          },
          error: (err) => {
            this.success = false;
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          }
        }))
      }
    }
  }

  checkMemberBasedAddonsValidation() {
    let memberBasedValidation: boolean = false;
    let occMember = [];
    if ((!this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Personal))) && this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Personal].value) {
      //  If personal accident value is checked. Then check for valdation
      if (this.occupation.controls.length > 0) {
        for (let i = 0; i < this.occupation.length; i++) {
          const group = this.occupation.at(i) as FormGroup;
          let dynamicKey = 'occupationFor' + group.value['occLabel'] + group.value['memberIndex'];

          if (!this.utilityService.isEmptyOrNull(group.value[dynamicKey])) {
            occMember.push(1);
          }
        }
      }

      // Check whether occupation is selected of that fields
      if (occMember.length == 0) {
        this.utilityService.popUpDialog('Please select occupation for atleast one member');
        memberBasedValidation = true;
      }
      return memberBasedValidation;
    }
    return memberBasedValidation;
  }

  constructRefiledRequest(): SaveEditQuoteHapV1Request {
    let tenure = (this.healthAdvantageForm.value.policyTenure).split(' ')[0];
    this.tenureDuration = parseInt(tenure);
    let proposerDOB = '';

    if (this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].value) {
      let dob = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].value;
      if (!this.utilityService.isEmptyOrNull(dob)) {
        let dob = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].value;
        // proposerDOB = this.datePipe.transform(dob, 'yyyy-MM-dd') + ' T' +this.datePipe.transform(dob, 'HH:mm:ss');
        proposerDOB = this.utilityService.formatDate(dob);
      }
    }
    else {
      proposerDOB = undefined;
    }

    let portabilityData = this.hapMasterData.Data.PortabilitySubProductCode;

    let requestData: any = {
      "ProductType": this.healthAdvantageForm.value.productType == 'New' ? 'N' : 'R',
      "BancsPartyCode": this.storageService.getAESDecryptedData(userEnums.OrionAgentDetails).BancsPartyCode,
      "UserType": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "NoOfAdults": (this.adultDetails.length).toString(),
      "NoOfKids": (this.childDetails.length).toString(),
      "SubProductType": this.hapDataModal.subProductType.orionHap,
      "Tenure": this.tenureDuration,
      "GSTStateCode": this.stateDetails.stateId,
      "CityCode": this.stateDetails.cityId,
      "SumInsured": this.healthAdvantageForm.value.applicantAnnualSum,
      "GSTStateName": this.stateDetails.stateName ? this.stateDetails.stateName : '',
      "ProposerDOB": proposerDOB,
      "SaveQuote": false,//as discussed we don't want to save it . So false
      "Members": this.getMemberRequestAddons(),
      "SubProductCode": this.healthAdvantageForm.value.productType != 'New' ? portabilityData : this.hapMasterData.Data.SubProductCode,
      "productCode": this.hapMasterData.Data.productCode,
      "IpAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      "City": this.stateDetails.cityName,
      "Pincode": (this.healthAdvantageForm.value.pincode).toString(),
      "PreHospitalization": this.healthAdvantageForm.controls['preHospitalDuration'].value,
      "PostHospitalization": this.healthAdvantageForm.controls['postHospitalDuration'].value,
      "ProposalAnnualIncome": this.healthAdvantageForm.controls['applicantAnnualIncome'].value,
      "PEDWaitingPeriod": this.healthAdvantageForm.controls['pedWaitingPeriod'].value,
      "SpecificConditionWaiting": this.healthAdvantageForm.controls['conditionWaitingPeriod'].value,
      "ZoneUpgrade": this.healthAdvantageForm.controls['zoneUpgrade'].value == "Yes" ? true : false,//Initially it goes false
      "Zone": this.zoneMappedWithPincode,
      "RevisedZone": this.healthAdvantageForm.controls['zoneUpgrade'].value == "Yes" ? this.healthAdvantageForm.controls['reviseZone'].value : this.zoneMappedWithPincode,
      "VoluntaryCopaymentPercentage": this.healthAdvantageForm.value.copaymentPercentTaken == 'No' ? null : this.healthAdvantageForm.value.copaymentPercentTaken.replace('%', ''),
      "VolDeductible": this.healthAdvantageForm.value.voluntaryDeductibleRefiling,
      "InstallmentApplicable": this.healthAdvantageForm.controls['EMI'].value,
      "InstallmentFrequency": this.healthAdvantageForm.controls['EMI'].value == 'Yes' ? this.healthAdvantageForm.controls['EMIInstallment'].value : "",
      "CibilScore": this.cibilToggle ? this.utilityService.isUndefinedORNull(this.cibilScore) ? this.hapFormData.quoteFormData.creditScore : this.cibilScore : 0,
      "IsCibilTaken": this.cibilGroup.value.cibilDiscount == "Yes" ? true : false,
    }
    return requestData;
  }


  // Quote request formation 
  checkIsGSTRegistered() {
    return this.stateDetails.stateId != this.hapDataModal.stateId ? false : true;
  }

  getMemberRequest() {
    let members = [];
    if (this.adultDetails.length > 0) {
      this.adultDetails.value.forEach((element, i) => {
        this.adultDetails.value[i]['Gender' + `${i + 1}`] = this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips);
        members.push({ DOB: this.utilityService.formatDate(element['dobAdult' + (i + 1)]), MemberType: healthMembers.Adult, RelationshipwithApplicant: element['adultRealtionship' + (i + 1)], VaccinationDate: this.utilityService.formatDate(element['adultVaccinationDate' + (i + 1)]), Gender: this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips) })
      });
    }
    if (this.childDetails.length > 0) {
      this.childDetails.value.forEach((element, j) => {
        this.childDetails.value[j]['Gender' + `${j + 1}`] = this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips);
        members.push({ DOB: this.utilityService.formatDate(element['dobChild' + (j + 1)]), MemberType: healthMembers.Child, RelationshipwithApplicant: element['childRelationship' + (j + 1)], Gender: this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips) })
      });
    }
    return members;
  }

  getMemberRequestAddons() {
    let members = [];
    if (this.adultDetails.length > 0) {
      this.adultDetails.value.forEach((element, i) => {
        this.adultDetails.value[i]['Gender' + `${i + 1}`] = this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips);
        let addonsData = this.constructMemberBasedAddons(i, healthMembers.Adult);
        let memberObj = {
          DOB: this.utilityService.formatDate(element['dobAdult' + (i + 1)]),
          MemberType: healthMembers.Adult,
          RelationshipwithApplicant: element['adultRealtionship' + (i + 1)],
          VaccinationDate: this.utilityService.formatDate(element['adultVaccinationDate' + (i + 1)]),
          AddonCoverDetails: addonsData.memberAddon.length > 0 ? addonsData.memberAddon : [],
          // Gender: this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips),
          Occupation: !this.utilityService.isEmptyOrNull(addonsData.occupationData) ? addonsData.occupationData : ''
        }
        members.push(memberObj);
      });
    }
    if (this.childDetails.length > 0) {

      this.childDetails.value.forEach((element, j) => {
        this.childDetails.value[j]['Gender' + `${j + 1}`] = this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips);
        let addonsData = this.constructMemberBasedAddons(j, healthMembers.Child);
        let memberObj = {
          DOB: this.utilityService.formatDate(element['dobChild' + (j + 1)]),
          MemberType: healthMembers.Child,
          RelationshipwithApplicant: element['childRelationship' + (j + 1)],
          AddonCoverDetails: addonsData.memberAddon.length > 0 ? addonsData.memberAddon : [],
          // Gender: this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips)
        }
        members.push(memberObj);
      })
    }
    return members;
  }


  constructMemberBasedAddons(memberIndex: number, memberType: string) {
    // All member addons
    let memberAddon = [];
    let occupationData: string = '';
    // If Maternity is selected check for which Adult it is
    if (this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Maternity].value) {
      let maternityAdultValue = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].value;
      if (maternityAdultValue.length > 0) {
        let memberData = memberType + ' ' + (memberIndex + 1);
        if (maternityAdultValue.includes(memberData)) {
          this.addonsFromService.forEach(data => {
            if (data.CoverId == RefiledHapAddons.Maternity || data.CoverId == RefiledHapAddons.NewBorn || data.CoverId == RefiledHapAddons.VaccinationNewBorn) {
              let maternityData = { ...data };
              maternityData = { ...maternityData, IsAddonSelected: true };
              memberAddon.push(maternityData);
            }
          })
        }
      }

    }
    // Member based addons applicable only for Adult:- PA, Criical 
    // Check For Maternity Benefit Cover
    // Critical Illness Cover
    if (this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].value) {
      let criticalAdultValue = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].value;
      if (criticalAdultValue.length > 0) {
        let memberData = memberType + ' ' + (memberIndex + 1);
        if (criticalAdultValue.includes(memberData)) {
          let orignalOrionAddOnCoverId = this.fetchOrignalOrionAddOnCoverId(this.hapDataModal.groupCategory.Critical);
          if (orignalOrionAddOnCoverId != -1) {
            let criticalData = { ...this.addonsFromService[orignalOrionAddOnCoverId] };
            criticalData.IsAddonSelected = true;
            memberAddon.push(criticalData);
          }
        }
      }

    }

    // Personal Accident Cover
    if (this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Personal].value) {
      let orignalOrionAddOnCoverId = this.fetchOrignalOrionAddOnCoverId(this.hapDataModal.groupCategory.Personal);
      if (orignalOrionAddOnCoverId != -1) {

        let occupationMember = 'occupationFor' + memberType + (memberIndex + 1);
        for (let i = 0; i < this.occupation.length; i++) {
          const group = this.occupation.at(i) as FormGroup;
          const keys = Object.keys(group.controls);
          const keyFound = keys.some(key => key.includes(occupationMember));
          if (keyFound) {
            occupationData = this.occupation.value[i][occupationMember]
            if (!this.utilityService.isEmptyOrNull(occupationData)) {
              let personalData = { ...this.addonsFromService[orignalOrionAddOnCoverId] };
              personalData.IsAddonSelected = true;
              memberAddon.push(personalData);
            }
            break;
          }
        }
      }
    }

    // For child and adult only non member based adddons like befit or SI, CP,Nursing, Compassionate visit
    this.addonsFromService.forEach(data => {
      // Other Addons
      if (data.CoverId == RefiledHapAddons.SumInsured || data.CoverId == RefiledHapAddons.NursingAtHome || data.CoverId == RefiledHapAddons.CompassionateVisit || data.CoverId == RefiledHapAddons.ClaimProtector) {
        if (this.healthAdvantageForm.controls[data.CoverText].value) {
          let addonData = { ...data };
          addonData = { ...addonData, IsAddonSelected: true };
          memberAddon.push(addonData);
        }
      }
    })

    // Befit data
    if (!(this.utilityService.isEmptyOrNull(this.healthAdvantageForm.get(this.hapDataModal.groupCategory.Befit))) && this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Befit].value) {
      // Get the Befit Type
      let befitType = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.SelectedBefitData].value;
      if (!this.utilityService.isEmptyOrNull(befitType)) {
        let befitDataIndex = this.fetchOrignalOrionAddOnCoverId(befitType);
        if (befitDataIndex != -1) {
          let befitAddon = { ...this.addonsFromService[befitDataIndex], IsAddonSelected: true }
          memberAddon.push(befitAddon);
        }
      }
    }

    return { memberAddon, occupationData };

  }

  fetchOrignalOrionAddOnCoverId(label: string) {
    let addonId = this.addonsFromService.findIndex(data => data.CoverText == label);
    return addonId;
  }

  createMemberList() {
    let memberArray = [];
    if (this.adultDetails.length > 0) {
      for (let i = 1; i <= this.adultDetails.length; i++) {
        memberArray.push(`Adult ${i}`);
      }
    }
    if (this.childDetails.length > 0) {
      for (let i = 1; i <= this.childDetails.length; i++) {
        memberArray.push(`Child ${i}`);
      }
    }
    return memberArray;
  }

  createAddonList() {
    this.summaryAddons = this.addonsFromService.map(item => {
      return {
        'coverText': item.CoverText,
        'members': [],
        'isAllInsuredSelected': false
      }
    })
    const coverArray = ["Maternity Cover", "New Born Baby Cover", "Vaccination for new born baby", "Critical Illness", "Personal Accident Cover"];
    const addonsData = this.hapFormData.quoteFormData.addonsData;
    addonsData.forEach((j, index) => {
      for (let k of j['AddonCoverDetails']) {
        for (let i of this.summaryAddons) {
          if (i['coverText'] == k['CoverText']) {
            if (k['IsForAdult'] && k['IsForChild'] && !coverArray.includes(k['CoverText'])) {
              i['isAllInsuredSelected'] = true;
            } else {
              i['members'].push(`${index + 1}`);
            }
          }
        }
      }
    })
  }

  createViewFullSummary() {
    const memberArray = this.createMemberList();
    this.createAddonList();

    this.summaryAddons = this.summaryAddons.filter(item => {
      if (!item['isAllInsuredSelected']) {
        if (!item['coverText'].includes('BeFit')) {
          return item;
        }
      } else {
        return item;
      }
    })
    return { 'memberArray': memberArray, 'summaryAddons': this.summaryAddons };
  }


  setHapFormData(premiumData: SaveQuoteResponseStructure) {
    let resetFlag: boolean = this.checkMemberDetails();
    this.criticalIllnessAddons();
    this.checkForPersonalAccident();
    this.hapFormData.quoteFormData.addonList = this.hapMasterData.Data.AddonDetails;
    this.hapFormData.insuredFormData.befitCovers = this.hapMasterData.Data.befitCovers;
    this.hapFormData.insuredFormData.BefitApplicableCities = this.hapMasterData.Data.BefitApplicableCities;

    if (this.healthAdvantageForm.value.productType != 'New') {
      this.hapFormData.portabilityDataSet = true;
      this.hapFormData.quoteFormData.InstallmentApplicable = "No"
      this.hapFormData.quoteFormData.InstallmentFrequency = ""
    }
    else {
      this.hapFormData.portabilityDataSet = false;
    }
    this.hapFormData.quoteFormData.PlanCode = premiumData.PlanCode;
    this.hapFormData.quoteFormData.PlanName = premiumData.PlanName;
    this.hapFormData.quoteFormData.productName = this.healthAdvantageForm.controls['productName'].value;
    this.hapFormData.quoteFormData.productType = this.healthAdvantageForm.controls['productType'].value;
    this.hapFormData.quoteFormData.pincode = this.healthAdvantageForm.controls['pincode'].value;
    this.hapFormData.quoteFormData.adultDetails = this.adultDetails.value;
    this.hapFormData.quoteFormData.childDetails = this.childDetails.value;
    this.hapFormData.quoteFormData.cityId = this.stateDetails.cityId;
    this.hapFormData.quoteFormData.cityName = this.stateDetails.cityName;
    this.hapFormData.quoteFormData.stateId = this.stateDetails.stateId;
    this.hapFormData.quoteFormData.totalMemberData = this.getMemberRequestAddons();
    this.hapFormData.quoteFormData.policyTenure = this.healthAdvantageForm.controls['policyTenure'].value;
    this.hapFormData.quoteFormData.policyRange = this.healthAdvantageForm.controls['policyRange'].value;
    this.hapFormData.quoteFormData.applicantAnnualSum = this.healthAdvantageForm.controls['applicantAnnualSum'].value;
    this.hapFormData.quoteFormData.pedWaitingPeriod = this.healthAdvantageForm.controls['pedWaitingPeriod'].value;
    this.hapFormData.quoteFormData.conditionWaitingPeriod = this.healthAdvantageForm.controls['conditionWaitingPeriod'].value;
    this.hapFormData.quoteFormData.preHospitalDuration = this.healthAdvantageForm.controls['preHospitalDuration'].value;
    this.hapFormData.quoteFormData.postHospitalDuration = this.healthAdvantageForm.controls['postHospitalDuration'].value;
    this.hapFormData.quoteFormData.cityState = this.healthAdvantageForm.controls['cityState'].value;
    this.hapFormData.quoteFormData.applicantAnnualIncome = this.healthAdvantageForm.controls['applicantAnnualIncome'].value;
    this.hapFormData.quoteFormData.copaymentPercentTaken = this.healthAdvantageForm.controls['copaymentPercentTaken'].value;
    this.hapFormData.quoteFormData.voluntaryDeductible = this.healthAdvantageForm.controls['voluntaryDeductible'].value;
    // this.hapFormData.quoteFormData.softCopyDiscount = this.healthAdvantageForm.controls['softCopyDiscount'].value;
    this.hapFormData.quoteFormData.pedWaitingPeriod = this.healthAdvantageForm.controls['pedWaitingPeriod'].value;
    // this.hapFormData.quoteFormData.copaymentPercentTaken = this.healthAdvantageForm.controls['copaymentPercentTaken'].value.replace('%', '');
    this.hapFormData.quoteFormData.underWritterApproved = premiumData.Policystatus == "CONDITIONALAPPROVE" ? 'true' : 'false';
    this.hapFormData.quoteFormData.healthProposalProductType = healthProposalProductCode.hap;
    this.hapFormData.quoteFormData.isBefitApplicable = this.stateDetails.isBefitApplicable;
    this.hapFormData.quoteFormData.selfMemberExists = this.selfMemberExists;
    this.hapFormData.quoteFormData.proposerDobCi = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.proposerDobCi].value;
    // this.hapFormData.quoteFormData.corelationId = this.healthAdvantageForm.controls['correlationId'].value;
    // this.hapFormData.quoteFormData.PlanCode = this.healthAdvantageForm.controls['planCode'].value;
    // this.hapFormData.quoteFormData.PlanName = this.healthAdvantageForm.controls['planName'].value;

    this.hapFormData.policyDetails.DealId = this.dealId;
    this.hapFormData.quoteFormData.isCibilApplicable = this.cibilGroup.get('cibilDiscount').value;
    this.hapFormData.quoteFormData.proposerName = this.cibilGroup.get('cibilDiscount').value == 'Yes' ? this.cibilGroup.get('proposerName').value : '';
    this.hapFormData.quoteFormData.mobileNo = this.cibilGroup.get('cibilDiscount').value == 'Yes' ? this.cibilGroup.get('mobileNo').value : '';
    this.hapFormData.quoteFormData.DOB = this.cibilGroup.get('cibilDiscount').value == 'Yes' ? this.cibilGroup.get('DOB').value : '';
    this.hapFormData.quoteFormData.pinCode = this.cibilGroup.get('cibilDiscount').value == 'Yes' ? this.cibilGroup.get('pinCode').value : '';
    if (this.healthAdvantageForm.controls['EMI'].value == 'Yes') {
      this.hapFormData.quoteFormData.InstallmentApplicable = 'Yes';
      this.hapFormData.quoteFormData.InstallmentFrequency = this.healthAdvantageForm.controls['EMIInstallment'].value;
    }
    else {
      this.hapFormData.quoteFormData.InstallmentApplicable = 'No';
    }
    this.hapFormData.quoteFormData.zone = this.zoneMappedWithPincode;
    this.hapFormData.quoteFormData.addonsData = this.getMemberRequestAddons();
    this.hapFormData.quoteFormData.adultRelationShips = this.adultRelationShips;
    this.hapFormData.quoteFormData.childRelationShips = this.childRelationShips;
    this.hapFormData.quoteFormData.subProductType = this.hapDataModal.subProductType.orionHap;
    this.hapFormData.quoteFormData.subProductCode = this.hapMasterData.Data.SubProductCode;
    this.hapFormData.quoteFormData.zoneUpgrade = this.healthAdvantageForm.controls['zoneUpgrade'].value == "Yes" ? true : false;
    this.hapFormData.quoteFormData.reviseZone = this.healthAdvantageForm.controls['zoneUpgrade'].value == "Yes" ? this.healthAdvantageForm.controls['reviseZone'].value : this.zoneMappedWithPincode;
    this.hapFormData.quoteFormData.zoneDowngrade = this.healthAdvantageForm.controls['zoneDowngrade'].value == "Yes" ? true : false;
    this.hapFormData.quoteFormData.revisedZone = this.healthAdvantageForm.controls['zoneDowngrade'].value == "Yes" ? this.healthAdvantageForm.controls['revisedZone'].value : this.zoneMappedWithPincode;
    this.hapFormData.quoteFormData.refiledAddons = this.refiledAddons;
    this.hapFormData.quoteFormData.occupation = this.occupation.value;
    this.hapFormData.quoteFormData.maternityForAdult = this.utilityService.isEmptyOrNull(this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].value) ? [] : this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.MaternityForAdultValue].value;
    this.hapFormData.quoteFormData.criticalIllnessForAdult = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].value;
    this.hapFormData.quoteFormData.criticalAdultData = this.criticalAdultData;
    this.hapFormData.quoteFormData.maternityAdultData = this.maternityAdultData;
    this.hapFormData.quoteFormData.occupationdData = this.occupation.value;
    this.hapFormData.quoteFormData.hapRefillingSummary = this.createViewFullSummary();
    this.hapFormData.quoteFormData.creditScore = this.cibilScore.toString();
    this.hapFormData.quoteFormData.emiPremium = this.emiPremium;
    this.hapFormData.quoteFormData.netPremium = this.netPremium;
    this.hapFormData.quoteFormData.taxAmount = this.taxAmount;
    this.hapFormData.quoteDataSet = true;

    // If emi is true reset insured details
    let emiFlag: boolean = false;
    if (this.healthAdvantageForm.controls['EMI'].value == healthToggle.Yes) {
      if (this.hapFormData.insuredDataSet) {
        emiFlag = true;
      }
    }
    if (resetFlag || emiFlag) {
      this.setOtherFormFlags();
    }

    this.constructPremiumData(premiumData);
    this.healthDataStorageService.setHealthDataStorage(this.hapFormData);
  }


  criticalIllnessAddons() {
    if (this.hapFormData.insuredDataSet) {
      // Check whether critical Illness is selected or not
      if (this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Critical].value) {
        // Find out the adult for which it has selected
        let criticalAdultValue = this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.criticalIllnessForAdult].value;
        if (criticalAdultValue.length > 0) {
          this.hapFormData.insuredFormData.insuredMemberDetails.forEach((insuredMembers, index) => {
            for (let keys in insuredMembers) {
              if (keys.includes(insuredControls.preExistinDisease)) {
                let tempPedKey = keys.split(insuredControls.preExistinDisease)[1];
                let memberType = tempPedKey.includes(healthMembers.Adult) ? healthMembers.Adult : healthMembers.Child;
                let memberKey = tempPedKey.split(memberType);
                let newKey = memberType + ' ' + memberKey[1];
                if (criticalAdultValue.includes(newKey)) {
                  // Set no of ped control
                  insuredMembers[keys] = healthToggle.No;
                  let ped = this.hapFormData.insuredFormData.preExistingDisease[index];
                  // If ped was selected then make it as no
                  for (const keys in ped) {
                    if (ped.hasOwnProperty(keys)) {
                      ped[keys] = false;
                    }
                  }
                  this.hapFormData.insuredFormData.preExistingDisease[index] = ped;

                }
              }
            }
          })
        }
      }
    }
  }

  checkForPersonalAccident() {
    // Case 1 : If PA is selected and insured data is set we need to check . Members present in occupation/ deleted in occ those remaarks should be removed from ped
    if (this.hapFormData.insuredDataSet) {
      if (this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Personal].value) {
        //  Check if those members has PED selected or not with remarks
        if (this.occupation.value.length > 0) {

          this.hapFormData.insuredFormData.preExistingDisease.forEach((disease, index) => {

            for (let keys in disease) {
              if (keys.includes('remark')) {
                let memberType = keys.includes(healthMembers.Adult) ? healthMembers.Adult : healthMembers.Child;
                let splittedKey = keys.split('remark')[1]; //['', '1Adult1']
                const memberSelected = memberType + splittedKey.substring((memberType.length) + 1);
                // Now we need to find whether this key presents or not in ocuupation.
                //If not prseent delete the occupation key
                this.occupation.value.forEach(occData => {
                  let occKeys = Object.keys(occData);
                  // If the value of that occupation is empty and index matches delet remark
                  if (this.utilityService.isEmptyOrNull(occData[occKeys[0]]) && keys.includes(memberSelected)) {
                    delete disease[keys];
                  }
                })
              }
            }

          })
        }

      }

      // Case 2 If Pa is not selected then we need to remove all remark keys if exists
      else if (!this.healthAdvantageForm.controls[this.hapDataModal.groupCategory.Personal].value) {
        this.hapFormData.insuredFormData.preExistingDisease.forEach((disease, index) => {

          for (let keys in disease) {
            if (keys.includes('remark')) {
              delete disease[keys];
            }
          }

        })
      }
    }
  }

  checkMemberDetails(): boolean {
    let nonEqualMembers = [];
    let resetFlag: boolean = false;
    // Adult Details
    // If new adult details and child details length are same then we need to check the info
    if (this.adultDetails.length == this.hapFormData.quoteFormData.adultDetails.length) {
      let existingAdultDetails = this.hapFormData.quoteFormData.adultDetails;
      existingAdultDetails.forEach((adultData, index) => {
        // Looping through keys
        if (JSON.stringify(adultData) != JSON.stringify(this.adultDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }
    // If the length is only not equal that means adult data is been modified
    else if (this.adultDetails.length != this.hapFormData.quoteFormData.adultDetails.length) {
      nonEqualMembers.push(0);
    }

    // Child Details

    if (this.childDetails.length == this.hapFormData.quoteFormData.childDetails.length) {
      let existingChildDetails = this.hapFormData.quoteFormData.childDetails;
      existingChildDetails.forEach((childData, index) => {
        // Looping through keys
        if (JSON.stringify(childData) != JSON.stringify(this.childDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }

    // If the length is only not equal that means adult data is been modified
    else if (this.childDetails.length != this.hapFormData.quoteFormData.childDetails.length) {
      nonEqualMembers.push(0);
    }

    else {
      // Adult members found to be equal
      nonEqualMembers = [];
    }

    // If there is mismatch of data in terms of length or details
    if (nonEqualMembers.length > 0) {
      resetFlag = true;
    }
    else {
      resetFlag = false;
    }
    return resetFlag;
  }

  setOtherFormFlags() {
    this.hapFormData.insuredDataSet = false;
    this.hapFormData.kycDataSet = false;
    this.hapFormData.kycStatus.ekycMandatory = false;
    this.hapFormData.kycStatus.normal = false;
    this.hapFormData.kycStatus.normalSelf = false;
    this.hapFormData.kycStatus.ovd = false;
    this.hapFormData.kycStatus.ovdSelf = false;
    this.hapFormData.kycStatus.isKYCDoneSuccessfully = false;
    this.hapFormData.kycStatus.idType = false;
    this.hapFormData.kycStatus.certNumber = '';
    this.hapFormData.kycFormData = this.healthDataStorageService.resetKYCdata();
  }


  constructPremiumData(response: SaveQuoteResponseStructure) {
    this.hapFormData.premiumFormData.totalPremium = response.TotalPremium;
    this.hapFormData.premiumFormData.basicPremium = response.BasicPremium;
    this.hapFormData.premiumFormData.totalTax = response.TotalTax;
    this.hapFormData.premiumFormData.tenure = this.tenureDuration;
    this.hapFormData.premiumFormData.QuoteId = this.quoteID;
    this.hapFormData.premiumFormData.SubProductCode = this.hapMasterData.Data.SubProductCode;
    this.hapFormData.premiumFormData.PortabilitySubProductCode = this.hapMasterData.Data.PortabilitySubProductCode;
    //this.hapFormData.premiumFormData.SubProductCode = response.SubProductCode;
    // this.hapFormData.premiumFormData.productCode = response.productCode;
    this.hapFormData.premiumFormData.PfQuoteId = response.PFQuoteID;
    this.hapFormData.premiumFormData.InstallmentNetPremium = response.InstallmentNetPremium;
    this.hapFormData.premiumFormData.InstallmentTaxAmount = response.InstallmentTaxAmount;
    this.hapFormData.premiumFormData.InstallmentTotalPremium = parseInt(response.InstallmentTotalPremium);
    let c = response.UpgradedZone == "Zone_III" ? 'C' : '';
    let c1 = response.UpgradedZone == "Zone_II" ? 'B' : c;
    this.hapFormData.premiumFormData.zone = response.UpgradedZone == "Zone_I" ? 'A' : c1;
    this.hapFormData.premiumDataSet = true;
  }

  getadultchilddataforquickquote() {
    this.adultValue = this.hapFormData.quickquoteData.adultDetails.length;
    this.childValue = this.hapFormData.quickquoteData.childDetails.length;
    if (this.hapFormData.quickquoteData.adultDetails.length > 0) {
      this.hapFormData.quickquoteData.adultDetails.forEach((data, index) => {
        this.adultDetails.push(this.utilityService.createAdultDetails(index));
        // Patch values of adults
        // Below block is written because when there is self data that get's patched from kyc is not in date format
        // So to handle it we need to convert in into date type
        for (let adultKeys in data) {
          if (adultKeys.includes('dob')) {
            let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
            this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
          }
          else {
            this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
          }
        }
      })
    }

    if (this.hapFormData.quickquoteData.childDetails.length > 0) {
      this.hapFormData.quickquoteData.childDetails.forEach((data, index) => {
        this.childDetails.push(this.utilityService.createChildDetails(index));
        this.childMinMaxDate.push({ minDate: new Date(), maxDate: new Date() });
        // Patch values of adults
        for (let childKeys in data) {
          this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);
          if (childKeys.includes('Relationship')) {
            // We need to set min andd max dateof child as per relationship
            this.setResetChildDOB(data[childKeys], index);
          }
        }
      })
    }
  }

  patchQuickQuoteData() {
    this.hapFormData = this.healthDataStorageService.getHealthDataStorage();
    let applicantAnnualSum = Number(this.hapFormData.quickquoteData.applicantAnnualSum)
    this.healthAdvantageForm.patchValue({
      pincode: this.hapFormData.quickquoteData.pincode,
      cityState: this.hapFormData.quickquoteData.cityState,
      // adultDetails: this.hapFormData.quickquoteData.adultDetails,
      childDetails: this.hapFormData.quickquoteData.childDetails,
      policyTenure: this.hapFormData.quickquoteData.policyTenure ? this.hapFormData.quickquoteData.policyTenure : this.hapDataModal.tenurePeriod[0],
      applicantAnnualSum: this.hapFormData.quickquoteData.applicantAnnualSum,
    })
    if (applicantAnnualSum >= 7500000) {
      if (this.healthAdvantageForm.controls['policyRange']) {
        this.healthAdvantageForm.controls['policyRange'].patchValue(this.hapDataModal.hapPolicyRange[1]);
        this.changePolicyRange(this.hapDataModal.hapPolicyRange[1]);
        this.healthAdvantageForm.controls['applicantAnnualSum'].patchValue(applicantAnnualSum.toString());
      }
    } else {
      this.healthAdvantageForm.controls['policyRange'].patchValue(this.hapDataModal.hapPolicyRange[0]);
      this.changePolicyRange(this.hapDataModal.hapPolicyRange[0]);
      this.healthAdvantageForm.controls['applicantAnnualSum'].patchValue(applicantAnnualSum.toString());
    }
    this.fetchDataAsPerPincode('', this.hapFormData.quickquoteData.pincode)
    // this.stateFetched = true;
  }

  manageVisibility(request, element, addonDetails) {
    let policyTenure = request?.quoteFormData?.policyTenure == "3 Years";
    let worCoverldwide = (Number(request?.quoteFormData?.applicantAnnualSum) >= this.hapDataModal?.maxSumInsured && element.CoverId == 11);
    let maternityCover = policyTenure && element.CoverId == 3;
    let newBornCover = addonDetails['AddOn3'] && element.CoverId == 4;
    let vacciantionCover = addonDetails['AddOn3'] && element.CoverId == 5;
    let otherAddons = (element.CoverId != 3 && element.CoverId != 4 && element.CoverId != 5 && element.CoverId != 11);
    return worCoverldwide ? true : maternityCover ? true : newBornCover ? true : vacciantionCover ? true : otherAddons ? true : false;
  }

  onZoneUpgrade() {
    const zoneUpgradeControl = this.healthAdvantageForm.controls['zoneUpgrade'];
    const reviseZoneControl = this.healthAdvantageForm.controls['reviseZone'];
    reviseZoneControl.patchValue('');
    if (zoneUpgradeControl.value === 'No') {
      zoneUpgradeControl.patchValue('No');
      reviseZoneControl.clearValidators();
    } else {
      zoneUpgradeControl.patchValue('Yes');
      reviseZoneControl.setValidators(Validators.required);
    }
    reviseZoneControl.updateValueAndValidity();
  }

  onZoneDowngrade() {
    const zoneDowngradeControl = this.healthAdvantageForm.controls['zoneDowngrade'];
    const revisedZoneControl = this.healthAdvantageForm.controls['revisedZone'];
    revisedZoneControl.patchValue('');
    if (zoneDowngradeControl.value === 'No') {
      zoneDowngradeControl.patchValue('No');
      revisedZoneControl.clearValidators();
    } else {
      zoneDowngradeControl.patchValue('Yes');
      revisedZoneControl.setValidators(Validators.required);
    }
    revisedZoneControl.updateValueAndValidity();
  }

  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  onRefresh(status) {
    if (status) {
      this.fetchHAPDetails();
    }
  }

  private selectScrollListener: () => void
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  handleSelectionChange(selected: string): void {
    if (selected === 'copayment') {
      // If Co-Payment is selected, disable the Voluntary Deductible dropdown
      this.isVoluntaryDeductibleDisabled = true;
      this.healthAdvantageForm.get('voluntaryDeductible').setValue(null);
    } else if (selected === 'deductible') {
      // If Voluntary Deductible is selected, enable the Voluntary Deductible dropdown
      this.isVoluntaryDeductibleDisabled = false;
      this.selectedOption = null;
    }
  }

  get cibilGroup() {
    return this.healthAdvantageForm.get('cibilDiscountSection') as FormGroup;
  }

  cibilChange() {

    if (this.cibilGroup.get('cibilDiscount').value === this.hapDataModal.options[0]) {
      this.cibilGroup.get('proposerName').setValidators(Validators.required);
      this.cibilGroup.get('proposerName').updateValueAndValidity();
      this.cibilGroup.get('mobileNo').setValidators(Validators.required);
      this.cibilGroup.get('mobileNo').updateValueAndValidity();
      // this.cibilGroup.get('DOB').setValidators(Validators.required);
      // this.cibilGroup.get('DOB').updateValueAndValidity();
      this.cibilToggle = true;
    }
    else {
      this.cibilGroup.get('proposerName').clearValidators();
      this.cibilGroup.get('proposerName').updateValueAndValidity();
      this.cibilGroup.get('proposerName').setValue('');
      this.cibilGroup.get('mobileNo').clearValidators();
      this.cibilGroup.get('mobileNo').updateValueAndValidity();
      this.cibilGroup.get('mobileNo').setValue('');
      // this.cibilGroup.get('DOB').clearValidators();
      // this.cibilGroup.get('DOB').updateValueAndValidity();
      // this.cibilGroup.get('DOB').setValue('');
      this.cibilToggle = false;
    }
  }


  clearForm() {
    if (this.healthAdvantageForm.get('cibilDiscountSection').dirty) {
      this.healthAdvantageForm.get('cibilDiscountSection').reset();
    }
    else return;
  }

  onCibilSubmit() {
    this.onCibilSave = true;
    if (this.cibilGroup.valid) {
      const cibilPayload: GetCibilScoreRequestPayload = {
        "orgDiscount": "0.1",
        "userName": this.cibilGroup.get('proposerName').value,
        "userPincode": this.cibilGroup.get('pinCode').value,
        "userMobile": this.cibilGroup.get('mobileNo').value,
      }
      this.agentService.getCibilScore(cibilPayload).subscribe({
        next: (response) => {
          if (response.StatusCode == Constants.statusCode_success) {
            this.cibilScore = response.Data.CibilScoreResponse.CreditScore;
            this.hapFormData.quoteFormData.cibilScoreDiscount = response.Data.CibilScoreResponse.CibilScorePercentage;
            if (response.Data.CibilScoreResponse.CibilScorePercentage == '0.0') {
              this.cibilToggle = false;
              this.cibilGroup.get('cibilDiscount').setValue('No')
              this.cibilGroup.get('proposerName').clearValidators();
              this.cibilGroup.get('proposerName').updateValueAndValidity();
              this.cibilGroup.get('proposerName').setValue('');
              this.cibilGroup.get('mobileNo').clearValidators();
              this.cibilGroup.get('mobileNo').updateValueAndValidity();
              this.cibilGroup.get('mobileNo').setValue('');
              const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.cibilNotEligible);
              this.popupService.openGenericPopup(errorPopupData);
            }
            else if (response.Data.CibilScoreResponse.CibilScorePercentage != '0.0') {

              const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.cibilEligible);
              this.popupService.openGenericPopup(errorPopupData);
            }
          }
          else {
            this.cibilToggle = false;
            this.cibilGroup.get('cibilDiscount').setValue('No')
            this.cibilGroup.get('proposerName').clearValidators();
            this.cibilGroup.get('proposerName').updateValueAndValidity();
            this.cibilGroup.get('proposerName').setValue('');
            this.cibilGroup.get('mobileNo').clearValidators();
            this.cibilGroup.get('mobileNo').updateValueAndValidity();
            this.cibilGroup.get('mobileNo').setValue('');
          }
        }
      })
    }
    else return
  }

  proposerNameInput(event: KeyboardEvent) {
    const regex = Constants.namePatternRegExp;
    if (event.key === 'Backspace' || regex.test(event.key)) {
      return;
    }
    else {
      event.preventDefault();
    }
  }

  mobNoInput(event: KeyboardEvent) {
    const regex = Constants.numberPatternRegExp;
    if (event.key === 'Backspace' || regex.test(event.key)) {
      return;
    } else {
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    this.unsubscribeSubscription();
  }


}
