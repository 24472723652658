import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericService } from '../../services/generic.service';
import { CommonPayRequestPayload, PaymentResponsePayload } from '../../interface/payment';

@Injectable({
  providedIn: 'root'
})
export class OrionHAAPService {

  readonly baseURI = `${environment.baseURL}`;
  readonly SaveEditOrionHAAPProposal = `OrionHealth/SaveEditProposal`;
  readonly orionURI = `${environment.baseURL}BancsPayment/`;
  readonly commonPayment = 'CommonPayment';

  constructor(private generic: GenericService) { }

  saveEditOrionHAAPProposal(payload: any): Observable<any>{
    const endpoint = `${this.baseURI}${this.SaveEditOrionHAAPProposal}`;
    return this.generic.genericService<any>(endpoint, payload);
  }

  // Pay later Service
  orionCommonPayRequest(data: CommonPayRequestPayload): Observable<PaymentResponsePayload> {
    const endpoint = `${this.orionURI}${this.commonPayment}`;
    return this.generic.genericService<PaymentResponsePayload>(endpoint, data);
  }
}
