<section class="container-fluid">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="floatdiv floatingedit" *ngIf="scrolling">
                <img class="imgScss" src="../../../../../../assets/images/BBR/ScrollToTop.svg" alt="" (click)="scrollToTop()">
            </div>

            <div class="marginbox">
                <!-- Icon and title start -->
                <div class="myTransactionTitle" *ngIf="!isSearchVisible">
                    <div class="bbrheading">
                        <span><img src="../../../../../../assets/images/BBR/BBR.svg" alt=""></span>
                        <span class="titleSpanWidth">
                            <h3 class="titleH3Text">Booked Business Report</h3>
                        </span>
                    </div>
                    <div class="d-md-none headerImgSection">
                        <span (click)="openFilterBottomSheet()">
                            <img class="headerImg" src="{{filterImage}}" alt="">
                        </span>
                        <span (click)="openSearchBottomSheet()">
                            <img class="headerImg" src="{{editImage}}" alt="" /></span>
                        <span (click)="searchFilter()">
                            <img class="headerImg" src="../../../../../../assets/images/BBR/Search.svg" alt="" /></span>
                    </div>
                </div>
                <div class="mobilesearch__fieldBBr" *ngIf="isSearchVisible">
                    <div class="mobileinputDivbbr">
                        <input matInput type="text" class="input-group__input placeholder"
                            [cdkTrapFocusAutoCapture]="isSearchVisible" [cdkTrapFocus]="isSearchVisible"
                            [placeholder]="searchPlaceHolder" (input)="queryData()" [(ngModel)]="inputValue"
                            autocomplete="off">
                        <span class="crossclick" (click)="closeClear()">
                            <img src="../../../../../../assets/images/BBR/Close.svg" alt="">
                        </span>
                    </div>
                </div>
                <!-- Icon and title end -->

                <!-- Desktop View (above 767px) start -->
                <div class="d-none d-md-block">
                    <!-- Input fields card start -->
                    <mat-card class="innerbox borderinnerbox">
                        <div class="row fieldsdiv" [formGroup]="bookFormGroup">

                            <div class="col-sm-3 form-group">
                                <mat-label class=" input-group__label" for="IMID">IMID</mat-label>
                                <input matInput type="text" class="input-group__input" placeholder="Enter IMID"
                                    autocomplete="off" formControlName="IMID" id="IMID" readonly>
                            </div>

                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label" for="myInput">Product Type</mat-label>
                                <mat-select class="input-group__input cstm_select" id="productType"
                                    placeholder="Product Type" formControlName="productType"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                    <ng-container *ngFor="let item of productTypeList; let i = index; let last = last">
                                        <mat-option [value]="item.Product_Name">
                                            {{item.Product_Name}}
                                        </mat-option>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                </mat-select>
                            </div>

                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label">Select Date</mat-label>
                                <mat-date-range-input class="input-group__input dob" [rangePicker]="picker"
                                    (click)="picker.open()" [min]="minMax.minDate" [max]="minMax.maxDate">
                                    <input matStartDate formControlName="startDate" placeholder="Start date" readonly>
                                    <input matEndDate formControlName="endDate" placeholder="End date" readonly>
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker touchUi></mat-date-range-picker>
                            </div>

                            <div class="col-sm-3 form-group">
                                <button class=" search-button" [ngClass]="{'positionBtn': !isSelected}"
                                    (click)="onSubmit()">
                                    <span>Submit</span>
                                </button>
                            </div>

                        </div>
                    </mat-card>
                    <!-- Input fields card end -->

                </div>
                <!-- Desktop View (above 767px) end -->

                <!-- Info card start -->
                <mat-card class="innerbox borderinnerbox align-items-center p-3 infoCardColor d-none d-md-flex">
                    <div class="row fieldsdiv">
                        <table>
                            <caption class="hidden-caption">Details</caption>
                            <th class="hidden-caption">Details</th>
                            <tr>
                                <td class="vertAlign">
                                    <img src="../../../../../../assets/images/BBR/Info.svg" class="infoIcon" alt="Info">
                                </td>
                                <td>
                                    We found a total of <span class="font-weight-bold">{{policyCount ? policyCount : "0"
                                        }}</span>
                                    records
                                    with your search
                                    parameters. Your total booked
                                    business report from <span class="font-weight-bold">{{startDate |
                                        date:
                                        'dd MMM,yyyy'}}</span> to
                                    <span class="font-weight-bold">{{endDate | date: 'dd MMM,yyyy'
                                        }}</span>
                                    is <span class="font-weight-bold amountColor">
                                        ₹{{businessAmount ? utilityService.convertStringtoCurrency(businessAmount)
                                        : "0" }}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-card>
                <!-- Info card end -->

                <mat-card class="innerbox borderinnerbox p-2 infoCardColor d-md-none">
                    <table class="filterDatatable">
                        <caption class="hidden-caption">Details</caption>
                        <th class="hidden-caption">Details</th>
                        <tr>
                            <td class="table-cell-width-30"><img src="../../../../../../assets/images/BBR/calendarRange.svg" alt="">
                            </td>
                            <td class="filterlabel">Date Range</td>
                            <td class="table-cell-width-30">
                                <img src="../../../../../../assets/images/BBR/record.svg" alt="">
                            </td>
                            <td class="filterlabel">Records</td>
                            <td class="countLabel">{{policyCount}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="countLabel">
                                {{startDate |date:'dd MMM,yyyy'}} -
                                {{endDate | date: 'dd MMM,yyyy'}}
                            </td>
                            <td class="table-cell-width-30"> <img src="../../../../../../assets/images/BBR/amount.svg" alt=""></td>
                            <td class="filterlabel">Amount</td>
                            <td class="countLabel">₹{{utilityService.convertStringtoCurrency(businessAmount)}}</td>
                        </tr>
                    </table>
                </mat-card>

                <!-- Need to fix -->
                <mat-card class="innerbox borderinnerbox">
                    <div class="fieldsdiv">
                        <div class="productmainDiv">
                            <div class="productdiv">
                                <div class="align-items-center">
                                    <span>Product: <span class="font-weight-bold">{{product}}</span></span>
                                </div>
                            </div>
                            <div class="filterdiv">
                                <div class="d-none d-md-block">
                                    <div class="search__fieldBBr">
                                        <div class="inputDivbbr" [ngClass]="{'searchBorder': showCancel}">
                                            <input matInput type="text" class=" placeholder"
                                                [placeholder]="searchPlaceHolder" (input)="queryData()"
                                                [(ngModel)]="inputValue" (focus)="inputFocus()" (blur)="inputBlur()"
                                                autocomplete="off">
                                            <span class="crossclick pr-2" (click)="clearInputField()">
                                                <img src="../../../../../../assets/images/BBR/desktopCross.svg" alt="">
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <!-- Excel Download -->
                                <div *ngIf="this.reportList.length > 0" matTooltip="Download Excel" class="downloadExcel">
                                    <span (click)="downloadExcel()"><img src="../../../../../../assets/images/exceldownload.svg" alt=""></span>
                                </div>
                                
                                <!-- filter functionality -->
                                <div>
                                    <div class="dropdown d-none d-md-flex ">
                                        <button matTooltip="Filter" class="dropdown-toggle customDropdown" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <div class="toggIcon">
                                                <img src="../../../../../../assets/images/BBR/Filter.svg" alt="">
                                            </div>
                                        </button>
                                        <ul class="dropdown-menu customDropItems " aria-labelledby="dropdownMenuButton">
                                            <li class="dropdownHeading">
                                                <h4>Business type</h4>
                                            </li>
                                            <li class="dropdown-item cs_dropdown-item"
                                                *ngFor="let value of businessFilterList">
                                                <mat-checkbox [checked]="businessFilter.includes(value.toLowerCase())"
                                                    (change)="applyBusinessFilter($event.checked, value)">
                                                    {{ value }}
                                                </mat-checkbox>
                                            </li>
                                            <li>
                                                <mat-divider></mat-divider>
                                            </li>
                                            <li class="dropdownHeading">
                                                <h4>Endorsement type</h4>
                                            </li>
                                            <li class="dropdown-item cs_dropdown-item"
                                                *ngFor="let value of policyFilterList">
                                                <mat-checkbox [checked]="policyFilter.includes(value.toLowerCase())"
                                                    (change)="applyPolicyFilter($event.checked, value)">
                                                    {{ value }}
                                                </mat-checkbox>
                                            </li>
                                        </ul>

                                    </div>
                                </div>

                                <!-- ends here -->
                                <div class=" d-flex align-items-center">
                                    <mat-button-toggle-group class="custom-toggle-group" (change)="onToggle($event)"
                                        [value]="toggleValue">
                                        <mat-button-toggle matTooltip="Table View" class="custom_togglebtn"
                                            value="table"><img src="../../../../../../assets/images/BBR/tablelist.svg"
                                                alt=""></mat-button-toggle>
                                        <mat-button-toggle matTooltip="Card View" class="custom_togglebtn"
                                            value="card"><img src="../../../../../../assets/images/BBR/cardList.svg"
                                                alt=""></mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-card>
                <!-- Table format start -->
                <app-custom-table *ngIf="toggleValue == 'table'" [reportList]="filteredData" [buttonData]="tableBtnInfo"
                    [displayedColumns]="displayCol" (policyNo)="downloadPolicy($event)"
                    [tableSize]="'max-content'"></app-custom-table>
                <!-- Table format end -->

                <!-- Card format start -->
                <app-custom-card *ngIf="toggleValue == 'card'" [reportList]="filteredData" [buttonData]="cardBtnInfo"
                    [displayedColumns]="displayCol" (policyNo)="downloadPolicy($event)"></app-custom-card>
                <!-- Card format end -->

            </div>
        </div>
    </div>
</section>