import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/layout/popups/popup.component';
import { UtilityService } from './utility.service';
import { EditPortabilityPopupModal, PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupButton, popupButtonAction, popupDescriptionMsg, popupHeader, popupImgPath, popupType } from '../enums/popupEnums';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ModifyPortabilityDetailsPayload, FetchPropToModifyResponse, ModifyPortabilityDetailsResponse } from '../interface/mypolicy';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SaveEditPortabilityPopupComponent } from '../components/sidemenu/save-edit-portability-popup/save-edit-portability-popup.component';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private pedNotRequired = new Subject<boolean>();
  private paymentValue = new Subject<string>();
  private emailValue = new Subject<string>();
  private travelPaymentValue = new Subject<object>();
  private travelPaymentValue1 = new Subject<object>();
  pedNotRequired$ = this.pedNotRequired.asObservable();
  paymentValue$ = this.paymentValue.asObservable();
  emailValue$ = this.emailValue.asObservable();
  travelPaymentValue$ = this.travelPaymentValue.asObservable();
  travelPaymentValue1$ = this.travelPaymentValue1.asObservable();
  // Emi Value
  private emiValue = new BehaviorSubject<boolean>(false);
  emiValue$ = this.emiValue.asObservable();
  // After edit portability call onload api
  private onLoadMyPolicyApi = new BehaviorSubject<boolean>(false);
  onLoadMyPolicyAPi$ = this.onLoadMyPolicyApi.asObservable();

  private cibilScore = new BehaviorSubject<boolean>(false);
  cibilScore$ = this.cibilScore.asObservable();

  readonly baseURI = `${environment.baseURL}`;
  readonly modifyRolloverProposal = 'Health/ModifyRolloverProposal';
  readonly modifyRolloverOrionProposal = 'BancsPolicy/ModifyRolloverProposal';
  private portabilityPolicyDetails: FetchPropToModifyResponse;
  private EpolicyNumber;
  private isBancsPolicy: boolean;

  // back button pressed data
  private backButton = new Subject<boolean>();
  backButton$ = this.backButton.asObservable();
  // back button logout data
  private logout = new Subject<boolean>();
  logout$ = this.logout.asObservable();

  // delete data
  private delete = new Subject<boolean>();
  delete$ = this.delete.asObservable();
  // refresh form data
  private refreshForm = new Subject<boolean>();
  refreshForm$ = this.refreshForm.asObservable();

  // Close all dialog
  private closeDialog = new BehaviorSubject<boolean>(false);
  closeDialog$ = this.closeDialog.asObservable();

  // emit member index
  private memberIndex = new BehaviorSubject<string>('');
  memberIndex$ = this.memberIndex.asObservable();

  constructor(private dialog: MatDialog,
    private http: HttpClient,
    private utilityService: UtilityService) {
  }

  // Emit PED Data
  emitPEDData(value: boolean) {
    this.pedNotRequired.next(value);
  }
  //Emit Payment Data
  emitPaymentData(data: string) {
    this.paymentValue.next(data);
  }

  //Emit TravelPayment Data
  emitTravelPaymentData(data: object) {
    this.travelPaymentValue.next(data);
  }

  emitTravelPaymentDetails(data: object) {
    this.travelPaymentValue1.next(data);
  }

  // Emit Emi Data
  emitEmiData(data: boolean) {
    this.emiValue.next(data);
  }

  emitCibilData(data: boolean) {
    this.cibilScore.next(data);
  }

  // Refresh form data
  emitRefreshFormData(value: boolean) {
    this.refreshForm.next(value);
  }
  // back button press
  emitBackButtonData(value: boolean) {
    this.backButton.next(value);
  }
  //logutdata
  emitLogoutData(value: boolean) {
    this.logout.next(value);
  }
  //delete data
  emitDeleteData(value: boolean) {
    this.delete.next(value);
  }

  emitEmailValue(value: string) {
    this.emailValue.next(value);
  }

  emitMyPolicyOnLoadApi(value: boolean) {
    this.onLoadMyPolicyApi.next(value);
  }
  //emit member index
  emitRenewalMemberIndex(value: string) {
    this.memberIndex.next(value);
  }
  // popup for generic structure
  openGenericPopup(popupModal?: PopupModal) {

    let popupData: PopupModal = {
      popupType: popupModal.popupType,
      imgName: popupModal.imgName,
      header: popupModal.header,
      description: !this.utilityService.isEmptyOrNull(popupModal.description) ? popupModal.description : 'Something went wrong please try again later.',
      errorFlag: popupModal.errorFlag ? popupModal.errorFlag : false,
      data: popupModal.data ? popupModal.data : ' ',
      buttonLabel: popupModal.buttonLabel,
      buttonAction: popupModal.buttonAction
    };

    let className = popupType.confirmation || popupType.AML ? ['my-custom-dialog', 'confirm-dialog'] : 'my-custom-dialog'
    this.dialog.open(PopupComponent, {
      panelClass: className,
      maxWidth: popupModal.popupType == popupType.confirmation || popupModal.popupType == popupType.AML ? '25vw' : '100vw',
      data: popupData,
      disableClose: popupModal.buttonAction == popupButtonAction.logout || popupModal.header == popupHeader.refreshData || popupModal.buttonAction == popupButtonAction.dashboard || popupModal.description == popupDescriptionMsg.emiNotApplicable ? true : false || popupModal.description == popupDescriptionMsg.cibilKYC ? true : false
    }
    );
  }

  openPaymentPopup(popupModal?: PopupModal) {
    let popupData: PopupModal = {
      popupType: popupModal.popupType,
      imgName: popupModal.imgName,
      header: popupModal.header,
      description: !this.utilityService.isEmptyOrNull(popupModal.description) ? popupModal.description : 'Something went wrong please try again later.',
      buttonLabel: popupModal.buttonLabel,
      buttonAction: popupModal.buttonAction
    };

    this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      height: '45%',
      data: popupData,
    }
    );
  }
  openRiskCategoryPopUp(riskData) {
    let popupData = {
      popupType: 'riskCategory',
      data: riskData
    }

    this.dialog.open(PopupComponent, {
      data: popupData,
      maxWidth: window.innerWidth > 767 ? '35vw' : '90vw',
      panelClass: 'my-risk-category'
    })
  }
  openPremiumSummaryPopup(popupModal?: PopupModal) {
    const screenWidth = window.innerWidth;

    let popupData: PopupModal = {
      popupType: popupModal.popupType,
      imgName: popupModal.imgName,
      header: popupModal.header,
      description: !this.utilityService.isEmptyOrNull(popupModal.description) ? popupModal.description : 'Something went wrong please try again later.',
      buttonLabel: popupModal.buttonLabel
    };

    if (screenWidth >= 1158) {
      this.dialog.open(PopupComponent, { data: popupData, height: '100vh', width: '33vw', position: { left: '0px', top: '56px' } });
    }
    else if (screenWidth < 1158 && screenWidth >= 768) {
      this.dialog.open(PopupComponent, { data: popupData, height: '100vh', width: '48vw', position: { left: '0px', top: '56px' } });
    }
    else if (screenWidth <= 767) {
      this.dialog.open(PopupComponent, { width: '90%', maxWidth: '100vw', height: '95%', maxHeight: '100vh', data: popupData });
    }
  }

  // call below method to construct error popup message
  fetchErrorPopupMsg(errorMsg: string, data?: string, errorFlag?: boolean): PopupModal {
    let popupData: PopupModal = {
      popupType: popupType.generic,
      imgName: popupImgPath.alertGif,
      header: popupHeader.error,
      description: errorMsg,
      errorFlag: errorFlag ? errorFlag : false,
      data: data ? data : '',
      buttonLabel: popupButton.ok,
      buttonAction: popupButtonAction.close,
    }
    return popupData
  }
  //Info popup
  infoPopupMsg(errorMsg: string): PopupModal {
    let popupData: PopupModal = {
      popupType: popupType.generic,
      imgName: popupImgPath.successGif,
      header: popupHeader.success,
      description: errorMsg,
      buttonLabel: popupButton.ok,
      buttonAction: popupButtonAction.close
    }
    return popupData
  }
  public get getPortabilityPolicyDetails() {
    return this.portabilityPolicyDetails;
  }
  public set setPortabilityPolicyDetails(data: FetchPropToModifyResponse) {
    this.portabilityPolicyDetails = data;
  }
  public get getEpolicyNumber() {
    return this.EpolicyNumber;
  }
  public set setEpolicyNumber(data: string) {
    this.EpolicyNumber = data;
  }

  public get getIsbancsPolicy() {
    return this.isBancsPolicy;
  }
  public set setIsbancsPolicy(data: boolean) {
    this.isBancsPolicy = data;
  }

  modifyPortabilityDetails(request: ModifyPortabilityDetailsPayload, isBancsPolicy: boolean): Observable<ModifyPortabilityDetailsResponse> {
    let endpoint = `${this.baseURI}${isBancsPolicy ? this.modifyRolloverOrionProposal : this.modifyRolloverProposal}`
    return this.http.post<ModifyPortabilityDetailsResponse>( endpoint , request);
  }
  openEditPortabilityPopup(popupModal?: EditPortabilityPopupModal) {
    const screenWidth = window.innerWidth;
    this.setPortabilityPolicyDetails = popupModal.policyDetails;
    this.setEpolicyNumber = popupModal.EPolicyID;
    this.isBancsPolicy = popupModal.isBancsPolicy;
    let popupData: EditPortabilityPopupModal = {
      popupType: popupModal.popupType,
      imgName: popupModal.imgName,
      header: popupModal.header,
      description: !this.utilityService.isEmptyOrNull(popupModal.description) ? popupModal.description : 'Something went wrong please try again later.',
      buttonLabel: popupModal.buttonLabel,
      policyDetails: popupModal.policyDetails,
      EPolicyID: popupModal.EPolicyID,
      isBancsPolicy: popupModal.isBancsPolicy
    };
    if (screenWidth >= 767) {
      this.dialog.open(SaveEditPortabilityPopupComponent, { data: popupData, width: '20vw'});
    }
    else {
      this.dialog.open(SaveEditPortabilityPopupComponent, { width: '90%', maxWidth: '100vw', maxHeight: '100vh', data: popupData });
    }
  }

  openChequeStatusPopup(popupModal: PopupModal) {
    this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-dialog',
      width: '350px',
      data: popupModal
    });
  }

  openSalesGeniePopup(popupModal: PopupModal) {
    this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-dialog',
      width: '600px',
      data: popupModal
    });
  }

  openDeclarationsPopup(popupModal: PopupModal) {
    this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-dialog',
      width: '600px',
      data: popupModal
    });
  }

  openTravelCountryPopup(popupModal: PopupModal) {
    this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-dialog',
      // width: '33vw',
      maxWidth: window.innerWidth > 767 ? '35vw' : '80vw',  
      height: '300px',
      data: popupModal
    });
  }

  // Call tthis service when application is redirected to other app or logged out
  closeAllDialog(data: boolean) {
    this.closeDialog.next(data);
  }

}