<!-- Edit Portability popup -->
<div class="summaryMain pt-2">
  <div class="container summaryContainer">
    <div class="row">
      <div class="col-sm-12 closediv pl-3 pr-3">
        <h1 class="summaryHead">Edit Portability Details</h1>
        <div class="closebtn pl-0 pr-0" (click)="closeAllDialog()">
          <img src="../../../assets/images/cross.png" alt="close" style="width: 12px; height: 12px;">
        </div>

      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="insuredForm">
    <div class="container">
      <div class="row">
        <div class="col-12 mt-3" *ngIf='portabilityPolicyDetails.Data.PortabilityTypeOfPolicy=="FLOATER"'>
          <!--  -->
          <mat-label class="input-group__label" for="myInput">Portability Sum Insured (₹) <sup
              class="mandtory-field">*</sup></mat-label>
          <input type="text" id="suminsured" class="input-group__input" matInput placeholder="000000" autocomplete="off"
            [maxlength]="10" formControlName="FloaterSumInsured"
            (keydown)="utilityService.dynamicControlsAlphaOnly($event,false)"
            (keypress)="onKeyPressForSaveEditPortability($event);"
            (paste)="utilityService.onPaste($event,false,true)" />
          <mat-error *ngIf="insuredForm.get('FloaterSumInsured').invalid && 
              insuredForm.get('FloaterSumInsured').hasError('portabilitySIRequired')  && formSubmitted">
            Enter Portability Sum Insured.
          </mat-error>
          <mat-error *ngIf="insuredForm.get('FloaterSumInsured').invalid && 
              insuredForm.get('FloaterSumInsured').hasError('portabilitySIGreaterThanSI')  && formSubmitted">
            Portability SI should not be greater than IL Sum Insured.
          </mat-error>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
          <div class="col-sm-12 mt-3 form-group">
          <ng-container *ngFor="let mem of insuredForm.get('Members')['controls']; let j = index;">
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="policyToggle_group">
              <mat-button-toggle class="PolicyBtn" (click)="memberChanged(j)" [checked]="showSegment == (j)">
                {{mem.controls.KidAdultType.value}} {{mem.controls.MemberCount.value}}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </ng-container>
        </div>
      </div>
      <div formArrayName="Members">
        <ng-container *ngFor="let mem of insuredForm.get('Members')['controls']; let i = index;" [formGroupName]="i">
          <div *ngIf="showSegment == i">
            <div class="row">
              <div class="col-12">
                <h3 class="input-group__label">{{mem.controls.InsuredName.value}} </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12" *ngIf='portabilityPolicyDetails.Data.PortabilityTypeOfPolicy=="STANDALONE"'>
                <mat-label class="input-group__label" for="myInput">Portability Sum Insured (₹) <sup
                    class="mandtory-field">*</sup></mat-label>
                <input type="text" id="myInput" class="input-group__input" matInput formControlName="PortabilitySI"
                  placeholder="Portability Sum Insured" autocomplete="off" [maxlength]="10"
                  (input)="validationOfSumInsured($event)" />
                <mat-error
                  *ngIf="insuredForm.get('Members')['controls'][i].controls['PortabilitySI'].invalid && 
                insuredForm.get('Members')['controls'][i].controls['PortabilitySI'].hasError('portabilitySIRequired')  && formSubmitted">
                  Enter Portability Sum Insured.
                </mat-error>
                <mat-error
                  *ngIf="insuredForm.get('Members')['controls'][i].controls['PortabilitySI'].invalid && 
              insuredForm.get('Members')['controls'][i].controls['PortabilitySI'].hasError('portabilitySIGreaterThanSI')  && formSubmitted">
                  Portability SI should not be greater than IL Sum Insured.
                </mat-error>
              </div>
              <div class="col-12">
                <mat-label class="input-group__label" for="myInput">Has been insured since <sup
                    class="mandtory-field">*</sup></mat-label>
                <input class="input-group__input dob" matInput [min]="minPortabilityDOJ" [max]="maxPortabilityDOJ"
                  placeholder="DD/MM/YYYY" [matDatepicker]="picker1" name="policyDate" formControlName="PortabilityDOJ"
                  (click)="picker1.open()" readonly>
                <mat-datepicker-toggle matIconSuffix [for]="picker1">
                </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error
                  *ngIf="insuredForm.get('Members')['controls'][i].controls['PortabilityDOJ'].invalid && 
              insuredForm.get('Members')['controls'][i].controls['PortabilityDOJ'].hasError('required')  && formSubmitted">
                  Select Portability DOJ.
                </mat-error>
              </div>
              <div class="col-12">
                <mat-label class="input-group__label" for="myInput">Portability Waiver <sup
                    class="mandtory-field">*</sup></mat-label>
                <mat-select class="input-group__input cstm_select" formControlName="PortabilityWaiver"
                  (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()" placeholder="Select Waiver"
                  required>
                  <ng-container *ngFor="let waiverYear of portabilityWavierArray; let last = last">
                    <mat-option value={{waiverYear}}>{{waiverYear}}</mat-option>
                    <mat-divider *ngIf="!last"></mat-divider>
                  </ng-container>
                </mat-select>
                <mat-error
                  *ngIf="insuredForm.get('Members')['controls'][i].controls['PortabilityWaiver'].invalid && 
                insuredForm.get('Members')['controls'][i].controls['PortabilityWaiver'].hasError('required')  && formSubmitted">
                  Select Policy Waiver Period.
                </mat-error>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-12 mt-3 mb-3">
          <button mat-raised-button class="primary-button" (click)="saveEditPortability()"
            type="button">Proceed</button>
        </div>
      </div>
    </div>
  </form>
</div>