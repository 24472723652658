<mat-accordion class="example-headers-align" multi>
   <mat-expansion-panel class="customExpansion kycExpansion mb-2 marginBox" [expanded]="true" disabled="true"
      hideToggle>
      <mat-expansion-panel-header>
         <mat-panel-title>
            <h3>Applicant Details</h3>
         </mat-panel-title>
      </mat-expansion-panel-header>
      <app-kyc (changeCustomerType)="changeCustomerType($event)" (getKycDetails)="fetchKycDetails($event)"
         (getExistingCustomerDetails)="fetchExistingCustomerDetails($event)" [isPilotUser]="isPilotUser" [isKYCDoneSuccessfullyForPilotuser]="isKYCDoneSuccessfullyForPilotuser"></app-kyc>
   </mat-expansion-panel>
   <form [formGroup]="applicantForm" *ngIf="isKYCDoneSuccessfully">
      <ng-container>
         <mat-expansion-panel class="customExpansion mb-2" [expanded]="true" disabled="true">
            <mat-expansion-panel-header class="px-0">
               <mat-panel-title>
                  <h3>
                     Kyc Details
                  </h3>
               </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
               <div class="fieldsdiv">
                  <div class="row">
                     <div class="col-sm-3 form-group" *ngIf="isApplicantPanCardNoVisible()">
                        <mat-label class="input-group__label">Pan No.</mat-label>
                        <input type="text" matInput class="input-group__input" id="applicantPanCardNumber" autocomplete="off"
                           formControlName="applicantPanCardNumber" placeholder="Enter pan no" [maxlength]="10" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)">
                        <mat-error
                           *ngIf="applicantForm?.get('applicantPanCardNumber')?.errors?.['required'] && isSubmitted">
                           Pan number is required.
                        </mat-error>
                        <mat-error
                           *ngIf="applicantForm?.get('applicantPanCardNumber')?.errors?.['pattern'] && isSubmitted">
                           Please enter valid pan number.
                        </mat-error>
                     </div>
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label" for="myInput">Title</mat-label>
                        <mat-select class="input-group__input cstm_select" required (click)="handleMatSelectClick()"
                           (blur)="removeMatSelectScrollListener()" formControlName="applicantTitle"
                           placeholder="Select Title" id="applicantTitle">
                           <ng-container *ngFor="let dropDownData of travelModal.title; let last = last">
                              <mat-option value="{{dropDownData.value}}">{{dropDownData.label}}
                              </mat-option>
                              <mat-divider *ngIf="!last"></mat-divider>
                           </ng-container>
                        </mat-select>
                        <mat-error *ngIf="applicantForm?.get('applicantTitle')?.errors?.['required'] && isSubmitted">
                           Title is required.
                        </mat-error>
                     </div>
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Applicant Full Name</mat-label>
                        <input type="text" matInput class="input-group__input" id="applicantFullName" autocomplete="off"
                           formControlName="applicantFullName" placeholder="Enter full name">
                        <mat-error *ngIf="applicantForm?.get('applicantFullName')?.errors?.['required'] && isSubmitted">
                           Applicant Full Name is required.
                        </mat-error>
                     </div>
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">DOB</mat-label>
                        <input class="input-group__input dob" matInput [matDatepicker]="applicantDateOfBirth"
                           id="applicantDateOfBirth" [min]="" [max]="maxDate" (click)="applicantDateOfBirth.open()"
                           placeholder="MM/DD/YYYY" formControlName="applicantDateOfBirth" readonly>
                        <mat-datepicker-toggle matSuffix [for]="applicantDateOfBirth">
                        </mat-datepicker-toggle>
                        <mat-datepicker #applicantDateOfBirth touchUi>
                        </mat-datepicker>
                        <mat-error
                           *ngIf="applicantForm?.get('applicantDateOfBirth')?.errors?.['required'] && isSubmitted">
                           Date of birth is required.
                        </mat-error>
                     </div>
                  </div>
               </div>
            </div>
         </mat-expansion-panel>
      </ng-container>
      <ng-container formGroupName="permanentAddress">
         <mat-expansion-panel class="customExpansion mb-2" [expanded]="true" disabled="true">
            <mat-expansion-panel-header class="px-0">
               <mat-panel-title>
                  <h3>
                     Permanent Address Details
                  </h3>
               </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
               <div class="fieldsdiv">
                  <div class="row">
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Address 01</mat-label>
                        <input type="text" matInput class="input-group__input" id="permanentAddress1"
                           formControlName="permanentAddress1" placeholder="Enter address line 1" autocomplete="off">
                        <mat-error
                           *ngIf="applicantForm?.get('permanentAddress')?.get('permanentAddress1')?.errors?.['required'] && isSubmitted">
                           Address 01 is required.
                        </mat-error>
                     </div>
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Address 02</mat-label>
                        <input type="text" matInput class="input-group__input" id="permanentAddress2"
                           formControlName="permanentAddress2" placeholder="Enter address line 2" autocomplete="off">
                        <mat-error
                           *ngIf="applicantForm?.get('permanentAddress')?.get('permanentAddress2')?.errors?.['required'] && isSubmitted">
                           Address 02 is required.
                        </mat-error>
                     </div>
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Landmark</mat-label>
                        <input type="text" matInput class="input-group__input" id="permanentLandmark"
                           formControlName="permanentLandmark" placeholder="Enter Landmark" autocomplete="off">
                     </div>
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Pincode</mat-label>
                        <input type="text" matInput class="input-group__input health planselect"
                           placeholder="Enter Pincode" formControlName="permanentPincode" id="permanentPincode"
                           [maxlength]="6"
                           (input)="changePincode($any($event.target).value, 'permanentAddress')"autocomplete="off">
                        <mat-error
                           *ngIf="applicantForm?.get('permanentAddress')?.get('permanentPincode')?.errors?.['required'] && isSubmitted">
                           Pincode is required.
                        </mat-error>
                        <mat-error
                           *ngIf="applicantForm?.get('permanentAddress')?.get('permanentPincode')?.errors?.['pattern'] && isSubmitted">
                           Please enter pincode number.
                        </mat-error>
                     </div>
                     <ng-container *ngIf="showPermanentStateCityDetails">
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label">State</mat-label>
                           <input type="text" matInput class="input-group__input" id="permanentState"
                              formControlName="permanentState" placeholder="Enter State" autocomplete="off">
                           <mat-error
                              *ngIf="applicantForm?.get('permanentAddress')?.get('permanentState')?.errors?.['required'] && isSubmitted">
                              State is required.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group"
                           *ngIf="applicantForm.value?.permanentAddress?.permanentCityList?.length">
                           <mat-label class="input-group__label" for="myInput">City</mat-label>
                           <input type="text" matInput class="input-group__input" id="permanentCity" 
                              formControlName="permanentCity" placeholder="Enter City" autocomplete="off" readonly
                              *ngIf="applicantForm.value?.permanentAddress?.permanentCityList?.length == 1">
                           <mat-select class="input-group__input cstm_select" required (click)="handleMatSelectClick()"
                              (blur)="removeMatSelectScrollListener()" formControlName="permanentCity"
                              placeholder="Select City" id="permanentCity"
                              *ngIf="applicantForm.value?.permanentAddress?.permanentCityList?.length > 1">
                              <ng-container
                                 *ngFor="let dropDownData of applicantForm.value.permanentAddress.permanentCityList; let last = last">
                                 <mat-option value="{{dropDownData.label}}">{{dropDownData.label}}
                                 </mat-option>
                                 <mat-divider *ngIf="!last"></mat-divider>
                              </ng-container>
                           </mat-select>
                        </div>
                     </ng-container>
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Email Address</mat-label>
                        <input type="text" matInput class="input-group__input" id="applicantEmailAddress"
                           formControlName="applicantEmailAddress" placeholder="Enter email address" autocomplete="off">
                        <mat-error
                           *ngIf="applicantForm?.get('permanentAddress')?.get('applicantEmailAddress')?.errors?.['required'] && isSubmitted">
                           Email Address is required.
                        </mat-error>
                        <mat-error
                           *ngIf="applicantForm?.get('permanentAddress')?.get('applicantEmailAddress')?.errors?.['pattern'] && isSubmitted">
                           Please enter a valid email address.
                        </mat-error>
                     </div>
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Mobile Number</mat-label>
                        <input type="number" matInput class="input-group__input" id="applicantMobileNumber"
                           formControlName="applicantMobileNumber" placeholder="Enter mobile number" [minlength]="10"
                           [maxlength]="10" autocomplete="off">
                        <mat-error
                           *ngIf="applicantForm?.get('permanentAddress')?.get('applicantMobileNumber')?.errors?.['required'] && isSubmitted">
                           Mobile Number is required.
                        </mat-error>
                        <mat-error
                           *ngIf="applicantForm?.get('permanentAddress')?.get('applicantMobileNumber')?.errors?.['pattern'] && isSubmitted">
                           Please enter a valid mobile number.
                        </mat-error>
                     </div>
                     <div class="col-sm-12 form-group" style="top:17px;padding-left: 0px;">
                        <mat-checkbox class="cstmCheckbox" style="color: #282828;"
                           (change)="changeIsPermanentAddressSame($event)" formControlName="isPermanentAddressSame">
                           Is correspondence address same as permanent address
                        </mat-checkbox>
                     </div>
                  </div>
               </div>
            </div>
         </mat-expansion-panel>
      </ng-container>
      <ng-container formGroupName="correspondenceAddress" *ngIf="!isCorrespondenceAddressVisible">
         <mat-expansion-panel class="customExpansion mb-2" [expanded]="true" disabled="true">
            <mat-expansion-panel-header class="px-0">
               <mat-panel-title>
                  <h3>
                     Correspondence Address Details
                  </h3>
               </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
               <div class="fieldsdiv">
                  <div class="row">
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Address 01</mat-label>
                        <input type="text" matInput class="input-group__input" id="correspondenceAddress1"
                           formControlName="correspondenceAddress1" placeholder="Enter address line 1" autocomplete="off">
                        <mat-error
                           *ngIf="applicantForm?.get('correspondenceAddress')?.get('correspondenceAddress1')?.errors?.['required'] && isSubmitted">
                           Address 01 is required.
                        </mat-error>
                     </div>
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Address 02</mat-label>
                        <input type="text" matInput class="input-group__input" id="correspondenceAddress2"
                           formControlName="correspondenceAddress2" placeholder="Enter address line 2" autocomplete="off">
                        <mat-error
                           *ngIf="applicantForm?.get('correspondenceAddress')?.get('correspondenceAddress2')?.errors?.['required'] && isSubmitted">
                           Address 02 is required.
                        </mat-error>
                     </div>
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Landmark</mat-label>
                        <input type="text" matInput class="input-group__input" id="correspondenceLandmark"
                           formControlName="correspondenceLandmark" placeholder="Enter Landmark">
                     </div>
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Pincode</mat-label>
                        <input type="number" matInput class="input-group__input" id="correspondencePincode"
                           formControlName="correspondencePincode" placeholder="Enter Pincode" [minlength]="6"
                           [maxlength]="6" (input)="changePincode($any($event.target).value, 'correspondenceAddress')"  autocomplete="off">
                        <mat-error
                           *ngIf="applicantForm?.get('correspondenceAddress')?.get('correspondencePincode')?.errors?.['required'] && isSubmitted">
                           Pincode is required.
                        </mat-error>
                        <mat-error
                           *ngIf="applicantForm?.get('correspondenceAddress')?.get('correspondencePincode')?.errors?.['pattern'] && isSubmitted">
                           Please enter pincode number.
                        </mat-error>
                     </div>
                     <ng-container *ngIf="showCorrespondenceStateCityDetails">
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label">State</mat-label>
                           <input type="text" matInput class="input-group__input" id="correspondenceState"
                              formControlName="correspondenceState" placeholder="Enter State"  autocomplete="off" readonly>
                           <mat-error
                              *ngIf="applicantForm?.get('correspondenceAddress')?.get('correspondenceState')?.errors?.['required'] && isSubmitted">
                              State is required.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group"
                           *ngIf="applicantForm.value?.correspondenceAddress?.correspondenceCityList?.length">
                           <mat-label class="input-group__label" for="myInput">City</mat-label>
                           <input type="text" matInput class="input-group__input" id="correspondenceCity" readonly
                              formControlName="correspondenceCity" placeholder="Enter City"  autocomplete="off"
                              *ngIf="applicantForm.value?.correspondenceAddress?.correspondenceCityList?.length == 1">
                           <mat-select class="input-group__input cstm_select" required (click)="handleMatSelectClick()"
                              (blur)="removeMatSelectScrollListener()" formControlName="correspondenceCity"
                              placeholder="Select City" id="correspondenceCity"
                              *ngIf="applicantForm.value?.correspondenceAddress?.correspondenceCityList?.length > 1">
                              <ng-container
                                 *ngFor="let dropDownData of applicantForm.value.correspondenceAddress.correspondenceCityList; let last = last">
                                 <mat-option value="{{dropDownData.label}}">{{dropDownData.label}}
                                 </mat-option>
                                 <mat-divider *ngIf="!last"></mat-divider>
                              </ng-container>
                           </mat-select>
                        </div>
                     </ng-container>
                  </div>
               </div>
            </div>
         </mat-expansion-panel>
      </ng-container>
      <ng-container formGroupName="otherDetails">
         <mat-expansion-panel class="customExpansion mb-2" [expanded]="true" disabled="true">
            <mat-expansion-panel-header class="px-0">
               <mat-panel-title>
                  <h3>
                     Other Details
                  </h3>
               </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
               <div class="fieldsdiv">
                  <div class="row">

                     <div class="col-sm-3 form-group">
                        <label class="singlelabel width100 mb-2"> Are you registered with GST?</label>
                        <div class=" group_btn">
                           <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="custom-toggle-group"
                              formControlName="isGstRegistered" (change)="changeAreRegisteredWithGST($event.value)">
                              <mat-button-toggle class="custom_togglebtn" id="isGstRegistered"
                                 *ngFor="let radioData of travelModal.toggleOptions; let j = index" [value]="radioData">
                                 {{ radioData }}
                              </mat-button-toggle>
                           </mat-button-toggle-group>
                           <mat-error
                              *ngIf="applicantForm?.get('otherDetails')?.get('isGstRegistered')?.errors?.['required'] && isSubmitted">
                              Please select are you registered with GST.
                           </mat-error>
                        </div>
                     </div>
                     <ng-container *ngIf="applicantForm?.controls['otherDetails'].value.isGstRegistered == 'Yes'">
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label" for="myInput">Constitution of Business</mat-label>
                           <mat-select class="input-group__input cstm_select" required (click)="handleMatSelectClick()"
                              (blur)="removeMatSelectScrollListener()" formControlName="constitutionOfBusiness"
                              placeholder="Select Business" id="constitutionOfBusiness">
                              <ng-container *ngFor="let dropDownData of travelModal.businessData; let last = last">
                                 <mat-option value="{{dropDownData.label.toUpperCase()}}">{{dropDownData.label}}
                                 </mat-option>
                                 <mat-divider *ngIf="!last"></mat-divider>
                              </ng-container>
                           </mat-select>
                           <mat-error
                              *ngIf="applicantForm?.get('otherDetails')?.get('constitutionOfBusiness')?.errors?.['required'] && isSubmitted">
                              Constitution of Business is required.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label">Pan No</mat-label>
                           <input type="text" matInput class="input-group__input" id="" formControlName="panNumber" autocomplete="off"
                              placeholder="Enter Pan Number" [maxlength]="10" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)">
                           <mat-error
                              *ngIf="applicantForm?.get('otherDetails')?.get('panNumber')?.errors?.['required'] && isSubmitted">
                              Pan number is required.
                           </mat-error>
                           <mat-error
                              *ngIf="applicantForm?.get('otherDetails')?.get('panNumber')?.errors?.['pattern'] && isSubmitted">
                              Please enter valid pan number.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label" for="myInput">Customer Type</mat-label>
                           <mat-select class="input-group__input cstm_select" required (click)="handleMatSelectClick()"
                              (blur)="removeMatSelectScrollListener()" formControlName="customerType"
                              placeholder="Select Customer Type" id="customerType">
                              <ng-container *ngFor="let dropDownData of travelModal.customerTypeData; let last = last">
                                 <mat-option value="{{dropDownData.label.toUpperCase()}}">{{dropDownData.label}}
                                 </mat-option>
                                 <mat-divider *ngIf="!last"></mat-divider>
                              </ng-container>
                           </mat-select>
                           <mat-error
                              *ngIf="applicantForm?.get('otherDetails')?.get('customerType')?.errors?.['required'] && isSubmitted">
                              GST Registration Status is required.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label" for="myInput">GST Registration Status</mat-label>
                           <mat-select class="input-group__input cstm_select" required (click)="handleMatSelectClick()"
                              (blur)="removeMatSelectScrollListener()" formControlName="gstStatus"
                              placeholder="Select Status" id="gstStatus">
                              <ng-container *ngFor="let dropDownData of travelModal.gstStatusData; let last = last">
                                 <mat-option value="{{dropDownData.label.toUpperCase()}}">{{dropDownData.label }}
                                 </mat-option>
                                 <mat-divider *ngIf="!last"></mat-divider>
                              </ng-container>
                           </mat-select>
                           <mat-error
                              *ngIf="applicantForm?.get('otherDetails')?.get('gstStatus')?.errors?.['required'] && isSubmitted">
                              GST Registration Status is required.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group">
                           <label class="singlelabel width100 mb-2"> Is GSTIN or UIN applicable ?</label>
                           <div class=" group_btn">
                              <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                 class="custom-toggle-group" formControlName="isGstApplicable"
                                 (change)="changeIsGstApplicable($event.value)">
                                 <mat-button-toggle class="custom_togglebtn" id="isGstApplicable"
                                    *ngFor="let radioData of travelModal.gstOptions; let j = index" [value]="radioData">
                                    {{radioData}}
                                 </mat-button-toggle>
                              </mat-button-toggle-group>
                           </div>
                        </div>
                        <div class="col-sm-3 form-group"
                           *ngIf="applicantForm?.get('otherDetails')?.get('isGstApplicable')?.value =='GSTIN'">
                           <mat-label class="input-group__label">GSTIN</mat-label>
                           <input type="text" matInput class="input-group__input" id="gstin" formControlName="gstin"
                              placeholder="Enter GSTIN" autocomplete="off">
                           <mat-error
                              *ngIf="applicantForm?.get('otherDetails')?.get('gstin')?.errors?.['required'] && isSubmitted">
                              GSTIN number is required.
                           </mat-error>
                           <mat-error
                              *ngIf="applicantForm?.get('otherDetails')?.get('gstin')?.errors?.['pattern'] && isSubmitted">
                              Please enter valid GSTIN number.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group"
                           *ngIf="applicantForm?.get('otherDetails')?.get('isGstApplicable')?.value != 'GSTIN'">
                           <mat-label class="input-group__label">UIN</mat-label>
                           <input type="text" matInput class="input-group__input" id="uin" formControlName="uin"
                              placeholder="Enter UIN" autocomplete="off">
                           <mat-error
                              *ngIf="applicantForm?.get('otherDetails')?.get('uin')?.errors?.['required'] && isSubmitted">
                              UIN number is required.
                           </mat-error>
                           <mat-error
                              *ngIf="applicantForm?.get('otherDetails')?.get('gstin')?.errors?.['pattern'] && isSubmitted">
                              Please enter valid UIN number.
                           </mat-error>
                        </div>
                     </ng-container>
                  </div>
               </div>
            </div>
         </mat-expansion-panel>
      </ng-container>
      <ng-container *ngIf="travelFormData?.quoteFormData?.productName == 'Student Travel'">
         <ng-container formGroupName="universityDetails">
            <mat-expansion-panel class="customExpansion mb-2" [expanded]="true" disabled="true">
               <mat-expansion-panel-header class="px-0">
                  <mat-panel-title>
                     <h3>
                        Overseas College/University Details
                     </h3>
                  </mat-panel-title>
               </mat-expansion-panel-header>
               <div>
                  <div class="fieldsdiv">
                     <div class="row">
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label">Name of the Educational Institution</mat-label>
                           <input type="text" matInput class="input-group__input" id="instituteName"
                              formControlName="instituteName" placeholder="Enter name of the educational institution"
                              (input)="validationCheck($event,true,false)" autocomplete="off">
                           <mat-error
                              *ngIf="applicantForm?.get('universityDetails')?.get('instituteName')?.errors?.['required'] && isSubmitted">
                              Name of the Educational Institution is required.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label">Address 01</mat-label>
                           <input type="text" matInput class="input-group__input" id="instituteAddress1"
                              formControlName="instituteAddress1" placeholder="Enter address line 1" autocomplete="off"
                              (input)="validationCheck($event,false,false)">
                           <mat-error
                              *ngIf="applicantForm?.get('universityDetails')?.get('instituteAddress1')?.errors?.['required'] && isSubmitted">
                              Address 01 is required.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label">Address 02</mat-label>
                           <input type="text" matInput class="input-group__input" id="instituteAddress2"
                              formControlName="instituteAddress2" placeholder="Enter address line 2" autocomplete="off"
                              (input)="validationCheck($event,false,false)">
                           <mat-error
                              *ngIf="applicantForm?.get('universityDetails')?.get('instituteAddress2')?.errors?.['required'] && isSubmitted">
                              Address 02 is required.
                           </mat-error>

                        </div>
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label" for="myInput">Country</mat-label>
                           <mat-select class="input-group__input cstm_select" required (click)="handleMatSelectClick()"
                              (blur)="removeMatSelectScrollListener()" formControlName="instituteCountry"
                              placeholder="Select Country" id="instituteCountry">
                              <ng-container
                                 *ngFor="let dropDownData of applicantForm.get('universityDetails')?.get('instituteCountryList').value; let last = last">
                                 <mat-option value="{{dropDownData.label}}">{{dropDownData.label}}
                                 </mat-option>
                                 <mat-divider *ngIf="!last"></mat-divider>
                              </ng-container>
                           </mat-select>
                           <mat-error
                              *ngIf="applicantForm?.get('universityDetails')?.get('instituteCountry')?.errors?.['required'] && isSubmitted">
                              Country is required.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label">Pincode</mat-label>
                           <input type="text" matInput class="input-group__input health planselect"  autocomplete="off"
                              placeholder="Enter Pincode" formControlName="institutePincode" id="institutePincode"
                               >
                           <mat-error
                              *ngIf="applicantForm?.get('universityDetails')?.get('institutePincode')?.errors?.['required'] && isSubmitted">
                              Pincode is required.
                           </mat-error>
                           <mat-error
                              *ngIf="applicantForm?.get('universityDetails')?.get('institutePincode')?.errors?.['pattern'] && isSubmitted">
                              Please enter valid pincode number.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label">State</mat-label>
                           <input type="text" matInput class="input-group__input" id="instituteState"
                              formControlName="instituteState" placeholder="Enter State"
                              (input)="validationCheck($event,true,false)" autocomplete="off">
                           <mat-error
                              *ngIf="applicantForm?.get('universityDetails')?.get('instituteState')?.errors?.['required'] && isSubmitted">
                              State is required.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label">City</mat-label>
                           <input type="text" matInput class="input-group__input" id="instituteCity"
                              formControlName="instituteCity" placeholder="Enter city"
                              (input)="validationCheck($event,true,false)" autocomplete="off">
                           <mat-error
                              *ngIf="applicantForm?.get('universityDetails')?.get('instituteCity')?.errors?.['required'] && isSubmitted">
                              City is required.
                           </mat-error>
                        </div>
                     </div>
                  </div>
               </div>
            </mat-expansion-panel>
         </ng-container>
         <ng-container formGroupName="sponsorDetails">
            <mat-expansion-panel class="customExpansion mb-2" [expanded]="true" disabled="true">
               <mat-expansion-panel-header class="px-0">
                  <mat-panel-title>
                     <h3>
                        Sponsor Details
                     </h3>
                  </mat-panel-title>
               </mat-expansion-panel-header>
               <div>
                  <div class="fieldsdiv">
                     <div class="row">
                        <div class="col-sm-3 form-group">
                           <label class="singlelabel width100 mb-2"> Type of Sponsor</label>
                           <div class=" group_btn">
                              <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                 class="policyToggle_group" formControlName="sponsorType"
                                 (change)="changeSponsorType($event.value)">
                                 <mat-button-toggle class="PolicyBtn"
                                    *ngFor="let radioData of travelModal.sponsorType; let j = index"
                                    [value]="radioData">
                                    {{ radioData }}
                                 </mat-button-toggle>
                              </mat-button-toggle-group>
                           </div>
                        </div>
                        <div class="col-sm-3 form-group"
                           *ngIf="applicantForm.get('sponsorDetails')?.get('sponsorType')?.value == 'Individual'">
                           <mat-label class="input-group__label" for="myInput">Title</mat-label>
                           <mat-select class="input-group__input cstm_select" required (click)="handleMatSelectClick()"
                              (blur)="removeMatSelectScrollListener()" formControlName="sponsorTitle"
                              placeholder="Select Title" id="sponsorTitle">
                              <ng-container *ngFor="let dropDownData of travelModal.title; let last = last">
                                 <mat-option value="{{dropDownData.label}}">{{dropDownData.label}}
                                 </mat-option>
                                 <mat-divider *ngIf="!last"></mat-divider>
                              </ng-container>
                           </mat-select>
                           <mat-error
                              *ngIf="applicantForm.get('sponsorDetails')?.get('sponsorTitle')?.errors?.['required'] && isSubmitted">
                              Sponsor title is required.
                           </mat-error>
                        </div>
                        <div class="col-sm-3 form-group">
                           <mat-label class="input-group__label">Name of Sponsor</mat-label>
                           <input type="text" matInput class="input-group__input" id="sponsorName"
                              formControlName="sponsorName" placeholder="Enter sponsor name"
                              (input)="validationCheck($event,true,false)" autocomplete="off">
                           <mat-error
                              *ngIf="applicantForm.get('sponsorDetails')?.get('sponsorName')?.errors?.['required'] && isSubmitted">
                              Sponsor name is required.
                           </mat-error>
                        </div>
                     </div>
                  </div>
               </div>
            </mat-expansion-panel>
         </ng-container>
         <ng-container formGroupName="nomineeDetails">
            <mat-expansion-panel class="customExpansion mb-2" [expanded]="true" disabled="true">
               <mat-expansion-panel-header class="px-0">
                  <mat-panel-title>
                     <h3>
                        Nominee Details
                     </h3>
                  </mat-panel-title>
               </mat-expansion-panel-header>
               <div>
                  <div class="fieldsdiv">
                     <div class="row">
                        <div class="col-sm-3 form-group">
                           <label class="singlelabel width100 mb-2"> Is the nominee same as the Sponsor mentioned
                              above</label>
                           <div class=" group_btn">
                              <mat-button-toggle-group formControlName="isNomineeSameAsSponsor" name="fontStyle"
                                 aria-label="Font Style" class="policyToggle_group"
                                 (change)="changeIsNomineeSameAsSponsor($event.value)">
                                 <mat-button-toggle class="PolicyBtn"
                                    *ngFor="let radioData of travelModal.toggleOptions; let j = index"
                                    [value]="radioData">
                                    {{ radioData }}
                                 </mat-button-toggle>
                              </mat-button-toggle-group>
                           </div>
                        </div>
                        <ng-container
                           *ngIf="applicantForm.get('nomineeDetails')?.get('isNomineeSameAsSponsor')?.value == 'No'">
                           <div class="col-sm-3 form-group">
                              <mat-label class="input-group__label" for="myInput">Title</mat-label>
                              <mat-select class="input-group__input cstm_select" required
                                 (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                 formControlName="nomineeTitle" placeholder="Select Title" id="nomineeTitle">
                                 <ng-container *ngFor="let dropDownData of travelModal.title; let last = last">
                                    <mat-option value="{{dropDownData.label}}">{{dropDownData.label}}
                                    </mat-option>
                                    <mat-divider *ngIf="!last"></mat-divider>
                                 </ng-container>
                              </mat-select>
                              <mat-error
                                 *ngIf="applicantForm.get('nomineeDetails')?.get('nomineeTitle')?.errors?.['required'] && isSubmitted">
                                 Nominee title is required.
                              </mat-error>
                           </div>
                           <div class="col-sm-3 form-group">
                              <mat-label class="input-group__label">Name of Nominee</mat-label>
                              <input type="text" matInput class="input-group__input" id="nomineeName"
                                 formControlName="nomineeName" placeholder="Enter nominee name" autocomplete="off"
                                 (input)="validationCheck($event,true,false)">
                              <mat-error
                                 *ngIf="applicantForm.get('nomineeDetails')?.get('nomineeName')?.errors?.['required'] && isSubmitted">
                                 Nominee name is required.
                              </mat-error>
                           </div>
                        </ng-container>
                     </div>
                  </div>
               </div>
            </mat-expansion-panel>
         </ng-container>
      </ng-container>
   </form>
</mat-accordion>

<app-custom-footer [normalButton]="true" [buttonData]="[
{'label': 'Submit', 'type': 'primary', 'source': 'kyc', 'id': 'f4','disabled': false , 'hide': isKYCDoneSuccessfully ? false: true }
]" (buttonClicked)="kycButtonClicked($event)">
</app-custom-footer>