import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, finalize, tap, throwError } from 'rxjs';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { StorageService } from '../services/storage.service';
import { userEnums } from '../enums/userEnums';
import { UtilityService } from '../services/utility.service';
import { TracingService } from '../services/tracing.service';
import { Span, SpanStatusCode, context, trace } from '@opentelemetry/api';

@Injectable()
export class ApiRequestInterceptorInterceptor implements HttpInterceptor {

  orionProductJourneys = {
    startAPIs: ['/health/OrionHealth/SaveEditQuote'],
    journeyAPIs: ['/health/OrionHealth/SaveEditProposal']
  };


  constructor(
    private encryptDecryptService: EncryptDecryptService,
    private storageService: StorageService,
    private utility_servics: UtilityService,
    private tracingService: TracingService
  ) {

  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {

    const req = this.getHeaders(request);
    const reqCloned = this.handleRequestBody(req);

    if (this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) === 'true') {
      let traceId: any ;
      let isStartURL: boolean = false;
      let otherURL: boolean = true;

      /**
       * Managing trace IDs based on journeys
       */
      for (const startURL of this.orionProductJourneys.startAPIs) {
        if (request.url.includes(startURL)) {
          traceId = this.tracingService.generateTraceIdStoreLS('orionTransactionJourney');
          isStartURL = true;
          otherURL = false;
          break;
        }
      };

      if (!isStartURL) {
        for (const journeyURL of this.orionProductJourneys.journeyAPIs) {
          if (request.url.includes(journeyURL)) {
            traceId = this.tracingService.getTraceIdLS('orionTransactionJourney');
            otherURL = false;
            break;
          }
        };
      }

      if (otherURL) {
        traceId = this.tracingService.generateTraceId();
      }

      /**
       * Creating spans for EVERY API call
       */
      const span: Span = this.tracingService.startSpan(
        `HTTP ${request.method} ${request.url}`
      );

      let spanId = span.spanContext().spanId;
      let traceparent = `00-${traceId}-${spanId}-01`;

      request = reqCloned.clone({
        setHeaders: {
          accept: '*/*',
          'Access-Control-Allow-Origin': 'true',
          traceparent: traceparent,
          'Uber-Trace-Id': `${traceId}:${spanId}:0:01`,
          'X-B3-Spanid': `${spanId}`,
          'X-B3-Traceid': `${traceId}`,
        },
      });

      return context.with(trace.setSpan(context.active(), span), () => {
        return next.handle(request).pipe(
          tap({
            next: (event: HttpEvent<any>) => { },
            error: (error: HttpErrorResponse) => {
              // Set span status to ERROR for any HTTP error
              this.tracingService.endSpan(
                span,
                SpanStatusCode.ERROR,
                error.message
              );
            },
          }),
          finalize(() => {
            this.tracingService.endSpan(span, SpanStatusCode.OK);
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(() => new Error(error.message));
          })
        );
      });
    } else {
      return next.handle(reqCloned);
    }

  }

  getHeaders(request) {
    let uid =  'Uowkjsdjssrme';
    let url = request.url.toLowerCase();
    let req;
    if (url.includes('viewnotificationhistory') || url.includes('savetoken')) {
      let fcmauthtoken = this.storageService.getAESDecryptedDataLocal('FCMAuthtoken');
      req = request.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': "Bearer " + fcmauthtoken,
          'Cache-Control': 'no-cache',
        })
      });
    }
    else {
      req = request.clone({
        // 'Content-Type': "application/json",
        'Authorization': '',
        'X-Frame-Options': 'DENY',
      });

      let authToken = this.storageService.getAESDecryptedData(userEnums.AuthToken);
      let userName = this.storageService.getAESDecryptedData(userEnums.UopqksmlN);
      if (userName) {
        req = request.clone({
          setHeaders: {
            // 'Content-Type': "application/json",
            'Authorization': authToken ? authToken : '',
            'Access-Control-Allow-Methods': 'GET,POST',
            [`${uid}`]: this.encryptDecryptService.finalRsaEncryption(userName),
            'AppName': this.encryptDecryptService.finalRsaEncryption('critishield'),
            'SessionReference': this.storageService.getAESDecryptedData(userEnums.SessionReference),
          }
        })
      }
      if(!req.url.includes('DmsDocUpload'))
      req = request.clone({
        setHeaders: {
          'DTksqwtx': this.utility_servics.getCurrentDateTime()
        }
      })
    }
    return req;
  }

  handleRequestBody(req: HttpRequest<any>) {
    let url;
    url = req.url.replace(environment.baseURL, "");
    if (req.body) {
      let result = req.body instanceof FormData;
      if (result === false) {
        if (req.method.toLowerCase() === 'post') {
          let isReqArray = Array.isArray(req.body);
          let reqObjectType = typeof (req.body);
          if (isReqArray || reqObjectType === "object") {
            if (url.includes('Intranet') || url.includes('plutus')) {
              req = req.clone(req.body);
            }
            else {
              let body = {
                "Data": req.body,
                "DateTime": this.utility_servics.getCurrentDateTime()
              }
              req = req.clone({
                body: { "Data": this.encryptDecryptService.finalRsaEncryption(body) }
              })
            }
          } else {
            let body = {
              "Data": JSON.parse(req.body),
              "DateTime": this.utility_servics.getCurrentDateTime()
            }
            req = req.clone({
              body: { "Data": (this.encryptDecryptService.finalRsaEncryption(body)) }
            })
          }
        } else if (req.method.toLowerCase() === 'get') {
          let index = req.url.indexOf('?');
          if (index !== -1) {
            let domainName = req.url.substring(0, index);
            let parameters = req.url.substring(index + 1);
            if (parameters.length > 0) {
              req = req.clone({
                url: domainName + "?Data=" + this.encryptDecryptService.finalRsaEncryption(parameters),
              });
            } 
            // else {
            //   req = req.clone({
            //     url: domainName + "?Data=" + this.encryptDecryptService.finalRsaEncryption(parameters),
            //   });
            // }
          }
        }
      }
    }
    return req;
  }
}
