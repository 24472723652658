<div class="container" *ngIf="loadErrorPage">
  <div class="col-sm-12">
    <div class="errorDiv">
      <img src="../../../assets/images/error.gif" alt="" />
      <span class="mb-3">Oops! your page could not be found. But now that you've landed here, take a deep breath and
        relax.</span>
      <button class="secondarybtn_2 popupbtn" style="min-width: 219px" (click)="refreshEmitted()">
        Refresh
      </button>
    </div>
  </div>
</div>