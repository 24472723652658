import { Component, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Subscription, fromEvent } from 'rxjs';
import { PopupComponent } from 'src/app/layout/popups/popup.component';
import { HealthDataStorage, MyPolicyDetails } from 'src/app/modules/health/health.modal';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { Constants } from 'src/app/shared/constants/constants';
import { popupType } from 'src/app/shared/enums/popupEnums';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { EndorsedPolicyDetails, EndorsedPolicyDownloadRequest, FetchEndoredPolicyRequest } from 'src/app/shared/interface/endorsement';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { DownloadService } from 'src/app/shared/services/download.service';
import { EndorsementService } from 'src/app/shared/services/endorsement.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-nil-endorsement',
  templateUrl: './nil-endorsement.component.html',
  styleUrls: ['./nil-endorsement.component.scss']
})
export class NilEndorsementComponent {
  private interval: any;
  scrolling: boolean = false;
  isSearchVisible: boolean = false;
  toggleValue: 'table' | 'card' = 'card';
  policyFilter: string[] = [];
  policyFilterList = ['Policy', 'Cancelled', 'Pending for approval'];
  inputValue: string = '';
  showCancel: boolean = false;
  searchPlaceHolder: string;
  isSelected: boolean = false;
  isSubmitted: boolean = false;
  reportList: any = [];
  selectList = [];
  i: number = 0;
  phText: string[] = [
    'Search for Customer Name',
    'Search for Policy Number'
  ];
  filteredData: MyPolicyDetails[] = [];
  nilEndorsementForm: FormGroup;
  subscription: Subscription[] = [];
  policyDetails: any = [];
  selectedProduct: string = 'Health';
  productList = [];
  minMax: { maxDate: Date; minDate: Date; };
  healthFormData: HealthDataStorage;
  policyCount: number;
  myPolicyStartDate: Date;
  myPolicyEndDate: Date;
  myPolicyProduct: string;


  tableBtnInfo = [
    // {
      // label: 'Download Policy',
    //   imgPath: '../../../../../../../assets/images/BBR/download.svg',
    //   tooltip: 'Download Policy',
    // },
  ];

  cardBtnInfo = [
    // {
    //   label: 'Download Policy',
    //   imgPath: '../../../assets/images/myPolicy/downloadPolicy.svg',
    // }
  ];

  displayCol = [
    {
      columnDef: 'CustomerName',
      header: 'Customer Name',
      cell: (element: any) => `${element.CustomerName}`,
    },
    {
      columnDef: 'NewPolicyNo',
      header: 'New Policy Number',
      cell: (element: any) => `${element.NewPolicyNo}`,
      label: 'Download Policy'
    },
    {
      columnDef: 'OldPolicyNo',
      header: 'Old Policy Number',
      cell: (element: any) => `${element.OldPolicyNo}`,
    },
    {
      columnDef: 'EndorsementType',
      header: 'Endorsement Type',
      cell: (element: any) => `${element.EndorsementType}`,
    },
    {
      columnDef: 'Created_on',
      header: 'Created On',
      cell: (element: any) => `${element.Created_on}`,
    },
    // {
    //   columnDef: 'Action',
    //   header: 'Action',
    //   cell: '',
    // },
  ]

  constructor(
    private downloadService: DownloadService,
    private storageService: StorageService,
    public utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private healthDataStorageService: HealthDataStorageService,
    private renderer: Renderer2,
    private endorsementService: EndorsementService,
    private agentV1Service: AgentV1Service,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
    this.getProductList();
    this.createForm();
    this.setCalendarDates();
    this.setProductType();
    this.searchDetails();
    this.setPlaceHolderText();
    this.interval = setInterval(() => { this.setPlaceHolderText(); }, 2000);
  }

  setProductType() {
    if (sessionStorage.getItem(userEnums.AgentProductMap)) {
      const products = this.storageService.getAESDecryptedData(userEnums.AgentProductMap);
      // this.nilEndorsementForm.controls['productType'].setValue(products[0].productCode);
      if (products[0].productCode != 4) {
        this.nilEndorsementForm.controls['productName'].setValue(products[0].productList[0].productCode);
      }
      this.setSelectList(products[0].productCode);
    } else {
      this.productListListner('2');
    }
  }

  onWindowScroll() {
    this.scrolling = true;

    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  setCalendarDates() {
    let minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10));
    let maxDate = new Date(new Date().setFullYear(new Date().getFullYear())); //removing +1
    this.minMax = { maxDate: maxDate, minDate: minDate }
  }

  getProductList() {
    this.productList = this.agentV1Service.getAllProducts('myPolicy');
  }

  setSelectList(option) {
    for (let i of this.productList) {
      if (i.productCode == option) {
        this.selectList = i.productList
      }
    }
  }

  productListListner(option) {
    for (let i of this.productList) {
      if (i.productCode == option) {
        this.selectList = i.productList
        if (i.productCode != 4) {
          this.nilEndorsementForm.controls['productName'].setValue(this.selectList[0].productCode);
        }
      }
    }
  }

  createForm() {
    let startDate = this.utilityService.subtractDays(new Date(), 15);
    this.nilEndorsementForm = this.formBuilder.group({
      productType: ['Health', [Validators.required]],
      productName: [42, [Validators.required]],
      startDate: [startDate, [Validators.required]],
      endDate: [new Date(), [Validators.required]],
    })
    this.patchBanner();
  }

  onClick(type) {
    this.policyDetails = [];
    this.selectedProduct = type.tab.textLabel;
    this.searchDetails();
  }

  patchBanner() {
    this.myPolicyStartDate = this.nilEndorsementForm.value.startDate;
    this.myPolicyEndDate = this.nilEndorsementForm.value.endDate;
    // this.myPolicyProduct = this.getProductNameFromCode(this.nilEndorsementForm.value.productType);
    this.myPolicyProduct = this.nilEndorsementForm.value.productType;
  }

  getProductNameFromCode(x: number) {
    if (x == 2) {
      return 'Health'
    } else if (x == 3) {
      return 'Travel'
    } else if (x == 4) {
      return 'Home'
    }
    return '';
  }


  formatDate(date) {
    let newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, '0');
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const year = newDate.getFullYear();
    const formattedDate = `${year}-${month}-${day}T00:00:00Z`;
    return formattedDate;
  }

  searchDetails() {
    this.clearInputField();
    const startDate = this.formatDate(this.nilEndorsementForm.get('startDate').value);
    const endDate = this.formatDate(this.nilEndorsementForm.get('endDate').value);
    let body: FetchEndoredPolicyRequest = {
      endDate: endDate,
      policy_SubType: this.nilEndorsementForm.get('productName').value,
      policy_Type: '2',
      startDate: startDate
    };
    this.endorsementService.fetchEndorsedPolicyData(body).subscribe((response) => {
      if (response.StatusCode === Constants.statusCode_success) {
        this.policyDetails = response.Data;
        this.policyDetails = this.policyDetails.map((data) => {
          return {
            ...data,
            EndorsementType: this.utilityService.removeUnderScore(data.EndorsementType)
          }
        })
        this.filteredData = this.policyDetails;
        this.policyCount = this.policyDetails.length;
        this.patchBanner();
      } else {
        this.policyCount = 0;
        this.policyDetails = [];
        this.filteredData = [];
      }
    })
  }

  receiveDataAndCall(event) {
    if (event.identifier == 'Download Policy') {
      this.downloadPolicy(event.data);
    }
  }

  downloadPolicy(data: EndorsedPolicyDetails) {
    let payload: EndorsedPolicyDownloadRequest = {
      PolicyNo: data.NewPolicyNo,
      customerId: data.Customer_id
    };
    this.endorsementService.downloadEndorsePolicy(payload).subscribe((event) => {
      let filename = data.NewPolicyNo + ".pdf";
      this.downloadService.downloadPdf(event.Data, filename);
    })
  }

  generatePdf(params, name) {
    this.subscription.push(this.downloadService.generatePDF(params.endPoint, params.body).subscribe({
      next: (response) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.downloadService.downloadPdf(response.Data, name)
        }
      }
    })
    )
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  handleMatSelectClick() {
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  editImage: string = 'assets/images/BBR/Edit.svg'; // Initial image

  openSearchBottomSheet() {
    if (this.editImage === 'assets/images/BBR/Edit.svg') {
      this.editImage = 'assets/images/BBR/EditActive.svg';
    } else {
      this.editImage = 'assets/images/BBR/Edit.svg';
    }
    // this.nilEndorsementForm.value.productType
    const x = this.productList.filter(item => item.productCode == '2');
    const y = x[0].productList;
    const searchBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      // height: '50%',
      disableClose: true,
      data: {
        popupType: popupType.search,
        data: {
          controls: [
            {
              type: 'input',
              label: 'Product Type',
              readonly: true,
              value: this.nilEndorsementForm.value.productType
            },
            {
              type: 'myDependentDropdown',
              label: 'Product Name',
              list: y,
              value: this.nilEndorsementForm.value.productName
            },
            {
              type: 'rangepicker',
              label: 'Select date range',
              startDate: this.nilEndorsementForm.value.startDate,
              endDate: this.nilEndorsementForm.value.endDate,
            }],
          dates: {
            minDate: this.minMax.minDate,
            maxDate: this.minMax.maxDate,
          },
        }
      }
    }
    );
    // searchBottomSheet.afterClosed().subscribe((data) => this.patchSearchParameters(data));
    searchBottomSheet.afterClosed().subscribe((data) => {
      this.editImage = 'assets/images/BBR/Edit.svg';
      this.patchSearchParameters(data);
    });
  }

  patchSearchParameters(data) {
    if (data) {
      for (let i of data) {
        // if (i.type == 'myDropdown') {
        //   this.nilEndorsementForm.controls['productType'].setValue(i.value);
        // }
        if (i.type == 'myDependentDropdown' && i.value) {
          this.nilEndorsementForm.controls['productName'].setValue(i.value);
        }
        else if (i.type == 'rangepicker') {
          this.nilEndorsementForm.controls['startDate'].setValue(i.startDate);
          this.nilEndorsementForm.controls['endDate'].setValue(i.endDate);
        }
      }
      this.searchDetails();
    }
  }

  searchFilter() {
    this.isSearchVisible = true;
  }

  onToggle(event) {
    this.toggleValue = event.value;
  }

  applyPolicyFilter(isChecked: boolean, value: string) {
    value = value.toLowerCase();
    if (isChecked) {
      if (!this.policyFilter.includes(value)) {
        this.policyFilter.push(value);
      }
    } else {
      const index = this.policyFilter.indexOf(value);
      if (index >= 0) {
        this.policyFilter.splice(index, 1);
      }
    }
    this.applyFilter();
  }

  filterImage: string = 'assets/images/BBR/FilterMobile.svg'; // Initial image

  openFilterBottomSheet() {
    if (this.filterImage === 'assets/images/BBR/FilterMobile.svg') {
      this.filterImage = 'assets/images/BBR/FilterActive.svg';
    } else {
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
    }
    const filterBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      // height: '44%',
      disableClose: true,
      data: {
        popupType: popupType.filter,
        data: [
          {
            label: 'Status Type',
            list: this.policyFilterList,
            filter: this.policyFilter
          }]
      },
    }
    );
    filterBottomSheet.afterClosed().subscribe((data) => {
      // Reset the image back to FilterMobile.svg
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
      this.patchFilterParameters(data);
    });
  }

  patchFilterParameters(data) {
    if (data) {
      this.policyFilter = data[0].filter;
    }
    this.applyFilter();
  }

  clearInputField() {
    this.inputValue = '';
    this.queryData();
  }

  applyFilter() {
    this.clearInputField();
    this.filterLogic();
  }

  setPlaceHolderText() {
    this.searchPlaceHolder = this.phText[this.i];
    this.i++;
    if (this.i == this.phText.length) {
      this.i = 0;
    }
  }

  inputFocus() {
    this.showCancel = true;
  }

  inputBlur() {
    this.showCancel = false;
  }

  closeClear() {
    this.isSearchVisible = false;
    this.clearInputField();
  }

  filterLogic() {
    if (!(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.policyDetails.filter(item => this.policyFilter.includes(item['Status'].toLowerCase()))
    } else {
      this.filteredData = this.policyDetails;
    }
  }

  queryData() {
    const query = this.inputValue.trim();
    if (!query && this.utilityService.isEmptyOrNull(this.policyFilter)) {
      this.filteredData = this.policyDetails;
    }
    else if (!query && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filterLogic();
    }
    else if (query && this.utilityService.isEmptyOrNull(this.policyFilter)) {
      this.filteredData = this.policyDetails.filter(item => {
        // const customerName = item['CustomerName'] ? item['CustomerName'].toString().toLowerCase() : '';
        // return customerName.includes(query.toLowerCase())
        return Object.values(item).some(val =>
          val.toString().toLowerCase().includes(query.toLowerCase())
        );
      });
    }
    else if (query && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.filteredData.filter(item => {
        // const customerName = item['CustomerName'] ? item['CustomerName'].toString().toLowerCase() : '';
        // return customerName.includes(query.toLowerCase())
        return Object.values(item).some(val =>
          val.toString().toLowerCase().includes(query.toLowerCase())
        );
      });
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    window.removeEventListener('scroll', this.onWindowScroll.bind(this));
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
