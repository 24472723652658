import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GenericService } from '../services/generic.service';
import { SaveEditProposalV1RequestPayload, SaveEditQuoteV1ResponsePayload } from '../interface/health-booster';
import { SaveEditQuoteV1Request } from '../interface/transactionHealthAdvantege';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HBoosterService {

  constructor(private generic: GenericService) { }

  readonly baseURI = `${environment.baseURL}HBooster/`;
  readonly SaveEditQuoteV1 = 'SaveEditQuoteV1';
  readonly SaveEditProposal = 'SaveEditProposalV1';

  saveEditQuoteV1(data: SaveEditQuoteV1Request): Observable<any> {
    const endpoint = `${this.baseURI}${this.SaveEditQuoteV1}`;
    return this.generic.genericService<SaveEditQuoteV1ResponsePayload>(endpoint, data);
  }

  SaveEditProposalV1(data: SaveEditProposalV1RequestPayload): Observable<any> {
    const endpoint = `${this.baseURI}${this.SaveEditQuoteV1}`;
    return this.generic.genericService<SaveEditQuoteV1ResponsePayload>(endpoint, data);
  }
}
