// This hap modal should include only those data w.r.t hap

export class HEPModal {
    productType = ['New', 'Portability'];
    options = ['Yes', 'No'];
    copaymentPercentTaken =['No', '5%','10%','15%', '20%']
    tenurePeriod = ['1 Year', '2 Years', '3 Years'];
    emiInstallments = ['Monthly', 'Quarterly', 'Half Yearly']
    zoneCode = '3828';
    zoneData = ['ZONE 1', 'ZONE 2', 'ZONE 3'];
    hapPolicyRange = ['5L-50L', '75L-3Cr'];
    yes = 'Yes';
    no = 'No';
    maxSumInsured = 2500000;
    agent = 'Agent';
    subProductType = {
        hap:42,
        hep:33
    };
    volDedutible = 0;
    ipartnerMsite = 'NYSAHEALTH';
    riskCategory = [{
        "key": "Risk Category 1"
    },
    {
        "key": "Risk Category 2"
    },
    {
        "key": "Risk Category 3"
    }
    ]
    addOnCoverIds = {
        three: 3,
        four: 4,
        five: 5,
        six: 6,
        eight: 8,
        ten: 10,
        eleven: 11,
        twelve: 12,
        thirteen: 13
    }
    title = [
        {
            "value": "0",
            "label": "Mrs."
        },

        {
            "value": "1",
            "label": "Mr."
        },

        {
            "value": "2",
            "label": "Ms."
        },

        {
            "value": "21",
            "label": "Mx."
        }
    ]

    // Number of adult dropdown
    // by default 1A should be shown
    adultCount = [
        { 'label': '1 Adult', 'value': '1', 'type': 'adult', 'selected': true, 'hide': false },
        { 'label': '2 Adult', 'value': '2', 'type': 'adult', 'selected': false, 'hide': false },
    ]

    // Number of child dropdown
    // by default 0A should be shown
    childCount = [
        { 'label': '1 Child', 'value': '1', 'type': 'child', 'selected': false, 'hide': false },
        { 'label': '2 Child', 'value': '2', 'type': 'child', 'selected': false, 'hide': false },
        { 'label': '3 Child', 'value': '3', 'type': 'child', 'selected': false, 'hide': true },
    ]

    // 3 --> Maternity cover
    // 4 --> New born
    // 5 --> Vaccination for new born
    // 6 --> Domestic air ambulance
    // 8 --> Personal accident
    // 10 --> Tele Consultations
    // 11 --> World wide cover
    // 12 --> Sum Insured Protector
    // 13 --> Claim Protector
    stateId = '62';
    stateDetails = {
        'stateId': '',
        'cityId': '',
        'cityName': '',
        'stateName': '',
        'pincode': ''
    }
    memberButtonData = [
        { 'label': 'Adult', 'imgSrc': '../../../../assets/icons/Single Adult.svg', minusImgSrc: '../../../../assets/icons/minus.svg', plusImgSrc: '../../../../assets/icons/plus.svg', 'selected': false, 'hide': false, 'incDec': true },
        // { 'label': 'Child', 'imgSrc': '../../../../assets/icons/Single Child.svg', minusImgSrc: '../../../../assets/icons/minus.svg', plusImgSrc: '../../../../assets/icons/plus.svg', 'selected': false, 'hide': false, 'incDec': true }
    ]
}

export interface AddonStructure {
    checkboxValue: CheckBoxData,
    coverCode: string,
    coverId: number,
    isForAdult: boolean,
    isForChild: boolean,
    label: string,
    position: number,
    type: string,
    visible: boolean,
    visiblityAsPerService: boolean
}

export interface CheckBoxData {
    disabledAddon: boolean, checkedAddon: boolean
}