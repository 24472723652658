<form [formGroup]="healthAdvantageForm" *ngIf="!multipleServiceError && loadFormContent">

    <!-- Product Details Card Starts -->

    <div class="">
        <mat-card class="innerbox borderinnerbox producttype marginbox">
            <h3>Product Name & Type</h3>
            <div class="fieldsdiv">
                <div class="row">

                    <div class="col-sm-3 form-group order-1 order-sm-1 mb0">
                        <mat-label class="input-group__label" for="myInput"> Product
                            name</mat-label>

                        <input type="text" matInput class="input-group__input health planselect"
                            placeholder="Enter product" type="text" formControlName="productName" id="productName"
                            readonly autocomplete="off">
                    </div>


                    <div class="col-sm-2 form-group mt-4 order-2 order-sm-2 mb0">
                        <!-- <div class="Custom_group_btn2">
                            <input type="radio" class="Cstmradio__input2" value="New" name="productType" id="myradio8"
                                formControlName="productType" checked (change)="changeInProductType()">
                            <label class="Cstmradio__label2" for="myradio8">
                                New</label>
                            <input type="radio" class="Cstmradio__input2" value="Portability" name="productType"
                                id="myradio9" formControlName="productType" (change)="changeInProductType()">
                            <label class="Cstmradio__label2" for="myradio9">
                                Portability</label>
                        </div> -->

                        <div class="productTypeDiv">
                            <mat-button-toggle-group class="custom-toggle-group" formControlName="productType"
                                name="productType" (click)="changeInProductType()">
                                <mat-button-toggle class="custom_togglebtn"
                                    *ngFor="let option of hapDataModal.productType" [value]="option">
                                    {{option}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>


                    <div class="col-sm-3 form-group order-3 order-sm-3">
                        <mat-label class="input-group__label" for="myInput">PIN
                            code</mat-label>
                        <input matInput class="input-group__input" placeholder="Enter pincode" type="text"
                            formControlName="pincode" id="pincode" [minlength]="6" [maxlength]="6" autocomplete="off"
                            (input)="fetchDataAsPerPincode($event,null)">

                        <mat-error *ngIf="hasError('pincode', 'required') && formSubmitted">
                            Pincode is required
                        </mat-error>

                        <mat-error
                            *ngIf="(hasError('pincode','maxlength') || hasError('pincode','minlength')) && formSubmitted">
                            Atleast 6 digit pincode is required
                        </mat-error>

                        <mat-error
                            *ngIf="!hasError('pincode','minlength') && hasError('pincode','pattern')  && formSubmitted">
                            Please enter only numbers
                        </mat-error>

                        <mat-error *ngIf="invalidPinCodeMessage!== ''">
                            {{invalidPinCodeMessage}}
                        </mat-error>

                    </div>

                    <div class="col-xl-3 col-md-4 col-sm-3 form-group order-4 order-sm-4 stateCititeswidth"
                        *ngIf="this.stateFetched">
                        <mat-label class="input-group__label" for="myInput">State & Cities</mat-label>
                        <!-- If length is 1 -->
                        <input type="text" matInput class="input-group__input" placeholder="Enter pincode" type="text"
                            formControlName="cityState" readonly *ngIf="cityData.length == 1" autocomplete="off">


                        <mat-select formControlName="cityState" id="cityState" required
                            class="input-group__input  cstm_select" placeholder="Select state and city"
                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                            (selectionChange)="changeCityName($event.value,true)" *ngIf="cityData.length > 1">
                            <ng-container *ngFor="let item of cityData; let last = last">
                                <mat-option value={{item.value}}>
                                    {{item.stateLabel}} - {{item.cityLabel}}
                                </mat-option>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="hasError('cityState', 'required') && formSubmitted">
                            Please select atleast one option
                        </mat-error>
                    </div>

                    <!-- zone upgrade -->
                    <div class="col-sm-3 form-group order-5 order-sm-5"
                        *ngIf="healthAdvantageForm.controls['zoneDowngrade'].value == 'No' && reviseZone.length>0">
                        <label class="singlelabel emiToggle mb-0" style="width: 100%;">Do you want to upgrade
                            zone?</label>
                        <div class="group_btn">
                            <mat-button-toggle-group formControlName="zoneUpgrade" name="fontStyle"
                                aria-label="Font Style" class="custom-toggle-group" (click)="onZoneUpgrade()">
                                <mat-button-toggle class="custom_togglebtn" *ngFor="let option of hapDataModal.options"
                                    [value]="option">
                                    {{ option }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>

                    <div class="col-sm-2 form-group order-6 order-sm-6"
                        *ngIf="healthAdvantageForm.controls['zoneUpgrade'].value == 'Yes' && reviseZone.length>0">
                        <label class="singlelabel emiToggle mb-0" style="width: 100%;">Select zone</label>
                        <div [ngClass]="{'group_btn': reviseZone.length == 1}">
                            <mat-button-toggle-group formControlName="reviseZone" name="fontStyle"
                                aria-label="Font Style" class="custom-toggle-group">
                                <mat-button-toggle class="custom_togglebtn" *ngFor="let option of reviseZone"
                                    [value]="option">
                                    {{ option }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>

                    <!-- <div class="col-sm-3 form-group order-6 order-sm-6"
                        *ngIf="healthAdvantageForm.controls['zoneUpgrade'].value == 'Yes' && reviseZone.length>0">
                        <label class="singlelabel emiToggle mb-0" style="width: 100%;">Select zone</label>
                        <div [ngClass]="{'group_btn': reviseZone.length == 1}">
                            <mat-button-toggle-group formControlName="reviseZone" name="fontStyle"
                                aria-label="Font Style" class="custom-toggle-group">
                                <mat-button-toggle class="custom_togglebtn" *ngFor="let option of reviseZone"
                                    [value]="option">
                                    {{ option }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div> -->

                    <!-- Zone Downgrade -->
                    <!-- <div class="col-sm-3 form-group order-7 order-sm-7"
                        *ngIf="healthAdvantageForm.controls['zoneUpgrade'].value == 'No' && revisedZone.length > 0">
                        <label class="singlelabel emiToggle" style="width: 100%;">Do you want to downgrade zone?</label>
                        <div class="group_btn">
                            <mat-button-toggle-group formControlName="zoneDowngrade" name="fontStyle"
                                aria-label="Font Style" class="custom-toggle-group" (change)="onZoneDowngrade()">
                                <mat-button-toggle class="custom_togglebtn" *ngFor="let option of hapDataModal.options"
                                    [value]="option">
                                    {{ option }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>

                    <div class="col-sm-3 form-group order-8 order-sm-8"
                        *ngIf="healthAdvantageForm.controls['zoneDowngrade'].value == 'Yes' && revisedZone.length > 0">
                        <div class="ml-1">
                            <label class="input-group__label" style="top: 13px;">Do you want to downgrade zone?</label>
                            <mat-select class="input-group__input cstm_select" formControlName="revisedZone"
                                id="revisedZone" placeholder="Please select zone" required>
                                <mat-option *ngFor="let option of revisedZone" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="hasError('revisedZone', 'required') && revisedZone.length > 0 && formSubmitted">
                                Downgraded Zone is required
                            </mat-error>
                        </div>
                    </div> -->
                </div>
            </div>
        </mat-card>
    </div>
    <!-- Product Details Card Ends -->

    <!-- Member Detail Card Starts -->
    <mat-card class="innerbox reverinnerbox pt-2">
        <h3>Member Details</h3>
        <div class="fieldsdiv">
            <div class="row">
                <div class="col-sm-12 form-group">
                    <div class="memberbtnrefilling">
                        <button class="refilingbtn" *ngFor="let memberData of hapDataModal.memberButtonData"
                            (click)="memberTabClicked($event)">
                            <!-- Member Icons -->
                            <div class="refilimg">
                                <span>
                                    <img src="{{memberData.imgSrc}}" alt="{{memberData.label}}">
                                </span>
                                <strong>{{memberData.label}}</strong>
                            </div>

                            <!-- Increment Decryment member operations -->
                            <div class="Numberbtn" *ngIf="memberData.incDec" (click)="stopPropogation($event)">
                                <span (click)="decrymentMember(memberData, $event)">
                                    <img src="{{memberData.minusImgSrc}}" alt="minus">
                                </span>
                                <!-- Member Count -->
                                <strong>{{memberData.label == 'Adult'? adultValue: childValue}}</strong>

                                <span (click)="incrementMember(memberData, $event)">
                                    <img src="{{memberData.plusImgSrc}}" alt="plus">
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <h3 *ngIf="adultDetails.length > 0">Adults</h3>
        <!-- Adult fields Construction -->
        <div class="fieldsdiv " *ngIf="adultDetails.length > 0" formArrayName="adultDetails">
            <ng-container *ngFor="let data of adultDetails.controls; let i = index;" [formGroupName]="i">
                <div class="row">
                    <div class="col-sm-12" [ngClass]="{'mt-2': i>0}">
                        <span class="subheading">Adult {{i+1}}</span>
                    </div>

                    <div class="col-sm-3">
                        <mat-label class="input-group__label" for="myInput">Relationship</mat-label>
                        <mat-select class="input-group__input cstm_select"
                            formControlName="{{'adultRealtionship' + (i+1)}}" id="adultDetails" required
                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                            (selectionChange)="adultRelationShip($event.value, i);" placeholder="Select Relationship">
                            <ng-container *ngFor="let item of adultRelationShips; let last = last">
                                <mat-option value={{item.RelationshipName}}>
                                    {{item.RelationshipName}}
                                </mat-option>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </ng-container>
                        </mat-select>
                        <mat-error class="error-margin"
                            *ngIf="adultDetails.value[i]['adultRealtionship' + (i+1)] == '' && formSubmitted">
                            Adult {{i+1}} Relationship is required
                        </mat-error>
                    </div>

                    <div class="col-sm-3 form-group datepickerAlign">
                        <mat-label class="input-group__label">DOB</mat-label>
                        <input class="input-group__input dob" matInput [matDatepicker]="adultDOB"
                            [formControlName]="'dobAdult' + (i+1)" id="adultDetails"
                            [max]="getInsuredMinMaxDate('Adult','max')" [min]="getInsuredMinMaxDate('Adult','min')"
                            placeholder="DD/MM/YYYY" autocomplete="off" (click)="adultDOB.open()"
                            (dateInput)="adultsDateComparison($event.value,i,'dobAdult' + (i+1))" readonly>

                        <!-- Tooltip start -->
                        <!-- <ng-container *ngIf="data.value['adultRealtionship' + (i+1)] != ''">
                            <img src="../../../../../assets/images/Waiting Period.svg" alt="" class="dateTooltip"
                                #tooltip="matTooltip" matTooltip="{{'Date Range: '+
                                (adultMinMaxDate['minDate'] | date:'dd-MM-yyyy') + ' to ' +
                                (adultMinMaxDate['maxDate'] | date:'dd-MM-yyyy')}}" (click)="tooltip.show()">
                        </ng-container> -->

                        <!-- (keydown)="changeDateInput($event)"
                            (input)="onDateChange($event,i,'dobAdult' + (i+1),'adultDob')"
                            (focusout)="dobFocusOut(i,'dobAdult' + (i+1),'adultDob')"
                            (dateChange)="adultsDateComparison($event.value,i,'dobAdult' + (i+1))" maxlength="10"
                            minlength="10" -->
                            <!-- class="toggleDatepicker" -->
                        <!-- Tooltip end -->

                        <mat-datepicker-toggle matSuffix [for]="adultDOB">
                        </mat-datepicker-toggle>
                        <mat-datepicker #adultDOB touchUi>
                        </mat-datepicker>
                        <mat-error class="error-margin"
                            *ngIf="adultDetails.value[i]['dobAdult' + (i+1)] == '' && formSubmitted">
                            Adult {{i+1}} DOB is required
                        </mat-error>
                    </div>

                    <div class="col-sm-3 form-group" *ngIf="isVaccinationDiscountEnabled">
                        <label class="singlelabel vaccineLabel mb-0">Pneumococcal
                            vaccine taken in last 1 year?</label>
                        <div class="group_btn">
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                class="custom-toggle-group" formControlName="{{'adultVaccinationStatus' + (i+1)}}">
                                <mat-button-toggle class="custom_togglebtn" (change)="vaccinationDateValidation(i)"
                                    *ngFor="let option of hapDataModal.options" [value]="option">
                                    {{ option }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>

                    <div class="col-sm-3 form-group datepickerAlign">
                        <div class="vaccineMr" *ngIf="adultDetails.value[i]['adultVaccinationStatus' + (i+1)] == 'Yes'">
                            <label class="input-group__label mb-0" style="top: 13px;" for="myInput">Date of
                                Vaccination</label>
                            <!-- mat date picker starts here -->
                            <input class="input-group__input dob" matInput [matDatepicker]="vaccinationDate"
                                formControlName="{{'adultVaccinationDate' + (i+1)}}" id="adultDetails"
                                placeholder="DD/MM/YYYY" [max]="getMinMaxDateVaccine('max')"
                                [min]="getMinMaxDateVaccine('min')" (click)="vaccinationDate.open()" readonly
                                autocomplete="off">

                            <!-- Toolstip start -->
                            <!-- <ng-container>
                                <img src="../../../../../assets/images/Waiting Period.svg" alt="" class="dateTooltip"
                                    #tooltip="matTooltip" matTooltip="{{'Date Range: '+
                                        (vaccineMinMaxDate.minDate | date:'dd-MM-yyyy') + ' to ' +
                                        (vaccineMinMaxDate.maxDate | date:'dd-MM-yyyy')}}" (click)="tooltip.show()">
                            </ng-container> -->
                            <!-- (keydown)="changeDateInput($event)"
                            (input)="onDateChange($event,i,'adultVaccinationDate' + (i+1),'pneumoccalDob')"
                            (focusout)="dobFocusOut(i,'adultVaccinationDate' + (i+1),'pneumoccalDob')"
                            maxlength="10" minlength="10" -->
                            <!-- class="toggleDatepicker" -->
                            <!-- Tooltip end -->

                            <mat-datepicker-toggle matSuffix [for]="vaccinationDate">
                            </mat-datepicker-toggle>
                            <mat-datepicker #vaccinationDate touchUi>
                            </mat-datepicker>
                            <mat-error class="error-margin"
                                *ngIf="adultDetails.value[i]['adultVaccinationDate' + (i+1)] == '' && formSubmitted">
                                Adult {{i+1}} Vaccination Date is required
                            </mat-error>
                            <!-- mat date picker ends here -->
                        </div>
                    </div>
                </div>
                <!-- <mat-divider *ngIf="!i"></mat-divider> -->
            </ng-container>
        </div>
        <mat-divider *ngIf="childDetails.length > 0"></mat-divider>

        <!-- Child Fields Construction -->
        <h3 *ngIf="childDetails.length > 0">Children</h3>
        <div class="fieldsdiv " *ngIf="childDetails.length > 0" formArrayName="childDetails">
            <ng-container *ngFor="let data of childDetails.controls; let i = index" [formGroupName]="i">
                <div class="row ">
                    <div class="col-sm-12" [ngClass]="{'mt-2': i>0}">
                        <span class="subheading">Child {{i+1}}</span>
                    </div>

                    <div class="col-sm-3">
                        <mat-label class="input-group__label" style="top: 13px;" for="myInput">Relationship</mat-label>
                        <mat-select class="input-group__input cstm_select"
                            formControlName="{{'childRelationship' + (i+1)}}" id="childDetails"
                            placeholder="Relationship with Applicant of child"
                            (selectionChange)="depIndChildRelationship($event.value,i)" (click)="handleMatSelectClick()"
                            (blur)="removeMatSelectScrollListener()">
                            <!-- <mat-option disabled selected>Select Relationship</mat-option> -->
                            <ng-container *ngFor="let item of childRelationShips; let last = last">
                                <mat-option value={{item.RelationshipName}}
                                    *ngFor="let item of childRelationShips; let i = index">
                                    {{item.RelationshipName}}
                                </mat-option>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </ng-container>
                        </mat-select>
                        <mat-error class="error-margin"
                            *ngIf="childDetails.value[i]['childRelationship' + (i+1)] == '' && formSubmitted">
                            Child {{i+1}} Relationship is required
                        </mat-error>
                    </div>

                    <div class="col-sm-3 form-group datepickerAlign">
                        <mat-label class="input-group__label" style="top: 13px;">DOB</mat-label>
                        <input class="input-group__input dob" matInput [matDatepicker]="childDOB"
                            formControlName="{{'dobChild' + (i+1)}}" id="childDetails" placeholder="DD/MM/YYYY"
                            [max]="childMinMaxDate[i]['maxDate']" [min]="childMinMaxDate[i]['minDate']"
                            autocomplete="off" (click)="childDOB.open()" readonly>

                        <!-- Tooltip start -->
                        <!-- <ng-container *ngIf="data.value['childRelationship' + (i+1)] != ''">
                            <img src="../../../../../assets/images/Waiting Period.svg" alt="" class="dateTooltip"
                                #tooltip="matTooltip" matTooltip="{{'Date Range: '+
                                    (childMinMaxDate[i]['minDate'] | date:'dd-MM-yyyy') + ' to ' +
                                    (childMinMaxDate[i]['maxDate'] | date:'dd-MM-yyyy')}}" (click)="tooltip.show()">
                        </ng-container> -->

                        <!-- (keydown)="changeDateInput($event)"
                        (input)="onDateChange($event,i,'dobChild' + (i+1),'childDob')"
                        (focusout)="dobFocusOut(i,'dobChild' + (i+1),'childDob')"
                        (dateChange)="childDateComparison($event.value,i,'dobChild' + (i+1))" maxlength="10"
                        minlength="10"  -->
                        <!-- class="toggleDatepicker" -->
                        <!-- Tooltip end -->

                        <mat-datepicker-toggle matSuffix [for]="childDOB">
                        </mat-datepicker-toggle>
                        <mat-datepicker #childDOB touchUi>
                        </mat-datepicker>
                        <mat-error class="error-margin"
                            *ngIf="childDetails.value[i]['dobChild' + (i+1)] == '' && formSubmitted">
                            Child {{i+1}} DOB is required
                        </mat-error>
                    </div>

                </div>
                <!-- <mat-divider *ngIf="!i"></mat-divider> -->
            </ng-container>
        </div>

        <mat-divider></mat-divider>
        <!-- Premium Details Seection Starts -->
        <h3>Premium Details</h3>
        <div class="fieldsdiv">
            <div class="row ">
                <!-- Policy Tenure -->
                <div class="col-sm-6 col-md-6 col-lg-3 policyTenureWidth form-group">
                    <!-- Policy Tenure -->
                    <label for="" class="singlelabel mb-0" style="width:100%; margin-bottom: 6px !important;">Policy
                        Tenure</label>
                    <div class="policyTenureDiv"><br />
                        <mat-button-toggle-group formControlName="policyTenure" name="fontStyle" aria-label="Font Style"
                            class="custom-toggle-group">
                            <mat-button-toggle class="custom_togglebtn"
                                *ngFor="let tenureData of tenurePeriod; let j = index" [value]="tenureData"
                                (change)="changePolicyTenure(tenureData)">
                                {{ tenureData }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <mat-error
                            *ngIf="healthAdvantageForm.controls['policyTenure']?.errors?.['required'] && formSubmitted">
                            Policy Tenure is required
                        </mat-error>
                    </div>
                </div>

                <!-- Policy Range -->
                <div class="col-sm-6 col-lg-2 appIncome">
                    <label for="" class="singlelabel width100 mb-0">SI Range</label>
                    <div>
                        <mat-button-toggle-group formControlName="policyRange" name="fontStyle" aria-label="Font Style"
                            class="custom-toggle-group">
                            <mat-button-toggle class="custom_togglebtn"
                                *ngFor="let range of policyRangeData;let i = index" [value]="range"
                                (change)="changePolicyRange(range)">
                                {{ range }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <mat-error
                            *ngIf="healthAdvantageForm.controls['policyRange']?.errors?.['required'] && formSubmitted">
                            Policy Plan is required
                        </mat-error>
                    </div>
                </div>

                <!-- Annual Sum Insured -->
                <div class="col-sm-3 form-group appIncome"
                    *ngIf="healthAdvantageForm.controls['policyRange'].value !=''">
                    <label class="input-group__label mb-0" style="top: 13px;">Sum Insured</label>
                    <mat-select class="input-group__input cstm_select" (click)="handleMatSelectClick()"
                        (blur)="removeMatSelectScrollListener()" (selectionChange)="annualSumChanged($event.value)"
                        placeholder="Select applicant sum insured" formControlName="applicantAnnualSum"
                        id="applicantAnnualSum" required>
                        <ng-container *ngFor="let item of sumInsuredDetails; let i = index; let last = last">
                            <mat-option value="{{item.SI_VALUE}}">
                                {{utilityService.easyAmountReader(item.SI_VALUE)}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="healthAdvantageForm.controls['applicantAnnualSum']?.errors?.['required'] && formSubmitted">
                        Sum Insured is required
                    </mat-error>
                </div>
            </div>
        </div>

    </mat-card>
    <!-- Member Detail Card Ends -->


    <!-- Additional Details Start -->
    <mat-card class="innerbox borderinnerbox">
        <h3>Additional Details</h3>
        <div class="fieldsdiv">
            <div class="row">

                <!-- <div class="col-sm-2 form-group appIncome">
                    <label class="input-group__label" style="top: 13px;">Co-Payment</label>
                    <mat-select class="input-group__input cstm_select" placeholder="Select Co-Payment"
                        [(ngModel)]="selectedOption" name="copaymentPercentTaken"
                        (change)="handleSelectionChange('copayment')" (click)="handleMatSelectClick()"
                        (blur)="removeMatSelectScrollListener()">
                        <ng-container
                            *ngFor="let option of hapDataModal.copaymentPercentTaken.hap; let i = index; let last = last">
                            <mat-option [value]="option">
                                {{ option }}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                </div> -->

                <!-- <div class="col-sm-3 form-group appIncome">
                    <label class="input-group__label" style="top: 13px;">Voluntary Deductible</label>
                    <mat-select class="input-group__input cstm_select" placeholder="Select Voluntary Deductible Sum"
                        [disabled]="isVoluntaryDeductibleDisabled" (click)="handleMatSelectClick()"
                        (blur)="removeMatSelectScrollListener()" formControlName="voluntaryDeductible"
                        id="voluntaryDeductible" required>
                        <ng-container
                            *ngFor="let item of hapDataModal.voluntaryDeductible; let i = index; let last = last">
                            <mat-option [value]="item">
                                {{utilityService.convertStringtoCurrency(item)}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="healthAdvantageForm.controls['voluntaryDeductible']?.errors?.['required'] && formSubmitted">
                        Voluntary Deductible is required
                    </mat-error>
                </div> -->
                <!-- <div class="customaddons col-sm-12 col-md-12 col-lg-12"
                    [ngStyle]="{'border-radius': hasPedAddons ? '8px' : '0px'}">
                    <span class="d-flex pl-0">
                        <img src=".../../../../../../../assets/images/Waiting Period.svg" class="waiting">
                        <label class="waitinglabel">You may choose copayment or voluntary deductible. However,
                            voluntary deductible isn't available if the Sum Insured exceeds 20 lakhs.</label>
                    </span>
                </div> -->

                <!-- <div class="col-sm-3 form-group mb-0 appIncome">
                    <label class="input-group__label" style="top: 13px;">Co-Payment</label>
                    <mat-select class="input-group__input cstm_select" placeholder="Select Co-Payment"
                        formControlName="copaymentPercentTaken" name="selectedOptionCopay">
                        <ng-container
                            *ngFor="let option of hapDataModal.copaymentPercentTaken.hapRefiling; let i = index; let last = last">
                            <mat-option [value]="option">
                                {{ option }}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                </div> -->

                <div class="col-sm-3">
                    <label class="singlelabel emiToggle mb-0" style="width: 100%;">Co-Payment</label>
                    <div>
                        <mat-button-toggle-group formControlName="copaymentPercentTaken" class="custom-toggle-group">
                            <mat-button-toggle class="custom_togglebtn"
                                *ngFor="let option of hapDataModal.copaymentPercentTaken.hapRefiling" [value]="option">
                                {{ option }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <!-- Applicant annual income -->
                <div class="col-sm-3 form-group appIncome">
                    <label class="input-group__label mb-0" style="top: 13px;">Applicant Annual Income</label>
                    <mat-select class="input-group__input cstm_select" placeholder="Select applicant annual income"
                        [disabled]="isEMIdisabled" (click)="handleMatSelectClick()"
                        (blur)="removeMatSelectScrollListener()" formControlName="applicantAnnualIncome"
                        id="applicantAnnualIncome">
                        <ng-container *ngFor="let item of annualIncome; let last = last">
                            <mat-option value={{item.Name}}>
                                {{utilityService.easyIncomeReader(item.Name)}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error
                        *ngIf="healthAdvantageForm.controls['applicantAnnualIncome']?.errors?.['required'] && formSubmitted">
                        Annual Income is required
                    </mat-error>
                </div>
                <div class="col-sm-3 form-group"
                    *ngIf="healthAdvantageForm.controls['policyTenure'].value=='1 Year' && healthAdvantageForm.controls['productType'].value!='Portability' && showEMIValue">
                    <label class="singlelabel emiToggle mb-0" style="width: 100%;">Do you want to opt
                        EMI facility?</label>
                    <div class="group_btn">
                        <mat-button-toggle-group formControlName="EMI" name="fontStyle" aria-label="Font Style"
                            class="custom-toggle-group" (click)="emiValueChanged()">
                            <mat-button-toggle class="custom_togglebtn" *ngFor="let option of hapDataModal.options"
                                [value]="option">
                                {{ option }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <div class="col-sm-3 form-group"
                    *ngIf="healthAdvantageForm.controls['EMI'].value == 'Yes' && healthAdvantageForm.controls['productType'].value!='Portability' && healthAdvantageForm.controls['policyTenure'].value =='1 Year' && showEMIValue">
                    <label class="singlelabel emiToggle mb-0" style="width: 100%;">EMI Tenure</label>
                    <div>
                        <mat-button-toggle-group formControlName="EMIInstallment" class="custom-toggle-group">
                            <mat-button-toggle class="custom_togglebtn"
                                *ngFor="let option of hapDataModal.emiInstallments" [value]="option">
                                {{ option }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <!-- <div class="col-sm-3 form-group">
                    <div class="ml-1"
                        *ngIf="healthAdvantageForm.controls['EMI'].value == 'Yes' && healthAdvantageForm.controls['productType'].value!='Portability' && healthAdvantageForm.controls['policyTenure'].value =='1 Year' && showEMIValue">
                        <label class="input-group__label" style="top: 13px;">EMI Tenure</label>
                        <mat-select class="input-group__input cstm_select" placeholder="Select EMI Tenure"
                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                            formControlName="EMIInstallment" id="EMIInstallment" required>
                            <ng-container *ngFor="let option of hapDataModal.emiInstallments; let last = last">
                                <mat-option [value]="option">
                                    {{option}}
                                </mat-option>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </ng-container>
                        </mat-select>
                        <mat-error
                            *ngIf="healthAdvantageForm.controls['EMIInstallment']?.errors?.['required'] && formSubmitted">
                            EMI Installment is required
                        </mat-error>
                    </div>
                </div> -->
            </div>

            <mat-divider></mat-divider>

            <div class="row pt-3" formGroupName="cibilDiscountSection" id="cibilDiscountSection">
                <div class="col form-group">
                    <label class="singlelabel emiToggle mb-0" style="width: 100%;">Do you want to opt cibil
                        discount?</label>
                    <div class="cibilDiv">
                        <div class="group_btn col-sm-12 col-lg-2 col-md-3">
                            <mat-button-toggle-group formControlName="cibilDiscount" name="fontStyle"
                                aria-label="Font Style" class="custom-toggle-group" (click)="cibilChange()">
                                <mat-button-toggle class="custom_togglebtn" *ngFor="let option of hapDataModal.options"
                                    [value]="option">
                                    {{ option }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="customaddons col-sm-12 col-lg-10 col-md-12"
                            [ngStyle]="{'border-radius': hasPedAddons ? '8px' : '0px'}" *ngIf="cibilToggle">
                            <span class="d-flex pl-0">
                                <img src=".../../../../../../../assets/images/Waiting Period.svg" alt=""
                                    class="waiting">
                                <label class="waitinglabel">To avail the Cibil Discount, ensure accurate alignment
                                    with your KYC records. Discrepancies will affect eligibility.</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div formGroupName="cibilDiscountSection" id="cibilDiscountSection" *ngIf="cibilToggle">
                <div class="row">
                    <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label" for="myInput">Proposer Name</mat-label>
                        <input matInput class="input-group__input" placeholder="Enter Proposer Name" type="text"
                            (keydown)="proposerNameInput($event)" formControlName="proposerName" id="proposerName"
                            autocomplete="off">
                        <mat-error *ngIf="cibilGroup.get('proposerName').invalid && cibilSubmitted">
                            Proposer Name is required
                        </mat-error>
                    </div>
                    <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label" for="myInput">Mobile Number</mat-label>
                        <input matInput class="input-group__input" placeholder="Enter mobile no" type="text"
                            (keydown)="mobNoInput($event)" formControlName="mobileNo" id="mobileNo" [minlength]="10"
                            [maxlength]="10" autocomplete="off">
                        <mat-error *ngIf="cibilGroup.get('mobileNo').invalid && cibilSubmitted">
                            Please enter a valid Mobile Number (10 digits)
                        </mat-error>
                    </div>
                </div>
                <div class="row" *ngIf="cibilToggle && !showSave">
                    <div class="col-sm-3">
                        <button mat-button type="submit" (click)="onSubmit()"
                            [ngStyle]="{'background-color': bgColor, 'color': textColor}">
                            <span>Save</span></button>
                    </div>
                </div>
            </div>


            <div class="row mt-2">
                <div class="col-sm-12 customaddons pedAddons">
                    <span>
                        <img src=".../../../../../../../assets/images/Waiting Period.svg"
                            alt="Waiting period & pre / post hospital duration" class="waiting">
                        <label for="" class="waitinglabel">Waiting period & pre / post hospital duration</label>
                    </span>
                    <ul col-sm-3>
                        <li>
                            <span>
                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                            </span>
                            <span class="waitingperiodSpan">PED waiting period is 2 years</span>
                        </li>
                        <li>
                            <span>
                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                            </span>
                            <span class="waitingperiodSpan">Specific condition waiting period is 2 years</span>
                        </li>
                        <li>
                            <span>
                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                            </span>
                            <span class="waitingperiodSpan">Pre hospital duration is 60 days</span>
                        </li>
                        <li>
                            <span>
                                <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                            </span>
                            <span class="waitingperiodSpan">Post hospital duration is 180 days</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </mat-card>
    <!-- Additional Details End -->

    <!-- Additional Covers Start -->
    <mat-card class="innerbox borderinnerbox"
        *ngIf="!utilityService.isEmptyOrNull(healthAdvantageForm.controls['applicantAnnualSum'].value) && this.refiledAddons.length > 0">
        <h3>Additional Cover</h3>
        <ng-container *ngIf="(adultDetails.length > 0)">
            <div class="customaddons pl-3 pr-3" [ngStyle]="{'border-radius': hasPedAddons ? '8px' : '0px'}">
                <span class="d-flex pl-0">
                    <img src=".../../../../../../../assets/images/Waiting Period.svg" alt="" class="waiting">
                    <label class="waitinglabel">You can only choose either critical illness coverage or pre-existing
                        disease coverage, but not both. Pre-existing conditions are listed on the insured page.</label>
                </span>
            </div>
        </ng-container>

        <ng-container *ngFor="let addonsData of refiledAddons; let i = index">

            <div class="fieldsdiv" [ngClass]="getDynamicClasses(i)" *ngIf="!addonsData.hide">
                <div class=" customCheckslist">
                    <ul>

                        <li *ngFor="let childData of addonsData.childAddons; let i = index">

                            <mat-checkbox *ngIf="childData.visiblityAsPerService" class="cstmCheckbox"
                                [checked]="childData['checkboxValue']['checkedAddon']"
                                [disabled]="childData['checkboxValue']['disbaledAddon']"
                                formControlName="{{childData.label}}"
                                (change)="changeInAddonCover(addonsData,childData,true,$event)">{{childData.label}}</mat-checkbox>
                        </li>

                        <!-- Maternity benefit Cover adult dropdown and input field -->
                        <li class="ml-3" *ngIf="addonsData.showMaternityFields">
                            <mat-label class="input-group__label" for="myInput">Member</mat-label>
                            <ng-container *ngIf="maternityAdultData.length > 1">
                                <mat-select class="input-group__input cstm_select" placeholder="Select Member"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                    formControlName="maternityForAdult" id="maternityForAdult" multiple>
                                    <mat-option *ngFor="let maternityAdultData of maternityAdultData"
                                        [value]=maternityAdultData.label>
                                        {{maternityAdultData.label}}
                                    </mat-option>
                                </mat-select>
                            </ng-container>

                            <input type="text" matInput class="input-group__input" placeholder="Adult" type="text"
                                formControlName="maternityForAdult" *ngIf="maternityAdultData.length == 1"
                                autocomplete="off">

                            <mat-error
                                *ngIf="hasError('maternityForAdult', 'required') && maternityAdultData.length > 1">
                                Member is required
                            </mat-error>
                        </li>

                        <!-- Critical Illness  for adult dropdown -->
                        <!-- Critical benefit Cover adult dropdown and input field -->

                        <ng-container>
                            <li class="ml-3" *ngIf="addonsData.showCriticalFields">
                                <mat-label class="input-group__label" for="myInput">Adult</mat-label>
                                <ng-container *ngIf="criticalAdultData.length > 1">
                                    <mat-select class="input-group__input cstm_select" placeholder="Select Member"
                                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                        formControlName="criticalIllnessForAdult" id="criticalIllnessForAdult" multiple>
                                        <mat-option *ngFor="let criticalData of criticalAdultData"
                                            [value]=criticalData.label>
                                            {{criticalData.label}}
                                        </mat-option>
                                    </mat-select>
                                </ng-container>


                                <input type="text" matInput class="input-group__input" placeholder="Adult" type="text"
                                    formControlName="criticalIllnessForAdult" *ngIf="criticalAdultData.length == 1"
                                    autocomplete="off">

                                <mat-error
                                    *ngIf="hasError('criticalIllnessForAdult', 'required') && criticalAdultData.length > 1">
                                    Member is required
                                </mat-error>
                            </li>

                            <!-- Critical Illness Datepicker -->
                            <li class="col-sm-3 form-group datepickerAlign" *ngIf="addonsData.showCriticalDatePicker">
                                <mat-label class="input-group__label">Proposer DOB</mat-label>
                                <input class="input-group__input dob" matInput [matDatepicker]="criticalDOB"
                                    formControlName="proposerDobCi" id="proposerDobCi"
                                    [max]="getInsuredMinMaxDate('Adult','max')"
                                    [min]="getInsuredMinMaxDate('Adult','min')" placeholder="DD/MM/YYYY"
                                    (click)="criticalDOB.open()" readonly
                                    autocomplete="off">

                                <!-- Tooltip start -->
                                <!-- <ng-container>
                                    <img src="../../../../../assets/images/Waiting Period.svg" alt=""
                                        class="dateTooltip" #tooltip="matTooltip" matTooltip="{{'Date Range: '+
                                            (adultMinMaxDate['minDate'] | date:'dd-MM-yyyy') + ' to ' +
                                            (adultMinMaxDate['maxDate'] | date:'dd-MM-yyyy')}}"
                                        (click)="tooltip.show()">
                                </ng-container> -->

                                <!-- (keydown)="changeDateInput($event)"
                                    (input)="onDateChange($event,0,'proposerDobCi','proposerDob')"
                                    (focusout)="dobFocusOut(0,'proposerDobCi','proposerDob')" maxlength="10"
                                    minlength="10" -->
                                    <!--  class="toggleDatepicker" -->
                                <!-- Tooltip end -->

                                <mat-datepicker-toggle matSuffix [for]="criticalDOB">
                                </mat-datepicker-toggle>
                                <mat-datepicker #criticalDOB touchUi>
                                </mat-datepicker>
                                <mat-error class="error-margin"
                                    *ngIf="hasError('proposerDobCi', 'required') && formSubmitted">
                                    Proposer DOB is required
                                </mat-error>
                            </li>
                        </ng-container>

                        <!-- Personal accident addons dropdown needs to change in input field -->
                        <div class="row mb-3" formArrayName="occupation"
                            *ngIf="healthAdvantageForm.controls['Personal Accident Cover'].value&&addonsData.showOccupationFields">
                            <div class="col-sm-3" *ngFor="let occData of occupation.controls; let i = index"
                                [formGroupName]="i">
                                <mat-label class="input-group__label" for="myInput">Occupation For
                                    {{occupation.value[i]['occLabel'] + ' ' +occupation.value[i]['memberIndex']}}
                                </mat-label>
                                <mat-select class="input-group__input cstm_select" placeholder="Select Occupation"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                    formControlName="{{'occupationFor'+(occupation.value[i]['occLabel'])+(occupation.value[i]['memberIndex'])}}"
                                    (selectionChange)="selectOccupationOption($event.value,i)">
                                    <mat-option *ngFor="let item of occupationMaster" [value]="item.value">
                                        {{item.value}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>

                        <!-- Befit Dropdown -->
                        <li class="mb-2" *ngIf="addonsData.showBefitFields && addonsData.category == 'Befit'">
                            <mat-label class="input-group__label" for="myInput">Choose Befit Plan</mat-label>
                            <mat-select required class="input-group__input cstm_select" placeholder="Select Befit Plan"
                                (click)="handleMatSelectClick()" formControlName='selectedBefitData'
                                (blur)="removeMatSelectScrollListener()" id="selectedBefitData" required>
                                <ng-container *ngFor="let item of befitData; let i = index; let last = last">
                                    <mat-option value="{{item.label}}" selected>
                                        {{item.label}}
                                    </mat-option>
                                    <mat-divider *ngIf="!last"></mat-divider>
                                </ng-container>
                            </mat-select>
                            <mat-error *ngIf="hasError('selectedBefitData', 'required')">
                                Please select Befit
                            </mat-error>
                        </li>
                    </ul>
                </div>
            </div>
            <mat-divider *ngIf="addonsData['showDivider']"></mat-divider>
        </ng-container>

    </mat-card>
    <!-- Additional Covers Ends -->
</form>

<app-error-screen *ngIf="multipleServiceError" (refreshService)="onRefresh($event)">
</app-error-screen>

<app-custom-footer *ngIf="!multipleServiceError && loadFormContent" [normalButton]="true"
    [buttonData]="[{'label': 'Generate Quote', 'type': 'primary', 'source': 'quote', 'id': 'f1','disabled': false , 'hide': false}]"
    (buttonClicked)="getQuote($event)">
</app-custom-footer>