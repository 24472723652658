import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { Subject, Subscription, catchError, forkJoin, of } from 'rxjs';
import { LoaderService } from 'src/app/layout/loader/loader.service';
import { Constants } from 'src/app/shared/constants/constants';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { ChannelData, SwapParameters } from 'src/app/shared/interface/swapPrameters';
import { CreateLoginTokenResponse } from 'src/app/shared/interface/token';
import { AgentServiceA } from 'src/app/shared/proxy-services/AuthService/agent.service';
import { EncryptDecryptService } from 'src/app/shared/services/encrypt-decrypt.service';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { HealthDataStorage } from '../health/health.modal';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { AgentMasterResponsePayload, AgentMasterStructure } from 'src/app/shared/interface/agentV1';
import { UserType } from 'src/app/shared/enums/healthEnums';
import { AuthService } from 'src/app/shared/authentication/auth.service';
import { PolicyService } from 'src/app/shared/proxy-services/policy.service';
import { GetPolicyDetailsResponsePayload } from 'src/app/shared/interface/policy';
import { HealthDataStorageService } from '../health/health.service';
import * as mockData from 'src/app/shared/constants/mockData';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { RenewalDataStorageService } from 'src/app/shared/components/sidemenu/renewals/renewal.service';
import { OfflineCalculateElevateService } from 'src/app/shared/proxy-services/offline-calculate-elevate.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { TravelService } from 'src/app/shared/services/travel.service';
import { HomeDataRequestPayload } from 'src/app/shared/interface/home';
import { HomeService } from 'src/app/shared/proxy-services/home.service';

@Component({
  selector: 'app-swap',
  templateUrl: './swap.component.html',
  styleUrls: ['./swap.component.scss']
})
export class SwapComponent implements OnInit {
  errorOccured: boolean = false;
  route: string = '';
  loggingIn: boolean = false;
  agentDetails: AgentMasterResponsePayload;
  agentData: AgentMasterStructure;
  private subscription: Subscription[] = [];
  private dataSubject = new Subject<string>();
  data$ = this.dataSubject.asObservable();
  healthFormData: HealthDataStorage;
  channelData: ChannelData;
  referrer: string;
  uname: string;
  pswds: string;
  bancauser: string;
  bflag: string;
  isOrion: string;

  constructor(
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private storageService: StorageService,
    private agentService_A: AgentServiceA,
    private utilityService: UtilityService,
    private spinner: LoaderService,
    private userIdle: UserIdleService,
    private activeRoute: ActivatedRoute,
    private encryptDecryptService: EncryptDecryptService,
    private authService: AuthService,
    private agentV1Service: AgentV1Service,
    private healthDataStorageService: HealthDataStorageService,
    private policyService: PolicyService,
    private renewalStorageService: RenewalDataStorageService,
    private utility_service: UtilityService,
    private offlineCalculateElevateService: OfflineCalculateElevateService,
    private shareservice:SharedService) {
  }

  ngOnInit(): void {
    // Below step is done because data is getting cached . So reset form data and clear local.
    this.healthDataStorageService.resetHealthDataStorage();
    this.sessionStorageService.clear();
    // this.localStorageService.clearLocal();
    // this.authService.check();
    this.get_query_params();
    this.bflag = this.storageService.getAESDecryptedDataLocal('ctrbflagth')
    if (this.bflag == "true") {
      this.router.navigate(['/', routeEnums.WILDCARD])
    }
  }

  get_query_params() {
    this.errorOccured = false;
    let cstmr;
    this.activeRoute.queryParams.forEach((params) => {
      let other = !this.utilityService.isEmptyOrNull(params['otr']) ? decodeURIComponent(params['otr']) : '';
      let plutos = !this.utilityService.isEmptyOrNull(params['plts']) ? params['plts'] : '';
      let dash = !this.utilityService.isEmptyOrNull(params['dash']) ? params['dash'] : '';
      cstmr = !this.utilityService.isEmptyOrNull(params['cstmr']) ? params['cstmr'] : '';
      this.bancauser = !this.utilityService.isEmptyOrNull(params['bncusr']) ? decodeURIComponent(params['bncusr']) : '';
      this.isOrion = !this.utilityService.isEmptyOrNull(params['isorion']) ? params['isorion'] : '';

      // If it comes from channel partner
      if (!this.utilityService.isEmptyOrNull(other)) {
        this.loggingIn = true;
        let decryptedData = this.encryptDecryptService.finalRsaDecryption(other);
        this.channelData = typeof (decryptedData) == 'object' ? decryptedData : JSON.parse(decryptedData);
      } // mockData.iPartnerMsite ; 

      // If it comes from plutos (Payment confirmation page)
      else if (!this.utilityService.isEmptyOrNull(plutos)) {
        this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
        this.storageService.setAESEncryptedDataLocal(userEnums.UnxkdjT, this.channelData.UT);
        this.storageService.setAESEncryptedDataLocal(userEnums.TransactionId, plutos);
        this.channelData.UN = this.storageService.getAESDecryptedDataLocal(userEnums.UopqksmlN);
        this.channelData.PSWD = this.storageService.getAESDecryptedDataLocal(userEnums.Pqsxkpo);
        this.channelData.ST = 'plts';
        this.shareservice.emitSessionFlag(true);

      }

      // When it needs to be redirected to pana dashboard
      else if (!this.utilityService.isEmptyOrNull(dash)) {
        this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
        this.channelData.UN = this.storageService.getAESDecryptedDataLocal(userEnums.UopqksmlN);
        this.channelData.PSWD = this.storageService.getAESDecryptedDataLocal(userEnums.Pqsxkpo);
        this.channelData.ST = dash == 'nys' ? 'dash' : dash == 'upload' ? 'upload' : '';
        this.bflag = this.storageService.getAESDecryptedDataLocal('ctrbflagth');
      }

      // If it is for send customer link
      else if (!this.utilityService.isEmptyOrNull(cstmr)) {
        this.storageService.setAESEncryptedDataLocal(userEnums.UnxkdjT, UserType.Customer);
        this.fetchDetailsAsPerTransactionId(cstmr, this.isOrion);
      }        //We need to fetch valid user details to create token for Offline calcultor users
      else if (!this.utilityService.isEmptyOrNull(this.bancauser)) {
        this.validateOfflineCalUser(this.bancauser);
      }
      else {
        this.errorOccured = true;
      }
    });
    // We need to fetch token for all entry points rather then send customer lisnk
    if (this.utilityService.isEmptyOrNull(cstmr) && this.utilityService.isEmptyOrNull(this.bancauser) && !this.errorOccured) {
      this.createLoginToken(this.channelData);
    }
  }

  createLoginToken(channelData) {
    let swapParameters: SwapParameters = {
      productType: channelData.PRDT || Constants.health,
      subProductCode: this.healthDataStorageService.getProductCode(channelData.ST),
      loginDetails: {
        Uowkjsdjssrme: channelData.UN || this.uname,
        Powkjsdjssrd: channelData.PSWD || this.pswds
      },
      channelDetails: {
        channelName: channelData.CN || 'NYSAHEALTH',
        details: {
          userType: channelData.UT || 'Agent'
        }
      },
      flowType: channelData.FT || 'NEW',
      viewType: channelData.VT
    };
    // if (this.bancauser) {
    //   this.channelData.ST = 'calculate-elevate'
    // }
    // localStorage.setItem(userEnums.UopqksmlN, this.encryptDecryptService.finalRsaEncryption(swapParameters.loginDetails.username));
    // localStorage.setItem(userEnums.Pqsxkpo, this.encryptDecryptService.finalRsaEncryption(swapParameters.loginDetails.password));
    this.storageService.setAESEncryptedData(userEnums.LoginCredential, swapParameters.loginDetails);
    this.storageService.setAESEncryptedDataLocal(userEnums.ChannelData, channelData);
    this.storageService.setAESEncryptedData(userEnums.SwapParametersLogout, swapParameters);
    this.storageService.setAESEncryptedData(userEnums.SwapParameters, swapParameters);
    this.route = this.getRoute(channelData);
    this.creatingToken(swapParameters, this.route);
  }

  getRoute(channelData) {
    let route: any = mockData.mappedRoutes.find(route => route.paramName == channelData.ST) || ''
    return route ? route.routeName : '';
  }

  getPolicyDetails() {
    let data = {
      'PgTransNo': this.storageService.getAESDecryptedDataLocal(userEnums.TransactionId)
    }
    this.subscription.push(this.policyService.getPolicyDetails(data).subscribe({
      next: (response: GetPolicyDetailsResponsePayload) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.storageService.setAESEncryptedDataLocal(userEnums.PayConfResponse, response.Data);
          // this.storageService.setAESEncryptedDataLocal(userEnums.PolicyNumber, response.Data[0].PolicyNumber);
          // this.storageService.setAESEncryptedDataLocal(userEnums.PolicyId, response.Data[0].PolicyID);
          // this.storageService.setAESEncryptedDataLocal(userEnums.EPolicyId, response.Data[0].EPolicyID);
          // this.storageService.setAESEncryptedDataLocal(userEnums.TransFor, response.Data[0].TransFor);
          // this.storageService.setAESEncryptedDataLocal(userEnums.ProposalNumber, response.Data[0].PFTransaction);
          // this.storageService.setAESEncryptedDataLocal(userEnums.TotalPremium, response.Data[0].TotalPremium);
          // this.storageService.setAESEncryptedDataLocal(userEnums.StartDate, response.Data[0].StartDate);
          // this.storageService.setAESEncryptedDataLocal(userEnums.EndDate, response.Data[0].EndDate);
          // this.storageService.setAESEncryptedDataLocal(userEnums.NRIApplicable, response.Data[0].NRIApplicable);
          // const navigationExtras: NavigationExtras = {
          //   queryParams: {
          //     isorion: this.isOrion,
          //   },
          // };
          // commented as routing is happening through agent app master data
          // this.router.navigate(['/', routeEnums.PaymentConfirmation]);
        }
      },
      error: error => {
      }
    }))
  }

  creatingToken(data: SwapParameters, name) {
    let loginRequest = Object.assign(data.loginDetails, { sessionId: this.channelData.SID || '1' });
    this.subscription.push(this.agentService_A.login(loginRequest).subscribe({
      next: (res: CreateLoginTokenResponse) => {
        if (res.StatusCode == Constants.statusCode_success) {
          this.errorOccured = false;
          this.storageService.setAESEncryptedDataLocal(userEnums.UopqksmlN, data.loginDetails.Uowkjsdjssrme);
          this.storageService.setAESEncryptedDataLocal(userEnums.Pqsxkpo, data.loginDetails.Powkjsdjssrd);
          this.storageService.setAESEncryptedData(userEnums.AuthToken, res.Data.Token);
          this.storageService.setAESEncryptedData(userEnums.UopqksmlN, data.loginDetails.Uowkjsdjssrme);
          this.storageService.setAESEncryptedData(userEnums.SessionReference, res.SessionReference);
          // Setting below as empty initially
          if (name == 'plts') {
            this.getPolicyDetails();
          } if (name = 'bncusr') {
            this.getAgentAppMasterData(data);
          }
          else {
            this.getAgentAppMasterData(data);
          }
        }
        else if (res.StatusCode == Constants.lnk_expire_logout_Code) {
          if (!this.utility_service.isEmptyOrNull(this.channelData)) {
            if (this.channelData.CN.includes('MOTOR') || this.channelData.CN.includes('MFA')) {
              window.location.href = environment.nysaMotorLoginRedirection;
              return;
            } else if (this.channelData.CN.includes('BANCA')) {
              window.location.href = environment.nysaBancaLoginRedirection;
              return;
            }
          } else {
            if (environment.environment === 'sanity' || environment.environment === 'preprod') return;
            window.location.href = environment.nysaMotorLoginRedirection;
            return;
          }
          this.router.navigate(['/', routeEnums.LOGIN]);
        }
        else {
          this.errorOccured = true;
        }
      },
      error: (err) => {
        this.errorOccured = true;
      }
    }));
  }

  redirectTo(data: SwapParameters) {
    if (data.productType === Constants.health) {
      if (this.route == 'saved-quotes') {
        this.router.navigate([`/${this.route}`]);
      } else if (this.bancauser) {
        this.router.navigate([`/${this.route}`]);
        return;
      } else if (this.route == routeEnums.DocumentUpload) {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            isorion: this.isOrion,
          },
        };
        this.router.navigate([`/${routeEnums.DocumentUpload}`], navigationExtras);
        return;
      } else {

        if (this.route == 'plts') {
          const navigationExtras: NavigationExtras = {
            queryParams: {
              isorion: this.isOrion,
            },
          };
          this.router.navigate(['/', routeEnums.PaymentConfirmation], navigationExtras);
        }
        else {
          switch (this.route) {
            case 'orion-hap':
              this.storageService.setAESEncryptedData(userEnums.IsOrionJourney, 'true');
              break;
            default:
              break;
          }
          this.router.navigate([`/${routeEnums.HEALTH}/${this.route}`]);
        }
        return;
      }
    }
    else if (data.productType === Constants.travel) {
      if (this.channelData.ST != 'dash') {
        this.router.navigate([`/${routeEnums.TRAVEL}/${this.route}`]);
        return;
      }
    }

    this.router.navigate([`/${routeEnums.HEALTH}/${routeEnums.DASHBOARD}`]);
    return;
  }

  fetchDetailsAsPerTransactionId(customerLinkTransactionId: string, isOrion: string) {
    //transactionId from swap
    this.storageService.setAESEncryptedData(userEnums.SendPaymentLinkSummaryData, customerLinkTransactionId);
    if (isOrion == 'true') {
      this.router.navigate(['/', routeEnums.OrionPaymentLinkSummary]);
    } else {
      this.router.navigate(['/', routeEnums.PaymentLinkSummary]);
    }
  }
  fetchDetailsAsPerTransactionId_EMI(customerLinkTransactionId: string) {
    //transactionId from swap
    this.storageService.setAESEncryptedData(userEnums.SendPaymentLinkSummaryData, customerLinkTransactionId);
    this.router.navigate(['/', routeEnums.EMI_Payment_Link_Summary]);
  }

  getAgentAppMasterData(data: SwapParameters) {

    forkJoin({
      agentAppMasterDataResponse: this.agentV1Service.agentAppMasterData(),
      bancsPartyCodeResponse: this.agentV1Service.getBancsPartyCode().pipe(
        catchError(error => of(error))
      )
    }).subscribe(({ agentAppMasterDataResponse, bancsPartyCodeResponse }) => {

      if (agentAppMasterDataResponse.StatusCode == Constants.statusCode_success) {

        this.errorOccured = false;
        if (bancsPartyCodeResponse.Data) {
          this.storageService.setAESEncryptedData(userEnums.OrionAgentDetails, bancsPartyCodeResponse.Data);
        }
        this.storageService.setAESEncryptedData(userEnums.AgentDetails, agentAppMasterDataResponse.Data);
        let QcFlow = agentAppMasterDataResponse?.Data?.QcFlow ? agentAppMasterDataResponse?.Data?.QcFlow : false;
        this.storageService.setAESEncryptedData(userEnums.QcFlow, QcFlow);
        this.userIdle.startWatching();
        if (data.flowType.toLowerCase() == 'renewal') {
          let SubProductInfo = this.renewalStorageService.getSubProductType(this.channelData.PN);
          const eldestDateOfBirth = this.utility_service.formateDateWithMonth(this.channelData.DOB);
          if (SubProductInfo.ProductType == 'indimnity') {
            this.renewalStorageService.indenmitySearch(this.channelData.PN, SubProductInfo.SubProductType, eldestDateOfBirth);
          } else if (SubProductInfo.ProductType == 'zerotat') {
            this.renewalStorageService.zeroTatSearch(this.channelData.PN, eldestDateOfBirth);
          } else {
            this.route = this.getRoute(this.channelData);
            this.healthDataStorageService.resetHealthDataStorage();
            this.renewalStorageService.resetRenewalDataStorage();
            this.router.navigate([`/${this.route}`]);
          }
        }
        else {
          this.redirectTo(data);
        }
      }
      else if (agentAppMasterDataResponse.StatusCode != Constants.statusCode_success) {
        this.spinner.hide();
        this.errorOccured = true;
      }
    });
  }
  
  validateOfflineCalUser(passkey) {
    let request = {
      "PassKey": passkey
    }
    this.channelData = {} as ChannelData;
    this.subscription.push(this.offlineCalculateElevateService.getValidUserData(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.uname = response.Data.UserName;
          this.pswds = response.Data.PassKey;
          this.channelData = {} as ChannelData;
          this.createLoginToken(this.channelData);
        }
      }
    }))
  }

  goToHome() {
    window.location.href = environment.logoutRedirectionUrl;
  }

  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }


  ngOnDestroy() {
    // this.unsubscribeSubscription();
  }

}