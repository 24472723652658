import { HAPModal } from "src/app/modules/health/products/hap/hap-modal";
import { NewAddOnCoverDetails } from "../interface/renewal";
import { Injectable } from "@angular/core";
import { healthMembers, healthToggle, relationEnum, titleEnum } from "../enums/healthEnums";
import { UtilityService } from "./utility.service";
import { RenewalDataStorageService } from "../components/sidemenu/renewals/renewal.service";
import { RenewalDataStorage } from "../components/sidemenu/renewals/renewal-storage.modal";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Validators } from "@angular/forms";
import { RenewalModal } from "../components/sidemenu/renewals/renewal-modal";

@Injectable({
  providedIn: 'root'
})
export class RenewalAddOnService {
  hapDataModal: HAPModal = new HAPModal(); //hap data modal 
  renewalDataModal: RenewalModal = new RenewalModal(); //hap data modal 
  addonList: NewAddOnCoverDetails[];
  PersonalAccidentList: NewAddOnCoverDetails[];
  maternityList: NewAddOnCoverDetails[];
  renewalFormData: RenewalDataStorage;

  constructor(
    private utilityService: UtilityService,
    private renewalStorageService: RenewalDataStorageService,
    private snackBar: MatSnackBar,
  ) { }
  //hap add on
  manageHapVisibility(data, storedData) {
    let policyTenure = parseInt(storedData.policyTenure) === 3;
    let worldCoverldwide = Number(storedData?.applicantAnnualSum) >= this.hapDataModal?.maxSumInsured;
    // It checks only for worldide and maternity
    this.addonList = data.map(item => {
      // It sets the visiblity of addons specific for worlwide and maternity
      if (item.CoverId === 34 || item.CoverId === 42) {
        item.IsVisible = (item.CoverId === 42) ? worldCoverldwide : policyTenure;
      } 
      return item;
    })
    this.sortArray();

    return this.addonList;
  }

  sortArray() {
    this.addonList.sort((a, b) => {
      // Categories come first
      if (a.IsAddonSelected && !b.IsAddonSelected) {
        return -1;
      } else if (!a.IsAddonSelected && b.IsAddonSelected) {
        return 1;
      } else if ((a.CoverId === 34 || a.CoverId === 39) && !(b.CoverId === 34 || b.CoverId === 39)) {
        return 1; // Place items with CoverId 34 or 39 last
      } else if (!(a.CoverId === 34 || a.CoverId === 39) && (b.CoverId === 34 || b.CoverId === 39)) {
        return -1; // Place items with CoverId 34 or 39 last
      } else {
        // If both are categories or both are not categories, maintain their original order
        return 0;
      }
    });
  }

  modifyHapAddons(maternityAddons, maternitySubProductList, personalAccidentAddons, totalMemberData) {
    // Push the addons object as per thr mrmbers if 3 members then it will have 3 addons array inside parent array
    let tempPAAddons = personalAccidentAddons.length > 0 ? personalAccidentAddons : [];
    let tempMaternityAddons = maternityAddons.length > 0 ? maternityAddons : [];
    let tempMatSubAddons = maternitySubProductList.length > 0 ? maternitySubProductList : [];
    let maternityCombinedData = [];
    maternityAddons = [];
    personalAccidentAddons = [];

    totalMemberData.forEach(totalData => {
      // For PA
      if (tempPAAddons.length > 0) {
        let finalPAData = [];
        finalPAData.push({ ...tempPAAddons[0], occupation: '' })
        personalAccidentAddons.push(finalPAData);
      }
      // For Maternity
      if (tempMaternityAddons.length > 0) {
        maternityCombinedData = [];
        maternityCombinedData.push({ ...tempMaternityAddons[0], hide: false });
        if (tempMatSubAddons.length > 0) {
          tempMatSubAddons.forEach(data => {
            maternityCombinedData.push({ ...data, hide: true });
          })
        }
        maternityAddons.push(maternityCombinedData);
      }
    })
    return [personalAccidentAddons, maternityAddons];
  }

  setSumProtectorAddonConditions(insured, selectedAddon) {
    if (!this.utilityService.isEmptyOrNull(selectedAddon)) {
      let insuredAddonsArray = insured.get('insuredAddOn');
      let sumInsuredProtector = insuredAddonsArray.value.find(x => x.CoverId == 72);
      if (!this.utilityService.isUndefinedORNull(sumInsuredProtector)) {
        let sumInusredProtectorIndex = insuredAddonsArray.value.indexOf(sumInsuredProtector);
        if (selectedAddon.CoverId == 72 && selectedAddon.IsAddonSelected) {
          insured.get("insuredAddOn").at(sumInusredProtectorIndex).get("IsAddonSelected" + sumInusredProtectorIndex).patchValue(true);
        }
        else {
          insured.get("insuredAddOn").at(sumInusredProtectorIndex).get("IsAddonSelected" + sumInusredProtectorIndex).patchValue(false);
        }
      }
    }
  }
  //CHI ADDON Conditions
  setMaternityAddonConditionsForCHI(insured) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();
    let insuredAddonsArray = insured.get('insuredAddOn');
    //Maternity condition
    let maternityCoverObj = insuredAddonsArray.value.find(x => x.CoverId == 61 ); 
    if (!this.utilityService.isUndefinedORNull(maternityCoverObj)) {
      let maternityCoverIndex = insuredAddonsArray.value.indexOf(maternityCoverObj);
      let title = insured.get('insuredTitle').value;
      let gender = insured.get('insuredGender').value;
      let relationship = insured.get('insuredRelationWithAppl').value;
      
      if ((title == titleEnum.Mrs || title == titleEnum.Ms) && (relationship == relationEnum.Self || relationship == relationEnum.Spouse)) {
        insured.get("insuredAddOn").at(maternityCoverIndex).get("IsVisible").patchValue(true);
        // insured.get("insuredAddOn").at(maternityCoverIndex).get("IsAddonSelected" + maternityCoverIndex).patchValue(false);
      }
      else {
        insured.get("insuredAddOn").at(maternityCoverIndex).get("IsVisible").patchValue(false);
        insured.get("insuredAddOn").at(maternityCoverIndex).get("IsAddonSelected" + maternityCoverIndex).patchValue(false);
      }
    }
  }
  setPersonalAccidentAddonConditionsForCHI(insured,sumInsured) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();

    let insuredAddonsArray = insured.get('insuredAddOn');
    //Personal Accident addon condition
    let personalAccidentCoverObj = insuredAddonsArray.value.find(x => x.CoverId == 60);
    if (!this.utilityService.isUndefinedORNull(personalAccidentCoverObj)) {
      let personalAccidentCoverIndex = insuredAddonsArray.value.indexOf(personalAccidentCoverObj);
      let membertype = insured.get('memberType').value;
      let insuredDOB = insured.get('insuredDOB').value;
      let insuredAge = this.utilityService.calculateAge(insuredDOB);
      let PACoverSelected = insured.get("insuredAddOn").at(personalAccidentCoverIndex).get("IsAddonSelected" + personalAccidentCoverIndex).value;
      if (sumInsured <= 5000000 && membertype == healthMembers.Adult) {
        insured.get("insuredAddOn").at(personalAccidentCoverIndex).get("IsVisible").patchValue(true);
        // insured.get("insuredAddOn").at(personalAccidentCoverIndex).get("IsAddonSelected" + personalAccidentCoverIndex).patchValue(false);
        this.setOccupationInput(insured, PACoverSelected, personalAccidentCoverIndex)

      }
      else {
        insured.get("insuredAddOn").at(personalAccidentCoverIndex).get("IsVisible").patchValue(false);
        insured.get("insuredAddOn").at(personalAccidentCoverIndex).get("IsAddonSelected" + personalAccidentCoverIndex).patchValue(false);
      }
      
      // if (PACoverSelected) { this.setOccupationInput(insured, PACoverSelected, personalAccidentCoverIndex) }
    }
  }
  //HAAP renewal & Refiling addon conditions
  setMaternityAddonConditions(insured) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();
    let insuredAddonsArray = insured.get('insuredAddOn');
    //Maternity condition
    let maternityCoverObj = this.renewalFormData.ZoneRefilingflag == healthToggle.Yes ? insuredAddonsArray.value.find(x => x.CoverId == 68) : insuredAddonsArray.value.find(x => x.CoverId == 34);
    if (!this.utilityService.isUndefinedORNull(maternityCoverObj)) {
      let maternityCoverIndex = insuredAddonsArray.value.indexOf(maternityCoverObj);
      let title = insured.get('insuredTitle').value;
      let gender = insured.get('insuredGender').value;
      let relationship = insured.get('insuredRelationWithAppl').value;
      if (this.renewalFormData.ZoneRefilingflag == healthToggle.Yes) {
        let insuredDOB = insured.get('insuredDOB').value;
        let insuredAge = this.utilityService.calculateAge(insuredDOB);
        if ((insuredAge >= 18 && insuredAge <= 45) && (gender.toLowerCase() == 'fmale' || (gender.toLowerCase() == 'mf' && title == titleEnum.Mrs || title == titleEnum.Ms))) {
          insured.get("insuredAddOn").at(maternityCoverIndex).get("IsVisible").patchValue(true);
          this.setNewBornAndVaccinationAddonConditions(insured);
          this.setPersonalAccidentAddonConditions(insured);
        }
        else {
          insured.get("insuredAddOn").at(maternityCoverIndex).get("IsVisible").patchValue(false);
          this.setNewBornAndVaccinationAddonConditions(insured);
          this.setPersonalAccidentAddonConditions(insured);
          insured.get("insuredAddOn").at(maternityCoverIndex).get("IsAddonSelected" + maternityCoverIndex).patchValue(false);
        }
      } else {
        if (this.renewalFormData.Tenure == 3 && (title == titleEnum.Mrs || title == titleEnum.Ms) && (relationship == relationEnum.Self || relationship == relationEnum.Spouse)) {
          insured.get("insuredAddOn").at(maternityCoverIndex).get("IsVisible").patchValue(true);
        }
        else {
          insured.get("insuredAddOn").at(maternityCoverIndex).get("IsVisible").patchValue(false);
          insured.get("insuredAddOn").at(maternityCoverIndex).get("IsAddonSelected" + maternityCoverIndex).patchValue(false);
        }
      }

    }
  }
  setNewBornAndVaccinationAddonConditions(insured) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();
    let insuredAddonsArray = insured.get('insuredAddOn');
    let maternityCondCoverObj = this.renewalFormData.ZoneRefilingflag == healthToggle.Yes ? insuredAddonsArray.value.find(x => x.CoverId == 68) : insuredAddonsArray.value.find(x => x.CoverId == 34);
    let maternityCoverIndex = insuredAddonsArray.value.indexOf(maternityCondCoverObj);
    let maternityVisible = insured.get("insuredAddOn").at(maternityCoverIndex).get("IsVisible").value;
    let maternitySelected = insured.get("insuredAddOn").at(maternityCoverIndex).get("IsAddonSelected" + maternityCoverIndex).value;
    if (maternityVisible && maternitySelected) {
      let newBornBabyCondCoverObj = this.renewalFormData.ZoneRefilingflag == healthToggle.Yes ? insuredAddonsArray.value.find(x => x.CoverId == 69) : insuredAddonsArray.value.find(x => x.CoverId == 35);
      let vaccinationForNewBornBabyCondCoverObj = this.renewalFormData.ZoneRefilingflag == healthToggle.Yes ? insuredAddonsArray.value.find(x => x.CoverId == 70) : insuredAddonsArray.value.find(x => x.CoverId == 36);

      if (!this.utilityService.isUndefinedORNull(newBornBabyCondCoverObj)) {
        let newBornBabyCoverIndex = insuredAddonsArray.value.indexOf(newBornBabyCondCoverObj);
        insured.get("insuredAddOn").at(newBornBabyCoverIndex).get("IsVisible").patchValue(true);
        insured.get("insuredAddOn").at(newBornBabyCoverIndex).get("IsAddonSelected" + newBornBabyCoverIndex).patchValue(true);
        insured.get("insuredAddOn").at(newBornBabyCoverIndex).get("IsAddonSelected" + newBornBabyCoverIndex).disable();
      }
      if (!this.utilityService.isUndefinedORNull(vaccinationForNewBornBabyCondCoverObj)) {
        let vaccinationForNewBornBabyCoverIndex = insuredAddonsArray.value.indexOf(vaccinationForNewBornBabyCondCoverObj);
        insured.get("insuredAddOn").at(vaccinationForNewBornBabyCoverIndex).get("IsVisible").patchValue(true);
        insured.get("insuredAddOn").at(vaccinationForNewBornBabyCoverIndex).get("IsAddonSelected" + vaccinationForNewBornBabyCoverIndex).patchValue(true);
        insured.get("insuredAddOn").at(vaccinationForNewBornBabyCoverIndex).get("IsAddonSelected" + vaccinationForNewBornBabyCoverIndex).disable();
      }
    }
    else {

      let newBornBabyCondCoverObj = this.renewalFormData.ZoneRefilingflag == healthToggle.Yes ? insuredAddonsArray.value.find(x => x.CoverId == 69) : insuredAddonsArray.value.find(x => x.CoverId == 35);
      let vaccinationForNewBornBabyCondCoverObj = this.renewalFormData.ZoneRefilingflag == healthToggle.Yes ? insuredAddonsArray.value.find(x => x.CoverId == 70) : insuredAddonsArray.value.find(x => x.CoverId == 36);

      if (!this.utilityService.isUndefinedORNull(newBornBabyCondCoverObj)) {
        let newBornBabyCoverIndex = insuredAddonsArray.value.indexOf(newBornBabyCondCoverObj);
        insured.get("insuredAddOn").at(newBornBabyCoverIndex).get("IsVisible").patchValue(false);
        insured.get("insuredAddOn").at(newBornBabyCoverIndex).get("IsAddonSelected" + newBornBabyCoverIndex).patchValue(false);
        insured.get("insuredAddOn").at(newBornBabyCoverIndex).get("IsAddonSelected" + newBornBabyCoverIndex).disable();
      }
      if (!this.utilityService.isUndefinedORNull(vaccinationForNewBornBabyCondCoverObj)) {
        let vaccinationForNewBornBabyCoverIndex = insuredAddonsArray.value.indexOf(vaccinationForNewBornBabyCondCoverObj);
        insured.get("insuredAddOn").at(vaccinationForNewBornBabyCoverIndex).get("IsVisible").patchValue(false);
        insured.get("insuredAddOn").at(vaccinationForNewBornBabyCoverIndex).get("IsAddonSelected" + vaccinationForNewBornBabyCoverIndex).patchValue(false);
        insured.get("insuredAddOn").at(vaccinationForNewBornBabyCoverIndex).get("IsAddonSelected" + vaccinationForNewBornBabyCoverIndex).disable();
      }
    }
  }
  setDomesticAirAndTeleConsultAddonConditions(insured) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();

    let insuredAddonsArray = insured.get('insuredAddOn');

    //Domestic Air Ambulance and Tele consultation addon conditions
    let domesticAirCondCoverObj = insuredAddonsArray.value.find(x => x.CoverId == 37);
    let teleCommCondCoverObj = insuredAddonsArray.value.find(x => x.CoverId == 41);
    if (!this.utilityService.isUndefinedORNull(domesticAirCondCoverObj)) {
      let domesticAirCoverIndex = insuredAddonsArray.value.indexOf(domesticAirCondCoverObj);
      insured.get("insuredAddOn").at(domesticAirCoverIndex).get("IsVisible").patchValue(true);
      insured.get("insuredAddOn").at(domesticAirCoverIndex).get("IsAddonSelected" + domesticAirCoverIndex).patchValue(true);
      insured.get("insuredAddOn").at(domesticAirCoverIndex).get("IsAddonSelected" + domesticAirCoverIndex).disable();
    }
    if (!this.utilityService.isUndefinedORNull(teleCommCondCoverObj)) {
      let teleCommCoverIndex = insuredAddonsArray.value.indexOf(teleCommCondCoverObj);
      insured.get("insuredAddOn").at(teleCommCoverIndex).get("IsVisible").patchValue(true);
      insured.get("insuredAddOn").at(teleCommCoverIndex).get("IsAddonSelected" + teleCommCoverIndex).patchValue(true);
      insured.get("insuredAddOn").at(teleCommCoverIndex).get("IsAddonSelected" + teleCommCoverIndex).disable();
    }

  }
  setWorldWideCoverAddonConditions(insured, maxSumInsuredFlag: boolean) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();

    let insuredAddonsArray = insured.get('insuredAddOn');
    let worldWideCoverObj = insuredAddonsArray.value.find(x => x.CoverId == 42);
    if (!this.utilityService.isUndefinedORNull(worldWideCoverObj)) {
      let worldWideCoverIndex = insuredAddonsArray.value.indexOf(worldWideCoverObj);
      if (maxSumInsuredFlag) {
        insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsVisible").patchValue(true);
        insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsAddonSelected" + worldWideCoverIndex).patchValue(true);
        insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsAddonSelected" + worldWideCoverIndex).disable();
      }
      else {
        insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsVisible").patchValue(false);
        insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsAddonSelected" + worldWideCoverIndex).patchValue(false);
        insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsAddonSelected" + worldWideCoverIndex).enable();
      }
    }
  }
  setPersonalAccidentAddonConditions(insured) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();

    let insuredAddonsArray = insured.get('insuredAddOn');
    //Personal Accident addon condition
    let personalAccidentCoverObj = this.renewalFormData.ZoneRefilingflag == healthToggle.Yes ? insuredAddonsArray.value.find(x => x.CoverId == 71) : insuredAddonsArray.value.find(x => x.CoverId == 39);
    if (!this.utilityService.isUndefinedORNull(personalAccidentCoverObj)) {
      let personalAccidentCoverIndex = insuredAddonsArray.value.indexOf(personalAccidentCoverObj);
      let insuredDOB = insured.get('insuredDOB').value;
      let insuredAge = this.utilityService.calculateAge(insuredDOB);
      let PACoverSelected = insured.get("insuredAddOn").at(personalAccidentCoverIndex).get("IsAddonSelected" + personalAccidentCoverIndex).value;
      if (this.renewalFormData.ZoneRefilingflag == healthToggle.Yes) {
        if (insuredAge >= 18 && insuredAge <= 65) {
          insured.get("insuredAddOn").at(personalAccidentCoverIndex).get("IsVisible").patchValue(true);
        }
        else {
          insured.get("insuredAddOn").at(personalAccidentCoverIndex).get("IsVisible").patchValue(false);
          insured.get("insuredAddOn").at(personalAccidentCoverIndex).get("IsAddonSelected" + personalAccidentCoverIndex).patchValue(false);
        }
        this.setOccupationInput(insured, PACoverSelected, personalAccidentCoverIndex)
      }
      else {
        insured.get("insuredAddOn").at(personalAccidentCoverIndex).get("IsVisible").patchValue(true);
        this.setOccupationInput(insured, PACoverSelected, personalAccidentCoverIndex)
      }
      if (PACoverSelected) { this.setOccupationInput(insured, PACoverSelected, personalAccidentCoverIndex) }
    }
  }
  setOccupationInput(insuredMember, value, addonIndex) {
    if (value) {
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").enable();
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").setValidators([Validators.required]);
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").updateValueAndValidity();
    }
    else {
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").patchValue('');
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").disable();
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").clearValidators();
      insuredMember.get("insuredAddOn").at(addonIndex).get("insuredOccupation").updateValueAndValidity();
    }
  }
  setWorldWideAddonConditions(insured, sumInsured) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();
    let insuredAddonsArray = insured.get('insuredAddOn');
    let worldWideCover = insuredAddonsArray.value.find(x => x.CoverId == 63);
    if (!this.utilityService.isUndefinedORNull(worldWideCover)) {
      let worldWideCoverIndex = insuredAddonsArray.value.indexOf(worldWideCover);
      if (this.renewalFormData.SubProductType == this.renewalDataModal.subProductType.hep || this.renewalFormData.SubProductType == this.renewalDataModal.subProductType.hsp) {
      if (parseInt(sumInsured) >= 2500000) {
        insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsVisible").patchValue(true);
        // insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsAddonSelected" + worldWideCoverIndex).patchValue(true);
        // insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsAddonSelected" + worldWideCoverIndex).disable();
      }
      else {
        insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsVisible").patchValue(false);
        // insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsAddonSelected" + worldWideCoverIndex).patchValue(false);
      }
    }
    else if (this.renewalFormData.SubProductType == this.renewalDataModal.subProductType.he || this.renewalFormData.SubProductType == this.renewalDataModal.subProductType.hs) {
      if (parseInt(sumInsured) >= 5000000) {
        insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsVisible").patchValue(true);
        // insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsAddonSelected" + worldWideCoverIndex).patchValue(true);
        // insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsAddonSelected" + worldWideCoverIndex).disable();
      }
      else {
        insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsVisible").patchValue(false);
        // insured.get("insuredAddOn").at(worldWideCoverIndex).get("IsAddonSelected" + worldWideCoverIndex).patchValue(false);
      }
    }
    }
  }
  setClaimProtectorConditions(insured, claimProtectorAddon, selectAddons) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();

    let insuredAddonsArray = insured.get('insuredAddOn');
    let claimProtectorCoverObj = insuredAddonsArray.value.find(x => x.CoverId == 73);
    if (!this.utilityService.isUndefinedORNull(claimProtectorCoverObj)) {
      let claimProtectorCoverIndex = insuredAddonsArray.value.indexOf(claimProtectorCoverObj);
      if (Number(this.renewalFormData.SumInsured) >= 1500000) {
        insured.get("insuredAddOn").at(claimProtectorCoverIndex).get("IsVisible").patchValue(true);
        insured.get("insuredAddOn").at(claimProtectorCoverIndex).get("IsAddonSelected" + claimProtectorCoverIndex).patchValue(true);
        insured.get("insuredAddOn").at(claimProtectorCoverIndex).get("IsAddonSelected" + claimProtectorCoverIndex).disable();
      }
      else {
        insured.get("insuredAddOn").at(claimProtectorCoverIndex).get("IsVisible").patchValue(true);
        if (!claimProtectorAddon && !selectAddons) {
          insured.get("insuredAddOn").at(claimProtectorCoverIndex).get("IsAddonSelected" + claimProtectorCoverIndex).patchValue(false);
        }
        if (claimProtectorAddon || selectAddons) {
          insured.get("insuredAddOn").at(claimProtectorCoverIndex).get("IsAddonSelected" + claimProtectorCoverIndex).patchValue(true);
        }
        insured.get("insuredAddOn").at(claimProtectorCoverIndex).get("IsAddonSelected" + claimProtectorCoverIndex).enable();
      }
    }
  }
  setCompansitateVisitConditions(insured, CompansitateVisitAddon, selectAddons) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();
    let insuredAddonsArray = insured.get('insuredAddOn');
    let compansitateVisitCoverObj = insuredAddonsArray.value.find(x => x.CoverId == 74);
    if (!this.utilityService.isUndefinedORNull(compansitateVisitCoverObj)) {
      let compansitateVisitCoverIndex = insuredAddonsArray.value.indexOf(compansitateVisitCoverObj);
      if (Number(this.renewalFormData.SumInsured) >= 10000000) {
        insured.get("insuredAddOn").at(compansitateVisitCoverIndex).get("IsAddonSelected" + compansitateVisitCoverIndex).patchValue(true);
        insured.get("insuredAddOn").at(compansitateVisitCoverIndex).get("IsVisible").patchValue(true);

        insured.get("insuredAddOn").at(compansitateVisitCoverIndex).get("IsAddonSelected" + compansitateVisitCoverIndex).disable();
      }
      else {
        insured.get("insuredAddOn").at(compansitateVisitCoverIndex).get("IsVisible").patchValue(true);
        if (!CompansitateVisitAddon || !selectAddons) {
          insured.get("insuredAddOn").at(compansitateVisitCoverIndex).get("IsAddonSelected" + compansitateVisitCoverIndex).patchValue(false);
        }
        if (CompansitateVisitAddon || selectAddons) {
          insured.get("insuredAddOn").at(compansitateVisitCoverIndex).get("IsAddonSelected" + compansitateVisitCoverIndex).patchValue(true);
        }
        insured.get("insuredAddOn").at(compansitateVisitCoverIndex).get("IsAddonSelected" + compansitateVisitCoverIndex).enable();
      }
    }
  }
  setNursingAtHomeConditions(insured, nursingAtHomeAddon, selectAddons) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();

    let insuredAddonsArray = insured.get('insuredAddOn');
    let nursingAtHomeCoverObj = insuredAddonsArray.value.find(x => x.CoverId == 75);
    if (!this.utilityService.isUndefinedORNull(nursingAtHomeCoverObj)) {
      let nursingAtHomeCoverIndex = insuredAddonsArray.value.indexOf(nursingAtHomeCoverObj);
      if (Number(this.renewalFormData.SumInsured) >= 10000000) {
        insured.get("insuredAddOn").at(nursingAtHomeCoverIndex).get("IsVisible").patchValue(true);
        insured.get("insuredAddOn").at(nursingAtHomeCoverIndex).get("IsAddonSelected" + nursingAtHomeCoverIndex).patchValue(true);
        insured.get("insuredAddOn").at(nursingAtHomeCoverIndex).get("IsAddonSelected" + nursingAtHomeCoverIndex).disable();
      }
      else {
        insured.get("insuredAddOn").at(nursingAtHomeCoverIndex).get("IsVisible").patchValue(true);
        if (!nursingAtHomeAddon || !selectAddons) {
          insured.get("insuredAddOn").at(nursingAtHomeCoverIndex).get("IsAddonSelected" + nursingAtHomeCoverIndex).patchValue(false);
        }
        if (nursingAtHomeAddon || selectAddons) {
          insured.get("insuredAddOn").at(nursingAtHomeCoverIndex).get("IsAddonSelected" + nursingAtHomeCoverIndex).patchValue(true);
        }
        insured.get("insuredAddOn").at(nursingAtHomeCoverIndex).get("IsAddonSelected" + nursingAtHomeCoverIndex).enable();
      }
    }
  }
  //HAAP refiling addon conditions
  setCriticalIllnessAddonConditions(insured, selectedPED, patchAddonValue?) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();

    let insuredAddonsArray = insured.get('insuredAddOn');
    //Personal Accident addon condition
    let criticalIllnessCoverObj = insuredAddonsArray.value.find(x => x.CoverId == 67);
    if (!this.utilityService.isUndefinedORNull(criticalIllnessCoverObj)) {
      let criticalIllnessCoverIndex = insuredAddonsArray.value.indexOf(criticalIllnessCoverObj);
      let insuredDOB = insured.get('insuredDOB').value;
      let insuredAge = this.utilityService.calculateAge(insuredDOB);
      if (insuredAge >= 18 && insuredAge <= 50 && !selectedPED) {
        insured.get("insuredAddOn").at(criticalIllnessCoverIndex).get("IsVisible").patchValue(true);
        if (patchAddonValue)
          insured.get("insuredAddOn").at(criticalIllnessCoverIndex).get("IsAddonSelected" + criticalIllnessCoverIndex).patchValue(false);
      }
      else {
        insured.get("insuredAddOn").at(criticalIllnessCoverIndex).get("IsAddonSelected" + criticalIllnessCoverIndex).patchValue(false);
        insured.get("insuredAddOn").at(criticalIllnessCoverIndex).get("IsVisible").patchValue(false);
      }

    }
  }
  //HEP addon condition
  setHEPAddonCondition(insured) {
    let insuredAddonsArray = insured.get('insuredAddOn');
    let befitAddons = insuredAddonsArray.value.filter(x => [47, 48, 49, 50, 51, 52, 53].includes(x.CoverId) && x.IsVisible);
    return befitAddons;
  }

  //HAP befit addon condition
  setHAPAddonCondition(insured) {
    let insuredAddonsArray = insured.get('insuredAddOn');
    let befitAddons = insuredAddonsArray.value.filter(x => [76, 77, 78, 79, 80, 81, 82, 83].includes(x.CoverId) && x.IsVisible);
    return befitAddons;
  }

  //GS addon condition
  setGSAddonCondition(selectedCover, insured) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();

    let insuredAddonArray = insured.get('insuredAddOn');

    //VD addon condition
    let vdAddon = insuredAddonArray.value.find(x => [29].includes(x.CoverId));
    let copayAddon = insuredAddonArray.value.find(x => [28].includes(x.CoverId));
    let copayAddonIndex = insuredAddonArray.value.indexOf(copayAddon);
    let vdAddonIndex = insuredAddonArray.value.indexOf(vdAddon);
    if (selectedCover == 29 && !this.utilityService.isEmptyOrNull(vdAddon) && vdAddon[`IsAddonSelected` + vdAddonIndex]) {
      let sumInsuredObj = this.renewalFormData.SumInsuredDetails.find(x => x.SumAmount == this.renewalFormData.SumInsured);
      this.renewalFormData.VoluntaryDeductible = sumInsuredObj.VDValues ? Number(sumInsuredObj.VDValues[0].value) : 0;
      insured.get("insuredAddOn").at(vdAddonIndex).get("insuredVDValue").enable();
      insured.get("insuredAddOn").at(vdAddonIndex).get("insuredVDValue").setValidators([Validators.required]);
      insured.get("insuredAddOn").at(vdAddonIndex).get("insuredVDValue").patchValue(this.renewalFormData.VoluntaryDeductible);
      insured.get("insuredAddOn").at(vdAddonIndex).get("insuredVDValue").updateValueAndValidity();
      //copay unselect and disabled condition
      insured.get("insuredAddOn").at(copayAddonIndex).get("IsAddonSelected" + insuredAddonArray.value.indexOf(copayAddon)).patchValue(false);
      insured.get("insuredAddOn").at(copayAddonIndex).get("insuredCoPay").patchValue('');
      insured.get("insuredAddOn").at(copayAddonIndex).get("insuredCoPay").disable();
      insured.get("insuredAddOn").at(copayAddonIndex).get("insuredCoPay").clearValidators();
      insured.get("insuredAddOn").at(copayAddonIndex).get("insuredCoPay").updateValueAndValidity();
    }
    //CO-PAYMENT addon condition
    if (selectedCover == 28 && !this.utilityService.isEmptyOrNull(copayAddon) && copayAddon[`IsAddonSelected` + copayAddonIndex]) {
      insured.get("insuredAddOn").at(copayAddonIndex).get("insuredCoPay").patchValue(Number(this.renewalFormData.VoluntaryCopay));
      insured.get("insuredAddOn").at(copayAddonIndex).get("insuredCoPay").enable();
      insured.get("insuredAddOn").at(copayAddonIndex).get("insuredCoPay").setValidators([Validators.required]);
      insured.get("insuredAddOn").at(copayAddonIndex).get("insuredCoPay").updateValueAndValidity();
      //vd unselect and disabled condition
      insured.get("insuredAddOn").at(vdAddonIndex).get("IsAddonSelected" + insuredAddonArray.value.indexOf(vdAddon)).patchValue(false);
      insured.get("insuredAddOn").at(vdAddonIndex).get("insuredVDValue").patchValue(0);
      insured.get("insuredAddOn").at(vdAddonIndex).get("insuredVDValue").disable();
      insured.get("insuredAddOn").at(vdAddonIndex).get("insuredVDValue").clearValidators();
      insured.get("insuredAddOn").at(vdAddonIndex).get("insuredVDValue").updateValueAndValidity();
    }
    this.renewalStorageService.setRenewalDataStorage(this.renewalFormData);
  }

  //HB addon conditions
  setHealthBoosterAddonConditions(insured) {
    this.renewalFormData = this.renewalStorageService.getRenewalDataStorage();

    let insuredAddonsArray = insured.get('insuredAddOn');
    let membertype = insured.get('memberType').value;
    insuredAddonsArray.controls.forEach((addon: any, index) => {
      if ([15, 16, 17, 18].includes(addon.value.CoverId)) {
        if (membertype == healthMembers.Adult) {
          insured.get("insuredAddOn").at(index).get("IsVisible").patchValue(true);
          if (addon.value.CoverId == 15 && insured.get("insuredAddOn").at(index).get("IsAddonSelected" + index).value) {
            let silverIndex = insured.get("insuredAddOn").value.findIndex(x => x.CoverId == 16);
            insured.get("insuredAddOn").at(silverIndex).get("IsAddonSelected" + silverIndex).patchValue(false);
          } else if (addon.value.CoverId == 16 && insured.get("insuredAddOn").at(index).get("IsAddonSelected" + index).value) {
            let silverIndex = insured.get("insuredAddOn").value.findIndex(x => x.CoverId == 15);
            insured.get("insuredAddOn").at(silverIndex).get("IsAddonSelected" + silverIndex).patchValue(false);
          }
          if (addon.value.CoverId == 17 && insured.get("insuredAddOn").at(index).get("IsAddonSelected" + index).value) {
            let silverIndex = insured.get("insuredAddOn").value.findIndex(x => x.CoverId == 18);
            insured.get("insuredAddOn").at(silverIndex).get("IsAddonSelected" + silverIndex).patchValue(false);
          } else if (addon.value.CoverId == 18 && insured.get("insuredAddOn").at(index).get("IsAddonSelected" + index).value) {
            let silverIndex = insured.get("insuredAddOn").value.findIndex(x => x.CoverId == 17);
            insured.get("insuredAddOn").at(silverIndex).get("IsAddonSelected" + silverIndex).patchValue(false);
          }
        } else {
          insured.get("insuredAddOn").at(index).get("IsVisible").patchValue(false);
          insured.get("insuredAddOn").at(index).get("IsAddonSelected" + index).patchValue(false);
          insured.get("insuredAddOn").at(index).get("IsAddonSelected" + index).disable();
        }
      } else {
        insured.get("insuredAddOn").at(index).get("IsVisible").patchValue(true);
        if (addon.value.CoverId == 13 && insured.get("insuredAddOn").at(index).get("IsAddonSelected" + index).value) {
          let silverIndex = insured.get("insuredAddOn").value.findIndex(x => x.CoverId == 14);
          insured.get("insuredAddOn").at(silverIndex).get("IsAddonSelected" + silverIndex).patchValue(false);
        } else if (addon.value.CoverId == 14 && insured.get("insuredAddOn").at(index).get("IsAddonSelected" + index).value) {
          let goldIndex = insured.get("insuredAddOn").value.findIndex(x => x.CoverId == 13);
          insured.get("insuredAddOn").at(goldIndex).get("IsAddonSelected" + goldIndex).patchValue(false);
        }
      }
    })
  }

  //max addon condition
  setMAXAddonCondition(insured, SubProductCode, classicTopremium, tag) {
    let insuredAddonsArray = insured.get('insuredAddOn');
    let classicMaxAddons1 = insuredAddonsArray.value.find(x => [64].includes(x.CoverId));
    let classicMaxAddons2 = insuredAddonsArray.value.find(x => [65].includes(x.CoverId));
    let premiumMaxAddons = insuredAddonsArray.value.find(x => [66].includes(x.CoverId));
    if (!this.utilityService.isEmptyOrNull(premiumMaxAddons) && !classicTopremium) {
      let premiummIndexAddon = insuredAddonsArray.value.indexOf(premiumMaxAddons)
      if (parseInt(SubProductCode) == 1135 && (insured.get("insuredAddOn").at(premiummIndexAddon).get("IsAddonSelected" + premiummIndexAddon).value || insured.get("insuredAddOn").at(premiummIndexAddon).get("IsVisible").value)) {
        insured.get("insuredAddOn").at(premiummIndexAddon).get("IsAddonSelected" + premiummIndexAddon).patchValue(true);
        insured.get("insuredAddOn").at(premiummIndexAddon).get("IsAddonSelected" + premiummIndexAddon).disable();
        return false;
      } else {
        insured.get("insuredAddOn").at(premiummIndexAddon).get("IsAddonSelected" + premiummIndexAddon).patchValue(false);
        insured.get("insuredAddOn").at(premiummIndexAddon).get("IsVisible").patchValue(false);
        return true;
      }
    }
    else if (!this.utilityService.isUndefinedORNull(classicMaxAddons1) && !this.utilityService.isUndefinedORNull(classicMaxAddons2)) {
      let maxAddons1Index = insuredAddonsArray.value.indexOf(classicMaxAddons1);
      let maxAddons2Index = insuredAddonsArray.value.indexOf(classicMaxAddons2);
      let premiummIndexAddon = insuredAddonsArray.value.indexOf(premiumMaxAddons)
      //premium plan 
      if (parseInt(SubProductCode) == 1135) {
        if (classicTopremium) {
          let cwaitingPeriod = insured.get("insuredAddOn").at(maxAddons1Index).get("IsAddonSelected" + maxAddons1Index).value;
          if (cwaitingPeriod) {
            insured.get("insuredAddOn").at(premiummIndexAddon).get("IsAddonSelected" + premiummIndexAddon).patchValue(true);
            insured.get("insuredAddOn").at(premiummIndexAddon).get("IsAddonSelected" + premiummIndexAddon).disable();
            insured.get("insuredAddOn").at(premiummIndexAddon).get("IsVisible").patchValue(true);
            insured.get("insuredAddOn").at(maxAddons1Index).get("IsVisible").patchValue(false);
            insured.get("insuredAddOn").at(maxAddons1Index).get("IsAddonSelected" + maxAddons1Index).patchValue(false);
            insured.get("insuredAddOn").at(maxAddons2Index).get("IsVisible").patchValue(false);
            insured.get("insuredAddOn").at(maxAddons2Index).get("IsAddonSelected" + maxAddons2Index).patchValue(false);
            return false;
          } else {
            insured.get("insuredAddOn").at(maxAddons1Index).get("IsVisible").patchValue(false);
            insured.get("insuredAddOn").at(maxAddons1Index).get("IsAddonSelected" + maxAddons1Index).patchValue(false);
            insured.get("insuredAddOn").at(maxAddons2Index).get("IsVisible").patchValue(false);
            insured.get("insuredAddOn").at(maxAddons2Index).get("IsAddonSelected" + maxAddons2Index).patchValue(false);
            if (insured.get("insuredAddOn").at(premiummIndexAddon).get("IsAddonSelected" + premiummIndexAddon).value) {
              insured.get("insuredAddOn").at(premiummIndexAddon).get("IsAddonSelected" + premiummIndexAddon).disable();
              return false;
            } else {
              insured.get("insuredAddOn").at(premiummIndexAddon).get("IsAddonSelected" + premiummIndexAddon).patchValue(false);
              insured.get("insuredAddOn").at(premiummIndexAddon).get("IsVisible").patchValue(false);
              return true;
            }
          }
        }

      }
      //classic plan
      else {
        if (!this.utilityService.isUndefinedORNull(premiummIndexAddon) && premiummIndexAddon != -1) {
          let pwaiting = insured.get("insuredAddOn").at(premiummIndexAddon).get("IsAddonSelected" + premiummIndexAddon).value;
          if (pwaiting) {
            insured.get("insuredAddOn").at(maxAddons1Index).get("IsAddonSelected" + maxAddons1Index).patchValue(true);
            insured.get("insuredAddOn").at(maxAddons1Index).get("IsAddonSelected" + maxAddons1Index).disable();
            insured.get("insuredAddOn").at(maxAddons1Index).get("IsVisible").patchValue(true);
          } else {
            insured.get("insuredAddOn").at(maxAddons1Index).get("IsVisible").patchValue(false);
          }
        }
        let cwaitingPeriod = insured.get("insuredAddOn").at(maxAddons1Index).get("IsAddonSelected" + maxAddons1Index).value;
        if (cwaitingPeriod) {
          insured.get("insuredAddOn").at(maxAddons1Index).get("IsAddonSelected" + maxAddons1Index).patchValue(true);
          insured.get("insuredAddOn").at(maxAddons1Index).get("IsAddonSelected" + maxAddons1Index).disable();
          insured.get("insuredAddOn").at(maxAddons1Index).get("IsVisible").patchValue(true);
        } else {
          insured.get("insuredAddOn").at(maxAddons1Index).get("IsAddonSelected" + maxAddons1Index).patchValue(false);
          insured.get("insuredAddOn").at(maxAddons1Index).get("IsVisible").patchValue(false);
        }
        insured.get("insuredAddOn").at(maxAddons2Index).get("IsVisible").patchValue(true);
        if (tag == 'createForm') {
          insured.get("insuredAddOn").at(maxAddons2Index).get("IsAddonSelected" + maxAddons2Index).patchValue(true);
        }
        if (!this.utilityService.isUndefinedORNull(premiumMaxAddons)) {
          let premiummIndexAddon = insuredAddonsArray.value.indexOf(premiumMaxAddons)
          insured.get("insuredAddOn").at(premiummIndexAddon).get("IsVisible").patchValue(false);
          insured.get("insuredAddOn").at(premiummIndexAddon).get("IsAddonSelected" + premiummIndexAddon).patchValue(false);
        }
      }
    }
    return false;
  }

}