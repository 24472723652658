<form [formGroup]="instantRenewalForm">
    <div class="container-fluid">
        <div class="row">
            <div class="container panaMaincontainer">
                <div class="mainDiv">
                    <div class="steppermainDiv marginbox">
                        <div class="myTransactionTitle">
                            <span class="cstmstep"><img src="../../../../../assets/images/MyTransactionIcon/Renewal.svg"
                                    alt=""></span>
                            <span class="cstmstep">
                                <h3>Instant Renewal</h3>
                            </span>
                        </div>
                        <!-- tab content start here -->
                        <div class="tabInnerdiv instantTab mb-0 p-3 fieldsdiv">
                            <div class="row">
                                <div class="col-sm-3 pendingPayCol3 form-group mb0">
                                    <mat-label class="input-group__label">ICICI Lombard Policy Number
                                    </mat-label>
                                    <input class="input-group__input" formControlName="policyNumber"
                                        placeholder="Search ICICI Lombard Policy Number">
                                    <mat-error *ngIf="hasError('policyNumber', 'required') && formSubmitted">
                                        Policy Number is required
                                    </mat-error>

                                </div>
                                <div class="col-sm-3 pendingPayCol2 form-group">
                                    <mat-label class="input-group__label">{{labelString}}</mat-label>
                                    <input class="input-group__input dob" matInput [matDatepicker]="startDate"
                                        [max]="bothDate" id="adultDetails" formControlName="customerDOB"
                                        placeholder="DD/MM/YYYY" (click)="startDate.open()" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="startDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #startDate touchUi>
                                    </mat-datepicker>
                                </div>
                                <div class="col-sm-2 form-group d-md-block d-none">
                                    <button class="search-button" (click)="searchDetails()">
                                        Renew Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer d-md-none borderStyling">
            <div class="col-sm-12 text-right">
                <button mat-raised-button class="primary-button btn-lg btn-block" style="min-width: 140px;"
                    (click)="searchDetails()">Renew Now</button>
            </div>
        </div>
    </div>
</form>
