import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HealthDataStorage } from 'src/app/modules/health/health.modal';
import { Constants } from '../../constants/constants';
import { routeEnums } from '../../enums/routeEnums';
import { userEnums } from '../../enums/userEnums';
import { PolicyDetails } from '../../interface/policy';
import { DownloadService } from '../../services/download.service';
import { StorageService } from '../../services/storage.service';
import { UtilityService } from '../../services/utility.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTooltip } from '@angular/material/tooltip';

export interface PaymentConfirmationResponse {
  PfTransactionId: string;
  PolicyNumber: string[];
  PolicyID: string[];
  EPolicyID: string[];
  TransFor: string[];
  PfProposalNumber: string[];
  TotalPremium: number;
  StartDate: string;
  EndDate: string;
  NRIApplicable: string[];
}

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})

export class PaymentConfirmationComponent {
  private subscription: Subscription[] = [];
  healthFormData: HealthDataStorage;
  loadContent: boolean = false;
  bflag: string;
  hideDashboard: boolean = false;
  isOrion: string = '';
  responseLength: number = 1;
  isCopied = false; // Track copy status for tooltip message
  payConf: PaymentConfirmationResponse = {
    PfTransactionId: '',
    PolicyNumber: [],
    PolicyID: [],
    EPolicyID: [],
    TransFor: [],
    PfProposalNumber: [],
    TotalPremium: 0,
    StartDate: '',
    EndDate: '',
    NRIApplicable: []
  };

  constructor(
    public utilityService: UtilityService,
    private router: Router,
    private storageService: StorageService,
    private activeRoute: ActivatedRoute,
    private downloadService: DownloadService,
    private clipboard: Clipboard) {
  }

  copyText(text: string, tooltip: MatTooltip) {
    this.clipboard.copy(text);
    this.isCopied = true;

    // Manually trigger tooltip refresh to show the "Text copied successfully!" message
    tooltip.show();

    // Reset tooltip message and hide tooltip after 1 seconds
    setTimeout(() => {
        this.isCopied = false;
        tooltip.hide(); // Hide tooltip after 1 seconds
    }, 1000);
  }

  ngOnInit() {
    //this.testData()

    this.activeRoute.queryParams.forEach(param => {
      this.isOrion = !this.utilityService.isEmptyOrNull(param['isorion']) ? param['isorion'] : '';      
    })

    this.bflag = this.storageService.getAESDecryptedDataLocal('ctrbflagth')

    const response: PolicyDetails[] = this.storageService.getAESDecryptedDataLocal(userEnums.PayConfResponse);
    if (response) {
      this.responseLength = response.length;

      this.payConf.PfTransactionId = this.storageService.getAESDecryptedDataLocal(userEnums.TransactionId);
      this.payConf.TotalPremium = response[0].TotalPremium;
      this.payConf.StartDate = response[0].StartDate;
      this.payConf.EndDate = response[0].EndDate;

      for (const data of response) {
        this.payConf.PolicyNumber.push(data.PolicyNumber);
        this.payConf.PolicyID.push(data.PolicyID);
        this.payConf.EPolicyID.push(data.EPolicyID);
        this.payConf.TransFor.push(data.TransFor);
        this.payConf.PfProposalNumber.push(data.PFTransaction);
        this.payConf.NRIApplicable.push(data.NRIApplicable);
      }

      if (this.payConf.PolicyID[0]) {
        this.storageService.setAESEncryptedDataLocal(userEnums.MyPolicyDetails, Number(this.payConf.PolicyID[0]));
      }

      if (this.responseLength == 2) {
        const TransFor = this.payConf.TransFor[0] == 'R' || this.payConf.TransFor[1] == 'R' ? 'R' : this.payConf.TransFor[0];
        const NRIApplicable = this.payConf.NRIApplicable[0] == 'Yes' || this.payConf.NRIApplicable[1] == 'Yes' ? 'Yes' : this.payConf.NRIApplicable[0];
        this.storageService.setAESEncryptedDataLocal(userEnums.TransFor, TransFor);
        this.storageService.setAESEncryptedDataLocal(userEnums.NRIApplicable, NRIApplicable);
      } else {
        this.storageService.setAESEncryptedDataLocal(userEnums.TransFor, this.payConf.TransFor[0]);
        this.storageService.setAESEncryptedDataLocal(userEnums.NRIApplicable, this.payConf.NRIApplicable[0]);
      }

      this.loadContent = true;

    } else {
      this.loadContent = false;
    }
  }

  testData() {
    this.storageService.setAESEncryptedDataLocal('ctrbflagth', 'false');
    const data = [
      {
        "PolicyNumber": "4193i/APRN/52891786/00/000",
        "PolicyID": "15213033",
        "EPolicyID": "QMZQkbMsFy7U1vUPya6GjA%3d%3d",
        "TransFor": "R",
        "PFTransaction": "2172054989",
        "StartDate": "28-Oct-2024",
        "EndDate": "27-Oct-2025",
        "TotalPremium": 32178.0,
        "NRIApplicable": "No"
      },
      {
        "PolicyNumber": "4119/IP/52891787/00/000",
        "PolicyID": "15213034",
        "EPolicyID": "U4ac9cijMGYvmqhXo7960A%3d%3d",
        "TransFor": "N",
        "PFTransaction": "2172054990",
        "StartDate": "28-Oct-2024",
        "EndDate": "27-Oct-2025",
        "TotalPremium": 32178.0,
        "NRIApplicable": "Yes"
      }
    ]
    this.storageService.setAESEncryptedDataLocal(userEnums.PayConfResponse, data);
  }

  goToDashboard() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        dash: 'dash',
        isorion: this.isOrion
      },
    };
    this.router.navigate(['/', routeEnums.SWAP], navigationExtras)
    // this.router.navigate([routeEnums.HEALTH + '/' + routeEnums.DASHBOARD]);
  }

  goToUploadDocument() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        dash: 'upload',
        isorion: this.isOrion
      },
    };
    this.router.navigate(['/', routeEnums.SWAP], navigationExtras)
    // this.router.navigate([routeEnums.DocumentUpload]);
  }

  downloadPolicy(ePolicyId: string, policyNo: string): void {
    if (!this.utilityService.isEmptyOrNull(ePolicyId) && !this.utilityService.isEmptyOrNull(policyNo)) {
      const params = {
        endPoint: this.isOrion == 'true' ? 'BancsPolicy/BancsPDFDownload' : 'File/DownloadPDF',
        body:
        {
          type: 'POLICY',
          value: ePolicyId.trim()
        }
      }
      this.subscription.push(this.downloadService.generatePDF(params.endPoint, params.body).subscribe({
        next: (response) => {
          if (response.StatusCode == Constants.statusCode_success) {
            this.downloadService.downloadPdf(response.Data, policyNo)
          }
        },
      }))
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

}