import { Component, ElementRef, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subscription, debounceTime, distinctUntilChanged, Observable, concatMap, forkJoin, of } from 'rxjs';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { Constants } from 'src/app/shared/constants/constants';
import { patterns, healthDays, healthToggle, healthMembers, healthProposalProductCode, insuredControls } from 'src/app/shared/enums/healthEnums';
import { popupDescriptionMsg, popupType, popupImgPath, popupHeader, popupButton } from 'src/app/shared/enums/popupEnums';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { GetCibilScoreRequestPayload } from 'src/app/shared/interface/agent';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { ElevateMasterResponsePayload, ElevateBUIServiceAddonResponse, ElevateAddonsRequestPayload, ElevateAddonsResponsePayload, DropdownAddonList, ElevateMasterData, SumInsuredDetails } from 'src/app/shared/interface/elevate';
import { ZoneRefillingRequestPayload, ZoneRefillingResponsePayload, ElevateAddonCover, SaveQuoteResponseStructure } from 'src/app/shared/interface/health-advantedge';
import { RelationshipResponsePayload, RelationshipRequestPayload } from 'src/app/shared/interface/healthMaster';
import { IncomeRange } from 'src/app/shared/interface/max-protect';
import { NewAddOnCoverDetails } from 'src/app/shared/interface/renewal';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { ComboQuoteResponsePayload, SaveEditQuoteHapV1Request, TopupAndComboQuoteRequest } from 'src/app/shared/interface/transactionHealthAdvantege';
import { AgentService } from 'src/app/shared/proxy-services/agent.service';
import { ElevateService } from 'src/app/shared/proxy-services/elevate.service';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { WindowResizeService } from 'src/app/shared/services/window-resize.service';
import { HealthDataStorage } from '../../../health.modal';
import { HealthDataStorageService } from '../../../health.service';
import { ElevateParentAddonDetails, ElevateAddonStructure, UniqueGroups } from '../elevate-modal';
import { superSaverModal } from './saver-booster.modal';
import { Router } from '@angular/router';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
declare var $: any;

@Component({
  selector: 'app-saver-booster',
  templateUrl: './saver-booster.component.html',
  styleUrls: ['./saver-booster.component.scss']
})

// Below component is the combination of super saver and activate booster 
// Which extends the elevate base product

export class SaverBoosterComponent {
  saverBoosterForm: FormGroup;
  subscription: Subscription[] = [];
  cityData = [];
  superSaverDataModal: superSaverModal = new superSaverModal();
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  zoneRefilingData: string[];
  formSubmitted = false;
  adultCountData = this.superSaverDataModal.adultCount;
  childCountData = this.superSaverDataModal.childCount;
  adult2DateError: boolean = false;
  baseCoverSelected: boolean = false;
  invalidPinCodeMessage: string = '';
  adultRelationShips = [];
  childRelationShips = [];
  befitData = [];
  genderData = [];
  sumInsuredDetailsTemp = ['10L:90L', '15L:85L'];
  previousFormValue = {};
  cibilState = {};
  changeInCover: boolean = false;
  multipleServiceError: boolean = false;
  hasPedAddons: boolean = false;
  mobileViewActivated: boolean = false;
  moreAddonActivatedCount: number = 0;
  formValidCounter: number = 0;
  sumInsuredCounter: number = 1;
  elevateMasterData: ElevateMasterData;
  dealId: string = '';
  sumInsuredDetails = [];
  policyRangeData: [];
  occupationMaster = [];
  policyRange = [];
  memberData = [];
  selfMemberExists: boolean = false;
  showMoreAddonsOccupation: boolean = false;
  showMoreCriticaldatePicker: boolean = false;
  addonsReceivedFromService = [];
  policyPlan = [];
  elevateAddons: ElevateParentAddonDetails[] = [];
  elevateAddonsCopy: ElevateParentAddonDetails[] = [];
  coPaymentDetails: string[];
  annualIncome: IncomeRange[] = [];
  adultValue: number = 1;
  childValue: number = 0;
  biuServiceCounter = 0;
  tabImages = this.superSaverDataModal.addonGroupIcons;
  selectedTabImg: string[];
  addonGroupDesc = this.superSaverDataModal.addonGroupDesc;
  buttonLabel: string = 'Generate Quote';

  // Initially we need to assign coverdesc and cover id
  // Below there is a check which import cover desc and cover id dynamically
  coverDesc = this.superSaverDataModal.superSaverCoverDesc;
  coverId = this.superSaverDataModal.superSaverCoverId;

  validationOperation = this.superSaverDataModal.validationOperation;
  maternityAdultData: Array<{ 'id': number, 'label': string }> = [];
  criticalAdultData: Array<{ 'id': number, 'label': string }> = [];
  annualAdultData: Array<{ 'id': number, 'label': string }> = [];
  channelData: ChannelData;
  stateDetails = this.superSaverDataModal.stateDetails;
  zoneMappedWithPincode = '';
  cibilSubmitted: boolean = false;
  cibilScore: number = 0;
  bgColor: string = '#EC6608';
  textColor: string = '#282828';
  healthStorageForm: HealthDataStorage;
  tenureDuration: number = 0;
  emiPremium: string = '';
  netPremium: string = '';
  taxAmount: string = '';
  errorPopupData: PopupModal;
  zoneOption: string[];
  zoneUpgradeVisible: boolean = false;
  quoteDataSet: boolean = false;
  patchingDone: boolean = false;
  befitReturnedFromService: boolean = false;
  multipleMemberDropDown = [];
  reductionCoverId = [];
  dropDownControlname = [];
  rootNonDropdownAddons = [];
  zoneList = this.superSaverDataModal.elevateZone;
  summaryAddons = [];
  neutralGender = ['SELF', 'SPOUSE', 'EMPLOYEE'];
  maternityNeutralRelation = ['SELF', 'SPOUSE'];
  childMinMaxDate: Array<{ maxDate: Date; minDate: Date; }> = [];
  elevateBUIAddon = [];
  selectedPlan: any;
  elevateAllAddons = [];
  elevateAllAddonsCopy = [];
  plans: { CartType: string; AddOnCoverDetails: { CoverText: string; IsAddonSelected: boolean; }[]; }[];
  elevateBUICovertextAddons: any[];
  BUIresponse: boolean = false;
  hideBUIService: boolean = true;
  backButtonNeeded: boolean = false;
  showCustomAddons: boolean = true;
  showSelectedAddons: boolean = true;
  selectedElevateGroup: ElevateParentAddonDetails;
  elevateActiveGroupImg: string = '';
  initialFormState = {};
  selectedAddonList: ElevateAddonStructure[] = [];
  @ViewChild('addonsMatCard') addonsMatCard: ElementRef;
  quoteButtonLabel = 'Generate Quote';
  biuToken: string = '';
  biuSaveQuoteID: string = '';
  hideDIYAddon: boolean = false;
  totalAddonCount: number = 0;
  creditScore: string = '';
  quotePdfRequest: SaveEditQuoteHapV1Request;
  uniqueGroups: Array<string> = [];
  // configuration flags
  activateBoosterTopup: boolean = false;
  superSaverComboSI = [];
  superSaverComboMasterSI = [];
  boosterPlans: Array<{ label: string, value: string }> = [];
  calculator: boolean = false;
  voluntaryDeductible: Array<{ value: number }> = [];
  addonsLoadedInitially: number = 0;
  boosterSIData = [];
  boosterSILoaded: boolean = false;
  topUpSiDesc: string = '';
  comboPolicyRange = [];

  constructor(private readonly fb: FormBuilder,
    private rtoListService: RTOListService,
    private healthAdvantedgeService: HealthAdvantedgeService,
    public utilityService: UtilityService,
    private storageService: StorageService,
    private healthMasterService: HealthMasterService,
    private readonly renderer: Renderer2,
    private el: ElementRef,
    private router: Router,
    private windowResizeService: WindowResizeService,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    private agentService: AgentService,
    private popupService: PopupService, private elevate_service: ElevateService,
    private healthDataStorageService: HealthDataStorageService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.healthStorageForm = this.healthDataStorageService.getHealthDataStorage();
    this.quoteDataSet = this.healthStorageForm.quoteDataSet;
    this.onLoad();
    this.initForm();
    this.checkScreenWidth();
  }

  onLoad() {
    if (this.healthStorageForm.quoteDataSet) {
      this.patchingDone = false;
    }
    // To find which product is rendered
    let route = this.router.url.split('/');
    if (!this.utilityService.isEmptyOrNull(route)) {
      // Configuration of calculator journey
      this.calculator = route[2].includes('calculate');
      this.buttonLabel = this.calculator ? 'Calculate Premium' : 'Generate Quote';
      // Whenever calculate comes it is at root level.No health is present in route
      let updatedRoute = route[2];
      this.activateBoosterTopup = updatedRoute == routeEnums.ActivateBooster || updatedRoute == routeEnums.CalculateActivateBooster;
      this.coverDesc = !this.activateBoosterTopup ? this.superSaverDataModal.superSaverCoverDesc : this.superSaverDataModal.activateBoosterCoverDesc;
    }


    this.fetchElevateDetails();
  }

  private checkScreenWidth() {
    this.subscription.push(this.windowResizeService.getResizeObservable().subscribe(data => {
      this.mobileViewActivated = data.width <= 990;
      this.showSelectedAddons = data.width >= 990;
      if (data.width >= 990) {
        this.backButtonNeeded = false;
      }
      // If we change the views and custom plan is allowed
      // Then if it is web view switch back to orignal format
      if (!this.mobileViewActivated && this.hideBUIService && this.showSelectedAddons) {
        this.showCustomAddons = true;
      }
    })
    )
  }


  initForm() {
    let policyRange = '';
    // For combo product i.e. super saver there is no policy range
    if (!this.activateBoosterTopup) {
      policyRange = '';
    }
    // For topup product there is policy range
    else {
      policyRange = this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.policyRange : this.superSaverDataModal.policyRange[0];
    }

    this.saverBoosterForm = this.fb.group({
      addons: this.fb.group({
        occupation: this.fb.array([])
      }),
      moreAddons: this.fb.group({
        occupation: this.fb.array([])
      }),
      elevateControls: this.fb.group({
        productName: [],
        productType: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.productType : this.superSaverDataModal.productType[0]],
        pincode: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.pincode : '', [Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6)]],
        stateName: [''],
        cityName: [''],
        cityState: [''],
        planType: [this.superSaverDataModal.planType[0]],
        zoneUpgrade: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.zoneUpgrade ? this.superSaverDataModal.options[0] : this.superSaverDataModal.options[1] : this.superSaverDataModal.options[1]],
        reviseZone: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.reviseZone : ''],
        zone: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.zone : ''],
        adultDetails: this.fb.array([]),
        childDetails: this.fb.array([]),
        policyTenure: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.policyTenure : this.superSaverDataModal.policyTenure[0], Validators.required],
        policyRange: [policyRange],
        copaymentPercentTaken: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.copaymentPercentTaken : ''],
        voluntaryDeductible: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.voluntaryDeductible : ''],
        applicantAnnualSum: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.applicantAnnualSum : '', [Validators.required]],
        applicantAnnualIncome: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.applicantAnnualIncome : '', [Validators.required]],
        pedWaitingPeriod: [''],
        conditionWaitingPeriod: [''],
        preHospitalDuration: [''],
        postHospitalDuration: [''],
        EMI: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.InstallmentApplicable : healthToggle.No],
        EMIInstallment: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.InstallmentFrequency : 'Monthly'],
        cibilDiscount: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.isCibilApplicable : healthToggle.No],
        NRIToggle: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.isNRIApplicable : healthToggle.No],
        // NRIDiscount: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.isNRIDiscountApplicable : healthToggle.No],
        proposerName: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.proposerName : ''],
        mobileNo: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.mobileNo : ''],
        DOB: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.DOB : ''],
        PEDSelection: [this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.PEDSelection : healthToggle.No],
      })
    })

    this.selectedTabImg = this.tabImages.map(data => data.notFilled);
    // Initially first tab is selected
    this.selectedTabImg[0] = this.tabImages[0].filled;
    this.configureSaverBoosterCoverId(this.healthStorageForm.quoteDataSet);

    // Creating adult and child controls and patching existing data if present
    this.patchExistingMemberData();
    if (this.healthStorageForm.quoteDataSet) {
      this.uniqueGroups = this.healthStorageForm.quoteFormData.uniqueAddonsGroups;
      this.patchExistingAddons();
      if (this.activateBoosterTopup) {
        this.addonsLoadedInitially++;
      }
      // More Addons Start
      // this.patchExistingMoreAddons();
      // More addons end
    }

    this.subscription.push(this.elevateControls.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged((prev, curr) => {

        if (this.elevateControls.valid && (!this.patchingDone && !this.healthStorageForm.quoteDataSet) || (this.healthStorageForm.quoteDataSet && this.patchingDone)) {
          if (this.healthStorageForm.quoteDataSet && this.patchingDone && this.formValidCounter == 0) {
            this.previousFormValue = {};
          }
          else {
            this.previousFormValue = { ...prev };
          }
          this.cibilState = { ...prev };
        }
        return JSON.stringify(prev) === JSON.stringify(curr)
      }), // Check for distinct changes
    ).subscribe(data => {

      const isFormValid = this.elevateControls.valid;

      if (((!this.patchingDone && !this.healthStorageForm.quoteDataSet) || (this.healthStorageForm.quoteDataSet && this.patchingDone)) || this.calculator) {
        // Store initial form state if form is valid once
        if (isFormValid) {
          this.initialFormState = this.previousFormValue;
          if (this.formValidCounter == 0) {
            // this.initialFormState = { ...data };
            this.formValidCounter++;
          }
        }

        // Check for significant changes
        if (!this.utilityService.isEmptyOrNull(this.initialFormState) && JSON.stringify(data) !== JSON.stringify(this.initialFormState)) {
          // Perform below actions when significant changes detected
          // this.BUIresponse = false;
          // this.hideBUIService = true;
          this.showPremiumDetails(false);
          if (!this.calculator) {
            this.quoteButtonLabel = 'Generate Quote';
            this.customStepperService.resetStepper(true);
          }

          this.changeInCover = false;
        }
      }

      // Explicit check for cibil if value differs
      //if only cibil applied once
      if (this.cibilScore != 0 && this.cibilSubmitted && (this.cibilState['proposerName'] != this.elevateControls.value['proposerName'] || this.cibilState['mobileNo'] != this.elevateControls.value['mobileNo'])) {
        this.cibilScore = 0;
        this.cibilSubmitted = false;
      }

    }));

  }

  patchExistingMemberData() {
    if (this.healthStorageForm.quoteDataSet) {
      this.biuServiceCounter = 1;
      this.quoteButtonLabel = 'Proceed';
      this.baseCoverSelected = this.healthStorageForm.quoteFormData.baseCoverSelected
      this.hideBUIService = this.healthStorageForm.quoteFormData.hideBIUservice;
      this.hideDIYAddon = this.healthStorageForm.quoteFormData.hideDIYAddon;
      this.biuToken = this.healthStorageForm.quoteFormData.BiuPlanUniqueId;
      this.biuSaveQuoteID = this.healthStorageForm.quoteFormData.biuSaveQuoteID;
      this.BUIresponse = this.healthStorageForm.quoteFormData.BIUresponseService;
      this.elevateBUIAddon = this.healthStorageForm.quoteFormData.elevateBUIAddon;
      this.totalAddonCount = this.healthStorageForm.quoteFormData.totalAddonCount;
      this.elevateControls.controls['zone'].patchValue(this.healthStorageForm.quoteFormData.zone);
      this.plans = this.healthStorageForm.quoteFormData.plans;
      this.selectedPlan = this.healthStorageForm.quoteFormData.selectedPlan;
      if (!this.utilityService.isUndefinedORNull(this.selectedPlan)) {
        this.isSelectedPlan(this.selectedPlan.CartType);
      }
      if (this.elevateBUIAddon.length > 1) {
        this.getCombinedAddOns(this.elevateBUIAddon);
      }
      this.elevateBUICovertextAddons = this.healthStorageForm.quoteFormData.elevateBUICovertextAddons;
      this.cibilChange();
      this.adultValue = this.healthStorageForm.quoteFormData.adultDetails.length;
      this.childValue = this.healthStorageForm.quoteFormData.childDetails.length;
      if (this.healthStorageForm.quoteFormData.adultDetails.length > 0) {
        this.healthStorageForm.quoteFormData.adultDetails.forEach((data, index) => {
          this.adultDetails.push(this.utilityService.createAdultDetails(index));
          // Patch values of adults
          // Below block is written because when there is self data that get's patched from kyc is not in date format
          // So to handle it we need to convert in into date type
          for (let adultKeys in data) {
            if (adultKeys.includes('dob')) {
              let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
              this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
            }
            else {
              this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
            }
          }
        })
      }

      if (this.healthStorageForm.quoteFormData.childDetails.length > 0) {
        this.healthStorageForm.quoteFormData.childDetails.forEach((data, index) => {
          this.childDetails.push(this.utilityService.createChildDetails(index));
          this.childMinMaxDate.push({ minDate: new Date(), maxDate: new Date() });
          // Patch values of adults
          for (let childKeys in data) {
            this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);
            if (childKeys.includes('Relationship')) {
              // We need to set min andd max dateof child as per relationship
              this.setResetChildDOB(data[childKeys], index);
            }
          }
        })
      }
    }
    else if (this.healthStorageForm.quickquoteDataSet) {
      this.getadultchilddataforquickquote();
    }
    else {
      this.adultDetails.push(this.createAdultDetails(0));
      this.enbleDisableChildDob(healthMembers.Adult);
    }
  }

  get elevateControls(): FormGroup {
    return this.saverBoosterForm.get('elevateControls') as FormGroup
  }

  get addons(): FormGroup {
    return this.saverBoosterForm.get('addons') as FormGroup
  }

  get moreAddons(): FormGroup {
    return this.saverBoosterForm.get('moreAddons') as FormGroup
  }

  get activatedAddonsGroup(): FormGroup {
    // If bui service is hidden that means our custom addon will pass
    // If bui service is not hidden that means our bui more addons will pass
    if (!this.hideBUIService && this.elevateBUIAddon.length > 1) {
      return this.saverBoosterForm.get('moreAddons') as FormGroup
    }
    else {
      return this.saverBoosterForm.get('addons') as FormGroup
    }
  }

  //member logic starts
  get adultDetails(): FormArray {
    return this.elevateControls.get('adultDetails') as FormArray;
  }

  get childDetails(): FormArray {
    return this.elevateControls.get('childDetails') as FormArray;
  }

  get occupation(): FormArray {
    return this.addons.get('occupation') as FormArray;
  }

  get moreAddonsOccupation(): FormArray {
    return this.moreAddons.get('occupation') as FormArray;
  }

  getActivatedOccupation(): FormArray {
    if (!this.hideBUIService && this.elevateBUIAddon.length > 1) {
      return this.moreAddons.get('occupation') as FormArray;
    }
    else {
      return this.addons.get('occupation') as FormArray;
    }
  }

  createAdultDetails(index?): FormGroup {
    return new FormGroup({
      ['dobAdult' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultRealtionship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultVaccinationStatus' + `${index + 1}`]: new FormControl(healthToggle.No, [Validators.required]),
      ['adultVaccinationDate' + `${index + 1}`]: new FormControl(''),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  createChildDetails(index?): FormGroup {
    return new FormGroup({
      ['dobChild' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['childRelationship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  createOccupation(index?, memberLabel?): FormGroup {
    return new FormGroup({
      ['occupationFor' + `${memberLabel}` + `${index + 1}`]: new FormControl(''),
      ['occLabel']: new FormControl(memberLabel),
      ['memberIndex']: new FormControl((index + 1))
    })
  }

  tabChanged(parentAddons: ElevateParentAddonDetails) {
    let groupPosition = parentAddons.groupPosition;
    let groupIndex: number = 0;
    // Tab images is in the same sequence of elevate addons
    // So if group position matches only that image will be active rest will be inactive
    this.tabImages.forEach((data, index) => {
      if (data.group == groupPosition) {
        this.selectedTabImg[index] = data.filled;
        groupIndex = index;
      }
      else {
        this.selectedTabImg[index] = data.notFilled;
      }
    })
    this.selectedElevateGroup = parentAddons;
    this.elevateActiveGroupImg = this.tabImages[groupIndex].notFilled;
  }

  //reset form ocntrol on change pincode
  resetFeild() {
    this.elevateControls.controls['cityState'].reset();
    this.elevateControls.controls['cityName'].reset();
    this.elevateControls.controls['stateName'].reset();
    this.elevateControls.controls['zoneUpgrade'].setValue(healthToggle.No);
    this.elevateControls.controls['reviseZone'].setValue('');
    this.elevateControls.controls['reviseZone'].clearValidators();
    this.elevateControls.controls['reviseZone'].updateValueAndValidity();
    this.elevateControls.controls['zone'].reset();
    this.zoneRefilingData = [];
    this.cityData = [];
  }

  //fetch city state on change pincode
  fetchDataAsPerPincode(event, pincode) {
    this.invalidPinCodeMessage = '';
    this.resetFeild();
    this.zoneUpgradeVisible = false;
    // To call addons api again
    this.quoteDataSet = !this.patchingDone && this.healthStorageForm.quoteDataSet;
    let pinCode = !this.utilityService.isEmptyOrNull(event) ? event.target.value : pincode;
    if ((pinCode.toString().length == 6) && !this.elevateControls.controls['pincode'].hasError('pattern')) {
      let data: RTOListRequestPayload = {
        "Pincode": pinCode,
        "CityID": "",
      }
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.length > 0) {
              this.stateDetails.stateId = response.Data[0].StateId ? response.Data[0].StateId : '';
              this.stateDetails.stateName = response.Data[0].StateName ? response.Data[0].StateName : '';
              response.Data.forEach(cityList => {
                this.cityData.push({ 'cityLabel': cityList['CityName'], 'stateLabel': response.Data[0].StateName, 'value': cityList['CityID'] });
              });
              this.elevateControls.get('stateName').setValue(response.Data[0].StateName);

              if (this.cityData.length == 1) {
                this.elevateControls.controls['cityState'].setValue(response.Data[0].StateName + ' - ' + this.cityData[0].cityLabel);
                // this.elevateControls.controls['stateName'].setValue(response.Data[0].StateName);
                this.elevateControls.controls['cityName'].setValue(this.cityData[0].cityLabel);
                this.stateDetails.cityName = this.cityData[0].cityLabel;
                this.stateDetails.cityId = this.cityData[0].value;
                this.getZoneData();
              }

              if (this.healthStorageForm.quoteDataSet && this.cityData.length > 1 && !this.patchingDone) {
                this.elevateControls.controls['cityState'].setValue(this.healthStorageForm.quoteFormData.cityState);
                this.changeCityName(this.healthStorageForm.quoteFormData.cityId, false)
                this.zoneMappedWithPincode = this.healthStorageForm.quoteFormData.zone;
                this.elevateControls.controls['zone'].patchValue(this.zoneMappedWithPincode);
                this.onZoneUpgradePatching(this.elevateControls.controls['zone'].value);
                this.patchingDone = true;
              }
              if (this.healthStorageForm.quoteDataSet && this.healthStorageForm.quoteFormData.zoneUpgrade) {
                this.elevateControls.controls['zoneUpgrade'].setValue(healthToggle.Yes);
                this.elevateControls.controls['reviseZone'].setValue(this.healthStorageForm.quoteFormData.reviseZone);
              }
            }
          }
          else if (response.StatusCode == Constants.statusCode_failure) {
            this.invalidPinCodeMessage = response.StatusDesc;
          }
        },
        error: error => {
          this.invalidPinCodeMessage = '';
        }
      })
      )
    }
  }

  //on change city state value
  changeCityName(cityValue: string, showSpinner: boolean) {
    this.stateDetails.cityId = cityValue;
    this.stateDetails.cityName = this.cityData.length > 0 ? (this.cityData.find(data => data.value == cityValue).cityLabel) : '';
    this.elevateControls.controls['cityName'].setValue(this.cityData.find(data => data.value == cityValue).cityLabel);
    if (showSpinner) {
      this.getZoneData();
    }
  }

  getZoneData() {
    let params: ZoneRefillingRequestPayload = {
      // "subproductcode": this.elevateModal.subProductCode,
      "subproductcode": this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.subProductCode : this.elevateMasterData.SubProductCode,
      "State": this.elevateControls.controls['stateName'].value,
      "City": this.elevateControls.controls['cityName'].value,
      // "productcode": this.elevateModal.productCode,
      "productcode": this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.productCode.toString() : this.elevateMasterData.ProductCode,
      'Pincode': this.elevateControls.controls['pincode'].value,
    }

    this.subscription.push(this.healthAdvantedgeService.getZoneFromCityRefillingData(params).subscribe({
      next: (response: ZoneRefillingResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          let zoneRefillingData = !this.utilityService.isEmptyOrNull(response.Data.zone) ? response.Data.zone : '';
          this.elevateControls.controls['zone'].patchValue(zoneRefillingData);

          // if it is super topup then only zone based SI will get showcased
          // if (this.activateBoosterTopup) {
          //   let zoneValue = !this.healthStorageForm.quoteDataSet ? zoneRefillingData : (this.healthStorageForm.quoteFormData.zone);
          //   this.zoneBasedSI(zoneValue);
          // }
          this.zoneMappedWithPincode = zoneRefillingData;
          this.onZoneUpgradePatching(zoneRefillingData);
          this.elevateControls.controls['zone'].patchValue(this.zoneMappedWithPincode);

          if (!this.quoteDataSet) {
            this.fetchElevateAddons();
          }

          if (this.healthStorageForm.quoteDataSet && this.cityData.length == 1) {
            this.patchingDone = true;
          }

          if (this.healthStorageForm.quoteDataSet && this.healthStorageForm.quoteFormData.zoneUpgrade) {
            let selectedZone = this.zoneOption.filter(x => x == this.healthStorageForm.quoteFormData.reviseZone).toString();
            this.elevateControls.controls['reviseZone'].patchValue(selectedZone);
          }
        }
        else {
        }
      },
      error: error => {
      }
    }))
  }

  patchExistingAddons() {
    this.maternityAdultData = this.healthStorageForm.quoteFormData.maternityAdultData;
    this.criticalAdultData = this.healthStorageForm.quoteFormData.criticalAdultData;
    let elevateExistingAddonsData = this.healthStorageForm.quoteFormData.elevateAddonsFormValue;

    // Add addons control for all checkboxes and dropdown
    let formKeys = Object.keys(this.healthStorageForm.quoteFormData.elevateAddonsFormValue);
    if (formKeys.length > 0) {
      formKeys.forEach((data, index) => {
        let addonsDataValue = elevateExistingAddonsData[data];
        if (data != 'occupation') {
          this.addons.addControl(data, this.fb.control(addonsDataValue), { emitEvent: false });
          // Hidden as per the current requirement

          // if ((data.includes(this.coverDesc.MaternityForAdultValue) || data.includes(this.coverDesc.criticalIllnessForAdult) || data.includes(this.coverDesc.AnnualHealthForAdult))) {
          //   if (!this.utilityService.isEmptyOrNull(addonsDataValue)) {
          //     this.dynamicFormOperations(this.validationOperation.addValidators, data);
          //   }
          // }
        }
      })
    }

    // Setting of occupation data
    if (this.healthStorageForm.quoteFormData.occupation.length > 0) {
      this.healthStorageForm.quoteFormData.occupation.forEach((data, index) => {
        // Extract occupation keys
        const occKeys = Object.keys(data);
        let dynamicKey = 'occupationFor' + data[occKeys[1]] + data[occKeys[2]];
        this.occupation.push(this.createOccupation((data[occKeys[2]] - 1), data[occKeys[1]]));
        this.occupation.at(index).get(dynamicKey).setValue(data[dynamicKey]);
      }
      )
    }


    if (this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.NewBornBaby)) && this.addons.get(this.coverDesc.Maternity)) {
      // If new born and vaccination does not exists then add control
      this.addons.addControl(this.coverDesc.NewBornBaby, this.fb.control(true), { emitEvent: false });
      this.addons.addControl(this.coverDesc.VaccinationExpenses, this.fb.control(true), { emitEvent: false });

      // If maternity then disable new born and vaccination
      if (this.addons.get(this.coverDesc.Maternity).value) {
        this.addons.get(this.coverDesc.NewBornBaby).disable();
        this.addons.get(this.coverDesc.VaccinationExpenses).disable();
      }
    }

    // Add tele consultation control and disable it
    if (this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.TeleConsultation))) {
      this.addons.addControl(this.coverDesc.TeleConsultation, this.fb.control(true), { emitEvent: false });
      this.addons.get(this.coverDesc.TeleConsultation).disable();
    }

    // Annual Health Checkups and claim protector is by default selected it is applicable only for il saver
    if (!this.activateBoosterTopup) {
      if (this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.ClaimProtector))) {
        this.addons.addControl(this.coverDesc.ClaimProtector, this.fb.control(true), { emitEvent: false });
        this.addons.get(this.coverDesc.ClaimProtector).disable();
      }

      if (this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.AnnualHealthCheckups))) {
        this.addons.addControl(this.coverDesc.AnnualHealthCheckups, this.fb.control(true), { emitEvent: false });
        this.addons.get(this.coverDesc.AnnualHealthCheckups).disable();
      }

    }

    // for ci setting proposer dob
    if (!this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.Critical)) && this.addons.get(this.coverDesc.Critical).value) {
      this.selfMemberExists = true;
    }

    this.elevateAddons = this.healthStorageForm.quoteFormData.elevateAddons;
    this.addonsReceivedFromService = this.healthStorageForm.quoteFormData.addonList;
    this.elevateAddonsCopy = this.healthStorageForm.quoteFormData.elevateAddonsCopy;
    // Initially first tab will be present
    if (this.elevateAddons.length > 0) {
      this.selectedElevateGroup = this.elevateAddons[0];
      this.elevateActiveGroupImg = this.tabImages[0].notFilled;
      this.selectedAddonList = this.healthStorageForm.quoteFormData.selectedAddons;
    }
    // this.quoteDataSet = false;
  }

  patchExistingMoreAddons() {
    this.annualAdultData = this.healthStorageForm.quoteFormData.annualAdultData;
    this.showMoreCriticaldatePicker = this.healthStorageForm.quoteFormData.showMoreAddonsDatepicker;
    this.showMoreAddonsOccupation = this.healthStorageForm.quoteFormData.showMoreAddonsOcc;
    this.elevateAllAddons = this.healthStorageForm.quoteFormData.moreAddons;
    this.elevateAllAddonsCopy = this.healthStorageForm.quoteFormData.elevateAllAddonsCopy;
    let elevateExistingMoreAddonsData = this.healthStorageForm.quoteFormData.moreAddonsFormValue;
    this.hideBUIService = !this.healthStorageForm.quoteFormData.recommendedJourneySelected;
    this.moreAddonActivatedCount = this.healthStorageForm.quoteFormData.activeMoreAddonsCount;
    // Add addons control for all checkboxes and dropdown
    let formKeys = Object.keys(this.healthStorageForm.quoteFormData.moreAddonsFormValue);
    if (formKeys.length > 0) {
      formKeys.forEach((data, index) => {
        let addonsDataValue = elevateExistingMoreAddonsData[data];
        if (data != 'occupation') {
          this.moreAddons.addControl(data, this.fb.control(addonsDataValue), { emitEvent: false });
        }
      })
    }

    // Setting of occupation data
    if (this.healthStorageForm.quoteFormData.moreAddonsOccupation.length > 0) {
      this.healthStorageForm.quoteFormData.moreAddonsOccupation.forEach((data, index) => {
        // Extract occupation keys
        const occKeys = Object.keys(data);
        let dynamicKey = 'occupationFor' + data[occKeys[1]] + data[occKeys[2]];
        this.moreAddonsOccupation.push(this.createOccupation((data[occKeys[2]] - 1), data[occKeys[1]]));
        this.moreAddonsOccupation.at(index).get(dynamicKey).setValue(data[dynamicKey]);
      }
      )
    }

    if (this.utilityService.isEmptyOrNull(this.moreAddons.get(this.coverDesc.NewBornBaby))) {
      // If new born and vaccination does not exists then add control
      this.moreAddons.addControl(this.coverDesc.NewBornBaby, this.fb.control(true), { emitEvent: false });
      this.moreAddons.addControl(this.coverDesc.VaccinationExpenses, this.fb.control(true), { emitEvent: false });

      // If maternity then disable new born and vaccination
      if (this.moreAddons.get(this.coverDesc.Maternity).value) {
        this.moreAddons.get(this.coverDesc.NewBornBaby).disable();
        this.moreAddons.get(this.coverDesc.VaccinationExpenses).disable();
      }
    }

    // Add tele consultation control and disable it
    if (this.utilityService.isEmptyOrNull(this.moreAddons.get(this.coverDesc.TeleConsultation))) {
      this.moreAddons.addControl(this.coverDesc.TeleConsultation, this.fb.control(true), { emitEvent: false });
      this.moreAddons.get(this.coverDesc.TeleConsultation).disable();;
    }

    // for ci setting proposer dob
    if (this.moreAddons.get(this.coverDesc.Critical).value) {
      this.selfMemberExists = true;
    }
  }

  constructMoreAddonsForm() {
    let formKeys = Object.keys(this.addons.controls);
    let elevateExistingAddonsData = this.addons.value;
    if (formKeys.length > 0) {
      formKeys.forEach((data, index) => {
        let addonsDataValue = elevateExistingAddonsData[data];
        this.moreAddons.addControl(data, this.fb.control(addonsDataValue));
        // For disabled addons we need to explicitly set the addon values and disable it
        if (data.includes(this.coverDesc.TeleConsultation) || data.includes(this.coverDesc.NewBornBaby) || data.includes(this.coverDesc.VaccinationExpenses)) {
          this.moreAddons.get(data).setValue(true);
          // this.moreAddons.get(data).disable();
        }
      })
    }

    // Add befit controls by default if it's not present in the addons being returned
    if (this.utilityService.isEmptyOrNull(this.moreAddons.get(this.coverDesc.Befit))) {
      this.moreAddons.addControl(this.coverDesc.Befit, this.fb.control(false));
      this.moreAddons.addControl(this.coverDesc.SelectedBefitData, this.fb.control(''));
    }
  }

  resetAddonStatusForm() {
    if (this.biuServiceCounter >= 1 && !this.BUIresponse && this.quoteButtonLabel == 'Generate Quote' && !this.changeInCover) {
      if (this.hideBUIService) {
        this.resetForm(false);
      }
      else {
        this.resetForm(true);
      }
    }
  }

  fetchElevateAddons() {
    let reqParams: ElevateAddonsRequestPayload = {
      Pincode: this.elevateControls.controls['pincode'].value,
      SubProductCode: this.elevateMasterData.SubProductCode,
      ProductCode: this.elevateMasterData.ProductCode,
      Subtype: (this.elevateMasterData.SubProductType).toString()
    }

    this.subscription.push(this.elevate_service.fetchElevateAddons(reqParams).subscribe({
      next: (response: ElevateAddonsResponsePayload) => {
        if (response.StatusCode == Constants.statusCode_success) {

          let modifiedAddons = [];
          this.addonsReceivedFromService = [];
          this.addonsReceivedFromService = this.addonsReceivedFromService.concat(response.Data.NewAddOnCoverDetails, response.Data.DropdownAddonList)
          modifiedAddons = modifiedAddons.concat(response.Data.NewAddOnCoverDetails, response.Data.DropdownAddonList);

          if (this.elevateAddons.length == 0) {
            this.constructElevateAddons([...modifiedAddons]);
            // More addons form is construted based on more addons form group

            // More addons commented start
            // this.moreBefitDataModification([...modifiedAddons]);
            // this.constructMoreAddonsForm();
            // More addons commented end

            this.checkTabVisiblity();
          }
          else if (this.elevateAddons.length > 0) {
            let mostlyBoughtGroup = this.findParentGroups(this.addonGroupDesc.MostlyBought);
            let befitID = this.findChildAddonGroups(this.coverId.Befit, mostlyBoughtGroup.childAddons)
            // Now we need to check whether befit was returned or not from this pincode
            let befitDataFromService = response.Data.NewAddOnCoverDetails.filter(data => data.CoverText.includes('BeFit'));
            this.befitReturnedFromService = befitDataFromService.length == 0 ? false : true;

            // Case1: If befit is already present in existing addons but api has not returned any befit

            if (befitID != -1) {
              mostlyBoughtGroup.childAddons[befitID].showDependentField = false;
              mostlyBoughtGroup.childAddons[befitID].visiblityAsPerService = befitDataFromService.length == 0 ? false : true;
              // Also reset the control name
              this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.Befit, false);
              this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.SelectedBefitData, '');
              this.dynamicFormOperations(this.validationOperation.clearValidators, this.coverDesc.SelectedBefitData);

            }
            // Case 2: If befit was not present in existing api . But Now api has returned befit
            else if (befitDataFromService.length > 0 && befitID == -1) {
              //Filter Befit data
              let tempBefitData = befitDataFromService;
              // Only 1 element will be present
              let befitGroup = tempBefitData.length > 0 ? tempBefitData[0].groups : 1;

              // Now we need to push befit data into our elevate addons group
              if (tempBefitData.length > 0) {

                let subBefitData = [];
                // For pushing befit sub data
                tempBefitData.forEach(data => {
                  let tempBefitObj = {
                    checkboxValue: { checkedAddon: data.IsAddonSelected, disabledAddon: data.disabled },
                    coverCode: data.CoverCode,
                    coverId: data.CoverId,
                    isForAdult: data.IsForAdult,
                    isForChild: data.IsForChild,
                    isForFemale: data.IsForFemaleAdult,
                    label: data.CoverText,
                    position: data.position,
                    type: 'checkbox',
                    visiblityAsPerService: data.IsVisible,
                    group: data.groups,
                    groupName: data.groupName,
                    fieldType: '',
                    dropDownValue: [],
                    dropDownControlName: '',
                    defaultDropDownValue: '',
                    tooltipPresent: false,
                    showDependentField: false,
                    dropDownLabel: '',
                    dropDownPlaceholder: ''

                  }
                  subBefitData.push(tempBefitObj)
                })
                // Filter based on position
                subBefitData.sort((a, b) => a.position - b.position);
                this.befitData = subBefitData;

                // Also add controls for befit
                if (this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.Befit))) {
                  //  Patch Befit data and disable it
                  this.addons.addControl(this.coverDesc.Befit, this.fb.control(false));
                  this.addons.addControl(this.coverDesc.SelectedBefitData, this.fb.control(''));
                  this.dynamicFormOperations(this.validationOperation.clearValidators, this.coverDesc.SelectedBefitData);
                }
                else {
                  this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.Befit, false);
                  this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.SelectedBefitData, '');
                  this.dynamicFormOperations(this.validationOperation.clearValidators, this.coverDesc.SelectedBefitData);
                }

                // Construct refiled base group addons for befits
                // For assigning befit group category data

                let dropDownLabelPh = this.patchLblAndPlaceHolder(this.coverId.Befit)
                let befitData: ElevateAddonStructure = {
                  checkboxValue: { checkedAddon: false, disabledAddon: false },
                  coverId: 0,
                  isForAdult: true,
                  isForChild: true,
                  isForFemale: false,
                  label: 'Befit',
                  position: this.befitData[0].position,
                  type: 'checkbox',
                  visiblityAsPerService: true,
                  group: befitGroup,
                  groupName: this.befitData[0].groupName,
                  fieldType: 'dropdown',
                  dropDownValue: this.befitData,
                  dropDownControlName: this.fetchAddonControlNames(this.coverId.Befit),
                  defaultDropDownValue: '',
                  defaultDropDownControlValue: '',
                  tooltipPresent: false,
                  showDependentField: false,
                  dropDownLabel: dropDownLabelPh.label,
                  dropDownPlaceholder: dropDownLabelPh.placeholder
                };

                mostlyBoughtGroup.childAddons.push(befitData);
              }
            }
            // If befit added or removed calculate the count
            this.calculateActiveAddonCount();
          }
          // this.zoneBasedAddons();
        }
      }

    }))

  }

  moreBefitDataModification(addonsData) {
    let newData = [...addonsData];
    let nonBefitAddons = [];
    let disabledFields = [this.coverDesc.TeleConsultation, this.coverDesc.NewBornBaby, this.coverDesc.VaccinationExpenses];
    if (newData.length > 0) {
      newData.forEach(data => {
        // if ((data.CoverText.includes('BeFit') || data.CoverText.includes('Befit'))) {
        //   befitDataPresent = true;
        // }

        if (!(data.CoverText.includes('BeFit') || data.CoverText.includes('Befit'))) {
          // Except disabled addons all addons disabled should set to false
          let tempData = { ...data, disabled: disabledFields.includes(data.CoverText) };
          nonBefitAddons.push({ ...tempData });
        }
      })

      // If befit was not present then after pushing our custom befit hide it
      let beFitPresent = addonsData.length > nonBefitAddons.length;
      let befitData = {
        'CoverId': 0,
        'CoverText': this.coverDesc.Befit,
        'Description': this.coverDesc.Befit,
        "IsForAdult": true,
        "IsForChild": true,
        "IsAddonSelected": false,
        "IsVisible": beFitPresent,
        "IsForFemaleAdult": true,
        "disabled": false,
      };
      nonBefitAddons.push({ ...befitData });
      this.elevateAllAddons = [...nonBefitAddons];
      this.elevateAllAddonsCopy = [...this.elevateAllAddons];
    }
  }

  zoneBasedAddons() {
    // If zone is A and D then we need to hide infinite cover and power booster
    // also if jump start opted we need to hide the power booster and infinite cover
    let mostlyBoughtGroup = this.findParentGroups(this.addonGroupDesc.MostlyBought);
    mostlyBoughtGroup.childAddons.forEach(data => {
      if (data.coverId == this.coverId.PowerBooster || data.coverId == this.coverId.InfiniteCover) {
        let formValue = this.activatedAddonsGroup.get(data.label).value;
        this.changeInAddonCover(mostlyBoughtGroup, data, { checked: formValue });
      }
    })
  }

  findParentGroups(groupName: string): ElevateParentAddonDetails {
    let parentAddon: ElevateParentAddonDetails;
    if (this.elevateAddons.length > 0) {
      let dataFound = this.elevateAddons.filter(data => data.category.includes(groupName));
      parentAddon = dataFound.length > 0 ? dataFound[0] : null;
    }
    return parentAddon;
  }

  findChildAddonGroups(coverId: number, childAddons: ElevateAddonStructure[]): number {
    let index: number = -1;
    if (childAddons.length > 0) {
      index = childAddons.findIndex(data => data.coverId == coverId);
    }
    return index;
  }
  //zone upgrade patching
  onZoneUpgradePatching(selectedZone: string): void {
    switch (selectedZone) {
      case this.zoneList.zoneA:
      case this.zoneList.zoneD:
        this.zoneUpgradeVisible = false;
        this.elevateControls.controls['zoneUpgrade'].setValue(healthToggle.No);
        this.onZoneUpgrade();
        break;
      case this.zoneList.zoneB:
        this.zoneUpgradeVisible = true;
        this.zoneOption = this.superSaverDataModal.zone.filter(x => [this.zoneList.zoneA].includes(x));
        break;
      case this.zoneList.zoneC:
        this.zoneUpgradeVisible = true;
        this.zoneOption = this.superSaverDataModal.zone.filter(x => [this.zoneList.zoneA, this.zoneList.zoneB].includes(x));
        break;
      default:
        break;
    }
  }

  checkTabVisiblity() {
    // As on load no maternity ci and pa will be computed
    this.elevateAddons.forEach(data => {
      if (data.category == this.addonGroupDesc.MaternityCoverage || data.category == this.addonGroupDesc.LumpSum) {
        data.hide = true;
      }
    })
  }

  //on chnage policy range set SI value
  changePolicyRange(range: { label: string, value: string }) {
    // this.sumInsuredFilter();
    this.sumInsuredDetails = this.boosterSIData.filter(data => data.PolicyRange == range.value);
    this.elevateControls.controls['applicantAnnualSum'].setValue('');
    this.voluntaryDeductible = [];
    this.elevateControls.controls['voluntaryDeductible'].setValue('');
    this.initializeFirstTab();
  }

  zoneBasedSI(data) {
    // Below SI changes only applicable for super topup
    if (this.activateBoosterTopup) {
      let policyRange = this.elevateControls.get('policyRange').value;
      let annualSum = this.elevateControls.get('applicantAnnualSum').value;
      // All Rollover across all Zone will be starting from 10L
      //  Fresh :    Zone A: 7.5L,    Zone B & C: 5L, Zone D: 10L
      if (this.elevateControls.controls['productType'].value == 'New') {
        switch (data.toLowerCase()) {
          case this.zoneList.zoneA.toLowerCase(): {
            this.sumInsuredDetails = this.elevateMasterData.SumInsuredDetails.filter(x => x.SumAmount >= 750000 && x.PolicyRange == policyRange);
            this.policyPlan[0]['label'] = '7.5L-50L';
            break;
          }
          case this.zoneList.zoneB.toLowerCase():
          case this.zoneList.zoneC.toLowerCase(): {
            this.sumInsuredDetails = this.elevateMasterData.SumInsuredDetails.filter(x => x.SumAmount >= 500000 && x.PolicyRange == policyRange);
            this.policyPlan[0]['label'] = '5L-50L';
            break;
          }
          case this.zoneList.zoneD.toLowerCase(): {
            this.sumInsuredDetails = this.elevateMasterData.SumInsuredDetails.filter(x => x.SumAmount >= 1000000 && x.PolicyRange == policyRange);
            this.policyPlan[0]['label'] = '10L-50L'
            break;
          }
          default:
            break;
        }
      }
      else {
        this.sumInsuredDetails = this.elevateMasterData.SumInsuredDetails.filter(x => x.SumAmount >= 1000000 && x.PolicyRange == policyRange);
        this.policyPlan[0]['label'] = '10L-50L'
      }

      // Check if the si value exists or not
      if (!this.utilityService.isEmptyOrNull('annualSum')) {
        let index = this.sumInsuredDetails.findIndex(data => data.SumAmount == annualSum);
        if (index == -1) {
          this.elevateControls.controls['applicantAnnualSum'].reset();
        }
      }
    }
    if (this.healthStorageForm.quoteDataSet && this.sumInsuredCounter == 1) {
      this.sumInsuredCounter = 0;
    }
    if (this.sumInsuredCounter >= 1) {
      this.sumInsuredChange();
    }
    else {
      this.sumInsuredCounter++;
    }
  }

  //setting zone toggle value for zone upgrade
  onZoneUpgrade() {
    const zoneUpgradeControl = this.elevateControls.controls['zoneUpgrade'];
    const reviseZoneControl = this.elevateControls.controls['reviseZone'];
    reviseZoneControl.patchValue('');
    if (zoneUpgradeControl.value === healthToggle.No) {
      zoneUpgradeControl.patchValue(healthToggle.No);
      if (this.healthStorageForm.quoteDataSet) {
        this.healthStorageForm.quoteFormData.zoneUpgrade = false;
      }
      reviseZoneControl.patchValue('');
      reviseZoneControl.clearValidators();
    } else {
      zoneUpgradeControl.patchValue(healthToggle.Yes);
      reviseZoneControl.setValidators([Validators.required]);
    }
    reviseZoneControl.updateValueAndValidity();
  }

  adultsDateComparison(value: string, id: number, controlName: string) {
    let age: number = this.utilityService.calculateAge(this.adultDetails.value[id][controlName]);
    this.checkForPAAndCIPolicyLevel(age, id, healthMembers.Adult);
    this.adultGenderChange();
  }

  adultRelationShip(relationShipName: string, id: number) {
    this.enbleDisableChildDob(healthMembers.Adult);
    this.addValidatorsForNeutralGender(relationShipName, id, healthMembers.Adult);

    // Critical Illness addon is visible only in super saver
    if (!this.activateBoosterTopup) {
      let criticalGroup = this.findParentGroups(this.addonGroupDesc.LumpSum);
      if (!this.utilityService.isEmptyOrNull(criticalGroup) && criticalGroup.childAddons.length > 0) {
        let criticalIllnessAddon = criticalGroup.childAddons.find(addon => addon.coverId == this.coverId.CriticalIllness);
        if (this.elevateAddons.length > 0 && criticalIllnessAddon.dropDownValue.length > 0 && this.addons.controls[this.coverDesc.Critical].value) {
          this.checkProposerDobCI(criticalGroup);
        }
      }
    }

    if (!this.utilityService.isEmptyOrNull(this.adultDetails.value[id]['dobAdult' + (id + 1)])) {
      this.adultGenderChange();
    }
  }

  criticalAddonBasedOnRelationship() {
    let criticalGroup = this.findParentGroups(this.addonGroupDesc.LumpSum);
    if (!this.utilityService.isEmptyOrNull(criticalGroup) && criticalGroup.childAddons.length > 0) {
      let criticalIllnessAddon = criticalGroup.childAddons.find(addon => addon.coverId == this.coverId.CriticalIllness);
      if (this.elevateAddons.length > 0 && criticalIllnessAddon['dropDownValue'].length > 0 && this.addons.controls[this.coverDesc.Critical].value) {
        this.checkProposerDobCI(criticalGroup);
        // More Addons
        // this.checkProposerDobCI();
      }
    }
  }

  adultGenderChange() {
    if (!this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.Maternity))) {
      let maternityGroup = this.findParentGroups(this.addonGroupDesc.MaternityCoverage);
      if (!this.utilityService.isEmptyOrNull(maternityGroup) && maternityGroup.childAddons.length > 0) {
        let maternityAddonID = this.findChildAddonGroups(this.coverId.Maternity, maternityGroup.childAddons);
        // let moreAddonsMaternity = this.elevateAllAddons.findIndex(data => data.CoverText == this.coverDesc.Maternity);
        // Maternity will be applicable only if it is self and spouse 
        // And both the adult should have gender male female
        let memberGender = [];
        let memberRelation = [];
        if (this.adultDetails.value.length == 2) {
          // Check for relationship and gender
          this.adultDetails.value.forEach((element, index) => {
            let age: number = this.utilityService.calculateAge(this.adultDetails.value[index]['dobAdult' + (index + 1)]);
            let relationValue = this.adultDetails.value[index]['adultRealtionship' + (index + 1)];
            let neutralRelation = ['SELF', 'SPOUSE'];

            //  maternity will only be applicable if relation is self and spouse
            if (age >= 18 && age <= 50 && neutralRelation.includes(relationValue)) {

              if (!this.utilityService.isEmptyOrNull(this.adultDetails.value[index]['Gender' + (index + 1)]) && this.adultDetails.value[index]['Gender' + (index + 1)] != 'MF') {
                if (memberRelation.length == 0 || !memberRelation.includes(this.adultDetails.value[index]['adultRealtionship' + (index + 1)])) {
                  memberRelation.push(this.adultDetails.value[index]['adultRealtionship' + (index + 1)]);
                }
                // If gender exists check it should not include same gender. If gender not includes push the value
                if (memberGender.length == 0 || !memberGender.includes(this.adultDetails.value[index]['Gender' + (index + 1)])) {
                  memberGender.push(this.adultDetails.value[index]['Gender' + (index + 1)]);
                }
              }
            }
          });
        }

        // Now if gender length is 2 then only maternity will be applicable
        if (memberGender.length == 2 && memberRelation.length == 2) {
          let femaleID = memberGender.findIndex(data => data == 'FMALE');
          maternityGroup.childAddons[maternityAddonID].dropDownValue.push({ id: 0, label: healthMembers.Adult + ' ' + (femaleID + 1) })
        }
        else {
          maternityGroup.childAddons[maternityAddonID].dropDownValue = [];
        }
        this.maternityAdultData = maternityGroup.childAddons[maternityAddonID].dropDownValue;

        // Now here we need to check if the relationship of members are modified then maternity needs to be checked
        if (this.elevateAddons.length > 0) {
          this.changeInAddonCover(maternityGroup, maternityGroup.childAddons[maternityAddonID], { checked: this.addons.get(maternityGroup.childAddons[maternityAddonID].label).value })
          // More Addons
          // this.changeInMoreAddons(this.elevateAllAddons[moreAddonsMaternity], { checked: this.moreAddons.get(this.coverDesc.Maternity).value }, false)
        }
      }
    }
  }

  addValidatorsForNeutralGender(relationShipName: string, id: number, type: string) {
    // Add update dynamic validations for gender
    let memberData = type == healthMembers.Adult ? this.adultDetails : this.childDetails;
    memberData.at(id).get('Gender' + (id + 1)).setValue('');
    // if (!this.utilityService.isEmptyOrNull(memberData.get('Gender' + (id + 1)))) {
    if (this.neutralGender.includes(relationShipName)) {
      memberData.at(id).get('Gender' + (id + 1)).setValidators([Validators.required]);
    }
    else {
      memberData.at(id).get('Gender' + (id + 1)).clearValidators();
    }
    memberData.at(id).get('Gender' + (id + 1)).updateValueAndValidity();
    // }
  }

  enbleDisableChildDob(type: string) {
    // Enable disable child dob
    if (type == healthMembers.Child) {
      this.childDetails.controls.forEach((childGroup: FormGroup, index) => {
        const relationshipControl = childGroup.get('childRelationship' + (index + 1));
        const dobControl = childGroup.get('dobChild' + (index + 1));
        // Reset the dob of child if relation is changed
        // dobControl.setValue('');
        if (relationshipControl && dobControl) {
          const relationshipPresent = !this.utilityService.isEmptyOrNull(relationshipControl.value);
          // Disable child dob if child relation is not selected. 
          if (!relationshipPresent) {
            dobControl.disable();
          }
          // Enable when the relation is present
          else {
            dobControl.enable();
          }
        }
      })
    }
    // Enable disable adult dob 
    else if (type == healthMembers.Adult) {
      this.adultDetails.controls.forEach((adultGroup: FormGroup, index) => {
        const relationshipControl = adultGroup.get('adultRealtionship' + (index + 1));
        const dobControl = adultGroup.get('dobAdult' + (index + 1));
        // Reset the dob of adult if relation is changed
        // dobControl.setValue('');
        if (relationshipControl && dobControl) {
          const relationshipPresent = !this.utilityService.isEmptyOrNull(relationshipControl.value);
          // Disable child dob if adult relation is not selected. 
          if (!relationshipPresent) {
            dobControl.disable();
          }
          // Enable when the relation is present
          else {
            dobControl.enable();
          }
        }
      })
    }
  }


  checkForMaternityAddon(age: number, memberIndex: number, type: string) {
    let relationShipName = '';
    // let genderData: InsuredRelationship[] = [];
    let maternityGroup = this.findParentGroups(this.addonGroupDesc.MaternityCoverage);
    let maternityAddonID = this.findChildAddonGroups(this.coverId.Maternity, maternityGroup.childAddons);
    let maternityAddon = maternityGroup.childAddons[maternityAddonID];
    let maternityValueExists = maternityAddon.dropDownValue.length > 0;
    relationShipName = this.adultDetails.value[memberIndex]['adultRealtionship' + (memberIndex + 1)];
    // genderData = this.adultRelationShips.filter(relationData => relationData.RelationshipName == relationShipName);
    let allowedRelation = ['SELF', 'SPOUSE'];
    let index = 0;
    let memberName = healthMembers.Adult + ' ' + (memberIndex + 1);
    index = maternityAddon.dropDownValue.findIndex(data => data.label == memberName);

    // if (genderData.length > 0) {
    // (genderData[0].Gender == (RelationShipGender.Male).toUpperCase() || (genderData[0].Gender == (RelationShipGender.Neutral).toUpperCase() && relationShipName == 'EMPLOYEE'))
    if (!allowedRelation.includes(relationShipName) && maternityValueExists) {
      if (index != -1) {
        maternityAddon.dropDownValue.splice(index, 1);

        // if the addon value is selected and it same as deleted value then reset
        if (this.addons.get(maternityAddon.label).value) {
          // Now maternity for member is a form control which can contain multiple value
          let selectedMaternityMembers = this.addons.controls[maternityAddon.dropDownControlName].value;
          let selectedMemberId = selectedMaternityMembers.findIndex(data => data.label == memberName);
          if (selectedMemberId != -1) {
            selectedMemberId.splice(selectedMemberId, 1);
          }
        }
      }
    }

    // Gender should SPOUSE AND SELF
    //  genderData[0].Gender == (RelationShipGender.Female).toUpperCase() || (genderData[0].Gender == (RelationShipGender.Neutral).toUpperCase() && relationShipName != 'EMPLOYEE')
    else if (allowedRelation.includes(relationShipName)) {
      //  If it satisfies the age criteria
      if (age >= 18 && age <= 50) {
        // If the data is not presnt then only push the value
        if (index == -1) {
          maternityAddon.dropDownValue.push({ 'id': memberIndex, 'label': type + ' ' + (memberIndex + 1) });
        }
      }
      // even if it is female but the age is not satisfied
      else {
        if (index != -1) {
          // If it is already presnt then remove it
          maternityAddon.dropDownValue.splice(index, 1);
          // if the addon value is selected and it same as deleted value then reset
          if (this.addons.get(maternityAddon.label).value) {
            let selectedMaternityMembers = this.addons.controls[maternityAddon.dropDownControlName].value;
            let selectedMemberId = selectedMaternityMembers.findIndex(data => data.label == memberName);
            if (selectedMemberId != -1) {
              selectedMemberId.splice(selectedMemberId, 1);
            }
          }
        }
      }
    }
    // }
    // Now sort adult bases on increasing order in maternity data
    maternityAddon.dropDownValue = this.sortAdultData(maternityAddon.dropDownValue);
    this.maternityAdultData = maternityAddon.dropDownValue;

    // Now here we need to check if the relationship of members are modified then maternity needs to be checked
    if (this.elevateAddons.length > 0) {
      this.changeInAddonCover(maternityGroup, maternityAddon, { checked: this.addons.get(maternityAddon.label).value })
    }
  }

  checkForCriticalAddon(age: number, memberIndex: number, type: string) {
    let criticalGroup = this.findParentGroups(this.addonGroupDesc.LumpSum);
    let criticalIllnessAddon = criticalGroup.childAddons.find(addon => addon.coverId === this.coverId.CriticalIllness);

    const memberTypeCount = type + ' ' + (memberIndex + 1);
    const memberExists = criticalIllnessAddon.dropDownValue.findIndex(data => data.label === memberTypeCount);
    if (age >= 18 && age <= 50) {
      if (memberExists === -1) {
        criticalIllnessAddon.dropDownValue.push({ id: memberIndex, label: memberTypeCount });
      }
    }
    else { // age > 50 or age <= 18
      if (memberExists !== -1) {
        criticalIllnessAddon.dropDownValue.splice(memberExists, 1);

        if (this.elevateAddons.length > 0 && this.addons.get(this.coverDesc.Critical).value) {
          const selectedCriticalData = this.addons.get(this.coverDesc.criticalIllnessForAdult).value || [];
          const indexToRemove = selectedCriticalData.findIndex(data => data === memberTypeCount);
          if (indexToRemove !== -1) {
            selectedCriticalData.splice(indexToRemove, 1);
          }
        }
      }
    }

    this.criticalAdultData = criticalIllnessAddon.dropDownValue;

    if (criticalIllnessAddon.dropDownValue.length > 0) {
      criticalIllnessAddon.dropDownValue = this.sortAdultData(criticalIllnessAddon.dropDownValue);
    }

    if (this.elevateAddons.length > 0) {
      // let criticalIllnessAddon = this.elevateAddons[3].childAddons.find(addon => addon.coverId === this.coverId.CriticalIllness);
      // criticalIllnessAddon.dropDownValue = [];
      // criticalIllnessAddon.dropDownValue = this.criticalAdultData;
      this.changeInAddonCover(criticalGroup, criticalIllnessAddon, { checked: this.addons.get(this.coverDesc.Critical).value });
    }

  }


  checkForPersonalAccidentAddon(age: number, id: number, type: string) {
    let personalAccidentGroup = this.findParentGroups(this.addonGroupDesc.LumpSum);
    // Pa cover to be pushed for member whose age is between 18 to 65
    let memberIndex: number = id;
    if (age >= 18 && age <= 65 && type == healthMembers.Adult) {

      if (this.occupation.length == 0) {
        this.occupation.push(this.createOccupation(memberIndex, type));
      }
      // If that occupation exists for that member then remove it or push the values
      else if (this.occupation.length > 0) {
        let memberFound: boolean = false;
        let occupationMember = 'occupationFor' + type + (memberIndex + 1);
        for (let i = 0; i < this.occupation.length; i++) {
          const group = this.occupation.at(i) as FormGroup;
          const formKeys = Object.keys(group.controls);
          const keyFound = formKeys.some(key => key.includes(occupationMember));
          if (keyFound) {
            memberFound = true;
            break;
          }
        }
        // If member not found push it in occ array
        if (!memberFound) {
          this.occupation.push(this.createOccupation(memberIndex, type));
        }

      }
    }
    // If age is more simply remove the member
    else if (age < 18 || age > 65) {
      let occupationMember = 'occupationFor' + type + (memberIndex + 1);
      for (let i = 0; i < this.occupation.length; i++) {
        const group = this.occupation.at(i) as FormGroup;
        const formKeys = Object.keys(group.controls);
        const keyFound = formKeys.some(key => key.includes(occupationMember));
        if (keyFound) {
          this.occupation.removeAt(i);
          break;
        }
      }
    }

    if (this.elevateAddons.length > 0) {
      let personalAccident: ElevateAddonStructure = personalAccidentGroup.childAddons.filter(childAddon => childAddon.label == this.coverDesc.Personal)[0];
      this.changeInAddonCover(personalAccidentGroup, personalAccident, { checked: this.addons.get(this.coverDesc.Personal).value });
    }
  }

  checkForPAAndCIPolicyLevel(age: number, id: number, type: string) {
    let lumpSumGroup = this.findParentGroups(this.addonGroupDesc.LumpSum);
    if (!this.utilityService.isEmptyOrNull(lumpSumGroup) && lumpSumGroup.childAddons.length > 0) {
      let criticalIllnessAddon = lumpSumGroup.childAddons.find(addon => addon.coverId === this.coverId.CriticalIllness);

      let adultAge = [];
      if (this.adultDetails.value.length > 0) {
        this.adultDetails.value.forEach((element, index) => {
          // fetch age of each individual
          let age: number = this.utilityService.calculateAge(this.adultDetails.value[index]['dobAdult' + (index + 1)]);
          adultAge.push(age);
        });
      }

      let paPresent = adultAge.every(age => age >= 18 && age <= 65);
      let criticalPresent = adultAge.every(age => age >= 18 && age <= 50) && !this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.Critical));
      let personalAccidentExist = !this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.Personal));
      let criticalAddonExist = !this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.Critical));
      if ((paPresent && personalAccidentExist) || (criticalPresent && criticalAddonExist)) {
        this.adultDetails.value.forEach((element, index) => {

          // For Personal Accident
          if (paPresent) {
            if (this.occupation.length == 0) {
              // Construction of Diy Occ / 
              // Construction of More Addons occ
              this.occupation.push(this.createOccupation(index, type));
              // this.moreAddonsOccupation.push(this.createOccupation(index, type));
            }
            // If that occupation exists for that member then remove it or push the values
            else if (this.occupation.length > 0) {
              let memberFound: boolean = false;
              let occupationMember = 'occupationFor' + type + (index + 1);
              for (let i = 0; i < this.occupation.length; i++) {
                const group = this.occupation.at(i) as FormGroup;
                const formKeys = Object.keys(group.controls);
                const keyFound = formKeys.some(key => key.includes(occupationMember));
                if (keyFound) {
                  memberFound = true;
                  break;
                }
              }
              // If member not found push it in occ array
              if (!memberFound) {
                this.occupation.push(this.createOccupation(index, type));
                // this.moreAddonsOccupation.push(this.createOccupation(index, type));
              }
            }
          }

          // For Critical Illness
          if (criticalPresent) {
            const memberTypeCount = healthMembers.Adult + ' ' + (index + 1);
            const memberExists = criticalIllnessAddon.dropDownValue.length > 0 ? criticalIllnessAddon.dropDownValue.findIndex(data => data.label === memberTypeCount) : -1;
            if (memberExists === -1) {
              criticalIllnessAddon.dropDownValue.push({ id: index, label: memberTypeCount });
            }
          }
        })
      }

      else if ((!paPresent && personalAccidentExist) || (!criticalPresent && criticalAddonExist)) {
        if (!paPresent && !this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.Personal))) {
          this.occupation.clear();
          // this.moreAddonsOccupation.clear();
        }
        if (!criticalPresent && !this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.Critical))) {
          criticalIllnessAddon.dropDownValue = [];
        }
      }

      if (!this.utilityService.isEmptyOrNull(criticalIllnessAddon) && criticalIllnessAddon.dropDownValue.length > 0) {
        criticalIllnessAddon.dropDownValue = this.sortAdultData(criticalIllnessAddon.dropDownValue);
      }

      this.criticalAdultData = !this.utilityService.isEmptyOrNull(criticalIllnessAddon) ? criticalIllnessAddon.dropDownValue : [];

      if (this.elevateAddons.length > 0 && lumpSumGroup.childAddons.length > 0) {
        lumpSumGroup.childAddons.forEach(data => {
          if (data.coverId == this.coverId.PersonalAccident || data.coverId == this.coverId.CriticalIllness) {
            this.changeInAddonCover(lumpSumGroup, data, { checked: this.addons.get(data.label).value });
          }
        })

        // More Addons
        // this.elevateAllAddons.forEach(data => {
        //   if (data.CoverId == this.coverId.PersonalAccident || data.CoverId == this.coverId.CriticalIllness) {
        //     this.changeInMoreAddons(data, { checked: this.moreAddons.get(data.CoverText).value }, false);
        //   }
        // })
      }
    }
  }

  sortAdultData(memberData: Array<{ id: number, label: string }>): Array<{ id: number, label: string }> {
    // Now sort adult bases on increasing order 
    if (memberData.length > 0) {
      memberData = memberData.sort((a, b) => parseInt(a['label'].split(' ')[1]) - parseInt(b['label'].split(' ')[1])).map((data, index) => {
        return { ...data, id: index };
      });
    }
    return memberData;

  }

  selectOccupationOption(data, index, moreAddonsSource: boolean) {
    // There are certain occupations which should not be allowed to get selected
    let risk = this.occupationMaster.find(d => d.value === data).key;
    if (risk == '3' || risk == '4') {
      this.utilityService.showSnackbar('The selected occupation is not eligible for personal accident cover.');
      let controlName = moreAddonsSource ? this.moreAddonsOccupation.value[index]['occLabel'] + this.moreAddonsOccupation.value[index]['memberIndex'] : this.occupation.value[index]['occLabel'] + this.occupation.value[index]['memberIndex'];
      let resetOccupation = moreAddonsSource ? this.moreAddonsOccupation : this.occupation;
      resetOccupation.at(index).get(['occupationFor' + controlName]).setValue('');
    }
    this.resetFooterAndStepper();
  }

  constructElevateAddons(addOns: ElevateAddonCover[] | NewAddOnCoverDetails[] | DropdownAddonList[]) {
    let groupsFound: Array<number> = [];
    if (addOns.length === 0) return;

    let uniqueGroups: Array<UniqueGroups> = [];
    let totalAddons: ElevateAddonStructure[] = [];
    addOns.forEach((details, index) => {
      let checkBoxData: { disabledAddon: boolean, checkedAddon: boolean } = {
        disabledAddon: details.disabled || false,
        checkedAddon: details.IsAddonSelected || false
      };

      // Construction of Befit and Other Controls
      // It will add control only if befit is not present
      if (details.CoverText.toLowerCase().includes(this.coverDesc.Befit.toLowerCase()) &&
        this.utilityService.isEmptyOrNull(this.elevateControls.get(this.coverDesc.Befit))) {
        this.addAddonsControl(this.coverDesc.Befit, checkBoxData.checkedAddon);
      }

      // All remaining controls are added
      else if (!details.CoverText.toLowerCase().includes(this.coverDesc.Befit.toLowerCase())) {
        this.addAddonsControl(details.CoverText, checkBoxData.checkedAddon);
        // Disable those addons wwhose control is disabled
        if (details.disabled) {
          this.dynamicFormOperations(this.validationOperation.disableField, details.CoverText, '')
        }
      }

      let uniqueGroupFound: boolean = false;

      if (details.groups != 0) {
        uniqueGroupFound = !groupsFound.includes(details.groups);
        if (uniqueGroupFound) {
          uniqueGroups.push({ 'groupId': details.groups, 'hide': details.IsVisible ? false : true, groupDesc: details.groupName });
          groupsFound.push(details.groups);
        }
      }
      else {
        uniqueGroupFound = false;
      }

      let toolTipPresent: boolean = false;
      let dropDownValue: Array<{ id: number, label: string }> = [];

      if (!this.utilityService.isEmptyOrNull(details.DropDownList) && details.DropDownList.length > 0) {
        let tempValue = details.DropDownList.split(',');

        // Now if the default value includes in dropdown then we need to splice it
        // Only if it is of reducing waiting period
        if (this.reductionCoverId.includes(details.CoverId) && tempValue.length > 0 && !this.utilityService.isEmptyOrNull(details.DefaultValue)) {
          dropDownValue = tempValue
            .filter(elem => elem !== details.DefaultValue)
            .map((data, index) => ({ id: index, label: data }));
          toolTipPresent = true;
        }
        else if (!this.reductionCoverId.includes(details.CoverId)) {
          dropDownValue = tempValue.map((data, index) => ({ id: index, label: data }));
        }
      }

      // Adding annual health check ups members
      if (details.CoverId == this.coverId.AnnualHealthCheckups) {
        // It will include adult data
        dropDownValue = this.annualHealthCheckupMembers();
      }

      let controlName = this.fetchAddonControlNames(details.CoverId);
      let defaultValue = this.utilityService.isEmptyOrNull(details.DefaultValue) ? '' : details.DefaultValue;
      let defaultDropDownValue = dropDownValue.length == 1 ? dropDownValue[0].label : ''
      let dropDownLabelPh = this.patchLblAndPlaceHolder(details.CoverId);
      totalAddons.push({
        'label': details.CoverText,
        'checkboxValue': checkBoxData,
        'type': 'checkbox',
        'coverId': details.CoverId,
        'isForAdult': details.IsForAdult,
        'isForChild': details.IsForChild,
        'isForFemale': details.IsForFemaleAdult,
        'visiblityAsPerService': details.IsVisible,
        'position': details.position,// This helps to determine the position of the addons for placing
        'group': details.groups,
        'groupName': details.groupName,
        'fieldType': dropDownValue.length == 0 ? '' : dropDownValue.length == 1 ? 'input' : 'dropdown',
        'dropDownValue': dropDownValue,
        'dropDownControlName': controlName,
        'defaultDropDownControlValue': defaultDropDownValue,
        'defaultDropDownValue': defaultValue,
        'tooltipPresent': toolTipPresent,
        'showDependentField': false,
        'dropDownLabel': dropDownLabelPh.label,
        'dropDownPlaceholder': dropDownLabelPh.placeholder,

      })
      if (!this.utilityService.isEmptyOrNull(controlName)) {
        // maternity, critica illness and annual helath checkups value is dropdown
        let memberAddons = [this.coverId.Maternity, this.coverId.CriticalIllness, this.coverId.AnnualHealthCheckups]
        let value = memberAddons.includes(details.CoverId) ? [] : defaultDropDownValue;
        this.addAddonsControl(controlName, value);
      }

    });
    this.addAddonsControl(this.coverDesc.proposerDobCi, '');
    this.sortBasedOnGroup(totalAddons, uniqueGroups);
    this.calculateActiveAddonCount();
    // Initially first tab will be present
    this.selectedElevateGroup = this.elevateAddons[0];
    this.elevateActiveGroupImg = this.tabImages[0].notFilled;

    if (this.addonsLoadedInitially > 0 && this.activateBoosterTopup) {
      this.resetAddonView(false);
    }

    // Explicit call for jumpstart and inflation protector si
    this.pedSelected();
    if (this.activateBoosterTopup) {
      setTimeout(() => {
        this.siBasedAddonFilter();
      }, 100);
    }

    this.addonsLoadedInitially++;
  }

  calculateActiveAddonCount() {
    this.elevateAddons.forEach(parentAddons => {
      parentAddons.addonsCount = 0;
      parentAddons.addonsCount = parentAddons.childAddons.reduce((count, data) => {
        return count + (data.visiblityAsPerService ? 1 : 0);
      }, 0);
    });
  }

  calculateActiveMoreAddonCount() {
    if (this.elevateAllAddons.length > 0) {
      this.moreAddonActivatedCount = 0;
      this.moreAddonActivatedCount = this.elevateAllAddons.reduce((count, childAddons) => {
        return count + (childAddons.IsVisible ? 1 : 0);
      }, 0);
    }
  }

  annualHealthCheckupMembers(): Array<{ id: number, label: string }> {
    let dropdownValue: Array<{ id: number, label: string }>;
    if (this.adultDetails.value.length > 0) {
      dropdownValue = this.adultDetails.value.map((data, memberIndex) => {
        let obj = { 'id': memberIndex, 'label': healthMembers.Adult + ' ' + (memberIndex + 1) }
        return obj;
      })
    }
    this.annualAdultData = dropdownValue;
    return dropdownValue;
  }

  sumInsuredChange() {

    let mostlyBoughtGroup = this.findParentGroups(this.addonGroupDesc.MostlyBought);
    if (this.elevateAddons.length > 0) {
      mostlyBoughtGroup.childAddons.forEach(data => {
        if (data.label == this.coverDesc.PowerBooster || data.label == this.coverDesc.InfiniteCover || data.label == this.coverDesc.RoomModifier) {
          this.changeInAddonCover(mostlyBoughtGroup, data, { checked: this.addons.get(data.label).value })
        }
        else {
          return
        }
      })
    }

    // More Addons
    // this.elevateAllAddons.forEach(data => {
    //   if (data.CoverId == this.coverId.PowerBooster || data.CoverId == this.coverId.InfiniteCover) {
    //     this.changeInMoreAddons(data, { checked: this.moreAddons.get(data.CoverText).value }, false)
    //   }
    // })
  }

  // To show Vd Based on selected SI
  changeInBoosterSumInsured() {
    this.voluntaryDeductible = [];
    this.elevateControls.controls['voluntaryDeductible'].setValue('');
    let selectedSI = this.elevateControls.controls['applicantAnnualSum'].value;
    if (!this.utilityService.isEmptyOrNull(selectedSI)) {
      let vd: SumInsuredDetails[] = this.sumInsuredDetails.filter(data => data.SumAmount == selectedSI);
      if (vd.length == 1) {
        this.voluntaryDeductible = vd[0].VDValues;
      }
      this.siBasedAddonFilter();
    }
  }

  changeInTopupRange() {
    let range = this.elevateControls.controls['policyRange'].value;
    if (!this.utilityService.isEmptyOrNull(range)) {
      this.superSaverComboSI = this.superSaverComboMasterSI.filter(data => data.range == range);
      let siValue = this.superSaverComboSI.length > 0 ? this.superSaverComboSI[0]['value'] : '';
      this.elevateControls.controls['applicantAnnualSum'].setValue(siValue);
      // this.siBasedAddonFilter();
    }
  }

  siBasedAddonFilter() {
    let sumInsuredGroup = this.findParentGroups(this.addonGroupDesc.EnhancedSumPeriod);
    if (this.elevateAddons.length > 0 && !this.utilityService.isEmptyOrNull(sumInsuredGroup) && sumInsuredGroup.childAddons.length > 0) {
      let inflationProtectorAddonId = this.findChildAddonGroups(this.coverId.InflationProtector, sumInsuredGroup.childAddons);
      this.changeInAddonCover(sumInsuredGroup, sumInsuredGroup.childAddons[inflationProtectorAddonId], { checked: this.addons.get(this.coverDesc.InflationProtector).value })
    }
  }

  changeInTopupSI() {
    let topUpSiValue = this.superSaverComboSI.length == 1 ? this.superSaverComboSI[0].value : this.elevateControls.get('applicantAnnualSum').value;
    if (!this.utilityService.isEmptyOrNull(topUpSiValue)) {
      let siSplit = topUpSiValue.split(':');
      this.topUpSiDesc = `Base SI: ${siSplit[0]} \n Topup SI:  ${siSplit[1]}`;
    }
  }

  fetchAddonControlNames(coverId: number): string {
    switch (coverId) {
      case this.coverId.AnnualHealthCheckups:
        return this.coverDesc.AnnualHealthForAdult;
      case this.coverId.Maternity:
        return this.coverDesc.MaternityForAdultValue;
      case this.coverId.CriticalIllness:
        return this.coverDesc.criticalIllnessForAdult;
      case this.coverId.Befit:
        return this.coverDesc.SelectedBefitData;
      default:
        return this.dropDownControlname.includes(coverId) ? coverId + 'Addon' : '';
    }
  }

  addAddonsControl(controlName: string, defaultValue: string | boolean | string[]) {
    this.addons.addControl(controlName, this.fb.control(defaultValue));
  }

  patchLblAndPlaceHolder(coverId: number): { label: string, placeholder: string } {
    let labelPlaceholder: { label: string, placeholder: string } = { label: '', placeholder: '' };
    switch (coverId) {
      case this.coverId.Befit: {
        labelPlaceholder.label = 'Choose Befit Plan';
        labelPlaceholder.placeholder = 'Select Befit Plan';
        break;
      }
      case this.coverId.Maternity:
      case this.coverId.AnnualHealthCheckups:
      case this.coverId.CriticalIllness: {
        labelPlaceholder.label = 'Member';
        labelPlaceholder.placeholder = 'Select Member';
        break;
      }

      default: {
        labelPlaceholder.label = this.reductionCoverId.includes(coverId) ? 'Waiting Period' : coverId == this.coverId.RoomModifier ? 'Room Modifier' : coverId == this.coverId.CoPayment ? 'Co Payment' : '';
        labelPlaceholder.placeholder = this.reductionCoverId.includes(coverId) ? 'Select Waiting Period' : coverId == this.coverId.RoomModifier || coverId == this.coverId.CoPayment ? 'Select Option' : '';
        break;
      }
    }
    return labelPlaceholder;
  }

  sortBasedOnGroup(addonData, groupsData: Array<UniqueGroups>) {
    let befitData: ElevateAddonStructure[] = [];
    // Sorting addon data based on groups and position
    const sortedAddons = addonData.slice().sort((a, b) => {
      const groupComparison = a.group - b.group;
      return groupComparison === 0 ? a.position - b.position : groupComparison;
    });

    // Sorting the groups in ascending order
    const sortedGroupsData = groupsData.slice().sort((a, b) => a.groupId - b.groupId);

    // Based on group we need to make updated modal to cater html
    let newAddons: ElevateParentAddonDetails[] = [];

    sortedGroupsData.forEach((uniqueGroups, index) => {
      // World wide coverage is hidden
      if (uniqueGroups.groupDesc != this.addonGroupDesc.WorldwideCoverage) {
        this.uniqueGroups.push(uniqueGroups.groupDesc);
      }
      // Filtering addons based on group
      let specificGroupAddons = sortedAddons.filter(data => data['group'] == uniqueGroups['groupId']);

      if (specificGroupAddons.length > 0) {
        let nextIndex = index + 1;
        // If we want to show divider we need to check only when second group needs to be showcased or not 
        let showDividerFlag: boolean = false;
        if (nextIndex < sortedGroupsData.length) {
          showDividerFlag = !sortedGroupsData[nextIndex]['hide']
        }
        else {
          showDividerFlag = false;
        }

        if (!this.utilityService.isEmptyOrNull(uniqueGroups.groupDesc) && uniqueGroups.groupDesc.includes('Mostly')) {
          let befitDropdownData = specificGroupAddons.filter(data => data.label.includes('BeFit'));
          let dropDownLabelPh = this.patchLblAndPlaceHolder(this.coverId.Befit);
          if (befitDropdownData.length > 0) {
            befitData = [{
              checkboxValue: { checkedAddon: false, disabledAddon: false },
              coverId: 0,
              isForAdult: true,
              isForChild: true,
              isForFemale: false,
              label: 'Befit',
              position: 1,
              type: 'checkbox',
              visiblityAsPerService: true,
              group: uniqueGroups['groupId'],
              groupName: uniqueGroups.groupDesc,
              fieldType: 'dropdown',
              dropDownValue: befitDropdownData,
              dropDownControlName: this.fetchAddonControlNames(this.coverId.Befit),
              defaultDropDownValue: '',
              defaultDropDownControlValue: '',
              tooltipPresent: false,
              showDependentField: false,
              dropDownLabel: dropDownLabelPh.label,
              dropDownPlaceholder: dropDownLabelPh.placeholder
            }]
            this.addAddonsControl(befitData[0].dropDownControlName, '')
          }

          let otherDataThanBefit = specificGroupAddons.filter(data => !data.label.includes('BeFit'));
          befitData = otherDataThanBefit.concat(befitData);
        }
        // To hide world wide coverage and for combo there is no reduced waiting period group
        newAddons.push({
          'groupPosition': uniqueGroups['groupId'],
          'category': uniqueGroups.groupDesc,
          'hide': uniqueGroups.groupDesc == this.addonGroupDesc.WorldwideCoverage || (!this.activateBoosterTopup && uniqueGroups.groupDesc == this.addonGroupDesc.ReducedWP) ? true : false,
          'showDivider': showDividerFlag,
          'showMaternityFields': false,
          'showOccupationFields': false,
          'showCriticalFields': false,
          'showCriticalDatePicker': false,
          'showBefitFields': false,
          'addonsCount': 0,
          childAddons: uniqueGroups.groupDesc.includes('Mostly') ? befitData : specificGroupAddons
        })
      }
      specificGroupAddons = [];
    })
    this.elevateAddonsCopy = newAddons;
    this.elevateAddons = newAddons;
    this.uniqueGroups.push('All');

    // Only for super saver below block will work as annual health checkup is disabled
    if (!this.activateBoosterTopup && this.addons.get(this.coverDesc.AnnualHealthCheckups) && this.addons.controls[this.coverDesc.AnnualHealthCheckups].value) {
      let annualHealthIndex = this.findChildAddonGroups(this.coverId.AnnualHealthCheckups, this.elevateAddons[0].childAddons);
      // Set value for annual health checkup 
      this.changeInAddonCover(this.elevateAddons[0], this.elevateAddons[0].childAddons[annualHealthIndex], { checked: this.addons.controls[this.coverDesc.AnnualHealthCheckups].value }, false)
    }
  }

  changeInAddonCover(parentAddons: ElevateParentAddonDetails, childAddons: ElevateAddonStructure, event, countNeeded: boolean = true) {
    let checkboxValue: boolean = event.checked;
    // Below change in addons will only work  if that control will exist.
    if (!this.utilityService.isEmptyOrNull(this.addons.get(childAddons.label))) {
      switch (childAddons.coverId) {

        // Hide and show befit dropdown
        case this.coverId.Befit: {
          childAddons.showDependentField = checkboxValue ? true : false;
          if (checkboxValue) {
            this.addons.get(this.coverDesc.SelectedBefitData).setValidators([Validators.required]);
            this.addons.get(this.coverDesc.SelectedBefitData).updateValueAndValidity();
          }
          else {
            this.dynamicFormOperations(this.validationOperation.setValue, childAddons.label, false);
            this.addons.get(this.coverDesc.SelectedBefitData).setValue('');
            this.addons.get(this.coverDesc.SelectedBefitData).clearValidators();
            this.addons.get(this.coverDesc.SelectedBefitData).updateValueAndValidity();
          }

          break;
        }

        // If Jump start opted Ci can't be selected
        case this.coverId.JumpStart: {
          let isPEDSelected = this.elevateControls.get('PEDSelection').value == healthToggle.Yes;
          // overriding the jumpstart selection if ped is selected jumpstart should not behidden
          checkboxValue = !isPEDSelected ? false : checkboxValue;
          // Hide the reduced waiting period group if ped is not selected
          parentAddons.hide = !isPEDSelected;
          childAddons.visiblityAsPerService = isPEDSelected;
          this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.ABCDRider, checkboxValue);
          if (parentAddons.hide) {
            // Initially first tab will be present
            this.initializeFirstTab();
          }
          // let criticalId = -1;
          // let lumpSumGroup = this.findParentGroups(this.addonGroupDesc.LumpSum);
          // let lumpSumPresent = !this.utilityService.isEmptyOrNull(lumpSumGroup) && lumpSumGroup.childAddons.length > 0;
          // // critical Illness condition will only be hidden or visible only if lumpsum group is activated
          // if (lumpSumPresent) {
          //   criticalId = this.findChildAddonGroups(this.coverId.CriticalIllness, lumpSumGroup.childAddons);
          //   lumpSumGroup.childAddons[criticalId].visiblityAsPerService = !checkboxValue;
          //   // If pa is not present  and critical value is also not selected then we need to hide lumpSum group
          //   lumpSumGroup.hide = this.occupation.length == 0 && checkboxValue ? true : false;
          //   // Hiding or removing of critical Illness Will only be applicable if lump sum group is present
          //   if (checkboxValue) {
          //     if (!this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.Critical))) {
          //       this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.Critical, false);
          //       this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.criticalIllnessForAdult, []);
          //       this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.proposerDobCi, '');
          //       this.dynamicFormOperations(this.validationOperation.clearValidators, this.coverDesc.proposerDobCi);
          //       lumpSumGroup.showCriticalDatePicker = false;
          //     }
          //   }
          //   else {
          //     lumpSumGroup.hide = this.occupation.length > 0 || this.criticalAdultData.length > 0 ? false : true;
          //   }
          // }


          break;
        }

        case this.coverId.PowerBooster:
        case this.coverId.InfiniteCover: {

          childAddons.visiblityAsPerService = false;
          if (!checkboxValue) {
            this.dynamicFormOperations(this.validationOperation.setValue, childAddons.label, false);
          }

          break;
        }

        // case Personal Accident
        case this.coverId.PersonalAccident: {
          // If no occupation is present then simply hide the personal accident cover
          let occupationPresent: boolean = false;
          occupationPresent = this.occupation.length > 0;
          parentAddons.showDivider = occupationPresent;
          childAddons.visiblityAsPerService = occupationPresent;
          childAddons.checkboxValue.checkedAddon = false;
          parentAddons.hide = !occupationPresent && this.criticalAdultData.length == 0;
          // If pa is not present  and critical value is also not selected then we need to hide lumpSum group
          parentAddons.hide = !occupationPresent && this.criticalAdultData.length == 0 ? true : false;
          if (!occupationPresent) {
            parentAddons.showOccupationFields = false;
            if ((this.addons.get(childAddons.label) && this.addons.controls[childAddons.label].value)) {
              this.dynamicFormOperations(this.validationOperation.setValue, childAddons.label, false)
            }
          }

          else if (occupationPresent) {
            parentAddons.showOccupationFields = checkboxValue ? true : false;
            // Reset all occupation data

            this.occupation.value.forEach((data, index) => {
              const keys = Object.keys(data);
              // this.occupation.at(index).get(keys[0]).setValue('');
              if (!checkboxValue) {
                this.addons.get(childAddons.label).setValue(false);
                this.occupation.at(index).get(keys[0]).setValue('');
                this.occupation.at(index).get(keys[0]).clearValidators();
                this.occupation.at(index).get(keys[0]).updateValueAndValidity();
              }
              else if (checkboxValue) {
                this.occupation.at(index).get(keys[0]).setValidators([Validators.required]);
                this.occupation.at(index).get(keys[0]).updateValueAndValidity();
              }
            })
          }
          break;
        }

        // All waiting periods
        //   case this.coverId.WorldWideReduction:
        case this.coverId.MaternityWaiting:
        case this.coverId.RoomModifier:
        case this.coverId.SpecificIllnessReduction:
        case this.coverId.PEDReduction:
        case this.coverId.CoPayment: {
          childAddons.showDependentField = checkboxValue;
          let addonValue = checkboxValue ? childAddons.defaultDropDownControlValue : '';
          this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, addonValue);
          //  For ped reduction and room modifier user should be allowed to select value. Add / reset validations dynamically

          if (childAddons.coverId == this.coverId.RoomModifier || childAddons.coverId == this.coverId.CoPayment || childAddons.coverId == this.coverId.PEDReduction) {
            this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, '');

            if (checkboxValue && childAddons.dropDownValue.length > 1) {
              this.addons.get(childAddons.dropDownControlName).setValidators([Validators.required]);
              this.addons.get(childAddons.dropDownControlName).updateValueAndValidity();
            }

            else if (!checkboxValue && childAddons.dropDownValue.length > 1) {
              this.dynamicFormOperations(this.validationOperation.setValue, childAddons.label, false);
              this.addons.get(childAddons.dropDownControlName).clearValidators();
              this.addons.get(childAddons.dropDownControlName).updateValueAndValidity();
            }
          }

          // If it is maternity waiting period set by default value
          if (childAddons.coverId == this.coverId.RoomModifier || childAddons.coverId == this.coverId.MaternityWaiting || childAddons.coverId == this.coverId.SpecificIllnessReduction) {
            if (checkboxValue) {
              let defaultValue = '';
              if (childAddons.dropDownValue.length == 1) {
                defaultValue = childAddons.coverId == this.coverId.RoomModifier ? childAddons.defaultDropDownValue : childAddons.dropDownValue[0].label;
              }
              this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, defaultValue);
            }
            else {
              this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, '');
            }
          }
          break;
        }

        case this.coverId.CriticalIllness: {
          // Critical illness not selected scenario
          const isCriticalMembersPresent = childAddons.dropDownValue.length > 0;
          childAddons.visiblityAsPerService = isCriticalMembersPresent;
          parentAddons.showDivider = isCriticalMembersPresent;
          childAddons.showDependentField = false;
          parentAddons.hide = !isCriticalMembersPresent && this.occupation.length == 0;
          let jumpStartId = -1;
          // if critical illness is selcted jump start can't be added
          const isPEDSelected = this.elevateControls.get('PEDSelection').value == healthToggle.Yes;
          let reducedWaitingPeriodGroup = this.findParentGroups(this.addonGroupDesc.ReducedWP);
          let reducedWaitingGroupPresent: boolean = !this.utilityService.isEmptyOrNull(reducedWaitingPeriodGroup) && reducedWaitingPeriodGroup.childAddons.length > 0;
          // If reduced waiting period group is present then only hide jumpstart
          if (reducedWaitingGroupPresent) {
            jumpStartId = this.findChildAddonGroups(this.coverId.JumpStart, reducedWaitingPeriodGroup.childAddons);
            if (jumpStartId != -1) {
              reducedWaitingPeriodGroup.childAddons[jumpStartId].visiblityAsPerService = !checkboxValue && isPEDSelected;
              if (isPEDSelected) {
                reducedWaitingPeriodGroup.hide = checkboxValue && isCriticalMembersPresent;
              }
            }
          }

          if (!checkboxValue) {
            parentAddons.showCriticalDatePicker = false;
            this.dynamicFormOperations(this.validationOperation.setValue, childAddons.label, false);
            this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, []);
            this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.proposerDobCi, '');
            this.addons.get(this.coverDesc.proposerDobCi).clearValidators();
            this.addons.get(this.coverDesc.proposerDobCi).updateValueAndValidity();
            // this.dynamicFormOperations(this.validationOperation.clearValidators, this.coverDesc.proposerDobCi);

          }

          // Scenario 2: If critical illness addon is selected
          // If critical illness addon is not present then show message to select appropriate age
          else {
            parentAddons.showCriticalDatePicker = false;
            if (!isCriticalMembersPresent) {
              this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, []);
              this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.proposerDobCi, '');
              // this.dynamicFormOperations(this.validationOperation.clearValidators, this.coverDesc.proposerDobCi);
              this.addons.get(this.coverDesc.proposerDobCi).clearValidators();
              this.addons.get(this.coverDesc.proposerDobCi).updateValueAndValidity();
              if ((this.addons.get(this.coverDesc.Critical) && this.addons.controls[this.coverDesc.Critical].value)) {
                this.dynamicFormOperations(this.validationOperation.setValue, childAddons.label, false);
              }
            }
            // If  critical addons data is present then show the dropdown/input field
            else if (isCriticalMembersPresent) {
              if (this.addons.get(this.coverDesc.ABCDRider)) {
                this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.ABCDRider, false);
              }
              // check for critical illness proposer dob
              this.checkProposerDobCI(parentAddons);
              let memberValue = [];
              childAddons.dropDownValue.forEach(data => {
                memberValue.push(data.label);
              })
              this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, memberValue);
            }
          }
          break;
        }
        // case Annual Health Checkups
        case this.coverId.AnnualHealthCheckups: {
          let memberValue = [];
          let annualMemberPresent: boolean = false;
          annualMemberPresent = childAddons.dropDownValue.length > 0;
          childAddons.visiblityAsPerService = annualMemberPresent;

          if (!annualMemberPresent) {
            this.dynamicFormOperations(this.validationOperation.setValue, childAddons.label, false);
            this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, []);
          }

          else if (annualMemberPresent) {
            if (checkboxValue) {
              childAddons.dropDownValue.forEach(data => {
                memberValue.push(data.label);
              })
              this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, memberValue);
            }
            else {
              this.dynamicFormOperations(this.validationOperation.setValue, childAddons.label, false);
              this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, []);
            }
          }
          break;
        }

        // Maternity Cover
        case this.coverId.Maternity: {
          // If no relation is yet selected then don't allow adult to select maternity cover
          let maternityValueExists = childAddons.dropDownValue.length > 0;
          parentAddons.showDivider = maternityValueExists;
          parentAddons.hide = !maternityValueExists;
          childAddons.visiblityAsPerService = maternityValueExists;
          if (!maternityValueExists || !checkboxValue) {
            childAddons.showDependentField = false;
            this.dynamicFormOperations(this.validationOperation.setValue, childAddons.label, false);
            this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, []);
            // hide the cover vaccination and new born baby cover
            this.hideUnhideCovers(parentAddons, childAddons);
          }

          // If relation is selected then only perform below operation
          // For showcasing or hiding other maternity related covers
          else if (maternityValueExists) {
            this.hideUnhideCovers(parentAddons, childAddons);
            if (checkboxValue) {
              if (childAddons.dropDownValue.length == 1) {
                this.dynamicFormOperations(this.validationOperation.setValue, childAddons.dropDownControlName, [childAddons.dropDownValue[0].label])
                // this.dynamicFormOperations(this.validationOperation.disableField, childAddons.dropDownControlName);
                this.dynamicFormOperations(this.validationOperation.clearValidators, childAddons.dropDownControlName);
              }
            }
          }
          break;
        }

        // Inflation protector will be hidden for 4.9Cr for combo and topup
        case this.coverId.InflationProtector: {
          let annualSumData = this.elevateControls.get('applicantAnnualSum').value;
          let siCondition = this.utilityService.isEmptyOrNull(annualSumData) ? false : annualSumData.includes('49000000');

          // So if 4.9 cr si is met and only child addons is 1 then hide the group
          parentAddons.hide = siCondition && parentAddons.childAddons.length == 1;
          childAddons.visiblityAsPerService = !siCondition;
          // Call tab changed only if it is hidden
          // Initially first tab will be present
          if (parentAddons.hide) {
            // If that addon is selected then mark it as false
            if (checkboxValue) {
              this.addons.get(this.coverDesc.InflationProtector).setValue(false);
              checkboxValue = false;
            }
            this.initializeFirstTab();
          }

          break;
        }
        default: {
          this.addons.get(childAddons.label).setValue(checkboxValue);
          break;
        }
      }

      this.changeInCover = true;
      this.resetFooterAndStepper();
      this.calculateActiveAddonCount();
      if (countNeeded) {
        this.showAddonsSelected(parentAddons, childAddons, checkboxValue);
      }
    }

  }

  initializeFirstTab() {
    // Initially first tab will be present
    if (this.elevateAddons.length > 0) {
      let elevateAddons = this.elevateAddons;
      this.elevateAddons = [];
      setTimeout(() => {
        this.elevateAddons = [...elevateAddons];
        this.tabChanged(this.elevateAddons[0]);
      }, 0);
    }
  }


  changeInMoreAddons(childAddons: NewAddOnCoverDetails, event, resetStepper: boolean) {
    let checkboxValue: boolean = event.checked;
    let allowSI = this.elevateControls.get('applicantAnnualSum').value >= 1000000;
    let allowPercentSI = (this.elevateControls.get('applicantAnnualSum').value == 500000 || this.elevateControls.get('applicantAnnualSum').value == 750000);
    let portabilityPresent = this.elevateControls.value.productType != 'New';
    // Befit any value can be sent
    if (!childAddons.CoverText.includes('BeFit') && childAddons.CoverId != this.coverId.Befit) {
      this.moreAddons.get(childAddons.CoverText).setValue(checkboxValue);
      // this.dynamicFormOperations(this.validationOperation.setValue, childAddons.CoverText, checkboxValue);
    }

    switch (childAddons.CoverId) {
      case this.coverId.PowerBooster:
      case this.coverId.InfiniteCover: {

        let powerInfyCondition: boolean = false;
        // For infinite cover if si is allowed and not in portability then only it will be visible
        // if (childAddons.CoverId == this.coverId.InfiniteCover) {
        //   powerInfyCondition = allowSI && !portabilityPresent;
        // }
        // For power booster we just need to check si condition

        // else {
        powerInfyCondition = allowSI;
        // }
        childAddons.IsVisible = powerInfyCondition ? true : false;
        if (!powerInfyCondition || !checkboxValue) {
          this.moreAddons.get(childAddons.CoverText).setValue(false);
          // this.dynamicFormOperations(this.validationOperation.setValue, childAddons.CoverText, false);
        }

        break;

      }

      case this.coverId.JumpStart: {
        // If Jump start opted critical illness can't be taken
        let criticalIllnessId = this.elevateAllAddons.findIndex(data => data.CoverId == this.coverId.CriticalIllness);
        this.elevateAllAddons[criticalIllnessId].IsVisible = !checkboxValue;

        if (checkboxValue) {
          this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.Critical, false);
          this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.criticalIllnessForAdult, []);
          this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.proposerDobCi, '');
          this.moreAddons.get(this.coverDesc.proposerDobCi).clearValidators();
          this.moreAddons.get(this.coverDesc.proposerDobCi).updateValueAndValidity();
          // this.dynamicFormOperations(this.validationOperation.clearValidators, this.coverDesc.proposerDobCi);
          this.showMoreCriticaldatePicker = false;
        }
        // If it is selected as no and critical value is also no then visible jump start
        else if (!checkboxValue && !this.moreAddons.get(this.coverDesc.Critical).value) {
          childAddons.IsVisible = true;
        }
        break;
      }

      // Default values to be set for this dropdown and input field only for more addons
      case this.coverId.RoomModifier:
      case this.coverId.CoPayment:
      case this.coverId.MaternityWaiting: {
        let controlName = this.fetchAddonControlNames(childAddons.CoverId);
        let controlValue = '';
        if (checkboxValue) {
          switch (childAddons.CoverId) {
            case this.coverId.RoomModifier: {
              controlValue = allowPercentSI ? '1% of SI' : 'Upgrade';
              this.dynamicFormOperations(this.validationOperation.setValue, controlName, controlValue);
              break;
            }
            case this.coverId.CoPayment: {
              controlValue = '10';
              this.dynamicFormOperations(this.validationOperation.setValue, controlName, controlValue);
              break;
            }
            case this.coverId.MaternityWaiting: {
              controlValue = '1';

              break;
            }
            default:
              break;

          }
          this.dynamicFormOperations(this.validationOperation.setValue, controlName, controlValue);
        }

        else {
          this.dynamicFormOperations(this.validationOperation.setValue, controlName, '');
        }
        break;
      }


      case this.coverId.Befit: {
        if (checkboxValue) {
          this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.SelectedBefitData, 'BeFit_A');
        }
        else {
          this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.SelectedBefitData, '');
        }
        break;
      }

      case this.coverId.AnnualHealthCheckups: {
        let memberValue = [];
        let annualMemberPresent: boolean = false;
        annualMemberPresent = this.annualAdultData.length > 0;
        childAddons.IsVisible = annualMemberPresent;

        if (!annualMemberPresent) {
          this.dynamicFormOperations(this.validationOperation.setValue, childAddons.CoverText, false);
          this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.AnnualHealthForAdult, []);
        }

        else if (annualMemberPresent) {
          if (checkboxValue) {
            this.annualAdultData.forEach((data, index) => {
              memberValue.push(healthMembers.Adult + ' ' + (index + 1));
            })
            this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.AnnualHealthForAdult, memberValue);
          }
          else {
            this.dynamicFormOperations(this.validationOperation.setValue, childAddons.CoverText, false);
            this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.AnnualHealthForAdult, []);
          }
        }
        break;
      }

      // Maternity Cover
      case this.coverId.Maternity: {
        // If no relation is yet selected then don't allow adult to select maternity cover
        let maternityValueExists = this.maternityAdultData.length > 0;
        childAddons.IsVisible = maternityValueExists;

        if (!maternityValueExists || !checkboxValue) {
          this.dynamicFormOperations(this.validationOperation.setValue, childAddons.CoverText, false);
          this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.MaternityForAdultValue, []);
          // hide the cover vaccination and new born baby cover
          this.hideUnhideMoreCovers();
        }

        // If relation is selected then only perform below operation
        // For showcasing or hiding other maternity related covers
        else if (maternityValueExists) {
          this.hideUnhideMoreCovers();
          if (checkboxValue) {
            if (this.maternityAdultData.length == 1) {
              this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.MaternityForAdultValue, [this.maternityAdultData[0].label])
              // this.dynamicFormOperations(this.validationOperation.disableField, childAddons.dropDownControlName);
              this.dynamicFormOperations(this.validationOperation.clearValidators, this.coverDesc.MaternityForAdultValue);
            }
          }
        }
        break;
      }

      // Personal Accident
      case this.coverId.PersonalAccident: {
        // If no occupation is present then simply hide the personal accident cover
        let occupationPresent: boolean = false;
        occupationPresent = this.moreAddonsOccupation.length > 0;

        childAddons.IsVisible = occupationPresent;

        if (!occupationPresent) {
          this.showMoreAddonsOccupation = false;
          if ((this.moreAddons.get(childAddons.CoverText) && this.moreAddons.controls[childAddons.CoverText].value)) {
            this.dynamicFormOperations(this.validationOperation.setValue, childAddons.CoverText, false)
          }
        }

        else if (occupationPresent) {
          this.showMoreAddonsOccupation = checkboxValue ? true : false;
          // Reset all occupation data

          this.moreAddonsOccupation.value.forEach((data, index) => {
            const keys = Object.keys(data);
            if (!checkboxValue) {
              this.dynamicFormOperations(this.validationOperation.setValue, childAddons.CoverText, false);
              this.moreAddons.get(childAddons.CoverText).setValue(false);
              this.moreAddonsOccupation.at(index).get(keys[0]).clearValidators();
              this.moreAddonsOccupation.at(index).get(keys[0]).updateValueAndValidity();
            }
            else if (checkboxValue) {
              this.moreAddonsOccupation.at(index).get(keys[0]).setValidators([Validators.required]);
              this.moreAddonsOccupation.at(index).get(keys[0]).updateValueAndValidity();
            }
          })
        }
        break;
      }

      case this.coverId.CriticalIllness: {
        let jumpStartId = this.elevateAllAddons.findIndex(data => data.CoverId == this.coverId.JumpStart);
        // Critical illness not selected scenario
        const isCriticalMembersPresent = this.criticalAdultData.length > 0;
        childAddons.IsVisible = isCriticalMembersPresent;
        this.showMoreCriticaldatePicker = false;
        // If Ci Selected hide jump start
        this.elevateAllAddons[jumpStartId].IsVisible = !checkboxValue && this.elevateControls.get('PEDSelection').value == healthToggle.Yes;

        if (!checkboxValue) {
          this.showMoreCriticaldatePicker = false;
          this.dynamicFormOperations(this.validationOperation.setValue, childAddons.CoverText, false);
          this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.criticalIllnessForAdult, []);
          this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.proposerDobCi, '');
          // this.dynamicFormOperations(this.validationOperation.clearValidators, this.coverDesc.proposerDobCi);
          this.moreAddons.get(this.coverDesc.proposerDobCi).clearValidators();
          this.moreAddons.get(this.coverDesc.proposerDobCi).updateValueAndValidity();
        }

        // Scenario 2: If critical illness addon is selected
        // If critical illness addon is not present then show message to select appropriate age
        else {
          this.showMoreCriticaldatePicker = false;
          if (!isCriticalMembersPresent) {
            this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.criticalIllnessForAdult, []);
            this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.proposerDobCi, '');
            // this.dynamicFormOperations(this.validationOperation.clearValidators, this.coverDesc.proposerDobCi);
            this.moreAddons.get(this.coverDesc.proposerDobCi).clearValidators();
            this.moreAddons.get(this.coverDesc.proposerDobCi).updateValueAndValidity();
            if ((this.moreAddons.get(this.coverDesc.Critical) && this.moreAddons.controls[this.coverDesc.Critical].value)) {
              this.dynamicFormOperations(this.validationOperation.setValue, childAddons.CoverText, false);
            }
          }
          // If  critical addons data is present then show the dropdown/input field
          else if (isCriticalMembersPresent) {
            this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.ABCDRider, false);
            // check for critical illness proposer dob
            this.checkProposerDobCI();
            let memberValue = [];
            this.criticalAdultData.forEach(data => {
              memberValue.push(data.label);
            })
            this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.criticalIllnessForAdult, memberValue);
          }
        }
        break;
      }

      // By default whatever addons value is there it will set the value
      default: {
        // SOmetimes they are sending other befit values
        if (childAddons.CoverText.includes('BeFit')) {
          this.moreAddons.get(this.coverDesc.Befit).setValue(true);
          this.moreAddons.get(this.coverDesc.SelectedBefitData).setValue(childAddons.CoverText);
        }
        else {
          this.moreAddons.get(childAddons.CoverText).setValue(checkboxValue);
        }
        break;
      }

    }
    this.changeInCover = true;
    if (resetStepper) {
      if (this.healthStorageForm.quoteDataSet) {
        this.resetFooterAndStepper();
      }
      this.calculateActiveMoreAddonCount();
    }
  }

  // Addons Selected
  showAddonsSelected(parentAddons: ElevateParentAddonDetails, childAddons: ElevateAddonStructure, event) {

    let addonsPresentIndex = this.selectedAddonList.length == 0 ? -1 : this.selectedAddonList.findIndex(data => data.label == childAddons.label);
    //  If addons is selected and it is not present then push the value
    if (addonsPresentIndex == -1 && event) {
      this.selectedAddonList.push(childAddons);
    }
    // If addons is unchecked and present then delete the value
    else if (addonsPresentIndex != -1 && !event) {
      this.selectedAddonList.splice(addonsPresentIndex, 1);
    }
  }

  deleteAddons(childAddons: ElevateAddonStructure, index: number) {
    let selectedGroup = this.findParentGroups(childAddons.groupName);
    this.changeInAddonCover(selectedGroup, childAddons, { checked: false }, false);
    this.selectedAddonList.splice(index, 1);
    if (this.mobileViewActivated && this.selectedAddonList.length == 0 && !this.showCustomAddons) {
      this.backButtonClicked();
    }
  }

  dynamicFormOperations(operation: string, controlName: string, value?: string | string[] | boolean) {
    switch (operation) {
      case this.validationOperation.clearValidators: {
        this.activatedAddonsGroup.get(controlName).clearValidators();
        this.activatedAddonsGroup.get(controlName).updateValueAndValidity();
        break;
      }

      case this.validationOperation.addValidators: {
        this.activatedAddonsGroup.get(controlName).setValidators([Validators.required]);
        this.activatedAddonsGroup.get(controlName).updateValueAndValidity();
        break;
      }

      case this.validationOperation.disableField: {
        this.activatedAddonsGroup.get(controlName).disable();
        break;
      }
      case this.validationOperation.enableField: {
        this.activatedAddonsGroup.get(controlName).enable();
        break;
      }

      case this.validationOperation.setValue: {
        this.activatedAddonsGroup.get(controlName).setValue(value);
        break;
      }

      default: {
        break;
      }
    }

  }

  hideUnhideCovers(addonDetails: ElevateParentAddonDetails, childAddonDetails: ElevateAddonStructure) {

    addonDetails.childAddons.forEach(data => {
      switch (data.coverId) {
        // New Born and Vaccination Cover
        case this.coverId.NewBorn:
        case this.coverId.VaccinationNewBorn:
        case this.coverId.MaternityWaiting: {
          let maternityAddonValue = this.addons.controls[childAddonDetails.label].value;
          data.visiblityAsPerService = maternityAddonValue;
          // data.showDependentField = maternityAddonValue && data.coverId == this.coverId.MaternityWaiting  ? true : false;
          if (maternityAddonValue && data.checkboxValue.disabledAddon) {
            this.addons.controls[data.label].disable();
          }
          // If maternity not present hide waiting period as well
          if (data.coverId == this.coverId.MaternityWaiting) {
            data.showDependentField = maternityAddonValue && this.addons.controls[data.label].value ? true : false;
            if (!maternityAddonValue) {
              this.dynamicFormOperations(this.validationOperation.setValue, data.label, false);
            }
          }
          break;
        }
        default: {
          break;
        }
      }
    })
  }

  hideUnhideMoreCovers() {
    this.elevateAllAddons.forEach(data => {
      switch (data.CoverId) {
        // New Born and Vaccination Cover
        case this.coverId.NewBorn:
        case this.coverId.VaccinationNewBorn:
        case this.coverId.MaternityWaiting: {
          let maternityAddonValue = this.moreAddons.controls[this.coverDesc.Maternity].value;
          data.IsVisible = maternityAddonValue;

          if (maternityAddonValue && (data.CoverId == this.coverId.NewBorn || data.CoverId == this.coverId.VaccinationNewBorn)) {
            this.moreAddons.controls[data.CoverText].disable();
          }
          // If maternity not present hide waiting period as well
          if (data.CoverId == this.coverId.MaternityWaiting) {
            if (!maternityAddonValue) {
              this.dynamicFormOperations(this.validationOperation.setValue, data.CoverText, false);
            }
          }
          break;
        }
        default: {
          break;
        }
      }
    })
  }

  checkProposerDobCI(addonDetails?: ElevateParentAddonDetails) {
    // Only if critical addon exist
    if (!this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.Critical))) {
      let selfIndex: number = -1;
      let previousMemberValue = this.selfMemberExists;
      if (this.adultDetails.value.length > 0) {
        selfIndex = this.adultDetails.value.findIndex((data, index) => data['adultRealtionship' + (index + 1)] == 'SELF');
      }

      this.selfMemberExists = selfIndex == -1 ? false : true;

      // Case 1: If self found then proposer dob will be hidden and we need to pass self 
      if (this.selfMemberExists) {
        addonDetails.showCriticalDatePicker = false;
        let selfMember = this.adultDetails.value[selfIndex]['dobAdult' + (selfIndex + 1)];
        this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.proposerDobCi, selfMember);
        this.addons.get(this.coverDesc.proposerDobCi).clearValidators();
        this.addons.get(this.coverDesc.proposerDobCi).updateValueAndValidity();
      }
      // Case 2: If self does not exists then proposer dob needs to be asked
      else if (!this.selfMemberExists) {
        addonDetails.showCriticalDatePicker = true;
        if (previousMemberValue) {
          this.dynamicFormOperations(this.validationOperation.setValue, this.coverDesc.proposerDobCi, '')
        }
        this.addons.get(this.coverDesc.proposerDobCi).setValidators([Validators.required]);
        this.addons.get(this.coverDesc.proposerDobCi).updateValueAndValidity();
      }
    }
  }




  memberTabClicked(event) {
    this.stopPropogation(event);
  }

  incrementMember(memberData, event) {
    // this.spinner.show();
    // setTimeout(() => {
    this.stopPropogation(event);
    let previousAdultValue: number = this.adultValue;
    let previousChildValue: number = this.childValue;
    //  Bind the value returned from common method
    let data = this.utilityService.memberIncrement(memberData.label, this.adultDetails, this.childDetails, this.adultValue, this.childValue, 2, 3, true, true);
    this.adultValue = data.adultInc;
    this.childValue = data.childInc;
    setTimeout(() => {
      this.checkMemberBasedAddons(previousAdultValue, true);
    }, 0);

    this.enbleDisableChildDob(memberData.label);
    // Below condition will be applied only when adult is incremented from 0 to 1 and child Value should be equal to 1
    if (previousAdultValue == 0 && previousChildValue == 1) {
      this.setResetChildDOB(this.childDetails.value[0]['childRelationship1'], 0);
    }

    // if child value is incremented then push the min max date for child
    if (previousChildValue <= this.childValue && memberData.label == healthMembers.Child) {
      this.childMinMaxDate.push({ minDate: new Date(), maxDate: new Date() });
    }
    return;
    // }, 0);

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);
  }

  decrymentMember(memberData, event) {
    // this.spinner.show();
    // setTimeout(() => {
    this.stopPropogation(event);
    let previousAdultValue: number = this.adultValue;
    let data = this.utilityService.memberDecryment(memberData.label, this.adultDetails, this.childDetails, this.adultValue, this.childValue, 2, 3, true, true);
    //  Bind the value returned from common method
    this.adultValue = data.adultInc;
    this.childValue = data.childInc;
    this.enbleDisableChildDob(memberData.label);


    if (this.adultValue == 0) {
      this.depIndChildRelationship(this.childDetails.value[0]['childRelationship1'], 0);
      if (this.childValue == 1) {
        this.setResetChildDOB(this.childDetails.value[0]['childRelationship1'], 0);
      }
    }
    setTimeout(() => {
      this.checkMemberBasedAddons(previousAdultValue, false);
    }, 0);
    return;
    // }, 0);

    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 1000);
  }

  //member logic end here
  checkMemberBasedAddons1(previousAdultValue: number, increment: boolean) {
    // let memberBasedGroup = this.findParentGroups(this.addonGroupDesc.MemberBasedCoverage);

    // if (this.elevateAddons[3].childAddons.length > 0) {
    this.elevateAddons.forEach(addonsData => {
      // Check for annual health checkup and critical illness and maternity
      if (addonsData.category == this.addonGroupDesc.MaternityCoverage || addonsData.category == this.addonGroupDesc.LumpSum || addonsData.category == this.addonGroupDesc.MostlyBought)
        addonsData.childAddons.forEach((data, index) => {
          if (data.coverId == this.coverId.AnnualHealthCheckups || data.coverId == this.coverId.CriticalIllness || data.coverId == this.coverId.PersonalAccident || data.coverId == this.coverId.Maternity) {
            if (data.coverId == this.coverId.AnnualHealthCheckups || ((data.coverId == this.coverId.CriticalIllness || data.coverId == this.coverId.Maternity) && (previousAdultValue > this.adultValue))) {
              // If the drop down value is 1 and adult is incremented to 2 then we need to by default push adult 2
              if (data.coverId == this.coverId.AnnualHealthCheckups && (this.adultValue > previousAdultValue) && data.dropDownValue.length == 1) {
                data.dropDownValue.push({ id: 1, label: 'Adult 2' });
                if (this.addons.get(data.label).value) {
                  // this.dynamicFormOperations(this.validationOperation.enableField, data.dropDownControlName);
                  this.dynamicFormOperations(this.validationOperation.setValue, data.dropDownControlName, []);
                  this.dynamicFormOperations(this.validationOperation.addValidators, data.dropDownControlName);
                }
              }
              // If the adult was 2 now it has become 1 then simply pop the adult out
              // Decryment function
              else if (previousAdultValue > this.adultValue && this.adultValue != 0) {
                let member = healthMembers.Adult + ' ' + previousAdultValue;
                data.dropDownValue = data.dropDownValue.filter(data => data.label != member);
                addonsData.childAddons[index].dropDownValue = data.dropDownValue;
              }
            }
            // Personal Accident
            // When one adult gets spliced
            else if (data.coverId == this.coverId.PersonalAccident && (previousAdultValue > this.adultValue) && this.adultValue != 0) {
              if (this.occupation.value.length > 0) {
                let occupationMember = 'occupationFor' + healthMembers.Adult + previousAdultValue;
                for (let i = 0; i < this.occupation.length; i++) {
                  const group = this.occupation.at(i) as FormGroup;
                  const keys = Object.keys(group.controls);
                  const keyFound = keys.some(key => key.includes(occupationMember));
                  if (keyFound) {
                    this.occupation.removeAt(i);
                    break;
                  }
                }
              }
            }
            // If the adult value is decrymented to 0 that means there is only one child now
            else if (this.adultValue == 0) {
              data.dropDownValue = [];
              this.maternityAdultData = [];
              this.criticalAdultData = [];
              addonsData.hide = true;
              this.occupation.clear();
            }
            this.changeInAddonCover(addonsData, addonsData.childAddons[index], { checked: this.addons.get(data.label).value })
          }

        })
    })
    // }
  }

  checkMemberBasedAddons(previousAdultValue: number, increment: boolean) {

    this.elevateAddons.forEach(addonsData => {
      // Check for annual health checkup and critical illness and maternity
      if (addonsData.category == this.addonGroupDesc.MaternityCoverage || addonsData.category == this.addonGroupDesc.LumpSum || addonsData.category == this.addonGroupDesc.MostlyBought)
        addonsData.childAddons.forEach((data, index) => {
          if (data.coverId == this.coverId.AnnualHealthCheckups || data.coverId == this.coverId.CriticalIllness || data.coverId == this.coverId.PersonalAccident || data.coverId == this.coverId.Maternity) {
            if (this.adultValue != 0) {
              if (data.coverId == this.coverId.AnnualHealthCheckups || ((data.coverId == this.coverId.CriticalIllness) && (previousAdultValue > this.adultValue))) {
                // If the drop down value is 1 and adult is incremented to 2 then we need to by default push adult 2
                if (data.coverId == this.coverId.AnnualHealthCheckups && (this.adultValue > previousAdultValue)) {
                  if (data.dropDownValue.length == 1) {
                    data.dropDownValue.push({ id: 1, label: 'Adult 2' });
                  }
                  else if (data.dropDownValue.length == 0) {
                    data.dropDownValue.push({ id: 0, label: 'Adult 1' });
                    data.dropDownValue.push({ id: 1, label: 'Adult 2' });
                  }
                  this.annualAdultData = data.dropDownValue;
                }
                // If the adult was 2 now it has become 1 then simply pop the adult out
                // Decryment function
                else if (previousAdultValue > this.adultValue) {
                  let member = healthMembers.Adult + ' ' + previousAdultValue;
                  data.dropDownValue = data.dropDownValue.filter(data => data.label != member);
                  addonsData.childAddons[index].dropDownValue = data.dropDownValue;
                  if (data.coverId == this.coverId.AnnualHealthCheckups) {
                    this.annualAdultData = data.dropDownValue;
                  }
                  else {
                    this.criticalAdultData = data.dropDownValue;
                  }
                }
              }
              // For maternity If adult value is 1 then remove all the data of maternity
              else if (data.coverId == this.coverId.Maternity && this.adultDetails.value.length == 1) {
                data.dropDownValue = [];
                this.maternityAdultData = [];
              }

              // Personal Accident
              // When one adult gets spliced
              else if (data.coverId == this.coverId.PersonalAccident && (previousAdultValue > this.adultValue)) {
                if (this.occupation.value.length > 0) {
                  let occupationMember = 'occupationFor' + healthMembers.Adult + previousAdultValue;
                  for (let i = 0; i < this.occupation.length; i++) {
                    const group = this.occupation.at(i) as FormGroup;
                    const keys = Object.keys(group.controls);
                    const keyFound = keys.some(key => key.includes(occupationMember));
                    if (keyFound) {
                      this.occupation.removeAt(i);
                      break;
                    }
                  }
                }

                // if (this.moreAddonsOccupation.value.length > 0) {
                //   let occupationMember = 'occupationFor' + healthMembers.Adult + previousAdultValue;
                //   for (let i = 0; i < this.moreAddonsOccupation.length; i++) {
                //     const group = this.moreAddonsOccupation.at(i) as FormGroup;
                //     const keys = Object.keys(group.controls);
                //     const keyFound = keys.some(key => key.includes(occupationMember));
                //     if (keyFound) {
                //       this.moreAddonsOccupation.removeAt(i);
                //       break;
                //     }
                //   }
                // }
              }
            }
            // If the adult value is decrymented to 0 that means there is only one child now
            else if (this.adultValue == 0) {
              data.dropDownValue = [];
              this.maternityAdultData = [];
              this.criticalAdultData = [];
              this.annualAdultData = [];
              this.occupation.clear();
              // this.moreAddonsOccupation.clear();
            }
            let countNeeded: boolean = addonsData.childAddons[index].checkboxValue.disabledAddon ? false : true;
            this.changeInAddonCover(addonsData, addonsData.childAddons[index], { checked: this.addons.get(data.label).value }, countNeeded)
            // let moreAddonsIndex = this.elevateAllAddons.findIndex(moreData => moreData.CoverId == data.coverId);
            // this.changeInMoreAddons(this.elevateAllAddons[moreAddonsIndex], { checked: this.addons.get(data.label).value }, false)
          }
        })
    })
  }

  dropdownValue(controlName) {
  }

  depIndChildRelationship(relationship: string, index: number) {
    //reset dob
    this.childDetails.at(index).get('dobChild' + (index + 1)).setValue('');
    if (!this.utilityService.isEmptyOrNull(relationship)) {
      this.setResetChildDOB(relationship, index);
    }
    this.enbleDisableChildDob(healthMembers.Child);
    this.addValidatorsForNeutralGender(relationship, index, healthMembers.Child);
  }

  //set min max date
  getAdultInsuredMinMax(memberType: string, minMaxType: string, index: number, relationship: string) {
    // For Adult age criteria is 18 years to 125 years
    let adultMaxDate = this.utilityService.subtractYears(new Date(), 18);

    let yearsdate = this.utilityService.subtractYears(new Date(), 125);
    let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
    let adultMinDate = this.utilityService.subtractLeapYearDays(monthdate, 24);

    return minMaxType == 'min' ? adultMinDate : adultMaxDate;
  }

  setResetChildDOB(relationShipName: string, index: number) {
    let minDate, maxDate;
    let noOfAdults = this.adultDetails.length;
    // Max Date
    // Child min date is 92 days if adult is present-- 92 confrim by omkar
    // If no adult present then min date is 6 years
    maxDate = noOfAdults > 0 ? this.utilityService.subtractDays(new Date(), 92) : this.utilityService.subtractYears(new Date(), 6);

    // Min Date
    // Dependent Kid
    if (relationShipName == 'SON' || relationShipName == 'DAUGHTER') {
      let yearDate = this.utilityService.subtractYears(new Date(), 30);
      let monthDate = this.utilityService.subtractMonths(yearDate, 11);
      minDate = this.utilityService.subtractLeapYearDays(monthDate, 24);
    }
    else {
      let yearDate = this.utilityService.subtractYears(new Date(), 18);
      let monthDate = this.utilityService.subtractMonths(yearDate, 11);
      minDate = this.utilityService.subtractDays(monthDate, 24);
    }
    this.childMinMaxDate[index].minDate = minDate;
    this.childMinMaxDate[index].maxDate = maxDate;
  }

  //onvd and co pay change 
  copayVDSelectionChange(value) {
    if (value == 'VD') {
      this.elevateControls.controls['copaymentPercentTaken'].patchValue(this.coPaymentDetails[0]);
    } else {
      this.elevateControls.controls['voluntaryDeductible'].patchValue(this.superSaverDataModal.voluntaryDeductibleDetails[0]);
    }
  }

  manageComboTopupMasterData(response: ElevateMasterResponsePayload) {
    if (response.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      this.elevateMasterData = response.Data;
      // Common configuration
      this.elevateControls.controls['productName'].setValue(this.elevateMasterData.ProductName);
      this.coPaymentDetails = this.elevateMasterData.CoPay[0].split(",").map(val => val + '%');
      this.annualIncome = this.elevateMasterData.IncomeRange;
      this.elevateControls.controls['copaymentPercentTaken'].setValue(this.coPaymentDetails[0])
      let occupationMaster = this.elevateMasterData.Occupation ? this.elevateMasterData.Occupation : '';
      for (let i in occupationMaster) {
        this.occupationMaster.push({ key: occupationMaster[i], value: i });
      }
      this.occupationMaster = this.utilityService.sortDetails(this.occupationMaster, "value");
    }

    // Policy plan, or plans is applicable only when the product is activate booster/ super topup
    if (this.activateBoosterTopup) {
      this.boosterSILoaded = true;
      this.constructBoosterDetails();
    }

    // For combo there is different SI parameter and the format should be eg:10:80
    else if (!this.utilityService.isEmptyOrNull(this.elevateMasterData.SumInsured)) {
      this.constructSaverDetails();
    }

    // Voluntary deductible is applicable only in activate booster
    this.dynamicVDValidation();

    // Back ward patching
    if (this.healthStorageForm.quoteDataSet) {
      // this.elevateControls.controls['applicantAnnualSum'].setValue(this.healthStorageForm.quoteFormData.applicantAnnualSum);
      // Voluntary Deductible is applicable only in Activate Booster
      if (this.activateBoosterTopup) {
        this.elevateControls.controls['applicantAnnualSum'].setValue(this.healthStorageForm.quoteFormData.applicantAnnualSum);
        // If it is plan A and data is set construct SI
        // this.sumInsuredFilter();
        this.changeInBoosterSumInsured();
        this.elevateControls.controls['voluntaryDeductible'].setValue(this.healthStorageForm.quoteFormData.voluntaryDeductible);
      }
      // pincode state city api if data already exist
      if (!this.utilityService.isEmptyOrNull(this.elevateControls.controls['pincode'].value.toString())) {
        this.fetchDataAsPerPincode('', this.elevateControls.controls['pincode'].value);
      }
    }
  }


  constructSaverDetails() {
    if (this.elevateMasterData.sumInsuredRange.length > 0) {
      this.comboPolicyRange = [];
      let policyRange = this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.policyRange : '';
      this.elevateMasterData.sumInsuredRange.forEach((siData, index) => {
        this.comboPolicyRange.push(siData.SumInsured);

        siData.SI.forEach(tempSI => {
          this.superSaverComboMasterSI.push({
            label: tempSI.label,
            value: tempSI.value,
            note: this.transformCoverString(tempSI.label),
            range: this.comboPolicyRange[index]
          });
        })

        if (index == 0 && !this.healthStorageForm.quoteDataSet) {
          policyRange = siData.SumInsured;
        }

        // Filter the SI based on selection of policyrange
        if (policyRange == siData.SumInsured) {
          this.superSaverComboSI = this.superSaverComboMasterSI.filter(data => data.range == policyRange);
          this.elevateControls.controls['policyRange'].setValue(policyRange);
          // If SI value is existing then also patch that value
          let siValue = this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.applicantAnnualSum : this.superSaverComboSI[0]['value'];
          this.elevateControls.controls['applicantAnnualSum'].setValue(siValue);
        }
      })
    }
  }

  dynamicVDValidation() {
    if (this.activateBoosterTopup) {
      this.elevateControls.controls['voluntaryDeductible'].setValidators([Validators.required]);
    }
    else {
      this.elevateControls.controls['voluntaryDeductible'].clearValidators();
    }
    this.elevateControls.controls['voluntaryDeductible'].updateValueAndValidity();
  }

  constructBoosterDetails() {

    // Policy plans
    if (!this.utilityService.isEmptyOrNull(this.elevateMasterData.SumInsuredDetails) && this.elevateMasterData.SumInsuredDetails.length > 0) {
      let policyPlan = [];
      policyPlan = Array.from(new Set(this.elevateMasterData.SumInsuredDetails.map(data => data.PolicyRange
      )));
      this.superSaverDataModal.policyRange = [];
      this.policyPlan = policyPlan.map(data => {
        let policyData = {};
        policyData['label'] = data;
        policyData['value'] = data;
        // Dynamically assigning policy range value
        this.superSaverDataModal.policyRange.push(data);
        return policyData
      });
    }

    // Plan A & Plan B
    if (!this.utilityService.isEmptyOrNull(this.elevateMasterData.SubproductName)) {

      let objKeys = Object.keys(this.elevateMasterData.SubproductName);
      objKeys.forEach(data => {
        this.boosterPlans.push({ label: data, value: this.elevateMasterData.SubproductName[data] });
      })
      let policyRange = this.elevateControls.get('policyRange').value;
      let planValue = this.healthStorageForm.quoteDataSet ? this.healthStorageForm.quoteFormData.activateBoosterPlans : this.boosterPlans[0].label;
      this.elevateControls.controls['planType'].setValue(planValue);
      // If plan value is plan A then below label changes will be applicable
      // if (planValue == this.superSaverDataModal.planType[0] && !this.healthStorageForm.quoteDataSet) {
      //   this.policyPlan[1].label = '60L-3Cr';
      // }

      // Initial fist time SI filteration
      this.constructBoosterSI(this.elevateMasterData.SumInsuredDetails, policyRange);
      this.boosterSILoaded = false;
    }

  }

  //relationship and master api call
  requestDataFromMultipleSources(): Observable<[any, RelationshipResponsePayload]> {
    let proposalData: RelationshipRequestPayload = { product: Constants.healthProposalProductCodeElevate };
    const agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.dealId = agentDetails.MappedProduct.Health.HealthAdvantedgeDealID;
    return this.elevate_service.fetchMasterData(this.activateBoosterTopup).pipe(
      concatMap(healthResponse => {
        return forkJoin([
          of(healthResponse),
          this.healthMasterService.getHealthProposalRelationships(proposalData)
        ]);
      })
    );
  }

  //fetching relationship data response
  fetchElevateDetails() {
    this.subscription.push(this.requestDataFromMultipleSources().subscribe({
      next: (responseList: [ElevateMasterResponsePayload, RelationshipResponsePayload]) => {
        this.multipleServiceError = false;
        this.handleMultipleResponse(responseList)
      },
      error: error => {
        this.multipleServiceError = true;
      }
    })
    )
  }

  //relationship api and master api response
  handleMultipleResponse(responseList: [ElevateMasterResponsePayload, RelationshipResponsePayload]) {
    if (responseList[0].StatusCode == Constants.statusCode_success && responseList[1].StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      this.manageComboTopupMasterData(responseList[0]);
      this.manageHealthRelationShipsData(responseList[1]);
    }
    else {
      this.multipleServiceError = true;
    }
  }

  // handles member relationships 
  manageHealthRelationShipsData(response: RelationshipResponsePayload) {
    let adultRelations = [];
    let childRelations = [];
    if (response.StatusCode == Constants.statusCode_success) {
      if (response.Data.InsuredRelationship.length > 0) {
        adultRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Adult);
        childRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Child);

        this.adultRelationShips = adultRelations.length > 0 ? adultRelations.sort(function (a, b) {
          return a.RelationshipName.localeCompare(b.RelationshipName)
        }) : [];
        this.childRelationShips = childRelations.length > 0 ? childRelations.sort(function (a, b) {
          return a.RelationshipName.localeCompare(b.RelationshipName)
        }) : [];

        // Filter unique Genders
        if (response.Data.InsuredRelationship.length > 0) {
          this.genderData = this.superSaverDataModal.genderData;
        }
      }
      else {
        this.adultRelationShips = [];
        this.childRelationShips = [];
      }
    }
  }

  // reset emi feild
  emiValueReset() {
    this.elevateControls.controls['EMI'].setValue(healthToggle.No);
    this.elevateControls.controls['EMIInstallment'].reset();
    this.elevateControls.controls['EMIInstallment'].clearValidators();
    this.elevateControls.controls['EMIInstallment'].updateValueAndValidity();
  }

  //emi validators
  emiValueValidationUpdate() {
    this.elevateControls.controls['EMIInstallment'].setValue('');
    if (this.elevateControls.controls['EMI'].value == healthToggle.Yes) {
      this.elevateControls.controls['EMIInstallment'].setValidators([Validators.required]);
      this.elevateControls.controls['EMIInstallment'].updateValueAndValidity();
    }
    else {
      this.elevateControls.controls['EMI'].setValue(healthToggle.No);
      this.elevateControls.controls['EMIInstallment'].clearValidators();
      this.elevateControls.controls['EMIInstallment'].updateValueAndValidity();
    }
  }

  constructBoosterSI(sumInsuredData: Array<SumInsuredDetails>, policyRange: string) {
    let si = sumInsuredData;
    this.sumInsuredDetails = si.map(data => {
      let obj = {};
      obj['SumAmount'] = data.SumAmount,
        obj['PolicyRange'] = data.PolicyRange,
        obj['VDValues'] = data.VDValues,
        obj['label'] = data.SumAmount == 9999999999.0 ? 'Unlimited' : (data.SumAmount).toString()
      return obj;
    });
    if (this.boosterSILoaded) {
      this.boosterSIData = this.sumInsuredDetails;
    }
    this.sumInsuredDetails = this.sumInsuredDetails.filter(x => x.PolicyRange == policyRange)
  }

  // get quote
  getQuote(event: EmitButtonData) {
    this.formSubmitted = true;
    this.elevateControls.markAsDirty();
    if (!this.healthStorageForm.quoteDataSet) {
      this.changeInCover = false;
    }
    // let memberBasedValidation = this.checkMemberBasedAddonsValidation();
    // let memberBasedValidation: boolean = this.addonsValidation();
    let memberBasedValidation: boolean = true;
    //self case validation for more than 1 adult
    if (this.adultDetails.length > 0) {
      let adultRelationshipArrCount = 0;
      if (this.adultDetails.value.length > 0) {
        this.adultDetails.value.map((ele, index) => {
          if (ele[`adultRealtionship${index + 1}`] == 'SELF') {

            adultRelationshipArrCount++;
          }
        })
      }

      if ((adultRelationshipArrCount > 1)) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.selfErrorMsg);
        this.popupService.openGenericPopup(this.errorPopupData);
        return;
      }
    }

    if (!this.saverBoosterForm.valid || this.invalidPinCodeMessage !== '') {
      for (const key of Object.keys(this.elevateControls.controls)) {
        if (this.elevateControls.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          if (!this.utilityService.isEmptyOrNull(invalidControl)) {
            invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
            invalidControl.focus();
          }
          break;
        }
      }
      return;
    }

    if (this.elevateControls.controls['cibilDiscount'].value == healthToggle.Yes && !this.cibilSubmitted) {
      this.popupService.closeAllDialog(false);
      let popupData: PopupModal = {
        popupType: popupType.generic,
        imgName: popupImgPath.alertGif,
        header: popupHeader.cibilPopup,
        description: popupDescriptionMsg.cibilDiscount,
        buttonLabel: popupButton.ok
      }
      this.popupService.openGenericPopup(popupData);
      return;
    }

    else if (memberBasedValidation && this.invalidPinCodeMessage == '' && this.saverBoosterForm.valid) {
      this.saveEditQuoteAPI();
    }

  }

  addonsValidation(): boolean {
    let validationStatus: boolean = true;
    if (this.hideBUIService && this.addons.valid) {
      if (this.addons.get(this.coverDesc.Befit) && this.addons.get(this.coverDesc.Befit).value) {
        validationStatus = false;
        this.addons.get(this.coverDesc.SelectedBefitData).setValidators([Validators.required]);
        this.addons.get(this.coverDesc.SelectedBefitData).updateValueAndValidity();
      }
      if (this.addons.get(this.coverDesc.Critical) && this.addons.get(this.coverDesc.Critical).value) {
        validationStatus = false;
        this.addons.get(this.coverDesc.criticalIllnessForAdult).setValidators([Validators.required]);
        this.addons.get(this.coverDesc.criticalIllnessForAdult).updateValueAndValidity();
      }
      if (this.addons.get(this.coverDesc.RoomModifier) && this.addons.get(this.coverDesc.RoomModifier).value) {
        validationStatus = false;
        let controlName = this.fetchAddonControlNames(this.coverId.RoomModifier);
        this.addons.get(controlName).setValidators([Validators.required]);
        this.addons.get(controlName).updateValueAndValidity();
      }
    }
    else {
      validationStatus = true;
    }
    return validationStatus;
  }

  saveEditQuoteAPI() {
    let request: TopupAndComboQuoteRequest = this.constructTopUpAndComboRequest();
    this.quotePdfRequest = request;
    this.subscription.push(this.elevate_service.saveComboTopupQuote(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.constructResponseStructure(response.Data);
          if (this.calculator) {
            this.patchingDone = true;
          }
          this.setHealthStorageForm(response.Data);
          this.healthDataStorageService.setHealthDataStorage(this.healthStorageForm);
          // If premium exists then only allow user to proceed forward
          if (!this.utilityService.isEmptyOrNull(this.healthStorageForm.premiumFormData.totalPremium) && this.healthStorageForm.premiumFormData.totalPremium != '0') {
            this.navigateStepper(true);
          }
          else {
            this.navigateStepper(false);
          }
          this.showPremiumDetails(true);

        }
        else if (response.StatusCode != Constants.statusCode_success) {
          this.navigateStepper(false);
        }
      },
      error: (err) => {
        this.navigateStepper(false);
      }
    }))
  }

  constructResponseStructure(response: ComboQuoteResponsePayload) {
    let emiPremium: number = 0;
    let netPremium: number = 0;
    let taxAmount: number = 0;
    if (response.elevateBoosterResponses.length > 0) {
      response.elevateBoosterResponses.forEach(data => {
        emiPremium = parseInt(data.InstallmentTotalPremium) + emiPremium;
        netPremium = parseInt(data.InstallmentNetPremium) + netPremium;
        taxAmount = parseInt(data.InstallmentTaxAmount) + taxAmount;
      })
      this.emiPremium = (emiPremium).toString();
      this.netPremium = netPremium.toString();
      this.taxAmount = taxAmount.toString();
    }
  }

  // checkMemberBasedAddonsValidation() {
  //   let memberBasedValidation: boolean = false;
  //   let occMember = [];
  //   if ((!this.utilityService.isEmptyOrNull(this.addons.get(this.coverDesc.Personal))) && this.addons.controls[this.coverDesc.Personal].value) {
  //     //  If personal accident value is checked. Then check for valdation
  //     if (this.occupation.controls.length > 0) {
  //       for (let i = 0; i < this.occupation.length; i++) {
  //         const group = this.occupation.at(i) as FormGroup;
  //         let dynamicKey = 'occupationFor' + group.value['occLabel'] + group.value['memberIndex'];

  //         if (!this.utilityService.isEmptyOrNull(group.value[dynamicKey])) {
  //           occMember.push(1);
  //         }
  //       }
  //     }

  //     // Check whether occupation is selected of that fields
  //     if (occMember.length == 0) {
  //       this.utilityService.showSnackbar('Please select occupation for atleast one member');
  //       memberBasedValidation = true;
  //     }
  //     return memberBasedValidation;
  //   }
  //   return memberBasedValidation;
  // }

  navigateStepper(status: boolean) {
    if (!this.calculator) {
      this.customStepperService.emitStepData({ status: status, buttonId: 'f1' });
    }
  }

  showPremiumDetails(data: boolean) {
    this.customFooterService.showPremiumDetails(data);
  }

  setHealthStorageForm(premiumData: ComboQuoteResponsePayload) {
    let resetFlag: boolean = this.checkMemberDetails();
    this.criticalIllnessAddons();
    // let applicantAnnualSum = '';
    // if (this.activateBoosterTopup) {
    //   applicantAnnualSum = this.elevateControls.controls['applicantAnnualSum'].value;
    // }
    // else {
    //   applicantAnnualSum = this.superSaverComboSI.length == 1 ? this.superSaverComboSI[0].value : this.elevateControls.get('applicantAnnualSum').value;
    // }

    if (this.elevateControls.value.productType != 'New') {
      this.healthStorageForm.portabilityDataSet = true;
      this.healthStorageForm.quoteFormData.InstallmentApplicable = "No"
      this.healthStorageForm.quoteFormData.InstallmentFrequency = ""
    }
    else {
      this.healthStorageForm.portabilityDataSet = false;
    }
    this.healthStorageForm.quoteFormData.BiuPlanUniqueId = this.biuToken;

    this.healthStorageForm.quoteFormData.PEDSelection = this.elevateControls.get('PEDSelection').value;
    // this.healthStorageForm.quoteFormData.BIUresponseService = this.BUIresponse;
    this.healthStorageForm.quoteFormData.hideBIUservice = this.hideBUIService;
    // this.healthStorageForm.quoteFormData.elevateBUIAddon = this.elevateBUIAddon;
    this.healthStorageForm.quoteFormData.plans = this.plans;
    this.healthStorageForm.quoteFormData.totalAddonCount = this.totalAddonCount;
    // this.healthStorageForm.quoteFormData.hideDIYAddon = this.hideDIYAddon;
    // this.healthStorageForm.quoteFormData.elevateBUICovertextAddons = this.elevateBUICovertextAddons;
    // this.healthStorageForm.quoteFormData.selectedPlan = this.selectedPlan;

    this.healthStorageForm.quoteFormData.productName = this.elevateControls.controls['productName'].value;
    this.healthStorageForm.quoteFormData.activateBoosterPlans = this.elevateControls.controls['planType'].value;
    this.healthStorageForm.quoteFormData.productType = this.elevateControls.controls['productType'].value;
    this.healthStorageForm.quoteFormData.pincode = this.elevateControls.controls['pincode'].value;
    this.healthStorageForm.quoteFormData.adultDetails = this.adultDetails.value;
    this.healthStorageForm.quoteFormData.childDetails = this.childDetails.value;
    this.healthStorageForm.quoteFormData.cityId = this.stateDetails.cityId;
    this.healthStorageForm.quoteFormData.cityName = this.stateDetails.cityName;
    this.healthStorageForm.quoteFormData.stateId = this.stateDetails.stateId;
    this.healthStorageForm.quoteFormData.totalMemberData = this.getMemberRequestAddons(false, false);
    this.healthStorageForm.quoteFormData.policyTenure = this.elevateControls.controls['policyTenure'].value;
    this.healthStorageForm.quoteFormData.policyRange = this.elevateControls.controls['policyRange'].value;
    this.healthStorageForm.quoteFormData.applicantAnnualSum = this.elevateControls.get('applicantAnnualSum').value;
    this.healthStorageForm.quoteFormData.pedWaitingPeriod = this.elevateControls.controls['pedWaitingPeriod'].value;
    this.healthStorageForm.quoteFormData.conditionWaitingPeriod = this.elevateControls.controls['conditionWaitingPeriod'].value;
    this.healthStorageForm.quoteFormData.preHospitalDuration = this.elevateControls.controls['preHospitalDuration'].value;
    this.healthStorageForm.quoteFormData.postHospitalDuration = this.elevateControls.controls['postHospitalDuration'].value;
    this.healthStorageForm.quoteFormData.cityState = this.elevateControls.controls['cityState'].value;
    this.healthStorageForm.quoteFormData.applicantAnnualIncome = this.elevateControls.controls['applicantAnnualIncome'].value;
    //  Addons Config storage 
    this.healthStorageForm.quoteFormData.copaymentPercentTaken = this.addons.get(this.coverDesc.CoPayment) && this.addons.get(this.coverDesc.CoPayment).value ? this.addons.get(this.fetchAddonControlNames(this.coverId.CoPayment)).value : '';
    this.healthStorageForm.quoteFormData.voluntaryDeductible = this.elevateControls.controls['voluntaryDeductible'].value;
    this.healthStorageForm.quoteFormData.PEDWaitingperiodReduction = this.addons.get(this.coverDesc.PEDReductionRider) && this.addons.get(this.coverDesc.PEDReductionRider).value ? this.addons.get(this.fetchAddonControlNames(this.coverId.PEDReduction)).value : '';
    this.healthStorageForm.quoteFormData.RoomModifier = this.addons.get(this.coverDesc.RoomModifier).value && this.addons.get(this.fetchAddonControlNames(this.coverId.RoomModifier)) && this.addons.get(this.fetchAddonControlNames(this.coverId.RoomModifier)).value ? this.addons.get(this.fetchAddonControlNames(this.coverId.RoomModifier)).value : '';
    this.healthStorageForm.quoteFormData.WorldwideWaitingperiodReduction = this.addons.get(this.coverDesc.WorldWideWaitingPeriod) && this.addons.get(this.coverDesc.WorldWideWaitingPeriod).value ? this.addons.get(this.fetchAddonControlNames(this.coverId.WorldWideReduction)).value : '';
    this.healthStorageForm.quoteFormData.MaternityWaitingPeriodReduction = this.addons.get(this.coverDesc.MaternityReduction) && this.addons.get(this.coverDesc.MaternityReduction).value ? this.addons.get(this.fetchAddonControlNames(this.coverId.MaternityWaiting)).value : '';
    this.healthStorageForm.quoteFormData.NetworkAdvantageApplicable = this.addons.get(this.coverDesc.NetworkAdvantage) && this.addons.get(this.coverDesc.NetworkAdvantage).value ? healthToggle.Yes : null;
    this.healthStorageForm.quoteFormData.GuaranteedSuperBonus = this.addons.get(this.coverDesc.PowerBooster) && this.addons.get(this.coverDesc.PowerBooster).value ? healthToggle.Yes : null;
    this.healthStorageForm.quoteFormData.InfiniteCover = this.addons.get(this.coverDesc.InfiniteCover) && this.addons.get(this.coverDesc.InfiniteCover).value ? healthToggle.Yes : null;
    this.healthStorageForm.quoteFormData.JumpStart = this.addons.get(this.coverDesc.ABCDRider) && this.addons.get(this.coverDesc.ABCDRider).value ? healthToggle.Yes : null;
    this.healthStorageForm.quoteFormData.conditionWaitingPeriod = this.addons.get(this.coverDesc.SprcificIllnessReduction) && this.addons.get(this.coverDesc.SprcificIllnessReduction).value ? this.addons.get(this.fetchAddonControlNames(this.coverId.SpecificIllnessReduction)).value : '';
    this.healthStorageForm.quoteFormData.maternityForAdult = this.addons.get(this.coverDesc.MaternityForAdultValue) && ((this.addons.controls[this.coverDesc.MaternityForAdultValue].value).lengh > 0) ? this.addons.controls[this.coverDesc.MaternityForAdultValue].value : [];
    this.healthStorageForm.quoteFormData.criticalIllnessForAdult = this.addons.get(this.coverDesc.criticalIllnessForAdult) && ((this.addons.controls[this.coverDesc.criticalIllnessForAdult].value).length > 0) ? (this.addons.controls[this.coverDesc.criticalIllnessForAdult].value) : [];
    this.healthStorageForm.quoteFormData.criticalAdultData = this.criticalAdultData;
    this.healthStorageForm.quoteFormData.maternityAdultData = this.maternityAdultData;
    this.healthStorageForm.quoteFormData.proposerDobCi = this.activatedAddonsGroup.get(this.coverDesc.proposerDobCi) ? this.activatedAddonsGroup.controls[this.coverDesc.proposerDobCi].value : '';
    this.healthStorageForm.quoteFormData.annualAdultData = this.annualAdultData;
    // Addons config storage ends

    this.healthStorageForm.quoteFormData.healthProposalProductType = healthProposalProductCode.elevate; // need to change
    this.healthStorageForm.quoteFormData.productCode = parseInt(this.elevateMasterData.ProductCode);
    this.healthStorageForm.quoteFormData.isBefitApplicable = this.stateDetails.isBefitApplicable;
    this.healthStorageForm.quoteFormData.selfMemberExists = this.selfMemberExists;
    this.healthStorageForm.quoteFormData.zone = this.elevateControls.controls['zone'].value;
    this.healthStorageForm.policyDetails.DealId = this.dealId;
    this.healthStorageForm.quoteFormData.isCibilApplicable = this.elevateControls.get('cibilDiscount').value;
    this.healthStorageForm.quoteFormData.isNRIApplicable = this.elevateControls.get('NRIToggle').value;
    // this.healthStorageForm.quoteFormData.isNRIDiscountApplicable = this.elevateControls.get('NRIDiscount').value;
    this.healthStorageForm.quoteFormData.proposerName = this.elevateControls.get('cibilDiscount').value == healthToggle.Yes ? this.elevateControls.get('proposerName').value : '';
    this.healthStorageForm.quoteFormData.mobileNo = this.elevateControls.get('cibilDiscount').value == healthToggle.Yes ? this.elevateControls.get('mobileNo').value : '';
    this.healthStorageForm.quoteFormData.DOB = this.elevateControls.get('cibilDiscount').value == healthToggle.Yes ? this.elevateControls.get('DOB').value : '';
    this.healthStorageForm.quoteFormData.pinCode = this.elevateControls.get('cibilDiscount').value == healthToggle.Yes ? this.elevateControls.get('pincode').value : '';
    this.healthStorageForm.quoteDataSet = true;
    if (this.elevateControls.controls['EMI'].value == healthToggle.Yes) {
      this.healthStorageForm.quoteFormData.InstallmentApplicable = healthToggle.Yes;
      this.healthStorageForm.quoteFormData.InstallmentFrequency = this.elevateControls.controls['EMIInstallment'].value;
    }
    else {
      this.healthStorageForm.quoteFormData.InstallmentApplicable = healthToggle.No;
    }

    this.healthStorageForm.quoteFormData.zone = this.zoneMappedWithPincode;
    this.healthStorageForm.quoteFormData.addonsData = this.getMemberRequestAddons(true, false);
    this.healthStorageForm.quoteFormData.adultRelationShips = this.adultRelationShips;
    this.healthStorageForm.quoteFormData.childRelationShips = this.childRelationShips;
    this.healthStorageForm.quoteFormData.subProductType = this.elevateMasterData.SubProductType;
    this.healthStorageForm.quoteFormData.subProductCode = this.elevateMasterData.SubProductCode;
    this.healthStorageForm.quoteFormData.zoneUpgrade = this.elevateControls.controls['zoneUpgrade'].value == "Yes" ? true : false;
    this.healthStorageForm.quoteFormData.reviseZone = this.elevateControls.controls['zoneUpgrade'].value == "Yes" ? this.elevateControls.controls['reviseZone'].value : this.zoneMappedWithPincode;
    this.healthStorageForm.quoteFormData.cibilScoreDiscount = this.cibilScore;
    this.healthStorageForm.quoteFormData.creditScore = this.creditScore;
    this.healthStorageForm.quoteFormData.emiPremium = this.emiPremium;
    this.healthStorageForm.quoteFormData.netPremium = this.netPremium;
    this.healthStorageForm.quoteFormData.taxAmount = this.taxAmount;
    this.healthStorageForm.quoteDataSet = true;
    this.healthStorageForm.quoteFormData.subProductCode = this.elevateMasterData.SubProductCode;

    // Addons storage
    this.healthStorageForm.quoteFormData.uniqueAddonsGroups = this.uniqueGroups;
    this.healthStorageForm.quoteFormData.elevateAddons = this.elevateAddons;
    this.healthStorageForm.quoteFormData.elevateAddonsCopy = this.elevateAddonsCopy;
    this.healthStorageForm.quoteFormData.elevateAddonsFormValue = this.addons.value;
    this.healthStorageForm.quoteFormData.occupation = this.occupation.value;
    this.healthStorageForm.quoteFormData.addonList = this.addonsReceivedFromService;
    this.healthStorageForm.quoteFormData.selectedAddons = this.selectedAddonList;
    // More Addons Storage
    this.healthStorageForm.quoteFormData.elevateAllAddonsCopy = this.elevateAllAddonsCopy;
    // this.healthStorageForm.quoteFormData.showMoreAddonsDatepicker = this.showMoreCriticaldatePicker;
    // this.healthStorageForm.quoteFormData.showMoreAddonsOcc = this.showMoreAddonsOccupation;
    // this.healthStorageForm.quoteFormData.moreAddons = this.elevateAllAddons;
    // this.healthStorageForm.quoteFormData.moreAddonsFormValue = this.moreAddons.value;
    // this.healthStorageForm.quoteFormData.moreAddonsOccupation = this.moreAddonsOccupation.value;
    // this.healthStorageForm.quoteFormData.activeMoreAddonsCount = this.moreAddonActivatedCount;
    // this.healthStorageForm.quoteFormData.recommendedJourneySelected = !this.hideBUIService;
    // this.healthStorageForm.quoteFormData.baseCoverSelected = this.baseCoverSelected;
    this.constructPremiumBasedData(premiumData);
    // View full summary
    this.constructViewFullSummary();
    this.healthStorageForm.quoteFormData.elevateGroupAddons = this.summaryAddons;
    this.healthStorageForm.quoteFormData.hapRefillingSummary = this.createMostlyGroupAddons();

    //reset data for quickquote
    this.healthStorageForm.quickquoteData.pincode = '';
    this.healthStorageForm.quickquoteData.cityState = '';
    this.healthStorageForm.quickquoteData.adultDetails = [];
    this.healthStorageForm.quickquoteData.childDetails = [];
    this.healthStorageForm.quickquoteData.policyTenure = '';
    this.healthStorageForm.quickquoteData.applicantAnnualSum = '';

    // If emi is true reset insured details
    let emiFlag: boolean = false;
    if (this.elevateControls.controls['EMI'].value == healthToggle.Yes) {
      if (this.healthStorageForm.insuredDataSet) {
        emiFlag = true;
      }
    }
    if (resetFlag || emiFlag) {
      this.setOtherFormFlags();
    }

    // this.constructPremiumData(premiumData);
    this.healthDataStorageService.setHealthDataStorage(this.healthStorageForm);
    // this.elevatequotepdf();

  }

  constructPremiumBasedData(premiumDataResponse: ComboQuoteResponsePayload) {
    if (premiumDataResponse.elevateBoosterResponses.length > 0) {
      let totalPremium: number = 0;
      let basicPremium: number = 0;
      let totalTax: number = 0;
      premiumDataResponse.elevateBoosterResponses.forEach((premiumData, i) => {
        // Combo Proposal
        if ((premiumData.ProductType).toLowerCase().includes('activate')) {
          this.healthStorageForm.quoteFormData.topUpPlanCode = premiumData.PlanCode;
          this.healthStorageForm.quoteFormData.topUpPlanName = premiumData.PlanName;
          this.healthStorageForm.policyDetails.topUpQuoteId = premiumData.QuoteId;
        }
        // Elevate Proposal
        else {
          this.healthStorageForm.quoteFormData.PlanCode = premiumData.PlanCode;
          this.healthStorageForm.quoteFormData.PlanName = premiumData.PlanName;
          this.healthStorageForm.policyDetails.QuoteId = premiumData.QuoteId;
        }

        // Individual combo premium will be added in case of supersaver
        if (!this.activateBoosterTopup) {
          if (i == 0) {
            this.healthStorageForm.premiumFormData.comboBasicPremium1 = parseFloat(premiumData.BasicPremium);
            this.healthStorageForm.premiumFormData.comboTotalPremium1 = parseFloat(premiumData.TotalPremium);
            this.healthStorageForm.premiumFormData.comboTotalTax1 = parseFloat(premiumData.TotalTax);
          }
          else {
            this.healthStorageForm.premiumFormData.comboBasicPremium2 = parseFloat(premiumData.BasicPremium);
            this.healthStorageForm.premiumFormData.comboTotalPremium2 = parseFloat(premiumData.TotalPremium);
            this.healthStorageForm.premiumFormData.comboTotalTax2 = parseFloat(premiumData.TotalTax);
          }
        }

        totalPremium = parseFloat(premiumData.TotalPremium) + totalPremium;
        basicPremium = parseFloat(premiumData.BasicPremium) + basicPremium;
        totalTax = parseFloat(premiumData.TotalTax) + totalTax;
      })
      // Common Tags
      this.healthStorageForm.premiumFormData.totalPremium = (totalPremium).toString();
      this.healthStorageForm.premiumFormData.basicPremium = basicPremium.toString();
      this.healthStorageForm.premiumFormData.totalTax = totalTax.toString();
      this.healthStorageForm.premiumFormData.tenure = this.tenureDuration;
      this.healthStorageForm.premiumFormData.QuoteId = premiumDataResponse.elevateBoosterResponses[0].QuoteId;
      this.healthStorageForm.premiumFormData.SubProductCode = (this.elevateMasterData.SubProductType).toString();//change in sub product code
      this.healthStorageForm.premiumFormData.PortabilitySubProductCode = this.elevateMasterData.PortabilitySubProductCode;
      this.healthStorageForm.premiumFormData.PfQuoteId = null;
      this.healthStorageForm.premiumFormData.InstallmentNetPremium = '';
      this.healthStorageForm.premiumFormData.InstallmentTaxAmount = '';
      this.healthStorageForm.premiumFormData.InstallmentTotalPremium = 0;
      this.healthStorageForm.premiumFormData.zone = this.utilityService.isEmptyOrNull(premiumDataResponse.elevateBoosterResponses[0].UpgradedZone) ? '' : premiumDataResponse.elevateBoosterResponses[0].UpgradedZone;
      this.healthStorageForm.premiumDataSet = true;
    }

  }

  elevatequotepdf() {
    this.quotePdfRequest.NRIApplicable = this.healthStorageForm.quoteFormData.isNRIApplicable;
    this.quotePdfRequest.GuaranteedSuperBonus = this.healthStorageForm.quoteFormData.GuaranteedSuperBonus;
    this.quotePdfRequest.InfiniteCare = this.healthStorageForm.quoteFormData.InfiniteCover;
    this.quotePdfRequest.NetworkAdvantageApplicable = this.healthStorageForm.quoteFormData.NetworkAdvantageApplicable;
    this.quotePdfRequest.JumpStart = this.healthStorageForm.quoteFormData.JumpStart;
    this.quotePdfRequest.RoomModifier = this.healthStorageForm.quoteFormData.RoomModifier;

    delete this.quotePdfRequest.NRIDiscount;
    this.storageService.setAESEncryptedData(userEnums.QuotePDFData, this.quotePdfRequest);

  }


  constructViewFullSummary() {
    this.createMemberList();
    this.createSummaryAddonList();
    // Removing Befit if not selected
    this.summaryAddons = this.summaryAddons.filter(item => {
      if ((!item['isAllInsuredSelected'] && !item['coverText'].includes('BeFit')) || item['isAllInsuredSelected']) {
        return item;
      }
    })
  }

  createMemberList() {
    if (this.adultDetails.length > 0) {
      for (let i = 1; i <= this.adultDetails.length; i++) {
        this.memberData.push(`Adult ${i}`);
      }
    }
    if (this.childDetails.length > 0) {
      for (let i = 1; i <= this.childDetails.length; i++) {
        this.memberData.push(`Child ${i}`);
      }
    }
  }

  createSummaryAddonList() {
    // Below will get all the addons list
    let nonShowCaseAddons = [this.coverDesc.VoluntaryDeductible, this.coverDesc.WorldWideCover, this.coverDesc.WorldWideWaitingPeriod, this.coverDesc.SprcificIllnessReduction, this.coverDesc.PEDReductionRider, this.coverDesc.SumInsured];
    this.summaryAddons = this.addonsReceivedFromService
      .filter(items => !nonShowCaseAddons.includes(items.CoverText))
      .map(items => ({
        'coverText': items.CoverText,
        'members': [],
        'isAllInsuredSelected': false,
        'group': items.groupName,
        'coverId': items.CoverId
      }));

    let memberBasedAddons = [this.coverId.Maternity, this.coverId.NewBorn, this.coverId.VaccinationNewBorn, this.coverId.AnnualHealthCheckups, this.coverId.PersonalAccident, this.coverId.CriticalIllness];

    const addonsData = this.healthStorageForm.quoteFormData.addonsData;
    addonsData.forEach((memberAddons, index) => {
      for (let member of memberAddons['AddonCoverDetails']) {
        for (let addons of this.summaryAddons) {
          if (addons['coverText'] == member['CoverText']) {
            // Applicable for all members
            if (member['IsForAdult'] && member['IsForChild'] && !memberBasedAddons.includes(member['CoverText']))
              addons['isAllInsuredSelected'] = true;
            // Member based addons
            else {
              addons['members'].push(`${index + 1}`);
            }
          }
          // Waiting period addons which was expected to be sent outside
          else if ((this.rootNonDropdownAddons.includes(addons['coverId']) && this.activatedAddonsGroup.get(addons['coverText']).value)) {
            //  Maternity Waiting period is applicable only for adult
            if (addons['coverId'] == this.coverId.MaternityWaiting && this.activatedAddonsGroup.get(this.coverDesc.MaternityForAdultValue).value.includes(memberAddons['MemberType'] + ' ' + (index + 1))) {
              addons['members'].push(`${index + 1}`);
            }
            else if (addons['coverId'] != this.coverId.MaternityWaiting) {
              addons['isAllInsuredSelected'] = true;
            }
          }
        }
      }
    })
  }

  createMostlyGroupAddons() {
    if (this.hideBUIService) {
      let mostlyBoughtGroup = this.summaryAddons.filter(data => data.group == this.addonGroupDesc.MostlyBought);
      return { 'memberArray': this.memberData, 'summaryAddons': mostlyBoughtGroup };
    }
    else {
      return { 'memberArray': this.memberData, 'summaryAddons': this.summaryAddons };
    }
  }

  checkMemberDetails(): boolean {
    let nonEqualMembers = [];
    let resetFlag: boolean = false;
    // Adult Details
    // If new adult details and child details length are same then we need to check the info
    if (this.adultDetails.length == this.healthStorageForm.quoteFormData.adultDetails.length) {
      let existingAdultDetails = this.healthStorageForm.quoteFormData.adultDetails;
      existingAdultDetails.forEach((adultData, index) => {
        let tempAdultDetails = adultData;
        // When there is self detected there is change in format of dob.
        if (this.healthStorageForm.kycStatus.ovdSelf || this.healthStorageForm.kycStatus.normalSelf) {
          // convert dob in respected format
          for (let keys in tempAdultDetails) {
            // There are chances date is patched along with orignal time stamp
            if (keys.includes('dob') && !tempAdultDetails[keys].includes('T')) {
              tempAdultDetails[keys] = (new Date(tempAdultDetails[keys]));
            }
          }
        }
        // Looping through keys

        if (!this.checkMemberData(tempAdultDetails, this.adultDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }
    // If the length is only not equal that means adult data is been modified
    else if (this.adultDetails.length != this.healthStorageForm.quoteFormData.adultDetails.length) {
      nonEqualMembers.push(0);
    }

    // Child Details

    if (this.childDetails.length == this.healthStorageForm.quoteFormData.childDetails.length) {
      let existingChildDetails = this.healthStorageForm.quoteFormData.childDetails;
      existingChildDetails.forEach((childData, index) => {
        // Looping through keys

        if (!this.checkMemberData(childData, this.childDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }

    // If the length is only not equal that means adult data is been modified
    else if (this.childDetails.length != this.healthStorageForm.quoteFormData.childDetails.length) {
      nonEqualMembers.push(0);
    }

    else {
      // Adult members found to be equal
      nonEqualMembers = [];
    }

    // If there is mismatch of data in terms of length or details
    if (nonEqualMembers.length > 0) {
      resetFlag = true;
    }
    else {
      resetFlag = false;
    }
    return resetFlag;
  }

  checkMemberData(obj1: Object, obj2: Object): boolean {
    if (this.utilityService.isEmptyOrNull(obj1) || this.utilityService.isEmptyOrNull(obj2)) {
      return false
    }
    else {
      let obj1Keys = Object.keys(obj1);

      for (let keysData of obj1Keys) {
        if (obj1[keysData] != obj2[keysData]) {
          return false;
        }
      }
    }
    return true;
  }

  criticalIllnessAddons() {
    if (this.healthStorageForm.insuredDataSet) {
      // Check whether critical Illness is selected or not
      if (this.addons.get(this.coverDesc.Critical) && this.addons.controls[this.coverDesc.Critical].value) {
        // Find out the adult for which it has selected
        let criticalAdultValue = this.addons.controls[this.coverDesc.criticalIllnessForAdult].value;
        if (criticalAdultValue.length > 0) {
          this.healthStorageForm.insuredFormData.insuredMemberDetails.forEach((insuredMembers, index) => {
            for (let keys in insuredMembers) {
              if (keys.includes(insuredControls.preExistinDisease)) {
                let tempPedKey = keys.split(insuredControls.preExistinDisease)[1];
                let memberType = tempPedKey.includes(healthMembers.Adult) ? healthMembers.Adult : healthMembers.Child;
                let memberKey = tempPedKey.split(memberType);
                let newKey = memberType + ' ' + memberKey[1];
                if (criticalAdultValue.includes(newKey)) {
                  // Set no of ped control
                  insuredMembers[keys] = healthToggle.No;
                  let ped = this.healthStorageForm.insuredFormData.preExistingDisease[index];
                  // If ped was selected then make it as no
                  for (const keys in ped) {
                    if (ped.hasOwnProperty(keys)) {
                      ped[keys] = false;
                    }
                  }
                  this.healthStorageForm.insuredFormData.preExistingDisease[index] = ped;
                }
              }
            }
          })
        }
      }
    }
  }

  setOtherFormFlags() {
    this.healthStorageForm.insuredDataSet = false;
    this.healthStorageForm.kycDataSet = false;
    this.healthStorageForm.kycStatus.ekycMandatory = false;
    this.healthStorageForm.kycStatus.normal = false;
    this.healthStorageForm.kycStatus.normalSelf = false;
    this.healthStorageForm.kycStatus.ovd = false;
    this.healthStorageForm.kycStatus.ovdSelf = false;
    this.healthStorageForm.kycStatus.isKYCDoneSuccessfully = false;
    this.healthStorageForm.kycStatus.idType = false;
    this.healthStorageForm.kycStatus.certNumber = '';
    this.healthStorageForm.kycStatus.isPilotUser = false;
    this.healthStorageForm.kycFormData = this.healthDataStorageService.resetKYCdata();
  }

  // set value for premium
  constructPremiumData(response: SaveQuoteResponseStructure) {
    this.healthStorageForm.premiumFormData.totalPremium = response.TotalPremium;
    this.healthStorageForm.premiumFormData.basicPremium = response.BasicPremium;
    this.healthStorageForm.premiumFormData.totalTax = response.TotalTax;
    this.healthStorageForm.premiumFormData.tenure = this.tenureDuration;
    this.healthStorageForm.premiumFormData.QuoteId = response.QuoteId;
    this.healthStorageForm.premiumFormData.SubProductCode = (this.elevateMasterData.SubProductType).toString();//change in sub product code
    this.healthStorageForm.premiumFormData.PortabilitySubProductCode = this.elevateMasterData.PortabilitySubProductCode;
    this.healthStorageForm.premiumFormData.PfQuoteId = response.PFQuoteID;
    this.healthStorageForm.premiumFormData.InstallmentNetPremium = response.InstallmentNetPremium;
    this.healthStorageForm.premiumFormData.InstallmentTaxAmount = response.InstallmentTaxAmount;
    this.healthStorageForm.premiumFormData.InstallmentTotalPremium = parseInt(response.InstallmentTotalPremium);
    this.healthStorageForm.premiumFormData.zone = this.utilityService.isEmptyOrNull(response.UpgradedZone) ? '' : response.UpgradedZone;
    this.healthStorageForm.premiumDataSet = true;
  }

  constructTopUpAndComboRequest(): TopupAndComboQuoteRequest {
    let copayValue = '';
    let tenure = (this.elevateControls.value.policyTenure).split(' ')[0];
    this.tenureDuration = parseInt(tenure);
    // if (!this.utilityService.isEmptyOrNull(this.activatedAddonsGroup.get(this.fetchAddonControlNames(this.coverId.CoPayment)))) {
    //   this.activatedAddonsGroup.get(this.fetchAddonControlNames(this.coverId.CoPayment)).value;
    // }

    let proposerDOB = '';
    if (!this.utilityService.isEmptyOrNull(this.activatedAddonsGroup.get(this.coverDesc.Critical)) && this.activatedAddonsGroup.controls[this.coverDesc.Critical].value) {
      let dob = this.activatedAddonsGroup.controls[this.coverDesc.proposerDobCi].value;
      // proposerDOB = this.datePipe.transform(dob, 'yyyy-MM-dd') + ' T' +this.datePipe.transform(dob, 'HH:mm:ss');
      proposerDOB = !this.utilityService.isEmptyOrNull(dob) ? this.utilityService.formatDate(dob) : undefined;
    }
    // let applicantAnnualSum = '';
    // if (this.activateBoosterTopup) {
    //   applicantAnnualSum = this.elevateControls.controls['applicantAnnualSum'].value;
    // }
    // else {
    //   let topUpSI = this.superSaverComboSI.length == 1 ? this.superSaverComboSI[0].value : this.elevateControls.get('applicantAnnualSum').value;
    //   applicantAnnualSum = (topUpSI).replace(/L/g, '');
    // }
    let requestData: TopupAndComboQuoteRequest = {
      "QuoteID": '',
      "ProductType": this.elevateControls.value.productType == 'New' ? 'N' : 'R',
      "UserType": this.utilityService.isEmptyOrNull(this.channelData) ? 'Agent' : this.channelData.UT,
      "NoOfAdults": (this.adultDetails.length).toString(),
      "NoOfKids": (this.childDetails.length).toString(),
      "SubProductType": this.elevateMasterData.SubProductType,
      "Tenure": this.tenureDuration,
      "SumInsured": this.elevateControls.get('applicantAnnualSum').value,
      "GSTStateName": this.stateDetails.stateName ? this.stateDetails.stateName : '',
      "Members": this.getMemberRequestAddons(false, false),
      // "SubProductCode": this.elevateMasterData.SubProductCode,
      "IpAddress": this.utilityService.isEmptyOrNull(this.channelData) ? this.superSaverDataModal.ipAddress : this.channelData.CIP,
      "City": this.stateDetails.cityName,
      "Pincode": (this.elevateControls.value.pincode).toString(),
      "PreHospitalization": '',
      "PostHospitalization": '',
      "ProposalAnnualIncome": this.elevateControls.controls['applicantAnnualIncome'].value,
      "PEDWaitingPeriod": '',//need to pass value
      // "SpecificConditionWaiting": '',//need to pass value
      "ZoneUpgrade": this.elevateControls.controls['zoneUpgrade'].value == "Yes" ? true : false,//Initially it goes false
      "Zone": this.elevateControls.controls['zone'].value,
      "RevisedZone": this.elevateControls.controls['zoneUpgrade'].value == "Yes" ? this.elevateControls.controls['reviseZone'].value : this.elevateControls.controls['zone'].value,
      "VoluntaryCopaymentPercentage": this.utilityService.isEmptyOrNull(copayValue) || copayValue == healthToggle.No ? null : copayValue.replace('%', ''),
      "InstallmentApplicable": this.elevateControls.controls['EMI'].value,
      "InstallmentFrequency": this.elevateControls.controls['EMI'].value == healthToggle.Yes ? this.elevateControls.controls['EMIInstallment'].value : "",
      "CibilScorePercentage": this.cibilScore,
      "ProposerDOB": proposerDOB,
      // "PEDWaitingperiodReduction": "1 Years",
      // "RoomModifier": "No",
      // "JumpStart": "No",
      "NRIDiscount": this.elevateControls.controls['NRIToggle'].value == healthToggle.Yes ? true : false,
      // "WorldwideWaitingperiodReduction": "1 Years",
      // "MaternityWaitingPeriodReduction": "1 Years",
      "PlanType": this.activateBoosterTopup ? (this.elevateControls.controls['planType'].value).split(' ')[1] : 'A',
      "PolicySubType": this.elevateMasterData.SubProductType,
      "VoluntaryDeductible": this.activateBoosterTopup ? parseInt(this.elevateControls.controls['voluntaryDeductible'].value) : 0,
    }
    let waitingPeriodAddons = this.bindWaitingPeriodAddons();
    Object.assign(requestData, waitingPeriodAddons);
    return requestData;
  }


  getMemberRequestAddons(rootAddons: boolean, BUIReq) {
    let members = [];
    if (this.adultDetails.length > 0) {
      this.adultDetails.value.forEach((element, i) => {
        this.adultDetails.value[i]['Gender' + `${i + 1}`] = this.utilityService.isEmptyOrNull(element['Gender' + (i + 1)]) ? this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips) : element['Gender' + (i + 1)];
        let addonsData: any = [];
        addonsData = BUIReq ? [] : this.constructMemberBasedAddons(i, healthMembers.Adult, rootAddons);
        let memberObj = {
          DOB: this.utilityService.formatDate(element['dobAdult' + (i + 1)]),
          Gender: this.utilityService.patchGender(this.adultDetails.value[i]['Gender' + `${i + 1}`]),
          MemberType: healthMembers.Adult,
          RelationshipwithApplicant: element['adultRealtionship' + (i + 1)],
          VaccinationDate: this.utilityService.formatDate(element['adultVaccinationDate' + (i + 1)]),
          AddonCoverDetails: addonsData.memberAddon.length > 0 ? addonsData.memberAddon : [],
          Occupation: !this.utilityService.isEmptyOrNull(addonsData.occupationData) ? addonsData.occupationData : ''
        }
        members.push(memberObj);
      });
    }
    if (this.childDetails.length > 0) {

      this.childDetails.value.forEach((element, j) => {
        this.childDetails.value[j]['Gender' + `${j + 1}`] = this.utilityService.isEmptyOrNull(element['Gender' + (j + 1)]) ? this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips) : element['Gender' + (j + 1)];
        let addonsData = this.constructMemberBasedAddons(j, healthMembers.Child, rootAddons);
        let memberObj = {
          DOB: this.utilityService.formatDate(element['dobChild' + (j + 1)]),
          Gender: this.utilityService.patchGender(this.childDetails.value[j]['Gender' + `${j + 1}`]),
          MemberType: healthMembers.Child,
          RelationshipwithApplicant: element['childRelationship' + (j + 1)],
          AddonCoverDetails: addonsData.memberAddon.length > 0 ? addonsData.memberAddon : [],
          Occupation: !this.utilityService.isEmptyOrNull(addonsData.occupationData) ? addonsData.occupationData : ''
        }
        members.push(memberObj);
      })
    }
    return members;
  }

  bindWaitingPeriodAddons() {
    let waitingPeriodAddons = {};
    this.addonsReceivedFromService.forEach(serviceAddons => {
      if ((this.rootNonDropdownAddons.includes(serviceAddons.CoverId)) && this.activatedAddonsGroup.get(serviceAddons.CoverText).value) {
        let controlName = this.fetchAddonControlNames(serviceAddons.CoverId);
        switch (serviceAddons.CoverId) {
          case this.coverId.PEDReduction: {
            waitingPeriodAddons['PEDWaitingperiodReduction'] = this.activatedAddonsGroup.get(controlName).value + ' Years';
            break;
          }
          case this.coverId.RoomModifier: {
            waitingPeriodAddons['RoomModifier'] = this.activatedAddonsGroup.get(controlName).value;
            break;
          }

          case this.coverId.WorldWideReduction: {
            waitingPeriodAddons['WorldwideWaitingperiodReduction'] = this.activatedAddonsGroup.get(controlName).value + ' Years';
            break;
          }

          case this.coverId.MaternityWaiting: {
            waitingPeriodAddons['MaternityWaitingPeriodReduction'] = this.activatedAddonsGroup.get(controlName).value + ' Years';
            break;
          }

          // Specific illness waiting period 
          case this.coverId.SpecificIllnessReduction: {
            waitingPeriodAddons['SpecificConditionWaiting'] = this.activatedAddonsGroup.get(controlName).value + ' Years';
            break;
          }
          case this.coverId.JumpStart: {
            waitingPeriodAddons['JumpStart'] = healthToggle.Yes;
            break;
          }
          case this.coverId.InfiniteCover: {
            waitingPeriodAddons['InfiniteCare'] = healthToggle.Yes;
            break;
          }
          case this.coverId.NetworkAdvantage: {
            waitingPeriodAddons['NetworkAdvantageApplicable'] = healthToggle.Yes;
            break;
          }
          case this.coverId.PowerBooster: {
            waitingPeriodAddons['GuaranteedSuperBonus'] = healthToggle.Yes;
            break;
          }
          default:
            break;
        }
      }
    })
    return waitingPeriodAddons;
  }

  constructMemberBasedAddons(memberIndex: number, memberType: string, rootAddons: boolean) {
    // For member based addons we will get IsForAdult true and IsForChild false
    let memberBasedAddons = [this.coverId.Maternity, this.coverId.NewBorn, this.coverId.VaccinationNewBorn, this.coverId.AnnualHealthCheckups, this.coverId.PersonalAccident, this.coverId.CriticalIllness];
    let memberAddon = [];
    let nonMemberBasedAddons = [...memberBasedAddons, ...this.rootNonDropdownAddons];
    let occupationData: string = '';
    if (this.elevateAddons.length > 0) {
      this.elevateAddons.forEach((parentAddons, index) => {
        if (parentAddons.childAddons.length > 0) {
          parentAddons.childAddons.forEach((childAddons, index) => {
            let addonData = this.addonsReceivedFromService.find(serviceAddons => serviceAddons.CoverId == childAddons.coverId);
            //Member Based Addons
            // Below block should run for Annual Health, Maternity and Critical Illness
            if (childAddons.isForAdult && !childAddons.isForChild && this.addons.get(childAddons.label).value) {
              if (childAddons.coverId != this.coverId.PersonalAccident) {
                let memberData = memberType + ' ' + (memberIndex + 1);

                if (!(this.utilityService.isEmptyOrNull(childAddons.dropDownControlName)) && (this.activatedAddonsGroup.get(childAddons.dropDownControlName).value).includes(memberData)) {
                  memberAddon.push({ ...addonData, IsAddonSelected: true });
                }
                // For new born and vaccination if maternity is selected
                else if ((childAddons.coverId == this.coverId.NewBorn || childAddons.coverId == this.coverId.VaccinationNewBorn) && (this.activatedAddonsGroup.get(this.coverDesc.Maternity).value && this.activatedAddonsGroup.get(this.coverDesc.MaternityForAdultValue).value.includes(memberData))) {
                  memberAddon.push({ ...addonData, IsAddonSelected: true });
                }
              }
              // If it is personal accident
              else {
                let occupationMember = 'occupationFor' + memberType + (memberIndex + 1);
                for (let i = 0; i < this.getActivatedOccupation().length; i++) {
                  const group = this.getActivatedOccupation().at(i) as FormGroup;
                  const keys = Object.keys(group.controls);
                  const keyFound = keys.some(key => key.includes(occupationMember));
                  if (keyFound) {
                    occupationData = this.getActivatedOccupation().value[i][occupationMember]
                    if (!this.utilityService.isEmptyOrNull(occupationData)) {
                      memberAddon.push({ ...addonData, IsAddonSelected: true });
                    }
                    break;
                  }
                }
              }
            }

          })
        }
      })
    }
    // Policy Level Addons excluding Drop Down Value
    if (this.addonsReceivedFromService.length > 0) {
      this.addonsReceivedFromService.forEach(serviceAddons => {
        // Befit
        if (!nonMemberBasedAddons.includes(serviceAddons.CoverId)) {
          if (serviceAddons.CoverText.includes('BeFit') && !(this.utilityService.isEmptyOrNull(this.activatedAddonsGroup.get(this.coverDesc.Befit))) && this.activatedAddonsGroup.controls[this.coverDesc.Befit].value) {
            // Get the Befit Type
            let befitType = this.activatedAddonsGroup.controls[this.coverDesc.SelectedBefitData].value;
            if (!this.utilityService.isEmptyOrNull(befitType) && serviceAddons.CoverText == befitType) {
              memberAddon.push({ ...serviceAddons, IsAddonSelected: true });
            }
          }
          // Other than befit, member based addons and waiting period
          else if (!serviceAddons.CoverText.includes('BeFit') && !this.dropDownControlname.includes(serviceAddons.CoverId) && this.activatedAddonsGroup.get(serviceAddons.CoverText).value) {
            memberAddon.push({ ...serviceAddons, IsAddonSelected: true });

          }
        }
        // Only activated while sending data
        else if (this.rootNonDropdownAddons.includes(serviceAddons.CoverId) && this.activatedAddonsGroup.get(serviceAddons.CoverText).value && rootAddons) {
          //  explicit condition for maternity reduction
          let member = memberType + ' ' + (memberIndex + 1);
          if (serviceAddons.CoverText == this.coverDesc.MaternityReduction) {
            if (this.activatedAddonsGroup.get(this.coverDesc.MaternityForAdultValue).value.includes(member)) {
              memberAddon.push({ ...serviceAddons, IsAddonSelected: true });
            }
          }
          else {
            memberAddon.push({ ...serviceAddons, IsAddonSelected: true });
          }

        }
      })
    }
    return { memberAddon, occupationData };
  }

  //onRefresh
  onRefresh(status) {
    if (status) {
      this.onLoad();
    }
  }

  // cibil reset
  cibilChange() {
    this.cibilScore = 0;
    this.cibilSubmitted = false;
    if (this.elevateControls.get('cibilDiscount').value === healthToggle.Yes) {
      this.elevateControls.get('proposerName').setValidators([Validators.required]);
      this.elevateControls.get('proposerName').updateValueAndValidity();
      this.elevateControls.get('mobileNo').setValidators([Validators.required, Validators.minLength(10)]);
      this.elevateControls.get('mobileNo').updateValueAndValidity();
    }
    else {
      this.elevateControls.get('proposerName').clearValidators();
      this.elevateControls.get('proposerName').updateValueAndValidity();
      this.elevateControls.get('proposerName').setValue('');
      this.elevateControls.get('mobileNo').clearValidators();
      this.elevateControls.get('mobileNo').updateValueAndValidity();
      this.elevateControls.get('mobileNo').setValue('');
    }
  }

  //proposal input feild validation
  proposerNameInput(event: KeyboardEvent) {
    const regex = Constants.namePatternRegExp;
    if (event.key === 'Backspace' || regex.test(event.key)) {
      return;
    }
    else {
      event.preventDefault();
    }
  }

  //mobile feild validation
  mobNoInput(event: KeyboardEvent) {
    const regex = Constants.numberPatternRegExp;
    if (event.key === 'Backspace' || regex.test(event.key)) {
      return;
    } else {
      event.preventDefault();
    }
  }

  //getcibil discount api call
  onSubmit() {

    if (this.elevateControls.controls['proposerName'].valid && (this.elevateControls.controls['mobileNo'].valid)) {
      const cibilPayload: GetCibilScoreRequestPayload = {
        "orgDiscount": "0.1",
        "userName": this.elevateControls.get('proposerName').value,
        "userPincode": this.elevateControls.get('pincode').value,
        "userMobile": this.elevateControls.get('mobileNo').value,
      }
      this.agentService.getCibilScore(cibilPayload).subscribe({
        next: (response) => {
          if (response.StatusCode == Constants.statusCode_success) {
            this.cibilScore = response.Data.CibilScoreResponse.CibilScorePercentage
            this.creditScore = response.Data.CibilScoreResponse.CreditScore
            if (response.Data.CibilScoreResponse.CibilScorePercentage == '0.0') {
              this.elevateControls.get('cibilDiscount').setValue(healthToggle.No)
              this.elevateControls.get('proposerName').clearValidators();
              this.elevateControls.get('proposerName').updateValueAndValidity();
              this.elevateControls.get('proposerName').setValue('');
              this.elevateControls.get('mobileNo').clearValidators();
              this.elevateControls.get('mobileNo').updateValueAndValidity();
              this.elevateControls.get('mobileNo').setValue('');
              const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.cibilNotEligible);
              this.popupService.openGenericPopup(errorPopupData);
            }
            // else if (response.Data.CibilScoreResponse.CibilScorePercentage != '0.0') {

            //   let popupData: PopupModal = {
            //     popupType: popupType.generic,
            //     imgName: popupImgPath.successGif,
            //     header: popupHeader.success,
            //     description: popupDescriptionMsg.cibilEligible,
            //     buttonLabel: popupButton.ok,
            //     buttonAction: popupButtonAction.close
            //   }

            //   this.popupService.openGenericPopup(popupData);
            // }
          }
          else {
            this.elevateControls.get('cibilDiscount').setValue(healthToggle.No)
            this.elevateControls.get('proposerName').clearValidators();
            this.elevateControls.get('proposerName').updateValueAndValidity();
            this.elevateControls.get('proposerName').setValue('');
            this.elevateControls.get('mobileNo').clearValidators();
            this.elevateControls.get('mobileNo').updateValueAndValidity();
            this.elevateControls.get('mobileNo').setValue('');
          }
          this.cibilSubmitted = true;
        }
      })
    }
    else return;
  }

  //on change product type
  changeInProductType() {
    // Check if portability selected reset emi values
    if (this.elevateControls.value.productType != 'New') {
      this.emiValueReset();
      this.emiValueValidationUpdate();
      this.portabilityDataPresent(true);
    }
    else {
      this.portabilityDataPresent(false);
    }
    // Below block will only run dor activate booster as zone and vd is applicable only ther
    // if (this.activateBoosterTopup) {
    // this.zoneBasedSI(this.zoneMappedWithPincode);
    // this.changeInBoosterSumInsured()
    // }
  }

  changeInPlans() {
    let planData = this.elevateControls.controls['planType'].value;
    this.elevateMasterData.SubProductCode = this.boosterPlans.find(data => data.label == planData).value;
    //  If plan is changed then addons api needs to be called again

    if (!this.utilityService.isEmptyOrNull(this.zoneMappedWithPincode)) {
      this.elevateAddons = [];
      this.fetchElevateAddons();
    }

    // this.sumInsuredFilter();

    this.configureSaverBoosterCoverId(false);
  }

  sumInsuredFilter() {
    let policyRange = this.elevateControls.controls['policyRange'].value;
    // When plan A is selected remove unlimited SI
    if (this.elevateControls.controls['planType'].value == this.superSaverDataModal.planType[0]) {
      this.policyPlan[1].label = '60L-3Cr';
      // When plan A is selected remove unlimited SI  if selected
      if (!this.utilityService.isEmptyOrNull(this.elevateControls.controls['applicantAnnualSum'].value) && this.elevateControls.controls['applicantAnnualSum'].value == '9999999999') {
        this.elevateControls.controls['applicantAnnualSum'].setValue('');
        this.elevateControls.controls['voluntaryDeductible'].setValue('');
        this.voluntaryDeductible = [];
      }
      this.sumInsuredDetails = this.boosterSIData.filter(data => data.SumAmount != 9999999999.0 && data.PolicyRange == policyRange);
    }
    // Plan B has unmimited SI
    else {
      this.policyPlan[1].label = this.superSaverDataModal.policyRange[1];
      this.sumInsuredDetails = this.boosterSIData.filter(data => data.PolicyRange == policyRange);
    }
  }

  pedSelected() {
    // Jumpstart will be visible only in case of activate booster
    if (this.activateBoosterTopup) {
      let reducedWaitingPeriodGroup = this.findParentGroups(this.addonGroupDesc.ReducedWP);
      if (!this.utilityService.isEmptyOrNull(reducedWaitingPeriodGroup) && reducedWaitingPeriodGroup.childAddons.length > 0) {
        this.changeInAddonCover(reducedWaitingPeriodGroup, reducedWaitingPeriodGroup.childAddons[0], { checked: this.addons.get(this.coverDesc.ABCDRider).value })
      }
    }

  }

  configureSaverBoosterCoverId(dataSet: boolean) {
    // Assigning cover id's and cover desc dynamically
    let planData = '';
    if (this.activateBoosterTopup) {
      if (dataSet) {
        planData = this.healthStorageForm.quoteFormData.activateBoosterPlans;
      }
      else if (!this.utilityService.isEmptyOrNull(this.elevateControls.get('planType'))) {
        planData = this.elevateControls.controls['planType'].value;
      }
      this.coverId = this.activateBoosterTopup && planData == this.superSaverDataModal.planType[0] ? this.superSaverDataModal.activateBoosterCoverIdPlanA : this.superSaverDataModal.activateBoosterCoverIdPlanB;
    }
    else {
      this.coverId = this.superSaverDataModal.superSaverCoverId;
    }
    this.multipleMemberDropDown = [this.coverId.Maternity, this.coverId.CriticalIllness, this.coverId.AnnualHealthCheckups];
    this.reductionCoverId = [this.coverId.PEDReduction, this.coverId.MaternityWaiting, this.coverId.SpecificIllnessReduction, this.coverId.WorldWideReduction];
    this.dropDownControlname = [...this.reductionCoverId, this.coverId.CoPayment, this.coverId.VoluntaryDeductible, this.coverId.RoomModifier];
    this.rootNonDropdownAddons = [...this.dropDownControlname, this.coverId.JumpStart, this.coverId.PowerBooster, this.coverId.NetworkAdvantage, this.coverId.InfiniteCover];
  }

  portabilityDataPresent(data: boolean) {
    this.customStepperService.portabilityPresent(data);
  }

  hasError(controlName: string, errorCode: string): boolean {
    return !this.elevateControls.valid && this.elevateControls.hasError(errorCode, [controlName]);
  }

  hasAddonsError(controlName: string, errorCode: string): boolean {
    return !this.addons.valid && this.addons.hasError(errorCode, [controlName]);
  }

  stopPropogation(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private selectScrollListener: () => void
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  // reset NRI
  NRIReset() {
    if (this.elevateControls.controls['NRIToggle'].value == healthToggle.No) {
      this.elevateControls.controls['NRIDiscount'].patchValue(healthToggle.No);
    }
  }

  getDynamicClasses(index: number): Object {
    return {
      'mt-1 mb-2': index == 0,
      'mb-2 mt-2': index == 2
    };
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  toggleState: { [key: string]: boolean } = {};

  isSelectedPlan(plan: any): boolean {
    return this.selectedPlan && this.selectedPlan.CartType === plan.CartType;
  }

  onSelectPlan(data: {}): void {
    this.showMoreAddonsOccupation = false;
    this.showMoreCriticaldatePicker = false;
    let teleConsultationData = [];
    this.resetFooterAndStepper();
    this.selectedPlan = data;
    let selectedPlansCoverId: number[] = [];
    this.baseCoverSelected = this.selectedPlan.CartType == 'Basic';
    let concatMembers = [];
    //addon count
    let addonCount = 0;
    let dropDownAddonCount = 0;
    let builtInAddonCount = this.superSaverDataModal.buildInAddons.length;
    this.totalAddonCount = 0;


    if (!this.utilityService.isEmptyOrNull(this.selectedPlan.AddOnCoverDetails)) {
      addonCount = this.selectedPlan.AddOnCoverDetails.length;
      if (this.baseCoverSelected) {
        teleConsultationData = this.selectedPlan.AddOnCoverDetails.filter(data => data.CoverId == this.coverId.TeleConsultation);
      }
    }
    if (!this.utilityService.isEmptyOrNull(this.selectedPlan.DropdownAddonList)) {
      dropDownAddonCount = this.selectedPlan.DropdownAddonList.length;
    }
    this.totalAddonCount = addonCount + dropDownAddonCount + builtInAddonCount;
    //if portability then add base cover addons too coming form BUI sevcie api
    if (this.elevateControls.controls['productType'].value == 'Portability') {
      concatMembers = [...this.selectedPlan.AddOnCoverDetails || [],
      ...this.selectedPlan.DropdownAddonList || []];
    }
    //for new journey will remain same no addons for base cover
    else {
      concatMembers = !this.baseCoverSelected ? [...this.selectedPlan.AddOnCoverDetails || [],
      ...this.selectedPlan.DropdownAddonList || []] : this.elevateAllAddons.slice();
    }

    // Reset Form
    //for new journey condition will be remain same 
    if (this.elevateControls.controls['productType'].value == 'New') {
      if (!this.baseCoverSelected) {
        this.resetAddonForms(this.moreAddons, true);
      }
    }
    //for portability on change selected plan more addons should get reset
    else {
      this.resetAddonForms(this.moreAddons, true);
    }

    concatMembers.forEach(data => {
      this.changeInMoreAddons(data, { checked: data.IsAddonSelected }, false);

      //for new journey , base addon are null 
      if (this.elevateControls.controls['productType'].value == 'New') {
        if (data.IsAddonSelected && !this.baseCoverSelected) {
          selectedPlansCoverId.push(data.CoverId);
        }
      }
      //for portability we have addons in base cart 
      else {
        if (data.IsAddonSelected) {
          selectedPlansCoverId.push(data.CoverId);
        }
      }
    })

    // Check For concat members length . If null/empty then tele consultation should not pass
    if (this.baseCoverSelected || concatMembers.length == 0) {
      // If tele consulattion found then don't remove it or else remove it
      // If not found then hide only by default
      selectedPlansCoverId.push(this.coverId.TeleConsultation);
    }

    // Disabling selected cover id
    this.disableFields(selectedPlansCoverId, teleConsultationData);

    // Explicit check for critical illness when we switch plans if jump start was false/true
    // only when jump start is false

    // Also in some cases if maternity opted and when we switch plans new born vaccination was not hidden as maternity will be unselected
    this.elevateAllAddons.forEach((data, index) => {
      // Also check for maternity
      if (data.CoverId == this.coverId.Maternity || data.CoverId == this.coverId.JumpStart)
        this.changeInMoreAddons(this.elevateAllAddons[index], { checked: this.moreAddons.get(data.CoverText).value }, false);
    })

    this.calculateActiveMoreAddonCount();
  }


  disableFields(coverIds: number[], teleConsultationData: Array<{}>): void {
    const baseCovers = coverIds.length === 1;
    let befitCoverId = [117, 118, 119, 120, 121, 122, 123];
    let befitPresent = befitCoverId.some(value => coverIds.includes(value));
    // if it is selected in base covers then befit we are enforcing to be false
    //only if it is new journey
    if (this.elevateControls.controls['productType'].value == 'New' && this.baseCoverSelected) {
      this.moreAddons.get(this.coverDesc.Befit).setValue(false);
      this.moreAddons.get(this.coverDesc.SelectedBefitData).setValue('');
    }
    // Create a new array with modified properties
    const newElevateAllData = this.elevateAllAddons.map(parentData => {
      // For Tele consultation
      if (baseCovers && coverIds.includes(parentData.CoverId)) {
        if (teleConsultationData.length > 0) {
          this.moreAddons.get(this.coverDesc.TeleConsultation).setValue(teleConsultationData[0]['IsAddonSelected']);
          return {
            ...parentData,
            IsVisible: teleConsultationData[0]['IsVisible'],
            IsAddonSelected: teleConsultationData[0]['IsAddonSelected']
          };
        }
        else {
          this.moreAddons.get(this.coverDesc.TeleConsultation).setValue(false);
          return {
            ...parentData,
            IsVisible: false,
            IsAddonSelected: false
          };
        }

      }
      // Other than tele consulation covers and not base cover selected
      else {
        // Explicit check for befit
        if (parentData.CoverId == 0) {
          return {
            ...parentData,
            disabled: befitPresent
          };
        }
        // Unhide tele consulation 
        else if (parentData.CoverId == this.coverId.TeleConsultation) {
          this.moreAddons.get(parentData.CoverText).setValue(true);
          return {
            ...parentData,
            disabled: true,
            IsVisible: true,
            IsAddonSelected: true
          };

        }
        // For other controls
        else {
          return {
            ...parentData,
            disabled: coverIds.includes(parentData.CoverId)
          };
        }
      }
    });

    this.elevateAllAddons = [...newElevateAllData];
  }


  toggleMoreBenefits(plan: any): void {
    plan.showMoreBenefits = !plan.showMoreBenefits;
  }

  isMoreBenefitsVisible(plan: any): boolean {
    return !!plan.showMoreBenefits;
  }

  countSelectedAddons(plan: any): number {
    return plan.AddOnCoverDetails.filter((addon: any) => addon.IsAddonSelected).length;
  }

  resetForm(event) {
    // true means goes to diy adddons
    // false back to recommended
    this.hideBUIService = event;
    this.resetAddonView(event);
    this.changeInCover = false;
  }

  resetAddonView(event) {
    this.resetFooterAndStepper();
    // this.baseCoverSelected = false;
    // this.elevateAllAddons = [...this.elevateAllAddonsCopy];
    this.selectedAddonList = [];
    // this.elevateAddons = [...this.elevateAddonsCopy];
    if (event) {
      this.quoteButtonLabel = 'Get Quote';
      this.selectedPlan = [];
      this.resetAddonForms(this.moreAddons, true);
      this.showMoreAddonsOccupation = false;
      this.showMoreCriticaldatePicker = false;
      //addon from reset.
    } else {
      // this.selectedPlan = this.elevateBUIAddon.find(plan => plan.CartType === 'Essential');
      // if (!this.utilityService.isEmptyOrNull(this.selectedPlan)) {
      //   this.onSelectPlan(this.selectedPlan);
      // }
      // this.quoteButtonLabel = 'Proceed';
      this.resetAddonForms(this.addons, false);
      this.resetDependentFieldStatus();
      // Initially first tab is selected
      this.tabChanged(this.elevateAddons[0]);
      if (this.mobileViewActivated) {
        this.backButtonClicked();
      }
      //set more addon form 
      //this.selectedPlan --- recommended plan
    }
  }

  resetFooterAndStepper() {
    if (this.healthStorageForm.quoteDataSet && this.patchingDone) {
      this.showPremiumDetails(false);
      if (!this.calculator) {
        this.customStepperService.resetStepper(true);
      }
      this.quoteButtonLabel = 'Generate Quote';

    }
  }

  resetDependentFieldStatus() {
    this.elevateAddons.forEach(parentData => {
      if (parentData.category == this.addonGroupDesc.MostlyBought || parentData.category == this.addonGroupDesc.ReducedWP || parentData.category == this.addonGroupDesc.LumpSum || parentData.category == this.addonGroupDesc.MaternityCoverage) {
        parentData.childAddons.forEach(chilData => {
          if (chilData.coverId == this.coverId.Befit || chilData.coverId == this.coverId.RoomModifier || chilData.coverId == this.coverId.MaternityWaiting) {
            chilData.showDependentField = false;
          }

          else if (chilData.coverId == this.coverId.JumpStart) {
            parentData.hide = false;
          }

          else if (chilData.coverId == this.coverId.CriticalIllness || chilData.coverId == this.coverId.PersonalAccident) {
            parentData.showCriticalDatePicker = false;
            parentData.showOccupationFields = false;
            if (this.occupation.length == 0 && this.criticalAdultData.length == 0) {
              parentData.hide = true;
            }
            else {
              parentData.hide = false;
            }
          }
        })
      }
    })
  }

  resetAddonForms(addonForm: FormGroup, recommended: boolean) {
    let notResetControls = [this.coverId.CoPayment + 'Addon', this.coverId.RoomModifier + 'Addon', this.coverId.MaternityWaiting + 'Addon', this.coverDesc.TeleConsultation, this.coverDesc.NewBornBaby, this.coverDesc.VaccinationExpenses, this.coverDesc.SelectedBefitData, this.coverDesc.proposerDobCi]
    let memberControls = [this.coverDesc.MaternityForAdultValue, this.coverDesc.AnnualHealthForAdult, this.coverDesc.criticalIllnessForAdult];
    let formKeys = Object.keys(addonForm.controls);
    if (formKeys.length > 0) {
      formKeys.forEach(controlName => {
        // If not member based controls and default addons then reset
        if (controlName != 'occupation' && !notResetControls.includes(controlName) && !memberControls.includes(controlName)) {
          addonForm.get(controlName).setValue(false);
        }

        // member based addons
        else if (memberControls.includes(controlName)) {
          addonForm.get(controlName).setValue([]);
          addonForm.get(controlName).clearValidators();
          addonForm.get(controlName).updateValueAndValidity();
        }

        // Occupation
        else if (controlName == 'occupation') {
          addonForm.get(controlName).value.forEach((data, index) => {
            let occKeys = Object.keys(data);
            addonForm.get(controlName)['controls'].at(index)['controls'][occKeys[0]].setValue('');
            addonForm.get(controlName)['controls'].at(index)['controls'][occKeys[0]].clearValidators();
            addonForm.get(controlName)['controls'].at(index)['controls'][occKeys[0]].updateValueAndValidity();
          })
        }

        // Befit and proposer dob all validations to be removed
        else if (controlName == this.coverDesc.SelectedBefitData || controlName == this.coverDesc.proposerDobCi || controlName == this.fetchAddonControlNames(this.coverId.RoomModifier)) {
          addonForm.get(controlName).setValue('');
          addonForm.get(controlName).clearValidators();
          addonForm.get(controlName).updateValueAndValidity();
        }

        // Tele consultatiom
        else if (controlName == this.coverDesc.TeleConsultation) {
          addonForm.get(controlName).setValue(true);
        }
      })
    }
  }

  backButtonClicked() {
    this.backButtonNeeded = false;
    this.showCustomAddons = true;
    this.showSelectedAddons = false;
  }

  addonCountSelcted() {
    this.backButtonNeeded = true;
    this.showCustomAddons = false;
    this.showSelectedAddons = true;
  }

  scrollToElement(elementId: string): void {
    const control = this.el.nativeElement.querySelector('#' + elementId);
    if (!this.utilityService.isEmptyOrNull(control)) {
      control.scrollIntoView({ behavior: 'smooth', block: 'start' });
      control.focus();
    }
  }

  focusOnMatCard(): void {
    if (this.addonsMatCard && this.addonsMatCard.nativeElement) {
      this.addonsMatCard.nativeElement.focus();
    }
  }

  getCombinedAddOns(data: any): any[] {
    const addOnCoverDetails = Array.isArray(data.AddOnCoverDetails) ? data.AddOnCoverDetails : [];
    const dropDownOnCoverDetails = Array.isArray(data.DropdownAddonList) ? data.DropdownAddonList : [];
    return [...dropDownOnCoverDetails, ...addOnCoverDetails, ...this.superSaverDataModal.buildInAddons];
  }

  // Method to check if an add-on is selected for a specific CartType
  doesCoverTextExistForCartType(coverText: string, cartType: string): boolean {
    const plan = this.elevateBUIAddon.find((plan: any) => plan.CartType === cartType);
    if (!plan || !plan.AddOnCoverDetails) return false;

    // Check if coverText exists in the plan's AddOnCoverDetails
    const existsInPlan = plan.AddOnCoverDetails.some((detail: any) => detail.CoverText === coverText);

    const existsInDropPlan = plan.DropdownAddonList.some((detail: any) => detail.CoverText === coverText);

    return existsInPlan || existsInDropPlan;
  }

  getadultchilddataforquickquote() {
    this.adultValue = this.healthStorageForm.quickquoteData.adultDetails.length;
    this.childValue = this.healthStorageForm.quickquoteData.childDetails.length;
    if (this.healthStorageForm.quickquoteData.adultDetails.length > 0) {
      this.healthStorageForm.quickquoteData.adultDetails.forEach((data, index) => {
        this.adultDetails.push(this.utilityService.createAdultDetails(index));
        // Patch values of adults
        // Below block is written because when there is self data that get's patched from kyc is not in date format
        // So to handle it we need to convert in into date type
        for (let adultKeys in data) {
          if (adultKeys.includes('dob')) {
            // let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
            // this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
            let dobValue = typeof (data[adultKeys]) == 'string' ? new Date(data[adultKeys]) : data[adultKeys];
            this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
          }
          else {
            this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
          }
        }
      })
    }

    if (this.healthStorageForm.quickquoteData.childDetails.length > 0) {
      this.healthStorageForm.quickquoteData.childDetails.forEach((data, index) => {
        this.childDetails.push(this.utilityService.createChildDetails(index));
        this.childMinMaxDate.push({ minDate: new Date(), maxDate: new Date() });
        // Patch values of adults
        for (let childKeys in data) {
          this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);
          if (childKeys.includes('Relationship')) {
            // We need to set min andd max dateof child as per relationship
            this.setResetChildDOB(data[childKeys], index);
          }
        }
      })
    }
  }


  transformCoverString(input: string): string {
    const parts = input.split('+').map(part => part.trim());

    if (parts.length !== 2) {
      return '';
    }

    return `${parts[0]} Base cover + ${parts[1]} Top up Cover`;
  }



  ngOnDestroy() {
    this.unsubscribeSubscription();
  }

  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }
}
