import { Injectable } from '@angular/core';
import { Span, SpanStatusCode, context, trace } from '@opentelemetry/api';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { Resource } from '@opentelemetry/resources';
import { SimpleSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TracingService {
    collectorUrl = environment.jaegerURL;
    serviceName = environment.jaegerService;

    private currentTraceId: string = '';
    private rootSpan!: Span;
    private tracer = trace.getTracer('default');

    constructor() {
        this.setupOpenTelemetry();
        this.createRootSpan();
    }

    private setupOpenTelemetry() {
        const provider = new WebTracerProvider({
            idGenerator: {
                generateTraceId: () => this.currentTraceId,
                generateSpanId: () => this.generateRandomSpanId(),
            },
            resource: new Resource({
                [SemanticResourceAttributes.SERVICE_NAME]: this.serviceName,
            }),
        });

        const exporter = new OTLPTraceExporter({
            url: this.collectorUrl,
        });

        provider.addSpanProcessor(new SimpleSpanProcessor(exporter));
        provider.register();

        registerInstrumentations({
            instrumentations: [
                new FetchInstrumentation(),
                new XMLHttpRequestInstrumentation({
                    propagateTraceHeaderCorsUrls: [
                        /.+/, // Adjust this regex to match your API URLs to ensure headers are propagated correctly
                    ],
                    ignoreUrls: [
                        /api/, // Ensure the instrumentation does not create spans for the specific API requests
                    ],
                }),
            ],
        });
    }

    private createRootSpan() {
        this.rootSpan = this.tracer.startSpan('RootSpan', {
            attributes: { 'trace.id': this.currentTraceId },
        });
        context.with(trace.setSpan(context.active(), this.rootSpan), () => {
            // This ensures that any spans created within this callback will be children of rootSpan
            // For our purposes, we're just setting the active context and not doing any work here
        });
    }

    generateRandomId(byteLength: number): string {
        const bytes = new Uint8Array(byteLength);
        crypto.getRandomValues(bytes);
        const result = bytes.reduce(
            (acc, byte) => acc + byte.toString(16).padStart(2, '0'),
            ''
        );
        return result;
    }

    generateTraceIdStoreLS(key: string): string {
        this.currentTraceId = this.generateRandomId(16);
        localStorage.setItem(key, this.currentTraceId);
        return this.currentTraceId;
    }
    getTraceIdLS(key: string): string {
        const storedTraceId = localStorage.getItem(key);
        if (storedTraceId) {
            this.currentTraceId = storedTraceId;
        } else {
            this.currentTraceId = this.generateRandomId(16);
        }
        return this.currentTraceId;
    }
    generateTraceId(): string {
        this.currentTraceId = this.generateRandomId(16);
        return this.currentTraceId;
    }

    startSpan(name: string): Span {
        return this.tracer.startSpan(name, {
            attributes: { 'trace.id': this.currentTraceId },
        });
    }

    endSpan(span: Span, status?: SpanStatusCode, message?: string) {
        if (status) {
            span.setStatus({ code: status, message });
        }
        span.end();
    }

    // Call this when your application is about to close (e.g., in ngOnDestroy of your main component)
    // endRootSpan() {
    //   this.rootSpan.end();
    // }

    private generateRandomSpanId(): string {
        const bytes = new Uint8Array(8);
        crypto.getRandomValues(bytes);
        const result = bytes.reduce(
            (acc, byte) => acc + byte.toString(16).padStart(2, '0'),
            ''
        );
        return result;
    }
}
