<div class="scrollDiv bbrTable" *ngIf="dataSource.data.length > 0">
    <table class="tableSize" mat-table [dataSource]="dataSource" recycleRows [ngStyle]="{'min-width': tableSize}">
        <caption></caption>
        <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of displayedColumns" [ngSwitch]="true">
            <div *ngSwitchCase="column.columnDef == 'Select'">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <td mat-cell *matCellDef="let element" class="downloadActionImg">
                    <mat-checkbox
                        (change)="emitData({selected: $event.checked, rowData: element, id: element.id}, 'Add To Pay')"
                        [matTooltip]="column.addToPayTooltip" [checked]="element.isChecked"
                        *ngIf="evalConditionForMyPolicy(element, column.addToPayExp ?? true)"></mat-checkbox>
                    <span *ngIf="evalConditionForMyPolicy(element, column.recalExp ?? true)" class="imgPadding">
                        <img [matTooltip]="column.recalTooltip" src="{{column.imgPath}}" alt=""
                            (click)="emitData(element, column.recalTooltip)">
                    </span>
                </td>
            </div>
            <div *ngIf="['BundleProposalNo','BundlePolicyNo'].includes(column.columnDef)">
                <th mat-header-cell *matHeaderCellDef>{{column.header}}</th>
                <td mat-cell *matCellDef="let element">
                    <div class="proposalNumberColumn">
                        <span>
                            <span class="downloadSpan"
                                *ngIf="!utilityService.isEmptyOrNull(element[column.col1]) && !utilityService.isEmptyOrNull(element[column.col2])">
                                <img [matTooltip]="'Download'" [matTooltipPosition]="'left'"
                                    src="../../../../../../../assets/images/BBR/noBorderDownloadBtn.svg"
                                    alt="download" />
                            </span>
                            <span (click)="emitData(evalFunction(element, column.col1Expression), column.label)"
                                [matTooltip]="!utilityService.isEmptyOrNull(element[column.col1]) && !utilityService.isEmptyOrNull(element[column.col2]) ? column.tooltip1 ? column.tooltip1 : 'Download' : null"
                                [matTooltipPosition]="'left'"
                                [ngClass]="{'numberSpan': !utilityService.isEmptyOrNull(element[column.col1]) && !utilityService.isEmptyOrNull(element[column.col2])}">
                                {{!utilityService.isEmptyOrNull(element[column.col1]) &&
                                !utilityService.isEmptyOrNull(element[column.col2]) ? element[column.col1] : '-'}}
                            </span>
                            <!-- Copy Icon -->
                            <span class="copySpan"
                                *ngIf="!utilityService.isEmptyOrNull(element[column.col1]) && !utilityService.isEmptyOrNull(element[column.col2])"
                                (click)="copyText(element[column.col1], tooltip)">
                                <img [matTooltip]="isCopied ? 'Text copied successfully!' : 'Copy Text'"
                                    [matTooltipPosition]="'left'"
                                    src="../../../../../../../assets/images/BBR/copyText.svg" alt="copy"
                                    #tooltip="matTooltip" />
                            </span>
                        </span>
                        <span>
                            <span class="downloadSpan"
                                *ngIf="!utilityService.isEmptyOrNull(element[column.col2]) && !utilityService.isEmptyOrNull(element[column.col1])">
                                <img [matTooltip]="'Download'" [matTooltipPosition]="'left'"
                                    src="../../../../../../../assets/images/BBR/noBorderDownloadBtn.svg"
                                    alt="download" />
                            </span>
                            <span (click)="emitData(evalFunction(element, column.col2Expression), column.label)"
                                [matTooltip]="!utilityService.isEmptyOrNull(element[column.col2]) && !utilityService.isEmptyOrNull(element[column.col1]) ? column.tooltip2 ? column.tooltip2 : 'Download' : null"
                                [matTooltipPosition]="'left'"
                                [ngClass]="{'numberSpan': !utilityService.isEmptyOrNull(element[column.col2]) && !utilityService.isEmptyOrNull(element[column.col1])}">
                                {{!utilityService.isEmptyOrNull(element[column.col2]) &&
                                !utilityService.isEmptyOrNull(element[column.col1]) ? element[column.col2] : '-'}}
                            </span>
                            <!-- Copy Icon -->
                            <span class="copySpan"
                                *ngIf="!utilityService.isEmptyOrNull(element[column.col2]) && !utilityService.isEmptyOrNull(element[column.col1])"
                                (click)="copyText(element[column.col2], tooltip)">
                                <img [matTooltip]="isCopied ? 'Text copied successfully!' : 'Copy Text'"
                                    [matTooltipPosition]="'left'"
                                    src="../../../../../../../assets/images/BBR/copyText.svg" alt="copy"
                                    #tooltip="matTooltip" />
                            </span>
                        </span>
                    </div>
                </td>
            </div>
            <div
                *ngSwitchCase="['PFProposalNo','PFPolicyNo','ProposalNumber','PolicyNumber','policy_number','NewPolicyNo','PFPaymentID'].includes(column.columnDef)">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        <span class="downloadSpan"
                            *ngIf="evalConditionForMyPolicy(element, column.expression ?? true) && !utilityService.isEmptyOrNull(column.cell(element))">
                            <img [matTooltip]="evalConditionForMyPolicy(element, column.expression ?? true) && !utilityService.isEmptyOrNull(column.cell(element)) ? 'Download' : null"
                                [matTooltipPosition]="'left'"
                                src="../../../../../../../assets/images/BBR/noBorderDownloadBtn.svg" alt="download" />
                        </span>
                        <span (click)="emitData(element, column.label)"
                            [matTooltip]="evalConditionForMyPolicy(element, column.expression ?? true) && !utilityService.isEmptyOrNull(column.cell(element)) ? column.header : null"
                            [matTooltipPosition]="'left'"
                            [ngClass]="{'numberSpan': evalConditionForMyPolicy(element, column.expression ?? true) && !utilityService.isEmptyOrNull(column.cell(element))}">
                            {{utilityService.isEmptyOrNull(column.cell(element)) ? '-' : column.cell(element)}}
                        </span>

                        <!-- Copy Icon -->
                        <span class="copySpan" *ngIf="evalConditionForMyPolicy(element, column.expression ?? true) && !utilityService.isEmptyOrNull(column.cell(element))"
                            (click)="copyText(column.cell(element), tooltip)">
                            <img [matTooltip]="isCopied ? 'Text copied successfully!' : 'Copy Text'"
                                [matTooltipPosition]="'left'" src="../../../../../../../assets/images/BBR/copyText.svg"
                                alt="copy" #tooltip="matTooltip" />
                        </span>
                    </span>
                </td>
            </div>
            <div
                *ngSwitchCase="!['Action','Select','PFProposalNo','PFPolicyNo','ProposalNumber','PolicyNumber','policy_number','NewPolicyNo','PFPaymentID','BundleProposalNo','BundlePolicyNo'].includes(column.columnDef)">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <td mat-cell *matCellDef="let element"
                    [ngClass]="{'status': column.columnDef == 'Status' && column.cell(element) == 'Failed Payment'}">
                    {{
                    (column.columnDef != 'Amount' && column.columnDef != 'TotalPremium' &&
                    column.columnDef != 'ComboPremium' && column.columnDef != 'Premium')
                    ?
                    (utilityService.isEmptyOrNull(column.cell(element)) ||
                    (column.columnDef == 'HtRemark' && utilityService.isEmptyOrNull(column.cell(element))))
                    ? '-'
                    : column.columnDef == 'ProposalStatus'
                    ? addSpace(column.cell(element))
                    : column.cell(element)
                    : '₹' + utilityService.convertStringtoCurrency(column.cell(element))
                    }}
                </td>
            </div>
            <div *ngSwitchCase="column.columnDef == 'Action'">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element" class="downloadActionImg">
                    <div class="tableaction">
                        <span *ngFor="let btn of buttonData">
                            <span *ngIf="evalConditionForMyPolicy(element, btn.expression ?? true)"><img
                                    [matTooltip]="btn.tooltip" src="{{btn.imgPath}}" alt=""
                                    (click)="emitData(element, btn.label)"> &nbsp;
                            </span>
                        </span>
                    </div>
                </td>
            </div>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayCol"></tr>
        <tr mat-row *matRowDef="let row; columns: displayCol;"></tr>
    </table>
</div>


<div *ngIf="dataSource.data.length == 0" class="NoRecordFoundMainDiv">
    <mat-card class="NoRecordFound">
        <div class="NoRecordFoundData">
            <img src=" ../../../../../../../assets/images/BBR/NoRecordFound.svg" alt="No Record Found"
                class="noRecordImg mb-3">
            <h3 class="text-center mb-2 paraMessage"> No data to show</h3>
            <h4 class="text-center mb-0 messagePara"> Try adjusting your search or filter to find your report</h4>
        </div>
    </mat-card>
</div>
<div class="tablePag" *ngIf="dataSource.data.length != 0">
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChange($event)"></mat-paginator>
</div>