import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subscription, concatMap, forkJoin, of } from "rxjs";
import { HealthService } from "src/app/shared/proxy-services/health.service";
import { CompanyDetails, InsuredCompanyResponsePayload, PortabilityReasonsResponsePayload } from "src/app/shared/interface/health";
import { Constants } from "src/app/shared/constants/constants";
import { UtilityService } from "src/app/shared/services/utility.service";
import { HealthDataStorageService } from "../../health.service";
import { healthToggle, policyType, portabilityControls } from "src/app/shared/enums/healthEnums";
import { HealthDataStorage } from "../../health.modal";
import { ButtonData } from "src/app/shared/interface/formFieldsModal";
import portabilityDetailsControls from "src/assets/json/portability-details.json";
import { LoaderService } from "src/app/layout/loader/loader.service";
import { MatSelect } from "@angular/material/select";
import { CustomStepperService } from "src/app/shared/services/custom-stepper.service";
import { EmitButtonData } from "src/app/shared/interface/custom-footer";
import { PopupService } from "src/app/shared/services/popup.service";
import { ConvertSavedQuotesResponsePayload, SavedQuote } from "src/app/shared/interface/savedQuotes";
import { userEnums } from "src/app/shared/enums/userEnums";
import { StorageService } from "src/app/shared/services/storage.service";
import { HealthMasterService } from "src/app/shared/proxy-services/health-master.service";

@Component({
  selector: 'app-portability',
  templateUrl: './portability-details.component.html',
  styleUrls: ['./portability-details.component.scss'],

})
export class PortabilityComponent implements OnInit {
  @ViewChild('textCreate', { static: false }) textCreateComponent: ElementRef;
  portabilityControls = [];
  buttonData: ButtonData;
  companyNames: CompanyDetails[] = [];
  reasonOfPortability = [];
  private subscription: Subscription[] = [];
  portabilityForm: FormGroup;
  isSubmit: boolean = false
  showContent: boolean = false;
  transparentSpinner: boolean = false;
  multipleServiceError: boolean = false;
  healthFormData: HealthDataStorage;
  datepickerMobile: boolean = false;
  patchingDone: boolean = false;
  quoteDetails: ConvertSavedQuotesResponsePayload;
  quoteConvertData: SavedQuote;

  constructor(private fb: FormBuilder,
    private healthService: HealthService,
    private healthMasterService: HealthMasterService,
    public utilityService: UtilityService,
    private healthDataStorageService: HealthDataStorageService,
    private element: ElementRef,
    private customStepperService: CustomStepperService,
    private popupService:PopupService,
    private renderer: Renderer2,
    private storageService: StorageService) { }

  ngOnInit(): void {
    // To get all saved data so far
    this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);
    this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    this.fetchPortabilityDetails();
    if (window.innerWidth < 768) {
      this.datepickerMobile = true;
    } else {
      this.datepickerMobile = false;
    }
  }

  // IPAA-176: Saved Quotes Patching
  updateSavedQuotesPatching() {
    this.healthFormData.portabilityDataSet = true;
    let quoteFormData = this.healthFormData.portabilityFormData;
    quoteFormData.typeOfPolicy = this.quoteConvertData.PortabilityTypeOfPolicy;
    quoteFormData.portabilitySumInsured = this.quoteConvertData.PortabilitySumInsured;
    quoteFormData.policyStartDate = new Date(this.quoteConvertData.PreviousPolicies.PolicyStartDate).toISOString();
    quoteFormData.previousPolicyPED = this.quoteConvertData.PreviousPolicyPED;
    quoteFormData.policyEndDate = new Date(this.quoteConvertData.PreviousPolicies.PolicyEndDate).toISOString();
    quoteFormData.policyNumber = this.quoteConvertData.PreviousPolicies.PolicyNo;
    quoteFormData.portabilityReason = this.quoteConvertData.PreviousPolicies.ReasonOfPortability;
    quoteFormData.premiumAmount = this.quoteConvertData.PreviousPolicies.TotalPremium;
    quoteFormData.insuranceCompanyShortName = this.quoteConvertData.PreviousPolicies.InsurerCompany;
    quoteFormData.insuranceCompanyName = this.companyNames.filter(company => company.CompanyShortName == quoteFormData.insuranceCompanyShortName)[0].CompanyName;
    quoteFormData.previousPolicyClaims = this.quoteConvertData.PreviousPoliciesClaims;
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
  }
    

  fetchPortabilityDetails() {
    this.multipleServiceError = false;
    this.subscription.push(this.requestDataFromMultipleSources().subscribe({
      next: (responseList: [InsuredCompanyResponsePayload, PortabilityReasonsResponsePayload]) => {
        this.handleMultipleResponse(responseList);
        this.multipleServiceError = false;
      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f2' });
        this.showContent = false;
        this.multipleServiceError = true;
      }
    })
    )
  }

  // This contains all the services which are required on load
  requestDataFromMultipleSources(): Observable<[InsuredCompanyResponsePayload, PortabilityReasonsResponsePayload]> {
    let response = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'true' ? this.healthMasterService.getCompanyName() : this.healthService.getCompanyName();
    return response.pipe(
      concatMap(companyResponse => {
        return forkJoin([
          of(companyResponse),
          this.healthService.getReasonsOfPortabilityDetails()
        ]);
      })
    );
  }

  handleMultipleResponse(responseList: [InsuredCompanyResponsePayload, PortabilityReasonsResponsePayload]) {
    this.multipleServiceError = false;
 
    if (responseList[0].Data.length > 0 && responseList[0].StatusCode == Constants.statusCode_success) {
      this.companyNames = (responseList[0].Data).filter(data => data.CompanyCode != 'ICLB');
    }
 
    if (responseList[1].Data.Reasons.length > 0 && responseList[1].StatusCode == Constants.statusCode_success) {
      this.reasonOfPortability = responseList[1].Data.Reasons.map(data => {
        let portabilityReasons = {};
        portabilityReasons['label'] = data;
        portabilityReasons['value'] = data;
        return portabilityReasons;
      })
    }
 
    if (responseList[0].StatusCode != Constants.statusCode_success || responseList[1].StatusCode != Constants.statusCode_success) {
      // Show service error in ui
      this.customStepperService.emitStepData({ status: false, buttonId: 'f2' });
      this.multipleServiceError = true;
      this.showContent = false;
    }
 
    else if (responseList[0].StatusCode == Constants.statusCode_success && responseList[1].StatusCode == Constants.statusCode_success) {
      // Construction of dynamic form controls
      this.fetchPortabilityControls();
    }
  }

  // Api to fetch portability controls
  fetchPortabilityControls() {
    this.portabilityControls = portabilityDetailsControls.portabilityControlsData;
    this.buttonData = portabilityDetailsControls.buttonData;
    // Use below method to patch data returned by service in respective controls
    this.addServiceDataInForm();
    // IPAA-176: Saved quotes portability patching
    if(this.quoteDetails) {
      this.quoteConvertData = this.quoteDetails.Data;
      this.updateSavedQuotesPatching();
    }

    //If form value already present start patching in default value
    this.setExistingValue();
  }

  setExistingValue() {
    if (this.portabilityControls.length > 0 && this.healthFormData.portabilityDataSet) {
      this.portabilityControls.forEach(controls => {
        if (!this.utilityService.isEmptyOrNull(this.healthFormData.portabilityFormData[controls.controlName])) {
          controls.defaultValue = this.healthFormData.portabilityFormData[controls.controlName];
        }
      })
    }
    // form construction started
    this.constructPortabilityForm(this.portabilityControls);

  }

  constructPortabilityForm(controls) {
    this.portabilityForm = this.fb.group({
      // success:['',Validators.required]
    })

    if (controls.length > 0) {
      controls.forEach((formControls) => {
        this.portabilityForm.addControl(formControls.controlName, (this.fb.control(formControls.defaultValue, formControls.type == "radio" || formControls.controlName == portabilityControls.portabilitySumInsured ? null : this.utilityService.requiredFieldValidation())));
      })
    }

    // If portability data is already set then we need to show hide sum insured based on type of policy value
    // Even end date will get refreshed 
    if (this.healthFormData.portabilityDataSet) {
      this.portabilityControls.forEach((data, index) => {

        if (data.controlName == portabilityControls.typeOfPolicy) {
          let typeOfPolicyValue = (!this.utilityService.isEmptyOrNull(this.healthFormData.portabilityFormData[portabilityControls.typeOfPolicy])) ? this.healthFormData.portabilityFormData[portabilityControls.typeOfPolicy] : '';
          typeOfPolicyValue == policyType.Individual ? this.sumInsuredValidation(typeOfPolicyValue, true) : this.sumInsuredValidation(typeOfPolicyValue, false);
        }

        if (data.controlName == portabilityControls.previousPolicyPED || data.controlName == portabilityControls.previousPolicyClaims) {
          this.changeInRadioValue(this.healthFormData.portabilityFormData[data.controlName], data.controlName);
          let endDateIndex = this.findControlsByIndex(portabilityControls.policyEndDate);
          this.portabilityForm.get(portabilityControls.policyEndDate).setValue(this.portabilityControls[endDateIndex].defaultValue);
        }

        if (index == this.portabilityControls.length - 1) {
          this.patchingDone = true;
        }
      })

    }

    if (this.patchingDone) {
      this.portabilityForm.valueChanges.subscribe(data => {
        // If the data is already set and user changes the value then disable all further steps
        this.customStepperService.emitStepData({ status: false, buttonId: 'f2' });

      })
    }
    this.showContent = true;
  }

  addServiceDataInForm() {
    this.portabilityControls.forEach((response, index) => {
      // Use below logic to patch dropdown data into form
      if (response.controlName == portabilityControls.insuranceCompanyName && this.companyNames.length > 0) {
        this.portabilityControls[index].value = this.companyNames.map(data => {
          let companyName = {};
          companyName['label'] = data.CompanyName;
          companyName['value'] = data.CompanyCode;
          return companyName;
        }
        )
      }
      if (response.controlName == portabilityControls.portabilityReason && this.reasonOfPortability.length > 0) {
        this.portabilityControls[index].value = this.reasonOfPortability;
      }
      // Setting min and max date for datepicker into form
      if (response.controlName == portabilityControls.policyStartDate || response.controlName == portabilityControls.policyEndDate) {
        this.portabilityControls[index].minDate = response.controlName == portabilityControls.policyStartDate ? new Date(new Date().setFullYear(new Date().getFullYear() - 21, new Date().getMonth(), new Date().getDate() + 1)) : new Date(new Date().setDate(new Date().getDate() + 2));
        this.portabilityControls[index].maxDate = response.controlName == portabilityControls.policyStartDate ? new Date() : new Date(new Date().setDate(new Date().getDate() + 45));
      }
    })
  }

  // Below method is used to perform operations in dropdown
  // Always apply condition with control name just like below
  changeOfDropdown(value, controlName, index?) {
    if (controlName == portabilityControls.typeOfPolicy) {
      value == policyType.Individual ? this.sumInsuredValidation(value, true) : this.sumInsuredValidation(value, false);
    }
  }

  // Below method is used to perform operations in radio buttons
  // Always apply condition with control name just like below
  changeInRadioValue(value, controlName, index?) {
    if (controlName == portabilityControls.previousPolicyPED || controlName == portabilityControls.previousPolicyClaims) {
      let endDateIndex = this.findControlsByIndex(portabilityControls.policyEndDate)
      this.portabilityControls[endDateIndex].maxDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 45);
      // Below condition will work if any of the two radio controls value is set to yes
      if (controlName == portabilityControls.previousPolicyPED && this.portabilityForm.controls[portabilityControls.previousPolicyClaims].value == healthToggle.Yes || controlName == portabilityControls.previousPolicyClaims && this.portabilityForm.controls[portabilityControls.previousPolicyPED].value == healthToggle.Yes) {
        //  And if the selected radio control is also set to yes we need to reset enddate control
        if (value == healthToggle.Yes) {
          this.portabilityForm.get(portabilityControls.policyEndDate).setValue("");
        }
      }
      // Below condition will work if except the select radio control the other radio controls value is set to No
      else if (controlName == portabilityControls.previousPolicyPED && this.portabilityForm.controls[portabilityControls.previousPolicyClaims].value == healthToggle.No || controlName == portabilityControls.previousPolicyClaims && this.portabilityForm.controls[portabilityControls.previousPolicyPED].value == healthToggle.No) {
        // If the selected control value is set to yes
        if (value == healthToggle.Yes) {
          this.portabilityForm.get(portabilityControls.policyEndDate).setValue("");
          this.portabilityControls[endDateIndex].minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 10);
        }
        // If the selected control value is set to No. That means no radio  control is selected . Then you need to update the min end date
        else if (value == healthToggle.No) {
          this.portabilityForm.get(portabilityControls.policyEndDate).setValue("");
          this.portabilityControls[endDateIndex].minDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2);
        }
      }
    }
  }

  // This method is created to find controls and pass a comparison value so this method can return the index of that control
  findControlsByIndex(value: string): number {
    let sumIndex: number = this.portabilityControls.findIndex(data => data.controlName == value);
    return sumIndex;
  }

  sumInsuredValidation(value: string, hide: boolean) {
    if (value == policyType.Floater && !hide) {
      let sumIndex = this.findControlsByIndex(portabilityControls.portabilitySumInsured)
      this.portabilityControls[sumIndex].hide = hide;
      this.portabilityForm.controls[portabilityControls.portabilitySumInsured].setValidators([Validators.required]);
      this.portabilityForm.controls[portabilityControls.portabilitySumInsured].updateValueAndValidity();
    }
    else if (value == policyType.Individual && hide) {
      let sumIndex = this.findControlsByIndex(portabilityControls.portabilitySumInsured)
      this.portabilityControls[sumIndex].hide = hide;
      this.portabilityForm.controls[portabilityControls.portabilitySumInsured].reset();
      this.portabilityForm.controls[portabilityControls.portabilitySumInsured].clearValidators();
      this.portabilityForm.controls[portabilityControls.portabilitySumInsured].updateValueAndValidity();
    }
  }

  navigateTo(event: EmitButtonData) {
    this.portabilityForm.markAllAsTouched();
    this.isSubmit = true;
    if (!this.portabilityForm.valid) {
      for (const key of Object.keys(this.portabilityForm.controls)) {
        if (this.portabilityForm.controls[key].invalid) {
          const invalidControl = this.element.nativeElement.querySelector('#' + key + '.ng-invalid');
          invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
          invalidControl.focus();
          break;
        }
      }
      this.customStepperService.emitStepData({ status: false, buttonId: 'f2' });
    }
    else {
      this.patchingDone = true;
      this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
      this.setHealthFormData();
      this.isSubmit = false;
      this.customStepperService.emitStepData({ status: true, buttonId: 'f2' });
    }
  }

  setHealthFormData() {
    if (this.portabilityControls.length > 0) {
      this.portabilityControls.forEach(data => {
        if (data.controlName == portabilityControls.policyNumber) {
          this.healthFormData.portabilityFormData[data.controlName] = this.portabilityForm.controls[data.controlName].value ? (this.portabilityForm.controls[data.controlName].value).trim() : '';
        }
        else if (data.controlName == portabilityControls.insuranceCompanyName) {
          this.healthFormData.portabilityFormData[data.controlName] = this.portabilityForm.controls[data.controlName].value ? this.portabilityForm.controls[data.controlName].value : '';
          this.healthFormData.portabilityFormData.insuranceCompanyShortName = this.companyNames.filter(company => company.CompanyName == this.portabilityForm.controls[data.controlName].value)[0].CompanyShortName;
        }
        else {
          this.healthFormData.portabilityFormData[data.controlName] = this.portabilityForm.controls[data.controlName].value ? this.portabilityForm.controls[data.controlName].value : '';
        }
      })
    }
    this.healthFormData.portabilityDataSet = true;
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
  }

  isSelectOpen() {
    this.element.nativeElement.ownerDocument.body.style.overflow = 'hidden';
  }

  isSelectClose() {
    this.element.nativeElement.ownerDocument.body.style.overflow = 'auto';
  }

  onRefresh(status) {
    if (status) {
      this.fetchPortabilityDetails();
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  validationOnInputField(event: Event, alphaCheck: boolean, numberCheck: boolean) {
    this.utilityService.dynamicControlsAlphaOnly(event, alphaCheck);
    this.utilityService.dynamicControlsNumberOnly(event, numberCheck);
    this.utilityService.restrictSpace(event);
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

}
