import { HealthAilmentResponseStructure } from "src/app/shared/interface/healthMaster";
//common
export enum UserType {
    Agent = 'Agent',
    Customer = 'Customer',
}

export enum travelProducts {
    studentMedicalTravel = 'Student Travel',
    internationalTravel = 'International Travel',
}

export enum KycUserType {
    Coorperate = 'C',
    Individual = 'I'
}

export class TravelModal {
    sponsorType = ['Institution', 'Individual'];
    productType = ['New', 'Portability'];
    toggleOptions = ['Yes', 'No'];
    gstOptions = ['GSTIN', 'UIN'];
    agent = 'AGENT';
    ipAddress = 'NYSATRAVEL';
    title1 = [
        {
            "value": "0",
            "label": "Mrs."
        },

        {
            "value": "1",
            "label": "Mr."
        },

        {
            "value": "2",
            "label": "Ms."
        },

        {
            "value": "21",
            "label": "Mx."
        }
    ];
    title = [
        {
            "value": "0",
            "label": "Mrs.",
            "gender": "F"
        },
        {
            "value": "1",
            "label": "Mr.",
            "gender": "M"
        },
        {
            "value": "2",
            "label": "Ms.",
            "gender": "F"
        },
        {
            "value": "21",
            "label": "Mx.",
            "gender": "MF"
        }
    ]
    businessData = [
        { "value": "1", "label": "Non Resident Entity" },
        { "value": "2", "label": "Foreign company registered in India" },
        { "value": "3", "label": "Foreign LLP" },
        { "value": "4", "label": "Government Department" },
        { "value": "5", "label": "Hindu Undivided Family" },
        { "value": "6", "label": "LLP Partnership" },
        { "value": "7", "label": "Local Authorities" },
        { "value": "8", "label": "Partnership" },
        { "value": "9", "label": "Private Limited Company" },
        { "value": "10", "label": "Proprietorship" },
        { "value": "11", "label": "Others" }];
    customerTypeData = [
        { "value": "21", "label": "General" },
        { "value": "22", "label": "EOU/STP/EHTP" },
        { "value": "23", "label": "Government" },
        { "value": "24", "label": "Overseas" },
        { "value": "25", "label": "Related parties" },
        { "value": "26", "label": "SEZ" },
        { "value": "27", "label": "Others" }];
    gstStatusData = [
        { "value": "41", "label": "ARN Generated" },
        { "value": "42", "label": "Provision ID Obtained" },
        { "value": "43", "label": "To be commenced" },
        { "value": "44", "label": "Enrolled" },
        { "value": "45", "label": "Not applicable" }];
    travelLocation = [
        { "value": "USAorCanada", "label": "USA / Canada" },
        { "value": "schengenCountries", "label": "Schengen" },
        { "value": "asia", "label": "Asia" },
        { "value": "otherCountries", "label": "Other" }];
    tripType = [
        { "value": "roundTrip", "label": "Round Trip" },
        { "value": "multiTrip", "label": "Multi-trip" }];
    travelSubProductType = [
        { "value": "Individual", "label": "Individual" },
        { "value": "Family", "label": "Family" },
        { "value": "Senior Citizen", "label": "Senior Citizen" }];
    tripDuration = [{ "value": 30, "label": "30 Days" },
    { "value": 45, "label": "45 Days" },
    { "value": 60, "label": "60 Days" }];
    addonCovers = [{ coverText: 'Semi Professional Sports', coverId: 'addonCover1', coverCode: 'semiProfessionalSports' }]
}
export interface TravelDataStorage {
    productType: string;
    quoteDataSet: boolean;
    premiumDataSet: boolean;
    portabilityDataSet: boolean;
    insuredDataSet: boolean;
    kycDataSet: boolean;
    quoteFormData: QuoteFormData;
    premiumFormData: PremiumFormData;
    portabilityFormData: PortabilityFormData;
    insuredFormData: InsuredFormData;
    kycStatus: KycFlow;
    kycFormData: KycFormData;
    showAppointee: boolean;
    policyDetails: PolicyDetails;
    transactionDetails: TransactionDetails;
    paymentConfirmationDetails: PaymentConfirmationDetails;
    myPolicyDetails: MyPolicyDetails;
    tempEmail: string;
    tempMobileNo: number;

}
export interface TransactionDetails {
    EndDate: string;
    PFTransaction: string;
    PolicyNumber: string;
    StartDate: string;
    TotalPremium: number;
}
export interface PolicyDetails {
    PolicyID: number;
    EPolicyID?: any;
    ProposalNumber: string;
    PfProposalNumber: string;
    CustomerId: number;
    PfCustomerId: string;
    PolicySubType: number;
    PolicyType: number;
    PolicyStartDate: string;
    PolicyEndDate: string;
    Tenure: number;
    PolicyStatus: number;
    QuoteId: number;
    PfQuoteId: string;
    BasicPremium: string;
    TotalTax: string;
    TotalPremium: string;
    DealId: string;
}
export interface PaymentConfirmationDetails {
    PfTransactionId: string;
    PfProposalNumber: string;
    productType: string;
}
export interface TravelPremiumDetails {
    productName: string;
    travelType?: string,
    travelSubType?: string,
    numberOfTraveller: number,
    startDate: string,
    endDate: string,
    plan?: string,
    country: string,
    sumInsured: string,
    totalPremium: string,
    basicPremium: string,
    totalTax: string,
    quoteId: number,
    totalDays: number
}
export interface KycFormData {
    applicantDetails: ApplicantDetails;
    correspondenceAddress: CorrespondenceAddress;
    permanentAddress: PermanentAddress;
    otherDetails: OtherDetails;
    nomineeDetails: NomineeDetails;
    appointeeDetails: ApointeeDetails;
    kycDetails: KycDetails;
    universityDetails?: UniversityDetails;
    sponsorDetails?: SponsorDetails;
    customerType: string;
    CustomerID: number;
    PFCustomerID: string;
}
export interface KycFlow {
    ekycMandatory: boolean;
    normal: boolean;
    normalSelf: boolean;
    ovd: boolean;
    ovdSelf: boolean;
    isKYCDoneSuccessfully: boolean;
    idType: boolean;
    certNumber: string;
    isPilotUser: boolean;
}
export interface ApplicantDetails {
    applicantPanCardNumber: string;
    applicantTitle: string;
    applicantFullName: string;
    applicantDateOfBirth: string;
}
export interface CorrespondenceAddress {
    correspondenceAddress1: string;
    correspondenceAddress2: string;
    correspondenceLandmark: string;
    correspondencePincode: string;
    correspondencePincodeId?: number;
    correspondenceState: string;
    correspondenceStateId?: number;
    correspondenceCityList: Array<{}>;
    correspondenceCity: string;
    correspondenceCityId?: number;
}
export interface PermanentAddress {
    permanentAddress1: string;
    permanentAddress2: string;
    permanentLandmark: string;
    permanentPincode: string;
    permanentPincodeId?: number;
    permanentState: string;
    permanentStateId?: 0;
    permanentCityList: Array<{}>;
    permanentCity: string;
    permanentCityId?: 0;
    gender?: string;
    applicantMaritalStatus: string;
    maritalStatusId?: number;
    applicantEmailAddress: string;
    applicantMobileNumber: number;
    isPermanentAddressSame: boolean;
}
export interface OtherDetails {
    isGstRegistered: string;
    constitutionOfBusiness: string;
    constitutionOfBusinessId: string;
    panNumber: string;
    customerType: string;
    customerTypeId: string;
    gstStatus: string;
    gstStatusId: string;
    isGstApplicable: string;
    gstin: string;
    uin: string;
}
export interface KycDetails {
    CKYCID: string;
    EKYCID: string;
    ilKycReferenceNumber: string;
    pepFlag: boolean;
    kycMode: string;
    value: string;
    correlationId: string;
    customerID: number;
    pFCustomerID: string;
}
export interface UniversityDetails {
    instituteName: string;
    instituteAddress1: string;
    instituteAddress2: string;
    instituteCountry: string;
    instituteCountryList: Array<{}>;
    instituteState: string;
    instituteCity: string;
    institutePincode: string;
}
export interface SponsorDetails {
    sponsorType: string,
    sponsorTypeDetails?: Array<{}>;
    sponsorTitle: string,
    sponsorName: string,
}
export interface NomineeDetails {
    nomineeTitle: string;
    nomineeTitleId: number;
    nomineeName: string;
    isNomineeSameAsSponsor: boolean;
    nomineeRelationship?: string;
    nomineeDOB?: string;
    NomineeTitleID?: number;
    NomineeRelationshipID?: number;
}
export interface ApointeeDetails {
    appointeeTitle?: string;
    appointeeFullName?: string;
    appointeeRelationship?: string;
    appointeeDOB?: string;
    appointeeRelationShipID?: number;
    appointeeTitleID?: number;
}
export interface QuoteFormData {
    productName: string;
    subProductType: number;
    subProduct: string;
    state: string;
    stateId: number;
    isAlreadyTraveledFromIndia: boolean;
    isVisitingduringStudies: boolean;
    isKeralaGST: boolean;
    departureDateFromIndia: Date;
    policyStartDate: Date;
    leavingIndiaOn: Date;
    policyDuration: string;
    policyDurationInDays: number;
    policyEndDate: Date;
    studentDateOfBirth: Date;
    plan: string;
    planCode: number;
    sumInsured: number;
    noOfTraveller: number;
    coverageType: string;
    coverageSubType: number;
    members: Array<{}>;
    adultDetails: Array<{}>;
    childDetails: Array<{}>;
    totalMemberData: Array<{}>;
    planDetails: Array<{}>;
    relationshipDetails: Array<{}>;
    stateDetails: Array<{}>,
    quoteSummary: Array<{}>;
    tripType?: string,
    travelLoc?: string,
    isVisitOnImmigrantVisa?: boolean,
    leavingDate?: string,
    returningDate?: string,
    noOfTripDays?: number,
    tripDurationDetails?: Array<{}>,
    startDate?: string,
    noOfTravellerDetails?: Array<{}>,
    semiProfessionalSports?: boolean,
    adventureSports?: boolean


}
export interface PremiumFormData {
    productName: string;
    travelType?: string;
    travelSubType?: string;
    numberOfTraveller: number;
    startDate: string;
    endDate: string;
    plan?: string;
    country: string;
    sumInsured: string;
    totalPremium: string;
    basicPremium: string;
    totalTax: string;
    quoteId: number;
    totalDays: number;
}
export interface PortabilityFormData {
    insuranceCompanyName: string;
    insuranceCompanyShortName: string;
    policyEndDate: string;
    policyStartDate: string;
    policyNumber: string;
    portabilityReason: string;
    premiumAmount: string;
    previousPolicyClaims: string;
    previousPolicyPED: string;
    portabilitySumInsured: string;
    typeOfPolicy: string;
}
export interface InsuredFormData {
    insuredMemberDetails: Array<{}>;
    totalMemberDetails: Array<{}>;
    preExistingDisease: Array<{}>;
    existingDisease: HealthAilmentResponseStructure[];
    befitCovers: Array<{}>;
    BefitApplicableCities: Array<{}>;
    installmentApplicable: string;
    installmentTotalPremium: string;
    installmentFrequency: string;
}
export interface MyPolicyDetails {
    htNumber: string,
    htFolderIndex: number,
    policyId: number
}
export class TravelDataModal {
    TravelDataStorage: TravelDataStorage;
    setTravelDataStorage(form: TravelDataStorage) {
        this.TravelDataStorage = form;

    }

    getTravelDataStorage(): TravelDataStorage {
        if (this.TravelDataStorage) {
            return this.TravelDataStorage;
        }
        else {
            this.resetTravelDataStorage();
            return this.TravelDataStorage;
        }
    }

    resetTravelDataStorage(): TravelDataStorage {
        this.TravelDataStorage = {
            productType: 'Travel',
            quoteDataSet: false,
            premiumDataSet: false,
            portabilityDataSet: false,
            insuredDataSet: false,
            kycDataSet: false,
            showAppointee: false,
            tempMobileNo: 0,
            tempEmail: '',
            quoteFormData: {
                productName: '',
                subProductType: 0,
                subProduct: '',
                state: '',
                stateId: 0,
                isAlreadyTraveledFromIndia: false,
                isVisitingduringStudies: false,
                isKeralaGST: false,
                departureDateFromIndia: new Date(),
                policyStartDate: new Date(),
                leavingIndiaOn: new Date(),
                policyDuration: '',
                policyDurationInDays: 0,
                policyEndDate: new Date(),
                studentDateOfBirth: new Date(),
                plan: '',
                planCode: 0,
                sumInsured: 0,
                noOfTraveller: 0,
                coverageType: '',
                coverageSubType: 0,
                members: [],
                adultDetails: [],
                childDetails: [],
                totalMemberData: [],
                planDetails: [],
                relationshipDetails: [],
                stateDetails: [],
                quoteSummary: [],
                tripType: '',
                travelLoc: '',
                isVisitOnImmigrantVisa: false,
                leavingDate: '',
                returningDate: '',
                noOfTripDays: 0,
                tripDurationDetails: [],
                startDate: '',
                noOfTravellerDetails: [],
                semiProfessionalSports: false,
                adventureSports: false
            },
            premiumFormData: {
                productName: '',
                travelType: '',
                travelSubType: '',
                numberOfTraveller: 0,
                startDate: '',
                endDate: '',
                plan: '',
                country: '',
                sumInsured: '',
                totalPremium: '',
                basicPremium: '',
                totalTax: '',
                quoteId: 0,
                totalDays: 0,
            },
            portabilityFormData: {
                insuranceCompanyName: '',
                insuranceCompanyShortName: '',
                policyEndDate: '',
                policyStartDate: '',
                policyNumber: '',
                portabilityReason: '',
                premiumAmount: '',
                previousPolicyClaims: '',
                previousPolicyPED: '',
                portabilitySumInsured: '',
                typeOfPolicy: ''
            },
            insuredFormData: {
                insuredMemberDetails: [],
                preExistingDisease: [],
                totalMemberDetails: [],
                befitCovers: [],
                BefitApplicableCities: [],
                existingDisease: [],
                installmentApplicable: 'false',
                installmentTotalPremium: '',
                installmentFrequency: '',
            },
            kycStatus: {
                ekycMandatory: false,
                normal: false,
                normalSelf: false,
                ovd: false,
                ovdSelf: false,
                idType: false,
                isKYCDoneSuccessfully: false,
                certNumber: '',
                isPilotUser: false
            },
            kycFormData: this.resetKycFormData(),
            policyDetails: {
                PolicyID: 0,
                EPolicyID: '',
                ProposalNumber: '',
                PfProposalNumber: '',
                CustomerId: 0,
                PfCustomerId: '',
                PolicySubType: 0,
                PolicyType: 0,
                PolicyStartDate: '',
                PolicyEndDate: '',
                Tenure: 0,
                PolicyStatus: 0,
                QuoteId: 0,
                PfQuoteId: '',
                BasicPremium: '',
                TotalTax: '',
                TotalPremium: '',
                DealId: '',

            },
            transactionDetails: {
                EndDate: '',
                PFTransaction: '',
                PolicyNumber: '',
                StartDate: '',
                TotalPremium: 0
            },
            paymentConfirmationDetails: {
                PfProposalNumber: '',
                PfTransactionId: '',
                productType: '',
            },
            myPolicyDetails: {
                htNumber: '',
                htFolderIndex: 0,
                policyId: 0
            }
        }
        return this.TravelDataStorage;
    }

    resetKycFormData(): KycFormData {
        return {
            applicantDetails: {
                applicantPanCardNumber: '',
                applicantTitle: '',
                applicantFullName: '',
                applicantDateOfBirth: ''
            },
            correspondenceAddress: {
                correspondenceAddress1: '',
                correspondenceAddress2: '',
                correspondenceLandmark: '',
                correspondencePincode: '',
                correspondencePincodeId: 0,
                correspondenceState: '',
                correspondenceStateId: 0,
                correspondenceCityList: [],
                correspondenceCity: '',
                correspondenceCityId: 0,
                // gender: '',
                // correspondenceMaritalStatus: '',
                // correspondenceMaritalStatusId: 0,
                // correspondenceEmail: '',
                // correspondenceMobileNumber: 0,
                // isPermanentAddressSame: false
            },
            permanentAddress: {
                permanentAddress1: '',
                permanentAddress2: '',
                permanentLandmark: '',
                permanentPincode: '',
                permanentPincodeId: 0,
                permanentState: '',
                permanentStateId: 0,
                permanentCityList: [],
                permanentCity: '',
                permanentCityId: 0,
                gender: '',
                applicantMaritalStatus: '',
                maritalStatusId: 0,
                applicantEmailAddress: '',
                applicantMobileNumber: 0,
                isPermanentAddressSame: false
            },
            otherDetails: {
                isGstRegistered: '',
                constitutionOfBusiness: '',
                constitutionOfBusinessId: '',
                panNumber: '',
                customerType: '',
                customerTypeId: '',
                gstStatus: '',
                gstStatusId: '',
                isGstApplicable: '',
                gstin: '',
                uin: ''
            },
            nomineeDetails: {
                nomineeTitle: '',
                nomineeTitleId: 0,
                nomineeName: '',
                isNomineeSameAsSponsor: false,
                nomineeRelationship: '',
                nomineeDOB: '',
                NomineeTitleID: 0,
                NomineeRelationshipID: 0,
            },
            appointeeDetails: {
                appointeeTitle: '',
                appointeeFullName: '',
                appointeeRelationship: '',
                appointeeDOB: '',
                appointeeRelationShipID: 0,
                appointeeTitleID: 0
            },
            customerType: '',
            CustomerID: 0,
            PFCustomerID: '',
            kycDetails: {
                CKYCID: '',
                EKYCID: '',
                ilKycReferenceNumber: '',
                pepFlag: false,
                kycMode: '',
                value: '',
                correlationId: '',
                customerID: 0,
                pFCustomerID: '',
            },
            universityDetails: {
                instituteName: '',
                instituteAddress1: '',
                instituteAddress2: '',
                instituteCountry: '',
                instituteCountryList: [],
                instituteState: '',
                instituteCity: '',
                institutePincode: '',
            },
            sponsorDetails: {
                sponsorType: '',
                sponsorTypeDetails: [],
                sponsorTitle: '',
                sponsorName: '',
            }
        }
    }

}
