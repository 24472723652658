import { Component, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { fromEvent } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { Constants } from 'src/app/shared/constants/constants';
import { popupDescriptionMsg } from 'src/app/shared/enums/popupEnums';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { KycDetailsResponse } from 'src/app/shared/interface/kycDetails';
import { PaymentStatusRequestPayload } from 'src/app/shared/interface/payment';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { ApiTokenResponsePayload, AryaAPIKycResp } from 'src/app/shared/interface/utilityApi';
import { PaymodePaymentService } from 'src/app/shared/proxy-services/paymode-payment.service';
import { RenewalService } from 'src/app/shared/proxy-services/renewal-api.service';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { UtilityApiService } from 'src/app/shared/proxy-services/utility-api.service';
import { WrapperService } from 'src/app/shared/proxy-services/wrapper.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import HomeRenewalPopup from 'src/assets/json/home-renewal-popup.json';
import { environment } from 'src/environments/environment';
declare var $: any;
declare const AJL: any;

export interface HomeRenewalFooter {
  TotalPremium: number,
  SumInsured: string,
  BasicPremium: string,
  TotalTax: string,
  tableData: any
  Tenure: string,
  hideOpCoverShowTenure: boolean,
  hideSubLimitShowSI: boolean,
}
@Component({
  selector: 'app-renewal-home-summary',
  templateUrl: './renewal-home-summary.component.html',
  styleUrls: ['./renewal-home-summary.component.scss']
})

export class RenewalHomeSummaryComponent {
  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  @ViewChild('declarations') ps: TemplateRef<any>;
  @ViewChild('agent') ad: TemplateRef<any>;
  termsAndConditionForm: FormGroup;
  applicantDetailForm: FormGroup;
  mailingDetailForm: FormGroup;
  subscription: Subscription[] = [];
  ekycMandatory: boolean = false;
  ilKycReferenceNumber: string = '';
  cKycId: string = '';
  eKycId: string = '';
  eKycPan: string = '';
  isKYCDoneSuccessfully: boolean = false;
  channelData: ChannelData;
  scrolling: boolean = false;
  genders: string[] = ['Male', 'Female'];
  applicantAddressIds = [];
  riskAddressIds = [];
  mailingAddressIds = [];
  mailingAddressIdsCopy = [];
  isOVD: boolean = false;
  chooseDataArray: string[] = ['Renewal', 'KYC'];
  disableRenewal: boolean = false;
  setBold: boolean = false;
  renewalHomeSummary: any;
  panCardNo: string;
  aadharCardNo: string;
  kycCorrelationID: string;
  kycEmail: string;
  kycMobile: string;
  popupSection = HomeRenewalPopup;
  applicantCityEdit: boolean = false;
  agentName: string = '';
  homeRenewalFooterData: HomeRenewalFooter = { TotalPremium: 0, SumInsured: '', BasicPremium: '', TotalTax: '', Tenure: '', tableData: [], hideOpCoverShowTenure: false, hideSubLimitShowSI: false };

  constructor(public utilityService: UtilityService,
    private fb: FormBuilder,
    private wrapperService: WrapperService,
    private renderer: Renderer2,
    private storageService: StorageService,
    private paymode_payment_service: PaymodePaymentService,
    private customFooterService: CustomFooterService,
    private rtoListService: RTOListService,
    private popupService: PopupService,
    private dialog: MatDialog,
    private renewalService: RenewalService,
    private utilityApiService: UtilityApiService) {
    this.subscription.push(this.popupService.paymentValue$.subscribe(data => {
      this.callHomeProposal(data);
    }))
  }

  ngOnInit() {
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    this.renewalHomeSummary = this.storageService.getAESDecryptedData(userEnums.RenewalFormData);
    this.agentName = this.renewalHomeSummary.agentName;
    this.getRiskAddressIds();
    this.initApplicantDetailFormGroup();
    this.setChooseData();
    this.initMailingDetailFormGroup();
    this.initCheckBoxFormGroup();
    this.homeRenewalFooter();
    if (this.renewalHomeSummary.ckycNumber) {
      this.isKYCDoneSuccessfully = true;
      this.patchRenewalKYCData();
    }
    this.subscription.push(fromEvent(window, 'message')
      .subscribe((event) => {
        this.receiveMessage(event);
      }));
  }

  getAgentName() {
    if (sessionStorage.getItem(userEnums.AgentDetails)) {
      this.agentName = this.storageService.getAESDecryptedData(userEnums.AgentDetails)?.AgentName;
    }
  }

  patchRenewalKYCData() {
    this.panCardNo = this.renewalHomeSummary.Customerdetail.PAN;
    this.aadharCardNo = this.renewalHomeSummary.Customerdetail.AADHARNO;
    this.kycMobile = this.renewalHomeSummary.Customerdetail.MOBILENUMBER;
    this.kycEmail = this.renewalHomeSummary.Customerdetail.EmailId;
    this.applicantDetail['KYCapplicantMobile'].setValue(this.kycMobile);
    this.applicantDetail['KYCapplicantEmail'].setValue(this.kycEmail);
    this.applicantDetail['applicantAddress1'].setValue(this.renewalHomeSummary.Customerdetail.PERMANENTADDRESSLINE1);
    this.applicantDetail['applicantAddress2'].setValue(this.renewalHomeSummary.Customerdetail.PERMANENTADDRESSLINE2);
    this.applicantDetail['applicantPinCode'].setValue(this.renewalHomeSummary.Customerdetail.PERMANENTPINCODE);
    this.applicantDetail['applicantCity'].setValue(this.renewalHomeSummary.Customerdetail.PERMANENTCITYDISTRICT);
    this.applicantDetail['applicantState'].setValue(this.renewalHomeSummary.Customerdetail.PERMANENTSTATE);
    this.fetchPincode({ value: String(this.applicantDetail['applicantPinCode'].value) }, 'applicant');
  }

  setChooseData() {
    if (this.renewalHomeSummary.mobileno && this.renewalHomeSummary.emailId) {
      this.applicantDetail['chooseData'].setValue(this.chooseDataArray[0]);
    } else {
      this.disableRenewal = true;
    }
  }

  changeChooseData() {
    this.applicantDetail['chooseData'].setValue($("input:radio[name=renewalKYC]:checked").val());
    this.setBold = !this.setBold;
  }

  getRiskAddressIds() {
    if (this.renewalHomeSummary.riskPinCode) {
      this.fetchPincode({ value: String(this.renewalHomeSummary.riskPinCode) }, 'risk');
    }
  }

  getMinMaxDate(minMaxType: string) {
    let maxDate = this.utilityService.subtractYears(new Date(), 18);

    let yearsdate = this.utilityService.subtractYears(new Date(), 65);
    let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
    let minDate = this.utilityService.subtractDays(monthdate, 25);
    return minMaxType == 'min' ? minDate : maxDate;
  }

  onWindowScroll() {
    this.scrolling = true;

    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  fixDOB(value) {
    if (!value.includes('T')) {
      const date = value.split('/');
      return `${date[2]}-${date[1]}-${date[0]}T:00:00:00`
    }
    return value;
  }

  initCheckBoxFormGroup() {
    this.termsAndConditionForm = this.fb.group({
      agentDeclaration: [{ value: false, disabled: true }, [Validators.requiredTrue]],
      tncAcceptance: [{ value: false, disabled: true }, [Validators.requiredTrue]],
    })
  }

  get checkBox() {
    return this.termsAndConditionForm.controls;
  }

  //Applicant Detail Edit and Save 
  initApplicantDetailFormGroup() {
    this.applicantDetailForm = this.fb.group({
      applicantName: [this.renewalHomeSummary.Customerdetail.CustomerName, [Validators.required]],
      applicantDOB: [this.fixDOB(this.renewalHomeSummary.Customerdetail.DOB), [Validators.required]],
      applicantAddress1: ['', [Validators.required]],
      applicantAddress2: [''],
      applicantPinCode: ['', [Validators.required]],
      applicantCity: ['', [Validators.required]],
      applicantState: ['', [Validators.required]],
      applicantGender: [this.renewalHomeSummary.Customerdetail.Gender, [Validators.required]],
      KYCapplicantMobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      KYCapplicantEmail: ['', [Validators.required, Validators.email]],
      applicantEditIcon: [false],
      chooseData: [this.chooseDataArray[1]]
    })
  }

  get applicantDetail() {
    return this.applicantDetailForm.controls;
  }

  getApplicantEdit() {
    return this.applicantDetailForm.get('applicantEditIcon').value;
  }

  toggleApplicantData(value: string) {
    if (this.applicantDetailForm.valid && value == 'Save') {
      this.applicantDetailForm.get('applicantEditIcon').setValue(!this.applicantDetailForm.get('applicantEditIcon').value)
    } else if (!this.isKYCDoneSuccessfully) {
      const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.kycIncomplete);
      this.popupService.openGenericPopup(errorPopupData);
    }
    else if (this.applicantDetailForm.invalid && value == 'Save') {
      const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.requiredDetails);
      this.popupService.openGenericPopup(errorPopupData);
    }
    else if (value == 'Edit') {
      this.applicantDetailForm.get('applicantEditIcon').setValue(!this.applicantDetailForm.get('applicantEditIcon').value)
    }
  }

  //Mailing Location Edit and Save
  initMailingDetailFormGroup() {
    this.mailingDetailForm = this.fb.group({
      mailingAddress1: [this.renewalHomeSummary.Customerdetail.MAILINGADDRESSLINE1, [Validators.required]],
      mailingAddress2: [this.renewalHomeSummary.Customerdetail.MAILINGADDRESSLINE2],
      // mailingAddress3: [this.renewalHomeSummary.mailingAddress3],
      mailingPinCode: [this.renewalHomeSummary.Customerdetail.MAILINGPINCODE, [Validators.required]],
      mailingCity: [this.renewalHomeSummary.Customerdetail.MAILING_CITYDISTRICT_NAME, [Validators.required]],
      mailingState: [this.renewalHomeSummary.Customerdetail.MAILING_STATE_NAME, [Validators.required]],
      mailingEditIcon: [false],
      isLocationSame: [false]
    })

    if (this.mailingDetailForm.get('mailingPinCode').value) {
      this.fetchPincode({ value: String(this.mailingDetailForm.get('mailingPinCode').value) }, 'mailing');
    }
  }

  get mailingDetail() {
    return this.mailingDetailForm.controls;
  }

  getMailingEdit() {
    return this.mailingDetailForm.get('mailingEditIcon').value;
  }

  toggleMailingData(value: string) {
    if (this.mailingDetailForm.valid && value == 'Save') {
      this.mailingDetailForm.get('mailingEditIcon').setValue(!this.mailingDetailForm.get('mailingEditIcon').value)
    } else if (this.mailingDetailForm.invalid && value == 'Save') {
      const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.requiredDetails);
      this.popupService.openGenericPopup(errorPopupData);
    } else if (value == 'Edit') {
      this.mailingDetailForm.get('mailingEditIcon').setValue(!this.mailingDetailForm.get('mailingEditIcon').value)
    }
  }

  isCitySame(type: string) {
    let cityArray: string[] = [];
    if (type == 'mailing') {
      this.mailingAddressIds[0].forEach(data => {
        cityArray.push(data.CityName);
      })
      if (!cityArray.includes(this.mailingDetail['mailingCity'].value)) {
        this.mailingDetail['mailingCity'].setErrors({ 'incorrect': true });
      }
      return !cityArray.includes(this.mailingDetail['mailingCity'].value);
    }
    return false;
  }

  validatePinCode(event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const regex = Constants.numberPatternRegExp;
    if (event.key === 'Backspace') {
      return;
    }
    if (!regex.test(inputValue + event.key)) {
      event.preventDefault();
    }
  }

  validateName(event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const regex = Constants.namePatternRegExp;
    if (event.key === 'Backspace') {
      return;
    }
    if (!regex.test(inputValue + event.key)) {
      event.preventDefault();
    }
  }

  fetchPincode(event, type: string) {
    if (event.value.length == 6) {
      const data: RTOListRequestPayload = {
        "Pincode": String(event.value),
        "CityID": "",
      }
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (type == 'applicant') {
              this.applicantAddressIds.push(response.Data);
              if (this.applicantAddressIds[0].length == 2) {
                this.applicantCityEdit = true;
              }
              // if (this.isOVD) {
              //   this.applicantDetail['applicantCity'].setValue(response.Data[0].CityName);
              // }
              // if (this.utilityService.isEmptyOrNull(this.cKycId)){
              this.applicantDetail['applicantCity'].setValue(response.Data[0].CityName);
              this.applicantDetail['applicantState'].setValue(response.Data[0].StateName);
            }
            else if (type == 'mailing') {
              this.mailingAddressIds.push(response.Data);
              this.mailingAddressIdsCopy = this.mailingAddressIds;
              if (!this.mailingDetail['mailingCity'].value) {
                this.mailingDetail['mailingCity'].setValue(response.Data[0].CityName);
              }
              this.mailingDetail['mailingState'].setValue(response.Data[0].StateName);
            } else if (type == 'risk') {
              this.riskAddressIds.push(response.Data)
            }
          }
        }
      }))
    } else {
      if (type == 'mailing') {
        this.mailingDetail['mailingCity'].setValue('');
        this.mailingDetail['mailingState'].setValue('');
        this.mailingAddressIds = [];
      } else if (type == 'risk') {
        this.riskAddressIds = [];
      } else if (type == 'applicant') {
        this.applicantDetail['applicantCity'].setValue('');
        this.applicantDetail['applicantState'].setValue('');
        this.applicantAddressIds = [];
      }
    }
  }

  isSameLocation(isChecked: boolean) {
    if (isChecked) {
      this.mailingDetail['mailingAddress1'].setValue(this.renewalHomeSummary.riskAddressLine1);
      this.mailingDetail['mailingAddress2'].setValue(this.renewalHomeSummary.riskAddressLine2);
      // this.mailingDetail['mailingAddress3'].setValue(this.renewalHomeSummary.riskAddressLine3);
      this.mailingDetail['mailingPinCode'].setValue(this.renewalHomeSummary.riskPinCode);
      this.mailingDetail['mailingCity'].setValue(this.renewalHomeSummary.riskCity);
      this.mailingDetail['mailingState'].setValue(this.renewalHomeSummary.riskState);
      this.mailingDetail['mailingEditIcon'].setValue(true);
      this.mailingAddressIds = this.riskAddressIds;
    } else {
      this.mailingDetail['mailingAddress1'].setValue(this.renewalHomeSummary.Customerdetail.MAILINGADDRESSLINE1);
      this.mailingDetail['mailingAddress2'].setValue(this.renewalHomeSummary.Customerdetail.MAILINGADDRESSLINE2);
      // this.mailingDetail['mailingAddress3'].setValue('');
      this.mailingDetail['mailingPinCode'].setValue(this.renewalHomeSummary.Customerdetail.MAILINGPINCODE);
      this.mailingDetail['mailingCity'].setValue(this.renewalHomeSummary.Customerdetail.MAILING_CITYDISTRICT_NAME);
      this.mailingDetail['mailingState'].setValue(this.renewalHomeSummary.Customerdetail.MAILING_STATE_NAME);
      this.mailingDetail['mailingEditIcon'].setValue(false);
      this.mailingAddressIds = this.mailingAddressIdsCopy;
    }
  }


  homeRenewalFooter() {
    this.homeRenewalFooterData.BasicPremium = String(this.renewalHomeSummary.basicPremium);
    this.homeRenewalFooterData.TotalTax = String(this.renewalHomeSummary.totalGst);
    this.homeRenewalFooterData.TotalPremium = this.renewalHomeSummary.totalPremium;
    this.homeRenewalFooterData.Tenure = this.renewalHomeSummary.policyTenureStructure != 0 ? String(this.renewalHomeSummary.policyTenureStructure) : String(this.renewalHomeSummary.policyTenureContent);
    this.homeRenewalFooterData.SumInsured = String(this.renewalHomeSummary.totalSumInsured);
    this.homeRenewalFooterData.hideOpCoverShowTenure = true;
    this.homeRenewalFooterData.hideSubLimitShowSI = true;
    this.homeRenewalFooterData.tableData = this.renewalHomeSummary.coverDetails;
    this.customFooterService.showRenewalFooter(this.homeRenewalFooterData);
  }

  getContactInfo() {
    if (this.applicantDetail['chooseData'].value == 'Renewal') {
      return { mobileNo: this.renewalHomeSummary.mobileno, emailID: this.renewalHomeSummary.emailId }
    } else {
      return { mobileNo: this.applicantDetail['KYCapplicantMobile'].value, emailID: this.applicantDetail['KYCapplicantEmail'].value }
    }
  }

  getkycAddressID() {
    for (let i of this.applicantAddressIds[0]) {
      if ((i.CityName).toLowerCase() == (this.applicantDetail['applicantCity'].value).toLowerCase()) {
        return i;
      }
    }
    const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.pincodeError);
    setTimeout(() => { this.popupService.openGenericPopup(errorPopupData); }, 100);
  }

  getmailingAddressID() {
    for (let i of this.mailingAddressIds[0]) {
      if ((i.CityName).toLowerCase() == (this.mailingDetail['mailingCity'].value).toLowerCase()) {
        return i;
      }
    }
    const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.pincodeError);
    setTimeout(() => { this.popupService.openGenericPopup(errorPopupData); }, 100);
  }

  constructHomeProposalRequest() {
    const customerName = this.applicantDetail['applicantName'].value;
    const customerDOB = this.utilityService.setDOB(this.applicantDetail['applicantDOB'].value);
    const contactInfo = this.getContactInfo();
    const kycAddressID = this.getkycAddressID();
    const mailingAddressID = this.getmailingAddressID();
    const requestData = {
      "Clauses": this.renewalHomeSummary.Clauses,
      "IsCertified": this.renewalHomeSummary.IsCertified,
      "policyNo": this.renewalHomeSummary.policyNo,
      "policyStartDate": this.renewalHomeSummary.policyStartDate,
      "policyEndDate": this.renewalHomeSummary.policyEndDate,
      "policyType": this.renewalHomeSummary.policyType,
      "pf_customerId": this.renewalHomeSummary.Customerdetail.CustomerId,
      "riskAddressLine1": this.renewalHomeSummary.riskAddressLine1,
      "riskAddressLine2": this.renewalHomeSummary.riskAddressLine2,
      "riskAddressLine3": this.renewalHomeSummary.riskAddressLine3,
      "riskState": this.renewalHomeSummary.riskState,
      "riskCity": this.renewalHomeSummary.riskCity,
      "riskPinCode": this.renewalHomeSummary.riskPinCode,
      "policyTenureStructure": this.renewalHomeSummary.policyTenureStructure,
      "constructionRate": this.renewalHomeSummary.constructionRate,
      "areaOfProperty": this.renewalHomeSummary.areaOfProperty,
      "structureSumInsured": this.renewalHomeSummary.structureSumInsured,
      "policyTenureContent": this.renewalHomeSummary.policyTenureContent,
      "contentSumInsured": this.renewalHomeSummary.contentSumInsured,
      "revisedProductCode": this.renewalHomeSummary.revisedProductCode,
      "revisedProductName": this.renewalHomeSummary.revisedProductName,
      "productCode": this.renewalHomeSummary.existingProductCode,
      "productName": this.renewalHomeSummary.existingProductName,
      "claimAmount": this.renewalHomeSummary.claimAmount,
      "claimPremium": this.renewalHomeSummary.claimPremium,
      "locationOfProperty": this.renewalHomeSummary.locationOfProperty,
      "premiumReceivedFromCustomer": this.renewalHomeSummary.premiumReceivedFromCustomer,
      "coverDetails": this.renewalHomeSummary.coverDetails,
      "basicPremium": this.renewalHomeSummary.basicPremium,
      "totalGst": this.renewalHomeSummary.totalGst,
      "totalPremium": this.renewalHomeSummary.totalPremium,
      "durablesSumInsured": this.renewalHomeSummary.durablesSumInsured,
      "furnitureAndFixturesSumInsured": this.renewalHomeSummary.furnitureAndFixturesSumInsured,
      "clothingUtensumInsuredlsAndOthersSumInsured": this.renewalHomeSummary.clothingUtensumInsuredlsAndOthersSumInsured,
      "valuablesSumInsured": this.renewalHomeSummary.valuablesSumInsured,
      "dealNumber": this.renewalHomeSummary.dealNumber,
      "QuickId": this.renewalHomeSummary.QuickId,
      "applicantStatus": this.renewalHomeSummary.applicantStatus,
      "hspNonHsp": this.renewalHomeSummary.hspNonHsp,
      "ilLocationCode": this.renewalHomeSummary.ilLocationCode,
      "lanNo": this.renewalHomeSummary.lanNo,
      "loanSanctionAmount": this.renewalHomeSummary.loanSanctionAmount,
      "loanDisbursalAmount": this.renewalHomeSummary.loanDisbursalAmount,
      "loanDisbursalDate": this.renewalHomeSummary.loanDisbursalDate,
      "loanSanctionDate": this.renewalHomeSummary.loanSanctionDate,
      "loanTenuer": this.renewalHomeSummary.loanTenuer,
      "masterPolicy": this.renewalHomeSummary.masterPolicy,
      "masterPolicyNo": this.renewalHomeSummary.masterPolicyNo,
      "noOfApplicant": this.renewalHomeSummary.noOfApplicant,
      "ownershipType": this.renewalHomeSummary.ownershipType,
      "proposalType": this.renewalHomeSummary.proposalType,
      "typeOfDwelling": this.renewalHomeSummary.typeOfDwelling,
      "uniqueCode": this.renewalHomeSummary.uniqueCode,
      "typeOfPolicy": this.renewalHomeSummary.typeOfPolicy,
      "privilegedCustomer": this.renewalHomeSummary.privilegedCustomer,
      "planCode": this.renewalHomeSummary.planCode,
      "planName": this.renewalHomeSummary.planName,
      "policyTenureForChi": this.renewalHomeSummary.policyTenureForChi,
      "CorrelationID": this.renewalHomeSummary.correlationId,
      "personalAccidentRequired": this.renewalHomeSummary.personalAccidentRequired,
      "personalAccidentInsuredName": this.renewalHomeSummary.personalAccidentInsuredName,
      "personalAccidentDob": this.renewalHomeSummary.personalAccidentDob,
      "personalAccidentRelationshipWithApplicant": this.renewalHomeSummary.personalAccidentRelationshipWithApplicant,
      "personalAccidentGender": this.renewalHomeSummary.personalAccidentGender,
      "personalAccidentInrValue": this.renewalHomeSummary.personalAccidentInrValue,
      "personalAccidentNomineeName": this.renewalHomeSummary.personalAccidentNomineeName,
      "personalAccidentRelationshipWithInsured": this.renewalHomeSummary.personalAccidentRelationshipWithInsured,
      "IsCustomerModified": true,
      "Customerdetails": {
        "IpartnerCustomerId": this.renewalHomeSummary.Customerdetail.CustomerId,
        "CustomerName": customerName,
        "CustomerID": "",
        "CustomerType": "",
        "Gender": this.applicantDetail['applicantGender'].value,
        "DOB": customerDOB,
        "PAN": this.panCardNo,
        "EmailId": contactInfo.emailID,
        "AlternateEmailId": "",
        "MOBILENUMBER": contactInfo.mobileNo,
        "AlternateMobileNo": "",
        "TELEPHONENUMBER": "",
        "MOTHER_NAME": "",
        "MAILINGCITYDISTRICT": mailingAddressID.CityID,
        "MAILING_CITYDISTRICT_NAME": this.mailingDetail['mailingCity'].value,
        "MAILINGAREAVILLAGE_CD": "",
        "MAILINGAREAVILLAGE_NAME": "",
        "MAILINGCOUNTRY_CD": "100",
        "MAILINGCOUNTRY_NAME": "India",
        "MAILINGADDRESSDETAIL": "",
        "MAILINGPINCODE": this.mailingDetail['mailingPinCode'].value,
        "MAILING_STATE_NAME": this.mailingDetail['mailingState'].value,
        "MAILINGSTATE_CODE": mailingAddressID.StateId,
        "MAILINGLANDMARK": "",
        "PERMANENTCITYCODE": kycAddressID.CityID,
        "PERMANENTADDRESSDETAIL": "",
        "PERMANENTPINCODE": this.applicantDetail['applicantPinCode'].value,
        "PERMANENTCITYDISTRICT": this.applicantDetail['applicantCity'].value,
        "PERMANENTAREAVILLAGE_CD": "",
        "PERMANENTAREAVILLAGE_NAME": "",
        "PERMANENTCOUNTRY_CD": "100",
        "PERMANENTCOUNTRY_NAME": "India",
        "PERMANENTSTATE": this.applicantDetail['applicantState'].value,
        "PERMANENTSTATECODE": kycAddressID.StateId,
        "PERMANENTLANDMARK": "",
        "OCCUPATIONDESC": "",
        "NATIONALITY": "",
        "RESIDENTIALSTATUS": "",
        "EDUCATIONQUALIFICATION": "",
        "ANNUALINCOME": "",
        "PASSPORTNO": "",
        "AADHARNO": this.aadharCardNo,
        "MARITALSTATUS": "",
        "MAILINGADDRESSLINE1": this.mailingDetail['mailingAddress1'].value,
        "MAILINGADDRESSLINE2": this.mailingDetail['mailingAddress2'].value,
        "PERMANENTADDRESSLINE1": this.applicantDetail['applicantAddress1'].value,
        "PERMANENTADDRESSLINE2": this.applicantDetail['applicantAddress2'].value,
        "PanExemptedApplicable": false,
        "AadhaarExemptedApplicable": false
      }
    }
    return requestData;
  }

  buttonSelection(event) {
    let value = event.label;
    if (value.indexOf(' ')) {
      value = event.label.replace(' ', '');
    }
    this.callHomeProposal(value);
  }

  callHomeProposal(value) {
    if (this.applicantDetailForm.valid && this.mailingDetailForm.valid && this.checkBox['tncAcceptance'].value && this.checkBox['agentDeclaration'].value && this.applicantDetail['applicantEditIcon'].value && this.mailingDetail['mailingEditIcon'].value) {
      const proposalRequest = this.constructHomeProposalRequest();
      this.renewalService.homeProposal(proposalRequest).subscribe({
        next: (response) => {
          if (response.StatusCode == Constants.statusCode_success) {
            const data = {
              "PolicyID": response.Data.PolicyID,
              "ProposalNo": response.Data.ProposalNo,
              "CustomerID": response.Data.CustomerID,
              "CorrelationID": response.Data.CorrelationID,
              "TotalPremium": this.renewalHomeSummary.totalPremium
            }
            this.paymentCreation(value, data);
          }
        }
      })
    }
    else {
      const errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.requiredSaveDetails);
      setTimeout(() => { this.popupService.openGenericPopup(errorPopupData); }, 100);
    }
  }


  paymentCreation(payMode, req) {
    let data: PaymentStatusRequestPayload = {
      paymode: payMode,
      befitPlan: false,
      homePlan: false,
      zeroTatProduct: false,
      proposalPolicyID: req.PolicyID,
      bundalPolicyID: '',
      totalPremium: req.TotalPremium,
      proposalNumber: req.ProposalNo,
      bundleProposalNumber: '',
    }
    switch (payMode) {
      case 'PaymentLink': {
        this.paymode_payment_service.commonPayLaterRequest(data);
        break;
      }
      case 'PayNow': {
        this.paymode_payment_service.CreateBundlePayment(data)
        break;
      }
      case 'PayLater': {
        this.paymode_payment_service.commonPayLaterRequest(data);
        break;
      }
      default: {
        break;
      }
    }
  }

  aryaApiKycCalling() {
    const getAryaApiResp = (res, status) => {
      if (status.toLowerCase() == Constants.statusMsg_Success) {
        let kycData: KycDetailsResponse = this.kycResponseMapping(res);
        this.getKYCDetails(kycData);
      }
      else {
        this.isKYCDoneSuccessfully = false;
        this.ekycMandatory = false;
        this.utilityService.popUpDialog('Kindly complete E-KYC/C-KYC to proceed further');
      }
    }


    this.subscription.push(this.utilityApiService.getApiToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          let kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? (res.Data).split(' ')[1] : '';
          let environmentType: string = '';
          environmentType = environment.environment == 'production' ? 'production' : 'uat';
          AJL.aryafns.initMod(kycToken, getAryaApiResp, environmentType);
        }
      }
    }));
  }

  kycResponseMapping(oldKycResp: AryaAPIKycResp): KycDetailsResponse {
    let statusCode: number = 0;
    let alternateAddressPresent: boolean = false;
    alternateAddressPresent = !this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.alternate_address);
    if (((oldKycResp.api_response.statusMessage).toLowerCase() == Constants.statusMsg_Success) || (oldKycResp.api_response.statusMessage == Constants.statusMsg_Manual)) {
      statusCode = 1;
    }
    else {
      statusCode = 0;
    }
    let kycResp: KycDetailsResponse = {
      StatusCode: statusCode,
      StatusMessage: oldKycResp.api_response.statusMessage,
      data: {
        correlationId: oldKycResp.api_response.correlationId,
        customer_type: oldKycResp.api_response.kyc_details.customer_type,
        pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        status: oldKycResp.api_response.status,
        statusMessage: oldKycResp.api_response.statusMessage,
        message: oldKycResp.api_response.message,
        kyc_details: {
          il_kyc_ref_no: oldKycResp.api_response.kyc_details.il_kyc_ref_no,
          certificate_type: oldKycResp.api_response.kyc_details.certificate_type,
          certificate_number: oldKycResp.api_response.kyc_details.certificate_number,
          certificate_type_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_type_poa) ? oldKycResp.api_response.kyc_details.certificate_type_poa : '',
          certificate_number_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_number_poa) ? oldKycResp.api_response.kyc_details.certificate_number_poa : '',
          first_name: oldKycResp.api_response.kyc_details.first_name,
          middle_name: '', //Not present
          last_name: oldKycResp.api_response.kyc_details.last_name,
          full_name: oldKycResp.api_response.kyc_details.full_name,
          gender: oldKycResp.api_response.kyc_details.gender,
          dob: oldKycResp.api_response.kyc_details.dob,
          mobile_number: oldKycResp.api_response.kyc_details.mobile_number,
          email: oldKycResp.api_response.kyc_details.email,
          ckyc_number: oldKycResp.api_response.kyc_details.ckyc_number,
          il_customer_id: '',
          permanent_address: {
            address_line_1: oldKycResp.api_response.kyc_details.permanent_address.address_line_1,
            address_line_2: oldKycResp.api_response.kyc_details.permanent_address.address_line_2,
            pin_code: oldKycResp.api_response.kyc_details.permanent_address.pin_code,
            city: oldKycResp.api_response.kyc_details.permanent_address.city,
            state: oldKycResp.api_response.kyc_details.permanent_address.state,
          },
          alternate_address: {
            address_line_1: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.address_line_1 : '',
            address_line_2: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.address_line_2 : '',
            pin_code: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.pin_code : '',
            city: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.city : '',
            state: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.state : '',
          },
          udf_1: '',
          udf_2: '',
          udf_3: '',
          udf_4: '',
          udf_5: '',
          pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        }
      }
    }
    return kycResp;
  }

  // callKYC() {
  //   this.aryaApiKycCalling();
  // }

  callKYC() {
    this.subscription.push(this.wrapperService.createKYCToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          let kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? res.Data.split("=")[1] : '';
          let source = this.utilityService.identifyEnvironmentType(kycToken);
          let resourceParams = JSON.stringify(source);
          const externalUrlFrame = document.getElementById('externalUrlFrame') as HTMLIFrameElement;
          externalUrlFrame.src = environment.kycUrl + '?rsrc=' + resourceParams;
          $('#externalUrlModal').modal('show');
        }
      },
    }));
  }

  receiveMessage(event) {
    $('#externalUrlModal').modal('hide');
    if (!this.utilityService.isEmptyOrNull(event)) {
      let res = typeof (event.data) == 'string' ? JSON.parse(event.data) : event.data;
      this.getKYCDetails(res);
    }
  }

  getKYCDetails(res: KycDetailsResponse) {
    if (res.StatusCode == Constants.statusCode_success) {
      let tempResponse: KycDetailsResponse = typeof (res) == 'string' ? JSON.parse(res) : res;
      this.ekycMandatory = true;
      this.kycCorrelationID = tempResponse.data.correlationId;
      this.ilKycReferenceNumber = tempResponse.data.hasOwnProperty('kyc_details') ? tempResponse.data.kyc_details.il_kyc_ref_no : '';
      this.cKycId = tempResponse.data.hasOwnProperty('kyc_details') ? tempResponse.data.kyc_details.ckyc_number : '';
      this.isKYCDoneSuccessfully = tempResponse.data.hasOwnProperty('kyc_details') && ((!this.utilityService.isEmptyOrNull(this.cKycId) || !this.utilityService.isEmptyOrNull(this.ilKycReferenceNumber) || !this.utilityService.isEmptyOrNull(this.eKycId))) ? true : false;
      //this.setKYCDetails(tempResponse.data);
      if (res.data.statusMessage == Constants.statusMsg_Manual) {
        this.isOVD = true;
      } else {
        this.patchKYCData(tempResponse);
      }
    } else {
      this.isKYCDoneSuccessfully = false;
      this.ekycMandatory = false;
    }
  }

  patchKYCData(tempResponse) {
    if (tempResponse.data.kyc_details.certificate_type == 'PAN') {
      this.panCardNo = tempResponse.data.kyc_details.certificate_number;
    } else if (tempResponse.data.kyc_details.certificate_type == 'AADHAAR') {
      this.aadharCardNo = tempResponse.data.kyc_details.certificate_number;
    }

    if (!this.renewalHomeSummary.Customerdetail.CustomerName) {
      this.applicantDetail['applicantName'].setValue(tempResponse.data.kyc_details.full_name);
    }
    if (!this.renewalHomeSummary.Customerdetail.DOB) {
      this.applicantDetail['applicantDOB'].setValue(tempResponse.data.kyc_details.dob);
    }
    // if (!this.renewalHomeSummary.Customerdetail.Gender) {
    this.applicantDetail['applicantGender'].setValue(this.returnGender(tempResponse.data.kyc_details.gender));
    // }
    this.kycMobile = tempResponse.data.kyc_details.mobile_number;
    this.kycEmail = tempResponse.data.kyc_details.email;
    this.applicantDetail['KYCapplicantMobile'].setValue(tempResponse.data.kyc_details.mobile_number);
    this.applicantDetail['KYCapplicantEmail'].setValue(tempResponse.data.kyc_details.email);
    this.applicantDetail['applicantAddress1'].setValue(tempResponse.data.kyc_details.permanent_address.address_line_1);
    this.applicantDetail['applicantAddress2'].setValue(tempResponse.data.kyc_details.permanent_address.address_line_2);
    this.applicantDetail['applicantPinCode'].setValue(tempResponse.data.kyc_details.permanent_address.pin_code);
    this.applicantDetail['applicantCity'].setValue(tempResponse.data.kyc_details.permanent_address.city);
    this.applicantDetail['applicantState'].setValue(tempResponse.data.kyc_details.permanent_address.state);
    this.fetchPincode({ value: String(this.applicantDetail['applicantPinCode'].value) }, 'applicant');
  }

  returnGender(value: string): string {
    switch (value) {
      case "M": {
        return "Male";
      }
      case "F": {
        return "Female";
      }
      default: {
        return value;
      }
    }
  }

  openPopup() {
    this.termsAndConditionForm.get('tncAcceptance').enable();
    this.dialog.open(this.ps, { maxHeight: '95vh', maxWidth: '95vw' });
  }

  openAgentPopup() {
    this.termsAndConditionForm.get('agentDeclaration').enable();
    this.dialog.open(this.ad, { maxHeight: '95vh', maxWidth: '95vw' });
  }

  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  close() {
    this.dialog.closeAll();
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

}