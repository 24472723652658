<div class="container-fluid">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="floatdiv floatingedit" *ngIf="scrolling">
                <img class="imgScss" src="../../../../../../assets/images/BBR/ScrollToTop.svg" alt=""
                    (click)="scrollToTop()">
            </div>
            <!--- First Mat Card With Product Name Policy and rest other details -->
            <div class="mainDiv marginbox">
                <div class="row mx-0 col mainDiv">
                    <mat-card class="col-xl-4 col-lg-6 col-md-6 col-sm-6 marginCard col-md-offset-1">
                        <div class="row paddingRow">
                            <div class="col firstDiv">
                                <div class="imgPaddingBottom">
                                    <img src="../../../../../../assets/images/HomeRenewal/home 1.svg" alt="Image">
                                </div>
                                <div class="labelName">
                                    Product Name
                                </div>
                                <div class="valueName titlecase">
                                    {{renewalHomeSummary.revisedProductName ?
                                    (renewalHomeSummary.revisedProductName.toLowerCase()) :
                                    'Complete Home Product'}}
                                </div>
                            </div>
                            <div class="col verticalBorder"></div>
                            <div class="col firstDiv">
                                <div class="imgPaddingBottom">
                                    <img src="../../../../../../assets/images/HomeRenewal/policys.svg" alt="Image">
                                </div>
                                <div class="labelName">
                                    Policy Number
                                </div>
                                <div class="valueName">
                                    {{renewalHomeSummary.policyNo}}
                                </div>
                            </div>
                        </div>
                    </mat-card>


                    <mat-card class="col-xl-4 col-lg-6 col-md-6 col-sm-6 marginCard col-md-offset-1">
                        <div class="row paddingRow">
                            <div class="col firstDiv">
                                <div class="imgPaddingBottom">
                                    <img src="../../../../../../assets/images/HomeRenewal/calendar.svg" alt="Image">
                                </div>
                                <div class="labelName">
                                    Previous Policy Start Date
                                </div>
                                <div class="valueName">
                                    {{utilityService.formattedDate(renewalHomeSummary.policyStartDate)}}
                                </div>
                            </div>
                            <div class="col verticalBorder"></div>
                            <div class="col firstDiv">
                                <div class="imgPaddingBottom">
                                    <img src="../../../../../../assets/images/HomeRenewal/calendar.svg" alt="Image">
                                </div>
                                <div class="labelName">
                                    Previous Policy End Date
                                </div>
                                <div class="valueName">
                                    {{utilityService.formattedDate(renewalHomeSummary.policyEndDate)}}
                                </div>
                            </div>
                        </div>
                    </mat-card>


                    <mat-card class="col-xl-4 col-lg-6 col-md-6 col-sm-6 marginCard">
                        <div class="row paddingRow" *ngIf="renewalHomeSummary.areaOfProperty">
                            <div class="col firstDiv">
                                <div class="imgPaddingBottom">
                                    <img src="../../../../../../assets/images/HomeRenewal/carpetarea.svg" alt="Image">
                                </div>
                                <div class="labelName">
                                    Carpet Area (sq-ft)
                                </div>
                                <div class="valueName">
                                    {{renewalHomeSummary.areaOfProperty}}
                                </div>
                            </div>
                            <div class="col verticalBorder" *ngIf="renewalHomeSummary.areaOfProperty"></div>
                            <div class="col firstDiv" *ngIf="renewalHomeSummary.constructionRate">
                                <div class="imgPaddingBottom">
                                    <img src="../../../../../../assets/images/HomeRenewal/construction.svg" alt="Image">
                                </div>
                                <div class="labelName">
                                    Construction Cost
                                </div>
                                <div class="valueName">
                                    ₹{{utilityService.convertStringtoCurrency(renewalHomeSummary.constructionRate)}}
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>

            <!-- Second Mat Card with KYC Details-->
            <div class="">
                <div class="row mx-0 col KycDiv">
                    <mat-card class="col-xl col-lg col-md-6 col-sm-6 KycCard ">
                        <div class="row KycpaddingRow">

                            <div class="col-xl-3 col-sm-12 leftPartKyc">
                                <div class="labelFirstDiv">
                                    <img src="../../../../../../assets/images/HomeRenewal/home 1.svg" alt="Image">
                                    <span class="KyclabelName">
                                        Complete KYC in 1 minute
                                    </span>
                                </div>
                                <div class="labelName">
                                    You can complete your kyc any of these options
                                </div>
                            </div>

                            <div class="col-xl-9 col-sm-12 KycImage">
                                <div class="col kycImageList">
                                    <div class="imgPaddingBottom">
                                        <img src="../../../../../../assets/images/HomeRenewal/pancard.png" alt="Image">
                                    </div>
                                    <div class="labelName">
                                        Pan Card
                                    </div>
                                </div>
                                <span class="col verticalBorders"></span>
                                <div class="col kycImageList">
                                    <div class="imgPaddingBottom">
                                        <img src="../../../../../../assets/images/HomeRenewal/Aadhar Card.svg"
                                            alt="Image">
                                    </div>
                                    <div class="labelName">
                                        Aadhar Card
                                    </div>
                                </div>
                                <span class="col verticalBorders"></span>
                                <div class="col kycImageList">
                                    <div class="imgPaddingBottom">
                                        <img src="../../../../../../assets/images/HomeRenewal/Driving Licence.svg"
                                            alt="Image">
                                    </div>
                                    <div class="labelName">
                                        Driving License
                                    </div>
                                </div>
                                <span class="col verticalBorders"></span>
                                <div class="col kycImageList">
                                    <div class="imgPaddingBottom">
                                        <img src="../../../../../../assets/images/HomeRenewal/CKYC.svg" alt="Image">
                                    </div>
                                    <div class="labelName">
                                        C KYC Number
                                    </div>
                                </div>


                                <span class="col verticalBorders"></span>
                                <div class="col kycImageList">
                                    <div class="imgPaddingBottom">
                                        <img src="../../../../../../assets/images/HomeRenewal/CIN No.svg" alt="Image">
                                    </div>
                                    <div class="labelName">
                                        CIN
                                    </div>
                                </div>

                                <span class="col verticalBorders"></span>
                                <div class="col kycImageList">
                                    <div class="imgPaddingBottom">
                                        <img src="../../../../../../assets/images/HomeRenewal/Voter ID.svg" alt="Image">
                                    </div>
                                    <div class="labelName">
                                        Voter Id
                                    </div>
                                </div>
                                <span class="col verticalBorders"></span>
                                <div class="col kycImageList">
                                    <div class="imgPaddingBottom">
                                        <img src="../../../../../../assets/images/HomeRenewal/Passport.svg" alt="Image">
                                    </div>
                                    <div class="labelName">
                                        Passport
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row KycpaddingRowSecond">
                            <div class=" col updateKycData leftPartKyc">
                                <!-- If kyc is not completed -->
                                <div class="labelFirstDiv" *ngIf="!isKYCDoneSuccessfully">
                                    <img src="../../../../../../assets/images/verifyIndicator.gif" alt="Image">
                                    <span class="valueName ml-2 titleKYCWidth">
                                        <span class="titleSpanText">
                                            Please complete your KYC verification
                                        </span>
                                    </span>
                                    <button class="secondarybtn_2 ml-4" (click)="callKYC()">Update KYC</button>
                                </div>

                                <!-- If kyc is completed -->
                                <div class="labelFirstDiv" *ngIf="isKYCDoneSuccessfully">
                                    <img src="../../../../../../assets/images/complete.gif" alt="Image">
                                    <span class="valueName ml-2">
                                        KYC Verification successfully completed
                                    </span>
                                </div>

                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>

            <!-- KYC Details Applicant and Benfits Covers-->
            <div class="MainApplicantBenfitDiv">
                <div class="row mx-0 col-xl-7 col-lg-7 col-md-12 col-sm-12">
                    <form [formGroup]="applicantDetailForm" class="widthForm">
                        <mat-card class="KycCard">
                            <div class="row mx-0 KycpaddingRow">
                                <div class="row col leftPartKyc">
                                    <div class="labelFirstDiv">
                                        <div class="col-9">
                                            <img src="../../../../../../assets/images/HomeRenewal/applicantDetails-icon.svg"
                                                alt="Image">
                                            <span class="KyclabelName titleWidth">
                                                <span class="titleSpanText">
                                                    Applicant Details
                                                </span>
                                            </span>
                                        </div>
                                        <div class="editAndSave col-xl-3 col-sm-4 d-flex justify-content-end">
                                            <div class="" *ngIf="getApplicantEdit()"
                                                (click)="toggleApplicantData('Edit')" matTooltip="Edit">
                                                <img src="../../../../../assets/images/HomeRenewal/edit.svg" alt="Image"
                                                    class="editIcon">
                                                <span class="valueName"> Edit </span>
                                            </div>

                                            <div class="" *ngIf="!getApplicantEdit()"
                                                (click)="toggleApplicantData('Save')" matTooltip="Save">
                                                <img src="../../../../../assets/images/HomeRenewal/save.svg" alt="Image"
                                                    class="editIcon">
                                                <span class="valueName"> Save </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- First Div Applicant Detail-->
                            <div class="row mx-0 KycpaddingRowSecond">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ApplicantDetail">

                                    <div class="col-xl-4 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="(applicantDetail['applicantName'].value)">
                                        <mat-label class="labelName" for="">Full Name</mat-label>
                                        <p class="valueName titlecase">
                                            {{applicantDetail['applicantName'].value.toLowerCase()}}</p>
                                    </div>

                                    <div class="col-xl-4 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!getApplicantEdit() && (!applicantDetail['applicantName'].value)">
                                        <mat-label class="labelName" for="">Full Name</mat-label>
                                        <input matInput class="placeholder input-field-email" placeholder="Enter Name"
                                            name="fullname" autocomplete="new-fullname" type="text"
                                            formControlName="applicantName" (keydown)="validateName($event)"
                                            [readonly]="!isKYCDoneSuccessfully && !isOVD">
                                        <div *ngIf="applicantDetail['applicantName'].invalid">
                                            <div class="errorMessage noPadding"
                                                *ngIf="applicantDetail['applicantName'].errors['name']">
                                                Name is invalid
                                            </div>
                                            <div class="errorMessage noPadding"
                                                *ngIf="applicantDetail['applicantName'].errors['required'] && isKYCDoneSuccessfully">
                                                Name is required
                                            </div>
                                            <div class="errorMessage noPadding"
                                                *ngIf="!isKYCDoneSuccessfully && !isOVD">
                                                Please complete KYC to enter data
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="(applicantDetail['applicantDOB'].value)">
                                        <mat-label class="labelName" for="">DOB</mat-label>
                                        <p class="valueName d-flex"> <img class="calendar-icon"
                                                src="../../../../../../assets/images/HomeRenewal/calendars.svg"
                                                alt="Image">
                                            &nbsp;
                                            {{utilityService.formattedDate(applicantDetail['applicantDOB'].value)}}</p>
                                    </div>

                                    <div class="col-xl-4 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!getApplicantEdit() && (!applicantDetail['applicantDOB'].value)">
                                        <mat-label class="labelName">DOB</mat-label>
                                        <input class="placeholder input-field-email" matInput [matDatepicker]="DOB"
                                            formControlName="applicantDOB" [max]="getMinMaxDate('max')"
                                            [min]="getMinMaxDate('min')" (click)="DOB.open()" placeholder="DD/MM/YYYY"
                                            readonly>
                                        <mat-datepicker-toggle matSuffix [for]="DOB">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #DOB touchUi [disabled]="!isKYCDoneSuccessfully && !isOVD">
                                        </mat-datepicker>
                                        <div *ngIf="applicantDetail['applicantDOB'].invalid">
                                            <div class="errorMessage noPadding"
                                                *ngIf="applicantDetail['applicantDOB'].errors['required'] && isKYCDoneSuccessfully">
                                                DOB is required
                                            </div>
                                            <div class="errorMessage noPadding"
                                                *ngIf="!isKYCDoneSuccessfully && !isOVD">
                                                Please complete KYC to enter data
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 ApplicantlabelFirstDiv"
                                        *ngIf="getApplicantEdit()">
                                        <mat-label class="labelName" for="">Gender</mat-label>
                                        <p class="valueName">{{applicantDetail['applicantGender'].value}}</p>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 ApplicantlabelFirstDiv"
                                        *ngIf="!getApplicantEdit()">
                                        <mat-label class="labelName" for="">Gender</mat-label>
                                        <mat-select formControlName="applicantGender" required
                                            class="input-group__input cstm_select mat-select-dropdown"
                                            placeholder="Select Gender" (click)="handleMatSelectClick()"
                                            (blur)="removeMatSelectScrollListener()">
                                            <ng-container *ngFor="let item of genders; let last = last">
                                                <mat-option value={{item}}>
                                                    {{item}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <div *ngIf="applicantDetail['applicantGender'].invalid">
                                            <div class="errorMessage noPadding"
                                                *ngIf="applicantDetail['applicantGender'].errors['required']">
                                                Gender is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ApplicantDetail"
                                    *ngIf="isKYCDoneSuccessfully">
                                    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!isOVD || getApplicantEdit()">
                                        <mat-label class="labelName" for="">Address Line 1 <span
                                                class="mandatory">*</span></mat-label>
                                        <p class="valueName">{{applicantDetail['applicantAddress1'].value}}</p>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!getApplicantEdit() && isOVD">
                                        <mat-label class="labelName" for="">Address Line 1 <span
                                                class="mandatory">*</span></mat-label>
                                        <input matInput class="placeholder input-field-email"
                                            placeholder="Enter Address Line 1" type="text"
                                            formControlName="applicantAddress1">
                                        <div *ngIf="applicantDetail['applicantAddress1'].invalid">
                                            <span class="errorMessage noPadding"
                                                *ngIf="applicantDetail['applicantAddress1'].errors['required']">
                                                Address Line 1 is required
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ApplicantDetail"
                                    *ngIf="isKYCDoneSuccessfully">
                                    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="(!isOVD || getApplicantEdit()) && applicantDetail['applicantAddress2'].value">
                                        <mat-label class="labelName" for="">Address Line 2</mat-label>
                                        <p class="valueName">{{applicantDetail['applicantAddress2'].value}}</p>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!getApplicantEdit() && isOVD">
                                        <mat-label class="labelName" for="">Address Line 2</mat-label>
                                        <input matInput class="placeholder input-field-email"
                                            placeholder="Enter Address Line 2" type="text"
                                            formControlName="applicantAddress2">
                                    </div>
                                </div>

                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ApplicantDetail MailingEditSecondRow"
                                    *ngIf="isKYCDoneSuccessfully">
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv MailingLocationDetail"
                                        *ngIf="!isOVD || getApplicantEdit()">
                                        <mat-label class="labelName" for="">Pin code</mat-label>
                                        <p class="valueName">{{applicantDetail['applicantPinCode'].value}}</p>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!getApplicantEdit() && isOVD">
                                        <mat-label class="labelName" for="">Pin code</mat-label>
                                        <input matInput class="placeholder input-field-email"
                                            placeholder="Enter Pin Code" type="text" formControlName="applicantPinCode"
                                            (keydown)="validatePinCode($event)"
                                            (paste)="utilityService.onPaste($event, false, true)"
                                            (input)="fetchPincode($event.target, 'applicant')" minlength="6"
                                            maxlength="6">

                                        <div *ngIf="applicantDetail['applicantPinCode'].invalid">
                                            <span class="errorMessage noPadding"
                                                *ngIf="applicantDetail['applicantPinCode'].errors['required']">
                                                Pin code is required
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!isOVD || getApplicantEdit()">
                                        <mat-label class="labelName" for="">State</mat-label>
                                        <p class="valueName">{{applicantDetail['applicantState'].value}}</p>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!getApplicantEdit() && isOVD && applicantDetail['applicantState'].value">
                                        <mat-label class="labelName" for="">State</mat-label>
                                        <input matInput class="input-field-email" placeholder="Enter State" type="text"
                                            formControlName="applicantState" readonly>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv MailingLocationDetail"
                                        *ngIf="getApplicantEdit() || (!applicantCityEdit && !isOVD)">
                                        <mat-label class="labelName" for="">City</mat-label>
                                        <p class="valueName">{{applicantDetail['applicantCity'].value}}</p>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv MailingLocationDetail"
                                        *ngIf="applicantCityEdit && !getApplicantEdit() && !isOVD">
                                        <ng-container>
                                            <mat-label class="labelName" for="">City</mat-label>
                                            <mat-select formControlName="applicantCity" required
                                                class="mat-select-dropdown input-group__input cstm_select"
                                                placeholder="Select City" (click)="handleMatSelectClick()"
                                                (blur)="removeMatSelectScrollListener()">
                                                <ng-container
                                                    *ngFor="let item of applicantAddressIds[0]; let last = last">
                                                    <mat-option value={{item.CityName}}>
                                                        {{item.CityName}}
                                                    </mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select>
                                        </ng-container>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv MailingLocationDetail"
                                        *ngIf="!getApplicantEdit() && isOVD && applicantDetail['applicantCity'].value && applicantAddressIds.length > 0">
                                        <ng-container *ngIf="applicantAddressIds[0].length == 1">
                                            <mat-label class="labelName" for="">City</mat-label>
                                            <input matInput class="input-field-email" placeholder="Enter City"
                                                type="text" formControlName="applicantCity" readonly>
                                        </ng-container>
                                        <ng-container *ngIf="applicantAddressIds[0].length == 2">
                                            <mat-label class="labelName" for="">City</mat-label>
                                            <mat-select formControlName="applicantCity" required
                                                class="mat-select-dropdown input-group__input cstm_select"
                                                placeholder="Select City" (click)="handleMatSelectClick()"
                                                (blur)="removeMatSelectScrollListener()">
                                                <ng-container
                                                    *ngFor="let item of applicantAddressIds[0]; let last = last">
                                                    <mat-option value={{item.CityName}}>
                                                        {{item.CityName}}
                                                    </mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-12 ApplicantDetail"
                                    *ngIf="renewalHomeSummary.emailId && renewalHomeSummary.mobileno">
                                    <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv">
                                        <mat-label class="labelName" for="">RN Email ID</mat-label>
                                        <p class="valueName">{{renewalHomeSummary.emailId | lowercase}}</p>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv">
                                        <mat-label class="labelName" for="">
                                            RN Mobile No.</mat-label>
                                        <p class="valueName d-flex"><img class="call-icon"
                                                src="../../../../../../assets/images/HomeRenewal/call.svg" alt="Image">
                                            &nbsp;{{renewalHomeSummary.mobileno}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-12 ApplicantDetail" *ngIf="isKYCDoneSuccessfully">
                                    <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="getApplicantEdit() || this.kycEmail">
                                        <mat-label class="labelName" for="">KYC Email ID</mat-label>
                                        <p class="valueName">{{applicantDetail['KYCapplicantEmail'].value | lowercase}}
                                        </p>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!getApplicantEdit() && (!this.kycEmail || isOVD)">
                                        <mat-label class="labelName" for="">KYC Email ID</mat-label>
                                        <input matInput class="placeholder input-field-email" name="email"
                                            autocomplete="new-email" placeholder="Enter email id" type="text"
                                            formControlName="KYCapplicantEmail">
                                        <div *ngIf="applicantDetail['KYCapplicantEmail'].invalid">
                                            <div class="errorMessage noPadding"
                                                *ngIf="applicantDetail['KYCapplicantEmail'].errors['email']">
                                                KYC Email is invalid
                                            </div>
                                            <div class="errorMessage noPadding"
                                                *ngIf="applicantDetail['KYCapplicantEmail'].errors['required']">
                                                KYC Email is required
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="getApplicantEdit() || this.kycMobile">
                                        <mat-label class="labelName" for="">
                                            KYC Mobile No.</mat-label>
                                        <p class="valueName d-flex"><img class="call-icon"
                                                src="../../../../../../assets/images/HomeRenewal/call.svg" alt="Image">
                                            &nbsp;{{applicantDetail['KYCapplicantMobile'].value}}
                                        </p>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!getApplicantEdit() && (!this.kycMobile || isOVD)">
                                        <mat-label class="labelName" for="">
                                            KYC Mobile No.</mat-label>
                                        <input matInput class="placeholder input-field-email" name="mobile"
                                            autocomplete="new-mobile" placeholder="Enter Mobile No." type="text"
                                            formControlName="KYCapplicantMobile">
                                        <div *ngIf="applicantDetail['KYCapplicantMobile'].invalid">
                                            <div class="errorMessage noPadding"
                                                *ngIf="applicantDetail['KYCapplicantMobile'].errors['pattern']">
                                                KYC Mobile Number is invalid
                                            </div>
                                            <div class="errorMessage noPadding"
                                                *ngIf="applicantDetail['KYCapplicantMobile'].errors['required']">
                                                KYC Mobile Number is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 ApplicantDetail" *ngIf="!disableRenewal && isKYCDoneSuccessfully">
                                    <div class="col-12 ApplicantlabelFirstDiv">
                                        <mat-label class="labelName" for="">Choose appropriate set of Email ID and
                                            Mobile
                                            No.</mat-label>
                                        <div class="pt-2 radioDiv">
                                            <div class="radioOption">
                                                <input type="radio" name="renewalKYC" value="Renewal" class="radioSize"
                                                    (click)="changeChooseData()" checked>
                                                <label class="radioLabelName Renewal"
                                                    [ngClass]="{'valueName': !setBold}">Renewal</label>
                                            </div>
                                            <div class="radioPaddingLeft radioOption">
                                                <input type="radio" name="renewalKYC" value="KYC" class="radioSize"
                                                    (click)="changeChooseData()">
                                                <label class="radioLabelName KYC"
                                                    [ngClass]="{'valueName': setBold}">KYC</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </mat-card>
                    </form>
                    <!--- Second Div Risk Location-->
                    <form [formGroup]="mailingDetailForm" class="widthForm">
                        <mat-card class="KycCard">
                            <div class="row mx-0 KycpaddingRow">
                                <div class="row col leftPartKyc">
                                    <div class="labelFirstDiv">
                                        <div class="col-9">
                                            <img src="../../../../../../assets/images/HomeRenewal/location.svg"
                                                alt="Image">
                                            <span class="KyclabelName">
                                                Risk Location
                                            </span>
                                        </div>
                                        <div class="editAndSave col-3 d-flex justify-content-end">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-0 KycpaddingRowSecond">

                                <div class="col-12 ApplicantDetail">
                                    <div class="col-12 ApplicantlabelFirstDiv">
                                        <mat-label class="labelName" for="">Address</mat-label>
                                        <p class="valueName">
                                            {{utilityService.removeHyphens(this.renewalHomeSummary.riskAddressLine1)
                                            + ' ' + this.renewalHomeSummary.riskAddressLine2 + ' ' +
                                            this.renewalHomeSummary.riskCity + ' ' + this.renewalHomeSummary.riskState +
                                            ' ' + this.renewalHomeSummary.riskPinCode }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 checkBoxCheckList">
                                    <hr>
                                    <mat-checkbox class="cstmCheckbox" formControlName="isLocationSame"
                                        (change)="isSameLocation($event.checked)">
                                        Is mailing location same as risk location
                                    </mat-checkbox>
                                </div>
                            </div>
                        </mat-card>


                        <!-- Third Div MAiling Location-->
                        <mat-card class="KycCard" *ngIf="!mailingDetail['isLocationSame'].value">
                            <div class="row mx-0 KycpaddingRow">
                                <div class="row col leftPartKyc">
                                    <div class="labelFirstDiv">
                                        <div class="col-9">
                                            <img src="../../../../../../assets/images/HomeRenewal/location.svg"
                                                alt="Image">
                                            <span class="KyclabelName titleWidth">
                                                <span class="titleSpanText">
                                                    Mailing Location
                                                </span>
                                            </span>
                                        </div>
                                        <div class="editAndSave col-xl-3 col-sm-4 d-flex justify-content-end">
                                            <div *ngIf="getMailingEdit()" (click)="toggleMailingData('Edit')"
                                                matTooltip="Edit">
                                                <img src="../../../../../assets/images/HomeRenewal/edit.svg" alt="Image"
                                                    class="editIcon">
                                                <span class="valueName"> Edit </span>
                                            </div>

                                            <div *ngIf="!getMailingEdit()" (click)="toggleMailingData('Save')"
                                                matTooltip="Save">
                                                <img src="../../../../../assets/images/HomeRenewal/save.svg" alt="Image"
                                                    class="editIcon">
                                                <span class="valueName"> Save </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-0 KycpaddingRowSecond">

                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ApplicantDetail">

                                    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="getMailingEdit()">
                                        <mat-label class="labelName" for="">Address Line 1 <span
                                                class="mandatory">*</span></mat-label>
                                        <p class="valueName">{{mailingDetail['mailingAddress1'].value}}</p>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!getMailingEdit()">
                                        <mat-label class="labelName" for="">Address Line 1 <span
                                                class="mandatory">*</span></mat-label>
                                        <input matInput class="placeholder input-field-email"
                                            placeholder="Enter Address Line 1" type="text"
                                            formControlName="mailingAddress1">
                                        <div *ngIf="mailingDetail['mailingAddress1'].invalid">
                                            <span class="errorMessage noPadding"
                                                *ngIf="mailingDetail['mailingAddress1'].errors['required']">
                                                Address Line 1 is required
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ApplicantDetail">
                                    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="getMailingEdit() && mailingDetail['mailingAddress2'].value">
                                        <mat-label class="labelName" for="">Address Line 2</mat-label>
                                        <p class="valueName">{{mailingDetail['mailingAddress2'].value}}</p>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 ApplicantlabelFirstDiv"
                                        *ngIf="!getMailingEdit()">
                                        <mat-label class="labelName" for="">Address Line 2</mat-label>
                                        <input matInput class="placeholder input-field-email"
                                            placeholder="Enter Address Line 2" type="text"
                                            formControlName="mailingAddress2">
                                    </div>
                                </div>

                                <div
                                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ApplicantDetail MailingEditSecondRow">
                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 ApplicantlabelFirstDiv MailingLocationDetail"
                                        *ngIf="getMailingEdit()">
                                        <mat-label class="labelName" for="">Pin code</mat-label>
                                        <p class="valueName">{{mailingDetail['mailingPinCode'].value}}</p>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 ApplicantlabelFirstDiv"
                                        *ngIf="!getMailingEdit()">
                                        <mat-label class="labelName" for="">Pin code</mat-label>
                                        <input matInput class="placeholder input-field-email"
                                            placeholder="Enter Pin Code" type="text" formControlName="mailingPinCode"
                                            (keydown)="validatePinCode($event)"
                                            (paste)="utilityService.onPaste($event, false, true)"
                                            (input)="fetchPincode($event.target, 'mailing')" minlength="6"
                                            maxlength="6">

                                        <div *ngIf="mailingDetail['mailingPinCode'].invalid">
                                            <span class="errorMessage noPadding"
                                                *ngIf="mailingDetail['mailingPinCode'].errors['required']">
                                                Pin code is required
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 ApplicantlabelFirstDiv"
                                        *ngIf="getMailingEdit()">
                                        <mat-label class="labelName" for="">State</mat-label>
                                        <p class="valueName">{{mailingDetail['mailingState'].value}}</p>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 ApplicantlabelFirstDiv"
                                        *ngIf="!getMailingEdit() && mailingDetail['mailingState'].value">
                                        <mat-label class="labelName" for="">State</mat-label>
                                        <input matInput class="input-field-email" placeholder="Enter State" type="text"
                                            formControlName="mailingState" readonly>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 ApplicantlabelFirstDiv MailingLocationDetail"
                                        *ngIf="getMailingEdit()">
                                        <mat-label class="labelName" for="">City</mat-label>
                                        <p class="valueName">{{mailingDetail['mailingCity'].value}}</p>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 ApplicantlabelFirstDiv MailingLocationDetail"
                                        *ngIf="!getMailingEdit() && mailingDetail['mailingCity'].value && mailingAddressIds.length > 0">
                                        <ng-container *ngIf="mailingAddressIds[0].length == 1">
                                            <mat-label class="labelName" for="">City</mat-label>
                                            <input matInput class="input-field-email" placeholder="Enter City"
                                                type="text" formControlName="mailingCity" readonly>
                                        </ng-container>
                                        <ng-container *ngIf="mailingAddressIds[0].length == 2">
                                            <mat-label class="labelName" for="">City</mat-label>
                                            <mat-select formControlName="mailingCity" required
                                                class="mat-select-dropdown input-group__input cstm_select"
                                                placeholder="Select City" (click)="handleMatSelectClick()"
                                                (blur)="removeMatSelectScrollListener()">
                                                <ng-container
                                                    *ngFor="let item of mailingAddressIds[0]; let last = last">
                                                    <mat-option value={{item.CityName}}>
                                                        {{item.CityName}}
                                                    </mat-option>
                                                    <mat-divider *ngIf="!last"></mat-divider>
                                                </ng-container>
                                            </mat-select>
                                        </ng-container>
                                        <div *ngIf="mailingAddressIds[0].length == 2 && isCitySame('mailing')">
                                            <span class="errorMessage noPadding">
                                                City is required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </form>
                </div>

                <!-- Benefit Cover Design-->
                <div class="row mx-0 col-xl-5 col-lg-5 col-md-12 col-sm-12 KycDetails KycApplicantDetails">
                    <!-- Benefit Cover-->
                    <mat-card class="col-xl-12 col-lg-12 col-md col-sm KycCard BenfitCoverHeight">
                        <div class="row KycpaddingRow">
                            <div class="col leftPartKyc">
                                <div class="labelFirstDiv">
                                    <img src="../../../../../../assets/images/HomeRenewal/benefitcovers.svg"
                                        alt="Image">
                                    <span class="KyclabelName">
                                        Coverage under the policy
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 p-0">
                            <mat-accordion class="accordion">
                                <mat-expansion-panel *ngFor="let ds of renewalHomeSummary.coverDetails"
                                    [disabled]="!ds.coverDescription">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{ds.coverName}}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div>
                                        <p>{{ds.coverDescription}}</p>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>

                        </div>
                    </mat-card>

                    <!-- Personal Accident -->
                    <mat-card class="KycCard widthForm"
                        *ngIf="this.renewalHomeSummary.personalAccidentRequired == 'YES'">
                        <div class="row mx-0 KycpaddingRow">
                            <div class="row col leftPartKyc">
                                <div class="labelFirstDiv">
                                    <div class="col-12">
                                        <img src="../../../../../../assets/images/HomeRenewal/applicantDetails-icon.svg"
                                            alt="Image">
                                        <span class="KyclabelName">
                                            Personal Accident
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0 KycpaddingRowSecond paPadding">
                            <div class="col-12 ApplicantDetail">
                                <div class="col paBigCol ApplicantlabelFirstDiv">
                                    <mat-label class="labelName" for="">Insured Name</mat-label>
                                    <p class="valueName titlecase">
                                        {{renewalHomeSummary.personalAccidentInsuredName.toLowerCase()}}
                                    </p>
                                </div>
                                <div class="col paSmallCol ApplicantlabelFirstDiv">
                                    <mat-label class="labelName" for="">Insured Gender</mat-label>
                                    <p class="valueName titlecase">
                                        {{renewalHomeSummary.personalAccidentGender.toLowerCase()}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 ApplicantDetail">
                                <div class="col paBigCol ApplicantlabelFirstDiv">
                                    <mat-label class="labelName" for="">Insured Relationship with Applicant</mat-label>
                                    <p class="valueName titlecase">
                                        {{renewalHomeSummary.personalAccidentRelationshipWithApplicant.toLowerCase()}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 ApplicantDetail">
                                <div class="col paBigCol ApplicantlabelFirstDiv">
                                    <mat-label class="labelName" for="">Nominee Name</mat-label>
                                    <p class="valueName titlecase">
                                        {{renewalHomeSummary.personalAccidentNomineeName.toLowerCase()}}
                                    </p>
                                </div>
                                <div class="col paSmallCol ApplicantlabelFirstDiv">
                                    <mat-label class="labelName" for="">Nominee DOB</mat-label>
                                    <p class="valueName">
                                        {{utilityService.formattedDate(renewalHomeSummary.personalAccidentDob)}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 ApplicantDetail">
                                <div class="col paBigCol ApplicantlabelFirstDiv">
                                    <mat-label class="labelName" for="">Nominee Relationship with Insured</mat-label>
                                    <p class="valueName titlecase">
                                        {{renewalHomeSummary.personalAccidentRelationshipWithInsured.toLowerCase()}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </mat-card>

                </div>
            </div>

            <div class="row mx-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 termsandconditions KycDiv">
                <mat-card class="col-xl col-lg col-md-12 col-sm-12 KycCard">
                    <div class="row KycpaddingRow">
                        <div class="row col leftPartKyc">
                            <div class="col labelFirstDiv">
                                <img src="../../../../../../assets/images/HomeRenewal/HtNo.svg" alt="Image">
                                <span class="KyclabelName">
                                    Terms & Conditions
                                </span>
                            </div>
                        </div>
                    </div>
                    <form [formGroup]="termsAndConditionForm">
                        <div class="row KycpaddingRowSecond fieldsdiv">
                            <div class="row col customDiv">
                                <div class="col-sm-12 customCheckslist checkBoxCheckList">
                                    <ul>
                                        <li>
                                            <table class="declarationTable">
                                                <caption class="hidden-caption">Details</caption>
                                                <th class="hidden-caption">Declarations</th>
                                                <td class="hidden-caption">Term & Condition</td>
                                                <tr>
                                                    <td class="DecalartionsMAinRow">
                                                        <mat-checkbox id="checkbox2" class="cstmCheckbox"
                                                            formControlName="agentDeclaration">
                                                        </mat-checkbox>
                                                        <span class="declarationsmessage titlecase">I
                                                            {{agentName.toLowerCase()}} accept <a>
                                                                <span class="custom-anchor"
                                                                    (click)="openAgentPopup()">Agent's
                                                                    Declaration</span></a></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div *ngIf="!checkBox['agentDeclaration'].value">
                                                            <span class="errorMessage">Please read and accept
                                                                the agent declaration</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </li>

                                    </ul>
                                </div>
                                <div class="col-sm-12 customCheckslist checkBoxCheckList">
                                    <ul>
                                        <li>
                                            <table class="declarationTable">
                                                <caption class="hidden-caption">Details</caption>
                                                <th class="hidden-caption">Declarations</th>
                                                <td class="hidden-caption">Term & Condition</td>
                                                <tr>
                                                    <td class="DecalartionsMAinRow">
                                                        <mat-checkbox id="checkbox2" class="cstmCheckbox"
                                                            formControlName="tncAcceptance">
                                                        </mat-checkbox>
                                                        <span class="declarationsmessage">I hereby declare that I have
                                                            understood the nature of questions... <a><span
                                                                    class="custom-anchor" (click)="openPopup()">Read
                                                                    More</span></a></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div *ngIf="!checkBox['tncAcceptance'].value">
                                                            <span class="errorMessage">Please read and accept
                                                                the declarations</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card>
            </div>
        </div>
    </div>
</div>

<app-custom-footer [normalButton]="false" [renewButton]="false"
    [isKYCDoneSuccessfully]="isKYCDoneSuccessfully ? true: false" [buttonData]="[
        {'label': 'Pay Now', 'type': 'secondary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': isKYCDoneSuccessfully?false:true },
        {'label': 'Pay Later', 'type': 'secondary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': isKYCDoneSuccessfully?false:true },
        {'label': 'Payment Link', 'type': 'primary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': isKYCDoneSuccessfully?false:true },
        {'label': 'Choose Payment', 'type': 'primary', 'source': 'summary', 'id': 'f5','disabled': false , 'hide': isKYCDoneSuccessfully?false:true },
    ]" (buttonClicked)="buttonSelection($event)">
</app-custom-footer>

<!-- Kyc Modal -->
<div class="modal fade kycPopupScreen" id="externalUrlModal" tabindex="-1" role="dialog"
    aria-labelledby="externalUrlModalLabel" aria-hidden="true">
    <div class="modal-dialog my-0" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <iframe id="externalUrlFrame" width="100%" height="600px" title=""></iframe>
            </div>
        </div>
    </div>
</div>

<ng-template #declarations>
    <div class="declarationPopup">
        <div class="declarationHeader sticky-top">
            <div class="col closeDiv">
                <span class="summaryHead mb-0">Declarations</span>
                <div class="closebtn" (click)="close()">
                    <img src="../../../assets/images/cross.png" alt="close" class="closeIcon">
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="matDeclarationPopup">
            <div *ngFor="let section of popupSection.section">
                <div class="container custom-dialog">
                    <div class="popupHeading">
                        <span>{{section.title}}</span>
                    </div>
                    <div class="dialog-content">
                        <p *ngFor="let i of section.content">
                            {{i.line}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #agent>
    <div class="declarationPopup">
        <div class="declarationHeader sticky-top">
            <div class="col closeDiv">
                <span class="summaryHead mb-0">Agent Declaration</span>
                <div class="closebtn" (click)="close()">
                    <img src="../../../assets/images/cross.png" alt="close" class="closeIcon">
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="matDeclarationPopup">
            <div class="container custom-dialog">
                <div class="dialog-content">
                    <p>
                        I, <span class="titlecase">{{agentName.toLowerCase()}}</span> in my capacity as an Insurance
                        Advisor/ Specified Person of the Corporate
                        Agent/Authorized employee of the Broker/Relationship Officer, do hereby declare that I have
                        explained all the
                        contents of this Proposal Form, including the nature of the questions contained in this Proposal
                        Form to the
                        Proposer including statement(s), information and response(s) submitted by him/her in this
                        Proposal Form to
                        questions contained herein or any details sought herein will form the basis of the Contract of
                        Insurance
                        between the Company and the Proposer, if this Proposal is accepted by the Company for issuance
                        of the Policy.
                    </p>
                    <p>
                        I have further explained that if any untrue statement(s)/ information/response(s) is/are
                        contained in this
                        Proposal Form/including addendum(s), affidavits, statements, submissions, furnished/to be
                        furnished, the
                        Company shall have the right to cancel the policy at its discretion.
                    </p>
                    <p>
                        Further, this declaration does not confirm issuance of policy or assumption of risk thereof.
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
