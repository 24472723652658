
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, fromEvent } from 'rxjs';
import { HealthDataStorage, MyPolicyDetails } from 'src/app/modules/health/health.modal';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { Constants } from 'src/app/shared/constants/constants';
import { UWStatusEnum } from 'src/app/shared/enums/healthEnums';
import { PolicyType, TravelPolicyType, HomePolicyType, HealthPolicyType } from 'src/app/shared/enums/pendingPaymentEnums';
import { popupType, popupImgPath, popupHeader, popupButton, popupButtonAction } from 'src/app/shared/enums/popupEnums';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { ProposalStatusResponsePayload, FetchPoliciesRequestPayload, FetchPropToModifyResponse, FetchPoliciesResponsePayload, DownloadCouponRequestPayload, ProposalStatusRequestPayload } from 'src/app/shared/interface/mypolicy';
import { DownloadService } from 'src/app/shared/services/download.service';
import { MyPolicyService } from 'src/app/shared/services/mypolicy.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { PopupModal, EditPortabilityPopupModal } from 'src/app/layout/popups/popup.modal';
import { LoaderService } from 'src/app/layout/loader/loader.service';
import { Component, OnInit, NgZone, ViewChildren, QueryList, Renderer2, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { PopupComponent } from 'src/app/layout/popups/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { HealthClaimService } from '../health-claim/services/health-claim.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EndorsedPolicyRequest, NewEndorsedPolicyResponse } from 'src/app/shared/interface/endorsement';
import { EndorsementService } from 'src/app/shared/services/endorsement.service';

@Component({
  selector: 'app-my-policy',
  templateUrl: './my-policy.component.html',
  styleUrls: ['./my-policy.component.scss']
})
export class MyPolicyComponent implements OnInit {
  private interval: any;
  scrolling: boolean = false;
  isSearchVisible: boolean = false;
  productTypeList: { productName: string; productCode: number }[];
  toggleValue: 'table' | 'card' = 'card';
  policyFilter: string[] = [];
  policyFilterList = ['Policy', 'Cancelled', 'Pending for approval'];
  inputValue: string = '';
  showCancel: boolean = false;
  searchPlaceHolder: string;
  isSelected: boolean = false;
  isSubmitted: boolean = false;
  reportList: any = [];
  selectList = [];
  i: number = 0;
  phText: string[] = ['Search for Proposal Number',
    'Search for Customer Name',
    'Search for Policy Number'];
  filteredData: MyPolicyDetails[] = [];
  @ViewChild('iframe') iframe: TemplateRef<any>;
  iframeUrl: SafeResourceUrl | null = null;

  tableBtnInfo = [
    // {
    //   label: 'Download Proposal',
    //   imgPath: '../../../../../../../assets/images/BBR/downloadProposal.svg',
    //   tooltip: 'Download Proposal',
    //   expression: 'data.Status && (data.PolicySubType != 37)  && data.PolicyType != 4 && data.PolicySubType !=6'
    // },
    {
      label: 'UW Status',
      imgPath: '../../../../../../../assets/images/BBR/UWStatus.svg',
      tooltip: 'UW Status',
      expression: 'data.PolicyType == 2 && (data.Status.toUpperCase().indexOf("PENDING") > -1 && data.Status.toUpperCase() != "MEDICAL TEST PENDING" && data.Status.toUpperCase() != "PENDING FOR COUNTER OFFER ACCEPTANCE")'
    }, {
      label: 'Upload Document',
      imgPath: '../../../../../../../assets/images/BBR/Upload.svg',
      tooltip: 'Upload',
      expression: 'data.PolicyType == 2 && (data.TransFor == "R" || data.NRIDiscount) && data.PolicySubType != 23 && (data.HtStatus.toUpperCase() !== "SUCCESS" && data.HtStatus.toUpperCase() !== "APPROVED")' //&& data.HtStatus.toUpperCase() != "REJECTED"
    },
    // {
    //   label: 'Download Policy',
    //   imgPath: '../../../../../../../assets/images/BBR/downloadProposal.svg',
    //   tooltip: 'Download Policy',
    //   expression: "(data.Status.toUpperCase() == 'POLICY' || data.Status.toUpperCase() == 'INSTACR') && (data.PFPolicyNo != 0 || data.PFPolicyNo != '')"
    // }, 
    {
      label: 'Coupons',
      imgPath: '../../../../../../../assets/images/BBR/Coupon.svg',
      tooltip: 'Coupons',
      expression: "!data.IsBancsPolicy && data.PolicyType == 2 && data.Status.toUpperCase() == 'POLICY' && !([21, 22, 23, 24, 25, 37, 38, 45, 46, 47].includes(data.PolicySubType))"
    }, {
      label: 'Edit Portability Details',
      imgPath: '../../../../../../../assets/images/BBR/EditPortability.svg',
      tooltip: 'Edit Portability Details',
      expression: 'data.AllowEdit && [33, 40, 42, 45, 47].includes(data.PolicySubType) && (data.HtStatus.toUpperCase() !== "SUCCESS" && data.HtStatus.toUpperCase() !== "APPROVED")' // && data.HtStatus.toUpperCase() != "REJECTED"
    }, {
      label: 'Endorse Policy',
      imgPath: '../../../assets/images/myPolicy/endorsed_orange.svg',
      tooltip: 'Endorse Policy',
      expression: '([42, 33, 34, 20, 22, 40, 45].includes(data.PolicySubType)) && (data.PFPolicyNo != "")'
    }
  ];

  cardBtnInfo = [
    // {
    //   label: 'Download Proposal',
    //   imgPath: '../../../assets/images/myPolicy/downloadProposal.svg',
    //   expression: 'data.Status && (data.PolicySubType != 37)  && data.PolicyType != 4 && data.PolicySubType !=6'
    // },
    {
      label: 'UW Status',
      imgPath: '../../../assets/images/myPolicy/uwStatus.svg',
      expression: 'data.PolicyType == 2 && (data.Status.toUpperCase().indexOf("PENDING") > -1 && data.Status.toUpperCase() != "MEDICAL TEST PENDING" && data.Status.toUpperCase() != "PENDING FOR COUNTER OFFER ACCEPTANCE")'
    }, {
      label: 'Upload Document',
      imgPath: '../../../assets/images/myPolicy/upload.svg',
      expression: 'data.PolicyType == 2 && (data.TransFor == "R" || data.NRIDiscount) && data.PolicySubType != 23 && (data.HtStatus.toUpperCase() !== "SUCCESS" && data.HtStatus.toUpperCase() !== "APPROVED")' //&& data.HtStatus.toUpperCase() != "REJECTED"

    },
    // {
    //   label: 'Download Policy',
    //   imgPath: '../../../assets/images/myPolicy/downloadPolicy.svg',
    //   expression: "(data.Status.toUpperCase() == 'POLICY' || data.Status.toUpperCase() == 'INSTACR') && (data.PFPolicyNo != 0 || data.PFPolicyNo != '')"
    // }, 
    {
      label: 'Coupons',
      imgPath: '../../../assets/images/myPolicy/Coupon.svg',
      expression: "!data.IsBancsPolicy && data.PolicyType == 2 && data.Status.toUpperCase() == 'POLICY' && !([21, 22, 23, 24, 25, 37, 38, 45, 46, 47].includes(data.PolicySubType))"
    }, {
      label: 'Edit Portability Details',
      imgPath: '../../../assets/images/myPolicy/editPortability.svg',
      expression: 'data.AllowEdit && [33, 40, 42, 45, 47].includes(data.PolicySubType) && (data.HtStatus.toUpperCase() !== "SUCCESS" && data.HtStatus.toUpperCase() !== "APPROVED")' // && data.HtStatus.toUpperCase() != "REJECTED"
    }, {
      label: 'Endorse Policy',
      imgPath: '../../../assets/images/myPolicy/endorsed_blue.svg',
      expression: '(data.PolicySubType == 42 || data.PolicySubType == 33 || data.PolicySubType == 34 || data.PolicySubType == 20 || data.PolicySubType == 22 || data.PolicySubType == 40 || data.PolicySubType == 45) && (data.PFPolicyNo != "")'
    }
  ];

  displayCol = [
    {
      columnDef: 'CustomerName',
      header: 'Customer Name',
      cell: (element: any) => `${element.CustomerName}`,
    },
    {
      columnDef: 'PFPolicyNo',
      header: 'Policy Number',
      cell: (element: any) => `${element.PFPolicyNo}`,
      expression: "(data.Status.toUpperCase() == 'POLICY' || data.Status.toUpperCase() == 'INSTACR') && (data.PFPolicyNo != 0 || data.PFPolicyNo != '')",
      label: 'Download Policy'
    },
    {
      columnDef: 'ProposalNumber',
      header: 'Proposal Number',
      cell: (element: any) => `${element.ProposalNumber}`,
      expression: 'data.Status && (data.PolicySubType != 37) && data.PolicyType != 4 && data.PolicySubType !=6',
      label: 'Download Proposal'
    },
    {
      columnDef: 'StartDate',
      header: 'Start Date',
      cell: (element: any) => `${element.StartDate}`,
    },
    {
      columnDef: 'EndDate',
      header: 'End Date',
      cell: (element: any) => `${element.EndDate}`,
    },
    {
      columnDef: 'TransOn',
      header: 'Transaction Date',
      cell: (element: any) => `${element.TransOn}`,
    },
    {
      columnDef: 'ComboPremium',
      header: 'Total Premium',
      cell: (element: any) => `${element.TotalPremium}`,
    },
    {
      columnDef: 'Action',
      header: 'Action',
      cell: '',
    },
    {
      columnDef: 'Status',
      header: 'Policy Status',
      cell: (element: any) => `${element.Status}`,
    },
    {
      columnDef: 'ProposalStatus',
      header: 'UW Status',
      cell: (element: any) => `${element.ProposalStatus}`,
    },
    {
      columnDef: 'HtNumber',
      header: 'HT Number',
      cell: (element: any) => `${element.HtNumber}`,
    },
    {
      columnDef: 'HtStatus',
      header: 'Nysa HT Status',
      // expression: '(data.TransFor == "R" || data.NRIDiscount) && !this.utilityService.isEmptyOrNull(data.HtStatus)',
      cell: (element: any) => `${element.HtStatus}`,
    },
    {
      columnDef: 'HtRemark',
      header: 'QC Remarks',
      // expression: '!this.utilityService.isEmptyOrNull(data.HtRemark)',
      cell: (element: any) => `${element.HtRemark}`,
    },
  ]

  ogProposalCol = {
    columnDef: 'ProposalNumber',
    header: 'Proposal Number',
    cell: (element: any) => `${element.ProposalNumber}`,
    expression: 'data.Status && (data.PolicySubType != 37) && data.PolicyType != 4 && data.PolicySubType !=6',
    label: 'Download Proposal'
  };
  changeProposalCol = {
    columnDef: 'BundleProposalNo',
    header: 'Proposal Number',
    cell: (element: any) => `${element.BundleProposalNo}`,
    expression: 'No Need',
    label: 'Download Proposal',
    col1: 'ProposalNumber',
    col1Expression: "{'EPolicyID': list['EPolicyID'], 'Status': list['Status'], 'ProposalStatus': list['ProposalStatus'], 'CUUWSTATUS': list['CUUWSTATUS'], 'PolicyType': list['PolicyType'], 'PolicySubType': list['PolicySubType'], 'PolicyID': list['PolicyID'], 'IsBancsPolicy': list['IsBancsPolicy'], 'BundleID': list['BundlePolicyID'], 'ProposalNumber': list['ProposalNumber'], 'PFPolicyNo': list['PFPolicyNo']}",
    col2: 'BundleProposalNumber',
    col2Expression: "{'EPolicyID': list['BundleEPolicyID'], 'Status': list['Status'], 'ProposalStatus': list['ProposalStatus'], 'CUUWSTATUS': list['CUUWSTATUS'], 'PolicyType': list['PolicyType'], 'PolicySubType': list['PolicySubType'], 'PolicyID': list['PolicyID'], 'IsBancsPolicy': list['IsBancsPolicy'], 'BundleID': list['BundlePolicyID'], 'ProposalNumber': list['BundleProposalNumber'], 'PFPolicyNo': list['BundlePolicyNumber']}",
    tooltip1: 'Base Proposal',
    tooltip2: 'Super Topup Proposal'
  };
  ogPolicyCol = {
    columnDef: 'PFPolicyNo',
    header: 'Policy Number',
    cell: (element: any) => `${element.PFPolicyNo}`,
    expression: "(data.Status.toUpperCase() == 'POLICY' || data.Status.toUpperCase() == 'INSTACR') && (data.PFPolicyNo != 0 || data.PFPolicyNo != '')",
    label: 'Download Policy'
  };
  changePolicyCol = {
    columnDef: 'BundlePolicyNo',
    header: 'Policy Number',
    cell: (element: any) => `${element.BundleProposalNo}`,
    expression: 'No Need',
    label: 'Download Policy',
    col1: 'PFPolicyNo',
    col1Expression: "{'EPolicyID': list['EPolicyID'], 'Status': list['Status'], 'ProposalStatus': list['ProposalStatus'], 'CUUWSTATUS': list['CUUWSTATUS'], 'PolicyType': list['PolicyType'], 'PolicySubType': list['PolicySubType'], 'PolicyID': list['PolicyID'], 'IsBancsPolicy': list['IsBancsPolicy'], 'BundleID': list['BundlePolicyID'], 'ProposalNumber': list['ProposalNumber'], 'PFPolicyNo': list['PFPolicyNo']}",
    col2: 'BundlePolicyNumber',
    col2Expression: "{'EPolicyID': list['BundleEPolicyID'], 'Status': list['Status'], 'ProposalStatus': list['ProposalStatus'], 'CUUWSTATUS': list['CUUWSTATUS'], 'PolicyType': list['PolicyType'], 'PolicySubType': list['PolicySubType'], 'PolicyID': list['PolicyID'], 'IsBancsPolicy': list['IsBancsPolicy'], 'BundleID': list['BundlePolicyID'], 'ProposalNumber': list['BundleProposalNumber'], 'PFPolicyNo': list['BundlePolicyNumber']}",
    tooltip1: 'Base Policy',
    tooltip2: 'Super Topup Policy'
  };

  mypolicyForm: FormGroup;
  subscription: Subscription[] = [];
  HEROES: { id: number; name: string; }[];
  finalDetails: any = [];
  policyDetails: any = [];
  UWStatus: UWStatusEnum;
  travelList: { productName: string; productCode: number; isProductMapped: any; }[];
  formSubmitted: boolean = false;
  selectedProduct: string = 'Health';
  productCode: number = 42;
  productCodeTravel: number = 3;
  productList = [];
  productDetails = [];
  policyType: number;
  minMax: { maxDate: Date; minDate: Date; };
  underWritterStatus: ProposalStatusResponsePayload[];
  healthFormData: HealthDataStorage;
  policyCount: number;
  myPolicyStartDate: Date;
  myPolicyEndDate: Date;
  myPolicyProduct: string;

  constructor(
    private popupService: PopupService,
    private myPolicyService: MyPolicyService,
    private downloadService: DownloadService,
    private storageService: StorageService,
    private router: Router,
    public utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private healthDataStorageService: HealthDataStorageService,
    private healthMasterService: HealthMasterService,
    private spinner: LoaderService,
    private zone: NgZone,
    private renderer: Renderer2,
    private endorsmentService: EndorsementService,
    private domSanitization: DomSanitizer,
    private endorsementService: HealthClaimService,
    private agentV1Service: AgentV1Service,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.subscription.push(fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();
      }));
    this.myPolicyService.editPortabilityFlag$.subscribe((value) => {
      this.searchDetails();
    });
    this.popupService.onLoadMyPolicyAPi$.subscribe(data => {
      this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
      this.getProductList();
      this.createForm();
      this.setCalendarDates();
      this.setProductType();
      this.searchDetails();
      this.setPlaceHolderText();
      this.interval = setInterval(() => { this.setPlaceHolderText(); }, 2000);
    })
    // this.forQcFlow();
    this.subscription.push(fromEvent(window, 'message')
      .subscribe((event) => {
        this.receiveEndorsedData(event);
      }));
  }

  productCodeDetails = [
    { productName: 'Elevate', productCode: 45 },
    { productName: 'Health AdvantEdge Plus', productCode: 42 },
    { productName: 'Health Elite Plus', productCode: 33 },
    { productName: 'Health Booster', productCode: 20 },
    { productName: 'Arogya Sanjeevani', productCode: 22 },
    { productName: 'Golden Shield', productCode: 40 },
    { productName: 'Max Protect', productCode: 34 },
    { productName: 'Criti Shield', productCode: 38 },
    { productName: 'Family Shield', productCode: 23 },
  ];

  receiveEndorsedData(event) {
    if (!this.utilityService.isEmptyOrNull(event.data) && event.data?.source != 'react-devtools-content-script') {
      let endorsedPolicyDetails: NewEndorsedPolicyResponse = JSON.parse(event.data);
      this.dialog.closeAll();
      const product = this.productCodeDetails.find(
        (item) => item.productName.toLowerCase() === endorsedPolicyDetails.productName.toLowerCase()
      );
      let productCode = product ? product.productCode : null;
      if (endorsedPolicyDetails.isSuccess) {
        let payload: EndorsedPolicyRequest = {
          "OldPolicyNo": endorsedPolicyDetails.OldPolicyNumber,
          "NewPolicyNo": endorsedPolicyDetails.newPolicyNumber,
          "EndorsementType": endorsedPolicyDetails.EndorsementType,
          "CustomerName": endorsedPolicyDetails.customerName,
          "Customer_id": +endorsedPolicyDetails.customerId,
          "Policy_Type": 2,
          "Policy_SubType": productCode
        };
        // check if event data is SUCCESS or NOT
        this.endorsmentService.setEndorsedPolicy(payload).subscribe((event2) => {
          if (event2.StatusCode == Constants.statusCode_success) {
            console.log(event2);
          }
        });
      }
    }
  }

  setProductType() {
    if (sessionStorage.getItem(userEnums.AgentProductMap)) {
      const products = this.storageService.getAESDecryptedData(userEnums.AgentProductMap);
      this.mypolicyForm.controls['productType'].setValue(products[0].productCode);
      if (products[0].productCode != 4) {
        this.mypolicyForm.controls['productName'].setValue(products[0].productList[0].productCode);
      }
      this.setSelectList(products[0].productCode);
    } else {
      this.productListListner('2');
    }
  }

  onWindowScroll() {
    this.scrolling = true;

    if (window.scrollY === 0) {
      this.scrolling = false;
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  setCalendarDates() {
    let minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10));
    let maxDate = new Date(new Date().setFullYear(new Date().getFullYear())); //removing +1
    this.minMax = { maxDate: maxDate, minDate: minDate }
  }

  getProductList() {
    this.productList = this.agentV1Service.getAllProducts('myPolicy');
  }

  setSelectList(option) {
    for (let i of this.productList) {
      if (i.productCode == option) {
        this.selectList = i.productList
      }
    }
  }

  productListListner(option) {
    for (let i of this.productList) {
      if (i.productCode == option) {
        this.selectList = i.productList
        if (i.productCode != 4) {
          this.mypolicyForm.controls['productName'].setValue(this.selectList[0].productCode);
        }
      }
    }
  }

  createForm() {
    let startDate = this.utilityService.subtractDays(new Date(), 15);
    this.mypolicyForm = this.formBuilder.group({
      productType: [2, [Validators.required]],
      productName: [42, [Validators.required]],
      startDate: [startDate, [Validators.required]],
      endDate: [new Date(), [Validators.required]],
    })
    this.patchBanner();
  }

  onClick(type) {
    this.policyDetails = [];
    this.selectedProduct = type.tab.textLabel;
    this.searchDetails();
  }

  selectproduct(productCode: number) {
    this.productCode = productCode;
  }

  selectproductTravel(productCode: number) {
    this.productCodeTravel = productCode;
  }
  patchBanner() {
    this.myPolicyStartDate = this.mypolicyForm.value.startDate;
    this.myPolicyEndDate = this.mypolicyForm.value.endDate;
    this.myPolicyProduct = this.getProductNameFromCode(this.mypolicyForm.value.productType);
  }

  getProductNameFromCode(x: number): string {
    if ((x == 2 || x == 3) && this.selectList.length > 0) {
      return this.selectList.find((product) => product.productCode == this.mypolicyForm.value.productName)?.productName;
    } else if (x == 4) {
      return 'Home'
    }
    return '';
  }

  constructRequest(): FetchPoliciesRequestPayload {
    let request = {
      "StartDate": this.utilityService.formatDate(this.mypolicyForm.value.startDate),
      "EndDate": this.utilityService.formatDate(this.mypolicyForm.value.endDate),
      "PolicyType": PolicyType.Health,
      "HealthPolicyType": this.mypolicyForm.value.productName,
      "TravelPolicyType": TravelPolicyType.None,
      "HomePolicyType": HomePolicyType.Home
    }
    return request;
  }
  constructRequest1(): FetchPoliciesRequestPayload {
    let request = {
      "StartDate": this.utilityService.formatDate(this.mypolicyForm.value.startDate),
      "EndDate": this.utilityService.formatDate(this.mypolicyForm.value.endDate),
      "PolicyType": PolicyType.Travel,
      "HealthPolicyType": HealthPolicyType.None,
      "TravelPolicyType": this.mypolicyForm.value.productName,
      "HomePolicyType": HomePolicyType.Home
    }
    return request;
  }
  constructRequest2(): FetchPoliciesRequestPayload {
    let request = {
      "StartDate": this.utilityService.formatDate(this.mypolicyForm.value.startDate),
      "EndDate": this.utilityService.formatDate(this.mypolicyForm.value.endDate),
      "PolicyType": PolicyType.Home,
      "HealthPolicyType": HealthPolicyType.None,
      "TravelPolicyType": TravelPolicyType.None,
      "HomePolicyType": HomePolicyType.Home
    }
    return request;
  }

  searchDetails() {
    this.clearInputField();
    let body;
    if (this.mypolicyForm.value.productType == 2) {
      body = this.constructRequest();
    } else if (this.mypolicyForm.value.productType == 3) {
      body = this.constructRequest1();
    } else if (this.mypolicyForm.value.productType == 4) {
      body = this.constructRequest2();
    }

    this.subscription.push(this.myPolicyService.fetchMypoliciesDetails(body).subscribe({
      next: (response: FetchPoliciesResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          if (response.Data.Health) {
            this.policyDetails = response.Data.Health;
            this.policyCount = response.Data.Health.length;
          } else if (response.Data.Travel) {
            this.policyDetails = response.Data.Travel;
            this.policyCount = response.Data.Travel.length;
          }
          else if (response.Data.Home) {
            this.policyDetails = response.Data.Home;
            this.policyCount = response.Data.Home.length;
          }
          if (this.mypolicyForm.value.productType == 2 && this.mypolicyForm.value.productName == 46) {
            this.displayCol = this.displayCol.map((col) => {
              if (col.columnDef == 'ProposalNumber') {
                return this.changeProposalCol;
              }
              if (col.columnDef == 'PFPolicyNo') {
                return this.changePolicyCol;
              }
              return col;
            });
          } else {
            this.displayCol = this.displayCol.map((col) => {
              if (col.columnDef == 'BundleProposalNo') {
                return this.ogProposalCol;
              }
              if (col.columnDef == 'BundlePolicyNo') {
                return this.ogPolicyCol;
              }
              return col;
            });
          }
          this.policyDetails = this.policyDetails.map((data) => {
            return {
              ...data,
              ComboPremium: (data?.TotalPremium ? Number(data?.TotalPremium) : 0) + ((Array.isArray(data?.BundleList) && data?.BundleList.length > 0) ? (data.BundleList[0]?.BundleTotalPremium ? Number(data.BundleList[0]?.BundleTotalPremium) : 0) : 0),
              HtNumber: data.HtNumber === null ? '' : data.HtNumber,
              HtStatus: !this.utilityService.isEmptyOrNull(data.HtStatus) && (data.TransFor == "R" || data.NRIDiscount) ? data.HtStatus : '-',
              BundleProposalNumber: Array.isArray(data?.BundleList) && data?.BundleList.length > 0 ? data.BundleList[0]?.ProposalNumber : '',
              BundlePolicyNumber: Array.isArray(data?.BundleList) && data?.BundleList.length > 0 ? data.BundleList[0]?.PolicyNumber : '',
              BundleEPolicyID: Array.isArray(data?.BundleList) && data?.BundleList.length > 0 ? data.BundleList[0]?.EPolicyID : '',
              BundlePolicyID: Array.isArray(data?.BundleList) && data?.BundleList.length > 0 ? data.BundleList[0]?.BundlePolicyID : '',
            };
          });
          this.filteredData = this.policyDetails;   
          this.patchBanner();
        } else {
          this.policyCount = 0;
          this.policyDetails = [];
          this.filteredData = [];
        }
      }
    }))
  }

  receiveDataAndCall(event) {
    if (event.identifier == 'Download Policy') {
      if (!this.utilityService.isEmptyOrNull(event.data?.EPolicyID) && (event.data?.Status.toUpperCase() == 'POLICY' || event.data?.Status.toUpperCase() == 'INSTACR') && (event.data?.PFPolicyNo != 0 || event.data?.PFPolicyNo != '')) {
        this.downloadPolicy(event.data);
      }
    } else if (event.identifier == 'Download Proposal') {
      if (!this.utilityService.isEmptyOrNull(event.data?.EPolicyID) && event.data?.Status && event.data?.PolicySubType != 37 && event.data?.PolicyType != 4 && event.data?.PolicySubType != 6) {
        this.downloadProposal(event.data);
      }
    } else if (event.identifier == 'Upload Document') {
      this.uploadDocument(event.data);
    } else if (event.identifier == 'UW Status') {
      this.getUnderWritterStatus(event.data);
    } else if (event.identifier == 'Coupons') {
      this.downloadCoupons(event.data)
    } else if (event.identifier == 'Edit Portability Details') {
      this.editingPortabilityDetails(event.data)
    } else if (event.identifier == 'Endorse Policy') {
      this.redirectToEndorsement(event.data)
    }
    else if ((this.mypolicyForm.valid) && (this.selectedProduct == 'Travel')) {


      this.policyDetails = [];

      let body = this.constructRequest1();
      this.subscription.push(this.myPolicyService.fetchMypoliciesDetails(body).subscribe({
        next: (response: FetchPoliciesResponsePayload) => {
          if (response.StatusCode === Constants.statusCode_success) {
            let policyDetails = response.Data.Travel;
            this.zone.run(() => {
              policyDetails.forEach(item => {
                let record: any = {
                  product: 'Travel',
                  customerName: item?.CustomerName,
                  policyType: item?.PolicyType,
                  policySubType: item?.PolicySubType,
                  policyStatus: item?.Status.toUpperCase(),
                  pfPolicyNo: item.PFPolicyNo == '0' ? '' : item?.PFPolicyNo,
                  policyID: item?.PolicyID,
                  ePolicyID: item?.EPolicyID,
                  proposalNumber: item?.ProposalNumber,
                  startDate: item?.StartDate,
                  endDate: item?.EndDate,
                  transactionOnDate: item?.TransOn,
                  totalPremium: item?.TotalPremium,
                  isHTNumberRequired: (item.TransFor == "R" && item.HtNumber),
                  htNumber: item?.HtNumber,
                  folderIndex: '',
                  isRecalculateRequired: false,
                  isEmiRequired: false,
                  transactionFor: item?.TransFor,
                  uwStatus: 'Pending for Approval with UW1',
                  custUWStatus: item?.CUUWSTATUS ? Number(item?.CUUWSTATUS) : 0,
                  allowEdit: item?.AllowEdit,
                  bundleID: item?.BundleID
                }
                this.policyDetails.push(record);
              })
            })
          }
        },
        error: error => {
        }
      })

      )

    }
    else {

      this.policyDetails = [];

      let body = this.constructRequest2();
      this.subscription.push(this.myPolicyService.fetchMypoliciesDetails(body).subscribe({
        next: (response: FetchPoliciesResponsePayload) => {
          if (response.StatusCode === Constants.statusCode_success) {
            let policyDetails = response.Data.Home;
            this.zone.run(() => {
              policyDetails.forEach(item => {
                let record: any = {
                  product: 'Home',
                  customerName: item?.CustomerName,
                  policyType: item?.PolicyType,
                  policySubType: item?.PolicySubType,
                  policyStatus: item?.Status.toUpperCase(),
                  pfPolicyNo: item.PFPolicyNo == '0' ? '' : item?.PFPolicyNo,
                  policyID: item?.PolicyID,
                  ePolicyID: item?.EPolicyID,
                  proposalNumber: item?.ProposalNumber,
                  startDate: item?.StartDate,
                  endDate: item?.EndDate,
                  transactionOnDate: item?.TransOn,
                  totalPremium: item?.TotalPremium,
                  isHTNumberRequired: (item.TransFor == "R" && item.HtNumber),
                  htNumber: item?.HtNumber,
                  folderIndex: '',
                  isRecalculateRequired: false,
                  isEmiRequired: false,
                  transactionFor: item?.TransFor,
                  uwStatus: 'Pending for Approval with UW1',
                  custUWStatus: item?.CUUWSTATUS ? Number(item?.CUUWSTATUS) : 0,
                  allowEdit: item?.AllowEdit,
                  bundleID: item?.BundleID
                }
                this.policyDetails.push(record);
              })
            })
          }
        },
        error: error => {
        }
      })

      )

    }
  }

  redirectToEndorsement(data) {
    let login = this.storageService.getAESDecryptedData(userEnums.LoginCredential);
    let payload = {
      "Type": "ENDORSEMENT",
      "userCode": 0,
      "IMID": login['Uowkjsdjssrme'],
      "endorsementReq": {
        "policyNumber": data.PFPolicyNo,
        "dateOfBirth": "2024-10-02" // as confirmed with Rahul V., we are passing the parameter as this is not required.
      }
    };
    this.getEndorsementUrl(payload);
  }

  getEndorsementUrl(payload) {
    this.subscription.push(
      this.endorsementService.getHealthClaimURL(payload).subscribe(res => {
        if (res.StatusCode == Constants.statusCode_success) {
          const trustedUrlPattern = /^(https:\/\/panacf\.insurancearticlez\.com|https:\/\/panaapi\.ilgicdash\.com|https:\/\/pana\.icicilombard|https:\/\/digitalpos-uat\.insurancearticlez\.com|https:\/\/ipartnerpro\.icicilombard\.com|http:\/\/localhost(:\d+)?|https:\/\/localhost(:\d+)?)(\/.*)?$/;
          if (trustedUrlPattern.test(res.Data)) {
            this.iframeUrl = this.domSanitization.bypassSecurityTrustResourceUrl(res.Data);
            const width = window.innerWidth < 768 ? '95%' : '55%';
            this.dialog.open(this.iframe, { maxWidth: '100%', width: width });
          }
        }
      })
    );
  }

  downloadPolicy(data) {
    let policyType = (data.CUUWSTATUS != '' && data.CUUWSTATUS > 0 && (data.PolicySubType == 19 || data.PolicySubType == 20)) ? 'HBCUWPDF' :
      data.PolicySubType == 21 ? 'POLICY' : (data.CUUWSTATUS != '' && data.CUUWSTATUS > 0) ? 'CHICUWPDF' : 'POLICY';
    // let policyId = data.policySubType == 38 || data.policySubType == 23 ? data.policyID : data.ePolicyID;
    let policyId = data.EPolicyID;
    let params = { policyType: policyType, policySubType: data.PolicySubType, policyId: policyId, ePolicyID: data.EPolicyID, bundleId: data.BundleList, policyID: data.PolicyID, isBancsPolicy: data.IsBancsPolicy }
    let details = this.downloadService.downloadPolicy(params);
    this.generatePdf(details, data.PFPolicyNo);
  }

  downloadProposal(data) {
    let policyId = data.EPolicyID;
    let params = { policyType: 'PROPOSAL', policySubType: data.PolicySubType, policyId: policyId, ePolicyID: data.EPolicyID, bundleId: data.BundleID, policyID: data.PolicyID, isBancsPolicy: data.IsBancsPolicy }
    let details = this.downloadService.downloadProposal(params);
    this.generatePdf(details, data.ProposalNumber);
  }

  generatePdf(params, name) {
    this.subscription.push(this.downloadService.generatePDF(params.endPoint, params.body).subscribe({
      next: (response) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.downloadService.downloadPdf(response.Data, name)
        }
      },
      error: error => {
        let errorPopupData = this.popupService.fetchErrorPopupMsg('');
        this.popupService.openGenericPopup(errorPopupData);
      }
    })

    )
  }

  downloadFile(base64Data, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  uploadDocument(data) {
    this.healthFormData.myPolicyDetails.htNumber = data?.HtNumber || '';
    this.healthFormData.myPolicyDetails.htFolderIndex = data?.HtFolderIndex || '';
    this.healthFormData.myPolicyDetails.policyId = data?.PolicyID;
    this.healthFormData.myPolicyDetails.TransFor = data?.TransFor;
    this.healthFormData.myPolicyDetails.NRIApplicable = data?.NRIDiscount;
    this.healthFormData.myPolicyDetails.isBancsPolicy = data?.IsBancsPolicy || false;
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
    this.router.navigate([routeEnums.DocumentUpload]);
    // this.utilityService.redirectionToUploadDocument(data?.HtNumber);
  }

  constructProposalStatusRequest(data): ProposalStatusRequestPayload {
    let request = { ProposalNumber: data.ProposalNumber }
    return request;
  }

  getUnderWritterStatus(data) {
    let body = this.constructProposalStatusRequest(data);
    let statusResponseObs$: any;
    if (data.IsBancsPolicy) {
      statusResponseObs$ = this.healthMasterService.getPolicyUWStatus(body, data.IsBancsPolicy);
    } else {
      statusResponseObs$ = this.myPolicyService.getUnderWritterStatus(body);
    }
    statusResponseObs$.subscribe({
      next: (response: ProposalStatusResponsePayload) => {
        this.underWritterStatus = response.Data;
        let popupData: PopupModal = {
          popupType: popupType.generic,
          imgName: popupImgPath.successGif,
          header: popupHeader.success,
          description: 'Policy Underwriter Status of ' + data.ProposalNumber + ' : ' + this.underWritterStatus,
          buttonLabel: popupButton.ok,
          buttonAction: popupButtonAction.close
        }
        this.popupService.openGenericPopup(popupData);
      },
      error: error => {
      }
    })
  }

  constructDownloadCouponsRequest(policyId, productType): DownloadCouponRequestPayload {
    let request = { EPolicyID: policyId, Type: productType }
    return request;
  }

  downloadCoupons(data) {
    let policySubType = data.policySubType == 21 ? 'PPAP' : 'CHI';
    let productType = (data.policySubType == 19 || data.policySubType == 20) ? 'HBOOSTER' : policySubType;
    let body = this.constructDownloadCouponsRequest(data.PolicyID, productType);
    this.subscription.push(this.myPolicyService.downloadCoupons(body).subscribe({
      next: (response) => {
        let fileName = 'coupon_' + data.ProposalNumber + '.pdf';
        this.downloadService.downloadPdf(response.Data, fileName);
      },
      error: error => {
        let errorPopupData = this.popupService.fetchErrorPopupMsg('');
        this.popupService.openGenericPopup(errorPopupData);
      }
    })
    )
  }

  fetchSelectedpolicyApiCall(data) {
    let body = { 'E_PolicyID': data.EPolicyID }
    this.spinner.show();
    this.subscription.push(this.myPolicyService.fetchSelectedPolicyDetails(body, data.IsBancsPolicy).subscribe({
      next: (response: FetchPropToModifyResponse) => {
        if (response.StatusCode === Constants.statusCode_success) {
          this.spinner.hide();
          let portabilityPolicyDetails = response;
          let popupData: EditPortabilityPopupModal = {
            popupType: popupType.editPortability,
            imgName: '',
            header: '',
            description: '',
            buttonLabel: '',
            policyDetails: portabilityPolicyDetails,
            EPolicyID: data.EPolicyID,
            isBancsPolicy: data.IsBancsPolicy
          }
          this.popupService.openEditPortabilityPopup(popupData);
        } else {
          this.spinner.hide();
          let errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusMsg);
          this.popupService.openGenericPopup(errorPopupData);
        }
      },
      error: error => {
        this.spinner.hide();
        let errorPopupData = this.popupService.fetchErrorPopupMsg('');
        this.popupService.openGenericPopup(errorPopupData);
      }
    })
    )
  }

  editingPortabilityDetails(data) {
    this.fetchSelectedpolicyApiCall(data);
  }

  onSubmit() {
    this.isSubmitted = true;
    this.policyDetails();
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  editImage: string = 'assets/images/BBR/Edit.svg'; // Initial image

  openSearchBottomSheet() {
    if (this.editImage === 'assets/images/BBR/Edit.svg') {
      this.editImage = 'assets/images/BBR/EditActive.svg';
    } else {
      this.editImage = 'assets/images/BBR/Edit.svg';
    }
    const x = this.productList.filter(item => item.productCode == this.mypolicyForm.value.productType);
    const y = x[0].productList;
    const searchBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        popupType: popupType.search,
        data: {
          controls: [{
            type: 'myDropdown',
            label: 'Product Type',
            list: this.productList,
            value: this.mypolicyForm.value.productType
          },
          {
            type: 'myDependentDropdown',
            label: 'Product Name',
            list: y,
            value: this.mypolicyForm.value.productName
          },
          {
            type: 'rangepicker',
            label: 'Select date range',
            startDate: this.mypolicyForm.value.startDate,
            endDate: this.mypolicyForm.value.endDate,
          }],
          dates: {
            minDate: this.minMax.minDate,
            maxDate: this.minMax.maxDate,
          },
        }
      }
    }
    );
    searchBottomSheet.afterClosed().subscribe((data) => {
      this.editImage = 'assets/images/BBR/Edit.svg';
      this.patchSearchParameters(data);
    });
  }

  patchSearchParameters(data) {
    if (data) {
      for (let i of data) {
        if (i.type == 'myDropdown') {
          this.mypolicyForm.controls['productType'].setValue(i.value);
        }
        else if (i.type == 'myDependentDropdown' && i.value) {
          this.mypolicyForm.controls['productName'].setValue(i.value);
        }
        else if (i.type == 'rangepicker') {
          this.mypolicyForm.controls['startDate'].setValue(i.startDate);
          this.mypolicyForm.controls['endDate'].setValue(i.endDate);
        }
      }
      this.searchDetails();
    }
  }

  searchFilter() {
    this.isSearchVisible = true;
  }

  onToggle(event) {
    this.toggleValue = event.value;
  }

  applyPolicyFilter(isChecked: boolean, value: string) {
    value = value.toLowerCase();
    if (isChecked) {
      if (!this.policyFilter.includes(value)) {
        this.policyFilter.push(value);
      }
    } else {
      const index = this.policyFilter.indexOf(value);
      if (index >= 0) {
        this.policyFilter.splice(index, 1);
      }
    }
    this.applyFilter();
  }

  filterImage: string = 'assets/images/BBR/FilterMobile.svg'; // Initial image

  openFilterBottomSheet() {
    if (this.filterImage === 'assets/images/BBR/FilterMobile.svg') {
      this.filterImage = 'assets/images/BBR/FilterActive.svg';
    } else {
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
    }
    const filterBottomSheet = this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-payment',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        popupType: popupType.filter,
        data: [
          {
            label: 'Status Type',
            list: this.policyFilterList,
            filter: this.policyFilter
          }]
      },
    }
    );
    filterBottomSheet.afterClosed().subscribe((data) => {
      // Reset the image back to FilterMobile.svg
      this.filterImage = 'assets/images/BBR/FilterMobile.svg';
      this.patchFilterParameters(data);
    });
  }

  patchFilterParameters(data) {
    if (data) {
      this.policyFilter = data[0].filter;
    }
    this.applyFilter();
  }

  clearInputField() {
    this.inputValue = '';
    this.queryData();
  }

  applyFilter() {
    this.clearInputField();
    this.filterLogic();
  }

  setPlaceHolderText() {
    this.searchPlaceHolder = this.phText[this.i];
    this.i++;
    if (this.i == this.phText.length) {
      this.i = 0;
    }
  }

  inputFocus() {
    this.showCancel = true;
  }

  inputBlur() {
    this.showCancel = false;
  }

  closeClear() {
    this.isSearchVisible = false;
    this.clearInputField();
  }

  filterLogic() {
    if (!(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.policyDetails.filter(item => this.policyFilter.includes(item['Status'].toLowerCase()))
    } else {
      this.filteredData = this.policyDetails;
    }
  }

  queryData() {
    const query = this.inputValue.trim();
    if (!query && this.utilityService.isEmptyOrNull(this.policyFilter)) {
      this.filteredData = this.policyDetails;
    }
    else if (!query && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filterLogic();
    }
    else if (query && this.utilityService.isEmptyOrNull(this.policyFilter)) {
      this.filteredData = this.policyDetails.filter(item => {
        const customerName = item['CustomerName'] ? item['CustomerName'].toString().toLowerCase() : '';
        const proposalNumber = item['ProposalNumber'] ? item['ProposalNumber'].toString().toLowerCase() : '';
        const bundleProposalNumber = item['BundleProposalNumber'] ? item['BundleProposalNumber'].toString().toLowerCase() : '';
        let pfPolicyNo = '';
        if (this.mypolicyForm.value.productName == 46) {
          pfPolicyNo = item['PFPolicyNo'] && item['BundlePolicyNumber'] ? item['PFPolicyNo'].toString().toLowerCase() : '';
        } else {
          pfPolicyNo = item['PFPolicyNo'] ? item['PFPolicyNo'].toString().toLowerCase() : '';
        }
        const bundlePolicyNumber = item['PFPolicyNo'] && item['BundlePolicyNumber'] ? item['BundlePolicyNumber'].toString().toLowerCase() : '';
        return customerName.includes(query.toLowerCase()) ||
          proposalNumber.includes(query.toLowerCase()) ||
          bundleProposalNumber.includes(query.toLowerCase()) ||
          pfPolicyNo.includes(query.toLowerCase()) ||
          bundlePolicyNumber.includes(query.toLowerCase());
      });
    }
    else if (query && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
      this.filteredData = this.filteredData.filter(item => {
        const customerName = item['CustomerName'] ? item['CustomerName'].toString().toLowerCase() : '';
        const proposalNumber = item['ProposalNumber'] ? item['ProposalNumber'].toString().toLowerCase() : '';
        const bundleProposalNumber = item['BundleProposalNumber'] ? item['BundleProposalNumber'].toString().toLowerCase() : '';
        let pfPolicyNo = '';
        if (this.mypolicyForm.value.productName == 46) {
          pfPolicyNo = item['PFPolicyNo'] && item['BundlePolicyNumber'] ? item['PFPolicyNo'].toString().toLowerCase() : '';
        } else {
          pfPolicyNo = item['PFPolicyNo'] ? item['PFPolicyNo'].toString().toLowerCase() : '';
        }
        const bundlePolicyNumber = item['PFPolicyNo'] && item['BundlePolicyNumber'] ? item['BundlePolicyNumber'].toString().toLowerCase() : '';
        return customerName.includes(query.toLowerCase()) ||
          proposalNumber.includes(query.toLowerCase()) ||
          bundleProposalNumber.includes(query.toLowerCase()) ||
          pfPolicyNo.includes(query.toLowerCase()) ||
          bundlePolicyNumber.includes(query.toLowerCase());
      });
    }
  }

  // forQcFlow() {
  //   let QcFlow = this.storageService.getAESDecryptedData(userEnums.QcFlow) || false;
  //   if (QcFlow) {
  //     let col = {
  //       columnDef: 'HtStatus',
  //       header: 'Nysa HT Status',
  //       expression: '(data.TransFor == "R" || data.NRIDiscount) && !this.utilityService.isEmptyOrNull(data.HtStatus)',
  //       cell: (element: any) => `${element.HtStatus}`,
  //     }
  //     this.displayCol.push(col);
  //   }
  // }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}