import { Injectable, OnDestroy } from '@angular/core';
import { MFAService } from './mfa.service';
import { StorageService } from './storage.service';
import { ChannelData } from '../interface/swapPrameters';
import { userEnums } from '../enums/userEnums';

@Injectable({
    providedIn: 'root',
})
export class TabCloseService implements OnDestroy {
    channelData: ChannelData;

    constructor(private mfaService: MFAService,
        private storageService: StorageService) {
        this.initTabCloseEvent();
        this.checkIfPageReloaded();
    }

    private initTabCloseEvent(): void {
        window.onbeforeunload = () => {
            sessionStorage.setItem('tabClose', 'true');
            this.handleTabCloseEvent();
        };

        window.addEventListener('pagehide', () => {
            sessionStorage.setItem('tabClose', 'true');
        });
    }

    private handleTabCloseEvent(): void {
        //after tab is close call sessionLogOut api
        // console.log('Tab is being closed');
        // this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
        // let sessionID: any = {
        //     "sessionId": this.channelData.SID || '1'
        // }
        // this.mfaService.sessionLogOut(sessionID).subscribe((res) => {
        //     console.log(res,'api call successfully done afte tab close');
        // })
    }

    private checkIfPageReloaded(): void {
        window.addEventListener('pageshow', (event) => {
            if (sessionStorage.getItem('tabClose')) {
                sessionStorage.removeItem('tabClose');
                console.log('Page was reloaded');
            }
        });
    }

    ngOnDestroy(): void {
        window.onbeforeunload = null;
        window.removeEventListener('pagehide', () => { });
        window.removeEventListener('pageshow', () => { });
    }
}
