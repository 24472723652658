import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { routeEnums } from './../../shared/enums/routeEnums';
import { ProductStepmanagerComponent } from './products/product-stepmanager/product-stepmanager.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ElevateOfflineCalculatorComponent } from 'src/app/shared/components/offline-calculator/elevate-offline-calculator/elevate-offline-calculator.component';
import { SaverBoosterComponent } from './products/elevate/saver-booster/saver-booster.component';

const ROUTE_PATHS = routeEnums;


const routes: Routes = [
  {
    path: ROUTE_PATHS.ROOT,
    redirectTo: ROUTE_PATHS.LOGIN,
    pathMatch: 'full'
  },

  {
    path: ROUTE_PATHS.DASHBOARD,
    component: LandingPageComponent,
  },
  // {
  //   path: ROUTE_PATHS.QuotePDF,
  //   component: QuotePdfComponent,
  // },

  {
    path: ROUTE_PATHS.HAP,
    component: ProductStepmanagerComponent,
  },
  {
    path: ROUTE_PATHS.Elevate,
    component: ProductStepmanagerComponent,
  },
  {
    path: ROUTE_PATHS.SuperSaver,
    component: ProductStepmanagerComponent,
  },
  {
    path: ROUTE_PATHS.ActivateBooster,
    component: ProductStepmanagerComponent,
  },
  {
    path: ROUTE_PATHS.OrionHap,
    component: ProductStepmanagerComponent,
  },
  {
    path: ROUTE_PATHS.HealthElitePlus,
    component: ProductStepmanagerComponent,
  },
  {
    path: ROUTE_PATHS.MaxProtect,
    component: ProductStepmanagerComponent,
  },

  {
    path: ROUTE_PATHS.ASP,
    component: ProductStepmanagerComponent,
  },

  {
    path: ROUTE_PATHS.HEALTH_BOOSTER,
    component: ProductStepmanagerComponent,
  },

  {
    path: ROUTE_PATHS.GoldenShield,
    component: ProductStepmanagerComponent,
  },
  {
    path: ROUTE_PATHS.CritiShield,
    component: ProductStepmanagerComponent,
  },
  {
    path: ROUTE_PATHS.Familyshield,
    component: ProductStepmanagerComponent
  },
  {
    path: ROUTE_PATHS.CalculateEevate,
    component: ElevateOfflineCalculatorComponent
  },
  {
    path:  ROUTE_PATHS.CalculateSuperSaver,
    component: SaverBoosterComponent
  },
  {
    path: ROUTE_PATHS.CalculateActivateBooster,
    component: SaverBoosterComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HealthRoutingModule { }