import { Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { TravelService } from 'src/app/shared/services/travel.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { TravelDataStorageService } from '../travel.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { RelationshipRequestPayload } from 'src/app/shared/interface/travel';
import { TravelDataStorage, TravelModal } from '../travel.modal';
import { healthMembers } from 'src/app/shared/enums/healthEnums';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { DeclarationsService } from 'src/app/shared/services/declarations.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { PopupModal } from 'src/app/layout/popups/popup.modal';

@Component({
  selector: 'app-internation-travel',
  templateUrl: './internation-travel.component.html',
  styleUrls: ['./internation-travel.component.scss']
})
export class InternationTravelComponent implements OnInit {
  internationTravelForm: FormGroup;
  isSubmitted: boolean = false;
  subscription: Subscription[] = [];
  stateMaster: any[] = [];
  planMaster: any[] = [];
  relationshipMaster: any = [];
  travelFormData: TravelDataStorage;
  formValid: boolean;
  leavingMinMaxDate: { maxDate: Date; minDate: Date; };
  returningMinMaxDate: { maxDate: Date; minDate: Date; };
  memberMinMaxDate: { minDate: Date | string; maxDate: Date; };
  startMinMaxDate: { minDate: Date; maxDate: Date; };
  travelModal: TravelModal = new TravelModal();
  patchingDone: boolean = false;
  datepickerMobile: boolean = false;
  channelData: ChannelData;
  travelDataModal: TravelModal = new TravelModal(); //travel data modal
  errorPopupData: PopupModal;
  multipleServiceError: boolean = false;
  loadFormContent: boolean = false;

  constructor(private fb: FormBuilder,
    public utilityService: UtilityService,
    private travelService: TravelService,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    private travelDataStorageService: TravelDataStorageService,
    private storageService: StorageService,
    private popupService: PopupService,
    private declarationService: DeclarationsService,
    private el: ElementRef
  ) {
  }

  ngOnInit() {
    this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);
    this.onLoadSteps();
    if (window.innerWidth < 768) {
      this.datepickerMobile = true;
    } else {
      this.datepickerMobile = false;
    }
  }

  onLoadSteps() {
    this.travelFormData = this.travelDataStorageService.getTravelDataStorage();
    let subProduct = this.travelFormData.quoteDataSet ? this.travelFormData.quoteFormData.subProduct : 'Individual';
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    this.fetchStateMaster();
    this.fetchListofSchengenStates();
    this.fetchRelationshipMaster();
    this.createInternationTravelForm(subProduct);
    this.fetchListofEuropeanUnionMemberStates();

    this.internationTravelForm.valueChanges.subscribe(data => {
      if (this.patchingDone && this.travelFormData.quoteDataSet) {
        // premium data set flag is used beacsuse user can come back and try to change the values.
        // In that case also we need to reset the stepper

        // And if user refreshes that means existing value is getting patched. In that case we are using the patchingDone flag
        // and resetting the premium footer
        this.showPremiumDetails(false);
        // Here we are resetting the stepper. Just to be sure if the quote is changed.
        // User needs to traverse through each steps
        this.customStepperService.resetStepper(true);
      }
    })
  }

  createInternationTravelForm(subProduct: any) {
    let obj: any = {};
    let travelFormData = this.travelDataStorageService.getTravelDataStorage().quoteFormData;
    let isPremiumDataSet = this.travelDataStorageService.getTravelDataStorage().premiumDataSet;
    if (isPremiumDataSet) {
      obj = {
        productName: travelFormData.productName, subProduct: subProduct,
        state: travelFormData.state, tripType: travelFormData.tripType, travelLoc: travelFormData.travelLoc, isVisitOnImmigrantVisa: travelFormData.isVisitOnImmigrantVisa, leavingDate: new Date(travelFormData.leavingDate), returningDate: new Date(travelFormData.returningDate), noOfTripDays: travelFormData.noOfTripDays,
        tripDurationDetails: travelFormData.tripDurationDetails, startDate: travelFormData.startDate, members: travelFormData.members, isKeralaGST: travelFormData.isKeralaGST,
        noOfTraveller: travelFormData.noOfTraveller, noOfTravellerDetails: travelFormData.noOfTravellerDetails, coverageType: travelFormData.coverageType,
        plan: travelFormData.plan, relationshipDetails: travelFormData.relationshipDetails, planDetails: travelFormData.planDetails, stateDetails: travelFormData.stateDetails,
        semiProfessionalSports: travelFormData.semiProfessionalSports, adventureSports: travelFormData.adventureSports
      }
    } else {
      let totalNoOfTraveller = subProduct == 'Individual' || (subProduct == 'Senior Citizen') ? 1 : 2;
      let totalNoOfTravellerDetails = subProduct == 'Family' ? [2, 3, 4, 5, 6] : [1];
      let coverageType = subProduct == 'Family' ? 'familyPlan' : 'individualPlan';
      obj = {
        productName: 'International Travel', subProduct: subProduct,
        state: '', tripType: '', travelLoc: '', isVisitOnImmigrantVisa: false, leavingDate: '', returningDate: '', noOfTripDays: '',
        tripDurationDetails: [], startDate: '', members: this.fb.array([]), isKeralaGST: false,
        noOfTraveller: totalNoOfTraveller, noOfTravellerDetails: totalNoOfTravellerDetails, coverageType: coverageType,
        plan: '', relationshipDetails: [], planDetails: [], stateDetails: [], semiProfessionalSports: false, adventureSports: false
      }
    }

    this.internationTravelForm = this.fb.group({
      productName: [{ value: obj.productName, disabled: true }, Validators.required],
      subProduct: [obj.subProduct, Validators.required],
      state: [obj.state, Validators.required],
      tripType: [obj.tripType],
      travelLoc: [obj.travelLoc],
      isVisitOnImmigrantVisa: [obj.isVisitOnImmigrantVisa],
      leavingDate: [obj.leavingDate],
      returningDate: [obj.returningDate],
      noOfTripDays: [obj.noOfTripDays, Validators.required],
      tripDurationDetails: [obj.tripDurationDetails],
      startDate: [obj.startDate],
      isKeralaGST: [obj.isKeralaGST],
      noOfTraveller: [obj.noOfTraveller],
      noOfTravellerDetails: [obj.noOfTravellerDetails],
      coverageType: [obj.coverageType],
      members: this.fb.array([]),
      plan: [obj.plan, Validators.required],
      relationshipDetails: [obj.relationshipDetails],
      planDetails: [obj.planDetails],
      stateDetails: [obj.stateDetails],
      semiProfessionalSports: [obj.semiProfessionalSports],
      adventureSports: [obj.adventureSports]
    })

    this.setValidators(subProduct, isPremiumDataSet, obj).then(res => {
      this.memberDetails.clear();
      this.createMembers(obj.noOfTraveller, isPremiumDataSet, obj).then(res => {
        this.internationTravelForm.value.leavingDate = new Date(this.internationTravelForm.value.leavingDate);
        this.setMinMaxdate().then(res => {
        });
      });
    })


  }

  setValidators(subProduct, isPremiumDataSet?, formData?): Promise<void> {
    return new Promise((resolve) => {
      if (subProduct == 'Individual') {
        this.internationTravelForm.controls['tripType'].setValidators([Validators.required]);
        this.internationTravelForm.controls['tripType'].updateValueAndValidity();
        this.internationTravelForm.controls['noOfTraveller'].setValidators([Validators.required]);
        this.internationTravelForm.controls['noOfTraveller'].updateValueAndValidity();
        let tripType = isPremiumDataSet ? formData.tripType : 'roundTrip';
        this.internationTravelForm.controls['tripType'].patchValue(tripType);
        this.changeTripType(tripType, isPremiumDataSet, formData);

        resolve();
      } else {
        this.internationTravelForm.controls['noOfTraveller'].setValidators([Validators.required]);
        this.internationTravelForm.controls['noOfTraveller'].updateValueAndValidity();
        this.internationTravelForm.controls['leavingDate'].setValidators([Validators.required]);
        this.internationTravelForm.controls['leavingDate'].updateValueAndValidity();
        this.internationTravelForm.controls['returningDate'].setValidators([Validators.required]);
        this.internationTravelForm.controls['returningDate'].updateValueAndValidity();
        this.internationTravelForm.controls['travelLoc'].setValidators([Validators.required]);
        this.internationTravelForm.controls['travelLoc'].updateValueAndValidity();
        this.internationTravelForm.controls['isVisitOnImmigrantVisa'].setValidators([Validators.required]);
        this.internationTravelForm.controls['isVisitOnImmigrantVisa'].updateValueAndValidity();
        this.internationTravelForm.controls['tripType'].clearValidators();
        this.internationTravelForm.controls['tripType'].updateValueAndValidity();
        resolve();
      }
    });
  }

  get memberDetails(): FormArray {
    return this.internationTravelForm.get('members') as FormArray;
  }

  createMembers(data: any, isPremiumDataSet?, formData?): Promise<void> {
    return new Promise((resolve) => {
      this.memberDetails.clear();
      let fields: any = {};
      let obj: any = {};
      for (let i = 0; i < data; i++) {
        if (isPremiumDataSet) {
          obj = {
            dob: formData.members[i].DOB, age: formData.age, relationship: formData.members[i].RelationshipwithApplicant, type: formData.members[i].MemberType,
            gender: formData.members[i].gender
          }
        } else {
          obj = { dob: '', age: 0, relationship: '', type: '', gender: '' }
        }
        fields = {
          DOB: [obj.dob, Validators.required], age: [obj.age], RelationshipwithApplicant: [obj.relationship, Validators.required], MemberType: [obj.type], memberTypeCount: '',
          gender: [obj.gender]
        }
        this.memberDetails.push(this.fb.group(fields));
      }
      resolve();
    });
  }

  setMinMaxdate(): Promise<void> {
    return new Promise((resolve) => {
      let date = this.utilityService.subtractMonths(new Date(), 3);
      let seniorCitizenMaxYear = new Date().getFullYear() - 71; let seniorCitizenMinYear = new Date().getFullYear() - 86;
      let minDate = this.internationTravelForm.value.subProduct == 'Senior Citizen' ? new Date(seniorCitizenMaxYear, this.internationTravelForm.value.leavingDate.getMonth(), this.internationTravelForm.value.leavingDate.getDate()) : date;
      let maxDate = this.internationTravelForm.value.subProduct == 'Senior Citizen' ? new Date(seniorCitizenMinYear, new Date().getMonth(), new Date().getDate()) : '';
      this.memberMinMaxDate = { minDate: maxDate, maxDate: minDate };
      let nextYear = new Date().getFullYear() + 2;
      this.leavingMinMaxDate = { minDate: new Date(), maxDate: new Date(nextYear, 11, 31) };

      let returningDate = this.internationTravelForm.value.leavingDate ? this.internationTravelForm.value.leavingDate : new Date();
      this.returningMinMaxDate = { minDate: returningDate, maxDate: new Date(nextYear, 11, 31) };

      this.startMinMaxDate = { minDate: new Date(), maxDate: new Date(nextYear, 11, 31) };
      resolve();
    });
  }

  changeState(state) {
    if (state == 'KERALA') {
      this.internationTravelForm.controls['isKeralaGST'].setValidators([Validators.required]);
      this.internationTravelForm.controls['isKeralaGST'].updateValueAndValidity();
    } else {
      this.internationTravelForm.controls['isKeralaGST'].clearValidators();
      this.internationTravelForm.controls['isKeralaGST'].updateValueAndValidity();
    }
  }

  changeSubProductType(value: any) {
    this.createInternationTravelForm(value);
  }

  changeTripType(value: any, isPremiumDataSet?, formData?) {
    let travelLoc = isPremiumDataSet ? formData?.travelLoc : '';
    let noOfTripDays = isPremiumDataSet ? formData?.noOfTripDays : '';
    let leavingDate = isPremiumDataSet ? formData?.leavingDate : '';
    let returningDate = isPremiumDataSet ? formData?.returningDate : '';
    this.internationTravelForm.controls['travelLoc'].patchValue(travelLoc);
    this.internationTravelForm.controls['noOfTripDays'].patchValue(noOfTripDays);
    this.internationTravelForm.controls['leavingDate'].patchValue(leavingDate);
    this.internationTravelForm.controls['returningDate'].patchValue(returningDate);
    let noOfTraveller = 1;
    this.createMembers(noOfTraveller, isPremiumDataSet, formData);
    if (value == 'multiTrip') {
      this.storageService.setAESEncryptedData(userEnums.SubProductCode, 5);
      let tripDurationDetails = [30, 45, 60];
      this.internationTravelForm.controls['startDate'].setValidators([Validators.required]);
      this.internationTravelForm.controls['startDate'].updateValueAndValidity();
      this.internationTravelForm.controls['leavingDate'].clearValidators();
      this.internationTravelForm.controls['leavingDate'].updateValueAndValidity();
      this.internationTravelForm.controls['returningDate'].clearValidators();
      this.internationTravelForm.controls['returningDate'].updateValueAndValidity();
      this.internationTravelForm.controls['travelLoc'].clearValidators();
      this.internationTravelForm.controls['travelLoc'].updateValueAndValidity();
      this.internationTravelForm.controls['isVisitOnImmigrantVisa'].clearValidators();
      this.internationTravelForm.controls['isVisitOnImmigrantVisa'].updateValueAndValidity();
      this.internationTravelForm.controls['tripDurationDetails'].patchValue(tripDurationDetails);
      this.internationTravelForm.controls['noOfTripDays'].enable();
    } else {
      this.storageService.setAESEncryptedData(userEnums.SubProductCode, 4);
      this.internationTravelForm.controls['leavingDate'].setValidators([Validators.required]);
      this.internationTravelForm.controls['leavingDate'].updateValueAndValidity();
      this.internationTravelForm.controls['returningDate'].setValidators([Validators.required]);
      this.internationTravelForm.controls['returningDate'].updateValueAndValidity();
      this.internationTravelForm.controls['travelLoc'].setValidators([Validators.required]);
      this.internationTravelForm.controls['travelLoc'].updateValueAndValidity();
      this.internationTravelForm.controls['isVisitOnImmigrantVisa'].setValidators([Validators.required]);
      this.internationTravelForm.controls['isVisitOnImmigrantVisa'].updateValueAndValidity();
      this.internationTravelForm.controls['startDate'].clearValidators();
      this.internationTravelForm.controls['startDate'].updateValueAndValidity();
      this.internationTravelForm.controls['tripDurationDetails'].patchValue([]);
    }
  }

  changeTravelLocation(ev: any) {
    this.checkTravelPlanCondition();
    this.internationTravelForm.patchValue({ 'plan': '' });
  }

  changeNoOfTraveller(value: any) {
    this.checkTravelPlanCondition();
    this.internationTravelForm.patchValue({ 'plan': '' });
    this.createMembers(value);
  }

  changeCoverageType(ev: any) {
    let noOfTravellerDetails = ev == 'individualPlan' ? [1] : [2, 3, 4, 5, 6];
    let totalNoOfTraveller = ev == 'individualPlan' ? 1 : 2;
    this.internationTravelForm.controls['noOfTraveller'].patchValue(totalNoOfTraveller);
    this.internationTravelForm.controls['noOfTravellerDetails'].patchValue(noOfTravellerDetails);
    this.changeNoOfTraveller(totalNoOfTraveller);
  }

  changeProductSubType(type) {
    this.travelFormData.quoteDataSet = false;
    this.travelFormData.premiumDataSet = false;
    this.travelFormData.insuredDataSet = false;
    this.travelFormData.kycDataSet = false;
    this.travelFormData.insuredFormData.insuredMemberDetails = [];
    this.travelDataStorageService.setTravelDataStorage(this.travelFormData);
    this.createInternationTravelForm(type);
  }

  getDuration(controlName) {
    let date;
    if (controlName == 'leavingDate' || controlName == 'startDate') {
      date = controlName == 'leavingDate' ? this.internationTravelForm.value.leavingDate : this.internationTravelForm.value.startDate;
    } else {
      date = (this.internationTravelForm.value.tripType == 'roundTrip' || this.internationTravelForm.value.subProduct != 'Individual')
        ? this.internationTravelForm.value.leavingDate : this.internationTravelForm.value.startDate;
    }
    if (date != '' && controlName != 'startDate') {
      let duration = this.calculateDuration(date, this.internationTravelForm.value.returningDate);
      this.internationTravelForm.patchValue({ 'noOfTripDays': duration });
    }
    if (controlName == 'leavingDate') {
      const members = this.internationTravelForm.get('members') as FormArray;
      for (let i = 0; i < members.length; i++) {
        members.at(i).get('DOB').reset(true);
      }
    }
    this.checkTravelPlanCondition();
    this.setMinMaxdate();
  }

  changeDateOfBirth(index) {
    let dob = this.internationTravelForm.value.members[index].DOB;
    let age = (new Date().getFullYear() - new Date(dob).getFullYear());
    let type = age > 18 ? 'Adult' : 'Child';
    this.internationTravelForm.controls['members']['controls'][index].get('age').patchValue(age);
    this.internationTravelForm.controls['members']['controls'][index].get('MemberType').patchValue(type);
  }

  changeRelationship(relationship, index) {
    let relationData = this.relationshipMaster.filter(x => x.RelationshipName == relationship);
    let gender = relationData[0].Gender;
    this.internationTravelForm.controls['members']['controls'][index].patchValue({ gender: gender });
  }

  calculateDuration(startDate, endDate) {
    let Days = 0;
    if (!this.utilityService.isEmptyOrNull(startDate) && !this.utilityService.isEmptyOrNull(endDate)) {
      var date1 = new Date(startDate);
      var date2 = new Date(endDate);
      var Time = date2.getTime() - date1.getTime();
      Days = (Time / (1000 * 3600 * 24)) + 1;
      return Days;
    }
    return Days;
  }

  fetchListofSchengenStates() {
    this.subscription.push(this.travelService.fetchListofSchengenStatesdetails('').subscribe({
      next: (response: any) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.multipleServiceError = false;
          this.storageService.setAESEncryptedData(userEnums.SchengenStates, response.Data);
        } else {
          this.multipleServiceError = true;
        }
      },
      error: error => {
      }
    })
    )
  }


  fetchListofEuropeanUnionMemberStates() {
    this.subscription.push(this.travelService.ListofEuropeanUnionMemberStatesdetails('').subscribe({
      next: (response: any) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.multipleServiceError = false;
          this.storageService.setAESEncryptedData(userEnums.EuropeanStates, response.Data);
        } else {
          this.multipleServiceError = true;
        }
      },
      error: error => {
      }
    })
    )
  }


  fetchStateMaster() {
    this.subscription.push(this.travelService.fetchStateMasterDetails('').subscribe({
      next: (response: any) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.stateMaster = response.Data;
          this.multipleServiceError = false;
        } else {
          this.multipleServiceError = true;
        }
      },
      error: error => {
      }
    })
    )
  }

  checkPlanStatus(subProduct) {
    return subProduct != "Family" && this.internationTravelForm.value.coverageType != "familyPlan";
  }

  constructFetchPlansRequest() {
    //goeCode
    let GeoCode = '';
    if (this.internationTravelForm.value.travelLoc === 'USAorCanada' || this.internationTravelForm.value.tripType === 'multiTrip') {
      GeoCode = 'WORLDWIDE';
    } else if (this.internationTravelForm.value.travelLoc === 'schengenCountries') {
      GeoCode = 'SCHENGEN COUNTRIES';
    } else if (this.internationTravelForm.value.travelLoc === 'asia') {
      GeoCode = 'ASIA';
    } else {
      GeoCode = 'EXCLUSCANADA';
    }
    let isMultiTrip = this.internationTravelForm.value.tripType == 'multiTrip';
    //travel type
    let TravelType = 1; // Default value
    if (this.internationTravelForm.value.subProduct === 'Individual') {
      TravelType = 1;
    } else if (this.internationTravelForm.value.subProduct === 'Family') {
      TravelType = 2;
    } else if (this.internationTravelForm.value.subProduct === 'Senior Citizen') {
      TravelType = 3;
    }
    let leavingIndiaDate = this.internationTravelForm.value.tripType != 'multiTrip' ? this.utilityService.DateWithSlash1(this.internationTravelForm.value.leavingDate) : "";
    let policyStartDate = this.internationTravelForm.value.tripType != 'multiTrip' ? '' : this.utilityService.DateWithSlash1(this.internationTravelForm.value.startDate);
    let IsIndividualPlan = this.checkPlanStatus(this.internationTravelForm.value.subProduct);
    let request = {
      "GeoCode": GeoCode,
      "isMultiTrip": isMultiTrip,
      "LeavingIndiaDate": leavingIndiaDate,
      "PolicyStartDate": policyStartDate,
      "TravelType": TravelType,
      "NoOfTravellers": this.internationTravelForm.value.noOfTraveller,
      "CoverNeededFloater": false,
      "IndividualPlan": IsIndividualPlan,
      "FamilyPlan": !IsIndividualPlan
    }
    return request;
  }

  fetchPlans() {
    let request = this.constructFetchPlansRequest();
    this.subscription.push(this.travelService.fetchInternationtravelPlanDetails(request).subscribe({
      next: (response: any) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.internationTravelForm.patchValue({ planDetails: [] });
          this.internationTravelForm.patchValue({ planDetails: response.Data });
        }
      },
      error: error => {
      }
    })
    )
  }

  constructFetchRelationshipRequest(): RelationshipRequestPayload {
    let request = {
      "CoverageType": "INDIVIDUAL",
      "SubType": 4
    }
    return request;
  }

  fetchRelationshipMaster() {
    let request = this.constructFetchRelationshipRequest();
    this.subscription.push(this.travelService.fetchRelationshipMasterDetails(request).subscribe({
      next: (response: any) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.relationshipMaster = response.Data;
          this.internationTravelForm.patchValue({ relationshipDetails: [] });
          this.internationTravelForm.patchValue({ relationshipDetails: response.Data })
          this.patchingDone = true;
          this.loadFormContent = true;
          this.multipleServiceError = false;
        } else {
          this.multipleServiceError = true;
        }
      },
      error: error => {
      }
    })
    )
  }

  checkTravelPlanCondition() {
    if ((!this.utilityService.isEmptyOrNull(this.internationTravelForm.value.leavingDate) || !this.utilityService.isEmptyOrNull(this.internationTravelForm.value.startDate))) {
      if (this.internationTravelForm.value.tripType == 'roundTrip') {
        if (!this.utilityService.isEmptyOrNull(this.internationTravelForm.value.travelLoc)) {
          this.fetchPlans();
        }
      } else {
        this.fetchPlans();
      }
    }
  }

  constructMemberDetails() {
    let member = [];
    let planDetails = this.internationTravelForm.value.planDetails.find(x => x.PlanName == this.internationTravelForm.value.plan);
    this.internationTravelForm.value.members.forEach(element => {
      member.push({ 'DateOfBirth': this.utilityService.getFormattedDate(element.DOB, 'dd-MMM-yyyy'), 'PlanID': planDetails.PlanCode })
    })
    return member;
  }

  constructSaveQuoteRequest() {
    let request = {};
    let stateDetails = this.stateMaster.find((x: any) => x.StateName == this.internationTravelForm.value.state);
    let travLoc = this.internationTravelForm.value.travelLoc;
    let isGSTRegistered = this.internationTravelForm.value.isKeralaGST;
    let isJammuKashmir = stateDetails.StateName == 'JAMMU & KASHMIR';
    let planDetails = this.internationTravelForm.value.planDetails.find(x => x.PlanName == this.internationTravelForm.value.plan);
    let IsIndividualPlan = this.internationTravelForm.value.subProduct != "Family";
    let IsFamilyPlan = this.internationTravelForm.value.subProduct == "Family";
    let travelCoverageType = this.internationTravelForm.value.coverageType;
    let isCoverNeededIndividual = this.internationTravelForm.value.subProduct == "Family" || (this.internationTravelForm.value.subProduct == "Senior Citizen" && travelCoverageType == 'familyPlan');
    let leavingIndia = this.internationTravelForm.value.tripType == 'multiTrip' ? this.internationTravelForm.value.startDate : this.internationTravelForm.value.leavingDate;
    request = {
      "UserType": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "ipaddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.travelDataModal.ipAddress : this.channelData.CIP,
      "IsResIndia": "Yes", "PolicyStartDate": "", "PolicyEndDate": "", "IsPlanUpgrade": "No", "NoOfTravelers": this.internationTravelForm.value.noOfTraveller,
      "IsImmigrantVisa": this.internationTravelForm.value.isVisitOnImmigrantVisa ? 'YES' : 'NO', "IsIndividualPlan": IsIndividualPlan, "IsFamilyPlan": IsFamilyPlan, "IsCoverNeededIndividual": isCoverNeededIndividual,
      "IsCoverNeededFloater": false, "planIds": planDetails.PlanCode, "planName": planDetails.PlanName, "GSTStateCode": stateDetails.StateId.toString(), "GSTStateName": stateDetails.StateName, "isGSTRegistered": isGSTRegistered,
      "IsJammuKashmir": isJammuKashmir,
    }
    request['IsAnnualMultiTrip'] = this.internationTravelForm.value.tripType == 'multiTrip' ? 'Yes' : 'No';
    request['MaximumDurationPerTrip'] = this.internationTravelForm.value.subProduct == 'Individual' ? this.internationTravelForm.controls['noOfTripDays'].value : 0;
    request['TripDuration'] = this.internationTravelForm.controls['noOfTripDays'].value;
    request['LeavingIndia'] = !this.utilityService.isUndefinedORNull(leavingIndia) ? this.utilityService.getFormattedDate(leavingIndia, 'dd-MMM-yyyy') : "";
    request['ReturnIndia'] = !this.utilityService.isUndefinedORNull(this.internationTravelForm.value.returningDate) ? this.utilityService.getFormattedDate(this.internationTravelForm.value.returningDate, 'dd-MMM-yyyy') : "";
    request['IsVisitingUSCanadaYes'] = travLoc == 'USAorCanada' ? 'Yes' : 'No';
    request['IsVisitingSCHENGENYes'] = travLoc == 'schengenCountries' ? 'Yes' : 'No';
    request['IsVisitingOtherCountries'] = travLoc == 'otherCountries' ? 'Yes' : 'No';

    if (this.internationTravelForm.value.subProduct == 'Individual') {
      request['coverageType'] = this.internationTravelForm.value.tripType == 'multiTrip' ? 'SINGLE' : 'INDIVIDUAL';
      request['IsProfessionalSportCover'] = this.internationTravelForm.value.semiProfessionalSports;
      request['IsAdventureSportCover'] = this.internationTravelForm.value.adventureSports;
    } else if (this.internationTravelForm.value.subProduct == 'Family') {
      request['coverageType'] = 'FAMILY';
      request['IsProfessionalSportCover'] = this.internationTravelForm.value.semiProfessionalSports;
      request['IsAdventureSportCover'] = this.internationTravelForm.value.adventureSports;
    } else if (this.internationTravelForm.value.subProduct == 'Senior Citizen') {
      request['coverageType'] = 'SENIOR';
      request['IsProfessionalSportCover'] = false;
      request['IsAdventureSportCover'] = false;
    }

    if (travelCoverageType != 'familyPlan') {
      request['IsIndividualPlan'] = true; request['IsFamilyPlan'] = false;
    } else {
      request['IsIndividualPlan'] = false; request['IsFamilyPlan'] = true;
    }

    if (this.internationTravelForm.value.subProduct != 'Family' && travelCoverageType != "familyPlan") {
      request['planType'] = 'SINGLE';
    } else {
      request['planType'] = 'FAMILY';
    }
    request['Members'] = this.constructMemberDetails();
    return request;
  }

  getMemberRequest() {
    let members = [];
    let adultDetails = this.getAdultDetails(); let childDetails = this.getChildDetails();
    if (adultDetails.length > 0) {
      adultDetails.forEach((element, i) => {
        members.push({ DOB: this.utilityService.formatDate(element.DOB), MemberType: healthMembers.Adult, RelationshipwithApplicant: element.relationship })
      });
    }
    if (childDetails.length > 0) {
      childDetails.forEach((element, j) => {
        members.push({ DOB: this.utilityService.formatDate(element.DOB), MemberType: healthMembers.Child, RelationshipwithApplicant: element.relationship })
      });
    }

    return members;
  }

  getQuote(event) {
    this.isSubmitted = true;
    if (!this.internationTravelForm.valid) {
      for (const key of Object.keys(this.internationTravelForm.controls)) {
        if (this.internationTravelForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
          invalidControl.focus();
          break;
        }
      }
      this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
    } else {
      let insuredMembers = this.internationTravelForm.value.members.filter(x => x.RelationshipwithApplicant == "Self");
      if (insuredMembers.length > 1) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('SELF can be selected only for one Insured');
        this.popupService.openGenericPopup(this.errorPopupData);
        return;
      } else {
        let request = this.constructSaveQuoteRequest();
        this.subscription.push(this.travelService.saveInternationalQuoteDetails(request).subscribe({
          next: (response) => {
            if (response.StatusCode == Constants.statusCode_success) {
              this.setInternationalFormData(response?.Data);
              if (!this.utilityService.isEmptyOrNull(this.travelFormData.premiumFormData.totalPremium) && this.travelFormData.premiumFormData.totalPremium != '0') {
                this.customStepperService.emitStepData({ status: true, buttonId: 'f1' });
              }
              else {
                this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
              }
              this.showPremiumDetails(true);
            } else if (response.StatusCode != Constants.statusCode_success) {
              this.multipleServiceError = false;
              this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            }
          },
          error: error => {
          }
        })
        )
      }
    }
  }

  getAdultDetails() {
    return this.internationTravelForm.value.members.filter(x => x.MemberType == healthMembers.Adult) || [];
  }

  getChildDetails() {
    return this.internationTravelForm.value.members.filter(x => x.MemberType == healthMembers.Child) || [];
  }

  setInternationalFormData(response: any) {
    this.travelFormData.quoteFormData = this.internationTravelForm.value;
    this.travelFormData.quoteFormData.productName = this.internationTravelForm.controls['productName'].value;
    this.travelFormData.quoteFormData.noOfTripDays = this.internationTravelForm.controls['noOfTripDays'].value;

    this.travelFormData.quoteFormData.stateId = this.stateMaster.find(x => x.StateName == this.internationTravelForm.value.state).StateId;
    this.travelFormData.quoteFormData.planCode = this.internationTravelForm.value.planDetails.find(x => x.PlanName == this.internationTravelForm.value.plan).PlanCode;
    this.travelFormData.quoteFormData.adultDetails = this.getAdultDetails();
    this.travelFormData.quoteFormData.childDetails = this.getChildDetails();
    this.travelFormData.quoteFormData.totalMemberData = this.getMemberRequest();
    this.travelFormData.quoteFormData.relationshipDetails = this.relationshipMaster;
    this.internationTravelForm.value.members.forEach((member, index) => {
      let relationshipData = this.relationshipMaster.filter(x => x.RelationshipName == member['RelationshipwithApplicant'])
      this.travelFormData.quoteFormData['members'][index]['relationshipId'] = relationshipData[0].RelationshipID;
    })
    this.travelFormData.quoteFormData.quoteSummary = this.createQuoteSummary();
    this.travelFormData.quoteDataSet = true;
    this.constructPremiumData(response);
    this.setOtherFormFlags();
    this.travelDataStorageService.setTravelDataStorage(this.travelFormData);
  }

  createQuoteSummary() {
    let addons = [];
    if (this.internationTravelForm.value.subProduct != 'Senior Citizen') {
      this.travelModal.addonCovers.forEach((x) => {
        addons.push({
          coverText: x["coverText"],
          coverId: x["coverId"],
          coverCode: x["coverCode"],
          [x.coverCode]: this.internationTravelForm.value[x.coverCode]
        });
      });
    }
    return addons;
  }

  constructPremiumData(response: any) {
    //country 
    let country = '-'; // Default value
    if (this.internationTravelForm.value.travelLoc === 'USAorCanada') {
      country = 'USA / Canada';
    } else if (this.internationTravelForm.value.travelLoc === 'schengenCountries') {
      country = 'Schengen Country';
    } else if (this.internationTravelForm.value.travelLoc === 'asia') {
      country = 'Asia';
    }
    let finalCountry = this.internationTravelForm.value.subProduct == 'Individual' && this.internationTravelForm.value.tripType == 'multiTrip' ? '-' : country;
    let totalPremium = this.internationTravelForm.value.tripType == 'multiTrip' ? response.PremiumMutiTrip : response.PremiumPayable;
    this.travelFormData.premiumFormData.totalPremium = totalPremium;
    this.travelFormData.premiumFormData.basicPremium = response.BasicPremium;
    this.travelFormData.premiumFormData.totalTax = response.TotalGSTAmt;
    this.travelFormData.premiumFormData.quoteId = response.QuoteID;
    this.travelFormData.premiumFormData.startDate = this.internationTravelForm.value.leavingDate;
    this.travelFormData.premiumFormData.endDate = this.internationTravelForm.value.returningDate;
    this.travelFormData.premiumFormData.numberOfTraveller = this.internationTravelForm.value.noOfTraveller;
    this.travelFormData.premiumFormData.totalDays = this.internationTravelForm.value.noOfTripDays;
    this.travelFormData.premiumFormData.country = finalCountry;
    this.travelFormData.premiumFormData.plan = this.internationTravelForm.value.plan;
    this.travelFormData.premiumFormData.productName = this.internationTravelForm.value.productName;

    this.travelFormData.premiumDataSet = true;
    this.formValid = true;
  }

  showPremiumDetails(data: boolean) {
    this.customFooterService.showPremiumDetails(data);
  }

  setOtherFormFlags() {
    if (this.travelFormData?.insuredFormData?.insuredMemberDetails.length) {

      this.travelFormData.insuredFormData.insuredMemberDetails.forEach((member, index) => {
        member['controls'].forEach((control, controlIndex) => {
          let controlName = control['controlName'];
          if (controlName == 'applicantRelationship') {
            if (this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][controlIndex][controlName] != this.travelFormData.quoteFormData.members[index]['RelationshipwithApplicant']) {
              this.travelFormData.insuredDataSet = false;
            }
            this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][controlIndex][controlName] =
              this.travelFormData.quoteFormData.members[index]['RelationshipwithApplicant'];
          }
          else if (controlName == 'dob') {
            if (this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][controlIndex][controlName] != this.travelFormData.quoteFormData.members[index]['DOB']) {
              this.travelFormData.insuredDataSet = false;
            }
            this.travelFormData.insuredFormData.insuredMemberDetails[index]['controls'][controlIndex][controlName] =
              this.travelFormData.quoteFormData.members[index]['DOB'];
          }
        })
      })
      this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
    }
  }

  onRefresh(status) {
    if (status) {
      this.fetchStateMaster();
      this.fetchRelationshipMaster();
      this.fetchListofSchengenStates();
      this.fetchListofEuropeanUnionMemberStates();
    }
  }
  calculateEndDate(event) {
    let leavingDate = this.internationTravelForm.value.leavingDate;
    let returningDate = this.internationTravelForm.value.returningDate;
    let noOfDays = (event.target.value - 1);
    if (this.utilityService.isUndefinedORNull(leavingDate) && this.utilityService.isUndefinedORNull(returningDate)) {
      alert('Please select Leaving date');
    } else if ((!this.utilityService.isUndefinedORNull(leavingDate) && this.utilityService.isUndefinedORNull(returningDate)) ||
      (!this.utilityService.isUndefinedORNull(leavingDate) && !this.utilityService.isUndefinedORNull(returningDate))) {
      let returningDate = new Date(leavingDate.getTime() + (noOfDays * 24 * 60 * 60 * 1000));
      this.internationTravelForm.patchValue({ 'returningDate': returningDate });
    } else if (this.utilityService.isUndefinedORNull(leavingDate) && !this.utilityService.isUndefinedORNull(returningDate)) {
      let leavingDate = new Date(returningDate.getTime() - (noOfDays * 24 * 60 * 60 * 1000));
      this.internationTravelForm.patchValue({ 'leavingDate': leavingDate });
    }
  }
  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }
  openPopup(travel) {
    this.declarationService.openDeclarationPopup(travel)
  }
}
