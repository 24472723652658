import { ResponseStructure } from "./responseStructure";

export interface HealthAdvantageResponsePayload extends ResponseStructure {
    Data: HealthAdvantageStructure
}



export interface HealthAdvantageStructure {
    PolicyTenure: string[],
    PEDWaitingperiod: string[],
    CoPay: string[],
    ProductType: string[],
    AdditionalCover: AdditionalCoverDetails[],
    Occupation: {},
    PolicyPlan: PolicyPlan[],
    AddonDetails: AddOnCover[] | ElevateAddonCover[],
    Relationships: string[],
    IncomeRange: IncomeRange[],
    SumInsuredDetails: SumInsured[],
    IsVaccinationDiscountEnabled: boolean,
    befitCovers: BefitCover[],
    BefitApplicableCities: string[],
    IsBefitCityApplicabilityChkON: boolean,
    IsKycFlag: true
    IsKycManadatory: true
    DealID: string,
    PolicyRange: string,
    SubProductCode?: string,
    PortabilitySubProductCode?: string,
    productCode?: string
}

export interface AdditionalCoverDetails {
    CVR_NAME: string,
    CVR_CODE: string,
}


export interface PolicyPlan {
    POLICY_PLAN: string,
    SI_VALUE: string,
}

export interface CommonAddOnCover {
    ApplicableSubProductList: string[]
    CoverId: number,
    CoverCode: string,
    CoverText: string,
    CoverVariantType: string,
    Description: string,
    IsForAdult: boolean,
    IsForChild: boolean,
    IsForFemaleAdult: boolean,
    SiLimit: number,
    disabled: boolean,
    position: number,
    maxAgeAllowed: number,
    IsAddonSelected: boolean,
    IsVisible: boolean,
    OrionCoverCode?: string
}

export interface ElevateAddonCover extends CommonAddOnCover {
    groups: number,
    groupName?: string
}

export interface AddOnCover extends CommonAddOnCover {
    groups: string,
}

export interface IncomeRange {
    Id: number,
    Name: string,
    INCOME_LOWER_LIMIT: string,
}


export interface SumInsured {
    SumAmount: number,
    VDValues: HealthVoluntaryDedutible[],
}

export interface BefitCoverBenefits {
    BenefitName: string,
    BenefitValue: string,
    BenefitValueType: string,
}

export interface BefitCover {
    CoverId: number,
    CoverName: string,
    DisplayName: string,
    IsHMP: boolean,
    befitCoverBenefits: BefitCoverBenefits[],
}

export interface HealthVoluntaryDedutible {
    value: number,
}

export class StepperCheckDataQuote {
    isSuccess: boolean;
    isEMIApplicable: boolean;
}

export interface ZoneRequestPayload {
    subproductcode?: string;
    productcode: string;
    State: string;
    City: string;
    IsOrion?: boolean;
    CorrelationId?: string // There is no need of this param in request payload
    Pincode?: string;
}

export interface ZoneRefillingRequestPayload {
    productcode: string;
    State: string;
    City: string;
    subproductcode: string,
    CorrelationId?: string // There is no need of this param in request payload
    Pincode?: string;
}

export interface ZoneResponsePayload extends ResponseStructure {
    Data: ZoneResponseStructure
}

export interface ZoneResponseStructure {
    zone: string,
    correlationId: string,
}

export interface ZoneRefillingResponsePayload extends ResponseStructure {
    Data: ZoneResponseStructure
}

export interface ZoneRefillingResponsePayload {
    zone: string,
    correlationId: string,
}

export interface Member {
    MemberType?: string,
    TitleID?: string,
    Name?: string,
    RelationshipID?: string,
    RelationshipName?: string,
    DOB?: string,
    Height?: string,
    Weight?: string,
    isExisting?: string,
    AdultType?: string,
    OtherDisease?: string,
    AddOnAgeGroup?: string,
    AddOnName?: string,
    Gender?: string,
    PanNumber?: string,
    GroupFlagCount?: number
    IsOtherPolicy?: boolean,
    InsuranceCompany?: string,
    OtherPolicyNo?: string,
    PolicyValidFrom?: number,
    PolicyValidTill?: number,
    ClaimsMade?: boolean,
    ExistingHealthPolicy?: ExistingHealthPolicy,
    Ailments?: HealthMemberAilments[],
    DateOfBirth?: Date,
    PlanID?: string,
    Relation?: string,
    AgeGroup?: string,
    SumInsuredText?: string,
    SumInsuredValue?: string,
    InsuredName?: string,
    InsuredDOB?: string,
    RelationshipwithApplicant?: string,
    AgeValue?: string,
    SumInsured?: string,
    AgeGroupValue?: string,
    PassportNumber?: string,
    MotherMaidenName?: string,
    NomineeName?: string,
    AilmentName?: string,
    TreatmentDetails?: string,
    DoctorAndHospitalDetails?: string,
    AddOnName6?: string,
    PortabilityWaiver?: string,
    PortabilityDOJ?: string,
    PortabilitySI?: string,
    Occupation?: string,
    IsPneumococcalVaccination?: boolean,
    VaccinationDate?: string,
}

export interface ExistingHealthPolicy {
    IsExisting?: string,
    Member?: string,
    TypeOfPolicy?: string,
    PolicyDuration?: number,
    InsuranceCompany?: string,
    SumInsured?: number,
}

export interface HealthMemberAilments {
    RadioButtonChecked?: string,
    CheckButtonChecked?: boolean,
    MemberAilmentID?: number,
    HealthMemberID?: number,
    AilmentStartDate?: Date,
    AilmentID?: number,
    OtherAilment?: string,
    MappedAilmentId?: string,
    AgeOfInsured?: number,
    PEDCode?: string,
    AilmentName?: string,
    Month?: string,
    Year?: string,
    PEDRemarks?: string,
}

export interface MemberV1 {
    AddOnName?: string,
    AddOnAgeGroup?: string,
    DOB?: string,
}

export interface SaveEditProposalV1ResponsePayload {
    ErrorText: string,
    StatusCode: number,
    StatusDesc: string,
    StatusMsg: string,
    StatusType: number,
    policyDetails: PolicyDetails;
}


export interface PolicyDetails {
    BasicPremium: string;
    CustomerId: number;
    DealId: string;
    EPolicyID: string;
    InstallmentNetPremium?: any;
    InstallmentTaxAmount?: any;
    InstallmentTotalPremium?: any;
    PfCustomerId: string;
    PfProposalNumber: string;
    PfQuoteId: string;
    PolicyEndDate: string;
    PolicyID: number;
    PolicyStartDate: string;
    PolicyStatus: number;
    PolicySubType: number;
    PolicyType: number;
    ProposalNumber: string;
    QuoteId: number;
    Tenure: number;
    TotalPremium: string;
    TotalTax: string;
    policyId: number;
}

export interface SaveEditProposalV1RequestPayload {
    UserType?: {}, //no need
    IpAddress?: string,
    ProductType: string,
    SubProductType: number,
    QuoteId: number,
    PfQuoteId: number,
    isIbankRelationship?: boolean,
    BankRelationship?: IBankRelationship,
    Members: Member[],
    NomineeName: string,
    // NomineeTitleID: number,
    NomineeRelationShipID: number,
    NomineeRelationShipText: string,
    NomineeDOB: string,
    HUF: string,
    PayMode: string,
    isAppointeeRequired: boolean,
    AppointeeName: string,
    AppointeeTitleID: number,
    AppointeeRelationShipID: number,
    AppointeeRelationShip: string,
    AppointeeDOB: string,
    CustomerID: number,
    CKYCId: string,
    EKYCid: string,
    ILKYCReferenceNumber: string,
    PfCustomerId: string,
    PortabilityTypeOfPolicy?: string,
    PortabilitySumInsured?: string,
    PreviousPolicies?: PreviousPoliciesDetails,
    PortabilityWaiver?: string,
    PortabilityDOJ?: string,
    PortabilitySI?: string,
    SpDetails?: SPPolicyDetails,
    SaveProposal: boolean,
    Product24?: string,
    ProposalDate?: string,
    SubLimit?: string,
    isCombo?: boolean,
    ComboLanNo?: string,
    IsOtherLoadingDiscount?: boolean,
    OtherLoadingDiscountPercentage?: string,
    IsBefit?: boolean,
    BefitPlan?: string,
    IsSoftCopyDiscountApplicable: boolean,
    PreHospitalization: string,
    PostHospitalization: string,
    PEDWaitingPeriod: string,
    SpecificConditionWaiting: string,
    SubProductCode: string,
    ProposalAnnualIncome: string,
    CPolicyNumber?: string,
    VoluntaryCopaymentPercentage: string,
    ZoneUpgrade: boolean,
    Zone?: string,
    RevisedZone: string,
    ProposalAnnualIncomeRange: string,
    Pincode: string,
    InstallmentApplicable?: string,
    InstallmentFrequency?: string,
    IsCHIRefile?: boolean;
    BancaToken: string,
    SaveQuoteId: number
}

export interface IBankRelationship {
    IsIbankRelationship: boolean,
    ApsId: string,
    CustomerRefNo: string,
}

export interface PreviousPoliciesDetails {
    InsurerCompany: string,
    PolicyNo: string,
    PolicyStartDate: string,
    PolicyEndDate: string,
    TotalPremium: string,
    SumInsured: string,
    Waiver: number,
    ReasonOfPortability: string,
}

export interface SPPolicyDetails {
    AlternateRMCode: string,
    AlternateRMName: string,
    CustomerReferenceNumber: string,
    ChannelName: string,
    PrimaryRMCode: string,
    SecondaryRMCode: string,
    BancaField01: string,
    BancaField02: string,
    BancaField03: string,
}

export interface SaveEditProposalV1ResponsePayload extends ResponseStructure {
    Data: SaveEditProposalV1Structure
}

export interface SaveEditProposalV1Structure {
    PolicyID: number,
    EPolicyID: string,
    ProposalNumber: string,
    PfProposalNumber: string,
    CustomerId: number,
    PfCustomerId: string,
    PolicySubType: number,
    PolicyType: number,
    PolicyStartDate: string,
    PolicyEndDate: string,
    Tenure: number,
    PolicyStatus: number,
    QuoteId: number,
    PfQuoteId: string,
    BasicPremium: string,
    TotalTax: string,
    TotalPremium: string,
    DealId: string,
}
export interface SaveQuoteResponsePayload extends ResponseStructure {
    Data: SaveQuoteResponseStructure
}
export interface SaveQuoteResponseStructure {
    CorrelationId: string;
    ProductType: string;
    SumInsured: string;
    QuoteId: number;
    IqmsNo?: number;
    Tenure: string;
    BasicPremium: string;
    TotalTax: string;
    TotalPremium: string;
    HospitalizationCover: string;
    OPDCover: string;
    PFQuoteID: number;
    GSTStateCode: string;
    GSTStateName: string;
    TaxType: string;
    CalculateHigherSI: boolean;
    PlanCode: string;
    PlanName: string;
    HigherSIPremium: string;
    Zone: string;
    SubProductCode: string;
    VoluntaryCopaymentPercentage: number;
    Policystatus: string;
    UpgradedZone: string;
    InstallmentNetPremium: string;
    InstallmentTaxAmount: string;
    InstallmentTotalPremium: string;
    basePremiumAgeslabPlanSuminsuredTablePremium: string;
    basePremiumWithAddons: string;
    OtherorUWloadingandPolicylevelUWloadingBasePremium: string;
    basePremiumWithDiscounts: string;
    CoverPremiums: string;
    CibilScorePercentage?: number;
    CibilScore?: number;
}

export interface GetConstitutionOfBusinessResponsePayLoad extends ResponseStructure {
    Data: GetConstitutionOfBusinessResponseStructure[]
}

export interface GetConstitutionOfBusinessResponseStructure {
    constitutionOfBusinessName: string,
    constitutionOfBusinessValue: string
}

export interface GetGSTCustomerTypeResponsePayLoad extends ResponseStructure {
    Data: GetGSTCustomerTypeResponseStructure[]
}

export interface GetGSTCustomerTypeResponseStructure {
    customerTypeName: string,
    customerTypeValue: string
}