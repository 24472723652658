import { EventEmitter, Injectable } from '@angular/core';
import { AgentServiceA } from './../proxy-services/AuthService/agent.service';
import { NavigationStart, Router } from '@angular/router';
import { userEnums } from '../enums/userEnums';
import { LocalStorageService, SessionStorageService, StorageService } from '../services/storage.service';
import { routeEnums } from '../enums/routeEnums';
import { DeveloperLoginService } from 'src/app/developer-dashboard/services/developer-login.service';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChannelData } from '../interface/swapPrameters';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { UtilityService } from '../services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isNotAuthenticated: EventEmitter<void> = new EventEmitter<void>();
  isAuthenticated: EventEmitter<void> = new EventEmitter<void>();
  currentUrl: string;
  login = environment.uat ? `/${routeEnums.UAT}/${routeEnums.LOGIN}` : `/${routeEnums.LOGIN}`;
  channelData: ChannelData;
  constructor(
    private router: Router,
    private agentService_A: AgentServiceA,
    private sessionStorageService: SessionStorageService,
    private devLoginService: DeveloperLoginService,
    private storageService: StorageService,
    private healthDataStorageService: HealthDataStorageService,
    private localStorageService: LocalStorageService,
    private utility_service: UtilityService
  ) {
    this.router.events.pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        this.currentUrl = e.url;
        this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
      });
  }

  check() {
    if (this.sessionStorageService.has(userEnums.AuthToken) && this.sessionStorageService.has(userEnums.UopqksmlN)) {
      this.isAuthenticated.emit();
      return true;
    } else {
      this.isNotAuthenticated.emit();
      setTimeout(() => {
        if (this.currentUrl != undefined && (this.currentUrl.includes(routeEnums.DEVELOPER) || this.currentUrl.includes(routeEnums.SWAP) || this.currentUrl.includes(routeEnums.PaymentLinkSummary) || this.currentUrl.includes(routeEnums.OrionPaymentLinkSummary))) {
          this.router.navigate([this.currentUrl]); // Developer dashboard login, Swap and Payment link page
        }

        else {
          if (window.location.hostname === 'localhost' || environment.sanity || environment.blue) {
            this.router.navigate(['/', routeEnums.LOGIN]);
          } else {
            if (!this.utility_service.isEmptyOrNull(this.channelData)) {
              if (this.channelData.CN.includes('MOTOR') || this.channelData.CN.includes('MFA')) {
                window.location.href = environment.nysaMotorLoginRedirection;
                return;
              } else if (this.channelData.CN.includes('BANCA')) {
                window.location.href = environment.nysaBancaLoginRedirection;
                return;
              }
            } else {
              window.location.href = environment.nysaMotorLoginRedirection;
              return;
            }
            this.router.navigate(['/', routeEnums.LOGIN]);
          }
        }
      }, 1);
      return false;
    }
  }

  logout() {
    this.agentService_A.logout();
  }

  logoutRedirect() {
    if (this.router.url.includes(`${routeEnums.DEVELOPER}`)) {
      this.devLoginService.logoutRedirectToLogin();
    } else {
      if (this.channelData.CN.includes('MOTOR') || this.channelData.CN.includes('MFA')) {
        window.location.href = environment.nysaMotorLoginRedirection;
        this.clearAfterLogout();
        return;
      }
      this.agentService_A.logoutRedirectToLogin();
    }
  }

  clearAfterLogout() {
    this.healthDataStorageService.resetHealthDataStorage();
    this.localStorageService.clearLocal();
    this.sessionStorageService.clear();
  }
}