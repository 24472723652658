import { Component, ElementRef, EventEmitter, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { concatMap, forkJoin, Observable, of, Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { HEPZone, healthDays, healthMembers, healthProducts, healthProposalProductCode, healthToggle, healthYears, patterns, zoneDetails, zoneHEPDetails } from 'src/app/shared/enums/healthEnums';
import { HealthDetails, SaveEditQuoteV1Request } from 'src/app/shared/interface/transactionHealthAdvantege';
import { MasterStateResponsePayload, StateDetails } from 'src/app/shared/interface/moterMaster';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { HealthAdvantageResponsePayload, IncomeRange, ZoneRequestPayload, ZoneResponsePayload, StepperCheckDataQuote } from 'src/app/shared/interface/health-advantedge';
import { HealthPlanMasterRequestPayload, RelationshipRequestPayload, RelationshipResponsePayload } from 'src/app/shared/interface/healthMaster';
import { MatStepper } from '@angular/material/stepper';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { popupDescriptionMsg } from 'src/app/shared/enums/popupEnums';
import { MatSelect } from '@angular/material/select';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { WindowResizeService } from 'src/app/shared/services/window-resize.service';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { HealthDataStorage, PremiumDetailsData } from '../../health.modal';
import { HealthDataStorageService } from '../../health.service';
import { AddonStructure, HAPModal } from '../hap/hap-modal';
import { HealthElitePlusService } from 'src/app/shared/proxy-services/health-elite-plus.service';
import { HealthElitePlusResponsePayload, GetAddonResponse, SumInsured } from 'src/app/shared/interface/healthElitePlus';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { ConvertSavedQuotesResponsePayload, SavedQuote } from 'src/app/shared/interface/savedQuotes';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { StepData } from 'src/app/shared/interface/custom-stepper';
import { HEPModal } from './hep-modal';
@Component({
  selector: 'app-health-elite-plus',
  templateUrl: './health-elite-plus.component.html',
  styleUrls: ['./health-elite-plus.component.scss']
})
export class HealthElitePlusComponent {
  @ViewChild('textCreate', { static: false }) textCreateComponent: ElementRef;
  @Output() QuoteValueEmitter = new EventEmitter<{ Quote: boolean, Portability: boolean, StatusCode: boolean }>();
  hepDataModal: HAPModal = new HAPModal(); //hap data modal 
  hepFormData: HealthDataStorage;
  hepForm: FormGroup;
  hepModal: HEPModal = new HEPModal(); //hep data modal 
  formSubmitted: boolean = false;
  formReset: boolean = false;
  healthProducts = healthProducts; // health enums
  occupationMaster = [];
  adult1Occupation = [];
  adult2Occupation = [];
  policyRangeData = [];
  tenurePeriod: string[] = [];
  hepMasterData: HealthElitePlusResponsePayload;
  annualIncome: IncomeRange[] = [];
  adultRelationShips = [];
  childRelationShips = [];
  adultCountData = this.hepDataModal.adultCount;
  childCountData = this.hepDataModal.childCount;
  adultData = [];
  childData = [];
  selectedAdult = [];
  selectedChild = [];
  totalAdultChildData = [];
  subscription: Subscription[] = [];
  states: StateDetails[] = [];
  sumInsuredDetails: SumInsured[] = [];
  cityData = [];
  addOns = [];
  personalAccidentAddons: Array<AddonStructure> = [];
  befitAddons: Array<AddonStructure> = [];
  nonMemberBasedAddons: Array<AddonStructure> = [];
  showMaternityDivider: boolean = false;
  showPersonalAccidentDivider: boolean = false;
  premiumDetails: PremiumDetailsData;
  stateDetails = this.hepDataModal.stateDetails;
  zoneMappedWithPincode: string = '';
  invalidPinCodeMessage: string;
  transparentSpinner: boolean = false;
  stateFetched: boolean = false;
  adult2DateError: boolean = false;
  //adultRelationShipError: boolean = false;
  success: boolean = false;
  showAddons: boolean = false;
  occupationFieldDisable: boolean = false;
  isVaccinationDiscountEnabled: boolean = false;
  oneMemberRequired: boolean = false;
  multipleServiceError: boolean = false;
  refreshPopupActivated: boolean = false;
  loadFormContent: boolean = false;
  patchingDone: boolean = false;
  tenureDuration: number = 0;
  previousAdultValue: string = '';
  previousChildValue: string = '';
  errorPopupData: PopupModal;
  reload = false;
  insuredMinMaxDate: { maxDate: Date; minDate: Date; };
  vaccineMinMaxDate: { maxDate: Date; minDate: Date; };
  selectedOption = 'No';
  statuscode: boolean;
  stepperCheckData: Array<StepperCheckDataQuote> = [];
  @ViewChild('quotePanel') quotePanel: MatExpansionPanel;
  @ViewChild('premiumQuotePanel') premiumQuotePanel: MatExpansionPanel;
  disableButton: boolean = false;
  minValue: number = 0;
  maxValue: number;
  stepValue: number;
  selectedValue: number;
  @ViewChild(MatStepper) stepper: MatStepper;
  portability: boolean = false;
  formValid: boolean = false;
  isInvalid: boolean = false;
  datepickerMobile: boolean = false;
  initialFormState = {};
  upgradeZoneFlag: boolean = false;
  zoneList = [];
  dealId: string = '';
  quoteDetails: ConvertSavedQuotesResponsePayload;
  saveQuoteCounter = 0;
  quoteConvertData: SavedQuote;
  steps: StepData[] = [];
  channelData: ChannelData;
  adultValue: number = 1;

  constructor(private fb: FormBuilder,
    public utilityService: UtilityService,
    private healthElitePlusService: HealthElitePlusService,
    private healthAdvantedgeService: HealthAdvantedgeService,
    private healthMasterService: HealthMasterService,
    private rtoListService: RTOListService,
    private popupService: PopupService,
    private healthDataStorageService: HealthDataStorageService,
    private renderer: Renderer2,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    private sessionStorageService: SessionStorageService,
    private windowResizeService: WindowResizeService,
    private el: ElementRef,
    private storageService: StorageService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.windowResizeService.getResizeObservable().subscribe(data => {
      this.datepickerMobile = data.width < 768;
    })
    this.onLoadSteps();
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
  }

  onLoadSteps() {
    this.premiumDetails = this.healthDataStorageService.initializePremiumData()
    this.hepFormData = this.healthDataStorageService.getHealthDataStorage();
    // IPAA-176: Saved Quotes Convert Patching
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    if (this.quoteDetails) {
      this.quoteConvertData = this.quoteDetails.Data;
      this.updateSavedQuotesConvertData();
      this.customFooterService.showPremiumDetails(true);
    }
    if (this.hepFormData.quoteDataSet) {
      this.patchingDone = false;
      this.zoneMappedWithPincode = this.hepFormData.quoteFormData.zone;
    }
    this.createhepForm();
    this.fetchHEPDetails();

    // IPAA-176: Saved Quotes Convert state Patching
    if (this.quoteConvertData) {
      this.fetchDataAsPerPincode(null, this.quoteConvertData.Pincode);
    }
  }

  updateSavedQuotesConvertData() {
    this.success = true;
    this.hepFormData.quoteDataSet = true;
    let quoteFormData = this.hepFormData.quoteFormData;
    this.adultRelationShips = this.quoteConvertData.AdultRelationShips;
    this.childRelationShips = this.quoteConvertData.ChildRelationShips;
    quoteFormData.productType = this.quoteConvertData.ProductType;
    quoteFormData.pincode = this.quoteConvertData.Pincode;
    quoteFormData.policyTenure = this.tenureSelectionAsPerNumber(this.quoteConvertData.Tenure);
    quoteFormData.policyRange = this.quoteConvertData.PolicyRange;
    quoteFormData.applicantAnnualSum = this.quoteConvertData.Members[0].SumInsured.toString();
    quoteFormData.applicantAnnualIncome = this.quoteConvertData.ProposalAnnualIncomeRange;
    quoteFormData.InstallmentApplicable = this.quoteConvertData.InstallmentApplicable;
    quoteFormData.InstallmentFrequency = this.quoteConvertData.InstallmentFrequency;
    quoteFormData.addonsFormdata = this.quoteConvertData.hepAddonsFormData;
    quoteFormData.zone = this.quoteConvertData.Zone;
    quoteFormData.cityState = this.quoteConvertData.CityState;
    quoteFormData.copaymentPercentTaken = this.quoteConvertData.VoluntaryCopaymentPercentage;
    this.zoneMappedWithPincode = this.hepFormData.quoteFormData.zone;
    this.hepFormData.premiumFormData.InstallmentTotalPremium = parseInt(this.quoteConvertData.InstallmentTotalPremium);
    quoteFormData.productType = this.quoteConvertData.ProductType;
    quoteFormData.zoneUpgrade = this.quoteConvertData.ZoneUpgrade;
    quoteFormData.reviseZone = this.quoteConvertData.RevisedZone;
    quoteFormData.productName = healthProducts.hep;
    quoteFormData.healthProposalProductType = healthProposalProductCode.hep;
    quoteFormData.subProductType = this.hepDataModal.subProductType.hep;
    quoteFormData.adultRelationShips = this.quoteConvertData.AdultRelationShips;
    quoteFormData.childRelationShips = this.quoteConvertData.ChildRelationShips;
    this.hepFormData.premiumFormData.PfQuoteId = this.quoteConvertData.GenericData.pfQuoteId;
    quoteFormData.copaymentPercentTaken = this.quoteConvertData.VoluntaryCopaymentPercentage;
    quoteFormData.corelationId = this.quoteConvertData.CustomerDetails.CorelationId;
    quoteFormData.productCode = this.quoteDetails.Data.GenericData.productCode;
    this.healthDataStorageService.setHealthDataStorage(this.hepFormData);
  }

  tenureSelectionAsPerNumber(data: number) {
    let tenureSelect;
    if (data == 1) {
      tenureSelect = this.hepDataModal.tenurePeriod[0];
    } else if (data == 2) {
      tenureSelect = this.hepDataModal.tenurePeriod[1];
    } else {
      tenureSelect = this.hepDataModal.tenurePeriod[2];
    }
    return tenureSelect;
  }

  isSelected(event: any, fromvalue: string): boolean {
    event.preventDefault();
    event.stopPropagation();
    return fromvalue === event.value;
  }

  //form construction
  createhepForm() {
    let upgradeZoneOption;

    if (this.hepFormData.quoteDataSet) {
      if (this.hepFormData.quoteFormData.zoneUpgrade) {
        upgradeZoneOption = healthToggle.Yes;
      } else {
        upgradeZoneOption = healthToggle.No;
      }
    } else {
      upgradeZoneOption = healthToggle.No;
    }
    let selectedZone;
    if (this.hepFormData.quoteDataSet) {
      if (this.hepFormData.quoteFormData.zoneUpgrade) {
        selectedZone = this.hepFormData.quoteFormData.reviseZone;
      } else {
        selectedZone = '';
      }
    } else {
      selectedZone = '';
    }
    let copaymentPercentTaken;
    if (this.hepFormData.quoteDataSet) {
      if (this.hepFormData.quoteFormData.copaymentPercentTaken == null) {
        copaymentPercentTaken = healthToggle.No;
      } else {
        copaymentPercentTaken = this.hepFormData.quoteFormData.copaymentPercentTaken + '%';
      }
    } else {
      copaymentPercentTaken = healthToggle.No;
    }
    // patch annual sum insured if quote data is already present
    this.hepForm = this.fb.group({
      productName: [healthProducts.hep],
      productType: [this.hepFormData.quoteDataSet ? this.hepFormData.quoteFormData.productType : this.hepDataModal.productType[0]],
      pincode: [this.hepFormData.quoteFormData.pincode, [Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6)]],
      cityState: ['', Validators.required],
      upgradeZoneOption: [upgradeZoneOption],
      selectedZone: [selectedZone],
      adultDetails: this.fb.array([]),
      childDetails: this.fb.array([]),
      policyTenure: [this.hepFormData.quoteDataSet ? this.hepFormData.quoteFormData.policyTenure : this.hepDataModal.tenurePeriod[0], Validators.required],
      policyRange: [this.hepFormData.quoteDataSet ? this.hepFormData.quoteFormData.policyRange : this.hepFormData.quoteFormData.policyRange[0], Validators.required],
      applicantAnnualSum: [this.hepFormData.quoteDataSet ? this.hepFormData.quoteFormData.applicantAnnualSum : '', [Validators.required]],
      pedWaitingPeriod: [healthYears.TwoYear],
      conditionWaitingPeriod: [healthYears.TwoYear],
      preHospitalDuration: [healthDays.SixtyDays],
      postHospitalDuration: [healthDays.OneEightyDays],
      softCopyDiscount: ['Yes'],
      copaymentPercentTaken: [copaymentPercentTaken],
      EMI: [this.hepFormData.quoteDataSet ? this.hepFormData.quoteFormData.InstallmentApplicable : 'No',],
      EMIInstallment: [this.hepFormData.quoteDataSet ? this.hepFormData.quoteFormData.InstallmentFrequency : ''],
    })
    // Creating adult and child controls and patching existing data if present
    this.patchExistingMemberData();

    this.hepForm.valueChanges.subscribe((data) => {
      // Below logic works when data is already patched .OR. User comes back and changes value. Only when premium is set
      // Now when user tries to change the value the stepper should reset to quote page
      // And premium should hide
      if (this.patchingDone) {
        // premium data set flag is used beacsuse user can come back and try to change the values.
        // In that case also we need to reset the stepper

        // And if user refreshes that means existing value is getting patched. In that case we are using the patchingDone flag
        // and resetting the premium footer
        if (JSON.stringify(this.hepForm.value) != JSON.stringify(this.initialFormState)) {
          this.showPremiumDetails(false);
          // Here we are resetting the stepper. Just to be sure if the quote is changed.
          // User needs to traverse through each steps
          // if (!this.quoteDetails) {
          //   this.customStepperService.resetStepper(true);
          // }
          if (this.quoteDetails) {
            // Below block is written to identify value changes due to save quote
            if (this.saveQuoteCounter == 0) {
              this.customStepperService.saveQuoteDataChanges(true);
              this.sessionStorageService.remove(userEnums.ConverToProposal);
              this.saveQuoteCounter++;
              this.quoteDetails = null;
            }
          }
          this.customStepperService.resetStepper(true);
        }
      }
    })
  }

  patchExistingMemberData() {
    if (this.hepFormData.quoteDataSet || this.quoteConvertData) {
      // IPAA-176: Patch AdultDetails and ChildDetails for Convert API
      if (this.quoteConvertData && this.quoteConvertData.AdultDetails) {
        this.hepFormData.quoteFormData.adultDetails = this.quoteConvertData.AdultDetails.map((data: any, i) => ({
          [`dobAdult${(i + 1)}`]: data.dob,
          [`adultRealtionship${(i + 1)}`]: data.relationship,
          [`adultVaccinationStatus${(i + 1)}`]: data.vaccineStatus,
          [`adultVaccinationDate${(i + 1)}`]: data.vaccineDate ? new Date(data.vaccineDate) : null,
          [`Gender${(i + 1)}`]: data.Gender
        }));
      }
      if (this.quoteConvertData && this.quoteConvertData.ChildDetails) {
        this.hepFormData.quoteFormData.childDetails = this.quoteConvertData.ChildDetails.map((data: any, i) => ({
          [`dobChild${(i + 1)}`]: new Date(data.dob),
          [`childRelationship${(i + 1)}`]: data.relationship,
          [`Gender${(i + 1)}`]: data.Gender
        }));
      }

      if (this.hepFormData.quoteFormData.adultDetails.length > 0) {
        this.hepFormData.quoteFormData.adultDetails.forEach((data, index) => {
          this.adultDetails.push(this.createAdultDetails(index));
          // Patch values of adults
          // Below block is written because when there is self data that get's patched from kyc is not in date format
          // So to handle it we need to convert in into date type
          for (let adultKeys in data) {
            if (adultKeys.includes('dob')) {
              let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
              this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
            }
            else {
              this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
            }
          }
        })

        // We need to highlight the selected adult also
        let selectedAdultIndex = this.hepFormData.quoteFormData.adultDetails.length - 1;
        this.adultCountData.forEach((adultCount, index) => {
          if (index == selectedAdultIndex) {
            adultCount.selected = true;
          }
          else {
            adultCount.selected = false;
          }
        })
      }

      if (this.hepFormData.quoteFormData.childDetails.length > 0) {
        this.hepFormData.quoteFormData.childDetails.forEach((data, index) => {
          this.childDetails.push(this.createChildDetails(index));
          // Patch values of adults
          for (let childKeys in data) {
            this.childDetails.at(index).get(childKeys).setValue(data[childKeys]);

          }
        })

        // We need to highlight the selected child also
        // If user has selected only one child then other child needs to hide that would be in case of 0 adults
        let selectedChildIndex = this.hepFormData.quoteFormData.childDetails.length - 1;
        this.childCountData.forEach((childCount, index) => {
          if (this.hepFormData.quoteFormData.adultDetails.length == 0) {
            // Disabling adult selected
            this.adultCountData[0].selected = false;
            this.adultCountData[1].selected = false;
            if (index == 0) {
              childCount.selected = true;
              childCount.hide = false;
            }
            else {
              childCount.selected = false;
              childCount.hide = true;
            }
          }
          else {
            if (index == selectedChildIndex) {
              childCount.selected = true;
              childCount.hide = false;
            }
            else {
              childCount.selected = false;
            }
          }
        })
      }

      // IPAA-176: Patch totalMemeber object for insured page Convert API
      if (this.quoteConvertData && (this.adultDetails.length > 0 || this.childDetails.length > 0)) {
        this.hepFormData.quoteFormData.totalMemberData = this.getMemberRequest();
        this.healthDataStorageService.setHealthDataStorage(this.hepFormData);
      }
    }
    else if (this.hepFormData.quickquoteDataSet) {
      this.getadultchilddataforquickquote();
    }
    else {
      this.adultDetails.push(this.createAdultDetails(0));
    }
  }

  get adultDetails(): FormArray {
    return this.hepForm.get('adultDetails') as FormArray;
  }

  get childDetails(): FormArray {
    return this.hepForm.get('childDetails') as FormArray;
  }

  vaccineToggleConvert(data: boolean) {
    let vaccingToggle = healthToggle.No;
    if (data) {
      vaccingToggle = healthToggle.Yes;
    } else {
      vaccingToggle = healthToggle.No;
    }
    return vaccingToggle;
  }

  createAdultDetails(index?): FormGroup {
    return new FormGroup({
      ['dobAdult' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultRealtionship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultVaccinationStatus' + `${index + 1}`]: new FormControl(healthToggle.No, [Validators.required]),
      ['adultVaccinationDate' + `${index + 1}`]: new FormControl(''),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  createChildDetails(index?): FormGroup {
    return new FormGroup({
      ['dobChild' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['childRelationship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  createAddons(control, defaultValue): FormGroup {
    return new FormGroup({
      [`${control}`]: new FormControl(defaultValue)
    })
  }
  fetchHEPDetails() {
    this.transparentSpinner = false;
    // If below solution does not work try calling api individually
    this.subscription.push(this.requestDataFromMultipleSources().subscribe({
      next: (responseList: [HealthElitePlusResponsePayload, RelationshipResponsePayload]) => {
        this.multipleServiceError = false;
        this.handleMultipleResponse(responseList);
      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    })
    )
  }


  handleMultipleResponse(responseList: [HealthElitePlusResponsePayload, RelationshipResponsePayload]) {
    if (responseList[0].StatusCode != Constants.statusCode_success || responseList[1].StatusCode != Constants.statusCode_success) {
      this.multipleServiceError = true;
    }
    else {
      this.multipleServiceError = false;
      this.manageHEPMasterData(responseList[0]);
      this.manageHealthRelationShipsData(responseList[1]);
      if (!this.hepFormData.quoteDataSet && this.hepFormData.quickquoteDataSet) {
        this.patchQuickQuoteData();
      }
    }
  }

  requestDataFromMultipleSources(): Observable<[HealthAdvantageResponsePayload, RelationshipResponsePayload]> {
    let proposalData: RelationshipRequestPayload = { product: Constants.healthProposalProductCodeChi };
    const agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.dealId = agentDetails.MappedProduct.Health.HealthCHIDealId;
    const UserID: string = String(agentDetails.AgentID)
    let chiMasterPayload: HealthPlanMasterRequestPayload = {
      UserID: UserID,//"13931457",
      SubType: this.hepDataModal.subProductType.hep.toString(),//"33",
      isCSC: true,
      isCHIRefileFlag: true
    }
    return this.healthElitePlusService.getHEPMasterData(chiMasterPayload).pipe(
      // concatMap operator to sequentially call getHealthAdvantagePlusMasterData
      concatMap(healthResponse => {
        // forkJoin to concurrently call the other two methods
        // The responses from these three requests are combined into an array and emitted as a single observable
        return forkJoin([
          // of(healthResponse) within the forkJoin to include the result of the first API call in the final array of responses. 
          of(healthResponse),
          this.healthMasterService.getHealthProposalRelationships(proposalData)
        ]);
      })
    );
  }

  // handles hap master data
  manageHEPMasterData(hepResponse: HealthElitePlusResponsePayload) {
    if (hepResponse.StatusCode == Constants.statusCode_success) {
      let addOns = [];
      this.multipleServiceError = false;
      this.policyRangeData = hepResponse.Data.PolicyRange ? hepResponse.Data.PolicyRange.split(",") : this.hepDataModal.hapPolicyRange;
      this.hepMasterData = hepResponse;

      if (this.hepMasterData.Data.PolicyTenure.length > 0) {
        // This needs to be changed from BE .
        let tempTenure: string[] = this.hepMasterData.Data.PolicyTenure[0].split(',').map((data, index) => data + ' ' + (index == 0 ? 'Year' : 'Years'));
        if (tempTenure.length > 0) {
          this.tenurePeriod = tempTenure;
        }
      }

      if (!this.hepFormData.quoteDataSet) {
        this.hepForm.controls['policyRange'].setValue(this.policyRangeData[0]);
      }
      this.changePolicyRange(this.hepFormData.quoteDataSet ? this.hepFormData.quoteFormData.policyRange : this.policyRangeData[0]);
      if (this.hepFormData.quoteDataSet) {
        this.hepForm.controls['applicantAnnualSum'].setValue(this.hepFormData.quoteFormData.applicantAnnualSum);
        this.fetchDataAsPerPincode(null, this.hepFormData.quoteFormData.pincode)
      }
      this.isVaccinationDiscountEnabled = this.hepMasterData.Data.IsVaccinationDiscountEnabled;
      addOns = this.hepMasterData.Data.AddonDetails.length > 0 ? this.hepMasterData.Data.AddonDetails : [];
      // IPAA-176: Saved quotes addon patching
      if (this.quoteConvertData && this.quoteConvertData.hepAddonsFormData) {
        let hepAddons = this.quoteConvertData.hepAddonsFormData;
        addOns.forEach(addOn => {
          if (Object.keys(hepAddons).includes(addOn.CoverText)) {
            addOn.IsAddonSelected = hepAddons[addOn.CoverText];
          } else {
            addOn.IsAddonSelected = hepAddons["BefitSelectionAddon"] == addOn.CoverText ? true : false;
          }
        }
        );
      }
      let occupationMaster = this.hepMasterData.Data.Occupation ? this.hepMasterData.Data.Occupation : '';
      for (let i in occupationMaster) {
        this.occupationMaster.push({ key: occupationMaster[i], value: i });
      }
      this.occupationMaster = this.utilityService.sortDetails(this.occupationMaster, "value");
      if (this.hepFormData.quoteDataSet) {
        this.patchExistingAddons();
        let zoneUpgrade = this.hepFormData.quoteFormData.zoneUpgrade ? healthToggle.Yes : healthToggle.No;
        let revisedZone = this.hepFormData.quoteFormData.zoneUpgrade ? this.hepFormData.quoteFormData.reviseZone : '';
        if (this.hepFormData.quoteFormData.zone) {
          if (this.hepFormData.quoteFormData.zone == HEPZone.proposalZone1) {
            this.upgradeZoneFlag = false;
          } else {
            this.upgradeZoneFlag = true;
          }
        } else {
          this.upgradeZoneFlag = false;
        }

        if (this.upgradeZoneFlag) {
          this.hepForm.controls['upgradeZoneOption'].setValue(zoneUpgrade);
          this.hepForm.controls['selectedZone'].setValue(revisedZone);
          if (this.hepFormData.quoteFormData.zone == zoneDetails.Zone3) {
            this.zoneList = [this.hepDataModal.HEPzoneData[0], this.hepDataModal.HEPzoneData[1]];
          }
          else if (this.hepFormData.quoteFormData.zone == zoneDetails.Zone2) {
            this.zoneList = [this.hepDataModal.HEPzoneData[0]];
          }
        }
      } else if (!this.hepFormData.quoteDataSet) {
        this.hepFormData.quoteFormData.addonList = this.hepMasterData.Data.AddonDetails;
        this.constructAddOnDetails(addOns);
      }
    }
  }

  // handle state list
  manageStateResponseData(stateResponse: MasterStateResponsePayload) {
    if (stateResponse.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      if ((stateResponse.Data).length > 0) {
        let sortedStates = stateResponse.Data.sort((a, b) => a.StateName.localeCompare(b.StateName));
        this.states = sortedStates;
      }
      else {
        this.multipleServiceError = true;
      }
    }
  }

  // handles member relationships 
  manageHealthRelationShipsData(response: RelationshipResponsePayload) {
    let adultRelations = [];
    let childRelations = [];
    if (response.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      if (response.Data.InsuredRelationship.length > 0) {
        adultRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Adult);
        childRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Child);
        let sortedAdultRelations = [];
        if (adultRelations.length > 0) {
          sortedAdultRelations = adultRelations.sort((a, b) => a.RelationshipName.localeCompare(b.RelationshipName));
        }
        this.adultRelationShips = sortedAdultRelations;
        let sortedChildRelations = [];
        if (childRelations.length > 0) {
          sortedChildRelations = childRelations.sort((a, b) => a.RelationshipName.localeCompare(b.RelationshipName));
        }
        this.childRelationShips = sortedChildRelations;
        this.loadFormContent = true;
      }
      else {
        this.adultRelationShips = [];
        this.childRelationShips = [];
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    }
  }

  patchExistingAddons() {
    this.nonMemberBasedAddons = this.hepFormData.quoteFormData.nonMemberBasedAddons;
    // Making new controls for addons
    for (let controls in this.hepFormData.quoteFormData.addonsFormdata) {
      this.hepForm.addControl(controls, this.fb.control(this.hepFormData.quoteFormData.addonsFormdata[controls]));
    }

    // Disable certain fields if they have that cover names
    if (this.nonMemberBasedAddons.length > 0) {
      let annualSum = this.hepFormData.quoteFormData.applicantAnnualSum;
      this.nonMemberBasedAddons.forEach(data => {
        // If world wide cover value is selected as yes and annual sum is >= 2500000 then disable it
        if (data.coverId == this.hepDataModal.addOnCoverIds.sixtythree && parseInt(annualSum) >= this.hepDataModal.maxSumInsured) {
          if (this.hepForm.controls[data.label].value) {
            data.disable = true;
          }
          else {
            data.visible = false;
          }
        }
        // If cover is domestic ans tele consultation please disable those values
        // Right now i have kept this block seperately for future. But it can be merged with above function
        if (data.coverId == this.hepDataModal.addOnCoverIds.six || data.coverId == this.hepDataModal.addOnCoverIds.ten) {
          if (this.hepForm.controls[data.label].value) {
            data.disable = true;
          }
        }
      })
    }
  }

  hasError(controlName: string, errorCode: string): boolean {
    return !this.hepForm.valid && this.hepForm.hasError(errorCode, [controlName]);
  }

  // Fetch statename according to pincode
  fetchDataAsPerPincode(event, existingPincode) {
    if (existingPincode == null) {
      this.hepFormData.quoteDataSet = false;
    }

    this.formReset = false;
    this.stateFetched = false;
    this.invalidPinCodeMessage = '';
    this.cityData = [];
    if ((existingPincode != null ? existingPincode : event.target.value.length == 6) && !this.hepForm.controls['pincode'].hasError('pattern')) {
      let data: RTOListRequestPayload = {
        "Pincode": existingPincode != null ? existingPincode.toString() : event.target.value,
        "CityID": ""
      }
      this.transparentSpinner = true;
      this.invalidPinCodeMessage = '';
      this.hepForm.controls['cityState'].reset();
      this.upgradeZoneFlag = false;
      this.hepForm.controls['upgradeZoneOption'].setValue(healthToggle.No);
      this.hepForm.controls['selectedZone'].setValue('');
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.length > 0) {
              this.stateDetails.stateId = response.Data[0].StateId ? response.Data[0].StateId : '';
              this.stateDetails.stateName = response.Data[0].StateName ? response.Data[0].StateName : '';
              this.stateDetails.pincode = existingPincode != null ? existingPincode : event.target.value;
              response.Data.forEach(cityList => {
                this.cityData.push({ 'cityLabel': cityList['CityName'], 'stateLabel': this.stateDetails.stateName, 'value': cityList['CityID'] });
              });
            }
            if (this.cityData.length == 1) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.getZoneData();
              this.hepForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
            }

            // IPAA-176: Patch City and State for Convert API
            if (this.quoteDetails && this.cityData.length > 0) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.getZoneData();
              this.hepForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
              this.hepFormData.quoteFormData.cityId = this.stateDetails.cityId;
            }
            // Need to check below in backward patching
            if (this.hepFormData.quoteDataSet) {
              this.hepForm.controls['cityState'].setValue(this.hepFormData.quoteFormData.cityState);
              this.changeCityName(this.hepFormData.quoteFormData.cityId, false);
            }
            if (this.hepForm.controls['applicantAnnualSum'].value != "") {
              this.getAddonData();
            }
            this.stateFetched = true;
          }

          else if (response.StatusCode == Constants.statusCode_failure) {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            this.stateFetched = false;
            this.invalidPinCodeMessage = response.StatusDesc;
          }

          else {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          }
        },
        error: error => {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.invalidPinCodeMessage = '';
          this.stateFetched = false;
        }
      })
      )
    }
    this.utilityService.numberOnly(event);
    this.utilityService.restrictSpace(event);
  }

  getZoneData() {
    let params: ZoneRequestPayload = {
      "productcode": (this.hepMasterData.Data.ProductCode).toString(),
      "State": this.stateDetails.stateName,
      "City": this.stateDetails.cityName
    }
    this.subscription.push(this.healthAdvantedgeService.getZoneFromCityData(params).subscribe({
      next: (response: ZoneResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          let zoneData = response.Data.zone;
          this.zoneMappedWithPincode = zoneData;
          if (zoneData) {
            if (zoneData == this.hepDataModal.HEPzoneData[0] || zoneData == this.hepDataModal.HEPzoneData[3]) {
              this.upgradeZoneFlag = false;
            } else {
              this.upgradeZoneFlag = true;
            }
          } else {
            this.upgradeZoneFlag = false;
          }
          if (zoneData == this.hepDataModal.HEPzoneData[2]) {
            this.zoneList = [this.hepDataModal.HEPzoneData[0], this.hepDataModal.HEPzoneData[1]];
          }
          else if (zoneData == this.hepDataModal.HEPzoneData[1]) {
            this.zoneList = [this.hepDataModal.HEPzoneData[0]];

          }
          else {
            this.zoneList = [];
          }
        }
        else {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.zoneMappedWithPincode = '';
        }
      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
      }
    }))
  }

  changePolicyTenure(tenure) {
    // If policy Tenure is more than 1 year then Emi Values need to be reset
    if (tenure != this.hepDataModal.tenurePeriod[0]) {
      this.hepForm.controls['EMI'].patchValue("No")
      this.hepForm.controls['EMIInstallment'].setValue('');
    }
  }

  changeCityName(cityValue: string, showSpinner: boolean) {
    this.stateDetails.cityId = cityValue;
    this.stateDetails.cityName = this.cityData.length > 0 ? (this.cityData.find(data => data.value == cityValue).cityLabel) : '';
    if (showSpinner) {
      ;
      this.getZoneData();
    }
  }

  changePolicyRange(range: string) {
    if (this.hepMasterData.Data) {
      let plans = this.hepMasterData.Data.SumInsuredDetails;
      let si = plans.filter(data => data.PolicyRange === range);
      this.sumInsuredDetails = si;
      this.minValue = 0;
      this.maxValue = 100;
      this.stepValue = 1;
      this.selectedValue = 50;
    }
  }
  emiValueChanged() {
    if (this.hepForm.controls['EMI'].value == healthToggle.Yes) {
      this.hepForm.controls['EMIInstallment'].setValue('Monthly');
    }
    else {
      this.hepForm.controls['EMI'].setValue(healthToggle.No);
      this.hepForm.controls['EMIInstallment'].setValue('');
    }
  }

  changeInProductType() {
    // Check if portability selected reset emi values
    if (this.hepForm.value.productType != 'New') {
      this.hepForm.controls['EMI'].setValue(healthToggle.No);
      this.hepForm.controls['EMIInstallment'].setValue('');
      // this.hepForm.controls['EMIInstallment'].clearValidators();
      // this.hepForm.controls['EMIInstallment'].updateValueAndValidity();
      this.healthDataStorageService.setHealthDataStorage(this.hepFormData);
      this.emiValueChanged();
      this.portabilityDataPresent(true);
    }
    else {
      this.portabilityDataPresent(false);
    }
  }

  portabilityDataPresent(data: boolean) {
    this.customStepperService.portabilityPresent(data);
  }

  showPremiumDetails(data: boolean) {
    this.customFooterService.showPremiumDetails(data);
  }

  getAddonData() {
    let params = {
      "SumInsured": this.hepForm.value.applicantAnnualSum,
      "Pincode": this.hepForm.value.pincode,
      "SubProductCode": this.hepMasterData.Data.SubProductCode,
      "ProductCode": this.hepMasterData.Data.ProductCode
    }
    this.subscription.push(this.healthElitePlusService.getHEPAddonList(params).subscribe({
      next: (response: GetAddonResponse) => {
        if (response.StatusCode === Constants.statusCode_success) {
          let NewAddOnCoverDetails = response.Data.NewAddOnCoverDetails;
          this.hepMasterData.Data.AddonDetails = NewAddOnCoverDetails;
          this.hepFormData.quoteFormData.addonList = this.hepMasterData.Data.AddonDetails;
          this.healthDataStorageService.setHealthDataStorage(this.hepFormData);
          let addOns = this.hepMasterData.Data.AddonDetails.length > 0 ? this.hepMasterData.Data.AddonDetails : []
          this.nonMemberBasedAddons = [];
          this.befitAddons = [];
          this.constructAddOnDetails(addOns)
          this.hepFormData.quoteFormData.nonMemberBasedAddons = this.nonMemberBasedAddons;
          let sumInsured = this.hepForm.controls['applicantAnnualSum'].value ? Number(this.hepForm.controls['applicantAnnualSum'].value) : 0;
          this.nonMemberBasedAddons.forEach(addonData => {
            // Worldwide cover should be visible if summ exceeds or equals to 2500000
            if (addonData.coverId == this.hepDataModal.addOnCoverIds.sixtythree) {
              addonData.disable = true;
              this.hepForm.controls[addonData.label].disable();
              if (sumInsured >= this.hepDataModal.maxSumInsured) {
                addonData.visible = true;
                this.hepForm.controls[addonData.label].setValue(true);
              }
              else {
                addonData.visible = false;
                this.hepForm.controls[addonData.label].setValue(false);
              }

            }
          })
          if (this.hepFormData.quoteDataSet && !this.patchingDone) {
            this.patchingDone = true;
          }
        }
        else {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
        }
      },
      error: error => {
        this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
      }
    }))
  }

  annualSumChanged(value) {
    if (this.hepForm.controls['pincode'].value) {
      this.getAddonData();
    }
  }

  memberTabClicked(event) {
    this.stopPropogation(event);
  }

  stopPropogation(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  incrementMember() {
    if (this.adultValue == 1) {
      this.adultValue = 2;
      this.adultDetails.push(this.createAdultDetails(1));
    } else {
      this.utilityService.popUpDialog('Cannot select more than 2 members');
    }
  }

  decrymentMember() {
    if (this.adultValue == 2) {
      this.adultValue = 1;
      this.adultDetails.removeAt(1);
    } else {
      this.utilityService.popUpDialog('Cannot select less than 1 member');
    }
  }

  // Adult count selected
  adultCountSelected(adultValue: string, event) {
    // adult count entire logic is set on adult data present in hap modal
    // toggle is handled by selected sttribute for each object
    let index = parseInt(adultValue) - 1;
    let selectedChild = this.fetchSelectedChild();
    switch (adultValue) {

      // If adult 1 is selected then we need to set selected attribute true
      case '1': {
        if (!this.adultCountData[index].selected) {
          this.adultCountData[index].selected = true;
          this.adultCountData[index + 1].selected = false;

          // Also enable second child because we have a condition for 0A 1C only should be there
          // SO if it's hidden unhide it
          if (this.childCountData[1].hide) {
            this.childCountData[1].hide = false;
            this.childCountData[1].selected = false;
          }

          // If two adults present in form array remove second adult as only 1A needed
          if (this.adultDetails.length > 1) {
            this.adult2DateError = false;
            this.adultDetails.removeAt(this.adultDetails.length - 1);
          }

          // If no adults presents in form array as again 1A is selected then we need to push in array
          // Push one adult in form array
          else if (this.adultDetails.length == 0) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(0));
          }

          // If one adult is selected then make third child hidden. SO for 1A 2C can be selected and also unselect them
          this.childCountData[2].hide = true;
          this.childCountData[2].selected = false;

          // if 3C is presnt in form array then remove third child from form array
          if (this.childDetails.length == 3) {
            // If third child was selected then we need to remove all child details from form array
            if (selectedChild[0].value == '3') {
              this.childDetails.clear();
            }
            else {
              this.childDetails.removeAt(this.childDetails.length - 1);
            }
          }
        }
        // But if user tries to click again on adult 1 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        else {
          // Now if he tries to uncheck adult. We need to allow uncheck only when any of the child is selcted otherwise we won't allow
          // Uncheck to be allowed only when child is present
          if (selectedChild.length == 0) {
            event.preventDefault();
            event.stopPropagation();
            this.utilityService.popUpDialog('Atleast one member is required');
          }
          // If uncheck can happen set the value of that adult as false.
          else {
            // Remove all adult details
            this.adultDetails.clear();
            this.adultCountData[index].selected = false;
            // If no adult is selected then only 1 child should be visible
            this.childCountData.forEach((childData, index) => {
              childData.hide = index == 0 ? false : true;
              childData.selected = index == 0 ? true : false;
            })

            // Remove child elements if present
            if (this.childDetails.length == 2) {
              this.childDetails.removeAt(this.childDetails.length - 1);
            }

          }
        }
        break;
      }

      // If adult 2 is selected then we need to set selected attribute true
      case '2': {
        if (!this.adultCountData[index].selected) {
          this.adultCountData[index].selected = true;
          this.adultCountData[index - 1].selected = false;

          // Push one adult in form array if one adult is already present
          if (this.adultDetails.length == 1) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(1));
          }

          // If no adult is present then push two adults
          else if (this.adultDetails.length == 0) {
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(0));
            this.adultDetails.push(this.createAdultDetails(1));
          }

          // If two adult is selected then make one child visible. SO for 2A 3C can be selected and also unselect them
          this.childCountData[2].selected = false;
          this.childCountData[2].hide = false;

          // If first child is hidden unhide it
          if (this.childCountData[1].hide) {
            this.childCountData[1].hide = false;
            this.childCountData[1].selected = false;
          }
        }
        // But if user tries to click again on adult 2 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        else {
          // Now if he tries to uncheck adult. We need to allow uncheck only when any of the child is selcted otherwise we won't allow
          // Uncheck to be allowed only when child is present
          if (selectedChild.length == 0) {
            event.preventDefault();
            event.stopPropagation();
            this.utilityService.popUpDialog('Atleast one member is required');
          }
          // If user tries to uncheck 2A but he has selected 3C. Then we can't allow uncheck
          else if (selectedChild.length > 0 && selectedChild[0].value == '3') {
            event.preventDefault();
            event.stopPropagation();
            this.utilityService.popUpDialog('3C can be selected on 2A only. To uncheck Please select 1A');
          }
          else if (selectedChild.length > 0) {
            // Remove all adult details
            this.adultDetails.clear();
            this.adultCountData[index].selected = false;
            // If no adult is selected then only 1 child should be visible
            this.childCountData.forEach((childData, index) => {
              childData.selected = index == 0 ? true : false;
              childData.hide = index == 0 ? false : true;
            })
            // Remove child elements if present
            if (this.childDetails.length == 2) {
              this.childDetails.removeAt(this.childDetails.length - 1);
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  // Child count selected
  childCountSelected(childValue: string, event) {
    // child count entire logic is set on child data present in hap modal
    // toggle is handled by selected attribute for each object
    let index = parseInt(childValue) - 1;
    let selectedAdult = this.fetchSelectedAdult();
    switch (childValue) {
      // If child 1 is selected then we need to set selected attribute true
      case '1': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;

          // As it is check box and 1C is selected we need to ensure other 2C & 3C is unchecked
          this.childCountData[index + 1].selected = false;
          this.childCountData[index + 2].selected = false;

          // If 0C is present push one data in child array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
          }

          // If 2C is present in child details form array remove second child
          else if (this.childDetails.length == 2) {
            this.childDetails.removeAt(this.childDetails.length - 1);
          }

          // If 3C is present then remove last two child
          else if (this.childDetails.length == 3) {
            this.childDetails.removeAt(this.childDetails.length - 1);
            this.childDetails.removeAt(this.childDetails.length - 1);
          }

        }

        // And if no adult is selected then child can't be unchecked otherwise uncheck can happen
        else if (selectedAdult.length == 0) {
          event.preventDefault();
          event.stopPropagation();
          this.utilityService.popUpDialog('Atleast one member is required');
        }
        // if it can be unchecked set value as false of that unchecked child
        else if (selectedAdult.length > 0) {
          this.childCountData[index].selected = false;
          // clear child details
          this.childDetails.clear();
        }
        break;
      }

      // If child 2 is selected then we need to set selected attribute true
      case '2': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;
          // As it is check box and 2C is selected we need to ensure other 1C & 3C is unchecked
          this.childCountData[index - 1].selected = false;
          this.childCountData[index + 1].selected = false;

          // If 0C is present push two data in child array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
            this.childDetails.push(this.createChildDetails(1));
          }

          // If 1C is present push one data in child array
          else if (this.childDetails.length == 1) {
            this.childDetails.push(this.createChildDetails(1));
          }

          // If 3C is present in child details form array remove third child
          else if (this.childDetails.length == 3) {
            this.childDetails.removeAt(this.childDetails.length - 1);
          }
        }

        // And if no adult is selected then child can't be unchecked otherwise uncheck can happen
        else if (selectedAdult.length == 0) {
          event.preventDefault();
          event.stopPropagation();
          this.utilityService.popUpDialog('Atleast one member is required');
        }
        // if it can be unchecked set value as false of that unchecked child
        else if (selectedAdult.length > 0) {
          this.childCountData[index].selected = false;
          // clear child details
          this.childDetails.clear();
        }
        break;
      }

      // If child 3 is selected then we need to set selected attribute true
      case '3': {
        if (!this.childCountData[index].selected) {
          this.childCountData[index].selected = true;
          // As it is check box and 3C is selected we need to ensure other 1C & 2C is unchecked
          this.childCountData[index - 2].selected = false;
          this.childCountData[index - 1].selected = false;

          // If 0C is present then push 3 child data in form array
          if (this.childDetails.length == 0) {
            this.childDetails.push(this.createChildDetails(0));
            this.childDetails.push(this.createChildDetails(1));
            this.childDetails.push(this.createChildDetails(2));
          }
          // If 1C is present push two data in child array
          else if (this.childDetails.length == 1) {
            this.childDetails.push(this.createChildDetails(1));
            this.childDetails.push(this.createChildDetails(2));
          }
          // If 2C is present push one data in child array
          else if (this.childDetails.length == 2) {
            this.childDetails.push(this.createChildDetails(2));
          }
        }
        // But if user tries to click again on child 3 and if it is already selected that means if selected is true
        // then stop checkbox behaviour
        //In this case 2A is required and if it is checked it can't be unchecked
        else {
          event.preventDefault();
          event.stopPropagation();
          this.utilityService.popUpDialog('3C can be selected on 2A only. To uncheck Please select 1A');
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  fetchSelectedAdult() {
    return this.adultCountData.filter(element => element.selected);
  }

  fetchSelectedChild() {
    return this.childCountData.filter(element => element.selected);
  }
  onbefitAddonSelection(value) {
  }
  constructAddOnDetails(addOns) {
    if (addOns.length > 0) {
      addOns.forEach((details) => {

        // Befit Addons  based
        if (details.CoverId == this.hepDataModal.addOnCoverIds.fortyseven || details.CoverId == this.hepDataModal.addOnCoverIds.fortyeight || details.CoverId == this.hepDataModal.addOnCoverIds.fortynine || details.CoverId == this.hepDataModal.addOnCoverIds.fifty || details.CoverId == this.hepDataModal.addOnCoverIds.fiftyone || details.CoverId == this.hepDataModal.addOnCoverIds.fiftytwo || details.CoverId == this.hepDataModal.addOnCoverIds.fiftythree) {
          let addonsOccurence = 0;
          let befitAddons: AddonStructure;

          // Befit Cover A needs to have position 1
          if (details.CoverId == this.hepDataModal.addOnCoverIds.fortyseven) {
            addonsOccurence = 1;
          }
          // Befit Cover B cover needs to have position 2
          else if (details.CoverId == this.hepDataModal.addOnCoverIds.fortyeight) {
            addonsOccurence = 2;
          }
          // Befit Cover C will occur at third position
          else if (details.CoverId == this.hepDataModal.addOnCoverIds.fortynine) {
            addonsOccurence = 3;
          }
          // Befit Cover D will occur at third position
          else if (details.CoverId == this.hepDataModal.addOnCoverIds.fifty) {
            addonsOccurence = 4;
          }
          // Befit Cover E will occur at third position
          else if (details.CoverId == this.hepDataModal.addOnCoverIds.fiftyone) {
            addonsOccurence = 5;
          }
          // Befit Cover F will occur at third position
          else if (details.CoverId == this.hepDataModal.addOnCoverIds.fiftytwo) {
            addonsOccurence = 6;
          }
          // Befit Cover G will occur at third position
          else if (details.CoverId == this.hepDataModal.addOnCoverIds.fiftythree) {
            addonsOccurence = 7;
          }
          // pushing default selected befit addon cover data
          if (details.IsAddonSelected) {
            // Below logic is used to create controls of the labels 
            this.hepForm.addControl("BefitSelectionAddon", this.fb.control(details.CoverText));
          }

          befitAddons = {
            'label': details.CoverText,
            'checkboxValue': details.IsAddonSelected,
            'type': 'checkbox',
            'coverCode': details.CoverCode,
            'coverId': details.CoverId,
            'isForAdult': details.IsForAdult,
            'isForChild': details.IsForChild,
            'visiblityAsPerService': true, // temproary: details.IsVisible // If service needs to disable certain addon they can simply make it false on UI
            'visible': details.CoverId == this.hepDataModal.addOnCoverIds.sixtythree ? false : true, // There are certain addons which should be visible only as per certain condition. For such addons visiblity is used to restrict those addons in html
            'position': addonsOccurence, // This helps to determine the position of the addons for placing
            'disable': false,
          }
          this.befitAddons.push(befitAddons);
        }
        // non member based other addons
        else {
          let otherAddons: AddonStructure;
          let addonsOccurence: number = 0;
          if (details.CoverId == this.hepDataModal.addOnCoverIds.fortyfive) { // claim protector
            addonsOccurence = 1;
          }
          else if (details.CoverId == this.hepDataModal.addOnCoverIds.fortySix) {
            addonsOccurence = 2; // Sum insured protector
          }
          else if (details.CoverId == this.hepDataModal.addOnCoverIds.fiftyseven) { // nursing at home
            addonsOccurence = 3;
          }
          else if (details.CoverId == this.hepDataModal.addOnCoverIds.fiftyeight) { // compassionate visit
            addonsOccurence = 4;
          }
          else if (details.CoverId == this.hepDataModal.addOnCoverIds.sixtythree) { // world wide cover
            addonsOccurence = 5;
          }
          // pushing maternity addon cover data
          otherAddons = this.constructAddonsData(details, addonsOccurence);
          this.nonMemberBasedAddons.push(otherAddons);
        }
      })
    }
    // Sorting addons based on position
    this.utilityService.sortDetails(this.befitAddons, 'position');
    this.utilityService.sortDetails(this.nonMemberBasedAddons, 'position');
  }

  constructAddonsData(details, occurence: number): AddonStructure {

    // Below addons data is created dynamically . To handle the listof addons below structure is used
    // there are certain addons whose by default value is yes and no should not be allowed to be selected that's y checkbox data is used

    let checkBoxData: { disabledAddon: boolean, checkedAddon: boolean } = { disabledAddon: false, checkedAddon: false };
    checkBoxData.checkedAddon = details.IsAddonSelected ? true : false;
    checkBoxData.disabledAddon = details.IsAddonSelected ? true : false;

    // Below logic is used to create controls of the labels 
    this.hepForm.addControl(details.CoverText, this.fb.control(checkBoxData.checkedAddon ? true : false));

    return {
      'label': details.CoverText,
      'checkboxValue': checkBoxData,
      'type': 'checkbox',
      'coverCode': details.CoverCode,
      'coverId': details.CoverId,
      'isForAdult': details.IsForAdult,
      'isForChild': details.IsForChild,
      'visiblityAsPerService': true, // temproary: details.IsVisible // If service needs to disable certain addon they can simply make it false on UI
      'visible': details.CoverId == this.hepDataModal.addOnCoverIds.sixtythree ? false : true, // There are certain addons which should be visible only as per certain condition. For such addons visiblity is used to restrict those addons in html
      'position': occurence,// This helps to determine the position of the addons for placing
      'disable': false,
    }

  }

  adultsDateComparison() {
    if (this.adultDetails.length == 2) {
      if (this.adultDetails.value[1]['dobAdult2'] != '' && this.utilityService.dateDiff(this.adultDetails.value[1]['dobAdult2'], this.adultDetails.value[0]['dobAdult1'])) {
        this.adult2DateError = true;
      }
      else {
        this.adult2DateError = false;
      }
    }
    else {
      this.adult2DateError = false;
    }

  }

  selectOccupationOption(data, index) {
    // There are certain occupations which should not be allowed to get selected
    let risk = this.occupationMaster.find(d => d.value === data).key;
    if (risk == '3' || risk == '4') {
      this.utilityService.popUpDialog('The selected occupation is not eligible for personal accident cover.');
      this.hepForm.controls['occupationForAdult' + `${index}`].setValue("");
    }
  }

  getMinMaxDate(type: string, minMaxType: string) {
    let noOfAdult = this.adultDetails.length;
    let minYear = type == healthMembers.Child ? 26 : 66;
    let maxYear;
    if (type != healthMembers.Child) {
      maxYear = 18;
    } else if (type == healthMembers.Child && noOfAdult == 0) {
      maxYear = 6;
    } else {
      maxYear = 0;
    }
    let maxMonth = type == healthMembers.Child && noOfAdult != 0 ? 3 : '';
    let minMonth = type == healthMembers.Child && noOfAdult != 0 ? 9 : '';
    let insuredMaxYear = (type != healthMembers.Child) || (type == healthMembers.Child && noOfAdult == 0) ? new Date().getFullYear() - maxYear : new Date().getFullYear();
    let insuredMinYear = new Date().getFullYear() - minYear;
    this.insuredMinMaxDate = this.utilityService.getMinMaxDate(insuredMaxYear, insuredMinYear, maxMonth, minMonth);
    return minMaxType == 'min' ? this.insuredMinMaxDate.minDate : this.insuredMinMaxDate.maxDate;
  }

  getInsuredMinMaxDate(memberType: string, minMaxType: string) {
    if (memberType == 'Adult') {
      let adultMaxDate = this.utilityService.subtractYears(new Date(), 65);

      let yearsdate = this.utilityService.subtractYears(new Date(), 99);
      let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
      let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

      return minMaxType == 'min' ? adultMinDate : adultMaxDate;
    }
    else {
      let noOfAdults = this.adultDetails.length;
      if (noOfAdults > 0) {
        //depedent child
        let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);

        let yearDate = this.utilityService.subtractYears(new Date(), 20);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? depChildMinDate : depChildMaxDate;
      }
      else {
        //independent child
        let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

        let yearDate = this.utilityService.subtractYears(new Date(), 20);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let indChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? indChildMinDate : indChildMaxDate;
      }
    }
  }

  getMinMaxDateVaccine(minMaxType: string) {
    let minYear = 1;
    let maxYear = 0;
    let maxMonth = '';
    let minMonth = '';
    let vaccineMaxYear = new Date().getFullYear() - maxYear
    let vaccineMinYear = new Date().getFullYear() - minYear;
    this.vaccineMinMaxDate = this.utilityService.getMinMaxDate(vaccineMaxYear, vaccineMinYear, maxMonth, minMonth);
    return minMaxType == 'min' ? this.vaccineMinMaxDate.minDate : this.vaccineMinMaxDate.maxDate;
  }

  vaccinationDateValidation(index: number) {
    let adultControls = this.adultDetails.controls[index] as FormGroup;
    if (this.adultDetails.value[index]['adultVaccinationStatus' + (index + 1)] == 'Yes') {
      adultControls.controls['adultVaccinationDate' + (index + 1)].setValidators([Validators.required]);
      adultControls.controls['adultVaccinationDate' + (index + 1)].updateValueAndValidity();
    }
    else {
      adultControls.controls['adultVaccinationDate' + (index + 1)].setValue(null);
      adultControls.controls['adultVaccinationDate' + (index + 1)].clearValidators();
      adultControls.controls['adultVaccinationDate' + (index + 1)].updateValueAndValidity();
    }
  }

  createQuoteSummaryForm() {
    let arrayDetails = [];
    this.nonMemberBasedAddons.forEach(element => {
      let item = {
        coverId: element.coverId, coverCode: element.label, coverText: element.label, isAddonMemberBasis: false,//(element.IsForAdult && !element.IsForChild),
        inputType: '', isSelected: false
      }
      item[element.label] = false;
      item[element.label] = this.hepForm.getRawValue()[element.label];
      // }
      arrayDetails.push(item);

    });
    this.befitAddons.forEach(element => {
      let item = {
        coverId: element.coverId, coverCode: element.label, coverText: element.label, isAddonMemberBasis: false,//(element.IsForAdult && !element.IsForChild),
        inputType: '', isSelected: false
      }
      item[element.label] = false;
      item[element.label] = element.label == this.hepForm.value['BefitSelectionAddon'] ? true : false;
      arrayDetails.push(item);
    })
    return arrayDetails;
  }

  getQuote(event: EmitButtonData) {
    this.formReset = false;
    this.transparentSpinner = false;
    this.success = false;
    this.formSubmitted = true;
    this.hepForm.markAllAsTouched();
    let memberBasedValidation = false;

    //self case validation for more than 1 adult/child
    if (this.adultDetails.length > 0 || this.childDetails.length > 0) {
      let adultRelationshipArrCount = 0;
      let childRelationshipArrCount = 0;
      if (this.adultDetails.value.length > 0) {
        this.adultDetails.value.map((ele, index) => {
          if (ele[`adultRealtionship${index + 1}`] == 'SELF') {
            adultRelationshipArrCount++;
          }
        })
      }

      if (this.childDetails.value.length > 0) {
        this.childDetails.value.map((ele, index) => {
          if (ele[`childRelationship${index + 1}`] == 'SELF') {
            childRelationshipArrCount++;
          }
        })
      }

      if ((adultRelationshipArrCount > 1 || childRelationshipArrCount > 1) || (adultRelationshipArrCount > 0 && childRelationshipArrCount > 0)) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('SELF can be selected only for one Insured');
        this.popupService.openGenericPopup(this.errorPopupData);
        return;
      }
    }

    // If child details length is only 1 . we need to check dob present in child is valid or not
    if (this.childDetails.length == 1 && this.adultDetails.length == 0) {
      // Read Dob value if it's invalid then reset
      if (this.childDetails.at(0).get('dobChild1').status == 'INVALID') {
        this.childDetails.at(0).get('dobChild1').reset();
        this.childDetails.at(0).get('dobChild1').setValue('');
        this.childDetails.at(0).get('dobChild1').setValidators(Validators.required);
        this.childDetails.at(0).get('dobChild1').updateValueAndValidity();
      }
    }
    if (!this.hepForm.valid || this.adult2DateError || this.invalidPinCodeMessage !== '') {
      for (const key of Object.keys(this.hepForm.controls)) {
        if (this.hepForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
          invalidControl.focus();
          break;
        }
      }
      this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
    }

    else if (this.invalidPinCodeMessage == '' && !memberBasedValidation) {
      this.formSubmitted = false;
      let request: SaveEditQuoteV1Request = this.constructRequest();
      this.subscription.push(this.healthElitePlusService.saveEditQuoteData(request).subscribe({
        next: response => {
          if (response.StatusCode == Constants.statusCode_success) {
            this.statuscode = true;
            if (response.Data.HealthDetails.CorrelationId) {
              this.hepFormData.quoteFormData.corelationId = response.Data.HealthDetails.CorrelationId;
              this.healthDataStorageService.setHealthDataStorage(this.hepFormData);
            }
            if (response.Data.HealthDetails.Policystatus == "CONDITIONALAPPROVE" && this.hepForm.controls['EMI'].value == 'Yes') {
              this.errorPopupData = this.popupService.fetchErrorPopupMsg(popupDescriptionMsg.emiNotApplicable);
              this.popupService.openGenericPopup(this.errorPopupData);
              this.hepForm.controls['EMI'].patchValue("No")
              this.hepForm.controls['EMIInstallment'].setValue('');
              this.emiValueChanged();
            }
            else {
              this.setHepFormData(response?.Data.HealthDetails);
              this.disableButton = true;
              this.success = true;
              this.patchingDone = true;
              this.initialFormState = this.hepForm.value;
              // If premium exists then only allow user to proceed forward
              if (!this.utilityService.isEmptyOrNull(this.hepFormData.premiumFormData.totalPremium) && this.hepFormData.premiumFormData.totalPremium != '0') {
                this.customStepperService.emitStepData({ status: true, buttonId: 'f1' });
              }
              else {
                this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
              }
              this.showPremiumDetails(true);
            }
          }
          else if (response.StatusCode != Constants.statusCode_success) {

            this.success = false;
            this.disableButton = false;
            this.statuscode = false;
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          }
        },
        error: (err) => {
          this.success = false;
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
        }
      }))
    }
  }

  checkMemberBasedAddonsValidation() {
    let memberBasedValidation: boolean = false;
    if (this.hepForm.controls[this.personalAccidentAddons[0].label].value) {
      // Check whether occupation is selected of that fields
      if (this.utilityService.isEmptyOrNull(this.hepForm.controls['occupationForAdult1'].value) && this.utilityService.isEmptyOrNull(this.hepForm.controls['occupationForAdult2'].value)) {
        this.utilityService.popUpDialog('Please select occupation for atleast one member');
        memberBasedValidation = true;
      }
      return memberBasedValidation;
    }
    // Checking for maternity validation
    else if (this.hepForm.controls['Maternity Benefit Cover'].value) {
      if (this.utilityService.isEmptyOrNull(this.hepForm.controls['maternityForAdult'].value)) {
        this.hepForm.controls['maternityForAdult'].setValidators(Validators.required);
        this.hepForm.controls['maternityForAdult'].clearValidators();
        memberBasedValidation = true;
      }
      else {
        this.hepForm.controls['maternityForAdult'].updateValueAndValidity();
        this.hepForm.controls['maternityForAdult'].clearValidators();
      }
      return memberBasedValidation;
    }
    return memberBasedValidation;
  }

  constructRequest(): SaveEditQuoteV1Request {
    let tenure = (this.hepForm.value.policyTenure).split(' ')[0];
    this.tenureDuration = parseInt(tenure);
    let adult1DOB = this.adultDetails.value.length > 0 ? this.utilityService.formatDate(this.adultDetails.value[0]['dobAdult1']) : '';
    let child1DOB = this.childDetails.value.length > 0 ? this.utilityService.formatDate(this.childDetails.value[0]['dobChild1']) : '';
    let adult1Age = this.adultDetails.value.length > 0 ? this.utilityService.calculateAge(this.adultDetails.value[0]['dobAdult1']) : '';
    let child1Age = this.childDetails.value.length > 0 ? this.utilityService.calculateAge(this.childDetails.value[0]['dobChild1']) : '';

    let zone = this.zoneMappedWithPincode;
    let requestData: SaveEditQuoteV1Request = {
      "ProductType": this.hepForm.value.productType == 'New' ? 'N' : 'R',
      "UserType": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "NoOfAdults": (this.adultDetails.length).toString(),
      "NoOfKids": (this.childDetails.length).toString(),
      "NoOfInLaws": null,
      "AgeGroup": null,
      "SubProductType": this.hepDataModal.subProductType.hep,
      "SubLimit": null,
      "Tenure": this.tenureDuration,
      "IsJammuKashmir": this.checkIsGSTRegistered(),
      "VolDedutible": this.hepDataModal.volDedutible,
      "GSTStateCode": this.stateDetails.stateId,
      "CityCode": this.stateDetails.cityId,
      "SumInsured": this.hepForm.value.applicantAnnualSum,
      "GSTStateName": this.stateDetails.stateName ? this.stateDetails.stateName : '',
      "isGSTRegistered": this.checkIsGSTRegistered(),
      "CalculateHigherSI": false,
      "SaveQuote": false,//let's send it true in premium we are setting true
      "Members": this.getMemberRequest(),
      "IpAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hepDataModal.ipAddress : this.channelData.CIP,
      "IsOtherLoadingDiscount": false,
      "DobOfEldestMember": this.adultDetails.value.length == 0 ? child1DOB : adult1DOB,
      "EldestMemberAge": this.adultDetails.value.length == 0 ? (child1Age).toString() : (adult1Age).toString(),
      "City": this.stateDetails.cityName,
      "Pincode": (this.hepForm.value.pincode).toString(),
      "PreHospitalization": this.hepForm.controls['preHospitalDuration'].value,
      "PostHospitalization": this.hepForm.controls['postHospitalDuration'].value,
      "PEDWaitingPeriod": this.hepForm.controls['pedWaitingPeriod'].value,
      "SpecificConditionWaiting": this.hepForm.controls['conditionWaitingPeriod'].value,
      "ZoneUpgrade": this.hepForm.controls['upgradeZoneOption'].value == healthToggle.Yes ? true : false,//false,//Initially it goes false
      "Zone": zone,
      "RevisedZone": this.hepForm.controls['upgradeZoneOption'].value == healthToggle.Yes ? this.hepForm.controls['selectedZone'].value : zone,
      "IsSoftCopyDiscountApplicable": true,
      "VoluntaryCopaymentPercentage": this.hepForm.value.copaymentPercentTaken == 'No' ? null : this.hepForm.value.copaymentPercentTaken.replace('%', ''),
      "InstallmentApplicable": this.hepForm.controls['EMI'].value,
      "InstallmentFrequency": this.hepForm.controls['EMI'].value == 'Yes' ? this.hepForm.controls['EMIInstallment'].value : "",
      "IsCHIRefile": true,
      "OtherLoadingDiscountPercentage": "",
    }
    return requestData;
  }

  // Construction of addon Request
  constructAddonRequest() {
    let tempAddons = [];
    let addon: any;
    // Patching non member based addons 
    if (this.nonMemberBasedAddons.length > 0) {
      this.hepMasterData.Data.AddonDetails.forEach((addonsData: any) => {
        // Addons which are visible and the value is selected send only those values
        if (this.hepForm.getRawValue()[addonsData.CoverText]) {
          addon = {
            "CoverId": addonsData.CoverId,
            "CoverCode": `${addonsData.CoverText}_${addonsData.CoverId}`,
            "CoverText": addonsData.CoverText,
            "CoverVariantType": addonsData.CoverVariantType,
            "IsForAdult": addonsData.IsForAdult,
            "IsForChild": addonsData.IsForChild,
            "SiLimit": addonsData.SiLimit,
            "maxAgeAllowed": addonsData.MaxAgeAllowed ? addonsData.MaxAgeAllowed : 0,
            "IsAddonSelected": this.hepForm.getRawValue()[addonsData.CoverText],
            "Description": addonsData.Description ? addonsData.Description : "",
            "IsVisible": addonsData.IsVisible,
            "IsForFemaleAdult": addonsData.IsForFemaleAdult
          }
          tempAddons.push(addon);
        }
        if (this.hepForm.controls["BefitSelectionAddon"].value.includes(addonsData.CoverText)) {
          addon = {
            "CoverId": addonsData.CoverId,
            "CoverCode": `${addonsData.CoverText}_${addonsData.CoverId}`,
            "CoverText": addonsData.CoverText,
            "CoverVariantType": addonsData.CoverVariantType,
            "IsForAdult": addonsData.IsForAdult,
            "IsForChild": addonsData.IsForChild,
            "SiLimit": addonsData.SiLimit,
            "maxAgeAllowed": addonsData.MaxAgeAllowed ? addonsData.MaxAgeAllowed : 0,
            "IsAddonSelected": true,
            "Description": addonsData.Description ? addonsData.Description : "",
            "IsVisible": addonsData.IsVisible,
            "IsForFemaleAdult": addonsData.IsForFemaleAdult
          }
          tempAddons.push(addon);
        }
      })
    }
    return tempAddons;
  }

  // Quote request formation 
  checkIsGSTRegistered() {
    return this.stateDetails.stateId != this.hepDataModal.stateId ? false : true;
  }

  getMemberRequest() {
    let members = [];
    if (this.adultDetails.length > 0) {
      this.adultDetails.value.forEach((element, i) => {
        this.adultDetails.value[i]['Gender' + `${i + 1}`] = this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips);
        members.push({
          DOB: this.utilityService.formatDate(element['dobAdult' + (i + 1)]),
          MemberType: healthMembers.Adult,
          RelationshipwithApplicant: element['adultRealtionship' + (i + 1)],
          VaccinationDate: element['adultVaccinationDate' + (i + 1)] ? this.utilityService.formatDate(element['adultVaccinationDate' + (i + 1)]) : null,
          AddOnCoverDetails: this.constructAddonRequest(),
          Gender: this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips)
        })
      });
    }
    if (this.childDetails.length > 0) {
      this.childDetails.value.forEach((element, j) => {
        this.childDetails.value[j]['Gender' + `${j + 1}`] = this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips);
        members.push({
          DOB: this.utilityService.formatDate(element['dobChild' + (j + 1)]),
          MemberType: healthMembers.Child,
          RelationshipwithApplicant: element['childRelationship' + (j + 1)],
          AddOnCoverDetails: this.constructAddonRequest(),
          Gender: this.utilityService.setGender(element['childRelationship' + (j + 1)], this.childRelationShips)
        })
      });
    }
    return members;
  }

  setHepFormData(premiumData: HealthDetails) {
    let resetFlag: boolean = this.checkMemberDetails();
    this.hepFormData.quoteFormData.addonList = this.hepMasterData.Data.AddonDetails;
    if (this.hepForm.value.productType != 'New') {
      this.hepFormData.portabilityDataSet = true;
      this.hepFormData.quoteFormData.InstallmentApplicable = "No"
      this.hepFormData.quoteFormData.InstallmentFrequency = ""
    }
    else {
      this.hepFormData.portabilityDataSet = false;
    }
    this.hepFormData.quoteFormData.productCode = this.hepMasterData.Data.ProductCode;
    this.hepFormData.quoteFormData.productName = this.hepForm.controls['productName'].value;
    this.hepFormData.quoteFormData.productType = this.hepForm.controls['productType'].value;
    this.hepFormData.quoteFormData.pincode = this.hepForm.controls['pincode'].value;
    this.hepFormData.quoteFormData.adultDetails = this.adultDetails.value;
    this.hepFormData.quoteFormData.childDetails = this.childDetails.value;
    this.hepFormData.quoteFormData.cityId = this.stateDetails.cityId;
    this.hepFormData.quoteFormData.cityName = this.stateDetails.cityName;
    this.hepFormData.quoteFormData.stateId = this.stateDetails.stateId;
    this.hepFormData.quoteFormData.totalMemberData = this.getMemberRequest();
    this.hepFormData.quoteFormData.policyTenure = this.hepForm.controls['policyTenure'].value;
    this.hepFormData.quoteFormData.policyRange = this.hepForm.controls['policyRange'].value;
    this.hepFormData.quoteFormData.applicantAnnualSum = this.hepForm.controls['applicantAnnualSum'].value;
    this.hepFormData.quoteFormData.pedWaitingPeriod = this.hepForm.controls['pedWaitingPeriod'].value;
    this.hepFormData.quoteFormData.conditionWaitingPeriod = this.hepForm.controls['conditionWaitingPeriod'].value;
    this.hepFormData.quoteFormData.preHospitalDuration = this.hepForm.controls['preHospitalDuration'].value;
    this.hepFormData.quoteFormData.postHospitalDuration = this.hepForm.controls['postHospitalDuration'].value;
    this.hepFormData.quoteFormData.cityState = this.hepForm.controls['cityState'].value;
    this.hepFormData.quoteFormData.softCopyDiscount = this.hepForm.controls['softCopyDiscount'].value;
    this.hepFormData.quoteFormData.pedWaitingPeriod = this.hepForm.controls['pedWaitingPeriod'].value;
    this.hepFormData.quoteFormData.copaymentPercentTaken = this.hepForm.value.copaymentPercentTaken != 'No' ? this.hepForm.controls['copaymentPercentTaken'].value.replace('%', '') : null;
    this.hepFormData.quoteFormData.underWritterApproved = premiumData.Policystatus == "CONDITIONALAPPROVE" ? 'false' : 'true';
    this.hepFormData.quoteFormData.healthProposalProductType = healthProposalProductCode.hep;
    this.hepFormData.quoteFormData.subProductType = this.hepDataModal.subProductType.hep;
    this.hepFormData.premiumFormData.SubProductCode = premiumData.SubProductCode;
    if (this.hepForm.controls['EMI'].value == 'Yes') {
      this.hepFormData.quoteFormData.InstallmentApplicable = 'Yes';
      this.hepFormData.quoteFormData.InstallmentFrequency = this.hepForm.controls['EMIInstallment'].value;
    }
    else {
      this.hepFormData.quoteFormData.InstallmentApplicable = 'No';
    }
    this.setAddonsFormValue();
    let zone = this.zoneMappedWithPincode;
    let revisedZone = this.hepForm.controls['upgradeZoneOption'].value == healthToggle.Yes ? this.hepForm.controls['selectedZone'].value : zone;

    this.hepFormData.quoteFormData.zoneUpgrade = this.hepForm.controls['upgradeZoneOption'].value == healthToggle.Yes ? true : false;//false,//Initially it goes false
      this.hepFormData.quoteFormData.zone = zone;
    this.hepFormData.quoteFormData.reviseZone = revisedZone;
    this.hepFormData.quoteFormData.addons = this.constructAddonRequest();
    this.hepFormData.quoteFormData.befitAddons = this.befitAddons;
    this.hepFormData.quoteFormData.nonMemberBasedAddons = this.nonMemberBasedAddons;
    this.hepFormData.quoteFormData.adultRelationShips = this.adultRelationShips;
    this.hepFormData.quoteFormData.childRelationShips = this.childRelationShips;
    this.hepFormData.quoteFormData.quoteSummary = this.createQuoteSummaryForm()

    //reset data for quickquote
    this.hepFormData.quickquoteData.pincode = '';
    this.hepFormData.quickquoteData.cityState = '';
    this.hepFormData.quickquoteData.adultDetails = [];
    this.hepFormData.quickquoteData.childDetails = [];
    this.hepFormData.quickquoteData.policyTenure = '';
    this.hepFormData.quickquoteData.applicantAnnualSum = '';
    this.hepFormData.quoteDataSet = true;

    // If emi is true reset insured details
    let emiFlag: boolean = false;
    if (this.hepForm.controls['EMI'].value == healthToggle.Yes) {
      if (this.hepFormData.insuredDataSet) {
        emiFlag = true;
      }
    }
    if (resetFlag || emiFlag) {
      this.setOtherFormFlags();
    }
    this.constructPremiumData(premiumData);
    this.healthDataStorageService.setHealthDataStorage(this.hepFormData);
  }


  checkMemberDetails(): boolean {
    let nonEqualMembers = [];
    let resetFlag: boolean = false;
    // Adult Details
    // If new adult details and child details length are same then we need to check the info
    if (this.adultDetails.length == this.hepFormData.quoteFormData.adultDetails.length) {
      let existingAdultDetails = this.hepFormData.quoteFormData.adultDetails;
      existingAdultDetails.forEach((adultData, index) => {
        let tempAdultDetails = adultData;
        // When there is self detected there is change in format of dob.
        if (this.hepFormData.kycStatus.ovdSelf || this.hepFormData.kycStatus.normalSelf) {
          // convert dob in respected format
          for (let keys in tempAdultDetails) {
            // There are chances date is patched along with orignal time stamp
            if (keys.includes('dob') && !tempAdultDetails[keys].includes('T')) {
              tempAdultDetails[keys] = (new Date(tempAdultDetails[keys]));
            }
          }
        }
        // Looping through keys
        if (JSON.stringify(tempAdultDetails) != JSON.stringify(this.adultDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }
    // If the length is only not equal that means adult data is been modified
    else if (this.adultDetails.length != this.hepFormData.quoteFormData.adultDetails.length) {
      nonEqualMembers.push(0);
    }

    // Child Details
    if (this.childDetails.length == this.hepFormData.quoteFormData.childDetails.length) {
      let existingChildDetails = this.hepFormData.quoteFormData.childDetails;
      existingChildDetails.forEach((childData, index) => {
        // Looping through keys
        if (JSON.stringify(childData) != JSON.stringify(this.childDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }
    // If the length is only not equal that means adult data is been modified
    else if (this.childDetails.length != this.hepFormData.quoteFormData.childDetails.length) {
      nonEqualMembers.push(0);
    }
    else {
      // Adult members found to be equal
      nonEqualMembers = [];
    }
    // If there is mismatch of data in terms of length or details
    if (nonEqualMembers.length > 0) {
      resetFlag = true;
    }
    return resetFlag;
  }
  setOtherFormFlags() {
    this.hepFormData.insuredDataSet = false;
    this.hepFormData.kycDataSet = false;
    this.hepFormData.kycStatus.ekycMandatory = false;
    this.hepFormData.kycStatus.normal = false;
    this.hepFormData.kycStatus.normalSelf = false;
    this.hepFormData.kycStatus.ovd = false;
    this.hepFormData.kycStatus.ovdSelf = false;
    this.hepFormData.kycStatus.isKYCDoneSuccessfully = false;
    this.hepFormData.kycStatus.idType = false;
    this.hepFormData.kycStatus.certNumber = '';
    this.hepFormData.kycStatus.isPilotUser = false;
    this.hepFormData.kycFormData = this.healthDataStorageService.resetKYCdata();
  }
  setAddonsFormValue() {
    // Other non member based addons
    if (this.nonMemberBasedAddons.length > 0) {
      this.nonMemberBasedAddons.forEach(nonMemberAddons => {
        this.hepFormData.quoteFormData.addonsFormdata[nonMemberAddons.label] = this.hepForm.controls[nonMemberAddons.label].value;

      })
    }
    this.hepFormData.quoteFormData.addonsFormdata["BefitSelectionAddon"] = !this.utilityService.isEmptyOrNull(this.hepForm.get('BefitSelectionAddon')) ? this.hepForm.controls["BefitSelectionAddon"].value : '';
  }

  constructPremiumData(response: HealthDetails) {
    let zoneValue = '';
    if (response.UpgradedZone == zoneHEPDetails.Zone1) {
     zoneValue = 'A';
    } else if (response.UpgradedZone == zoneHEPDetails.Zone2) {
     zoneValue = 'B';
    } else if (response.UpgradedZone == zoneHEPDetails.Zone3) {
     zoneValue = 'C';
    } else if (response.UpgradedZone == zoneHEPDetails.Zone4) {
     zoneValue = 'D';
    }
    this.hepFormData.premiumFormData.zone = zoneValue;
    this.hepFormData.premiumFormData.totalPremium = response.TotalPremium;
    this.hepFormData.premiumFormData.basicPremium = response.BasicPremium;
    this.hepFormData.premiumFormData.totalTax = response.TotalTax;
    this.hepFormData.premiumFormData.tenure = this.tenureDuration;
    this.hepFormData.premiumFormData.QuoteId = response.QuoteId;
    this.hepFormData.premiumFormData.SubProductCode = response.SubProductCode;
    this.hepFormData.premiumFormData.PfQuoteId = response.PFQuoteID;
    this.hepFormData.premiumFormData.InstallmentNetPremium = response.InstallmentNetPremium;
    this.hepFormData.premiumFormData.InstallmentTaxAmount = response.InstallmentTaxAmount;
    this.hepFormData.premiumFormData.InstallmentTotalPremium = response.InstallmentTotalPremium;
    this.hepFormData.premiumDataSet = true;
    this.formValid = true;
  }

  getadultchilddataforquickquote() {
    if (this.hepFormData.quickquoteData.adultDetails.length > 0) {
      this.hepFormData.quickquoteData.adultDetails.forEach((data, index) => {
        this.adultDetails.push(this.createAdultDetails(index));
        // Patch values of adults
        // Below block is written because when there is self data that get's patched from kyc is not in date format
        // So to handle it we need to convert in into date type
        for (let adultKeys in data) {
          if (adultKeys.includes('dob')) {
            let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
            this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
          }
          else {
            this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
          }
        }
      })

      // We need to highlight the selected adult also
      let selectedAdultIndex = this.hepFormData.quickquoteData.adultDetails.length - 1;
      this.adultCountData.forEach((adultCount, index) => {
        if (index == selectedAdultIndex) {
          adultCount.selected = true;
        }
        else {
          adultCount.selected = false;
        }
      })
    }
  }

  patchQuickQuoteData() {
    this.hepFormData = this.healthDataStorageService.getHealthDataStorage();
    let applicantAnnualSum = Number(this.hepFormData.quickquoteData.applicantAnnualSum)
    this.hepForm.patchValue({
      pincode: this.hepFormData.quickquoteData.pincode,
      cityState: this.hepFormData.quickquoteData.cityState,
      childDetails: this.hepFormData.quickquoteData.childDetails,
      policyTenure: this.hepFormData.quickquoteData.policyTenure ? this.hepFormData.quickquoteData.policyTenure : this.hepDataModal.tenurePeriod[0],
      applicantAnnualSum: this.hepFormData.quickquoteData.applicantAnnualSum,
    })
    if (applicantAnnualSum >= 7500000) {
      if (this.hepForm.controls['policyRange']) {
        this.hepForm.controls['policyRange'].patchValue(this.hepDataModal.hapPolicyRange[1]);
        this.changePolicyRange(this.hepDataModal.hapPolicyRange[1]);
        this.hepForm.controls['applicantAnnualSum'].patchValue(applicantAnnualSum.toString());
      }
    } else {
      this.hepForm.controls['policyRange'].patchValue(this.hepDataModal.hapPolicyRange[0]);
      this.changePolicyRange(this.hepDataModal.hapPolicyRange[0]);
      this.hepForm.controls['applicantAnnualSum'].patchValue(applicantAnnualSum.toString());
    }
    this.fetchDataAsPerPincode('', this.hepFormData.quickquoteData.pincode)
  }

  resetForm() {
    this.formReset = true;
    this.success = false;
    this.formSubmitted = false;
    if (this.quotePanel.expanded) {
      this.quotePanel.close();
    }
    this.resetFormData();
  }

  resetFormData() {
    this.hepForm.controls['pincode'].reset();
    this.hepForm.controls['policyRange'].reset();
    this.hepForm.controls['applicantAnnualSum'].reset();
    this.hepForm.controls['state'].reset();
    this.hepForm.controls['city'].reset();
    this.adultData = [];
    this.childData = [];
  }

  openPanel(panelName) {
    if (panelName === 'quotePanel') {
      if (this.formReset && this.success) {
        if (this.premiumQuotePanel.expanded) {
          this.premiumQuotePanel.close();
          this.success = false;
        }
      }
    }
    if (panelName === 'premiumPanel' && this.success) {
      if (this.quotePanel.expanded) {
        this.quotePanel.close();
      }
    }
  }

  goToSecondStep() {
    this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
  }


  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  onRefresh(status) {
    if (status) {
      this.fetchHEPDetails();
    }
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }
  setZoneValidators() {
    if (this.upgradeZoneFlag) {
      this.hepForm.controls['selectedZone'].setValidators(Validators.required);
      this.hepForm.controls['selectedZone'].updateValueAndValidity();
    }
    else {
      this.hepForm.controls['selectedZone'].clearValidators();
      this.hepForm.controls['selectedZone'].updateValueAndValidity();
    }
  }
  zoneChanged(value) {

  }
  onclickUpgradeZoneOption(value) {
    if (this.hepForm.controls['upgradeZoneOption'].value == healthToggle.No) {
      this.hepForm.controls['selectedZone'].setValue('');
    }
    else {
      this.hepForm.controls['selectedZone'].setValue(this.zoneList[0]);
    }
  }
  ngOnDestroy() {
    this.unsubscribeSubscription();
  }

}
