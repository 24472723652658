
  <div class="">
    <main class="bell-btn" (click)="toggleDiv()">
      <div class="notification-icon" [class.shake]="showShakeAnimation">
        <div *ngIf="!showdot">
          <img class="image-notification" src="../assets/images/AfterNotification.png" />
        </div>
      </div>

      <div class="notification-icon" [class.shake]="showShakeAnimation">
        <div *ngIf="showdot">
          <img class="image-notification-af" src="../assets/images/beforeNotification.png" />
        </div>
      </div>
    </main>
  </div>

  <mat-card class="notify-card" *ngIf="showDiv">
    <div class="messageList" *ngIf="messages_Array.length > 0">
      <div class="sticky" *ngFor="let message of messages_Array">
        <div class="message message-divider">
          <!-- <div class="">{{ message.title}} {{ message.message.split('|')[0].trim() }},
            Message Type: {{ message.message.split('|')[1].trim() }}, Time: {{ message.message.split('|')[2].trim() }}
          </div> -->
          <div class="messageDiv">
            {{ message.title}} 
          </div>
          <div class="messageDiv">
            {{ message.message.split('|')[0].trim() }}
          </div>
          <div class="messageDiv">
             Message Type: {{ message.message.split('|')[1].trim() }} 
          </div>
          <div class="messageDiv">
            Time: {{ message.message.split('|')[2].trim() }}
          </div>
        </div>
      </div>
    </div>

    <div class="nothing col-sm-12" *ngIf="messages_Array.length == 0">
      <div class="nothing-div">
        <img class="nothing-img" src="../assets/images/nothingtoshow.jpg" />
      </div>
      <div class="nothing-content">
        <h3 class="col no-notification-message">No notification to show.</h3>
      </div>
    </div>
  </mat-card>


