import { Component, Inject, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/layout/loader/loader.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { HealthDataStorageService } from 'src/app/modules/health/health.service';
import { MyPolicyService } from 'src/app/shared/services/mypolicy.service';
import { Constants } from '../../../constants/constants';
import { FetchPropToModifyResponse, ModifyPortabilityDetailsResponse } from '../../../interface/mypolicy';
import { AgentServiceA } from '../../../proxy-services/AuthService/agent.service';
import { PopupService } from '../../../services/popup.service';
import { UtilityService } from '../../../services/utility.service'; //'../../../services/utility.service';

@Component({
  selector: 'app-save-edit-portability-popup',
  templateUrl: './save-edit-portability-popup.component.html',
  styleUrls: ['./save-edit-portability-popup.component.scss']
})
export class SaveEditPortabilityPopupComponent {

  insuredForm: FormGroup;
  portabilityWavierArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
  insuredMembersObj = [];
  insuredMemberControl: FormArray;
  portabilityPolicyDetails: FetchPropToModifyResponse;
  minPortabilityDOJ;
  maxPortabilityDOJ;
  showSegment: number = 0;
  formSubmitted: boolean = false;
  private subscription: Subscription[] = [];


  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: PopupModal,
    public popupService: PopupService,
    private formbuilder: FormBuilder,
    private dialog: MatDialog,
    public utilityService: UtilityService,
    private myPolicyService: MyPolicyService,
    private agentService: AgentServiceA,
    private healthDataStorageService: HealthDataStorageService,
    private spinner: LoaderService,
    private renderer: Renderer2) {
  }
  ngOnInit() {
    this.createInsuredForm();
    this.setValidatorsForPortabilitySI();
  }
  closeAllDialog() {
    this.dialog.closeAll();
  }

  createInsuredForm() {
    this.insuredForm = this.formbuilder.group({
      FloaterSumInsured: [this.popupService.getPortabilityPolicyDetails?.Data?.PortabilitySuminsured],
      Members: this.formbuilder.array([])
    });
    this.pushMember();
  }
  pushMember() {
    this.portabilityPolicyDetails = this.popupService.getPortabilityPolicyDetails;
    let portabilityDetails = this.popupService.getPortabilityPolicyDetails?.Data;
    if (!this.utilityService.isEmptyOrNull(portabilityDetails)) {
      for (const data of portabilityDetails.portabilityInsuredDetails) {
        let today = new Date(portabilityDetails.PrevPolicyEndDate);
        let dojDate = new Date(data.PortabilityDOJ);
        let length = 1 + (today.getFullYear() - dojDate.getFullYear()); 
        let a = [];
        for (const i of Array.from({ length: length + 1 }, (_, index) => index)) {
          a[i] = i.toString();
        }
        this.minPortabilityDOJ = new Date(new Date().setFullYear(new Date().getFullYear() - 100));
        this.maxPortabilityDOJ = new Date(new Date().setFullYear(new Date().getFullYear()))
        this.insuredMembersObj.push({
          "InsuredID": data.InsuredID,
          'InsuredName': data.InsuredName,
          'PortabilityDOJ': this.utilityService.formatDateWithDash(data.PortabilityDOJ),
          'PortabilitySI': data.PortabilitySI,
          'PortabilityWaiver': data.PortabilityWaiver,
          'KidAdultType': data.KidAdultType,
          'MemberCount': 0,
        });
      }
    }

    this.addInsuredMemberItems();
  }
  addInsuredMemberItems() {
    this.insuredMemberControl = this.insuredForm.get('Members') as FormArray;
    let adultCount = 0;
    let childCount = 0
    for (const member of this.insuredMembersObj) {
      let count = member.KidAdultType === "Adult" ? ++adultCount : ++childCount;
      this.insuredMemberControl.push(this.createInsuredMemItem(member, count));
    }
  }
  createInsuredMemItem(val: any, count): FormGroup {

    return this.formbuilder.group({
      InsuredID: val.InsuredID,
      InsuredName: [val.InsuredName],
      PortabilityDOJ: [val.PortabilityDOJ, [Validators.required]],
      PortabilitySI: [val.PortabilitySI, [Validators.required]],
      PortabilityWaiver: [val.PortabilityWaiver, [Validators.required]],
      displayBlock: "block",//[val.displayBlock],
      selected: true, //[val.selected],
      KidAdultType: [val.KidAdultType],
      MemberCount: [count]
    });
  }
  setValidatorsForPortabilitySI() {
    if (this.portabilityPolicyDetails?.Data.PortabilityTypeOfPolicy == "FLOATER") {
      this.insuredForm.get('FloaterSumInsured').setValidators([portabilitySIValidator(this.portabilityPolicyDetails?.Data.SumInsured)]);
      this.insuredForm.get('FloaterSumInsured').updateValueAndValidity();

      this.insuredForm.get('Members')['controls'].forEach(element => {
        element.controls['PortabilitySI'].clearValidators();
        element.controls['PortabilitySI'].updateValueAndValidity();
      })
    }
    else {
      this.insuredForm.get('Members')['controls'].forEach(element => {
        element.controls['PortabilitySI'].setValidators([portabilitySIValidator(this.portabilityPolicyDetails.Data.SumInsured)]);
        element.controls['PortabilitySI'].updateValueAndValidity();
      })

      this.insuredForm.get('FloaterSumInsured').clearValidators();
      this.insuredForm.get('FloaterSumInsured').updateValueAndValidity();
    }
  }
  memberChanged(index: number) {
    this.showSegment = index;
  }

  onKeyPressForSaveEditPortability(event) {
    const allowedRegex = /^\d$/;
    const inputValue = event.key;
    if (!inputValue.match(allowedRegex) && inputValue !== 'Backspace') {
      event.preventDefault();
    }
  }

  saveEditPortability() {
    this.formSubmitted = true;
    if (this.insuredForm.valid) {
      const body = {
        E_PolicyID: this.popupService.getEpolicyNumber,
        PortabilitySuminsured: this.insuredForm.value.FloaterSumInsured,//this.portabilityPolicyDetails.Data.PortabilitySuminsured,
        PortabilityTypeOfPolicy: this.portabilityPolicyDetails.Data.PortabilityTypeOfPolicy,
        portabilityInsuredDetails: []
      };
      this.insuredForm.value.Members.forEach((elem, index) => {
        body.portabilityInsuredDetails.push({ "InsuredID": elem.InsuredID, "InsuredName": elem.InsuredName, "PortabilityWaiver": elem.PortabilityWaiver, "PortabilityDOJ": this.utilityService.formatDate(elem.PortabilityDOJ), "PortabilitySI": elem.PortabilitySI });
      });
      this.spinner.show();
      this.subscription.push(this.popupService.modifyPortabilityDetails(body, this.popupService.getIsbancsPolicy).subscribe({
        next: (response: ModifyPortabilityDetailsResponse) => {
          if (response.StatusCode === Constants.statusCode_success) {
            this.spinner.hide();
            let msg = `Proposal modified successfully and updated Proposal No. is ${response.Data.Pf_Proposal_no}`;
            let ResponseMessagePopup = this.popupService.infoPopupMsg(msg);//(response.Data.StatusMsg);
            this.popupService.openGenericPopup(ResponseMessagePopup);
            this.myPolicyService.emitValue(true);
          } else {
            this.spinner.hide();
            let errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusMsg);
            this.popupService.openGenericPopup(errorPopupData);
          }
        },
        error: error => {
          this.spinner.hide();
          let errorPopupData = this.popupService.fetchErrorPopupMsg('');
          this.popupService.openGenericPopup(errorPopupData);
        }
      })

      )
    }
  }
  validationOfSumInsured(event: Event) {
    this.utilityService.dynamicControlsAlphaOnly(event, false);
    this.utilityService.restrictSpace(event);
    this.utilityService.dynamicControlsNumberOnly(event, true);
    //this.utilityService.onPaste(event,false,true)
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }
}

function portabilitySIValidator(sumInsured: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) {
      return { 'portabilitySIRequired': true };
    }
    else {
      if (control.value > sumInsured || control.value.length > 10) {
        return { 'portabilitySIGreaterThanSI': true };
      }
    }
    return null;
  };
}

