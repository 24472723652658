import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentLinkDeclarationsComponent } from '../payment-link-declarations/payment-link-declarations.component';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { SessionStorageService, StorageService } from 'src/app/shared/services/storage.service';
import { FetchDetailsAsPerTransactionIDRequestPayload } from 'src/app/shared/interface/customer-payment';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PaymentService } from 'src/app/shared/proxy-services/payment.service';
import { Router } from '@angular/router';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { Constants } from 'src/app/shared/constants/constants';
import { InsuredMemberPhysicalDetail, UpdateBasicPaymentDetailsRequestPayload } from 'src/app/shared/interface/health';
import { HealthDataStorage } from 'src/app/modules/health/health.modal';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { RelationshipRequestPayload } from 'src/app/shared/interface/healthMaster';
import { PaymentLinkSummary } from 'src/app/shared/interface/paymentLinkSummary';
import { HealthService } from 'src/app/shared/proxy-services/health.service';
import { CustomerPaymentService } from 'src/app/shared/proxy-services/customer-payment.service';

export interface CorrespondenceAddress {
  line1: string, 
  line2: string, 
  cityName: string, 
  stateName: string, 
  pinCode: string
}

@Component({
  selector: 'app-payment-link-summary',
  templateUrl: './payment-link-summary.component.html',
  styleUrls: ['./payment-link-summary.component.scss'],
})
export class PaymentLinkSummaryComponent implements OnInit, OnDestroy {

  response: any;
  patchValue: PaymentLinkSummary = {
    productName: " ",
    planName: " ",
    sumInsured: " ",
    policyTenure: " ",
    subLimit: " ",
    insuredMembers: " ",
    applicantName: " ",
    address1: " ",
    address2: " ",
    city: " ",
    state: " ",
    pincode: " ",
    email: " ",
    phoneNo: " ",
    gstInNo: " ",
    nomineeName: " ",
    nomineeRelation: " ",
    nomineeDOB: " ",
    appointeeName: " ",
    appointeeRelation: " ",
    appointeeDOB: " ",
    startDate: " ",
    endDate: " ",
    basicPremium: " ",
    installmentApplicable: " ",
    installmentFrequency: " ",
    installmentPremium: " ",
    gstTax: " ",
    totalPremium: " ",
    voluntaryDeductible: " ",
  };

  payload: UpdateBasicPaymentDetailsRequestPayload = {
    PolicyId: " ",
    NomineeName: " ",
    NomineeDOB: " ",
    NomineeRS: " ",
    InsuredMemberPhysicalDetails: [],
    AppointeeName: " ",
    AppointeeDOB: " ",
    AppointeeRS: " "
  };

  productForSubType = {
    20: 'HBOOSTER',
    22: 'ASP',
    //HEP
    33: 'CHI',
    //MP
    34: 'CHI',
    40: 'HGS',
    42: 'HADV',
  };

  addon: any[][] = [];
  isZeroTat: boolean = false;
  nomineeAge: number;
  currentDate: Date;
  getDate: string;
  minDOBNominee: string;
  minDOBAppointee: string;
  maxDOBNominee: string;
  maxDOBAppointee: string;
  private subscription: Subscription[] = [];
  PFProposalNo: string;
  checkbox1Error: boolean = false;
  checkbox2Error: boolean = false;
  loadFormContent: boolean = false;
  receivedDataFromPlutus: string;
  errorPopupData: PopupModal;
  emiPremium: string;
  healthFormData: HealthDataStorage;
  paymentLinkForm: FormGroup;
  checkBoxForm: FormGroup;

  nFullName: boolean = true;
  nRelationship: boolean = true;
  nDOB: boolean = true;
  aFullName: boolean = true;
  aRelationship: boolean = true;
  aDOB: boolean = true;
  nRelationshipOption: string;
  aRelationshipOption: string;
  policyID: string;
  errorOccured: boolean = false;
  productType: string;
  subType: number;
  relationships: string[] = [];
  productArray = [19, 20, 22, 33, 42, 45];
  elevateProducts = [45, 46, 47];
  payNowAmount: string;
  bpolicyID: string;
  prevInsurer: string;
  reasonOfPortability: string;
  prevPolicyNumber: string;
  prevPolicyPremium: string;
  prevPolicyStartDate: string;
  prevPolicyEndDate: string;
  showEdit: boolean = true;
  travelSubTypes = [3, 4, 5, 6];
  internationalSubTypes = [4, 5, 6];
  destination: string;
  tripDuration: string;
  travelHomeAddons = [];
  occupation: string;
  hobby: string;
  policyType: number;
  riskLocationAddress: string;
  elevateCovers = [];
  travelLocations: { from: string, to: string } = { from: '', to: '' };
  tripSafeAddons = [];
  cibilApplicable: boolean = false;
  tripSecureCorrespondenceAddress: CorrespondenceAddress = { line1 : '', line2: '', cityName: '', stateName: '', pinCode: '' };

  constructor(
    private dialog: MatDialog,
    public utilityService: UtilityService,
    private storageService: StorageService,
    public paymentService: PaymentService,
    public router: Router,
    private fb: FormBuilder,
    private healthService: HealthService,
    private healthMasterService: HealthMasterService,
    private sessionStorageService: SessionStorageService,
    private customerPaymentService: CustomerPaymentService) { }

  ngOnInit(): void {
    this.errorOccured = false;
    let customerLinkTransactionId = this.storageService.getAESDecryptedData(userEnums.SendPaymentLinkSummaryData);
    let request: FetchDetailsAsPerTransactionIDRequestPayload = {
      TranId: customerLinkTransactionId
    }
    this.customerPaymentService.fetchDetailsAsPerTransactionId(request).subscribe({
      next: (response) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.response = response;
          this.policyType = this.response.Data.PolicyType;
        
          if (this.policyType == 2) {
            this.patchData();
          } else if (this.policyType == 3) {
            if (this.response.Data.StudentTravelCustomerPolicyDetails) {
              this.patchDataForStudent();
            } else if (this.response.Data.InternationalTravelCustomerPolicyDetails) {
              this.patchDataForInternational();
            }
          } else if (this.policyType == 4) {
            this.patchDataForHome();
          }
          this.currentDate = new Date();
          this.getDate = this.currentDate.toISOString().split('T')[0];
          this.minDOBNominee = this.getMinDOBNominee(this.getDate);
          this.minDOBAppointee = this.getMinDOBAppointee(this.getDate);
          this.maxDOBNominee = this.getDate;
          this.maxDOBAppointee = this.getMaxDOBAppointee(this.getDate);
        }
        else {
          this.loadFormContent = false;
          this.errorOccured = true;
        }
      },
      error: err => {
        this.loadFormContent = false;
        this.errorOccured = true;
      }
    })
  }

  getProductFromSubType(type: number): string {
    return this.productForSubType[type]
  }

  fetchRelationships() {
    this.errorOccured = false;
    let proposalData: RelationshipRequestPayload = {
      product: this.getProductFromSubType(this.subType) ? this.getProductFromSubType(this.subType) : 'HADV'
    };
    this.healthMasterService.getHealthProposalRelationships(proposalData).subscribe({
      next: (response) => {
        if (response.StatusCode == Constants.statusCode_success) {
          for (let i of response.Data.NomineeAppointeeRelationship) {
            this.relationships.push(i.RelationshipName)
          }
        } else {
          this.loadFormContent = false;
          this.errorOccured = true;
        }
      },
      error: err => {
        this.loadFormContent = false;
        this.errorOccured = true;
      }
    })
  }

  patchDataForHome() {
    this.addon[0] = [];
    this.nomineeAge = 19;
    this.showEdit = false;
    this.PFProposalNo = this.response.Data.CustomerPolicyDetails.ProposalNumber;
    this.policyID = String(this.response.Data.CustomerPolicyDetails.PolicyId);
    this.bpolicyID = '';
    this.productType = '';
    this.subType = this.response.Data.CustomerPolicyDetails.SubType;

    //First Card
    this.patchValue.productName = this.response.Data.CustomerPolicyDetails.ProductName;
    this.patchValue.planName = this.response.Data.CustomerPolicyDetails.plan;
    this.patchValue.policyTenure = String(this.response.Data.CustomerPolicyDetails.PolicyTenure);
    this.patchValue.sumInsured = this.response.Data.CustomerPolicyDetails.InsuredAmount;

    //Second Card
    this.patchValue.applicantName = this.response.Data.CustomerPolicyDetails.Customer.FullName;
    this.patchValue.email = this.response.Data.CustomerPolicyDetails.Customer.EmailName;
    this.patchValue.phoneNo = this.response.Data.CustomerPolicyDetails.Customer.TelephoneNumber;
    this.riskLocationAddress = this.response.Data.CustomerPolicyDetails.PolicyAddress.PermanentAddressLine1;
    this.patchValue.gstInNo = '';

    //Third Card
    this.patchValue.startDate = this.invertDate(this.response.Data.CustomerPolicyDetails.StartDate);
    this.patchValue.endDate = this.invertDate(this.response.Data.CustomerPolicyDetails.EndDate);
    this.patchValue.basicPremium = this.response.Data.CustomerPolicyDetails.BasicPremium;
    this.patchValue.gstTax = this.response.Data.CustomerPolicyDetails.TotalTax;
    this.patchValue.totalPremium = this.response.Data.CustomerPolicyDetails.TotalPremium;
    this.payNowAmount = `Pay Now ₹${this.utilityService.convertStringtoCurrency(Math.trunc(Number(this.patchValue.totalPremium)))}`;

    //Fourth Card
    this.travelHomeAddons = this.response.Data.CustomerPolicyDetails.CoverDetails;

    this.createPaymentpaymentLinkForm();
  }

  patchDataForStudent() {
    this.addon[0] = [];
    this.nomineeAge = 19;
    this.showEdit = false;
    this.PFProposalNo = this.response.Data.StudentTravelCustomerPolicyDetails.ProposalNumber;
    this.policyID = this.response.Data.StudentTravelCustomerPolicyDetails.PolicyId;
    this.bpolicyID = '';
    this.productType = '';
    this.subType = this.response.Data.StudentTravelCustomerPolicyDetails.SubType;

    //First Card
    this.patchValue.productName = this.response.Data.StudentTravelCustomerPolicyDetails.ProductName;
    this.patchValue.planName = this.response.Data.StudentTravelCustomerPolicyDetails.InsuredDetails.PlanName;
    this.destination = this.response.Data.StudentTravelCustomerPolicyDetails.GeographicalScope;
    this.patchValue.sumInsured = this.response.Data.StudentTravelCustomerPolicyDetails.InsuredDetails.InsuredAmount;

    //Second Card
    this.patchValue.insuredMembers = [{
      Name: this.response.Data.StudentTravelCustomerPolicyDetails.InsuredDetails.FullName,
      Relationship: this.response.Data.StudentTravelCustomerPolicyDetails.InsuredDetails.RelationShipNameWithApplicant,
      DateOfBirth: this.invertDate(this.response.Data.StudentTravelCustomerPolicyDetails.InsuredDetails.DateOfBirth),
      Passport: this.response.Data.StudentTravelCustomerPolicyDetails.InsuredDetails.PassportNumber,
      Mother: this.response.Data.StudentTravelCustomerPolicyDetails.InsuredDetails.MotherMaidenName
    }]

    //Third Card
    this.patchValue.nomineeName = this.response.Data.StudentTravelCustomerPolicyDetails.InsuredDetails.Nominee.FullName;
    this.nRelationshipOption = this.response.Data.StudentTravelCustomerPolicyDetails.InsuredDetails.Nominee.RelationshipName;
    this.patchValue.nomineeRelation = this.nRelationshipOption;

    //Fourth Card
    this.patchValue.applicantName = this.response.Data.StudentTravelCustomerPolicyDetails.Customer.FullName;
    this.patchValue.address1 = this.response.Data.StudentTravelCustomerPolicyDetails.PolicyPermanentAddress.PermanentAddressLine1;
    this.patchValue.address2 = this.response.Data.StudentTravelCustomerPolicyDetails.PolicyPermanentAddress.PermanentAddressLine2;
    this.patchValue.city = this.response.Data.StudentTravelCustomerPolicyDetails.PolicyPermanentAddress.CityName;
    this.patchValue.state = this.response.Data.StudentTravelCustomerPolicyDetails.PolicyPermanentAddress.StateName;
    this.patchValue.pincode = this.response.Data.StudentTravelCustomerPolicyDetails.PolicyPermanentAddress.PinCode;
    this.patchValue.email = this.response.Data.StudentTravelCustomerPolicyDetails.Customer.EmailName;
    this.patchValue.phoneNo = this.response.Data.StudentTravelCustomerPolicyDetails.Customer.TelephoneNumber;
    this.patchValue.gstInNo = this.response.Data.StudentTravelCustomerPolicyDetails.Customer.GSTDetails ? this.response.Data.StudentTravelCustomerPolicyDetails.Customer.GSTDetails.GSTIN_NO : '';

    //Fifth Card
    this.patchValue.startDate = this.invertDate(this.response.Data.StudentTravelCustomerPolicyDetails.TravelStartDate);
    this.patchValue.endDate = this.invertDate(this.response.Data.StudentTravelCustomerPolicyDetails.TravelEndDate);
    this.patchValue.basicPremium = this.response.Data.StudentTravelCustomerPolicyDetails.BasicPremium;
    this.patchValue.gstTax = this.response.Data.StudentTravelCustomerPolicyDetails.TotalTax;
    this.patchValue.totalPremium = this.response.Data.StudentTravelCustomerPolicyDetails.TotalPremium;
    this.payNowAmount = `Pay Now ₹${this.utilityService.convertStringtoCurrency(Math.trunc(Number(this.patchValue.totalPremium)))}`;

    this.createPaymentpaymentLinkForm();
  }

  patchDataForInternational() {
    this.addon[0] = [];
    this.nomineeAge = 19;
    this.showEdit = false;
    this.PFProposalNo = this.response.Data.InternationalTravelCustomerPolicyDetails.ProposalNumber;
    this.policyID = this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyId;
    this.bpolicyID = '';
    this.productType = '';
    this.subType = this.response.Data.InternationalTravelCustomerPolicyDetails.SubType;

    //First Card
    this.patchValue.productName = this.response.Data.InternationalTravelCustomerPolicyDetails.ProductName;
    this.patchValue.planName = this.response.Data.InternationalTravelCustomerPolicyDetails.PlanDetails[0].PlanName;
    this.destination = this.response.Data.InternationalTravelCustomerPolicyDetails.PlanDetails[0].geographicalScopeText;
    this.tripDuration = this.response.Data.InternationalTravelCustomerPolicyDetails.TripDuration; //Days
    this.patchValue.sumInsured = '';

    if (this.subType == 6) {
      this.travelLocations.from = this.response.Data.InternationalTravelCustomerPolicyDetails.DestinationCountryFrom;
      this.travelLocations.to = this.response.Data.InternationalTravelCustomerPolicyDetails.PlanDetails[0].geographicalScopeText;
    }

    //Second Card
    this.patchValue.insuredMembers = [];
    for (let i of this.response.Data.InternationalTravelCustomerPolicyDetails.InsuredDetails) {
      this.patchValue.insuredMembers.push({
        Name: i.FullName,
        Relationship: i.RelationWithApplicant,
        DateOfBirth: this.invertDate(i.DateOfBirth),
        Passport: i.PassportNumber,
        Ailment: i.Ailment,
        NomineeName: i.Nominee.FullName,
        NomineeRelationship: i.Nominee.RelationshipName,
        PreExistingDisease: i?.PreExistingDisease,
      })
    }

    //Third Card
    this.patchValue.applicantName = this.response.Data.InternationalTravelCustomerPolicyDetails.Customer.FullName;
    this.patchValue.address1 = this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyPermanentAddress.PermanentAddressLine1;
    this.patchValue.address2 = this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyPermanentAddress.PermanentAddressLine2;
    this.patchValue.city = this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyPermanentAddress.CityName;
    this.patchValue.state = this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyPermanentAddress.StateName;
    this.patchValue.pincode = this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyPermanentAddress.PinCode;
    this.patchValue.email = this.response.Data.InternationalTravelCustomerPolicyDetails.Customer.EmailName;
    this.patchValue.phoneNo = this.response.Data.InternationalTravelCustomerPolicyDetails.Customer.TelephoneNumber;
    this.occupation = this.response.Data.InternationalTravelCustomerPolicyDetails.Customer.Occupation;
    this.hobby = this.response.Data.InternationalTravelCustomerPolicyDetails.Customer.Hobby;
    this.patchValue.gstInNo = this.response.Data.InternationalTravelCustomerPolicyDetails.Customer.GSTDetails ? this.response.Data.InternationalTravelCustomerPolicyDetails.Customer.GSTDetails.GSTIN_NO : '';
    
    if(this.subType == 6 && this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyCorrespondenceAddress){
      this.tripSecureCorrespondenceAddress.line1 = this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyCorrespondenceAddress?.Line1;
      this.tripSecureCorrespondenceAddress.line2 = this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyCorrespondenceAddress?.Line2;
      this.tripSecureCorrespondenceAddress.cityName = this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyCorrespondenceAddress?.CityName;
      this.tripSecureCorrespondenceAddress.stateName = this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyCorrespondenceAddress?.StateName;
      this.tripSecureCorrespondenceAddress.pinCode = this.response.Data.InternationalTravelCustomerPolicyDetails.PolicyCorrespondenceAddress?.PinCode;
    }


    //Fourth Card
    this.patchValue.startDate = this.invertDate(this.response.Data.InternationalTravelCustomerPolicyDetails.TravelStartDate);
    this.patchValue.endDate = this.invertDate(this.response.Data.InternationalTravelCustomerPolicyDetails.TravelEndDate);
    this.patchValue.basicPremium = this.response.Data.InternationalTravelCustomerPolicyDetails.BasicPremium;
    this.patchValue.gstTax = this.response.Data.InternationalTravelCustomerPolicyDetails.TotalTax;
    this.patchValue.totalPremium = this.response.Data.InternationalTravelCustomerPolicyDetails.TotalPremium;
    this.payNowAmount = `Pay Now ₹${this.utilityService.convertStringtoCurrency(Math.trunc(Number(this.patchValue.totalPremium)))}`;

    //Fifth Card
    if (this.subType != 6 && this.response.Data.InternationalTravelCustomerPolicyDetails.sportscovers) {
      for (let i of this.response.Data.InternationalTravelCustomerPolicyDetails.sportscovers) {
        this.travelHomeAddons.push(i.LoadingDiscName);
      }
    }

    if (this.subType == 6 && this.response.Data.InternationalTravelCustomerPolicyDetails.CoverDetails) {
      for (let i of this.response.Data.InternationalTravelCustomerPolicyDetails.CoverDetails) {
        this.tripSafeAddons.push({ Name: i.Name, SI: i.SI, Premium: i.Premium });
      }
    }

    this.createPaymentpaymentLinkForm();
  }

  patchData() {
    this.cibilApplicable = this.response.Data.CustomerPolicyDetails.Health?.CIBILApplicable;
    this.PFProposalNo = this.response.Data.CustomerPolicyDetails.PF_ProposalNo;
    this.policyID = this.response.Data.CustomerPolicyDetails.Health.PolicyId;
    this.bpolicyID = this.response.Data.CustomerPolicyDetails.PPBundlePolicy ? this.response.Data.CustomerPolicyDetails.PPBundlePolicy.PolicyID : '';
    this.productType = this.response.Data.CustomerPolicyDetails.TransFor;
    this.subType = this.response.Data.CustomerPolicyDetails.SubType;

    if (this.productType !== "N" || !this.productArray.includes(this.subType) || this.bpolicyID) {
      this.showEdit = false;
    }

    if (this.productType == 'R') {
      this.prevInsurer = this.response.Data.CustomerPolicyDetails.Health.PreviousPolicyInsurerCompany;
      this.reasonOfPortability = this.response.Data.CustomerPolicyDetails.Health.ReasonOfPortability;
      this.prevPolicyNumber = this.response.Data.CustomerPolicyDetails.Health.PreviousPolicyNumber;
      this.prevPolicyPremium = this.response.Data.CustomerPolicyDetails.Health.PreviousPolicyPremium;
      const pPSD = this.response.Data.CustomerPolicyDetails.Health.PrevPolicyStartDate;
      this.prevPolicyStartDate = this.formatDate(this.utilityService.truncateAfterT(pPSD));
      const pPED = this.response.Data.CustomerPolicyDetails.Health.PrevPolicyEndDate;
      this.prevPolicyEndDate = this.formatDate(this.utilityService.truncateAfterT(pPED));
    }

    //First Card
    this.patchValue.productName = this.response.Data.CustomerPolicyDetails.ProductName;
    if (this.subType == 47){
      this.patchValue.planName = this.response.Data.CustomerPolicyDetails.Health?.HealthProuctName ? this.response.Data.CustomerPolicyDetails.Health?.HealthProuctName : '';
    } else {
      this.patchValue.planName = this.response.Data.CustomerPolicyDetails.Health.PlanName ? this.response.Data.CustomerPolicyDetails.Health.PlanName : '';
    }
    this.patchValue.sumInsured = this.response.Data.CustomerPolicyDetails.Health.SumInsured ? this.response.Data.CustomerPolicyDetails.Health.SumInsured : '';
    this.patchValue.policyTenure = this.response.Data.CustomerPolicyDetails.Health.PolicyTenure;
    this.patchValue.subLimit = this.response.Data.CustomerPolicyDetails.Health.SubLimit;

    //Second Card
    this.patchValue.insuredMembers = this.response.Data.CustomerPolicyDetails.Health.InsuredMembers;

    if (this.subType != 23 && this.subType != 38) {
      this.isZeroTat = false;
      for (let j = 0; j < this.patchValue.insuredMembers.length; j++) {
        this.addon[j] = [];
        if (this.patchValue.insuredMembers[j].AddOnCoverDetails.length > 0) {
          for (const coverDetail of this.patchValue.insuredMembers[j].AddOnCoverDetails) {
            this.addon[j].push(coverDetail.CoverText);
          }
        }
      }
    } else {
      this.isZeroTat = true;
      for (let j = 0; j < this.patchValue.insuredMembers.length; j++) {
        this.addon[j] = [];
        if (this.patchValue.insuredMembers[j].AddOnCoverDetails.length > 0) {
          for (const addon of this.patchValue.insuredMembers[j].AddOnCoverDetails) {
            const coverName = addon.CoverText;
            const coverValue = addon.SiLimit;
            this.addon[j].push({ Name: coverName, Value: coverValue });
          }
        }
      }
    }

    if (this.elevateProducts.includes(this.subType)) {
      const elevateCovers = this.response.Data.CustomerPolicyDetails.Health.elevateaditionalcovers;
      for (let i of Object.keys(elevateCovers)) {
        if (!this.utilityService.isEmptyOrNull(elevateCovers[i])) {
          this.elevateCovers.push({ Name: i, Value: elevateCovers[i] })
        }
      }
    }

    //Third Card
    this.patchValue.applicantName = this.response.Data.CustomerPolicyDetails.Health.CustomerName;
    this.patchValue.address1 = this.response.Data.CustomerPolicyDetails.Health.AddressLine1;
    this.patchValue.address2 = this.response.Data.CustomerPolicyDetails.Health.AddressLine2;
    this.patchValue.city = this.response.Data.CustomerPolicyDetails.Health.City;
    this.patchValue.state = this.response.Data.CustomerPolicyDetails.Health.State;
    this.patchValue.pincode = this.utilityService.isEmptyOrNull(this.response.Data.CustomerPolicyDetails.Health.Pincode)? '':this.response.Data.CustomerPolicyDetails.Health.Pincode;
    this.patchValue.email = this.response.Data.CustomerPolicyDetails.Health.Email;
    this.patchValue.phoneNo = this.response.Data.CustomerPolicyDetails.Health.TelePhoneNumber;
    this.patchValue.gstInNo = this.response.Data.CustomerPolicyDetails.CustomerGSTINNo;
    this.patchValue.nomineeName = this.response.Data.CustomerPolicyDetails.Health.NomineeName;
    this.nRelationshipOption = this.response.Data.CustomerPolicyDetails.Health.NomineeReleationShip;
    this.patchValue.nomineeRelation = this.nRelationshipOption;
    this.patchValue.nomineeDOB = this.response.Data.CustomerPolicyDetails.Health.NomineeDOB;

    this.nomineeAge = this.calculateNomineeAge(this.patchValue.nomineeDOB);

    this.patchValue.appointeeName = this.response.Data.CustomerPolicyDetails.Health.AppointeeName ? this.response.Data.CustomerPolicyDetails.Health.AppointeeName : '';
    this.aRelationshipOption = this.response.Data.CustomerPolicyDetails.Health.AppointeeReleationShip ? this.response.Data.CustomerPolicyDetails.Health.AppointeeReleationShip : '';
    this.patchValue.appointeeRelation = this.aRelationshipOption;
    this.patchValue.appointeeDOB = this.response.Data.CustomerPolicyDetails.Health.AppointeeName ? this.response.Data.CustomerPolicyDetails.Health.AppointeeDOB : '';
    if (this.showEdit) {
      this.fetchRelationships()
    }

    //Fourth Card
    this.patchValue.startDate = this.response.Data.CustomerPolicyDetails.Health.PolicyStartDate;
    this.patchValue.endDate = this.response.Data.CustomerPolicyDetails.Health.PolicyEndDate;
    this.patchValue.basicPremium = this.response.Data.CustomerPolicyDetails.Health.BasicPremium;
    this.patchValue.installmentApplicable = this.response.Data.CustomerPolicyDetails.Health.InstallmentApplicable;
    this.patchValue.gstTax = this.response.Data.CustomerPolicyDetails.Health.TotalTax;
    this.patchValue.voluntaryDeductible = this.response.Data.CustomerPolicyDetails.Health?.VoluntaryDedutible ? this.response.Data.CustomerPolicyDetails.Health?.VoluntaryDedutible : '';
    if (this.bpolicyID) {
      this.patchValue.totalPremium = String(this.response.Data.CustomerPolicyDetails.TotalPremium);
    } else {
      this.patchValue.totalPremium = this.response.Data.CustomerPolicyDetails.Health.TotalPremium;
    }
    if (this.patchValue.installmentApplicable) {
      this.patchValue.installmentFrequency = this.response.Data.CustomerPolicyDetails.Health.InstallmentFrequency;
      this.patchValue.installmentPremium = String(this.response.Data.CustomerPolicyDetails.Health.InstallmentTotalPremium);
      const tempPremium = `₹${this.utilityService.convertStringtoCurrency(Math.trunc(Number(this.patchValue.installmentPremium)))}`;
      this.patchValue.installmentPremium = tempPremium;
      this.payNowAmount = `Pay Now ${tempPremium}`
    } else {
      this.payNowAmount = `Pay Now ₹${this.utilityService.convertStringtoCurrency(Math.trunc(Number(this.patchValue.totalPremium)))}`
    }

    this.payload.NomineeName = this.patchValue.nomineeName;
    this.payload.NomineeDOB = this.patchValue.nomineeDOB;
    this.payload.NomineeRS = this.patchValue.nomineeRelation;
    this.payload.AppointeeName = this.patchValue.appointeeName;
    this.payload.AppointeeDOB = this.patchValue.appointeeDOB
    this.payload.AppointeeRS = this.patchValue.appointeeRelation;

    if (this.subType != 23) {
      for (const _ of this.patchValue.insuredMembers) {
        const memberDetail: InsuredMemberPhysicalDetail = {
          HeightInFeet: '',
          HeightInInch: '',
          Weight: ''
        };
        this.payload.InsuredMemberPhysicalDetails.push(memberDetail);
      }

      for (let i = 0; i < this.patchValue.insuredMembers.length; i++) {
        this.payload.InsuredMemberPhysicalDetails[i]['HeightInFeet'] = this.patchValue.insuredMembers[i]['HeightInFeet'];
        this.payload.InsuredMemberPhysicalDetails[i]['HeightInInch'] = this.patchValue.insuredMembers[i]['HeightInInch'];
        this.payload.InsuredMemberPhysicalDetails[i]['Weight'] = this.patchValue.insuredMembers[i]['Weight'];
      }
    } else {
      for (const insuredMemberData of this.patchValue.insuredMembers) {
        insuredMemberData['HeightInFeet'] = "0";
        insuredMemberData['HeightInInch'] = "0";
        insuredMemberData['Weight'] = "0";
      }
    }
    this.createPaymentpaymentLinkForm();
  }

  createPaymentpaymentLinkForm() {
    this.paymentLinkForm = this.fb.group({
      height: this.fb.array([]),
      weight: this.fb.array([]),
      nominee: this.fb.group({
        fullName: [this.patchValue.nomineeName],
        relationship: [this.patchValue.nomineeRelation],
        dob: [this.invertDate(this.patchValue.nomineeDOB)],
      }),
      appointee: this.fb.group({
        fullName: [this.patchValue.appointeeName ? this.patchValue.appointeeName : ''],
        relationship: [this.patchValue.appointeeRelation ? this.patchValue.appointeeRelation : ''],
        dob: [this.patchValue.appointeeDOB ? this.invertDate(this.patchValue.appointeeDOB) : ''],
      }),
    });

    //For some reason keeping it in one form breaks functionality
    this.checkBoxForm = this.fb.group({
      checkbox1: [false, Validators.requiredTrue],
      checkbox2: [false, Validators.requiredTrue],
    })

    if (!(this.travelSubTypes.includes(this.subType)) && this.policyType != 4) {
      this.getHeightWeightValues()
    }

    this.loadFormContent = true;
  }

  getHeightWeightValues() {
    for (const memberData of this.patchValue.insuredMembers) {
      const HeightFeetInch = memberData['HeightInFeet'] + "." + memberData['HeightInInch'];
      this.heightArray.push(
        this.fb.group({
          feetInches: [HeightFeetInch],
          iHeight: [true],
        })
      );
      this.weightArray.push(
        this.fb.group({
          kilo: [memberData['Weight']],
          iWeight: [true],
        })
      );
    }
  }

  get getHeight() {
    return (<FormArray>this.paymentLinkForm.get('height')).controls
  }

  get getWeight() {
    return (<FormArray>this.paymentLinkForm.get('weight')).controls
  }

  get heightArray(): FormArray {
    return (<FormArray>this.paymentLinkForm.get('height'));
  }

  get weightArray(): FormArray {
    return (<FormArray>this.paymentLinkForm.get('weight'));
  }

  validateHeightInput(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const regex = Constants.heightPatternRegExp;
    if (event.key === 'Backspace') {
      return;
    }
    if (!regex.test(inputValue + event.key)) {
      event.preventDefault();
    }
  }

  validateWeightInput(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const regex = Constants.weightPatternRegExp;
    if (event.key === 'Backspace') {
      return;
    }
    if (!regex.test(inputValue + event.key)) {
      event.preventDefault();
    }
  }

  validateNameInput(event: KeyboardEvent) {
    const regex = Constants.namePatternRegExp;
    if (event.key === 'Backspace' || regex.test(event.key)) {
      return;
    } else {
      event.preventDefault();
    }
  }

  splitNumber(number: string) {
    const parts = number.toString().split('.');
    const wholePart = parseInt(parts[0]);
    const fractionalPart = parseInt(parts[1]) || 0;
    return { wholePart, fractionalPart };
  }

  viewHeight(index: number) {
    const heightGroup = this.heightArray.at(index);
    const height = heightGroup.get('iHeight')
    return height.value
  }

  viewWeight(index: number) {
    const weightGroup = this.weightArray.at(index);
    const weight = weightGroup.get('iWeight')
    return weight.value
  }

  toggleEditHeight(index: number) {
    const heightGroup = this.heightArray.at(index);
    const feetInchesControl = heightGroup.get('feetInches')
    const h = feetInchesControl.value;
    const { wholePart, fractionalPart } = this.splitNumber(h);
    if (feetInchesControl.value === '') {
      const HeightFeetInch = this.patchValue.insuredMembers[index]['HeightInFeet'] + "." + this.patchValue.insuredMembers[index]['HeightInInch'];
      feetInchesControl.setValue(HeightFeetInch)
    }
    else {
      this.patchValue.insuredMembers[index]['HeightInFeet'] = wholePart;
      this.patchValue.insuredMembers[index]['HeightInInch'] = fractionalPart;
    }
    heightGroup.get('iHeight')?.setValue(!heightGroup.get('iHeight')?.value);
  }

  toggleEditWeight(index: number) {
    const weightGroup = this.weightArray.at(index);
    const kiloControl = weightGroup.get('kilo')
    if (kiloControl.value === '') {
      kiloControl.setValue(this.patchValue.insuredMembers[index]['Weight'])
    } else {
      this.patchValue.insuredMembers[index]['Weight'] = kiloControl.value
    }
    weightGroup.get('iWeight')?.setValue(!weightGroup.get('iWeight')?.value);
  }

  getMinDOBNominee(date: string): string {
    const dateParts = date.split('-');
    let year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    year = String(Number(year) - 100);
    return `${year}-${month}-${day}`
  }

  getMinDOBAppointee(date: string): string {
    const dateParts = date.split('-');
    let year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    year = String(Number(year) - 118);
    return `${year}-${month}-${day}`
  }

  getMaxDOBAppointee(date: string): string {
    const dateParts = date.split('-');
    let year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    year = String(Number(year) - 18);
    return `${year}-${month}-${day}`
  }


  calculateNomineeAge(DOB) {
    let dobParts;
    if (DOB.includes('/')) {
      dobParts = DOB.split('/');
    } else if (DOB.includes('-')) {
      dobParts = DOB.split('-');
    }

    let today = new Date();
    let day, month, year;

    // Check if the date format is DD/MM/YYYY
    if (dobParts[0].length === 2) {
      day = parseInt(dobParts[0]);
      month = parseInt(dobParts[1]);
      year = parseInt(dobParts[2]);
    } else {
      // Assume the date format is YYYY/MM/DD
      year = parseInt(dobParts[0]);
      month = parseInt(dobParts[1]);
      day = parseInt(dobParts[2]);
    }

    let birthDate = new Date(year, month - 1, day);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  invertDate(inputDate: string): string {
    if (inputDate.includes('T')) {
      inputDate = this.utilityService.truncateAfterT(inputDate);
    }
    const dateParts = inputDate.split('-');
    if (dateParts.length === 3) {
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    } else
      return inputDate;
  }

  formatDate(inputDate: string): string {
    const dateParts = inputDate.split('-');
    if (dateParts.length === 3) {
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2];
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    } else
      return inputDate;
  }

  fixDate(inputDate: string): string {
    const dateParts = inputDate.split('/');
    if (dateParts.length === 3) {
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    } else
      return inputDate;
  }

  updateNName() {
    const nomineeFullNameControl = this.paymentLinkForm.get('nominee.fullName');
    if (nomineeFullNameControl.value === '') {
      nomineeFullNameControl.setValue(this.patchValue.nomineeName)
    }
    else {
      nomineeFullNameControl.setValue(nomineeFullNameControl.value.trim())
      this.patchValue.nomineeName = nomineeFullNameControl.value
    }
    this.nFullName = !this.nFullName
  }

  updateAName() {
    const appointeeFullNameControl = this.paymentLinkForm.get('appointee.fullName');
    if (appointeeFullNameControl.value === '') {
      appointeeFullNameControl.setValue(this.patchValue.appointeeName)
    }
    else {
      appointeeFullNameControl.setValue(appointeeFullNameControl.value.trim())
      this.patchValue.appointeeName = appointeeFullNameControl.value
    }
    this.aFullName = !this.aFullName
  }

  getNomineeAge() {
    const nomineeDOBControl = this.paymentLinkForm.get('nominee.dob')
    if (nomineeDOBControl.value === '') {
      const patchNDOB = this.patchValue.nomineeDOB;
      if (patchNDOB.includes('-')) {
        nomineeDOBControl.setValue(this.invertDate(patchNDOB))
      } else {
        nomineeDOBControl.setValue(this.fixDate(patchNDOB))
      }
    }
    this.nomineeAge = this.calculateNomineeAge(this.paymentLinkForm.get('nominee.dob').value);
    this.patchValue.nomineeDOB = this.formatDate(nomineeDOBControl.value)
    if (this.nomineeAge > 18) {
      this.paymentLinkForm.get('appointee').patchValue({
        fullName: '',
        relationship: '',
        dob: '',
      });
    } else {
      this.paymentLinkForm.get('appointee').patchValue({
        fullName: this.patchValue.appointeeName,
        relationship: this.patchValue.appointeeRelation,
        dob: this.patchValue.appointeeDOB,
      });
    }
  }

  updateADate() {
    const appointeeDOBControl = this.paymentLinkForm.get('appointee.dob')
    if (appointeeDOBControl.value === '') {
      const patchADOB = this.patchValue.appointeeDOB;
      if (patchADOB.includes('-')) {
        appointeeDOBControl.setValue(this.invertDate(patchADOB))
      } else {
        appointeeDOBControl.setValue(this.fixDate(patchADOB))
      }
    }
    this.patchValue.appointeeDOB = this.formatDate(appointeeDOBControl.value)
  }

  openDeclaration(): void {
    let hheight: string;
    let declarationType: string;
    if (this.travelSubTypes.includes(this.subType)) {
      declarationType = 'declarationT';
      hheight = '300px';
    } else if (this.policyType == 4) {
      declarationType = 'declarationH';
      hheight = '200px';
    } else {
      declarationType = 'declaration';
      hheight = '450px';
    }
    this.dialog.open(PaymentLinkDeclarationsComponent, {
      panelClass: 'my-custom-popup',
      data: declarationType,
      height: hheight,
      width: '800px',
      autoFocus: false
    });
  }

  openTnc(): void {
    let ddata: string;
    if (this.travelSubTypes.includes(this.subType)) {
      ddata = 'tncT';
    } else if (this.policyType == 4) {
      ddata = 'tncH';
    } else {
      ddata = 'tnc';
    }
    this.dialog.open(PaymentLinkDeclarationsComponent, {
      panelClass: 'my-custom-popup',
      data: ddata,
      height: '450px',
      width: '800px',
      autoFocus: false
    });
  }

  openPrivacy(): void {
    this.dialog.open(PaymentLinkDeclarationsComponent, { panelClass: 'my-custom-popup', data: 'privacy', height: '450px', width: '800px', autoFocus: false });
  }

  constructPayNowRequest() {
    const userType = this.storageService.getAESDecryptedDataLocal(userEnums.UnxkdjT) ? this.storageService.getAESDecryptedDataLocal(userEnums.UnxkdjT) : 'Customer'
    let request;
    if (this.bpolicyID) {
      request = {
        CorrelationID: null,
        PolicyID: this.policyID.toString() + ':' + this.bpolicyID.toString(),
        UserType: userType
      }
    }
    else {
      request = {
        PolicyID: this.policyID.toString().toString(),
        UserType: userType,
        CorrelationID: null
      }
    }
    return request;
  }


  isCheckBox1Error() {
    if (this.checkBoxForm.controls['checkbox1'].status == "INVALID") {
      this.checkbox1Error = true;
    } else {
      this.checkbox1Error = false;
    }
  }

  isCheckBox2Error() {
    if (this.checkBoxForm.controls['checkbox2'].status == "INVALID") {
      this.checkbox2Error = true;
    } else {
      this.checkbox2Error = false;
    }
  }

  valueCheck(): boolean {
    // edit functionality only allowed for some products, new cases and non-bundle policies
    if (this.productType === "N" && this.productArray.includes(this.subType) && !this.bpolicyID) {
      const formValues = this.paymentLinkForm.value;
      if (
        this.payload.NomineeName == formValues.nominee.fullName &&
        this.payload.NomineeDOB == formValues.nominee.dob &&
        this.payload.NomineeRS == formValues.nominee.relationship &&
        this.payload.AppointeeName == formValues.appointee.fullName &&
        this.payload.AppointeeDOB == formValues.appointee.dob &&
        this.payload.AppointeeRS == formValues.appointee.relationship) {
        let flag: boolean = false;
        for (let i = 0; i < this.patchValue.insuredMembers.length; i++) {
          const { wholePart, fractionalPart } = this.splitNumber(formValues.height[i]['feetInches']);
          const w: string = String(wholePart);
          const f: string = String(fractionalPart);
          if (
            this.payload.InsuredMemberPhysicalDetails[i]['HeightInFeet'] != w ||
            this.payload.InsuredMemberPhysicalDetails[i]['HeightInInch'] != f ||
            this.payload.InsuredMemberPhysicalDetails[i]['Weight'] != formValues.weight[i]['kilo']
          ) {
            flag = true;
            break;
          }

        }
        return flag;
      }
      else
        return true;
    }
    else
      return false;
  }

  callPlutus() {
    let payNowRequest = this.constructPayNowRequest();
    this.subscription.push(this.paymentService.payNowRequest(payNowRequest).subscribe({
      next: response => {
        if (response.StatusCode == 1 && response.StatusMsg == "Success") {
          let url = response.Data.URL
          this.storageService.setAESEncryptedDataLocal(userEnums.ProposalNumber, this.PFProposalNo);
          this.sessionStorageService.clear();
          window.location.href = url;
        }
      },
    }))
  }

  submitForm() {
    this.isCheckBox1Error();
    this.isCheckBox2Error();
    if (this.paymentLinkForm.valid && this.checkBoxForm.valid) {
      if (this.paymentLinkForm.dirty && this.valueCheck()) {
        const formValues = this.paymentLinkForm.value;
        this.payload.PolicyId = this.policyID;
        this.payload.NomineeName = formValues.nominee.fullName;
        this.payload.NomineeDOB = formValues.nominee.dob;
        this.payload.NomineeRS = formValues.nominee.relationship;
        this.payload.AppointeeName = formValues.appointee.fullName;
        this.payload.AppointeeDOB = formValues.appointee.dob;
        this.payload.AppointeeRS = formValues.appointee.relationship;
        for (let i = 0; i < this.patchValue.insuredMembers.length; i++) {
          const { wholePart, fractionalPart } = this.splitNumber(formValues.height[i]['feetInches']);
          this.payload.InsuredMemberPhysicalDetails[i]['HeightInFeet'] = String(wholePart);
          this.payload.InsuredMemberPhysicalDetails[i]['HeightInInch'] = String(fractionalPart);
          this.payload.InsuredMemberPhysicalDetails[i]['Weight'] = formValues.weight[i]['kilo'];
        }
        this.healthService.updateBasicPaymentDetails(this.payload, false).subscribe({
          next: (response) => {
            if (response.StatusCode == Constants.statusCode_success) {
              this.callPlutus();
            }
          },
        })
      } else {
        this.callPlutus();
      }
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}