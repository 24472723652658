import { Component, OnDestroy, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { HealthDataStorageService } from '../../health.service';
import { BefitHomeStorage, HealthDataStorage } from '../../health.modal';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { HealthMemberAilments, SaveEditProposalV1RequestPayload } from 'src/app/shared/interface/health-advantedge';
import { PortabilityReason, ProductCode, healthMembers, insuredDiseaseID, policyType, remarkList } from 'src/app/shared/enums/healthEnums';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import MedicalTestGuidelinesData from 'src/assets/json/medical-test-guidelines.json';
import { PaymentService } from 'src/app/shared/proxy-services/payment.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/layout/loader/loader.service';
import { BefitResponsePayload, SavehealthPolicyRequestPayload, SaveHealthPolicyResponsePayload } from 'src/app/shared/interface/health';
import { HAPModal } from '../../products/hap/hap-modal';
import { CreateBefitProposalRequestPayload, CreateBefitProposalResponsePayload, CoverHomeRequestPayload, CreateHomeResponsePayload } from 'src/app/shared/interface/wrapper';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { HomeMasterDataRequestPayload, HomeMasterDataResponsePayloadStructure, PlanDetails } from 'src/app/shared/interface/home';
import { HomeService } from 'src/app/shared/proxy-services/home.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { WrapperService } from 'src/app/shared/proxy-services/wrapper.service';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { RelationshipRequestPayload, RelationshipResponsePayload } from 'src/app/shared/interface/healthMaster';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { StorageService } from 'src/app/shared/services/storage.service';
import { AgentMasterStructure } from 'src/app/shared/interface/agentV1';
import { MatSelect } from '@angular/material/select';
import { EmitButtonData, UpdatePremiumData } from 'src/app/shared/interface/custom-footer';
import { HealthService } from 'src/app/shared/proxy-services/health.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { SaveEditProposalV2RequestPayload, Member, SaveEditProposalV2HAPRequestPayload, InsuranceMember } from 'src/app/shared/interface/max-protect';
import { DatePipe } from '@angular/common';
import { MaxProtectModal } from '../../products/max-protect/max-protect.modal';
import { MaxProtectService } from 'src/app/shared/proxy-services/max-protect.service';
import { ConvertSavedQuotesResponsePayload, SavedQuote } from 'src/app/shared/interface/savedQuotes';
import { PaymodePaymentService } from 'src/app/shared/proxy-services/paymode-payment.service';
import { Router } from '@angular/router';
import { popupButton, popupButtonAction, popupDescriptionMsg, popupHeader, popupImgPath, popupType } from 'src/app/shared/enums/popupEnums';
import { DeclarationsService } from 'src/app/shared/services/declarations.service';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { StepData } from 'src/app/shared/interface/custom-stepper';
import { PaymentStatusRequestPayload } from 'src/app/shared/interface/payment';
import { ElevateService } from 'src/app/shared/proxy-services/elevate.service';
import { OrionHAAPService } from 'src/app/shared/proxy-services/orion/orion-haap.service';
import { logKYCData } from 'src/app/shared/interface/utilityApi';
import { UtilityApiService } from 'src/app/shared/proxy-services/utility-api.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.scss'],
  providers: [DatePipe]
})
export class SummaryPageComponent implements OnDestroy {

  rnAgent: any;
  healthFormData: HealthDataStorage;
  MemberDetailsData = [];
  bifitCovers = [];
  befitproposalDisable = false;
  homeInsuranceDisable = false;
  SummaryForm: FormGroup
  selectedHomePlanDetails;
  homeMasterData: HomeMasterDataResponsePayloadStructure;
  errorPopupData: PopupModal;
  genericBefitShow: boolean = true;
  genericHomeShow: boolean = true;
  isSaveQuote: boolean = false;

  finalBefitPlanSelected: string = "";
  private subscription: Subscription[] = [];
  memberDetails = [];
  nomineeDOB: string = '';
  appointeeDOB: string = '';
  maxProtectDataModal: MaxProtectModal = new MaxProtectModal();
  adultMemberData = [{
    'applicantRelationship': '',
    'dob': '',
    'feetHeight': '',
    'fullName': '',
    'inchesHeight': '',
    'insuredSince': '',
    'pneumococcalDOB': '',
    'preExistinDisease': '',
    'previouslyInsured': '',
    'sumInsured': '',
    'title': '',
    'waiverPeriod': '',
    'weight': ''
  }]
  selectedCoverId = 0;
  befitResponse: BefitResponsePayload;
  hapDataModal: HAPModal = new HAPModal();
  totalpremiumWithbefitCover: number = 0;
  selectedCoverName: string = '';
  invalid: boolean = false;
  insuredData: [];
  BefitSelectedPremium: number = 0;
  relationshipDetails: RelationshipResponsePayload;
  saveHeathRes: SaveHealthPolicyResponsePayload;
  SIinsured: string = '';
  homeSumInsured: number = 0;
  zeroTatResponseBefit: CreateBefitProposalResponsePayload;
  CreateHomeResponse: CreateHomeResponsePayload;
  hmpMandatory: boolean = false;
  agentDetails: AgentMasterStructure;
  medicaltest: string;
  isBMIValid: boolean;
  homeCoverDetails: PlanDetails[];
  BenefitDiscription: any[];
  routeData: string = '';
  quoteDetails: ConvertSavedQuotesResponsePayload;
  saveButtonShow: boolean = true;
  channelData: ChannelData;
  steps: StepData[] = [];
  productType: string = '';
  befitHomeDetails: BefitHomeStorage;
  checkData = [];
  isOrionJourney: string;
  homeHAPCustomerId: number = 0;
  saverBundlePolicyId: string;
  saverBundleProposalNumber: string;

  constructor(private fb: FormBuilder,
    private utilityAPIService: UtilityApiService,
    private healthMasterService: HealthMasterService,
    private healthDataStorageService: HealthDataStorageService,
    private storageService: StorageService,
    public utilitySerice: UtilityService,
    private homeService: HomeService,
    private healthService: HealthService,
    public router: Router,
    private popupService: PopupService,
    private agentV1Service: AgentV1Service,
    public paymentService: PaymentService,
    private spinner: LoaderService,
    private wrapperService: WrapperService,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    private renderer: Renderer2,
    private datePipe: DatePipe,
    private utilityService: UtilityService,
    private maxProtectService: MaxProtectService,
    private elevate_service: ElevateService,
    private paymode_payment_service: PaymodePaymentService,
    private declarationService: DeclarationsService,
    private orionHAAPService: OrionHAAPService,
    private shareservice: SharedService) {
    this.subscription.push(this.popupService.paymentValue$.subscribe(x => {
      this.proposalCreation(x);
    }))
  }

  ngOnInit() {
    this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
    this.befitHomeDetails = this.healthDataStorageService.getBefithomeStorage();
    this.logKYCData();
    this.isOrionJourney = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney);
    this.routeData = (this.router.url).split('/')[2];
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    this.productType = this.storageService.getAESDecryptedData(userEnums.ProductType);
    this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);
    window.scrollTo(0, 0);
    this.createForm();
    setTimeout(() => {
      this.nomineeDOB = this.healthFormData.kycFormData.nomineeDetails.nomineeDOB;
      this.nomineeDOB = this.utilitySerice.formatDateWithDash(this.nomineeDOB);
      this.appointeeDOB = this.healthFormData.kycFormData.appointeeDetails.appointeeDOB;
      this.appointeeDOB = this.utilitySerice.formatDateWithDash(this.appointeeDOB);
      this.insuredMemberDetails(this.MemberDetailsData);
      this.constructInsuredMembers();
      this.manageRelationShip();
      this.showBefitHomePlan();
      this.checkHMPCondition();
      this.MedicaltestGuideLines();
    }, 0);
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    if (this.channelData.CIP == 'NYSAPRO' || this.channelData.CIP == 'NYSABANCA' || this.healthFormData.quoteFormData.subProductType == ProductCode.Elevate || this.routeData === routeEnums.SuperSaver || this.routeData === routeEnums.ActivateBooster || this.isOrionJourney == 'true' || (this.channelData.hasOwnProperty('BFLAG') && (this.channelData.BFLAG || this.channelData.BFLAG == "true"))) {
      this.saveButtonShow = false;
    } else if (this.quoteDetails && this.quoteDetails.Data) {
      this.saveButtonShow = false;
      this.updateConvertSummaryData();
    } else {
      this.saveButtonShow = true;
    }

  }

  logKYCData() {
    let kycData = this.kycDataDetails();
    this.subscription.push(this.utilityAPIService.logDataDetails(kycData).subscribe({
      next: (res) => {
        if (res.StatusCode == Constants.statusMsg_SuccessCode) {
        }
      }
    }));
  }

  createForm() {
    this.SummaryForm = this.fb.group({
      befitPlan: ["false"],
      hmpPlan: ["true"],
      homePlan: ["false"],
      medicalDeclaration1: [true, [Validators.required]],
      medicalDeclaration2: [true, [Validators.required]],
      BefitSelectionPlan: ['']
    })
    //agent data storage
    this.agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
    this.rnAgent = this.agentDetails.AgentName;
    this.MemberDetailsData.push(this.healthFormData.insuredFormData.insuredMemberDetails);
  }

  insuredMemberDetails(value: any) {
    value.forEach((item, index) => {
      for (const key in item) {
        if (key.includes('Adult' + (index + 1))) {
          this.adultMemberData.push(item);
        }
      }
    });
  }

  updateConvertSummaryData() {
    let homePlan = this.quoteDetails.Data.GenericData.homePlan ? "true" : "false";
    if (this.healthFormData.quoteFormData.subProductType != 42) {
      this.healthFormData.quoteFormData.isBefitApplicable = "true";
      this.healthFormData.insuredFormData.befitCovers = this.quoteDetails.Data.GenericData.befitCovers;

    }
    let selectedHomePlan = this.quoteDetails.Data.GenericData.selectedHomePlanDetails;
    this.genericBefitShow = true;
    if (this.quoteDetails.Data.ProductType == "Portability") {
      this.healthFormData.portabilityDataSet = true;
    }
    if (this.quoteDetails.Data.IsBefit) {
      this.SummaryForm.controls['befitPlan'].setValue('true');
      this.hmpMandatory = this.quoteDetails.Data.GenericData.hmpPlan;
      this.selectedCoverName = this.befitHomeDetails && this.befitHomeDetails.befitSelection ? this.befitHomeDetails.befitData : this.quoteDetails.Data.GenericData.befitSelectedCoverName;
      this.SummaryForm.controls['BefitSelectionPlan'].setValue(this.selectedCoverName);
      setTimeout(() => {
        this.onbefitPanSelection(this.selectedCoverName);
      }, 0);
    }
    if (this.quoteDetails.Data.GenericData.homePlan) {
      this.SummaryForm.controls['homePlan'].setValue(homePlan);
      this.healthFormData.quoteFormData.underWritterApproved = this.quoteDetails.Data.GenericData.underWritterApproved;
      this.homeInsuranceSelection(homePlan);
      let homeInsuranceCover;
      if (this.befitHomeDetails) {
        if (this.befitHomeDetails.homeInsuranceSelection) {
          homeInsuranceCover = this.befitHomeDetails.homeInsuranceData;
        } else {
          homeInsuranceCover = selectedHomePlan;
        }
      } else {
        homeInsuranceCover = selectedHomePlan;
      }
      setTimeout(() => {
        this.homeCoverSelected(homeInsuranceCover);
      }, 0);
    }
    this.customFooterService.showPremiumDetails(true);
    this.steps = this.storageService.getAESDecryptedData(userEnums.StepperDetails);
    this.subscription.push(this.customStepperService.stepData$.subscribe(data => {
      this.customStepperService.activatedComponentCheck(data, this.steps);
    }))
  }

  constructInsuredMembers() {
    if (this.healthFormData.quoteFormData.totalMemberData.length > 0) {
      let childCount = 0;
      this.healthFormData.quoteFormData.totalMemberData.forEach((data, index) => {
        if (data['MemberType'] == healthMembers.Adult) {
          let adultMemberType = healthMembers.Adult + (index + 1);
          let feetHeight = ((this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + adultMemberType]).split('.')[0]);
          let inchesHeight = ((this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + adultMemberType]).split('.')[1]);
          let tempMemberObj = {
            'fullName': this.healthFormData.insuredFormData.insuredMemberDetails[index]['fullName' + adultMemberType],
            'Relationship': data['RelationshipwithApplicant'],
            'dob': data['DOB'].includes('T') ? this.utilitySerice.formatDate(data['DOB']) : data['DOB'],
            'height': feetHeight + '.' + inchesHeight,
            'feetHeight': feetHeight,
            'inchesHeight': inchesHeight,
            'weight': this.healthFormData.insuredFormData.insuredMemberDetails[index]['weight' + adultMemberType],
            'preExistingDisease': this.healthFormData.insuredFormData.insuredMemberDetails[index]['preExistinDisease' + adultMemberType],
            'pneumoccalDOB': !this.utilitySerice.isEmptyOrNull(data['VaccinationDate']) ? data['VaccinationDate'] : '',
            'memberTypeLabel': healthMembers.Adult + ' ' + (index + 1),
            'memberType': healthMembers.Adult
          }
          this.memberDetails.push(tempMemberObj);
        }
        else if (data['MemberType'] == healthMembers.Child) {
          childCount = childCount + 1;
          let childMemberType = healthMembers.Child + (childCount);
          let feetHeight = ((this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + childMemberType]).split('.')[0]);
          let inchesHeight = ((this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + childMemberType]).split('.')[1]);
          let tempMemberObj = {
            'fullName': this.healthFormData.insuredFormData.insuredMemberDetails[index]['fullName' + childMemberType],
            'Relationship': data['RelationshipwithApplicant'],
            'dob': data['DOB'],
            'height': feetHeight + '.' + inchesHeight,
            'feetHeight': feetHeight,
            'inchesHeight': inchesHeight,
            'weight': this.healthFormData.insuredFormData.insuredMemberDetails[index]['weight' + childMemberType],
            'preExistingDisease': this.healthFormData.insuredFormData.insuredMemberDetails[index]['preExistinDisease' + childMemberType] == 'Yes' ? "Yes" : "No",
            'pneumoccalDOB': '',
            'memberTypeLabel': healthMembers.Child + ' ' + childCount,
            'memberType': healthMembers.Child
          }
          this.memberDetails.push(tempMemberObj);
        }
      })
    }
  }

  manageRelationShip() {
    let product: RelationshipRequestPayload = {
      product: this.healthFormData.quoteFormData.healthProposalProductType,
    }
    this.subscription.push(this.healthMasterService.getHealthProposalRelationships(product).subscribe(response => {
      if (response.StatusCode == 1 && response.StatusMsg == "Success") {
        this.relationshipDetails = response;
      }
    }))
  }

  // ipaa-176: Save button to saved quotes
  saveQuotesButtonClick() {
    this.isSaveQuote = true;
    this.savedProposal();
  }

  onCheckBefit(value: string) {
    if (value == 'false') {
      if (this.quoteDetails) {
        this.updatePremiumData(this.quoteDetails.Data.TotalPremium);
      } else {
        this.updatePremiumData(this.healthFormData.premiumFormData.totalPremium);
      }
      this.SummaryForm.controls['befitPlan'].setValue('false');
      this.SummaryForm.controls['BefitSelectionPlan'].setValue('');
      this.BefitSelectedPremium = 0;
    }
    else {
      this.checkHMPCondition();
    }
  }

  savehealthPolicydetails(payMode: string) {
    let request: SavehealthPolicyRequestPayload = this.constructPolicyDetails(payMode);
    this.subscription.push(this.healthService.savehealthPolicyDetails(request).subscribe(response => {
      if (response.StatusCode == Constants.statusCode_success) {
        this.saveHeathRes = response;
        this.paymentLink(payMode);
      }
      else {
        this.spinner.hide();
      }
    }))
  }

  befitCreation(payMode: string) {
    let request: CreateBefitProposalRequestPayload = this.constructCreateBefit();
    this.subscription.push(this.wrapperService.createBefitProposal(request).subscribe(response => {
      if (response.StatusCode == 1 && response.StatusMsg == "Success") {
        this.zeroTatResponseBefit = response;
        this.savehealthPolicydetails(payMode);
      }
    }))
  }

  constructCreateBefit(): CreateBefitProposalRequestPayload {
    let postData: CreateBefitProposalRequestPayload = {
      "BaseProductProposalNo": this.healthFormData.policyDetails.PfProposalNumber,
      "CorrelationId": this.utilitySerice.createCorrelationId(),
      "CustomerDetails":
      {
        "AddressLine1": this.healthFormData.kycFormData.permanentAddress.permanentAddress1,
        "CityCode": this.healthFormData.quoteFormData.cityId,
        "CustomerID": this.healthFormData.policyDetails.PfCustomerId,
        "CustomerName": this.healthFormData.kycFormData.applicantDetails.applicantFullName,
        "DateOfBirth": this.utilitySerice.formatDate(this.healthFormData.kycFormData.applicantDetails.applicantDOB),
        "Email": this.healthFormData.kycFormData.permanentAddress.permanentEmail,
        "Gender": this.healthFormData.kycFormData.applicantDetails.applicantTitle == 'Mr.' ? 'MALE' : 'FEMALE',
        "MobileNumber": this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
        "PANCardNo": this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber,
        "PinCode": this.healthFormData.kycFormData.correspondenceAddress.correspondencePincode,
        "StateCode": this.healthFormData.quoteFormData.stateId,
      },
      "DealId": this.healthFormData.policyDetails.DealId,
      "ILProductCode": 4180,
      "PolicyStartDate": this.utilitySerice.formatDateWithDash(this.healthFormData.policyDetails.PolicyStartDate),
      "PolicyEndDate": this.utilitySerice.formatDateWithDash(this.healthFormData.policyDetails.PolicyEndDate),
      "PolicyTenure": Number(this.healthFormData.premiumFormData.tenure),
      "SubProductCode": this.befitResponse.Data.ProductCode,
      "riskDetails": {
        "InwardDate": this.utilitySerice.formatDateWithDash(new Date()),
        "NomineeDOB": this.utilitySerice.formatDate(this.healthFormData.kycFormData.nomineeDetails.nomineeDOB),
        "NomineeName": this.healthFormData.kycFormData.nomineeDetails.nomineeFullName,
        "NomineeRelationship": this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship,
        "PlanCode": this.befitResponse.Data.PlanCode,
        "RisksList": [
          {
            "SumInsured": Number(this.befitResponse.Data.SumInsured),
            "coverDetailsList": [{
              "CoverDescription": this.selectedCoverName,
              "SumInsured": Number(this.befitResponse.Data.SumInsured),
            }]
          }]
      },
      "insuredDataDetails": this.insuredDetails(),
    }
    return postData;
  }

  insuredDetails() {
    let memberDetails = [];
    if (this.healthFormData.quoteFormData.totalMemberData.length > 0) {
      let childCount = 0;
      this.healthFormData.quoteFormData.totalMemberData.forEach((data, index) => {
        if (data['MemberType'] == healthMembers.Adult) {
          let adultMemberType = healthMembers.Adult + (index + 1);
          let feetHeight = ((this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + adultMemberType]).split('.')[0]);
          let inchesHeight = ((this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + adultMemberType]).split('.')[1]);

          let tempMemberObj = {
            'InsuredName': this.healthFormData.insuredFormData.insuredMemberDetails[index]['fullName' + adultMemberType],
            'InsuredRelation': data['RelationshipwithApplicant'],
            'InsuredAge': this.calculateAge(data['DOB']),
            'InsuredDOB': this.utilitySerice.formatDateWithDash(data['DOB']),
            'InsuredGender': (this.healthFormData.insuredFormData.insuredMemberDetails[index]['title' + adultMemberType]) == 'Mr.' ? 'Male' : 'Female',
            'InsuredHeightinFeet': feetHeight,
            'InsuredHeightininches': inchesHeight,
            'InsuredWeight': this.healthFormData.insuredFormData.insuredMemberDetails[index]['weight' + adultMemberType],
          }
          memberDetails.push(tempMemberObj);
        }
        if (data['MemberType'] == healthMembers.Child) {
          childCount = childCount + 1;
          let childMemberType = healthMembers.Child + (childCount);
          let feetHeight = ((this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + childMemberType]).split('.')[0]);
          let inchesHeight = ((this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + childMemberType]).split('.')[1]);
          let tempMemberObj = {
            'InsuredName': this.healthFormData.insuredFormData.insuredMemberDetails[index]['fullName' + childMemberType],
            'InsuredRelation': data['RelationshipwithApplicant'],
            'InsuredAge': this.calculateAge(data['DOB']),
            'InsuredDOB': this.utilitySerice.formatDateWithDash(data['DOB']),
            'InsuredGender': (this.healthFormData.insuredFormData.insuredMemberDetails[index]['title' + childMemberType]) == 'Mr.' ? 'Male' : 'female',
            'InsuredHeightinFeet': feetHeight,
            'InsuredHeightininches': inchesHeight,
            'InsuredWeight': this.healthFormData.insuredFormData.insuredMemberDetails[index]['weight' + childMemberType],
          }
          memberDetails.push(tempMemberObj);
        }
      })
    }
    return memberDetails;
  }

  memberInsuredDetails() {
    const memberDetails = [];

    const mapRelationshipID = (relationshipName) => {
      const relationship = this.relationshipDetails.Data.InsuredRelationship.find(x => x.RelationshipName === relationshipName);
      return relationship ? relationship.RelationshipID : '';
    };

    const mapTitleID = (title) => {
      const titleObj = this.hapDataModal.title.find(x => x.label === title);
      return titleObj ? titleObj.value : '';
    };

    const mapGender = (title) => {
      return title === 'Mr.' ? 'Male' : 'Female';
    };

    const mapHeight = (height) => {
      return height.split('.')[0];
    };
    if (this.healthFormData.quoteFormData.totalMemberData.length > 0) {
      let childCount = 0;

      this.healthFormData.quoteFormData.totalMemberData.forEach((data, index) => {
        if (data['MemberType'] == healthMembers.Adult) {
          const adultMemberType = healthMembers.Adult + (index + 1);
          const relationshipID = mapRelationshipID(data['RelationshipwithApplicant']);
          const titleID = mapTitleID(this.healthFormData.insuredFormData.insuredMemberDetails[index]['title' + adultMemberType]);
          const feetHeight = mapHeight(this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + adultMemberType]);
          let planID = 0;

          if (this.SummaryForm.controls['befitPlan'].value === 'true') {
            planID = this.befitResponse.Data.PlanCode;
          } else if (this.SummaryForm.controls['homePlan'].value === 'true') {
            planID = this.homeMasterData.CHPPlanCode;
          }
          const tempMemberObj = {
            "memberType": data['MemberType'],
            "titleID": parseInt(titleID),
            "name": this.healthFormData.insuredFormData.insuredMemberDetails[index]['fullName' + adultMemberType],
            "relationshipID": relationshipID,
            "relationshipName": data['RelationshipwithApplicant'],
            "dob": data['DOB'],
            "height": feetHeight,
            "weight": this.healthFormData.insuredFormData.insuredMemberDetails[index]['weight' + adultMemberType],
            "otherDisease": null,
            "addOnAgeGroup": null,
            "addOnName": null,
            "gender": mapGender(this.healthFormData.insuredFormData.insuredMemberDetails[index]['title' + adultMemberType]),
            "planID": planID == 0 ? '' : planID,
            "policyValidFrom": this.healthFormData.policyDetails.PolicyStartDate,
            "policyValidTill": this.healthFormData.policyDetails.PolicyEndDate,
            "sumInsuredValue": 0,
            "sumInsured": 0
          };

          memberDetails.push(tempMemberObj);
        }

        if (data['MemberType'] == healthMembers.Child) {
          childCount = childCount + 1;
          const childMemberType = healthMembers.Child + (childCount);
          const relationshipID = mapRelationshipID(data['RelationshipwithApplicant']);
          const titleID = mapTitleID(this.healthFormData.insuredFormData.insuredMemberDetails[index]['title' + childMemberType]);
          const feetHeight = mapHeight(this.healthFormData.insuredFormData.insuredMemberDetails[index]['feetInchesHeight' + childMemberType]);
          let planID = 0;

          if (this.SummaryForm.controls['befitPlan'].value === 'true') {
            planID = this.befitResponse.Data.PlanCode;
          } else if (this.SummaryForm.controls['homePlan'].value === 'true') {
            planID = this.homeMasterData.CHPPlanCode;
          }
          const tempMemberObj = {
            "memberType": data['MemberType'],
            "titleID": parseInt(titleID),
            "name": this.healthFormData.insuredFormData.insuredMemberDetails[index]['fullName' + childMemberType],
            "relationshipID": relationshipID,
            "relationshipName": data['RelationshipwithApplicant'],
            "dob": data['DOB'],
            "height": feetHeight,
            "weight": this.healthFormData.insuredFormData.insuredMemberDetails[index]['weight' + childMemberType],
            "otherDisease": null,
            "addOnAgeGroup": null,
            "addOnName": null,
            "gender": mapGender(this.healthFormData.insuredFormData.insuredMemberDetails[index]['title' + childMemberType]),
            "planID": planID == 0 ? '' : planID,
            "policyValidFrom": this.healthFormData.policyDetails.PolicyStartDate,
            "policyValidTill": this.healthFormData.policyDetails.PolicyEndDate,
            "sumInsuredValue": 0,
            "sumInsured": 0
          };

          memberDetails.push(tempMemberObj);
        }
      });
    }

    return memberDetails;
  }

  constructPolicyDetails(payMode: string): SavehealthPolicyRequestPayload {
    let requestData: SavehealthPolicyRequestPayload =
    {
      "PayMode": payMode.toLowerCase(),
      "correlation": this.SummaryForm.controls['befitPlan'].value == 'true' ? this.zeroTatResponseBefit.Data.correlationId : this.CreateHomeResponse.Data.CorrelationId,
      "pfProposalNo": this.SummaryForm.controls['befitPlan'].value == 'true' ? this.zeroTatResponseBefit.Data.proposalNumber : this.CreateHomeResponse.Data.ProposalNumber,
      "userType": this.healthFormData.kycFormData.customerType,
      "noOfAdults": this.healthFormData.quoteFormData.adultDetails.length,
      "noOfKids": this.healthFormData.quoteFormData.childDetails.length,
      "sumInsured": this.healthFormData.quoteFormData.applicantAnnualSum,
      "tenure": this.SummaryForm.controls['befitPlan'].value == 'true' ? this.healthFormData.premiumFormData.tenure : 1,
      "dealID": this.healthFormData.policyDetails.DealId,
      "policyType": this.SummaryForm.controls['befitPlan'].value == 'true' ? "2" : "4",
      "subPolicyType": this.SummaryForm.controls['befitPlan'].value == 'true' ? "37" : "1",
      "policyStartDate": this.healthFormData.policyDetails.PolicyStartDate,
      "policyEndDate": this.healthFormData.policyDetails.PolicyEndDate,
      "basicPremium": this.SummaryForm.controls['befitPlan'].value == 'true' ? Number(this.befitResponse.Data.BasicPremium) : Number(this.selectedHomePlanDetails.Premium),//home proposal left
      "totalTax": this.SummaryForm.controls['befitPlan'].value == 'true' ? Number(this.befitResponse.Data.TotalTax) : 0,
      "totalPremium": this.SummaryForm.controls['befitPlan'].value == 'true' ? this.befitResponse.Data.TotalPremium : Number(this.selectedHomePlanDetails.Premium), "coverPremium": "0", // home proposal left
      "members": this.memberInsuredDetails(),
      "ipaddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      "titleId": this.hapDataModal.title.filter(data => data.label == this.healthFormData.kycFormData.applicantDetails.applicantTitle)[0].value,//left from insured page
      "customerID": this.homeHAPCustomerId != 0 ? this.homeHAPCustomerId : this.healthFormData.policyDetails.CustomerId,
      "customerName": this.healthFormData.kycFormData.applicantDetails.applicantFullName,
      "customerDOB": this.SummaryForm.controls['befitPlan'].value == 'true' ? this.healthFormData.kycFormData.applicantDetails.applicantDOB : this.utilitySerice.formatDate((this.healthFormData.kycFormData.applicantDetails.applicantDOB)),
      "customerType": this.healthFormData.kycFormData.customerType,
      "emailID": this.healthFormData.kycFormData.permanentAddress.permanentEmail,
      "mobileNo": this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
      "address": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress1,
      "pincode": this.healthFormData.kycFormData.correspondenceAddress.correspondencePincode,
      "cityID": this.healthFormData.quoteFormData.cityId,
      "stateID": this.healthFormData.quoteFormData.stateId,
      "countryId": 100,
      "permanentAddress": this.healthFormData.kycFormData.permanentAddress.permanentAddress1,
      "permanentAddrPincodeID": this.healthFormData.kycFormData.permanentAddress.permanentPincode,
      "permanentAddrStateID": this.healthFormData.quoteFormData.stateId,
      "permanentAddrCityID": this.healthFormData.quoteFormData.cityId,
      "pfCustomerId": this.healthFormData.policyDetails.PfCustomerId,
      "BancaToken": this.channelData.CIP == 'NYSABANCA' ? this.channelData.BT : ''
    }
    return requestData;
  }

  onbefitPanSelection(value: string) {
    this.selectedCoverName = value;
    this.befitHomeDetails.befitSelection = true;
    this.befitHomeDetails.befitData = value;
    this.healthDataStorageService.setBefithomeStorage(this.befitHomeDetails);
    this.healthFormData.insuredFormData.befitCovers.forEach((x: any) => {
      if (x.CoverName == value) {
        this.selectedCoverId = x.CoverId;
        this.BenefitDiscription = x.befitCoverBenefits
      }
    })
    let request1 = this.constructBefitPlanPremium();
    this.BefitSelectedPremium = 0;
    let totalpremiumWithbefitCover: number = 0;
    this.subscription.push(this.healthService.getBefitPlanPremiumDetails(request1).subscribe(response => {
      if (response.StatusCode == 1 && response.StatusMsg == "Success") {
        this.BefitSelectedPremium = response.Data.TotalPremium;
        this.befitResponse = response;
        if (this.quoteDetails) {
          totalpremiumWithbefitCover = parseInt(this.quoteDetails.Data.TotalPremium) + this.BefitSelectedPremium;
        } else {
          totalpremiumWithbefitCover = parseInt(this.healthFormData.premiumFormData.totalPremium) + this.BefitSelectedPremium;
        }
        this.updatePremiumData(totalpremiumWithbefitCover.toString());
      }
      else {
        this.SummaryForm.controls['befitPlan'].setValue('false');
        this.updatePremiumData(this.healthFormData.premiumFormData.totalPremium);
        this.errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusDesc);
        this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))
  }

  updatePremiumData(premium: string) {
    let premiumData: UpdatePremiumData = {
      update: false,
      data: '',
      value: 'summary'
    };
    premiumData.update = true;
    premiumData.data = premium;
    this.customFooterService.updatePremiumDetails(premiumData);
  }

  calculateAge(dob: string) {
    const today = new Date();
    const birthDate = new Date(dob);
    const timeDiff = today.getTime() - birthDate.getTime();
    const ageInMilliseconds = timeDiff;

    // Calculate the age in years
    const ageInYears = Math.floor(ageInMilliseconds / (1000 * 3600 * 24 * 365.25));

    return ageInYears;
  }

  constructBefitPlanPremium() {
    let request1 = {
      AgeOfEldestMember: this.calculateAge(this.memberDetails[0].dob),
      CoverId: this.selectedCoverId,
      NoOfAdult: this.healthFormData.quoteFormData.adultDetails.length,
      NoOfKid: this.healthFormData.quoteFormData.childDetails.length,
      Tenure: this.healthFormData.premiumFormData.tenure,
    }
    return request1
  }

  setProposalData(data) {
    //for saver and booster response is array of object and for rest products its object
    const result = Array.isArray(data.Data) ? data.Data[0] : data.Data;

    if (this.healthFormData.quoteFormData.subProductType === 46) {
      this.saverBundlePolicyId = data.Data[1].PolicyID.toString();
      this.saverBundleProposalNumber = data.Data[1].PfProposalNumber.toString();
    }
    this.healthFormData.policyDetails.BasicPremium = result.BasicPremium;
    this.healthFormData.policyDetails.CustomerId = result.CustomerId;
    this.healthFormData.policyDetails.EPolicyID = result.EPolicyID;
    this.healthFormData.policyDetails.PfCustomerId = result.PfCustomerId;
    this.healthFormData.policyDetails.PfProposalNumber = result.PfProposalNumber;
    this.healthFormData.policyDetails.PfQuoteId = result.PfQuoteId;
    this.healthFormData.policyDetails.PolicyEndDate = result.PolicyEndDate;
    this.healthFormData.policyDetails.PolicyID = result.PolicyID;
    this.healthFormData.policyDetails.PolicyStartDate = result.PolicyStartDate;
    this.healthFormData.policyDetails.PolicyStatus = result.PolicyStatus;
    this.healthFormData.policyDetails.PolicySubType = result.PolicySubType;
    this.healthFormData.policyDetails.ProposalNumber = result.ProposalNumber;
    this.healthFormData.policyDetails.QuoteId = result.QuoteId;
    this.healthFormData.policyDetails.Tenure = result.Tenure;
    this.healthFormData.policyDetails.TotalPremium = result.TotalPremium;
    this.healthFormData.policyDetails.TotalTax = result.TotalTax;
    this.healthFormData.policyDetails.PolicyID = result.PolicyID;
    // setting transaction details needed in payment confirmation
    this.healthFormData.paymentConfirmationDetails.PfProposalNumber = result.PfProposalNumber;
    this.healthFormData.paymentConfirmationDetails.productType = this.healthFormData.quoteFormData.productType;
    if (this.routeData === routeEnums.ActivateBooster) {
      this.healthFormData.policyDetails.PolicyType = result.PolicyType;
      this.healthFormData.policyDetails.DealId = result.DealId;
    }
  }

  setMaxProposalDataV2(data) {
    this.healthFormData.policyDetails.BasicPremium = data.Data.policyDetails.BasicPremium;
    this.healthFormData.policyDetails.TotalPremium = data.Data.policyDetails.TotalPremium;
    this.healthFormData.policyDetails.PfProposalNumber = data.Data.policyDetails.PfProposalNumber;
    this.healthFormData.policyDetails.PolicyID = data.Data.policyDetails.PolicyID;
  }

  paymentModeSelected(buttonData: EmitButtonData) {
    switch (buttonData['label']) {
      case 'Payment Link': {
        this.proposalCreation('PaymentLink');
        break;
      }
      case 'Pay Now': {
        this.proposalCreation('PayNow');
        if (this.channelData.hasOwnProperty('BFLAG') && (this.channelData.BFLAG || this.channelData.BFLAG == "true")) {
          this.storageService.setAESEncryptedDataLocal('ctrbflagth', this.channelData.BFLAG)
        }
        break;
      }
      case 'Pay Later': {
        this.proposalCreation('PayLater');
        break;
      }
      case 'Save': {
        this.proposalCreation('Save');
        break;
      }
      default:
        break;
    }
  }

  //need to add object as per kyc modal
  kycDataDetails() {
    let obj: logKYCData = {
      "ckyc_no": this.healthFormData.kycFormData.CKYCID,
      "id_type": this.healthFormData.kycFormData.certificateType,
      "ekyc_no": this.healthFormData.kycFormData.EKYCID,
      "ilreference_no": this.healthFormData.kycFormData.ilKycReferenceNumber,
      "customer_name": this.healthFormData.kycFormData.applicantDetails.applicantFullName,
      "cust_dob": this.healthFormData.kycFormData.applicantDetails.applicantDOB,
      "cust_emailid": this.healthFormData.kycFormData.permanentAddress.permanentEmail,
      "Mobile_no": this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
      "response": "",
      "correlation_id": this.healthFormData.kycFormData.kycCorrelationId,
      "action": "Summary"
    }
    return obj;
  }

  //after selection of paymentmode below function is called
  proposalCreation(payMode: string) {
    this.shareservice.emitSessionFlag(true);
    if (this.SummaryForm.controls['medicalDeclaration1'].value && this.SummaryForm.controls['medicalDeclaration2'].value) {

      if (this.SummaryForm.controls['befitPlan'].value == 'true' && this.BefitSelectedPremium == 0) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('Kindly select the befit plan');
        this.popupService.openGenericPopup(this.errorPopupData);
        return;
      }

      else {
        if (this.routeData == routeEnums.HAP || this.routeData == routeEnums.MaxProtect || this.isOrionJourney == 'true' || this.routeData == routeEnums.Elevate || this.routeData === routeEnums.SuperSaver || this.routeData === routeEnums.ActivateBooster) {
          this.commonApiKit(payMode);
        } else {
          this.commonProposal(payMode);
        }
      }
    }
    else {
      this.invalid = true;
    }
  }

  commonProposal(payMode) {
    let request1: SaveEditProposalV1RequestPayload = this.constructProposalRequest(this.healthFormData, payMode);
    let subscription = this.healthService.saveEditProposalV1(request1).subscribe({
      next: proposalResponse => {
        if (proposalResponse.StatusCode == Constants.statusCode_success) {
          this.setProposalData(proposalResponse);
          subscription.unsubscribe();
          if (this.SummaryForm.controls['befitPlan'].value == 'true') {
            this.befitCreation(payMode);
          }
          // If Home proposal selected
          else if (this.SummaryForm.controls['homePlan'].value == 'true') {
            this.createHomeProposalCreation(payMode);
          }
          else {
            this.paymentLink(payMode);
          }
        }
      },
      error: error => {
      }
    })
  }

  payment(payMode) {
    if (this.SummaryForm.controls['befitPlan'].value == 'true') {
      this.befitCreation(payMode);
    }
    // If Home proposal selected
    else if (this.SummaryForm.controls['homePlan'].value == 'true') {
      this.createHomeProposalCreation(payMode);
    }
    else {
      this.paymentLink(payMode);
    }
  }

  commonApiKit(payMode) {
    if (this.isOrionJourney == 'true') {
      let request = this.constructOrionHapProposalRequest(payMode);

      let subscription = this.orionHAAPService.saveEditOrionHAAPProposal(request).subscribe({
        next: proposalResponse => {
          if (proposalResponse.StatusCode == Constants.statusCode_success) {
            subscription.unsubscribe();
            let hapProposalResponse = { Data: proposalResponse.Data }
            this.setProposalData(hapProposalResponse);

            // If it is hap and home is selcted then call home api
            if (this.SummaryForm.controls['homePlan'].value == 'true') {
              this.createHomeProposalCreation(payMode);
            }
            else {
              this.paymentLink(payMode);
            }
          }
        },
        error: error => {
        }
      })

    } else {
      let hapProduct = this.routeData == routeEnums.HAP || this.routeData == routeEnums.Elevate || this.routeData === routeEnums.SuperSaver || this.routeData === routeEnums.ActivateBooster ? true : false
      let request = !hapProduct ? this.constructMaxProposalRequestV2(payMode) : this.constructHapElevateProposalRequest(payMode);
      let requestAPI = this.routeData == routeEnums.Elevate ? this.elevate_service.saveProposal(request) : (this.routeData === routeEnums.SuperSaver || this.routeData === routeEnums.ActivateBooster) ? this.elevate_service.saveSaverBoosterProposal(request) : this.maxProtectService.saveEditProposalV2(request);
      let subscription = requestAPI.subscribe({
        next: proposalResponse => {
          if (proposalResponse.StatusCode == Constants.statusCode_success) {
            subscription.unsubscribe();
            if (hapProduct) {
              let hapProposalResponse = { Data: proposalResponse.Data.policyDetails }
              this.setProposalData(hapProposalResponse);
            }
            else {
              this.setMaxProposalDataV2(proposalResponse);
            }
            // If it is hap and home is selcted then call home api
            if (this.SummaryForm.controls['homePlan'].value == 'true' && hapProduct) {
              this.homeHAPCustomerId = proposalResponse.Data.policyDetails.CustomerId;
              this.createHomeProposalCreation(payMode);
            }
            else {
              this.paymentLink(payMode);
            }
          }
        },
        error: error => {
        }
      })
    }

  }

  savedProposal() {
    let request = this.constructSavedQuotesRequest();
    this.subscription.push(this.healthService.savedQuote(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          setTimeout(() => {
            let popupData: PopupModal = {
              popupType: popupType.generic,
              imgName: popupImgPath.successGif,
              header: popupHeader.quoteSuccess,
              description: `${popupDescriptionMsg.savedQuotesSuccessMessage} ${response.Data.QuoteId}`,
              buttonLabel: popupButton.ok,
              buttonAction: popupButtonAction.dashboard
            }
            this.popupService.openGenericPopup(popupData);
          }, 0);
        }
      },
      error: error => {
      }
    }))
  }

  paymentLink(payMode: string) {
    let homePlan = this.SummaryForm.controls['homePlan'].value == "true" ? true : false;
    let befitPlan = this.SummaryForm.controls['befitPlan'].value == "true" ? true : false;
    let totalPremium = 0;
    if (homePlan) {
      totalPremium = parseInt(this.healthFormData.premiumFormData.totalPremium) + parseInt(this.selectedHomePlanDetails.Premium.toString());
    } else if (befitPlan) {
      totalPremium = parseInt(this.healthFormData.premiumFormData.totalPremium) + parseInt(this.BefitSelectedPremium.toString());
    } else {
      totalPremium = parseInt(this.healthFormData.premiumFormData.totalPremium);
    }
    //common payment service paynow
    let data: PaymentStatusRequestPayload = {
      paymode: payMode,
      befitPlan: this.SummaryForm.controls['befitPlan'].value == "true" ? true : false,
      homePlan: this.SummaryForm.controls['homePlan'].value == "true" ? true : false,
      zeroTatProduct: false,
      proposalPolicyID: this.healthFormData.policyDetails.PolicyID.toString(),
      bundalPolicyID: this.healthFormData.quoteFormData.subProductType === 46 ? this.saverBundlePolicyId : this.saveHeathRes?.Data.PolicyId.toString(),
      totalPremium: this.healthFormData.quoteFormData.InstallmentApplicable == 'Yes' ? this.healthFormData.premiumFormData.InstallmentTotalPremium.toString() : totalPremium.toString(),
      proposalNumber: this.isOrionJourney == 'true' ? this.healthFormData.policyDetails.ProposalNumber : this.healthFormData.policyDetails.PfProposalNumber,
      bundleProposalNumber: this.healthFormData.quoteFormData.subProductType === 46 ? this.saverBundleProposalNumber : this.saveHeathRes?.Data.PfProposalNo,
      bundlePlan: this.healthFormData.quoteFormData.subProductType === 46,
    }
    if (payMode == 'PayNow') {
      this.paymode_payment_service.CreateBundlePayment(data);
    }
    else if (payMode != 'Save') {
      //common payment service paylater/payment link
      this.paymode_payment_service.commonPayLaterRequest(data);
    }
  }

  fetchDetailsAsPaymentTransactionId(paymentTransactionId: string) {
    this.healthFormData.paymentConfirmationDetails.PfTransactionId = paymentTransactionId;
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);
    localStorage.clear();
    if (this.healthFormData.portabilityDataSet) {
      this.router.navigate([routeEnums.DocumentUpload]);
      // this.utilityService.redirectionToUploadDocument();
    }
    else {
      this.router.navigate(['.', routeEnums.PaymentConfirmation]);
    }
  }

  createHomeProposalCreation(payMode: string) {
    let request: CoverHomeRequestPayload = this.createHomeProposalRequest();
    this.subscription.push(this.wrapperService.createHomeProposal(request).subscribe(response => {
      if (response.StatusCode == Constants.statusCode_success) {
        this.CreateHomeResponse = response;
        this.savehealthPolicydetails(payMode);
      }
    }))
  }

  createHomeProposalRequest(): CoverHomeRequestPayload {
    let postData: CoverHomeRequestPayload = {
      "ContentSumInsured": this.homeSumInsured,
      "CorrelationId": this.utilitySerice.createCorrelationId(),
      "CoverDetails": this.getHomeCoverRequest(),
      "CustomerDetails": {
        "AddressLine1": this.healthFormData.kycFormData.permanentAddress.permanentAddress1,
        "CityCode": this.healthFormData.quoteFormData.cityId,
        "CKYCID": this.healthFormData.kycFormData.CKYCID,
        "CustomerName": this.healthFormData.kycFormData.applicantDetails.applicantFullName,
        "DateOfBirth": this.utilitySerice.DateWithSlash(this.healthFormData.kycFormData.applicantDetails.applicantDOB),
        "EKYCid": this.healthFormData.kycFormData.EKYCID,
        "Email": this.healthFormData.kycFormData.permanentAddress.permanentEmail,
        "ILkycReferenceNumber": this.healthFormData.kycFormData.ilKycReferenceNumber,
        "MobileNumber": this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
        "PepFlag": this.healthFormData.kycFormData.pepFlag,
        "PinCode": this.healthFormData.kycFormData.permanentAddress.permanentPincode,
        "StateCode": this.healthFormData.quoteFormData.stateId,
      },
      "DealId": this.homeMasterData.DealId,//this.agentDetails.MappedProduct.Home.HomeDealId,
      "FurnitureSI": this.homeSumInsured,//this.homeSumInsured,
      "HomePlanCode": this.homeMasterData.CHPPlanCode.toString(),
      "HomePlanName": this.homeMasterData.CHPPlanName,
      "InsuredAddressLine": this.healthFormData.kycFormData.permanentAddress.permanentAddress1,
      "InsuredCityCode": this.healthFormData.quoteFormData.cityId,
      "InsuredPinCode": this.healthFormData.kycFormData.permanentAddress.permanentPincode,
      "InsuredStateCode": this.healthFormData.quoteFormData.stateId,
      "InwardDate": this.utilitySerice.formatDateWithDash(new Date()),
      "PaymentAmount": Number(this.selectedHomePlanDetails.Premium),
      "PaymentDate": this.utilitySerice.DateWithSlash(new Date()),
      "PolicyStartDate": this.utilitySerice.formatDateWithDash(new Date()),
      "PremiumReceivedFromCustomer": Number(this.selectedHomePlanDetails.Premium),
      "PolicyEndDate": this.utilitySerice.formatDateWithDash(this.healthFormData.policyDetails.PolicyEndDate),
    }
    return postData;
  }

  getHomeCoverRequest() {
    let req = [];
    this.selectedHomePlanDetails.Covers.forEach(x => {
      let obj = {
        CoverCode: Number(x.Cover_Code),
        CoverName: x.Cover_Name,
        SumInsured: Number(x.Sum_Insured)
      }
      req.push(obj);
    })
    return req;
  }

  constructPaymentLinkRequest() {
    let request;
    request = {
      EmailID: this.healthFormData.kycFormData.permanentAddress.permanentEmail,
      PolicyID: this.healthFormData.policyDetails.PolicyID.toString(),
      RequestType: "2",
      MobileNo: this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
    }
    return request;
  }

  constructCommonPaymentRequest() {
    let request;
    if (this.SummaryForm.controls['befitPlan'].value == 'true' || this.SummaryForm.controls['homePlan'].value == 'true') {
      request = {
        "TransType": "BUNDLE_PAYMENT",
        "GatewayReturnURL": "",
        "PolicyIDs": this.healthFormData.policyDetails.PolicyID.toString() + ':' + this.saveHeathRes.Data.PolicyId,
        "PayerType": "Customer",
        "ModeID": 0,
        "UserRole": "AGENT",
        "IPAddress": "IPARTNER-MSITE",
        "PaymentMode": "None",
        "PaymentAmount": 0
      }
    } else {
      request = {
        "TransType": "POLICY_PAYMENT",
        "GatewayReturnURL": "",
        "PolicyIDs": this.healthFormData.policyDetails.PolicyID.toString(),
        "PayerType": "Customer",
        "ModeID": 0,
        "UserRole": "AGENT",
        "IPAddress": "IPARTNER-MSITE",
        "PaymentMode": "None",
        "PaymentAmount": 0
      }
    }
    return request;
  }

  constructPayNowRequest() {
    let request;
    request = {
      PolicyID: this.healthFormData.policyDetails.PolicyID.toString(),
      UserType: 'Agent',
      CorrelationID: null
    }
    return request;
  }

  constructSavedQuotesRequest() {
    const alteredDate = new Date(this.healthFormData.kycFormData.applicantDetails.applicantDOB)
    const formattedDate = this.datePipe.transform(alteredDate, 'yyyy-MM-ddTHH:mm:ss');
    let savedQuotesAdultDetails = [];
    let savedQuotesChildDetails = [];
    let adultDetails = this.healthFormData.quoteFormData.adultDetails;
    if (adultDetails) {
      savedQuotesAdultDetails = adultDetails.map((member, i) => ({
        memberCount: i + 1,
        dob: this.utilitySerice.formatDate(member[`dobAdult${(i + 1)}`]),
        relationship: member[`adultRealtionship${(i + 1)}`],
        vaccineDate: this.utilityService.formatDate(member[`adultVaccinationDate${(i + 1)}`]),
        vaccineStatus: member[`adultVaccinationStatus${(i + 1)}`],
        Gender: member[`Gender${(i + 1)}`]
      }));
    }
    let childDetails = this.healthFormData.quoteFormData.childDetails;
    if (childDetails) {
      savedQuotesChildDetails = childDetails.map((member, i) => ({
        memberCount: i + 1,
        dob: this.utilitySerice.formatDate(member[`dobChild${(i + 1)}`]),
        relationship: member[`childRelationship${(i + 1)}`],
        Gender: member[`Gender${(i + 1)}`]
      }));
    }
    let hapAddons = {
      addons: this.healthFormData.quoteFormData.addonsData,
      addOnData: this.healthFormData.quoteFormData.addonsData,
      cibilApplicable: this.healthFormData.quoteFormData.isCibilApplicable,
      cibilProposerName: this.healthFormData.quoteFormData.proposerName,
      cibilMobileNo: this.healthFormData.quoteFormData.mobileNo,
      cibilDOB: this.healthFormData.quoteFormData.DOB,
      cibilPincode: this.healthFormData.quoteFormData.pinCode,
      occupationdData: this.healthFormData.quoteFormData.occupationdData,
      cibilScoreDiscount: this.healthFormData.quoteFormData.cibilScoreDiscount,
      maternityForAdult: this.healthFormData.quoteFormData.maternityForAdult,
      criticalIllnessForAdult: this.healthFormData.quoteFormData.criticalIllnessForAdult,
      criticalAdultData: this.healthFormData.quoteFormData.criticalAdultData,
      maternityAdultData: this.healthFormData.quoteFormData.maternityAdultData,
      totalMemberData: this.healthFormData.quoteFormData.totalMemberData,
      hapRefillingSummary: this.healthFormData.quoteFormData.hapRefillingSummary,
      subProductCode: this.healthFormData.quoteFormData.subProductCode,
      netPremium: this.healthFormData.quoteFormData.netPremium,
      taxAmount: this.healthFormData.quoteFormData.taxAmount,
      emiPremium: this.healthFormData.quoteFormData.emiPremium,
      PlanCode: this.healthFormData.quoteFormData.PlanCode,
      PlanName: this.healthFormData.quoteFormData.PlanName,
      PortabilitySubProductCode: this.healthFormData.premiumFormData.PortabilitySubProductCode,
      proposerDobCi: this.healthFormData.quoteFormData.proposerDobCi
    };

    let genericData = {
      pfQuoteId: this.healthFormData.premiumFormData.PfQuoteId,
      befitCovers: this.healthFormData.insuredFormData.befitCovers,
      befitBenefitDiscription: this.BenefitDiscription,
      befitSelectedCoverName: this.selectedCoverName,
      dealID: this.healthFormData.policyDetails.DealId,
      cityId: this.healthFormData.quoteFormData.cityId,
      stateId: this.healthFormData.quoteFormData.stateId,
      kycAppointee: this.healthFormData.showAppointee,
      kycStateId: this.healthFormData.kycFormData.KYCStateId,
      homePlan: this.homeInsuranceDisable,
      selectedHomePlanDetails: this.selectedHomePlanDetails,
      underWritterApproved: this.healthFormData.quoteFormData.underWritterApproved,
      hmpPlan: this.hmpMandatory,
      befitPremium: this.befitResponse && this.befitResponse.Data.TotalPremium ? this.befitResponse.Data.TotalPremium : 0,
      homePremium: this.selectedHomePlanDetails && this.selectedHomePlanDetails.Premium ? this.selectedHomePlanDetails.Premium : 0,
      productCode: this.healthFormData.quoteFormData.productCode,
      gsCheckbox2: this.healthFormData.quoteFormData.checkbox2,
      productType: this.productType,
      insuredPED: this.healthFormData.insuredFormData.preExistingDisease,
      kycApplicantTitleID: this.healthFormData.kycFormData.applicantDetails.applicantTitle,
      addonListHB: this.healthFormData.quoteFormData.subProductType == 20 ? this.healthFormData.quoteFormData.addonListHB : [],
      KYCStateId: parseInt(this.healthFormData.kycFormData.KYCStateId),
      KYCCityId: parseInt(this.healthFormData.kycFormData.KYCCityId),
      CoStateId: parseInt(this.healthFormData.kycFormData.CoStateId),
      CoCityId: parseInt(this.healthFormData.kycFormData.CoCityId),
      kycOvd: this.healthFormData.kycStatus.ovd,
      kycOvdSelf: this.healthFormData.kycStatus.ovdSelf,
      kycIDType: this.healthFormData.kycStatus.idType
    };

    this.healthFormData.quoteFormData.SumInsured = parseInt(this.healthFormData.quoteFormData.applicantAnnualSum);
    let specificConditionWaiting;
    if (this.healthFormData.quoteFormData.subProductType == 45 || this.healthFormData.quoteFormData.subProductType == 46 || this.healthFormData.quoteFormData.subProductType == 47) {
      if (this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.conditionWaitingPeriod)) {
        specificConditionWaiting = undefined;
      } else {
        specificConditionWaiting = this.healthFormData.quoteFormData.conditionWaitingPeriod + ' Years';
      }
    } else {
      specificConditionWaiting = this.healthFormData.quoteFormData.conditionWaitingPeriod;
    }

    let saveProposalRequest: SavedQuote = {
      "UserType": "AGENT",
      "IpAddress": "IPARTNER-Msite",
      "ProductType": this.healthFormData.quoteFormData.productType ? this.healthFormData.quoteFormData.productType : null,
      "SubProductType": this.healthFormData.quoteFormData.subProductType,
      "SubProductCode": this.healthFormData.quoteFormData.subProductType == 34 ? this.healthFormData.quoteFormData.subProductCode : this.healthFormData.premiumFormData.SubProductCode,
      "AdultDetails": savedQuotesAdultDetails,
      "AdultRelationShips": this.healthFormData.quoteFormData.adultRelationShips,
      "ChildRelationShips": this.healthFormData.quoteFormData.childRelationShips,
      "ChildDetails": savedQuotesChildDetails,
      "City": this.healthFormData.quoteFormData.cityName,
      "PolicyRange": this.healthFormData.quoteFormData.policyRange,
      "CityState": this.healthFormData.quoteFormData.cityState,
      "Quotesummary": this.healthFormData.quoteFormData.quoteSummary,
      "isIbankRelationship": false,
      "BankRelationship": {
        "IsIbankRelationship": false,
        "ApsId": "",
        "CustomerRefNo": ""
      },
      "Members": this.healthFormData.quoteFormData.subProductType == 42 ? this.constructHapMemberDetails() : this.constructMemberDetails(),
      "NomineeTitleID": this.healthFormData.kycFormData.nomineeDetails.NomineeTitleID,
      "NomineeDOB": this.utilitySerice.formatDate(this.healthFormData.kycFormData.nomineeDetails.nomineeDOB),
      "NomineeName": this.healthFormData.kycFormData.nomineeDetails.nomineeFullName,
      "NomineeRelationShipID": this.healthFormData.kycFormData.nomineeDetails.NomineeRelationshipID,
      "NomineeRelationShipText": this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship,
      "HUF": "N",
      "isAppointeeRequired": this.healthFormData.showAppointee,
      "AppointeeName": this.healthFormData.kycFormData.appointeeDetails.appointeeFullName ?? '',
      "AppointeeTitleID": this.healthFormData.kycFormData.appointeeDetails.appointeeTitleID ?? 0,
      "AppointeeRelationShipID": this.healthFormData.kycFormData.appointeeDetails.appointeeRelationShipID ?? 0,
      "AppointeeRelationShip": this.healthFormData.kycFormData.appointeeDetails.appointeeRelationship ?? '',
      "AppointeeDOB": this.healthFormData.kycFormData.appointeeDetails.appointeeDOB ? this.utilitySerice.formatDate(this.healthFormData.kycFormData.appointeeDetails.appointeeDOB) : '',
      "CustomerID": this.utilitySerice.isUndefinedORNull(this.healthFormData.kycFormData.CustomerID) ? 0 : this.healthFormData.kycFormData.CustomerID,
      "PfCustomerId": this.healthFormData.kycFormData.PFCustomerID,
      "PortabilityTypeOfPolicy": this.healthFormData.portabilityFormData.typeOfPolicy,
      "PortabilitySumInsured": this.healthFormData.portabilityFormData.portabilitySumInsured,
      "PreviousPolicies": this.healthFormData.portabilityFormData.previousPolicyClaims ? this.healthFormData.portabilityFormData.previousPolicyClaims : null,
      "PortabilityWaiver": this.healthFormData.portabilityFormData.typeOfPolicy,
      "PortabilityDOJ": this.healthFormData.portabilityFormData.policyStartDate,
      "PortabilitySI": this.healthFormData.portabilityFormData.portabilitySumInsured,
      "PreviousPoliciesClaims": this.healthFormData.portabilityFormData.previousPolicyClaims ? this.healthFormData.portabilityFormData.previousPolicyClaims : null,
      "PreviousPolicyPED": this.healthFormData.portabilityFormData.previousPolicyPED,
      "SpDetails": null,
      "SaveProposal": true,
      "Product24": "",
      "ProposalDate": "",
      "SubLimit": "",
      "isCombo": false,
      "ComboLanNo": "",
      "IsOtherLoadingDiscount": false,
      "OtherLoadingDiscountPercentage": "",
      "IsBefit": this.SummaryForm.controls['befitPlan'].value == 'true' ? true : false,
      "BefitPlan": this.SummaryForm.controls['befitPlan'].value == 'true' ? this.selectedCoverName : '',
      "IsSoftCopyDiscountApplicable": false,
      "PreHospitalization": this.healthFormData.quoteFormData.preHospitalDuration,
      "PostHospitalization": this.healthFormData.quoteFormData.postHospitalDuration,
      "PEDWaitingPeriod": this.healthFormData.quoteFormData.pedWaitingPeriod,
      "SpecificConditionWaiting": specificConditionWaiting,
      "ProposalAnnualIncome": "",
      "RevisedZone": this.healthFormData.quoteFormData.reviseZone,
      "Zone": this.healthFormData.quoteFormData.zone,
      "CPolicyNumber": "",
      "VoluntaryCopaymentPercentage": this.healthFormData.quoteFormData.copaymentPercentTaken == '0' ? "" : this.healthFormData.quoteFormData.copaymentPercentTaken,
      "ZoneUpgrade": this.healthFormData.quoteFormData.zoneUpgrade ? this.healthFormData.quoteFormData.zoneUpgrade : false,
      "ProposalAnnualIncomeRange": this.healthFormData.quoteFormData.applicantAnnualIncome,
      "Pincode": this.healthFormData.quoteFormData.pincode,
      "DigitalPOSUserMobileNo": "",
      "IsPruCombi": false,
      "IPruCombiCoRelation": "",
      "IPruCombiAppNo": "",
      "IsCHIRefile": false,
      "InstallmentApplicable": this.healthFormData.quoteFormData.InstallmentApplicable,
      "InstallmentFrequency": this.healthFormData.quoteFormData.InstallmentFrequency,
      "InstallmentTotalPremium": this.healthFormData.premiumFormData.InstallmentTotalPremium ? this.healthFormData.premiumFormData.InstallmentTotalPremium.toString() : null,
      "BancaToken": "",
      "ProductName": this.healthFormData.quoteFormData.productName,
      "TotalPremium": this.healthFormData.premiumFormData.totalPremium,
      "BasicPremium": this.healthFormData.premiumFormData.basicPremium,
      "TotalTax": this.healthFormData.premiumFormData.totalTax,
      "PlanCode": this.healthFormData.quoteFormData.PlanCode,
      "PlanName": this.healthFormData.quoteFormData.PlanName,
      "Tenure": parseInt((this.healthFormData.quoteFormData.policyTenure).split(' ')[0]),
      "hapAddonsFormData": this.healthFormData.quoteFormData.subProductType == 42 ? hapAddons : {},
      "hepAddonsFormData": this.healthFormData.quoteFormData.subProductType == 33 ? this.healthFormData.quoteFormData.addonsFormdata : {},
      "hbAddonsFormData": this.healthFormData.quoteFormData.subProductType == 20 ? this.healthFormData.quoteFormData.addonsHB : [],
      "gsAddonsFormData": this.healthFormData.quoteFormData.subProductType == 40 ? this.healthFormData.quoteFormData.addonList : [],
      "CustomerDetails": {
        "CustomerType": this.healthFormData.kycFormData.customerType,
        "CustomerName": this.healthFormData.kycFormData.applicantDetails.applicantFullName,
        "DateOfBirth": formattedDate,
        "PinCode": this.healthFormData.kycFormData.permanentAddress.permanentPincode,
        "PANCardNo": this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber == "" ? null : this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber,
        "Email": this.healthFormData.kycFormData.permanentAddress.permanentEmail,
        "MobileNumber": this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
        "AddressLine1": this.healthFormData.kycFormData.permanentAddress.permanentAddress1,
        "AddressLine2": this.healthFormData.kycFormData.permanentAddress.permanentAddress2,
        "CountryCode": 100,
        "StateCode": +this.healthFormData.quoteFormData.stateId,
        "CityCode": +this.healthFormData.quoteFormData.cityId,
        "Gender": this.utilitySerice.titleBasedGender(this.healthFormData.kycFormData.applicantDetails.applicantTitle, this.routeData),
        "MobileISD": "91",
        "State": this.healthFormData.kycFormData.permanentAddress.permanentState,
        "City": this.healthFormData.kycFormData.permanentAddress.permanentCity,
        "Landmark": this.healthFormData.kycFormData.permanentAddress.permanentLandmark,
        "MaritalStatus": this.healthFormData.kycFormData.permanentAddress.permanentMaritalStatus,
        "PermanentSameAddress": this.healthFormData.kycFormData.permanentAddress.permanentSameAddress ? 'true' : 'false',
        "CorrespondingAddress": this.healthFormData.kycFormData.correspondenceAddress ? {
          "CorrespondenceLandmark": this.healthFormData.kycFormData.correspondenceAddress.correspondenceLandmark,
          "CorrespondenceAddressLine1": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress1,
          "CorrespondenceAddressLine2": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress2,
          "CorrespondencePincode": this.healthFormData.kycFormData.correspondenceAddress.correspondencePincode,
          "CorrespondenceState": this.healthFormData.kycFormData.correspondenceAddress.correspondenceState,
          "CorrespondenceCity": this.healthFormData.kycFormData.correspondenceAddress.correspondenceCity,
        } : null,
        "GSTDetails": this.healthFormData.kycFormData.otherDetails.business != "" ? {
          "GSTRegistered": this.healthFormData.kycFormData.otherDetails.gstRegistered,
          "GSTExemptionApplicable": this.healthFormData.kycFormData.otherDetails.gstApplicable,
          "GSTInNumber": this.healthFormData.kycFormData.otherDetails.gstin,
          "GSTToState": this.healthFormData.kycFormData.KYCStateId,
          "ConstitutionOfBusiness": this.healthFormData.kycFormData.otherDetails.business,
          "CustomerType": this.healthFormData.kycFormData.otherDetails.otherCustomerType,
          "PanDetails": this.healthFormData.kycFormData.otherDetails.panNumber,
          "GSTRegistrationStatus": this.healthFormData.kycFormData.otherDetails.gstStatus,
          "insuredHIP": this.healthFormData.kycFormData.otherDetails['insuredHIP'] ? this.healthFormData.kycFormData.otherDetails['insuredHIP'] : "",
          "taxExemptionHB": this.healthFormData.kycFormData.otherDetails['taxExemptionHB'] ? this.healthFormData.kycFormData.otherDetails['taxExemptionHB'] : "",
          "UINNumber": this.healthFormData.kycFormData.otherDetails.uin,
        } : null,
        "AadharNumber": null,
        "IsCollectionofform60": false,
        "AadharEnrollmentNo": null,
        "eIA_Number": null,
        "CorelationId": this.healthFormData.quoteFormData.corelationId ? this.healthFormData.quoteFormData.corelationId : '',
        "CustomerID": "",
        "CKYCId": this.healthFormData.kycFormData.CKYCID,
        "EKYCid": this.healthFormData.kycFormData.EKYCID,
        "PEPFlag": this.healthFormData.kycFormData.pepFlag,
        "ILKYCReferenceNumber": this.healthFormData.kycFormData.ilKycReferenceNumber,
        "SkipDedupeLogic": null,
        "DateOfIncorporation": null,
        "SourceOfFunds": null,
        "OtherFunds": null,
        "CIN": null
      },
      "CritiShield": null,
      "FamilyShield": null,
      "GenericData": genericData,
      "VoluntaryDeductible": this.healthFormData.quoteFormData.voluntaryDeductible,
      "ProductTypeHB": this.healthFormData.quoteFormData.productTypeHB,
    };

    if (this.healthFormData.quoteFormData.productType == "Portability") {
      saveProposalRequest.PortabilityTypeOfPolicy = this.healthFormData.portabilityFormData.typeOfPolicy;
      saveProposalRequest.PortabilitySumInsured = this.healthFormData.portabilityFormData.typeOfPolicy == policyType.Floater ? this.healthFormData.portabilityFormData.portabilitySumInsured : this.SIinsured;
      saveProposalRequest.PortabilityWaiver = "0";
      saveProposalRequest.PortabilityDOJ = this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyStartDate);
      saveProposalRequest.PortabilitySI = "0";
      saveProposalRequest.PreviousPolicies = {
        InsurerCompany: this.healthFormData.portabilityFormData.insuranceCompanyShortName,
        PolicyNo: this.healthFormData.portabilityFormData.policyNumber,
        PolicyStartDate: this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyStartDate),
        PolicyEndDate: this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyEndDate),
        TotalPremium: this.healthFormData.portabilityFormData.premiumAmount,
        SumInsured: "0", // By default goes 0 in existing
        Waiver: 0, // By default goes 0
        ReasonOfPortability: this.healthFormData.portabilityFormData.portabilityReason,
      }
    }
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);

    return saveProposalRequest;

  }

  constructMaxProposalRequestV2(paymode) {
    const alteredDate = new Date(this.healthFormData.kycFormData.applicantDetails.applicantDOB)
    const formattedDate = this.datePipe.transform(alteredDate, 'yyyy-MM-ddTHH:mm:ss');
    let saveProposalRequest: SaveEditProposalV2RequestPayload = {
      "UserType": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "IpAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      "ProductType": this.maxProtectDataModal.maxProtectProductType,
      "SubProductType": this.maxProtectDataModal.subProductType.toString(),
      "SubProductCode": this.healthFormData.quoteFormData.subProductCode,
      "PayMode": paymode.toLowerCase(),
      "isIbankRelationship": false,
      "BankRelationship": {
        "IsIbankRelationship": false,
        "ApsId": "",
        "CustomerRefNo": ""
      },
      "Members": this.constructMemberDetails(),
      "NomineeTitleID": this.healthFormData.kycFormData.nomineeDetails.NomineeTitleID.toString(),
      "NomineeDOB": this.utilityService.formatDateWithSpace(this.healthFormData.kycFormData.nomineeDetails.nomineeDOB),
      "NomineeName": this.healthFormData.kycFormData.nomineeDetails.nomineeFullName,
      "NomineeRelationShipID": this.healthFormData.kycFormData.nomineeDetails.NomineeRelationshipID.toString(),
      "NomineeRelationShipText": this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship,
      "HUF": "N",
      "isAppointeeRequired": this.healthFormData.showAppointee,
      "AppointeeName": this.healthFormData.kycFormData.appointeeDetails.appointeeFullName ?? '',
      "AppointeeTitleID": this.healthFormData.kycFormData.appointeeDetails.appointeeTitleID ?? 0,
      "AppointeeRelationShipID": this.healthFormData.kycFormData.appointeeDetails.appointeeRelationShipID ?? 0,
      "AppointeeRelationShip": this.healthFormData.kycFormData.appointeeDetails.appointeeRelationship ?? '',
      "AppointeeDOB": this.healthFormData.kycFormData.appointeeDetails.appointeeDOB ? this.utilityService.formatDateWithSpace(this.utilityService.formatDateWithSpace(this.healthFormData.kycFormData.appointeeDetails.appointeeDOB)) : '',
      "CustomerID": this.healthFormData.kycFormData.CustomerID,
      "PfCustomerId": this.healthFormData.kycFormData.PFCustomerID,
      "PortabilityTypeOfPolicy": "",
      "PortabilitySumInsured": "",
      "PreviousPolicies": null,
      "PortabilityWaiver": "",
      "PortabilityDOJ": "",
      "PortabilitySI": "",
      "SpDetails": null,
      "SaveProposal": false,
      "Product24": "",
      "ProposalDate": "",
      "SubLimit": "",
      "isCombo": false,
      "ComboLanNo": "",
      "IsOtherLoadingDiscount": false,
      "OtherLoadingDiscountPercentage": "",
      "IsBefit": false,
      "BefitPlan": "",
      "IsSoftCopyDiscountApplicable": false,
      "PreHospitalization": this.healthFormData.quoteFormData.preHospitalDuration,
      "PostHospitalization": this.healthFormData.quoteFormData.postHospitalDuration,
      "PEDWaitingPeriod": this.healthFormData.quoteFormData.pedWaitingPeriod,
      "SpecificConditionWaiting": "",
      "ProposalAnnualIncome": this.healthFormData.quoteFormData.applicantAnnualIncome,
      "RevisedZone": this.healthFormData.quoteFormData.reviseZone,
      "Zone": this.healthFormData.quoteFormData.zone,
      "CPolicyNumber": "",
      "VoluntaryCopaymentPercentage": this.healthFormData.quoteFormData.copaymentPercentTaken == '0' ? "" : this.healthFormData.quoteFormData.copaymentPercentTaken,
      "ZoneUpgrade": this.healthFormData.quoteFormData.zoneUpgrade,
      "ProposalAnnualIncomeRange": "",
      "Pincode": this.healthFormData.quoteFormData.pincode,
      "DigitalPOSUserMobileNo": "",
      "IsPruCombi": false,
      "IPruCombiCoRelation": "",
      "IPruCombiAppNo": "",
      "IsCHIRefile": false,
      "InstallmentApplicable": this.healthFormData.quoteFormData.InstallmentApplicable,
      "InstallmentFrequency": this.healthFormData.quoteFormData.InstallmentFrequency,
      "BancaToken": this.channelData.CIP == 'NYSABANCA' ? this.channelData.BT : '',
      "ProductName": "Max_Protect",
      "TotalPremium": this.healthFormData.quoteFormData.TotalPremium,
      "BasicPremium": this.healthFormData.quoteFormData.BasicPremium,
      "TotalTax": this.healthFormData.quoteFormData.TotalTax,
      "PlanCode": this.healthFormData.quoteFormData.PlanCode,
      "PlanName": this.healthFormData.quoteFormData.PlanName,
      "Tenure": parseInt((this.healthFormData.quoteFormData.policyTenure).split(' ')[0]),
      "SaveQuoteID": this.quoteDetails ? this.quoteDetails.Data.GenericData.QuoteID : 0,
      "CustomerDetails": {
        "CustomerType": "Individual",
        "CustomerName": this.healthFormData.kycFormData.applicantDetails.applicantFullName,
        "DateOfBirth": formattedDate,
        "PinCode": this.healthFormData.kycFormData.permanentAddress.permanentPincode,
        "PANCardNo": this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber == "" ? null : this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber,
        "Email": this.healthFormData.kycFormData.permanentAddress.permanentEmail,
        "MobileNumber": this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
        "AddressLine1": this.healthFormData.kycFormData.permanentAddress.permanentAddress1,
        "AddressLine2": this.healthFormData.kycFormData.permanentAddress.permanentAddress2,
        "CountryCode": 100,
        "StateCode": parseInt(this.healthFormData.kycFormData.KYCStateId),
        "CityCode": parseInt(this.healthFormData.kycFormData.KYCCityId),
        "Gender": this.utilitySerice.titleBasedGender(this.healthFormData.kycFormData.applicantDetails.applicantTitle, this.routeData),
        "MobileISD": "91",
        "CorrespondingAddress": {
          "AddressLine1": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress1,
          "AddressLine2": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress2,
          "CityCode": parseInt(this.healthFormData.kycFormData.CoCityId),
          "CountryCode": 100,
          "StateCode": parseInt(this.healthFormData.kycFormData.CoStateId),
          "Pincode": parseInt(this.healthFormData.kycFormData.correspondenceAddress.correspondencePincode),
        },
        "GSTDetails": this.healthFormData.kycFormData.otherDetails.business != "" ? {
          "GSTExemptionApplicable": this.healthFormData.kycFormData.otherDetails.gstApplicable,
          "GSTInNumber": this.healthFormData.kycFormData.otherDetails.gstin,
          "GSTToState": this.healthFormData.kycFormData.KYCStateId,
          "ConstitutionOfBusiness": this.healthFormData.kycFormData.otherDetails.business,
          "CustomerType": this.healthFormData.kycFormData.otherDetails.otherCustomerType,
          "PanDetails": this.healthFormData.kycFormData.otherDetails.panNumber,
          "GSTRegistrationStatus": this.healthFormData.kycFormData.otherDetails.gstStatus,
        } : null,
        "AadharNumber": null,
        "IsCollectionofform60": false,
        "AadharEnrollmentNo": null,
        "eIA_Number": null,
        "CorelationId": this.healthFormData.quoteFormData.corelationId,
        "CustomerID": "",
        "CKYCId": this.healthFormData.kycFormData.CKYCID,
        "EKYCid": this.healthFormData.kycFormData.EKYCID,
        "PEPFlag": this.healthFormData.kycFormData.pepFlag,
        "ILKYCReferenceNumber": this.healthFormData.kycFormData.ilKycReferenceNumber,
        "SkipDedupeLogic": null,
        "DateOfIncorporation": null,
        "SourceOfFunds": null,
        "OtherFunds": null,
        "CIN": null
      }
    };

    if (this.healthFormData.quoteFormData.productType == "Portability") {
      let portabilityReason = this.healthFormData.portabilityFormData.portabilityReason == PortabilityReason.ClaimNotHandled ? PortabilityReason.lowerClaimNotHandled : this.healthFormData.portabilityFormData.portabilityReason;
      saveProposalRequest.PortabilityTypeOfPolicy = this.healthFormData.portabilityFormData.typeOfPolicy == policyType.Floater ? 'F' : 'I';
      saveProposalRequest.PortabilitySumInsured = this.healthFormData.portabilityFormData.typeOfPolicy == policyType.Floater ? this.healthFormData.portabilityFormData.portabilitySumInsured : this.SIinsured;
      saveProposalRequest.PortabilityWaiver = "0";
      saveProposalRequest.PortabilityDOJ = this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyStartDate);
      saveProposalRequest.PortabilitySI = "0";
      saveProposalRequest.PreviousPolicies = {
        InsurerCompany: this.healthFormData.portabilityFormData.insuranceCompanyShortName,
        PolicyNo: this.healthFormData.portabilityFormData.policyNumber,
        PolicyStartDate: this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyStartDate),
        PolicyEndDate: this.utilityService.formatDate(this.healthFormData.portabilityFormData.policyEndDate),
        TotalPremium: this.healthFormData.portabilityFormData.premiumAmount,
        SumInsured: "0", // By default goes 0 in existing
        Waiver: 0, // By default goes 0
        ReasonOfPortability: portabilityReason,
      }
    }
    this.healthDataStorageService.setHealthDataStorage(this.healthFormData);

    return saveProposalRequest;

  }


  constructProposalRequest(saveEditResponse: HealthDataStorage, payMode) {
    let request: SaveEditProposalV1RequestPayload = {
      UserType: this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      IpAddress: this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      AppointeeDOB: this.healthFormData.showAppointee ? this.utilitySerice.formatDateWithSpace(this.healthFormData.kycFormData.appointeeDetails.appointeeDOB) : '',
      AppointeeName: this.healthFormData.showAppointee ? this.healthFormData.kycFormData.appointeeDetails.appointeeFullName : '',
      AppointeeRelationShip: this.healthFormData.showAppointee ? this.healthFormData.kycFormData.appointeeDetails.appointeeRelationship : '',
      AppointeeRelationShipID: this.healthFormData.showAppointee ? this.healthFormData.kycFormData.appointeeDetails.appointeeRelationShipID : 0,
      AppointeeTitleID: this.healthFormData.showAppointee ? this.healthFormData.kycFormData.appointeeDetails.appointeeTitleID : 0,
      CustomerID: this.healthFormData.kycFormData.CustomerID,
      CKYCId: this.healthFormData.kycFormData.CKYCID,
      EKYCid: this.healthFormData.kycFormData.EKYCID,
      ILKYCReferenceNumber: this.healthFormData.kycFormData.ilKycReferenceNumber,
      HUF: "Y", //by default
      PayMode: payMode.toLowerCase(),
      InstallmentApplicable: this.healthFormData.quoteFormData.InstallmentApplicable,
      InstallmentFrequency: this.healthFormData.quoteFormData.InstallmentFrequency,
      isAppointeeRequired: this.healthFormData.showAppointee,
      IsSoftCopyDiscountApplicable: this.healthFormData.quoteFormData.subProductType == 40 ? false : true,
      Members: this.constructMemberDetails(),
      NomineeDOB: this.healthFormData.kycFormData.nomineeDetails.nomineeDOB != '' ? this.utilitySerice.formatDateWithSpace(this.healthFormData.kycFormData.nomineeDetails.nomineeDOB) : '',
      NomineeName: this.healthFormData.kycFormData.nomineeDetails.nomineeFullName != '' ? this.healthFormData.kycFormData.nomineeDetails.nomineeFullName : '',
      NomineeRelationShipText: this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship != '' ? this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship : '',
      NomineeRelationShipID: this.healthFormData.kycFormData.nomineeDetails.NomineeRelationshipID,
      PfCustomerId: this.healthFormData.kycFormData.PFCustomerID,
      PfQuoteId: this.healthFormData.premiumFormData.PfQuoteId,
      IsBefit: this.SummaryForm.controls['befitPlan'].value == 'true' ? true : false,
      BefitPlan: this.SummaryForm.controls['befitPlan'].value == 'true' ? this.selectedCoverName : '',
      Pincode: this.healthFormData.kycFormData.permanentAddress.permanentPincode,
      ProductType: this.healthFormData.quoteFormData.healthProposalProductType, // in pana we don't set this
      QuoteId: this.healthFormData.premiumFormData.QuoteId,
      SubProductCode: this.healthFormData.premiumFormData.SubProductCode,
      SubLimit: null,// By default no point
      SubProductType: this.healthFormData.quoteFormData.subProductType, // By default we are sending
      SaveProposal: false,
      PreHospitalization: this.healthFormData.quoteFormData.preHospitalDuration,
      PostHospitalization: this.healthFormData.quoteFormData.postHospitalDuration,
      PEDWaitingPeriod: this.healthFormData.quoteFormData.pedWaitingPeriod,
      SpecificConditionWaiting: this.healthFormData.quoteFormData.conditionWaitingPeriod,
      ProposalAnnualIncome: this.healthFormData.quoteFormData.applicantAnnualIncome,
      VoluntaryCopaymentPercentage: this.healthFormData.quoteFormData.copaymentPercentTaken == null ? "" : this.healthFormData.quoteFormData.copaymentPercentTaken.replace('%', ''),
      ProposalAnnualIncomeRange: this.healthFormData.quoteFormData.applicantAnnualIncome,
      ZoneUpgrade: this.healthFormData.quoteFormData.zoneUpgrade,
      RevisedZone: this.healthFormData.quoteFormData.reviseZone,
      Zone: this.healthFormData.quoteFormData.zone,
      IsOtherLoadingDiscount: this.healthFormData.quoteFormData.IsOtherLoadingDiscount,
      BancaToken: this.channelData.CIP == 'NYSABANCA' ? this.channelData.BT : '',
      SaveQuoteId: this.quoteDetails ? this.quoteDetails.Data.GenericData.QuoteID : 0
    }
    if (this.healthFormData.quoteFormData.subProductType == 33) {
      request.IsCHIRefile = true;
      request.RevisedZone = this.healthFormData.quoteFormData.reviseZone;
      request.Zone = this.healthFormData.quoteFormData.zone;
      request.IsBefit = false;
      request.BefitPlan = '';
      request.IsSoftCopyDiscountApplicable = false;
    }
    // If user has selected portability
    if (this.healthFormData.quoteFormData.productType == "Portability") {
      let portabilityReason = this.healthFormData.portabilityFormData.portabilityReason == PortabilityReason.ClaimNotHandled ? PortabilityReason.lowerClaimNotHandled : this.healthFormData.portabilityFormData.portabilityReason;
      request.PortabilityTypeOfPolicy = this.healthFormData.portabilityFormData.typeOfPolicy == "Floater" ? 'F' : 'I';
      request.PortabilitySumInsured = this.healthFormData.portabilityFormData.typeOfPolicy == "Floater" ? this.healthFormData.portabilityFormData.portabilitySumInsured : this.SIinsured;
      request.PreviousPolicies = {
        InsurerCompany: this.healthFormData.portabilityFormData.insuranceCompanyShortName,
        PolicyNo: this.healthFormData.portabilityFormData.policyNumber,
        PolicyStartDate: this.utilitySerice.formatDate(this.healthFormData.portabilityFormData.policyStartDate),
        PolicyEndDate: this.utilitySerice.formatDate(this.healthFormData.portabilityFormData.policyEndDate),
        TotalPremium: this.healthFormData.portabilityFormData.premiumAmount,
        SumInsured: "0", // By default goes 0 in existing
        Waiver: 0, // By default goes 0
        ReasonOfPortability: portabilityReason,
      }
    }
    // set product key for healthbooster
    if (this.healthFormData.quoteFormData.subProductType == 20) {
      request['Product24'] = this.healthFormData.kycFormData.otherDetails['taxExemptionHB'];
    }
    return request;
  }

  constructOrionHapProposalRequest(payMode) {
    const alteredDate = new Date(this.healthFormData.kycFormData.applicantDetails.applicantDOB)
    const formattedDate = this.datePipe.transform(alteredDate, 'yyyy-MM-ddTHH:mm:ss');
    let portabilityData = this.healthFormData.premiumFormData.PortabilitySubProductCode;
    let request: any = {
      SaveQuoteID: this.healthFormData.premiumFormData.QuoteId,
      UserType: this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      IpAddress: this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      AppointeeDOB: this.healthFormData.showAppointee ? this.utilitySerice.formatDateWithSpace(this.healthFormData.kycFormData.appointeeDetails.appointeeDOB) : '',
      AppointeeName: this.healthFormData.showAppointee ? this.healthFormData.kycFormData.appointeeDetails.appointeeFullName : '',
      AppointeeRelationShip: this.healthFormData.showAppointee ? this.healthFormData.kycFormData.appointeeDetails.appointeeRelationship : '',
      AppointeeRelationShipID: this.healthFormData.showAppointee && this.healthFormData.kycFormData.appointeeDetails.appointeeRelationShipID ? this.healthFormData.kycFormData.appointeeDetails.appointeeRelationShipID : 0,
      AppointeeTitleID: this.healthFormData.showAppointee ? this.healthFormData.kycFormData.appointeeDetails.appointeeTitleID : 0,
      HUF: "Y", //by default
      InstallmentApplicable: this.healthFormData.quoteFormData.InstallmentApplicable,
      InstallmentFrequency: this.healthFormData.quoteFormData.InstallmentFrequency,
      InstallmentNetPremium: this.healthFormData.quoteFormData.netPremium ? this.healthFormData.quoteFormData.netPremium : 0,
      InstallmentTaxAmount: this.healthFormData.quoteFormData.taxAmount ? this.healthFormData.quoteFormData.taxAmount : 0,
      InstallmentTotalPremium: this.healthFormData.quoteFormData.emiPremium ? this.healthFormData.quoteFormData.emiPremium : 0,
      isAppointeeRequired: this.healthFormData.showAppointee,
      IsSoftCopyDiscountApplicable: false,
      Members: this.constructOrionHapMemberDetails(),
      NomineeDOB: this.healthFormData.kycFormData.nomineeDetails.nomineeDOB != '' ? this.utilitySerice.formatDateWithSpace(this.healthFormData.kycFormData.nomineeDetails.nomineeDOB) : '',
      NomineeName: this.healthFormData.kycFormData.nomineeDetails.nomineeFullName != '' ? this.healthFormData.kycFormData.nomineeDetails.nomineeFullName : '',
      // NomineeTitleID: this.healthFormData.kycFormData.nomineeDetails.NomineeTitleID,
      NomineeTitleID: 0, //passing hardcoded NomineeTitleID cause its useless and will be removed later when backend iss refactoring code.
      NomineeRelationShipText: this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship != '' ? this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship : '',
      Pincode: this.healthFormData.kycFormData.permanentAddress.permanentPincode,
      ProductType: this.healthFormData.quoteFormData.healthProposalProductType, // in pana we don't set this
      // QuoteId: this.healthFormData.premiumFormData.QuoteId,
      SubProductCode: this.healthFormData.portabilityDataSet ? portabilityData : this.healthFormData.premiumFormData.SubProductCode,
      ProductName: "Apex_Plus_R", // By default
      SubLimit: null,// By default no point
      SubProductType: this.healthFormData.quoteFormData.subProductType,
      SaveProposal: false,
      ProposalDate: "", // by default
      PreHospitalization: this.healthFormData.quoteFormData.preHospitalDuration,
      PostHospitalization: this.healthFormData.quoteFormData.postHospitalDuration,
      PEDWaitingPeriod: this.healthFormData.quoteFormData.pedWaitingPeriod,
      SpecificConditionWaiting: this.healthFormData.quoteFormData.conditionWaitingPeriod,
      ProposalAnnualIncome: this.healthFormData.quoteFormData.applicantAnnualIncome,
      VoluntaryCopaymentPercentage: this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.copaymentPercentTaken) ? "" : this.healthFormData.quoteFormData.copaymentPercentTaken.replace('%', ''),
      ProposalAnnualIncomeRange: this.healthFormData.quoteFormData.applicantAnnualIncome,
      ZoneUpgrade: this.healthFormData.quoteFormData.zoneUpgrade,
      RevisedZone: this.healthFormData.quoteFormData.reviseZone,
      Zone: this.healthFormData.quoteFormData.zone,
      CibilScore: this.healthFormData.quoteFormData.creditScore ? this.healthFormData.quoteFormData.creditScore : '0',
      PlanCode: this.healthFormData.quoteFormData.PlanCode,
      PlanName: this.healthFormData.quoteFormData.PlanName,
      BasicPremium: this.healthFormData.premiumFormData.basicPremium,
      TotalTax: this.healthFormData.premiumFormData.totalTax,
      TotalPremium: this.healthFormData.premiumFormData.totalPremium,
      Tenure: parseInt((this.healthFormData.quoteFormData.policyTenure).split(' ')[0]),
      NoOfAdults: this.healthFormData.quoteFormData.adultDetails.length.toString(),
      NoOfKids: this.healthFormData.quoteFormData.childDetails.length.toString(),
      CustomerDetails: {
        "CustomerType": "Individual",
        "CustomerName": this.healthFormData.kycFormData.applicantDetails.applicantFullName,
        "DateOfBirth": formattedDate,
        "PANCardNo": this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber == "" ? null : this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber,
        "Email": this.healthFormData.kycFormData.permanentAddress.permanentEmail,
        "MobileNumber": this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
        "Country": "100",
        "Gender": this.healthFormData.kycFormData.applicantDetails.applicantTitle == 'Mr.' ? 'MALE' : 'FEMALE',
        "MobileISD": "91",
        "GSTDetails": this.healthFormData.kycFormData.otherDetails.business != "" ? {
          "GSTExemptionApplicable": this.healthFormData.kycFormData.otherDetails.gstApplicable,
          "GSTInNumber": this.healthFormData.kycFormData.otherDetails.gstin,
          "GSTToState": this.healthFormData.kycFormData.KYCStateId,
          "ConstitutionOfBusiness": this.healthFormData.kycFormData.otherDetails.business,
          "CustomerType": this.healthFormData.kycFormData.otherDetails.otherCustomerType,
          "PanDetails": this.healthFormData.kycFormData.otherDetails.panNumber,
          "GSTRegistrationStatus": this.healthFormData.kycFormData.otherDetails.gstStatus,
        } : null,
        "AadharNumber": null,
        "IsCollectionofform60": false,
        "AadharEnrollmentNo": null,
        "eIA_Number": null,
        "CorelationId": this.healthFormData.quoteFormData.corelationId,
        "CustomerID": this.utilityService.isEmptyOrNull(this.healthFormData.kycFormData.CustomerID?.toString()) ? '' : this.healthFormData.kycFormData.CustomerID.toString(),
        "CKYCId": this.healthFormData.kycFormData.CKYCID,
        "EKYCid": this.healthFormData.kycFormData.EKYCID,
        "PEPFlag": this.healthFormData.kycFormData.pepFlag,
        "ILKYCReferenceNumber": null,
        "IsMailingAddresssameasPermanent": this.healthFormData.kycFormData.permanentAddress.permanentSameAddress,
        "SkipDedupeLogic": null,
        "DateOfIncorporation": null,
        "SourceOfFunds": null,
        "OtherFunds": null,
        "CIN": null,
        "Address": this.getAddresses(),
        MaritalStatus: this.healthFormData.kycFormData.permanentAddress.permanentMaritalStatus,
      },
      BancsPartyCode: this.storageService.getAESDecryptedData(userEnums.OrionAgentDetails).BancsPartyCode,
      SumInsured: this.healthFormData.quoteFormData.applicantAnnualSum,
      "BancaToken": ""
    }
    // If user has selected portability
    if (this.healthFormData.quoteFormData.productType == "Portability") {
      request.PortabilityTypeOfPolicy = this.healthFormData.portabilityFormData.typeOfPolicy == "Floater" ? 'F' : 'I';
      request.PortabilitySumInsured = this.healthFormData.portabilityFormData.typeOfPolicy == "Floater" ? this.healthFormData.portabilityFormData.portabilitySumInsured : this.SIinsured;
      request.PreviousPolicies = {
        InsurerCompany: this.healthFormData.portabilityFormData.insuranceCompanyShortName,
        PolicyNo: this.healthFormData.portabilityFormData.policyNumber,
        PolicyStartDate: this.utilitySerice.formatDate(this.healthFormData.portabilityFormData.policyStartDate),
        PolicyEndDate: this.utilitySerice.formatDate(this.healthFormData.portabilityFormData.policyEndDate),
        TotalPremium: this.healthFormData.portabilityFormData.premiumAmount,
        SumInsured: "0", // By default goes 0 in existing
        Waiver: 0, // By default goes 0
        ReasonOfPortability: this.healthFormData.portabilityFormData.portabilityReason,
      }
    }
    return request;
  }

  constructHapElevateProposalRequest(payMode): SaveEditProposalV2HAPRequestPayload {
    const alteredDate = new Date(this.healthFormData.kycFormData.applicantDetails.applicantDOB)
    const formattedDate = this.datePipe.transform(alteredDate, 'yyyy-MM-ddTHH:mm:ss');
    let portabilityData = this.healthFormData.premiumFormData.PortabilitySubProductCode;
    let saveQuoteID: number;

    if (this.routeData === routeEnums.Elevate) {
      saveQuoteID = parseInt(this.healthFormData.quoteFormData.biuSaveQuoteID);
    } else if (this.quoteDetails) {
      saveQuoteID = this.quoteDetails.Data.GenericData.QuoteID;
    } else {
      saveQuoteID = this.healthFormData.premiumFormData.QuoteId;
    }

    let request: SaveEditProposalV2HAPRequestPayload = {
      SaveQuoteID: this.routeData == routeEnums.Elevate ? parseInt(this.healthFormData.quoteFormData.biuSaveQuoteID) : this.quoteDetails ? this.quoteDetails.Data.GenericData.QuoteID : this.healthFormData.premiumFormData.QuoteId,
      UserType: this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      IpAddress: this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      AppointeeDOB: this.healthFormData.showAppointee ? this.utilitySerice.formatDateWithSpace(this.healthFormData.kycFormData.appointeeDetails.appointeeDOB) : '',
      AppointeeName: this.healthFormData.showAppointee ? this.healthFormData.kycFormData.appointeeDetails.appointeeFullName : '',
      AppointeeRelationShip: this.healthFormData.showAppointee ? this.healthFormData.kycFormData.appointeeDetails.appointeeRelationship : '',
      AppointeeRelationShipID: this.healthFormData.showAppointee ? this.healthFormData.kycFormData.appointeeDetails.appointeeRelationShipID : 0,
      AppointeeTitleID: this.healthFormData.showAppointee ? this.healthFormData.kycFormData.appointeeDetails.appointeeTitleID : 0,
      HUF: "Y", //by default
      PayMode: payMode.toLowerCase(),
      InstallmentApplicable: this.healthFormData.quoteFormData.InstallmentApplicable,
      InstallmentFrequency: this.healthFormData.quoteFormData.InstallmentFrequency,
      InstallmentNetPremium: this.healthFormData.quoteFormData.netPremium,
      InstallmentTaxAmount: this.healthFormData.quoteFormData.taxAmount,
      InstallmentTotalPremium: this.healthFormData.quoteFormData.emiPremium,
      isAppointeeRequired: this.healthFormData.showAppointee,
      IsSoftCopyDiscountApplicable: false,
      Members: this.constructHapMemberDetails(),
      NomineeDOB: this.healthFormData.kycFormData.nomineeDetails.nomineeDOB != '' ? this.utilitySerice.formatDateWithSpace(this.healthFormData.kycFormData.nomineeDetails.nomineeDOB) : '',
      NomineeName: this.healthFormData.kycFormData.nomineeDetails.nomineeFullName != '' ? this.healthFormData.kycFormData.nomineeDetails.nomineeFullName : '',
      // NomineeTitleID: this.healthFormData.kycFormData.nomineeDetails.NomineeTitleID,
      NomineeRelationShipText: this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship != '' ? this.healthFormData.kycFormData.nomineeDetails.nomineeRelationship : '',
      NomineeRelationShipID: this.healthFormData.kycFormData.nomineeDetails.NomineeRelationshipID,
      Pincode: this.healthFormData.kycFormData.permanentAddress.permanentPincode,
      ProductType: this.healthFormData.quoteFormData.healthProposalProductType, // in pana we don't set this
      // QuoteId: this.healthFormData.premiumFormData.QuoteId,
      SubProductCode: this.healthFormData.portabilityDataSet == true ? portabilityData : this.healthFormData.premiumFormData.SubProductCode,
      ProductName: (this.routeData === routeEnums.SuperSaver || this.routeData === routeEnums.ActivateBooster) ? null : "Apex_Plus_R", // By default
      SubLimit: null,// By default no point
      SubProductType: this.healthFormData.quoteFormData.subProductType,
      SaveProposal: false,
      ProposalDate: "", // by default
      PreHospitalization: this.healthFormData.quoteFormData.preHospitalDuration,
      PostHospitalization: this.healthFormData.quoteFormData.postHospitalDuration,
      PEDWaitingPeriod: this.healthFormData.quoteFormData.pedWaitingPeriod,
      SpecificConditionWaiting: [45, 46, 47].includes(this.healthFormData.quoteFormData.subProductType) ? undefined : this.healthFormData.quoteFormData.conditionWaitingPeriod,
      ProposalAnnualIncome: this.healthFormData.quoteFormData.applicantAnnualIncome,
      VoluntaryCopaymentPercentage: this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.copaymentPercentTaken) ? "" : this.healthFormData.quoteFormData.copaymentPercentTaken.replace('%', ''),
      ProposalAnnualIncomeRange: this.healthFormData.quoteFormData.applicantAnnualIncome,
      ZoneUpgrade: this.healthFormData.quoteFormData.zoneUpgrade,
      RevisedZone: this.healthFormData.quoteFormData.reviseZone,
      Zone: this.healthFormData.quoteFormData.zone,
      CibilScorePercentage: this.healthFormData.quoteFormData.cibilScoreDiscount ? this.healthFormData.quoteFormData.cibilScoreDiscount : '0',
      CibilScore: this.routeData == routeEnums.Elevate || this.routeData === routeEnums.SuperSaver || this.routeData === routeEnums.ActivateBooster ? this.healthFormData.quoteFormData.creditScore : undefined,
      PlanCode: this.healthFormData.quoteFormData.PlanCode,
      PlanName: this.healthFormData.quoteFormData.PlanName,
      BasicPremium: this.healthFormData.premiumFormData.basicPremium,
      TotalTax: this.healthFormData.premiumFormData.totalTax,
      TotalPremium: this.quoteDetails ? this.quoteDetails.Data.TotalPremium : this.healthFormData.premiumFormData.totalPremium,
      Tenure: parseInt((this.healthFormData.quoteFormData.policyTenure).split(' ')[0]),
      CustomerDetails: {
        "CustomerType": "Individual",
        "CustomerName": this.healthFormData.kycFormData.applicantDetails.applicantFullName,
        "DateOfBirth": formattedDate,
        "PinCode": this.healthFormData.kycFormData.permanentAddress.permanentPincode,
        "PANCardNo": this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber == "" ? null : this.healthFormData.kycFormData.applicantDetails.applicantPanCardNumber,
        "Email": this.healthFormData.kycFormData.permanentAddress.permanentEmail,
        "MobileNumber": this.healthFormData.kycFormData.permanentAddress.permanentMobileNumber,
        "AddressLine1": this.healthFormData.kycFormData.permanentAddress.permanentAddress1,
        "AddressLine2": this.healthFormData.kycFormData.permanentAddress.permanentAddress2,
        "CountryCode": 100,
        "StateCode": parseInt(this.healthFormData.kycFormData.KYCStateId),
        "CityCode": parseInt(this.healthFormData.kycFormData.KYCCityId),
        "Gender": this.utilitySerice.titleBasedGender(this.healthFormData.kycFormData.applicantDetails.applicantTitle, this.routeData),
        "MobileISD": "91",
        "CorrespondingAddress": {
          "AddressLine1": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress1,
          "AddressLine2": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress2,
          "CityCode": parseInt(this.healthFormData.kycFormData.CoCityId),
          "CountryCode": 100,
          "StateCode": parseInt(this.healthFormData.kycFormData.CoStateId),
          "Pincode": parseInt(this.healthFormData.kycFormData.correspondenceAddress.correspondencePincode),
        },
        "GSTDetails": this.healthFormData.kycFormData.otherDetails.business != "" ? {
          "GSTExemptionApplicable": this.healthFormData.kycFormData.otherDetails.gstApplicable,
          "GSTInNumber": this.healthFormData.kycFormData.otherDetails.gstin,
          "GSTToState": this.healthFormData.kycFormData.KYCStateId,
          "ConstitutionOfBusiness": this.healthFormData.kycFormData.otherDetails.business,
          "CustomerType": this.healthFormData.kycFormData.otherDetails.otherCustomerType,
          "PanDetails": this.healthFormData.kycFormData.otherDetails.panNumber,
          "GSTRegistrationStatus": this.healthFormData.kycFormData.otherDetails.gstStatus,
        } : null,
        "AadharNumber": null,
        "IsCollectionofform60": false,
        "AadharEnrollmentNo": null,
        "eIA_Number": null,
        "CorelationId": this.healthFormData.quoteFormData.corelationId,
        "CustomerID": null,
        "CKYCId": this.healthFormData.kycFormData.CKYCID,
        "EKYCid": this.healthFormData.kycFormData.EKYCID,
        "PEPFlag": this.healthFormData.kycFormData.pepFlag,
        "ILKYCReferenceNumber": this.healthFormData.kycFormData.ilKycReferenceNumber,
        "SkipDedupeLogic": null,
        "DateOfIncorporation": null,
        "SourceOfFunds": null,
        "OtherFunds": null,
        "CIN": null,
      },
      BancaToken: this.channelData.CIP == 'NYSABANCA' ? this.channelData.BT : '',
    }
    // If user has selected portability
    if (this.healthFormData.quoteFormData.productType == "Portability") {
      let portabilityReason = this.healthFormData.portabilityFormData.portabilityReason == PortabilityReason.ClaimNotHandled ? PortabilityReason.lowerClaimNotHandled : this.healthFormData.portabilityFormData.portabilityReason;
      request.PortabilityTypeOfPolicy = this.healthFormData.portabilityFormData.typeOfPolicy == "Floater" ? 'F' : 'I';
      request.PortabilitySumInsured = this.healthFormData.portabilityFormData.typeOfPolicy == "Floater" ? this.healthFormData.portabilityFormData.portabilitySumInsured : this.SIinsured;
      request.PreviousPolicies = {
        InsurerCompany: this.healthFormData.portabilityFormData.insuranceCompanyShortName,
        PolicyNo: this.healthFormData.portabilityFormData.policyNumber,
        PolicyStartDate: this.utilitySerice.formatDate(this.healthFormData.portabilityFormData.policyStartDate),
        PolicyEndDate: this.utilitySerice.formatDate(this.healthFormData.portabilityFormData.policyEndDate),
        TotalPremium: this.healthFormData.portabilityFormData.premiumAmount,
        SumInsured: "0", // By default goes 0 in existing
        Waiver: 0, // By default goes 0
        ReasonOfPortability: portabilityReason,
      }
    }
    if (this.routeData == routeEnums.Elevate || this.routeData === routeEnums.SuperSaver || this.routeData === routeEnums.ActivateBooster) {
      const sumInsuredValue = this.healthFormData.quoteFormData.applicantAnnualSum;
      request.BiuPlanUniqueId = this.healthFormData.quoteFormData.BiuPlanUniqueId;
      request.SumInsured = (this.routeData === routeEnums.SuperSaver) ? sumInsuredValue.replace(/L/g, '') : parseInt(sumInsuredValue);
      request.PEDWaitingperiodReduction = this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.PEDWaitingperiodReduction) ? undefined : this.healthFormData.quoteFormData.PEDWaitingperiodReduction + ' Years';
      request.RoomModifier = this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.RoomModifier) ? undefined : this.healthFormData.quoteFormData.RoomModifier;
      request.JumpStart = this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.JumpStart) ? undefined : this.healthFormData.quoteFormData.JumpStart.toString();
      request.GuaranteedSuperBonus = this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.GuaranteedSuperBonus) ? undefined : this.healthFormData.quoteFormData.GuaranteedSuperBonus.toString();
      request.NRIDiscount = this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.isNRIApplicable) ? undefined : this.healthFormData.quoteFormData.isNRIApplicable == 'Yes' ? true : false;
      request.WorldwideWaitingperiodReduction = this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.WorldwideWaitingperiodReduction) ? undefined : this.healthFormData.quoteFormData.WorldwideWaitingperiodReduction + ' Years';
      request.MaternityWaitingPeriodReduction = this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.MaternityWaitingPeriodReduction) ? undefined : this.healthFormData.quoteFormData.MaternityWaitingPeriodReduction + ' Years';
      request.NetworkAdvantageApplicable = this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.NetworkAdvantageApplicable) ? undefined : this.healthFormData.quoteFormData.NetworkAdvantageApplicable
      request.InfiniteCare = this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.InfiniteCover) ? undefined : this.healthFormData.quoteFormData.InfiniteCover
    } else {
      request.ProductType = 'HADV';
      request.ProductName = 'Apex_Plus_R';
    }

    if (this.routeData === routeEnums.SuperSaver) {
      request.PolicySubType = this.healthFormData.quoteFormData.subProductType;
      request.PlanType = this.healthFormData.quoteFormData.activateBoosterPlans ? this.healthFormData.quoteFormData.activateBoosterPlans.split(' ')[1] : 'A';
      request.ElevateBoosterPlanName = this.healthFormData.quoteFormData.topUpPlanName;
      request.ElevateBoosterPlanCode = this.healthFormData.quoteFormData.topUpPlanCode;
      request.PremiumElevate = {
        "BasicPremium": this.healthFormData.premiumFormData.comboBasicPremium1,
        "TotalTax": this.healthFormData.premiumFormData.comboTotalTax1,
        "TotalPremium": this.healthFormData.premiumFormData.comboTotalPremium1
      };
      request.PremiumCombo = {
        "BasicPremium": this.healthFormData.premiumFormData.comboBasicPremium2,
        "TotalTax": this.healthFormData.premiumFormData.comboTotalTax2,
        "TotalPremium": this.healthFormData.premiumFormData.comboTotalPremium2
      }
    }

    if (this.routeData === routeEnums.ActivateBooster) {
      request.PolicySubType = this.healthFormData.quoteFormData.subProductType;
      request.PlanType = this.healthFormData.quoteFormData.activateBoosterPlans ? this.healthFormData.quoteFormData.activateBoosterPlans.split(' ')[1] : 'A';
      request.PlanName = this.healthFormData.quoteFormData.topUpPlanName;
      request.PlanCode = this.healthFormData.quoteFormData.topUpPlanCode;
      request.VoluntaryDeductibleSI = parseInt(this.healthFormData.quoteFormData.voluntaryDeductible);
    }

    return request;
  }

  constructOrionHapMemberDetails(): any[] {
    let membersData = [];
    let adultCount = 0;
    let childCount = 0;

    if (this.healthFormData.quoteFormData.totalMemberData.length > 0) {
      this.healthFormData.quoteFormData.totalMemberData.forEach((members, index) => {
        let memberType: string = members['MemberType'];
        let insuredDetailsObject = this.healthFormData.insuredFormData.insuredMemberDetails[index];
        let memberTypeCount: string = '';
        let selectedAddons = members['AddonCoverDetails'];
        if (memberType == healthMembers.Adult) {
          adultCount++;
          memberTypeCount = '';
          memberTypeCount = memberType + adultCount;
        }

        else if (memberType == healthMembers.Child) {
          adultCount = 0;
          childCount++;
          memberTypeCount = '';
          memberTypeCount = memberType + childCount;
        }

        let feetHeight = (insuredDetailsObject['feetInchesHeight' + memberTypeCount]).split('.')[0];
        let inchesHeight = (insuredDetailsObject['feetInchesHeight' + memberTypeCount]).split('.')[1];

        let propDOJ = "";
        if ([40, 42, 33, 45, 46, 47].includes(this.healthFormData.quoteFormData.subProductType)) {
          propDOJ = insuredDetailsObject['previouslyInsured' + memberTypeCount] == 'Yes' ? this.utilityService.formatDate(insuredDetailsObject['insuredSince' + memberTypeCount]) : this.utilityService.formatDate(new Date());
        }

        let portabilitySI;
        if (this.healthFormData.portabilityFormData.typeOfPolicy == policyType.Individual) {
          if (insuredDetailsObject['sumInsured' + memberTypeCount] == '') {
            portabilitySI = '1';
          } else {
            portabilitySI = insuredDetailsObject['sumInsured' + memberTypeCount];
          }
        } else {
          portabilitySI = '1';
        }

        membersData.push({
          "AddOnCoverDetails": selectedAddons,
          "AddOnName": "", //by default we are sending it
          "AddOnName6": "", //by default we are sending it
          "Ailments": this.getAilments(memberTypeCount),
          "DOB": members['DOB'],
          "Height": `${(feetHeight) + '.' + (inchesHeight)}`,
          "InsuranceCompany": "", // By default
          "Occupation": members['Occupation'],
          "isExisting": this.healthFormData.portabilityFormData.previousPolicyClaims == 'Yes' ? 'true' : 'false', // check with existing
          "MemberType": memberType,
          "Name": insuredDetailsObject['fullName' + memberTypeCount],
          "VaccinationDate": memberType == healthMembers.Adult ? members['VaccinationDate'] : undefined,
          "IsPneumococcalVaccination": memberType == healthMembers.Adult && !this.utilityService.isEmptyOrNull(members['VaccinationDate']) ? true : false,
          "OtherDisease": "", // Not needed
          "TypeofPolicy": "",
          "PolicyDuration": "",
          "RelationshipID": memberType == healthMembers.Adult ? (this.healthFormData.quoteFormData.adultRelationShips.filter(data => data.RelationshipName == members['RelationshipwithApplicant'])[0].RelationshipID) : (this.healthFormData.quoteFormData.childRelationShips.filter(data => data.RelationshipName == members['RelationshipwithApplicant'])[0].RelationshipID),// call api service
          "RelationshipName": members['RelationshipwithApplicant'],
          "SumInsured": this.healthFormData.quoteFormData.applicantAnnualSum,
          "TitleID": this.hapDataModal.title.filter(data => data.label == (insuredDetailsObject['title' + memberTypeCount]))[0].value,
          "Weight": insuredDetailsObject['weight' + memberTypeCount],
          'PortabilitySI': portabilitySI,
          "PortabilityDOJ": propDOJ,
          "PortabilityWaiver": insuredDetailsObject['previouslyInsured' + memberTypeCount] == 'Yes' ? insuredDetailsObject['waiverPeriod' + memberTypeCount] : '0',
          "MemberInceptionDate": this.utilityService.isEmptyOrNull(insuredDetailsObject['insuredSince' + memberTypeCount]) ? null : this.utilityService.formatDateWithSpace(insuredDetailsObject['insuredSince' + memberTypeCount])
        })
        this.SIinsured = (insuredDetailsObject['sumInsured' + memberTypeCount]);
      })
    }
    return membersData;
  }

  constructHapMemberDetails(): InsuranceMember[] {
    let membersData = [];
    let adultCount = 0;
    let childCount = 0;

    if (this.healthFormData.quoteFormData.totalMemberData.length > 0) {
      this.healthFormData.quoteFormData.totalMemberData.forEach((members, index) => {
        let memberType: string = members['MemberType'];
        let insuredDetailsObject = this.healthFormData.insuredFormData.insuredMemberDetails[index];
        let memberTypeCount: string = '';
        let selectedAddons = members['AddonCoverDetails'] || this.healthFormData.quoteFormData.addons[index].AddonCoverDetails;
        if (memberType == healthMembers.Adult) {
          adultCount++;
          memberTypeCount = '';
          memberTypeCount = memberType + adultCount;
        }

        else if (memberType == healthMembers.Child) {
          adultCount = 0;
          childCount++;
          memberTypeCount = '';
          memberTypeCount = memberType + childCount;
        }

        let feetHeight = (insuredDetailsObject['feetInchesHeight' + memberTypeCount]).split('.')[0];
        let inchesHeight = (insuredDetailsObject['feetInchesHeight' + memberTypeCount]).split('.')[1];

        let propDOJ = "";
        if ([40, 42, 33, 45, 46, 47].includes(this.healthFormData.quoteFormData.subProductType)) {
          propDOJ = insuredDetailsObject['previouslyInsured' + memberTypeCount] == 'Yes' ? this.utilityService.formatDate(insuredDetailsObject['insuredSince' + memberTypeCount]) : this.utilityService.formatDate(new Date());
        }
        let portabilitySI;
        if (this.quoteDetails) {
          this.healthFormData.portabilityFormData.typeOfPolicy = this.quoteDetails.Data.PortabilityTypeOfPolicy;
          portabilitySI = this.quoteDetails.Data.Members[index].PortabilitySI;
        } else {
          if (this.healthFormData.portabilityFormData.typeOfPolicy == policyType.Individual) {
            if (insuredDetailsObject['sumInsured' + memberTypeCount] == '') {
              portabilitySI = '1';
            } else {
              portabilitySI = insuredDetailsObject['sumInsured' + memberTypeCount];
            }
          } else {
            portabilitySI = '1';
          }
        }
        const sumInsuredValue = this.healthFormData.quoteFormData.applicantAnnualSum;
        membersData.push({
          "AddOnCoverDetails": selectedAddons ? selectedAddons : null,
          "AddOnName": "", //by default we are sending it
          "AddOnName6": "", //by default we are sending it
          "Ailments": this.getAilments(memberTypeCount),
          "DOB": members['DOB'],
          "Height": `${(feetHeight) + '.' + (inchesHeight)}`,
          "InsuranceCompany": "", // By default
          "Occupation": members['Occupation'] ? members['Occupation'] : "",
          "isExisting": this.healthFormData.portabilityFormData.previousPolicyClaims == 'Yes' ? 'true' : 'false', // check with existing
          "MemberType": memberType,
          "Name": insuredDetailsObject['fullName' + memberTypeCount],
          "VaccinationDate": memberType == healthMembers.Adult ? members['VaccinationDate'] : undefined,
          "OtherDisease": "", // Not needed
          "TypeofPolicy": "",
          "PolicyDuration": "",
          "RelationshipID": memberType == healthMembers.Adult ? (this.healthFormData.quoteFormData.adultRelationShips.filter(data => data.RelationshipName == members['RelationshipwithApplicant'])[0].RelationshipID) : (this.healthFormData.quoteFormData.childRelationShips.filter(data => data.RelationshipName == members['RelationshipwithApplicant'])[0].RelationshipID),// call api service
          "RelationshipName": members['RelationshipwithApplicant'],
          "SumInsured": (this.routeData === routeEnums.SuperSaver) ? sumInsuredValue.replace(/L/g, '') : sumInsuredValue,
          "TitleID": this.hapDataModal.title.filter(data => data.label == (insuredDetailsObject['title' + memberTypeCount]))[0].value,
          "Weight": insuredDetailsObject['weight' + memberTypeCount],
          'PortabilitySI': portabilitySI,
          "PortabilityDOJ": propDOJ,
          "PortabilityWaiver": insuredDetailsObject['previouslyInsured' + memberTypeCount] == 'Yes' ? insuredDetailsObject['waiverPeriod' + memberTypeCount] : '0',
          "previouslyInsured": this.isSaveQuote ? insuredDetailsObject['previouslyInsured' + memberTypeCount] : undefined
        });
        this.SIinsured = (insuredDetailsObject['sumInsured' + memberTypeCount]);
      })
    }
    return membersData;
  }

  constructMemberDetails(): Member[] {
    let membersData = [];
    let adultCount = 0;
    let childCount = 0;

    if (this.healthFormData.quoteFormData.totalMemberData.length > 0) {
      let selectedAddons = this.healthFormData.quoteFormData.totalMemberData[0]['AddonCoverDetails'];
      this.healthFormData.quoteFormData.totalMemberData.forEach((members, index) => {
        let occupation = '';
        let memberType: string = members['MemberType'];
        let insuredDetailsObject = this.healthFormData.insuredFormData.insuredMemberDetails[index];
        let memberTypeCount: string = '';
        if (memberType == healthMembers.Adult) {
          adultCount++;
          memberTypeCount = '';
          memberTypeCount = memberType + adultCount;
          // If personal accident is selected then add occupation details
          if (this.healthFormData.quoteFormData.addons['AddOn8']) {
            occupation = adultCount == 1 ? this.healthFormData.quoteFormData.occupationForAdult1 : this.healthFormData.quoteFormData.occupationForAdult2;
          }
        }

        else if (memberType == healthMembers.Child) {
          adultCount = 0;
          childCount++;
          memberTypeCount = '';
          memberTypeCount = memberType + childCount;
        }
        let feetHeight = (insuredDetailsObject['feetInchesHeight' + memberTypeCount]).split('.')[0];
        let inchesHeight = (insuredDetailsObject['feetInchesHeight' + memberTypeCount]).split('.')[1];
        // Below condition applicable for hap,gs and hep
        let propDOJ = "";
        if (this.healthFormData.quoteFormData.subProductType == 40 || this.healthFormData.quoteFormData.subProductType == 42 || this.healthFormData.quoteFormData.subProductType == 33) {
          propDOJ = insuredDetailsObject['previouslyInsured' + memberTypeCount] == 'Yes' ? this.utilityService.formatDate(insuredDetailsObject['insuredSince' + memberTypeCount]) : this.utilityService.formatDate(new Date());
        }
        let portabilitySI;
        if (this.healthFormData.portabilityFormData.typeOfPolicy == policyType.Individual) {
          if (insuredDetailsObject['sumInsured' + memberTypeCount] == '') {
            portabilitySI = '1';
          } else {
            portabilitySI = insuredDetailsObject['sumInsured' + memberTypeCount];
          }
        } else {
          portabilitySI = '1';
        }
        membersData.push({
          "AddOnCoverDetails": selectedAddons ? selectedAddons : null,
          "AddOnName": "", //by default we are sending it
          "AddOnName6": "", //by default we are sending it
          "Ailments": this.quoteDetails ? this.quoteDetails.Data.Members[index].Ailments : this.getAilments(memberTypeCount),
          "DOB": members['DOB'],
          "Height": `${(feetHeight) + '.' + (inchesHeight)}`,
          "InsuranceCompany": "", // By default
          "Occupation": memberType == healthMembers.Adult && this.healthFormData.quoteFormData.addons['AddOn8'] ? occupation : undefined,// By default
          "isExisting": this.healthFormData.portabilityFormData.previousPolicyClaims == 'Yes' ? 'true' : 'false', // check with existing
          "MemberType": memberType,
          "Name": insuredDetailsObject['fullName' + memberTypeCount],
          "VaccinationDate": memberType == healthMembers.Adult ? members['VaccinationDate'] : undefined,
          "OtherDisease": "", // Not needed
          "TypeofPolicy": "",
          "PolicyDuration": "",
          "RelationshipID": memberType == healthMembers.Adult ? (this.healthFormData.quoteFormData.adultRelationShips.filter(data => data.RelationshipName == members['RelationshipwithApplicant'])[0].RelationshipID) : (this.healthFormData.quoteFormData.childRelationShips.filter(data => data.RelationshipName == members['RelationshipwithApplicant'])[0].RelationshipID),// call api service
          "RelationshipName": members['RelationshipwithApplicant'],
          "SumInsured": this.healthFormData.quoteFormData.subProductType == 34 ? this.healthFormData.quoteFormData.SumInsured.toString() : this.healthFormData.quoteFormData.SumInsured,
          "TitleID": this.hapDataModal.title.filter(data => data.label == (insuredDetailsObject['title' + memberTypeCount]))[0].value,
          "Weight": insuredDetailsObject['weight' + memberTypeCount],
          'PortabilitySI': portabilitySI,
          "PortabilityDOJ": propDOJ,
          "PortabilityWaiver": insuredDetailsObject['previouslyInsured' + memberTypeCount] == 'Yes' ? insuredDetailsObject['waiverPeriod' + memberTypeCount] : '0',
          "previouslyInsured": this.isSaveQuote ? insuredDetailsObject['previouslyInsured' + memberTypeCount] : undefined
        });
        // additional fiedls for health booster
        if (this.healthFormData.quoteFormData.subProductType == 20) {
          membersData[index]["ExistingHealthPolicy"] = {};
          membersData[index]["ExistingHealthPolicy"]['IsExisting'] = this.healthFormData.insuredFormData.insuredMemberDetails[index][`existingHIP${memberTypeCount}`] == "Yes" ? "true" : "false";
          membersData[index]["ExistingHealthPolicy"]['InsuranceCompany'] = this.healthFormData.insuredFormData.insuredMemberDetails[index][`insuranceCompanyNameHB${memberTypeCount}`];
          membersData[index]["ExistingHealthPolicy"]['PolicyDuration'] = this.healthFormData.insuredFormData.insuredMemberDetails[index][`policyPeriodHB${memberTypeCount}`] ? Number(this.healthFormData.insuredFormData.insuredMemberDetails[index][`policyPeriodHB${memberTypeCount}`]) : 0;
          membersData[index]["ExistingHealthPolicy"]['SumInsured'] = this.healthFormData.insuredFormData.insuredMemberDetails[index][`sumInsuredHB${memberTypeCount}`] ? Number(this.healthFormData.insuredFormData.insuredMemberDetails[index][`sumInsuredHB${memberTypeCount}`]) : 0;
          membersData[index]["ExistingHealthPolicy"]['TypeOfPolicy'] = this.healthFormData.insuredFormData.insuredMemberDetails[index][`typeOfPolicyHB${memberTypeCount}`];
          membersData[index]["ExistingHealthPolicy"]['Member'] = memberTypeCount
        }
        if (this.quoteDetails) {
          membersData['previouslyInsured'] = insuredDetailsObject['previouslyInsured' + memberTypeCount];
        }
        this.SIinsured = (insuredDetailsObject['sumInsured' + memberTypeCount]);
      })
    }

    return membersData;
  }



  getAilments(memberCount: string) {
    let ailmentList: HealthMemberAilments[] = [];
    if (this.healthFormData.insuredFormData.preExistingDisease.length > 0 && this.healthFormData.insuredFormData.existingDisease.length > 0) {
      this.healthFormData.insuredFormData.existingDisease.forEach(disease => {
        this.healthFormData.insuredFormData.preExistingDisease.forEach(preExistingControls => {
          if (preExistingControls[disease.Value + memberCount]) {
            ailmentList.push({
              "AilmentID": 0,  // by default
              "Month": "1", // by default
              "Year": "1", // by default
              "AilmentName": disease.Name,
              "PEDCode": (disease.Value).toString(),
              "PEDRemarks": (memberCount.includes(healthMembers.Adult) && disease.Value == insuredDiseaseID.Fourty || disease.Value == insuredDiseaseID.FourtyOne) ? this.findRemark(preExistingControls, memberCount, disease.Value) : ''
            });
          }
        })
      })
    }
    return ailmentList;
  }

  findRemark(preExistingControls: {}, memberCount: string, id: number): string {
    let remarks = '';
    if (id == insuredDiseaseID.Fourty || id == insuredDiseaseID.FourtyOne) {
      for (let keys in preExistingControls) {
        if (keys == `${remarkList.Remark1 + memberCount}` || keys == `${remarkList.Remark2 + memberCount}`) {
          remarks = (id == insuredDiseaseID.Fourty) ? preExistingControls[remarkList.Remark1 + memberCount] : preExistingControls[remarkList.Remark2 + memberCount]
          return remarks;
        }
      }
    }
    return remarks;
  }

  showBefitHomePlan() {
    let subProductCode = this.healthFormData.quoteFormData.subProductType;
    //hap condition
    if (subProductCode == 42 && !this.quoteDetails) {
      this.SummaryForm.controls['homePlan'].setValue('false');
    }
    //HB and ASP condition
    else if (subProductCode == 22 || subProductCode == 20) {
      this.SummaryForm.controls['homePlan'].patchValue('false');
      this.genericHomeShow = false;
      if (this.healthFormData.quoteFormData.isBefitApplicable == 'false') {
        this.genericBefitShow = false;
      }
      if (this.healthFormData.quoteFormData.adultDetails.length == 0 && this.healthFormData.quoteFormData.childDetails.length == 1) {
        this.genericBefitShow = false;
        this.genericHomeShow = false;
      }
    }
    else {
      this.genericBefitShow = false;
      this.genericHomeShow = false;
    }
  }

  checkHMPCondition() {
    if (this.healthFormData.quoteFormData.subProductType == 20 || this.healthFormData.quoteFormData.subProductType == 22) {
      let item = [];
      this.SummaryForm.controls['hmpPlan'].disable()
      this.healthFormData.insuredFormData.befitCovers.forEach((x: any) => {
        if (x.IsHMP) {
          item.push(x);
        }
      })
      this.bifitCovers = item;
    }
  }

  isPedSelected() {
    let memberData = this.healthFormData.insuredFormData;
    let countPed = 0;
    memberData.preExistingDisease.forEach(element => {
      if (!this.utilitySerice.isEmptyOrNull(element)) {
        countPed += element ? 1 : countPed;
      }
    });
    return countPed > 0 ? true : false;
  }

  isUnderwritterApproval() {
    let uwSI = this.utilitySerice.isEmptyOrNull(this.healthFormData.quoteFormData.applicantAnnualSum);
    if (uwSI) {
      return ((this.isBMIValid) && !this.isPedSelected());
    } else if ((parseInt(this.healthFormData.quoteFormData.applicantAnnualSum) > 2000000)) {
      return (!this.isPedSelected());
    }
    else {
      return ((this.isBMIValid) && !this.isPedSelected());
    }
  }

  selectAddon(item: any) {
    this.finalBefitPlanSelected = item;
  }

  befitProposalSelection(item: any) {
    if (!item) {
      this.befitproposalDisable = true;
    } else {
      this.befitproposalDisable = false;
    }
  }

  homeCoverSelected(item: any) {
    this.totalpremiumWithbefitCover = 0;
    this.selectedHomePlanDetails = item;
    if (this.quoteDetails) {
      this.totalpremiumWithbefitCover = parseInt(this.quoteDetails.Data.TotalPremium) + Number(this.selectedHomePlanDetails.Premium);
    } else {
      this.totalpremiumWithbefitCover = parseInt(this.healthFormData.premiumFormData.totalPremium) + Number(this.selectedHomePlanDetails.Premium);
    }
    this.befitHomeDetails.homeInsuranceSelection = true;
    this.befitHomeDetails.homeInsuranceData = item;
    this.healthDataStorageService.setBefithomeStorage(this.befitHomeDetails);
    this.homeSumInsured = item.Covers.Sum_Insured;
    let sum = 0;
    let coverName = 'Home Content Cover';
    item.Covers.forEach(element => {
      if (element.Cover_Name == coverName) {
        sum = parseInt(element.Sum_Insured)
      }
    });
    this.homeSumInsured = sum;
    this.updatePremiumData(this.totalpremiumWithbefitCover.toString());
  }

  homeInsuranceSelection(item: string) {
    if (item == 'true') {
      this.SummaryForm.controls['homePlan'].setValue('true');
      this.homeInsuranceDisable = true;
      // call Master data API
      this.requestDataFromMultipleSources();
    } else {
      this.SummaryForm.controls['homePlan'].setValue('false');
      this.homeInsuranceDisable = false;
      this.updatePremiumData(this.healthFormData.premiumFormData.totalPremium);
    }
  }

  requestDataFromMultipleSources() {
    this.subscription.push(this.agentV1Service.agentAppMasterData().subscribe({
      next: (agentAppResponse) => {
        if (agentAppResponse.StatusCode == Constants.statusCode_success) {
          let mappedHomeDealId: string = agentAppResponse.Data.MappedProduct.Home.HomeDealId ? agentAppResponse.Data.MappedProduct.Home.HomeDealId : '';
          // If we get mapped Deal Id we need to call home master data
          let homeRequestPayload: HomeMasterDataRequestPayload = {
            EncryptedDealId: mappedHomeDealId
          }
          this.homeService.getHomeMasterData(homeRequestPayload).subscribe({
            next: (homeMasterResponse) => {
              if (homeMasterResponse.StatusCode == Constants.statusCode_success) {
                this.homeMasterData = homeMasterResponse.Data;
                this.homeCoverDetails = this.homeMasterData.PlanDetails.length > 0 ? this.homeMasterData.PlanDetails : [];
                if (!this.quoteDetails) {
                  this.totalpremiumWithbefitCover = parseInt(this.healthFormData.premiumFormData.totalPremium) + Number(this.homeCoverDetails[2].Premium);
                  this.homeCoverSelected(this.homeCoverDetails[2]);
                  this.checkData = [false, false, true];
                  this.updatePremiumData(this.totalpremiumWithbefitCover.toString());
                }
                this.befitHomeDetails.homeCoverDetails = this.homeCoverDetails ? this.homeCoverDetails : this.befitHomeDetails.homeCoverDetails;
                this.befitHomeDetails.homeCoverDetails.filter(cover => {
                  if (cover.Plan_Name == this.befitHomeDetails.homeInsuranceData.Plan_Name) {
                    this.checkData.push(true);
                  } else {
                    this.checkData.push(false);
                  }
                });
                this.befitHomeDetails.homeInsuranceSelectedCover = this.checkData;
                this.healthDataStorageService.setBefithomeStorage(this.befitHomeDetails);
              }
              else if (homeMasterResponse.StatusCode != Constants.statusCode_success) {
                this.errorPopupData = this.popupService.fetchErrorPopupMsg(homeMasterResponse.StatusDesc);
                this.popupService.openGenericPopup(this.errorPopupData);
              }
            },
            error: err => {
            }
          })
        }
        else if (agentAppResponse.StatusCode != Constants.statusCode_success) {
          this.errorPopupData = this.popupService.fetchErrorPopupMsg(agentAppResponse.StatusDesc);
          this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: err => {
      }
    }))
  }

  navigateToPage(value: string) {
    this.customStepperService.redirectToStep(value, this.healthFormData.portabilityDataSet);
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  //to fecth medical test guideline as per the product
  MedicaltestGuideLines() {
    let subProductType;
    if (this.quoteDetails && this.quoteDetails.Data) {
      subProductType = this.quoteDetails.Data.SubProductType;
    } else {
      subProductType = this.healthFormData.quoteFormData.subProductType;
    }
    let data = MedicalTestGuidelinesData.MedicalGuidelines;
    data.forEach(element => {
      if (element.subProductType == subProductType) {
        this.medicaltest = element.Data;
      }
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  openDeclarationPopup(declarationType: string) {
    let data = {
      rnAgent: this.rnAgent
    }

    this.declarationService.openDeclarationPopup(declarationType, data);
  }

  getAddresses() {
    let addressArr = [];
    addressArr.push({
      "ContactType": "Permanent",
      "AddressLine1": this.healthFormData.kycFormData.permanentAddress.permanentAddress1,
      "AddressLine2": this.healthFormData.kycFormData.permanentAddress.permanentAddress2,
      "AddressLine3": this.healthFormData.kycFormData.permanentAddress.permanentLandmark,
      "PinCode": this.healthFormData.kycFormData.permanentAddress.permanentPincode,
      "State": this.healthFormData.kycFormData.permanentAddress.permanentState,
      "City": this.healthFormData.kycFormData.permanentAddress.permanentCity
    })
    if (!this.healthFormData.kycFormData.permanentAddress.permanentSameAddress) {
      addressArr.push({
        "ContactType": "Mailing/Communication",
        "AddressLine1": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress1,
        "AddressLine2": this.healthFormData.kycFormData.correspondenceAddress.correspondenceAddress2,
        "AddressLine3": this.healthFormData.kycFormData.correspondenceAddress.correspondenceLandmark,
        "PinCode": this.healthFormData.kycFormData.correspondenceAddress.correspondencePincode,
        "State": this.healthFormData.kycFormData.correspondenceAddress.correspondenceState,
        "City": this.healthFormData.kycFormData.correspondenceAddress.correspondenceCity
      })
    }
    return addressArr;
  }
}