<form [formGroup]="quickquoteForm">
    <div class="container-fluid">
        <div class="row">
            <div class="container panaMaincontainer">
                <div class="mainDiv">
                    <div class="steppermainDiv marginbox">
                        <div class="myTransactionTitle">
                            <div class="bbrheading">
                                <span><img src="../../../../../../assets/images/quickquote/Group 172303.svg"
                                        alt=""></span>
                                <span>
                                    <h3>Quick Quote</h3>
                                </span>
                            </div>
                        </div>
                        <mat-card class="innerbox borderinnerbox">
                            <div class="fieldsdiv">
                                <div class="row">

                                    <div class="col-sm-3 form-group order-3 order-sm-3">
                                        <mat-label class="input-group__label" for="myInput">PIN code</mat-label>
                                        <input matInput class="input-group__input" placeholder="Enter pincode"
                                            type="text" formControlName="pincode" id="pincode" [minlength]="6"
                                            [maxlength]="6" autocomplete="off" (input)="fetchDataAsPerPincode($event)">

                                        <mat-error *ngIf="hasError('pincode', 'required') && formSubmitted">
                                            Pincode is required
                                        </mat-error>

                                        <mat-error
                                            *ngIf="(hasError('pincode','maxlength') || hasError('pincode','minlength')) && formSubmitted">
                                            Atleast 6 digit pincode is required
                                        </mat-error>

                                        <mat-error
                                            *ngIf="!hasError('pincode','minlength') && hasError('pincode','pattern')  && formSubmitted">
                                            Please enter only numbers
                                        </mat-error>

                                        <mat-error *ngIf="invalidPinCodeMessage!== ''">
                                            {{invalidPinCodeMessage}}
                                        </mat-error>
                                    </div>


                                    <div class="col-xl-3 col-md-4 col-sm-3 form-group order-4 order-sm-4 stateCititeswidth"
                                        *ngIf="this.stateFetched">
                                        <mat-label class="input-group__label" for="myInput">State & Cities</mat-label>
                                        <!-- If length is 1 -->
                                        <input type="text" matInput class="input-group__input" placeholder="Enter state"
                                            type="text" formControlName="cityState" readonly
                                            *ngIf="cityData.length == 1" autocomplete="off">

                                        <mat-select formControlName="cityState" id="cityState" required
                                            class="input-group__input  cstm_select" placeholder="Select state and city"
                                            (selectionChange)="changeCityName($event.value,true)"
                                            *ngIf="cityData.length > 1">
                                            <ng-container *ngFor="let item of cityData; let last = last">
                                                <mat-option value={{item.value}}>
                                                    {{item.stateLabel}} - {{item.cityLabel}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error *ngIf="hasError('cityState', 'required') && formSubmitted">
                                            Please select atleast one option
                                        </mat-error>
                                    </div>

                                </div>
                            </div>
                        </mat-card>
                        <!-- new html start here -->

                        <!-- Member Detail Card Starts -->
                        <mat-card class="innerbox reverinnerbox pt-2">
                            <h3>Member details</h3>
                            <div class="fieldsdiv">
                                <div class="row ">
                                    <div class="col-sm-12 form-group">
                                        <label for="" class="singlelabel" style="width: 100%;">Select Adult</label>
                                        <div class="memberbtnrefilling mb-3">
                                            <button class="refilingbtn">
                                                <div class="refilimg">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="bgfill"
                                                            width="16.644" height="17.265" viewBox="0 0 16.644 17.265">
                                                            <path id="Path_46560" data-name="Path 46560"
                                                                d="M18.95,6.862a4.641,4.641,0,0,1,1.128.127c.071.021.134.035.19.056a1.292,1.292,0,0,0,.36.853,3.921,3.921,0,0,1,.86,1.319,4.546,4.546,0,0,1,.324,1.693,4.486,4.486,0,0,1-.331,1.707,4.059,4.059,0,0,1-.875,1.326h0l-.021.021a1.239,1.239,0,0,0-.212.3,2.416,2.416,0,0,0-.226,1.671,2.6,2.6,0,0,0,1.911,1.855h.007A9.553,9.553,0,0,1,24.43,18.93c.705.487.959.9.959,1.114v1.481a.078.078,0,0,1-.078.078H11.34a.078.078,0,0,1-.078-.078V20.044c0-.226.289-.677,1.1-1.213a10.129,10.129,0,0,1,2.483-1.121.855.855,0,0,0,.1-.035,2.582,2.582,0,0,0,1.679-1.763,2.123,2.123,0,0,0-.437-1.883,1.085,1.085,0,0,0-.085-.092,4.077,4.077,0,0,1-.882-1.326,4.522,4.522,0,0,1-.331-1.714A4.315,4.315,0,0,1,15.9,8.075,3.259,3.259,0,0,1,18.209,6.9a.466.466,0,0,0,.1-.007,6.325,6.325,0,0,1,.642-.028m0-1.262a5.757,5.757,0,0,0-.8.049A5.041,5.041,0,0,0,13.625,10.9a5.5,5.5,0,0,0,1.594,3.935h0c.374.416.3,1.269-.719,1.664-1.53.444-4.5,1.777-4.5,3.54v1.481a1.339,1.339,0,0,0,1.34,1.34H25.3a1.339,1.339,0,0,0,1.34-1.34V20.044c0-1.657-2.6-2.927-4.182-3.449h0c-1.65-.543-.973-1.749-.959-1.763v-.007a5.522,5.522,0,0,0,1.58-3.928A5.517,5.517,0,0,0,21.531,7a.524.524,0,0,0,.12-.317c0-.6-1.206-1.086-2.7-1.086Z"
                                                                transform="translate(-10 -5.6)" fill="#1e1f20" />
                                                        </svg>
                                                    </span>
                                                    <strong>Adult</strong>
                                                </div>
                                                <div class="Numberbtn">
                                                    <span (click)="removeAdult()"><img
                                                            src="../../../../assets/icons/minus.svg" alt=""></span>
                                                    <strong>{{adultCount}}</strong>
                                                    <span (click)="addAdult()"><img
                                                            src="../../../../assets/icons/plus.svg" alt=""></span>
                                                </div>
                                            </button>
                                            <button class="refilingbtn">
                                                <div class="refilimg">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="bgfill"
                                                            width="13.346" height="19.396" viewBox="0 0 13.346 19.396">
                                                            <path id="Path_46271" data-name="Path 46271"
                                                                d="M53.787,24.173a2.859,2.859,0,0,1-1.156-.236,3.284,3.284,0,0,1-.981-.647,5.24,5.24,0,0,1-.761-.882,6.014,6.014,0,0,1-.563-1.019.459.459,0,0,1-.3.137.645.645,0,0,1-.327-.1,1.081,1.081,0,0,1-.312-.251,1.157,1.157,0,0,1-.228-.35l-.54-1.255a.642.642,0,0,1-.061-.259.414.414,0,0,1,.137-.32.545.545,0,0,1,.38-.122h.571q-.194-.822-.3-1.506a10.253,10.253,0,0,1-.1-1.514,3.584,3.584,0,0,1,.746-2.145,4.072,4.072,0,0,1,1.887-1.423l.091-.023s.076-.023.221-.061a1.862,1.862,0,0,0,.2-.053,4.708,4.708,0,0,0,.525-.19,4.241,4.241,0,0,0,.51-.274,1.289,1.289,0,0,0,.418-.388.852.852,0,0,0,.152-.479A1.3,1.3,0,0,0,53.635,10a2.25,2.25,0,0,1,.494.114,1.849,1.849,0,0,1,.388.175,2.193,2.193,0,0,1,.3.228,2.743,2.743,0,0,1,.251.266c.076.091.152.19.228.281a3,3,0,0,0,.259.3c.1.1.2.2.3.289a2.54,2.54,0,0,0,.4.274,2.952,2.952,0,0,0,.517.236,3.874,3.874,0,0,1,1.56.761,4.129,4.129,0,0,1,1.111,1.354,3.462,3.462,0,0,1,.4,1.59,11.408,11.408,0,0,1-.1,1.514c-.061.456-.167.959-.3,1.506h.6a.537.537,0,0,1,.373.122.414.414,0,0,1,.137.32.642.642,0,0,1-.061.259l-.54,1.255a1.257,1.257,0,0,1-.38.494.807.807,0,0,1-.487.205.482.482,0,0,1-.327-.152,5.246,5.246,0,0,1-.426.822,4.788,4.788,0,0,1-.571.753,4.529,4.529,0,0,1-.7.631,3.166,3.166,0,0,1-.829.426,2.857,2.857,0,0,1-.943.16l-1.5-.008Zm-4.534,3.781a.736.736,0,0,1-.533-.221A.718.718,0,0,1,48.5,27.2v-.753a1.969,1.969,0,0,1,.1-.654,2.37,2.37,0,0,1,.221-.5,1.236,1.236,0,0,1,.388-.38,4.82,4.82,0,0,1,.464-.274,2.781,2.781,0,0,1,.578-.2c.251-.068.456-.114.609-.145s.38-.068.669-.122a5.217,5.217,0,0,0,.6.517,1.32,1.32,0,0,0,.441.2,3.21,3.21,0,0,0,.624.046H55.91a2.193,2.193,0,0,0,.852-.122,3.283,3.283,0,0,0,.806-.639c.3.053.517.091.669.122s.358.076.609.145a2.781,2.781,0,0,1,.578.2,4.82,4.82,0,0,1,.464.274,1.324,1.324,0,0,1,.388.38,2.3,2.3,0,0,1,.32,1.156V27.2a.736.736,0,0,1-.221.533.707.707,0,0,1-.533.221H49.253Z"
                                                                transform="translate(-47.875 -9.183)" fill="none"
                                                                stroke="#1e1f20" stroke-width="1.25" />
                                                        </svg>
                                                    </span>
                                                    <strong>Child</strong>
                                                </div>
                                                <div class="Numberbtn">
                                                    <span (click)="removeChild()"><img
                                                            src="../../../../assets/icons/minus.svg" alt=""></span>
                                                    <strong>{{childCount}}</strong>
                                                    <span (click)="addChild()"><img
                                                            src="../../../../assets/icons/plus.svg" alt=""></span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <mat-divider *ngIf="adultDetails.length > 0"></mat-divider>
                            <h3 *ngIf="adultDetails.length > 0">Adults</h3>
                            <div class="fieldsdiv ">
                                <div class="row" formArrayName="AdultDetails">
                                    <ng-container *ngFor="let data of adultDetails.controls; let i = index;"
                                        [formGroupName]="i">

                                        <div class="col-sm-12 form-group mb-0">
                                            <span class="subheading">Adult {{i+1}}</span>
                                        </div>
                                        <div class="col-sm-3 form-group">
                                            <mat-label class="input-group__label">DOB</mat-label>
                                            <input class="input-group__input dob" matInput [matDatepicker]="adultDOB"
                                                [formControlName]="'dobAdult' + (i+1)" id="adultDetails"
                                                [max]="getInsuredMinMaxDate('Adult','max')"
                                                [min]="getInsuredMinMaxDate('Adult','min')" (click)="adultDOB.open()"
                                                placeholder="DD/MM/YYYY" (dateInput)="adultsDateComparison($event)"
                                                readonly>
                                            <mat-datepicker-toggle matSuffix [for]="adultDOB">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #adultDOB touchUi>
                                            </mat-datepicker>
                                            <mat-error class="error-margin"
                                                *ngIf="adultDetails.value[i]['dobAdult' + (i+1)] == '' && formSubmitted">
                                                Adult {{i+1}} DOB is required
                                            </mat-error>
                                        </div>
                                    </ng-container>
                                </div>
                                <!-- ends here -->

                            </div>
                            <mat-divider *ngIf="childDetails.length > 0"></mat-divider>
                            <div *ngIf="childDetails.length > 0">
                                <h3>Child</h3>
                                <div class="fieldsdiv">
                                    <div class="row" formArrayName="ChildDetails">
                                        <ng-container *ngFor="let data of childDetails.controls; let i = index;"
                                            [formGroupName]="i">
                                            <div class="col-sm-12 form-group mb-0">
                                                <span class="subheading">Child {{i+1}}</span>
                                            </div>
                                            <div class="col-sm-3 form-group">
                                                <mat-label class="input-group__label">DOB</mat-label>
                                                <input class="input-group__input dob" matInput
                                                    [matDatepicker]="childDOB" [formControlName]="'dobChild' + (i+1)"
                                                    id="childDetails" (click)="childDOB.open()" placeholder="DD/MM/YYYY"
                                                    [max]="getInsuredMinMaxDate('Child','max')"
                                                    [min]="getInsuredMinMaxDate('Child','min')"
                                                    (dateInput)="childsDateComparison($event)" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="childDOB">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #childDOB touchUi>
                                                </mat-datepicker>
                                                <mat-error class="error-margin"
                                                    *ngIf="childDetails.value[i]['dobChild' + (i+1)] == '' && formSubmitted">
                                                    Child {{i+1}} DOB is required
                                                </mat-error>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <mat-divider></mat-divider>

                            <!-- Premium Details Seection Starts -->
                            <h3>Premium Details</h3>
                            <div class="fieldsdiv">
                                <div class="row ">
                                    <!-- Policy Tenure -->
                                    <div class="col-sm-6 col-md-6 col-lg-3 policyTenureWidth form-group">
                                        <!-- Policy Tenure -->
                                        <label for="" class="singlelabel mb-2" style="width:100%">Policy Tenure</label>
                                        <div class="group_btn"><br />
                                            <mat-button-toggle-group formControlName="policyTenure" name="fontStyle"
                                                aria-label="Font Style" class="policyToggle_group">
                                                <mat-button-toggle class="PolicyBtn"
                                                    (change)="changePolicyTenure($event)"
                                                    *ngFor="let tenureData of hapDataModal.tenurePeriod; let j = index"
                                                    [value]="tenureData">
                                                    {{ tenureData }}
                                                </mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </div>
                                    </div>

                                    <!-- Annual Sum Insured -->
                                    <div class="col-sm-3 form-group appIncome">
                                        <label class="input-group__label" style="top: 13px;">Sum Insured</label>
                                        <mat-select class="input-group__input cstm_select"
                                            placeholder="Select applicant sum insured"
                                            formControlName="applicantAnnualSum" id="applicantAnnualSum"
                                            (selectionChange)="onDropdownChange($event)" required>
                                            <ng-container *ngFor="let data of sumInsuedDetails; let last = last">
                                                <mat-option value="{{data}}">
                                                    {{utilityService.easyAmountReader(data)}}
                                                </mat-option>
                                                <mat-divider *ngIf="!last"></mat-divider>
                                            </ng-container>
                                        </mat-select>
                                        <mat-error class="error-margin"
                                            *ngIf="quickquoteForm.controls['applicantAnnualSum']?.errors?.['required'] && formSubmitted">
                                            Sum Insured is required
                                        </mat-error>
                                    </div>
                                </div>
                            </div>

                            <mat-divider></mat-divider>

                            <!-- Product Details Seection Starts -->
                            <div *ngIf="showChooseProduct">
                                <h3>Choose Products</h3>
                                <div class="cardback">
                                    <div class="row mx-auto mainDivProduct">
                                        <div class="firstDiv">
                                            <ng-container *ngFor="let i of productDetails; let ind = index">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 premiumCardDesign"
                                                    *ngIf="ind < productDetails.length/2">
                                                    <mat-card class="card">
                                                        <img class="imgScss" src="{{i.imagePath}}" alt="">
                                                        <span class="data">{{i.productName}}</span>
                                                        <mat-divider></mat-divider>
                                                        <div class="premiumContent">
                                                            <div class="basePreiumContent col p-0">
                                                                <span class="cardcontentBasePremium">Base Premium</span>
                                                                <span
                                                                    class="cardcontentAmount">₹{{utilityService.convertStringtoCurrency(i.premium)}}</span>
                                                            </div>

                                                            <div class="basePreiumContent col-4 p-0">
                                                                <div class="proceedToQuoteActionButton"
                                                                    (click)="proceedToQuoteActionButton(i.productCode)">
                                                                </div>                                                            
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngFor="let i of productDetails; let ind = index">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 premiumCardDesign"
                                                    *ngIf="ind >= productDetails.length/2">
                                                    <mat-card class="card">
                                                        <img class="imgScss" src="{{i.imagePath}}" alt="">
                                                        <span class="data">{{i.productName}}</span>
                                                        <mat-divider></mat-divider>
                                                        <div class="premiumContent">
                                                            <div class="basePreiumContent col p-0">
                                                                <span class="cardcontentBasePremium">Base Premium</span>
                                                                <span
                                                                    class="cardcontentAmount">₹{{utilityService.convertStringtoCurrency(i.premium)}}</span>
                                                            </div>

                                                            <div class="basePreiumContent col-4 p-0">
                                                                <div class="proceedToQuoteActionButton"
                                                                    (click)="proceedToQuoteActionButton(i.productCode)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                        <!-- Member Detail Card Ends -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- new html start here -->
</form>

<app-custom-footer *ngIf="!showChooseProduct" [normalButton]="true"
    [buttonData]="[{'label': 'Generate Premium', 'type': 'primary', 'source': 'quote', 'id': 'f1','disabled': false , 'hide': false}]"
    (buttonClicked)="getPremium($event)">
</app-custom-footer>