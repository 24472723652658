<section class="container-fluid">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="floatdiv floatingedit" *ngIf="scrolling">
                <img class="imgScss" src="../../../../../../assets/images/BBR/ScrollToTop.svg" alt="Scroll to Top" (click)="scrollToTop()">
            </div>

            <div class="marginbox">
                <div class="myTransactionTitle" *ngIf="!isSearchVisible">
                    <div class="bbrheading">
                        <span><img src="../../../../../assets/images/Endorsed_policy.svg" alt=""></span>
                        <span>
                            <h3>Endorsed Policy</h3>
                        </span>
                    </div>
                    <div class="d-md-none headerImgSection">
                        <!-- <span (click)="openFilterBottomSheet()">
                <img class="headerImg FilterMobile" src="{{filterImage}}" alt="">
              </span> -->
                        <span (click)="openSearchBottomSheet()">
                            <img class="headerImg" src="{{editImage}}" alt="" />
                        </span>
                        <span (click)="searchFilter()">
                            <img class="headerImg" src="../../../../../../assets/images/BBR/Search.svg" alt="" /></span>
                    </div>
                </div>
                <div class="mobilesearch__fieldBBr" *ngIf="isSearchVisible">
                    <div class="mobileinputDivbbr">
                        <input matInput type="text" class="input-group__input placeholder"
                            [cdkTrapFocusAutoCapture]="isSearchVisible" [cdkTrapFocus]="isSearchVisible"
                            [placeholder]="searchPlaceHolder" (input)="queryData()" [(ngModel)]="inputValue">
                        <span class="crossclick" (click)="closeClear()">
                            <img src="../../../../../../assets/images/BBR/Close.svg" alt="">
                        </span>
                    </div>
                </div>

                <!-- Desktop View (above 767px) start -->
                <div class="d-none d-md-block">
                    <!-- Input fields card start -->
                    <mat-card class="innerbox borderinnerbox">
                        <div class="row fieldsdiv" [formGroup]="nilEndorsementForm">

                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label" for="myInput">Product Type</mat-label>
                                <input matInput type="text" class="input-group__input" placeholder="Product Type"
                                    autocomplete="off" formControlName="productType" id="productType" readonly>
                                <!-- <mat-select class="input-group__input cstm_select" id="productType" placeholder="Product Type"
                    formControlName="productType" (click)="handleMatSelectClick()"
                    (blur)="removeMatSelectScrollListener()" (selectionChange)="productListListner($event.value)">
                    <ng-container *ngFor="let item of productList; let i = index; let last = last">
                      <mat-option [value]="item.productCode">
                        {{item.productName}}
                      </mat-option>
                      <mat-divider *ngIf="!last"></mat-divider>
                    </ng-container>
                  </mat-select> -->
                            </div>

                            <div class="col-sm-3 form-group" *ngIf="selectList.length > 0">
                                <mat-label class="input-group__label" for="myInput">Product Name</mat-label>
                                <mat-select class="input-group__input cstm_select" id="productName"
                                    placeholder="Product Name" formControlName="productName"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                    <ng-container *ngFor="let p of selectList;let i = index; let last = last ">
                                        <mat-option [value]="p.productCode">
                                            {{p.productName}}
                                        </mat-option>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                </mat-select>
                            </div>

                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label">Select Date</mat-label>
                                <mat-date-range-input class="input-group__input dob" [rangePicker]="picker"
                                    (click)="picker.open()" [min]="minMax.minDate" [max]="minMax.maxDate">
                                    <input matStartDate formControlName="startDate" placeholder="Start date" readonly>
                                    <input matEndDate formControlName="endDate" placeholder="End date" readonly>
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker touchUi></mat-date-range-picker>
                            </div>

                            <div class="col-sm-3 form-group">
                                <button class=" search-button" (click)="searchDetails()">
                                    <span>Submit</span>
                                </button>
                            </div>

                        </div>
                    </mat-card>
                    <!-- Input fields card end -->

                </div>
                <!-- Desktop View (above 767px) end -->

                <!-- Info card start -->
                <mat-card class="innerbox borderinnerbox align-items-center p-3 infoCardColor d-none d-md-flex">
                    <div class="row fieldsdiv">
                        <table>
                            <caption class="hidden-caption">Details</caption>
                            <td class="hidden-caption">Details</td>
                            <th class="hidden-caption">Details</th>
                            <tr>
                                <td class="vertAlign">
                                    <img src="../../../../../../assets/images/BBR/Info.svg" class="infoIcon" alt="Info">
                                </td>
                                <td>
                                    We found a total of <span class="font-weight-bold">{{policyCount ? policyCount : "0"
                                        }}</span>
                                    records with your search parameters on your Endorsed Policy report between
                                    <span class="font-weight-bold">{{myPolicyStartDate | date: 'dd MMM, yyyy'}}</span>
                                    and
                                    <span class="font-weight-bold">{{myPolicyEndDate | date: 'dd MMM, yyyy'}}.</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-card>
                <!-- Info card end -->

                <mat-card class="innerbox borderinnerbox p-2 infoCardColor d-md-none">
                    <table class="filterDatatable">
                        <caption class="hidden-caption">Details</caption>
                        <td class="hidden-caption">Details</td>
                        <th class="hidden-caption">Details</th>
                        <tr>
                            <td [width]="30"><img src="../../../../../../assets/images/BBR/calendarRange.svg" alt="">
                            </td>
                            <th class="filterlabel">Date Range</th>
                            <td [width]="30">
                                <img src="../../../../../../assets/images/BBR/record.svg" alt="">
                            </td>
                            <th class="filterlabel">Records</th>
                            <td class="countLabel">{{policyCount ? policyCount : "0" }}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="countLabel">
                                {{myPolicyStartDate |date:'dd MMM,yyyy'}} -
                                {{myPolicyEndDate | date: 'dd MMM,yyyy'}}
                            </td>
                        </tr>
                    </table>
                </mat-card>

                <!-- Need to fix -->
                <mat-card class="innerbox borderinnerbox">
                    <div class="fieldsdiv">
                        <div class="productmainDiv">
                            <div class="productdiv">
                                <div class="align-items-center">
                                    <span>Product: <span class="font-weight-bold">{{myPolicyProduct}}</span></span>
                                </div>
                            </div>
                            <div class="filterdiv">
                                <div class="d-none d-md-block">
                                    <div class="search__fieldBBr">
                                        <div class="inputDivbbr" [ngClass]="{'searchBorder': showCancel}">
                                            <input matInput type="text" class=" placeholder"
                                                [placeholder]="searchPlaceHolder" (input)="queryData()"
                                                [(ngModel)]="inputValue" (focus)="inputFocus()" (blur)="inputBlur()">
                                            <span class="crossclick pr-2" (click)="clearInputField()">
                                                <img src="../../../../../../assets/images/BBR/desktopCross.svg" alt="">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- filter functionality -->
                                <!-- <div>
                    <div class="dropdown d-none d-md-flex ">
                      <button matTooltip="Filter" class="dropdown-toggle customDropdown" type="button"
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="toggIcon">
                          <img src="../../../../../../assets/images/BBR/Filter.svg" alt="">
                        </div>
                      </button>
                      <ul class="dropdown-menu customDropItems " aria-labelledby="dropdownMenuButton">
                        <li class="dropdownHeading">
                          <h4>Status Type</h4>
                        </li>
                        <li class="dropdown-item cs_dropdown-item" *ngFor="let value of policyFilterList">
                          <mat-checkbox [checked]="policyFilter.includes(value.toLowerCase())"
                            (change)="applyPolicyFilter($event.checked, value)">
                            {{ value }}
                          </mat-checkbox>
                        </li>
                      </ul>
  
                    </div>
                  </div> -->

                                <!-- ends here -->
                                <div class=" d-flex align-items-center">
                                    <mat-button-toggle-group class="custom-toggle-group" (change)="onToggle($event)"
                                        [value]="toggleValue">
                                        <mat-button-toggle matTooltip="Table View" class="custom_togglebtn"
                                            value="table"><img src="../../../../../../assets/images/BBR/tablelist.svg"
                                                alt=""></mat-button-toggle>
                                        <mat-button-toggle matTooltip="Card View" class="custom_togglebtn"
                                            value="card"><img src="../../../../../../assets/images/BBR/cardList.svg"
                                                alt=""></mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-card>

                <!-- Table format start -->
                <app-custom-table *ngIf="toggleValue == 'table'" [reportList]="filteredData" [buttonData]="tableBtnInfo"
                    [displayedColumns]="displayCol" (policyNo)="receiveDataAndCall($event)"
                    [tableSize]="'100%'"></app-custom-table>
                <!-- Table format end -->

                <!-- Card format start -->
                <app-custom-card *ngIf="toggleValue == 'card'" [reportList]="filteredData" [buttonData]="cardBtnInfo"
                    [displayedColumns]="displayCol" (policyNo)="receiveDataAndCall($event)"></app-custom-card>
                <!-- Card format end -->

            </div>
        </div>
    </div>
</section>