import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RenewalDataStorage} from '../renewals/renewal-storage.modal';
import { Subscription, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { StorageService } from 'src/app/shared/services/storage.service';
import { NavigationEnd, Router } from '@angular/router';
import { RenewalDataStorageService } from '../renewals/renewal.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { HAPModal } from 'src/app/modules/health/products/hap/hap-modal';
@Component({
  selector: 'app-instant-renewal',
  templateUrl: './instant-renewal.component.html',
  styleUrls: ['./instant-renewal.component.scss']
})
export class InstantRenewalComponent {
  instantRenewalForm: FormGroup;
  renewalDataStorage: RenewalDataStorage;
  zeroTatDataStorage: any;
  private subscription: Subscription[] = [];
  formSubmitted: boolean = false;
  errorPopupData: PopupModal;
  minDate: any;
  endDate: Date;
  bothDate: Date;
  previousUrl: string = '';
  currentUrl: string = '';
  hapDataModal: HAPModal = new HAPModal();
  dateSubscription: Subscription;
  homePolicies = ['4013', '4118', '4119', '4120', '4121', '4040', '1001', '1015', '1024'];
  labelArray = ['Customer DOB/Policy End Date', 'Customer DOB', 'Policy End Date'];
  labelString: string = '';

  constructor(private fb: FormBuilder, 
    private storageService: StorageService, private popupService: PopupService, private renewal_service_storage: RenewalDataStorageService,
    private utilityService: UtilityService,
    private router:Router) {
      
    this.previousUrl = userEnums.PreviousUrl ? this.storageService.getAESDecryptedData(userEnums.PreviousUrl) : null;
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
          this.storageService.setAESEncryptedData(userEnums.PreviousUrl, this.currentUrl);
          // Update the previous URL
        }
      }
    );
  }

  ngOnInit() {
    this.labelString = this.labelArray[0];
    let channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    if (!channelData || channelData && channelData.VT !== 'Mobile') {
      this.browserForwardButtonDisabled();
    }
    this.renewal_service_storage.resetRenewalDataStorage();
    this.renewalDataStorage = this.renewal_service_storage.getRenewalDataStorage();
    this.minDate = this.utilityService.getMinMaxDate(new Date().getFullYear()).maxDate;
    this.endDate = new Date();
    this.endDate.setDate(new Date().getDate() + 90);
    this.bothDate = this.minDate
    this.createForm();

    this.dateSubscription = this.instantRenewalForm.get('policyNumber')?.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(value => this.checkStartsWith(value))
    ).subscribe(result => {
      this.bothDate = result;
    });
  }

  checkStartsWith(value: string): Date {
    if (value) {
      const homeDate = this.homePolicies.some(date => value.startsWith(date));
      if (homeDate) {
        this.labelString = this.labelArray[2];
        return this.endDate;
      } else {
        this.labelString = this.labelArray[1];
        return this.minDate;
      }
    } else {
      this.labelString = this.labelArray[0];
      return this.minDate;
    }
  }

  createForm() {
    this.instantRenewalForm = this.fb.group({
      policyNumber: ["", Validators.required],
      customerDOB: [""]
    })
  }

  // Browser Forward Button disabled
  browserForwardButtonDisabled() {
    if (this.previousUrl === `/${routeEnums.RenewalSummary}`) {
      window.history.pushState(null, null, window.location.href);
    }
    window.onpopstate = () => {
      if (this.previousUrl === `/${routeEnums.RenewalSummary}`) {
        window.history.pushState(null, null, window.location.href);
      }
    };
  }

  homeSearch(policyNumber) {
    if (this.validateDetails()) {
      this.renewal_service_storage.homeSearch(policyNumber, this.instantRenewalForm.value.customerDOB);
      this.formSubmitted = false;
    }
  }

  zeroTAtSearch(policyNumber) {
    if (this.validateDetails()) {
      this.renewal_service_storage.zeroTatSearch(policyNumber, this.instantRenewalForm.value.customerDOB);
      this.formSubmitted = false;
    }
  }

  indenmitySearch(policyNumber, SubProductType) {
    if (this.validateDetails()) {
      this.renewal_service_storage.indenmitySearch(policyNumber, SubProductType, this.instantRenewalForm.value.customerDOB)
      this.formSubmitted = false;
    }
  }
  
  validateDetails() {
    let valid = false;
    if (this.instantRenewalForm.value.customerDOB) {
      valid = true;
    } else {
      this.errorPopupData = this.popupService.fetchErrorPopupMsg('Kindly enter valid Date of Birth');
      this.popupService.openGenericPopup(this.errorPopupData);
    }
    return valid;
  }

  searchDetails() {
    this.formSubmitted = true;
    if (this.instantRenewalForm.valid) {
      let policyNumber = this.instantRenewalForm.controls['policyNumber'].value;
      let SubProductInfo = this.renewal_service_storage.getSubProductType(policyNumber);
      if(SubProductInfo.ProductType == 'indimnity') {
        this.indenmitySearch(policyNumber, SubProductInfo.SubProductType);
      } else if (SubProductInfo.ProductType == 'zerotat') {
        this.zeroTAtSearch(policyNumber);
      } else if (SubProductInfo.ProductType == 'home') {
        this.homeSearch(policyNumber);
      } else {
        return;
      }
    }
    else {
      return;
    }
  }

  hasError(controlName: string, errorCode: string): boolean {
    return !this.instantRenewalForm.valid && this.instantRenewalForm.hasError(errorCode, [controlName]);
  }

  ngOnDestroy(): void {
    if (this.dateSubscription) {
      this.dateSubscription.unsubscribe();
    }
  }
}
