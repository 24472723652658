import { Injectable } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { validationPatterns, patterns, healthMembers, healthToggle, ProductType, ProductCode, GenderMapping } from "../enums/healthEnums";
import { BehaviorSubject } from "rxjs";
import { Constants } from "../constants/constants";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { userEnums } from "../enums/userEnums";
import { StorageService } from "./storage.service";
import { routeEnums } from "../enums/routeEnums";
import { PopupModal } from "src/app/layout/popups/popup.modal";
import { popupButton, popupButtonAction, popupImgPath, popupType } from "../enums/popupEnums";
import { PopupComponent } from "src/app/layout/popups/popup.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: 'root',
})

export class UtilityService {
  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  controlKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', 'Enter'];
  lastKeyPressed: string;
  dateRegex = Constants.dateFormatPattern;

  showSnackbar = (message: string) => {
    this.snackBar.open(message, '', {
      duration: 2000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
    });
  };

  popUpDialog(p0: string) {
    let popupData: PopupModal = {
      popupType: popupType.generic,
      imgName: popupImgPath.alertGif,
      header: '',
      description: p0,
      errorFlag: false,
      data: null,
      buttonLabel: popupButton.ok,
      buttonAction: popupButtonAction.close,
    };

    this.dialog.open(PopupComponent, {
      panelClass: 'my-custom-dialog',
      maxWidth: '100vw',
      data: popupData,
      disableClose: false
    }
    );
  }

  constructor(private snackBar: MatSnackBar, private router: Router, private datePipe: DatePipe,
    private storageService: StorageService, private dialog: MatDialog) { }

  ErrorMessage = new BehaviorSubject<string>('Success');

  getUTCTime() {
    let currTimestamp = Date.now(), //1482905176396
      utcDateString = (new Date(currTimestamp)).toUTCString();
    return new Date(utcDateString).getTime();
  }

  dateformate(date) {
    // Split the input date string
    const [day, month, year] = date.split('/');

    // Array of month names
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Get the abbreviated month name based on the month index
    const abbreviatedMonth = monthNames[parseInt(month, 10) - 1];

    // Construct the formatted date string
    const formattedDate = `${day}-${abbreviatedMonth}-${year}`;

    return formattedDate;
  }

  formatDate(date) { //dd-mmm-yyyy
    if (!date) return null;
    let currDate = new Date(date);
    return this.getMyDate(currDate.getDate()) + "-" + this.monthNames[currDate.getMonth()] + "-" + currDate.getFullYear();
  }

  formattedDate(dateString: string): string {
    if (dateString.includes('T')) {
      dateString = this.formatDateWithDash_dmy(dateString);
    }
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateParts = dateString.split('-');
    let month = dateParts[1];
    let day = dateParts[0];
    let year = dateParts[2];
    if (String(dateParts[1]).length == 2 && String(dateParts[0]).length == 4) {
      const monthIndex = parseInt(dateParts[1]) - 1;
      month = months[monthIndex];
      day = dateParts[2];
      year = dateParts[0];
    }
    return `${day}-${month}-${year}`;
  }

  patchGender(gender: string): string {
    let gen: string = '';
    if (!this.isEmptyOrNull(gender)) {
      switch (gender.toLowerCase()) {
        case GenderMapping.Male: {
          gen = 'Male'
          break;
        }
        case GenderMapping.Female: {
          gen = 'Female'
          break;

        }
        case GenderMapping.MF: {
          gen = 'Third gender';
          break;
        }
        default: {
          break;
        }
      }
    }
    return gen;
  }
  formateDateWithMonth(date) {
    // Input date string
    let formattedDate = '';
    if (date) {
      const dateString = date;
      // Split the date string into day, month, and year
      const [day, month, year] = dateString.split('/');
      // Create a Date object using the components
      const dateObject = new Date(`${year}-${month}-${day}`);
      // Format the date to "dd-MMM-yyyy"
      formattedDate = dateObject.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/\s/g, '-');
    // Replace "Sept" with "Sep"
      formattedDate = formattedDate.replace('Sept', 'Sep');
    }
    return formattedDate;
  }

  formateDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Add 1 because months are zero-based
    const year = date.getUTCFullYear();

    // Format day and month as two digits
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    // Create the DD MM YYYY formatted date string
    const formattedDate = `${formattedDay} ${formattedMonth} ${year}`;
    return formattedDate; // Output: "01 05 1982"

  }

  formatDateWithSpace(date) { //dd mmm yyyy
    let currDate = new Date(date);
    return this.getMyDate(currDate.getDate()) + " " + this.monthNames[currDate.getMonth()] + " " + currDate.getFullYear();
  }


  formatToMonthDateYearWithSlash(date: string) { //mm/dd/yyyy
    let stringDate = date;
    let splitDate = stringDate.split('/');
    let year = splitDate[2];
    let month = splitDate[1];
    let day = splitDate[0];
    let finalDate;
    finalDate = month + "/" + day + "/" + year;
    return !finalDate.includes('T') ? new Date(finalDate) : finalDate
  }

  formatToMonthDateYearHyphen(date: string) { //mm-dd-yyyy
    let stringDate = date;
    let splitDate = stringDate.split('-');
    let year = splitDate[0];
    let month = splitDate[1];
    let day = splitDate[2];
    let finalDate;
    finalDate = month + "-" + day + "-" + year;
    return !finalDate.includes('T') ? new Date(finalDate) : finalDate
  }

  formatDateWithDash(date) { //yyyy-mm-dd
    const originalDate = new Date(date);

    if (!date) {
      return null;
    } else if (originalDate.toString() == 'Invalid Date') {
      let dateParts = date.split("/");
      let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      return this.datePipe.transform(dateObject, 'yyyy-MM-dd');
    } else {
      return this.datePipe.transform(originalDate, 'yyyy-MM-dd');
    }
  }

  formatDateHomeRenewal(date) {
    if (typeof date === 'string') {
      const dateParts = date.split('/');
      return `${dateParts[0]}-${this.monthNames[+dateParts[1] - 1]}-${dateParts[2]}`
    }
    return this.formatDate(date);
  }

  //input date in form dd/mm/yyyy and output yyyy-mm-dd
  formatewithDate(date) {
    let dateString = date;
    let parts = dateString.split('/'); // Split the string into parts using '/'
    let formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`; // Rearrange the parts to 'yyyy-mm-dd'
    return formattedDate;
  }

  formatDate3(date) { //yyyy-mm-dd
    let data = date.split("/");
    let finalDate = data[2] + "-" + data[1] + "-" + data[0];
    return finalDate
  }

  convertDateFormat(formatDate) {
    let parts = formatDate.split('/');
    let finalPart = parts[1] + '/' + parts[0] + '/' + parts[2];
    return finalPart
  }

  DateWithSlash(date) { //yyyy/mm/dd
    const originalDate = new Date(date);

    // Get the year, day, and month components
    const year = originalDate.getFullYear();
    const day = originalDate.getDate().toString().padStart(2, '0');
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based, so we add 1

    // Create the formatted date string in yyyy-dd-mm format
    return `${year}-${month}-${day}`;
  }

  DateWithSlash1(date) { //dd-mm-yyyy
    const originalDate = new Date(date);

    // Get the year, day, and month components
    const year = originalDate.getFullYear();
    const day = originalDate.getDate().toString().padStart(2, '0');
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based, so we add 1

    // Create the formatted date string in dd-mm-yyyy format
    return `${day}-${month}-${year}`;
  }

  formatDateWithSlash(date) { //dd/mmm/yyyy
    let currDate = new Date(date);
    return this.getMyDate(currDate.getDate()) + "/" + this.monthNames[currDate.getMonth()] + "/" + currDate.getFullYear();
  }

  formatDateWithDash_dmy(date) { // dd-mmm-yyyy
    // Check if the input date already matches the desired format
    const match = /^(\d{2})-(\w{3})-(\d{4})$/.exec(date);
    if (match) {
      // If it's already in the desired format, return it as is
      return date;
    }

    let currDate = new Date(date);
    // Transform the date to the desired format
    return this.getMyDate(currDate.getDate()) + "-" + this.monthNames[currDate.getMonth()] + "-" + currDate.getFullYear();
  }


  getMonth(value) {
    const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthAbbreviation = value; // Example: November

    // Find the numerical month by searching the array
    const numericalMonth = monthAbbreviations.indexOf(monthAbbreviation) + 1; // Add 1 to match 1-based month numbering

    if (numericalMonth > 0) {
      // Check if the abbreviation was found
      const formattedMonth = String(numericalMonth).padStart(2, '0');
      return formattedMonth; // Output: "11" for November
    } else {
      return 'Invalid month abbreviation';
    }
  }

  // Comparison of two dates
  dateDiff(d1, d2) {
    return d1 < d2;
  }

  fetchMonth(date) {
    let tempMonth: string = this.monthNames[date.getMonth()];
    let monthId = this.monthNames.findIndex(data => data == tempMonth);
    return monthId + 1;
  }

  getMyDate(day) {
    return day < 10 ? '0' + day : '' + day;
  }

  getMonthInNumber(month): number {
    let months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    month = month.toLowerCase();
    let monthId = months.findIndex(data => data == month);
    return monthId + 1;
  }
  formatDate1(date) { //dd-mmm-yyyy
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let currDate = new Date(date);
    return this.getMyDate(currDate.getDate()) + "-" + monthNames[currDate.getMonth()] + "-" + currDate.getFullYear();
  }

  formatWithIncrementDate(inputDate, numberOfDays) {
    let date = new Date(inputDate)
    // Add a day
    let newDate = date.setDate(date.getDate() + numberOfDays)
    return new Date(newDate)
  }

  // formatDate(date) { //dd-mmm-yyyy
  //   var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  //   var currDate = new Date(date);
  //   return this.getMyDate(currDate.getDate()) + "-" + monthNames[currDate.getMonth()] + "-" + currDate.getFullYear();
  // }

  calculateAge(DOB) {
    let today = new Date();
    let birthDate = new Date(DOB);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  setGender(relationship, relationshipsArray): string {
    let gender = '';
    relationshipsArray.forEach((ele, index) => {
      if (ele.RelationshipName == relationship) {
        gender = ele.Gender;
      }
    });
    return gender;
  }

  // To get exact min and max date as per years passed
  // if need to add 11 month more
  getMinMaxDate(maxYear: any, minYear?: any, maxMonth?: any, minMonth?: any,) {
    let defaultMaxDate = new Date().getFullYear();
    let defaultMinDate = new Date().getFullYear() - 100;
    let maxDate = maxYear ? maxYear : defaultMaxDate;
    let minDate = minYear ? minYear : defaultMinDate;
    let d = new Date();
    let tempMaxDate, tempMinDate;
    if (maxMonth) {
      d.setFullYear(maxDate);
      let a = d.getMonth() - maxMonth;
      tempMaxDate = d.setMonth(a);
      d.setDate(d.getDate() + 1);
    } else {
      tempMaxDate = d.setFullYear(maxDate);
      d.setDate(d.getDate() + 1);
    }
    if (minMonth) {
      d.setFullYear(minDate);
      let a = d.getMonth() - minMonth;
      tempMinDate = d.setMonth(a);
      d.setDate(d.getDate() + 1);
    } else {
      d.setFullYear(minDate);
      tempMinDate = d.setMonth(d.getMonth());
      d.setDate(d.getDate() + 1);
    }
    return { maxDate: new Date(tempMaxDate), minDate: new Date(tempMinDate) }
  }

  compareDates(date1, date2) {
    return new Date(date1) >= new Date(date2);
  }

  easyIncomeReader(value: string): string {
    if (value.includes('-')) {
      let [start, end] = value.split('-');
      start = this.convertStringtoCurrency(start.trim());
      end = this.convertStringtoCurrency(end.trim());
      return `${start} - ${end}`
    }
    else if (value.includes('Upto') || value.includes('Up')) {
      let [_, end] = value.split(' ');
      end = this.convertStringtoCurrency(end);
      return `Upto ${end}`
    } 
    let [start, _] = value.split(' ');
    start = this.convertStringtoCurrency(start);
    return `${start} and above`
  }
  
  addCommasToIntegerPart(integerPart) {
    let result = '';
    let count = 0;

    for (let i = integerPart.length - 1; i >= 0; i--) {
        count++;
        result = integerPart[i] + result;
        if (count % 3 === 0 && i !== 0) {
            result = ',' + result;
        }
    }
    return result;
}

  convertStringtoCurrency(input) {
    if (input) {
      // Ensure the input is a valid number string
      const [integerPart, decimalPart] = input.toString().split('.');
      // Add commas to the integer part
      const integerWithCommas = this.addCommasToIntegerPart(integerPart);
      // const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // Combine integer and decimal parts
      return decimalPart ? `${integerWithCommas}.${decimalPart}` : integerWithCommas;
    }
    return '';
  }

  isEmptyOrNull(data) {
    if (data instanceof Date) {
      // If it's a date object, consider it non-empty
      return false;
    }
    else if (typeof (data) == 'boolean') {
      return false;
    }
    else if (data === undefined || data == null || data == 'null' || data == '' || data.length <= 0 || Object.keys(data).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  // restrict spaces
  restrictSpace(event) {
    let spaceRegex = /\s/g;
    if (event && event.currentTarget && event.currentTarget && event.currentTarget.value.match(spaceRegex)) {
      event.currentTarget.value = event.currentTarget.value.replace(spaceRegex, '');
    }
  }

  // To restrict user to enter only numbers
  numberOnly(event): boolean {
    let allowNumbersFlag: boolean;
    allowNumbersFlag = this.allowOnlyNumbers(event);
    return allowNumbersFlag;
  }

  //restruct only 10 digit number
  mobileNumberOnly(event): boolean {
    const input = event.target as HTMLInputElement;
    const keyCode = event.keyCode ? event.keyCode : event.which;

    // Allow control keys
    if (keyCode === 8 || keyCode === 46 || keyCode === 37 || keyCode === 39) {
      return true;
    }

    // Prevent input if length is already 10
    if (input.value.length >= 10) {
      return false;
    }

    // Allow only numbers (key codes for 0-9)
    if (keyCode >= 48 && keyCode <= 57) {
      return true;
    } else {
      return false;
    }
  }

  // To restrict user to only enter numbers  for dynamic controls
  // To restrict user to enter only numbers
  dynamicControlsNumberOnly(event, numberValidationRequired: boolean): boolean {
    let allowNumbersFlag: boolean;
    if (numberValidationRequired) {
      allowNumbersFlag = this.allowOnlyNumbers(event);
    }
    else if (!numberValidationRequired) {
      allowNumbersFlag = event;
    }

    return allowNumbersFlag;
  }

  dynamicControlsNumberDecimalOnly(event, numberValidationRequired: boolean): boolean {
    let allowNumbersFlag: boolean;
    if (numberValidationRequired) {
      allowNumbersFlag = this.allowOnlyNumbersDecimal(event);
    }
    else if (!numberValidationRequired) {
      allowNumbersFlag = event;
    }

    return allowNumbersFlag;
  }

  allowOnlyNumbers(event): boolean {
    let numberflagCheck: boolean = false;
    let inputValue;
    let numberRegex = /\D/g;
    if (event && event.currentTarget && event.currentTarget.value) {
      inputValue = event.currentTarget.value.replace(numberRegex, '');
      event.currentTarget.value = inputValue;
      numberflagCheck = true;
    }
    return numberflagCheck;
  }

  allowOnlyNumbersDecimal(event): boolean {
    // const charCode = (event.which) ? event.which : event.keyCode;
    // ASCII Codes for refrence: 48: 0; 57: 9; 31: Unit separator
    // if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    //   return false;
    // }
    let numberflagCheck: boolean = false;
    let inputValue;
    let numberRegex = /[^0-9.]+/g;
    if (event && event.currentTarget && event.currentTarget.value) {
      inputValue = event.currentTarget.value.replace(numberRegex, '');
      event.currentTarget.value = inputValue;
      numberflagCheck = true;
    }
    return numberflagCheck;
  }

  dynamicControlsAlphaOnly(event, textValidationRequired: boolean): boolean {
    let allowTextRequired: boolean = false;
    let alphaRegex = /[^a-z_ ]/i;
    if (textValidationRequired && event && event.currentTarget && event.currentTarget.value) {
      let validatedText = event.currentTarget.value.replace(alphaRegex, '');
      event.currentTarget.value = validatedText;
    }
    else if (!textValidationRequired) {
      allowTextRequired = true;
    }
    return allowTextRequired
  }


  // To check while pasting input
  onPaste(event: ClipboardEvent, onlyAlphaAllowed: boolean, onlyNumAllowed: boolean) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text/plain');
    if ((onlyAlphaAllowed && !this.isValidText(pastedText)) || (onlyNumAllowed && !this.isValidNumber(pastedText))) {
      event.preventDefault();

    }
  }

  // handle tab events
  nextElementFocus(id: string): string {
    //i stands for insured
    // s stands for search customer form
    // a stands for applicant block top 
    // c & p stands for correspondence and permanent block
    // o,n,k stands for gst, nominee and apointee block
    let customId: string = '';
    let delimeter = id[0];
    // Below are the field for which tab index is failing. Please check respective json to get control ame
    if (id == 'i3' || id == 'a3' || id == 'c3' || id == 'c5' || id == 's1' || id == 'p3' || id == 'p5' || id == 'n2' || id == 'k2') {
      let number = parseInt(id[1]);
      let nextId = number + 1;
      // By using below code we are focusing on next element
      // a4 is applicant dob ---> correspondence full name
      customId = delimeter + nextId.toString();
      return customId;
    }
    return customId;
    // }

  }

  isValidText(text: string): boolean {
    const pattern = Constants.textPatternRegExp;
    return pattern.test(text);
  }

  isValidNumber(text: string): boolean {
    const pattern = Constants.numberPatternRegExp;
    return pattern.test(text);
  }

  isValidPhoneNumber(text: string): boolean {
    const pattern = new RegExp(Constants.mobileNumberPatternRegExp);
    return pattern.test(text);
  }

  isValidPincodeNumber(text: string): boolean {
    const pattern = new RegExp(Constants.pinCodePatternRegExp);
    return pattern.test(text);
  }

  // construction of dynamic validations
  constructDynamicValidation(validationList: Object, validationArray: Validators[]) {
    for (let key in validationList) {
      if (key == validationPatterns.required) {
        validationArray.push(this.requiredFieldValidation())
      }

      else if (key == validationPatterns.pattern) {
        validationArray.push(Validators.pattern(patterns[`${validationList[key]}`]))
      }

      else if (key == validationPatterns.minlength || key == validationPatterns.maxlength) {
        validationArray.push(key == validationPatterns.minlength ? Validators.minLength(validationList[key]) : Validators.maxLength(validationList[key]))
      }
    }
    return validationArray;
  }

  // sorting columns alphabetically
  sortDetails(array, colName) {
    return array.sort(function (a, b) {
      if (a[colName] < b[colName]) {
        return -1;
      }
      if (a[colName] > b[colName]) {
        return 1;
      }
      return 0;
    });
  }

  subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  }

  subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months)
    return date;
  }

  subtractDays(date, days) {
    // let count: number = 0;
    // if (this.isLeapYear(new Date().getFullYear())) {
    //   count = 1;
    // }
    // days = days - count;
    date.setDate(date.getDate() - days);
    return date;
  }

  subtractLeapYearDays(date, days) {
    // Get the current day of the month
    let currentDay = date.getDate();

    // Subtract the days
    date.setDate(currentDay - days);

    // Check if the resulting date is in February of a leap year
    if (date.getMonth() === 1 && date.getDate() > 28) {
      // Adjust the date to February 28 (leap year day)
      date.setDate(28);
    }

    return date;

  }

  isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }

  addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }
  // validations
  // Required field validation
  requiredFieldValidation() {
    return (control: AbstractControl) => {
      if (typeof (control.value) == 'object') {
        return null;
      } else if (typeof (control.value) == 'boolean') {
        return null;
      }
      else {
        let value = control.value ? (control.value).trim() : '';
        if (value == '') {
          return { required: true }
        }
        return null;
      }
    }
  }

  // To check whether user has entered 0 number in input field or not
  zeroValidation() {
    return (control: AbstractControl) => {
      let value = parseInt(control.value);
      if (value == 0) {
        return { invalidNumber: true }
      }
      return null;

    }
  }
 s4() {
    return window.crypto.getRandomValues(new Uint16Array(1))[0].toString(16).padStart(4, '0');
}

createCorrelationId() {
    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
}
  // Creation of correlation Id
  // createCorrelationId() {
  //   function s4() {
  //     return Math.floor((1 + Math.random()) * 0x10000)
  //       .toString(16)
  //       .substring(1);
  //   }
  //   return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  // }

  truncateAfterWhiteSpace(value: string): string {
    if (value.indexOf(' ')) {
      const index = value.indexOf(' ');
      if (index !== -1) {
        return value.substring(0, index);
      } else {
        return value;
      }
    } else {
      return value;
    }

  }

  truncateAndReturnAfterSpace(value: string): string {
    if (value.indexOf(' ')) {
      const index = value.indexOf(' ');
      if (index !== -1) {
        return value.substring(index + 1);
      } else {
        return value;
      }
    } else {
      return value;
    }
  }

  truncateAfterT(value: string): string {
    if (value.indexOf('T')) {
      const index = value.indexOf('T');
      if (index !== -1) {
        return value.substring(0, index);
      } else {
        return value;
      }
    } else {
      return value;
    }
  }

  removeHyphens(value: string): string {
    if (value.includes('--')) {
      return value.replace(/--/g, ' ');
    } else {
      return value;
    }
  }

  setDOB(value: string): string {
    if (value.includes('T')) {
      const date = this.truncateAfterT(value);
      const dateParts = date.split('-');
      return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`
    } else {
      const dateParts = value.split('-');
      return `${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`
    }
  }

  removeUnderScore(value: string): string {
    if (value.includes('_')) {
      return value.replace(/_/g, ' ');
    } else {
      return value;
    }
  }

  // for send payment link summary
  easyAmountReader(value, s?: string): string {
    let symbol = '₹';
    if (s === 'dollar') {
      symbol = '$';
    }

    let val = Number(value);
    if (val >= 9999999999) {
      return 'Unlimited';
    } else if (val >= 10000000) {
      val = val / 10000000;
      return `${symbol} ${val % 1 === 0 ? val.toFixed(0) : val.toFixed(1)} Cr`;
    } else if (val >= 100000) {
      val = val / 100000;
      return `${symbol} ${val % 1 === 0 ? val.toFixed(0) : val.toFixed(1)} L`;
    } else if (val >= 1000) {
      val = val / 1000;
      return `${symbol} ${val % 1 === 0 ? val.toFixed(0) : val.toFixed(1)} K`;
    } else {
      return `${symbol} ${Math.floor(val)}`;
    }
  }

  identifyEnvironmentType(token: string): Object {
    let source = {
      env: '',
      port: '',
      token: ''
    };
    source.env = environment.environment;
    source.port = window.location.hostname === 'localhost' ? window.location.port : '';
    source.token = token;
    return source;
  }

  amountinWords(val: number): string {
    if (val >= 9999999999) {
      return 'UNLIMITED';
    }
    else if (val >= 10000000) {
      val = val / 10000000;
      return `₹ ${Math.floor(val)} Crore`;
    }
    else if (val >= 100000) {
      val = val / 100000;
      return `₹ ${Math.floor(val)} Lakhs`;
    }
    else
      return `₹ ${Math.floor(val)}`;
  }

  validateDateFormat(dateString: string): boolean {
    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format
    return datePattern.test(dateString);
  }


  // @Definitiion
  // type ---> denotes Adult & Child
  // adultFormData ---> Pass your adult form group
  // childFormData ---> Pass your child form group. Send null if you don't have child data and send child present as false
  // adultIncValue --> Pass the last incremental value for adult
  // childIncValue --> Pass the last incremental value for child
  // adultMemberAllowed --> Pass the max adult member allowed for particular feature
  // childMemberAllowed --> Pass the max child member allowed for particular feature
  // childPresent --> for particular feature pass true if child is also preent. Or false if child not required

  // Member incrementation fresh flow
  memberIncrement(type: string, adultFormData: FormArray<FormGroup>, childFormData: FormArray<FormGroup> | null, adultIncValue: number, childIncValue: number, adultMemberAllowed: number, childMemberAllowed: number, childPresent: boolean, elevate: boolean = false) {
    const maxAdult: number = adultMemberAllowed;
    const maxChild: number = childMemberAllowed;
    let resetChild: boolean = false;
    let addChild: boolean = false;

    if (type == healthMembers.Adult) {
      //Passed adult value should not be more than the Max Adult Criteria 
      if (adultIncValue <= maxAdult) {
        switch (adultIncValue) {
          // If user tries to select more than 7 adult then show snackbar
          // For 7A 0C should be there
          case 7: {
            this.popUpDialog('Only 7 Adults are allowed');
            // If there are children more than 0 reset to 0
            if (childIncValue > 0 && childPresent) {
              resetChild = true;
            }
            break;
          }
          case 6: {

            if (adultIncValue == maxAdult) {
              this.popUpDialog(`Only ${maxAdult} Adult are allowed`);
            }
            // If there are children more than 1 reset to 0
            else if (childIncValue >= 1 && childPresent) {
              resetChild = true;
            }
            break;
          }
          // If there are 5A and it wants 6A
          // Then only one child can be allowed
          case 5: {
            if (childIncValue >= 2 && childPresent) {
              resetChild = true;
            }
            break;
          }
          // If there are 4A and it wants 5A
          // Then only two child can be allowed
          case 4: {
            // For family shield there are 4 adults. So If user clicks on + again after 4 adults are selected
            // Show below snackbar
            if (adultIncValue == maxAdult) {
              this.popUpDialog(`Only ${maxAdult} Adult are allowed`);
            }

            else if (childIncValue > 2 && childPresent) {
              resetChild = true;
            }

            break;
          }
          default: {
            if (adultIncValue == maxAdult) {
              this.popUpDialog(`Only ${maxAdult} Adult are allowed`);
            }
            break;
          }
        }

        // Less than max adults can only be incremented
        if (adultIncValue != maxAdult) {
          adultIncValue++;
          adultFormData.push(this.createAdultDetails(adultFormData.length));
        }
      }
    }

    else if (type == healthMembers.Child && childPresent) {
      if (childIncValue <= maxChild) {
        switch (adultIncValue) {
          // If 0A is present then we should allow only 1C
          case 0: {
            if (childIncValue == 1) {
              this.popUpDialog('Only 1 Child is allowed for 0 Adult');
            }
            break;
          }
          // If adult is 1 then we should not allow child to be upgraded to more than two members
          case 1: {
            // If user has already made child two then we need to restrict user to not to add more child
            //  For elevate we are not having condition to add only 2 child
            if (childIncValue == 2 && !elevate) {
              this.popUpDialog('2 Child are only allowed on 1 Adult');
            }
            // If child is less than 2 then increment to 3
            // If it is coming from elevate then we can allow to add upto 3 child
            else if (childIncValue < 2 || (elevate && childIncValue < 3)) {
              addChild = true;
            }

            // If it is elevate user can't add more than 3 child
            else if (childIncValue == 3 && elevate) {
              this.popUpDialog(`Only ${maxChild} Child are allowed`);
            }

            break;
          }
          // Check if child needs to be upgraded to 3 
          // Allow only if adult is in between 2 and 4
          case 2:
          case 3:
          case 4: {
            // Here also child can be upgraded if the child length is 2 
            if (childIncValue <= 2) {
              addChild = true;
            }
            // If child is already 3 Dont allow
            else if (childIncValue == 3) {
              this.popUpDialog('Only 3 Child are allowed');
            }
            break;
          }
          // If adult is 5 then we can select only two childrens
          case 5: {
            if (childIncValue > 2) {
              resetChild = true;
            }
            else if (childIncValue < 2) {
              addChild = true;
            }
            else {
              this.popUpDialog('Only 2 Child is allowed for 5 Adult');
            }
            break;
          }
          // If 6A present user cans select only 1C
          case 6: {
            if (childIncValue > 1) {
              resetChild = true;
            }
            else if (childIncValue < 1) {
              addChild = true;
            }
            else {
              this.popUpDialog('Only 1 Child is allowed for 6 Adult');
            }
            break;
          }
          // If 7A are present child cannot be selected
          case 7: {
            if (childIncValue >= 1) {
              resetChild = true;
            }
            else {
              this.popUpDialog('Child can not be selected for 7 Adult')
            }
            break;
          }
          default: {
            if (childIncValue == maxChild) {
              this.popUpDialog(`Only ${maxChild} Child are allowed`)
            }
          }
        }
      }
    }

    // Reset child only if child is present
    if (resetChild && childPresent && !addChild) {
      childIncValue = 0;
      childFormData.clear();
    }

    // If child is present and we need to add child then below condition gets executed
    else if (addChild && childPresent && !resetChild) {
      childIncValue++;
      childFormData.push(this.createChildDetails(childFormData.length));
    }

    return { adultInc: adultIncValue, childInc: childIncValue };
  }
  // Member incrementation renewal flow
  memberRenewalIncrement(type: string, adultFormData: FormArray<FormGroup>, childFormData: FormArray<FormGroup> | null, adultIncValue: number, childIncValue: number, adultMemberAllowed: number, childMemberAllowed: number, childPresent: boolean) {
    const maxAdult: number = adultMemberAllowed;
    const maxChild: number = childMemberAllowed;
    let resetChild: boolean = false;
    let addChild: boolean = false;
    let addAdult: boolean = true;

    if (type == healthMembers.Adult) {
      //Passed adult value should not be more than the Max Adult Criteria 
      if (adultIncValue <= maxAdult) {
        switch (adultIncValue) {
          // If user tries to select more than 7 adult then show snackbar
          // For 7A 0C should be there
          case 7: {
            this.popUpDialog(`Only ${maxAdult} Adult and 1 Child are allowed`);
            // If there are children more than 0 reset to 0
            if (childIncValue > 0 && childPresent) {
              resetChild = true;
            }
            break;
          }
          case 6: {
            if (adultIncValue == maxAdult) {
              this.popUpDialog(`Max ${maxAdult} Adult are allowed`)
            }
            // If there are children more than 1 reset to 0
            if (childIncValue >= 1 && childPresent) {
              addAdult = false;
              this.popUpDialog(`Max ${maxAdult} Adult and ${maxChild} Child are allowed`);
            }
            break;
          }
          // If there are 5A and it wants 6A
          // Then only one child can be allowed
          case 5: {
            if (childIncValue >= 2 && childPresent) {
              addAdult = false;
              this.popUpDialog(`Max ${maxAdult} Adult and ${maxChild} Child are allowed`);
            }
            break;
          }
          // If there are 4A and it wants 5A
          // Then only two child can be allowed
          case 4: {
            // For family shield there are 4 adults. So If user clicks on + again after 4 adults are selected
            // Show below snackbar
            if (adultIncValue == maxAdult) {
              if (adultIncValue == maxAdult) {
                this.popUpDialog(`Max ${maxAdult} Adult and ${maxChild} Child are allowed`)
              }
            }

            else if (childIncValue > 2 && childPresent) {
              addAdult = false;
              this.popUpDialog(`Max ${maxAdult} Adult and ${maxChild} Child are allowed`);
            }

            break;
          }
          default: {
            if (adultIncValue == maxAdult) {
              this.popUpDialog(`Max ${maxAdult} Adult are allowed`)
            }
            break;
          }
        }

        // Less than max adults can only be incremented
        if (adultIncValue != maxAdult && addAdult) {
          adultIncValue++;
          adultFormData.push(this.createAdultDetails(adultFormData.length));
        }
      }
    }

    else if (type == healthMembers.Child && childPresent) {
      if (childIncValue <= maxChild) {
        switch (adultIncValue) {
          // If 0A is present then we should allow only 1C
          case 0: {
            if (childIncValue == 1) {
              this.popUpDialog('Only 1 Child is allowed for 0 Adult');
            }
            break;
          }
          // If adult is 1 then we should not allow child to be upgraded to more than two members
          case 1: {
            // If user has already made child two then we need to restrict user to not to add more child
            if (childIncValue == 2) {
              this.popUpDialog('2 Child are only allowed on 1 Adult');
            }
            // If child is less than 2 then increment to 3
            else if (childIncValue < 2) {
              addChild = true;
            }
            break;
          }
          // Check if child needs to be upgraded to 3 
          // Allow only if adult is in between 2 and 4
          case 2:
          case 3:
          case 4: {
            // Here also child can be upgraded if the child length is 2 
            if (childIncValue <= 2) {
              addChild = true;
            }
            // If child is already 3 Dont allow
            else if (childIncValue == 3) {
              this.popUpDialog('Only 3 Child are allowed');
            }
            break;
          }
          // If adult is 5 then we can select only two childrens
          case 5: {
            if (childIncValue > 2) {
              resetChild = true;
            }
            else if (childIncValue < 2) {
              addChild = true;
            }
            else {
              this.popUpDialog('Only 2 Child is allowed for 5 Adult');
            }
            break;
          }
          // If 6A present user cans select only 1C
          case 6: {
            if (childIncValue > 1) {
              resetChild = true;
            }
            else if (childIncValue < 1) {
              addChild = true;
            }
            else {
              this.popUpDialog('Only 1 Child is allowed for 6 Adult');
            }
            break;
          }
          // If 7A are present child cannot be selected
          case 7: {
            if (childIncValue >= 1) {
              resetChild = true;
            }
            else {
              this.popUpDialog('Child can not be selected for 7 Adult')
            }
            break;
          }
          default: {
            if (childIncValue == maxChild) {
              this.popUpDialog(`Only ${maxChild} Child are allowed`)
            }
          }
        }
      }
    }

    // Reset child only if child is present
    if (resetChild && childPresent && !addChild) {
      childIncValue = 0;
      childFormData.clear();
    }

    // If child is present and we need to add child then below condition gets executed
    else if (addChild && childPresent && !resetChild) {
      childIncValue++;
      childFormData.push(this.createChildDetails(childFormData.length));
    }

    return { adultInc: adultIncValue, childInc: childIncValue };
  }

  // Member decrymentation
  memberDecryment(type: string, adultFormData: FormArray<FormGroup>, childFormData: FormArray<FormGroup> | null, adultIncValue: number, childIncValue: number, adultMemberAllowed: number, childMemberAllowed: number, childPresent: boolean, elevate: boolean = false) {
    const maxAdult: number = adultMemberAllowed;
    const maxChild: number = childMemberAllowed;
    let adultReset: boolean = false;
    let childReset: boolean = false;
    let lastAdultPop: boolean = false;
    if (type == healthMembers.Adult) {
      if (adultIncValue <= maxAdult) {
        // If user is trying to make 0A.
        // If user is trying to make 0A then check for child. If child is present then 0A can be done
        if (adultIncValue == 1) {
          // If adult is one and child is also one then adult can be made 0
          // In elevate adult can't be made 0
          if (childIncValue == 1) {
            adultReset = true
          }
          // If child is also 0 then adult memeber can not be made 0
          else if (childIncValue == 0) {
            // this.popUpDialog('Atleast one member is requireds');
            this.popUpDialog('Atleast one member is required');
          }
          // If there are more child than 1 .
          // Then we need to by default make child 1
          // 3K is allowed to 1A
          else if (childIncValue > 1) {
            childIncValue = 1; //1C
            while (childFormData.length > 1) {
              childFormData.removeAt(1) //only 1C will be present
            }
            adultReset = true;
          }
        }
        // Now if adult was two and it is trying to become 1
        // Check for child.
        else if (adultIncValue == 2) {
          // Even if child is 3 user decryments to 2A in elevate it is allowed
          if (childIncValue == 3 && !elevate) {
            //  If 3C present then reset the child
            childReset = true;
          }
          lastAdultPop = true;
        }
        // If adult is 6 and it wants to become 5 then we can only select two child
        else if (adultIncValue == 6) {
          if (childIncValue > 2) {
            childReset = true;
          }
          lastAdultPop = true;
        }
        // In all other cases let adult be decrymented
        else if (adultIncValue > 0) {
          lastAdultPop = true;
        }
      }

      if (adultReset) {
        adultIncValue = 0;//0A
        adultFormData.clear();
      }

      if (childReset && childPresent) {
        childIncValue = 0; //0c
        childFormData.clear();
      }

      if (lastAdultPop) {
        adultIncValue--; // lasta dult will be removed
        adultFormData.removeAt(adultFormData.length - 1);
      }

    }
    else if (type == healthMembers.Child && childPresent) {
      if (childIncValue <= maxChild) {
        // If no adult is present and one child is there
        // Then decryment operator should not allow to decryment child
        if (adultIncValue == 0) {
          this.popUpDialog('Atleast one member is required');
        }
        // Below condition restrict user to decrement to negative values
        else if (childIncValue > 0) {
          childIncValue--;
          childFormData.removeAt(childFormData.length - 1);
        }
      }
    }
    return { adultInc: adultIncValue, childInc: childIncValue };
  }

  // Member tab clicked
  memberTabClicked(memberData, adultFormData: FormArray<FormGroup>, childFormData: FormArray<FormGroup> | null, adultIncValue: number, childIncValue: number, childPresent: boolean) {
    let childReset: boolean = false;
    let adultReset: boolean = false;
    // If child button is unselected then ensure you are makig child 0 
    // But also check one adult should be there then only make it to 0
    if (memberData.label == healthMembers.Child && childPresent) {
      if (adultIncValue >= 1) {
        childReset = true;
      }
      // If adult is not present then user can't uncheck child
      else if (adultIncValue == 0) {
        this.popUpDialog('Atleast one member is requireds');
      }
    }

    // If adult button is unchecked
    else if (memberData.label == healthMembers.Adult) {
      // if more child is present then make child to 1. As 0A can have 1C
      if (childIncValue >= 1) {
        childIncValue = 1;
        while (childFormData.length > 1) {
          childFormData.removeAt(1) //only 1C will be present
        }
        adultReset = true;
      }

      else if (childIncValue == 1) {
        adultReset = true;
      }
      // If child is not present then user can't uncheck adult
      else if (childIncValue == 0) {
        this.popUpDialog('Atleast one member is required');
      }
    }

    if (childReset && childPresent) {
      childIncValue = 0;
      childFormData.clear();
      memberData.incDec = !memberData.incDec;
      memberData.imgSrc = !memberData.incDec ? '../../../../assets/images/Single Child.svg' : '../../../../assets/images/selectedChild.svg';
    }

    else if (adultReset) {
      adultIncValue = 0;
      adultFormData.clear();
      memberData.incDec = !memberData.incDec;
      memberData.imgSrc = !memberData.incDec ? '../../../../assets/images/Single Adult.svg' : '../../../../assets/images/selectedAdult.svg';
    }
    return { adultInc: adultIncValue, childInc: childIncValue };
  }


  createAdultDetails(index?): FormGroup {
    return new FormGroup({
      ['adultRealtionship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['dobAdult' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultVaccinationStatus' + `${index + 1}`]: new FormControl(healthToggle.No, [Validators.required]),
      ['adultVaccinationDate' + `${index + 1}`]: new FormControl(''),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  createChildDetails(index?): FormGroup {
    return new FormGroup({
      ['childRelationship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['dobChild' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }


  isUndefinedORNull(data: any) {
    if (data === undefined || data === null || data.length <= 0) {
      return true;
    } else {
      return false;
    }
  }
  // To find whether product is indemnity or zero tat
  productTypeStatus(productCode: number): string {
    let productType: string = '';
    let url = this.router.url.split('/')[1];
    if (url == 'health') {
      if (productCode == 23 || productCode == 38) {
        productType = ProductType.ZeroTat;
      }
      else {
        productType = ProductType.Indemnity;
      }
    } else if (url == 'travel') {
      productType = ProductType.Travel;
    }
    return productType;
  }

  getStandardDateFormat(date, format) {
    let dateString = date.split('-');
    let formattedDate;
    if (format == 'dd-mm-yyyy') {
      formattedDate = `${dateString[2]}-${dateString[1]}-${dateString[0]}`;
    }  else if (format == 'dd-MMM-yyyy') {

      let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let [day, month, year] = date.split('-');
      formattedDate = `${year}-${monthNames.indexOf(month) + 1}-${day}`;
    }
    return new Date(formattedDate);
  }

  getFormattedDate(date, format) {
    // console.log('date formate hree ', this.datePipe.transform(date, 'yyyy-MM-dd'))
    let formattedDate;
    if (format == 'dd-mm-yyyy') {
      formattedDate = this.datePipe.transform(date, 'dd-MM-yyyy');
    } else if (format == 'dd/mm/yyyy') {
      formattedDate = this.datePipe.transform(date, 'dd/MM/yyyy');
    } else if (format == 'dd-MMM-yyyy') {
      formattedDate = this.datePipe.transform(date, 'dd-MMM-yyyy');
    } else if (format == 'yyyy-mm-dd') {
      formattedDate = this.datePipe.transform(date, 'yyyy-mm-dd');
    }
    return formattedDate;
  }
  // Map Sub Product Code
  mapSubProductCode(productType: string) {
    let productCode: number;
    switch (productType) {

      case 'hap': {
        productCode = ProductCode.HAP;
        break;
      }
      case 'gs': {
        productCode = ProductCode.GS;
        break;
      }
      case 'asp': {
        productCode = ProductCode.ASP;
        break;
      }
      case 'hep': {
        productCode = ProductCode.HEP;
        break;
      }
      case 'max': {
        productCode = ProductCode.MaxProtect;
        break;
      }
      case 'hb': {
        productCode = ProductCode.HB;
        break;
      }
      case 'cs': {
        productCode = ProductCode.CS;
        break;
      }
      case 'fs': {
        productCode = ProductCode.FS;
        break;
      }
      case 'elevate': {
        productCode = ProductCode.Elevate;
        break;
      }
      default: {
        break;
      }
    }
    this.storageService.setAESEncryptedData(userEnums.SubProductCode, productCode);
  }

  getCurrentDateTime(): string {
    let date = Math.floor(Date.now() / 1000)
    return date.toString();
  }

  checkDuplicate(values, key) {
    let valueArr = values.map(function (item) { return item[key] });
    let isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx
    });
    return isDuplicate;
  }


  toPascalCase(input: string): string {
    return input.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase(); });
  }

  checkForNumberInput(event: KeyboardEvent) {
    const regex = Constants.numberPatternRegExp;
    if (event.key === 'Backspace' || regex.test(event.key) || (event.ctrlKey && event.key === 'v')) {
      return;
    } else {
      event.preventDefault();
    }
  }
  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }

  private __getElementById(id: string): HTMLElement | null {
    const element = <HTMLElement>document.querySelector(`#${id}`);
    return element;
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  getFormattedKey(key: string): string {
    return key.replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
  }

  capitalize(value: string): string {
    return value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }

  // Gender will be determined based on title
  // Mr. ----> Male
  // Mx -----> Third Gender
  // Mrs., Ms. ----> Female
  titleBasedGender(title, route): string {
    let gender: string = '';
    let productCode: number = this.determineProductCode(route);
      // If you want gender in capital . Plz pass that product in capital products
    let capitialProducts = [ProductCode.HAP, ProductCode.Elevate, ProductCode.CS, ProductCode.FS, ProductCode.Combo, ProductCode.AB];
    let allowCapital = capitialProducts.includes(productCode);
    switch (title) {
      case 'Mr.': {
        gender = allowCapital ? 'MALE' : 'Male';
        break;
      }
      case 'Mx.': {
        gender = 'Third gender';
        break;
      }
      default: {
        gender = allowCapital ? 'FEMALE' : 'Female';
        break;
      }
    }
    return gender;
  }

  // Determining product code based on routes passed
  determineProductCode(route: string): number {
    let productCode: number = 0;
    switch (route) {
      case routeEnums.HAP: {
        productCode = ProductCode.HAP;
        break;
      }
      case routeEnums.MaxProtect: {
        productCode = ProductCode.MaxProtect;
        break;
      }
      case routeEnums.Elevate: {
        productCode = ProductCode.Elevate;
        break;
      }
      case routeEnums.CritiShield: {
        productCode = ProductCode.CS;
        break;
      }
      case routeEnums.Familyshield: {
        productCode = ProductCode.FS;
        break;
      }
      case routeEnums.GoldenShield: {
        productCode = ProductCode.GS;
        break;
      }
      case routeEnums.ASP: {
        productCode = ProductCode.ASP;
        break;
      }
      case routeEnums.HEALTH_BOOSTER: {
        productCode = ProductCode.HB;
        break;
      }
      case routeEnums.HEALTH_CHI: {
        productCode = ProductCode.HEP;
        break;
      }
      case routeEnums.SuperSaver: {
        productCode = ProductCode.Combo;
        break;
      }
      case routeEnums.ActivateBooster: {
        productCode = ProductCode.AB;
        break;
      }
      default: {
        break;
      }

    }
    return productCode;
  }

  // DOB input field validation
  // Below method restricts user to enter only numbers.
  // @params: Pass keyboard event only
  // Only special keys are allowed like backspace delete etc.
  allowNumbersAndSpclkeys(dateInput: KeyboardEvent) {
    const allowedNumberChar = /^[0-9]*$/;
    const inputChar = dateInput.key;
    this.lastKeyPressed = inputChar;
    if (!allowedNumberChar.test(this.lastKeyPressed) && !this.controlKeys.includes(this.lastKeyPressed)) {
      dateInput.preventDefault();
    }
  }

  // Below method is responsible for adding slashes for month date
  // Also if valid date entered it will append in correct format
  onDateChange(event): { status: boolean, updatedDate: Date } {
    const inputElement = event.target as HTMLInputElement;
    const value = event.target.value;
    let datePatchStatus: boolean = false;
    let newDate;
    let [day, month, year] = value.split('/');
    // Check if day or month is 2 append / afterwards provided no special keys typed
    if ((value.length == 2 || value.length == 5) && value.length < 10 && !this.controlKeys.includes(this.lastKeyPressed)) {
      this.formatDOBWithSlash(value, inputElement);
      datePatchStatus = false;
    }
    // Valid date entered
    else if (this.dateRegex.test(value)) {
      newDate = new Date(+year, parseInt(month) - 1, +(day));
      datePatchStatus = true;
    }
    return { status: datePatchStatus, updatedDate: newDate };
  }

  formatDOBWithSlash(value: string, inputElement: HTMLInputElement) {
    inputElement.value = value + '/';
  }

  // On focus out if min max criteria is not met it wil clear out
  dobFocusOut(value: Date, minMaxDate: { minDate: Date, maxDate: Date }): boolean {
    let resetStatus: boolean = false;
    // If the value is not null
    if (this.isEmptyOrNull(value)) {
      resetStatus = true;
    }
    // If not null check min max date criteria
    else {
      let updatedValue = typeof (value) == 'string' ? new Date(value) : value;
      updatedValue.setHours(0, 0, 0, 0);
      minMaxDate.maxDate.setHours(0, 0, 0, 0);
      minMaxDate.minDate.setHours(0, 0, 0, 0);
      if (value < minMaxDate.minDate || value > minMaxDate.maxDate) {
        resetStatus = true;
      }
    }
    return resetStatus;
  }

  redirectionToUploadDocument(htNumber?) {
    let QcFlow = this.storageService.getAESDecryptedData(userEnums.QcFlow);
    let navUrl = QcFlow && (htNumber?.toUpperCase().includes('NYSA') || this.isEmptyOrNull(htNumber)) ? routeEnums.DocumentUpload : routeEnums.OldDocumentUpload;
    this.router.navigate([navUrl]);
  }
}