<form [formGroup]="goldenShieldForm">
    <!-- Product Details Card Starts -->

    <div class="">
        <mat-card class="innerbox borderinnerbox marginbox">
            <h3>Product Name & Type</h3>
            <div class="fieldsdiv">
                <div class="row">

                    <div class="col-sm-3 form-group order-1 order-sm-1 mb0">
                        <mat-label class="input-group__label" for="myInput"> Product name</mat-label>
                        <input type="text" matInput class="input-group__input health planselect"
                            placeholder="Enter product" type="text" formControlName="productName" id="productName"
                            readonly autocomplete="off">

                    </div>

                    <div class="col-sm-2 form-group mt-4 order-2 order-sm-2 mb0">
                        <!-- <div class="Custom_group_btn2">

                            <input type="radio" class="Cstmradio__input2" value="New" name="productType" id="myradio8"
                                formControlName="productType" checked (change)="changeInProductType()">
                            <label class="Cstmradio__label2" for="myradio8">New</label>
                            <input type="radio" class="Cstmradio__input2" value="Portability" name="productType"
                                id="myradio9" formControlName="productType" (change)="changeInProductType()">
                            <label class="Cstmradio__label2" for="myradio9">Portability</label>
                        </div> -->

                        <div class="productTypeDiv">
                            <mat-button-toggle-group class="custom-toggle-group" formControlName="productType"
                                name="productType" (click)="changeInProductType()">
                                <mat-button-toggle class="custom_togglebtn"
                                    *ngFor="let option of hapDataModal.productType" [value]="option">
                                    {{option}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>


                    <div class="col-sm-3 form-group order-3 order-sm-3">
                        <mat-label class="input-group__label" for="myInput">PIN code</mat-label>
                        <input type="text" matInput class="input-group__input" placeholder="Enter pincode" type="text"
                            formControlName="pincode" id="pincode" maxlength="6" minlength="6"
                            (input)="fetchDataAsPerPincode($event,null)" autocomplete="off"> 

                        <mat-error *ngIf="hasError('pincode', 'required') && formSubmitted">
                            Pincode is required
                        </mat-error>

                        <mat-error *ngIf="(hasError('pincode','maxlength') || hasError('pincode','minlength')) && formSubmitted">
                            Atleast 6 digit pincode is required
                        </mat-error>

                        <mat-error *ngIf="!hasError('pincode','minlength') && hasError('pincode','pattern')  && formSubmitted">
                            Please enter only numbers
                        </mat-error>

                        <mat-error *ngIf="invalidPinCodeMessage!== ''">
                            {{invalidPinCodeMessage}}
                        </mat-error>
                    </div>

                    <div class="col-xl-3 col-md-4 col-sm-3 form-group order-4 order-sm-4 stateCititeswidth"
                        *ngIf="this.stateFetched">
                        <mat-label class="input-group__label" for="myInput">State & Cities</mat-label>
                        <input type="text" matInput class="input-group__input" placeholder="Enter state" type="text"
                            formControlName="cityState" readonly *ngIf="cityData.length == 1" autocomplete="off">

                        <mat-select formControlName="cityState" id="cityState" required
                            class="input-group__input  cstm_select" placeholder="Select state and city"
                            (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                            (selectionChange)="changeCityName($event.value,true)" *ngIf="cityData.length > 1">
                            <ng-container *ngFor="let item of cityData; let last = last">
                                <mat-option value={{item.value}}>
                                    {{item.stateLabel}} - {{item.cityLabel}}
                                </mat-option>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="hasError('cityState', 'required') && formSubmitted">
                            Please select atleast one option
                        </mat-error>
                    </div>

                    <!-- Zone Upgrade-->
                    <div class="col-sm-3 form-group order-5 order-sm-5">
                        <label class="singlelabel emiToggle mb-0" style="width: 100%;">Do you want to upgrade zone?</label>
                        <div class="group_btn">
                            <mat-button-toggle-group formControlName="zone" name="fontStyle" aria-label="Font Style"
                                class="custom-toggle-group">
                                <mat-button-toggle class="custom_togglebtn" *ngFor="let option of hapDataModal.options"
                                    [value]="option">
                                    {{ option }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <!-- Product Details Card Ends -->

    <!-- Member Detail Card Starts -->
    <mat-card class="innerbox borderinnerbox pt-2">
        <h3>Member Details</h3>
        <div class="fieldsdiv">
            <div class="row">

                <!-- Adult Panel -->
                <div class="col-sm-12 form-group">
                    <div class="memberbtnrefilling">
                        <button class="refilingbtn" *ngFor="let memberData of gsDataModal.memberButtonData"
                            (click)="memberTabClicked($event)">
                            <!-- Member Icons -->
                            <div class="refilimg">
                                <span>
                                    <img src="{{memberData.imgSrc}}" alt="{{memberData.label}}">
                                </span>
                                <strong>{{memberData.label}}</strong>
                            </div>

                            <!-- Increment Decryment member operations -->
                            <div class="Numberbtn" *ngIf="memberData.incDec" (click)="stopPropogation($event)">
                                <span (click)="decrymentMember()">
                                    <img src="{{memberData.minusImgSrc}}" alt="minus">
                                </span>
                                <!-- Member Count -->
                                <strong>{{adultValue}}</strong>

                                <span (click)="incrementMember()">
                                    <img src="{{memberData.plusImgSrc}}" alt="plus">
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <h3 *ngIf="adultDetails.length > 0">Adults</h3>
        <!-- Adult fields Construction -->
        <div class="fieldsdiv" *ngIf="adultDetails.length > 0" formArrayName="adultDetails">
            <div class="row" *ngFor="let data of adultDetails.controls; let i = index" [formGroupName]="i">
                <div class="col-sm-12">
                    <span class="subheading">Adult {{i+1}}</span>
                </div>
                <div class="col-sm-3">
                    <mat-label class="input-group__label">DOB</mat-label>
                    <input class="input-group__input dob" matInput [matDatepicker]="adultDOB"
                        [formControlName]="'dobAdult' + (i+1)" id="adultDetails"
                        [max]="getInsuredMinMaxDate('Adult','max')" [min]="getInsuredMinMaxDate('Adult','min')"
                        (click)="adultDOB.open()" placeholder="DD/MM/YYYY" readonly
                        (dateInput)="adultsDateComparison()">
                    <mat-datepicker-toggle matSuffix [for]="adultDOB">
                    </mat-datepicker-toggle>
                    <mat-datepicker #adultDOB touchUi>
                    </mat-datepicker>
                    <mat-error class="error-margin"
                        *ngIf="adultDetails.value[i]['dobAdult' + (i+1)] == '' && formSubmitted">
                        Adult {{i+1}} DOB is required
                    </mat-error>
                    <mat-error class="error-margin" *ngIf="adult2DateError">
                        Adult2 age can not be greater than Adult1
                    </mat-error>
                </div>

                <div class="col-sm-3 form-group">
                    <mat-label class="input-group__label" for="myInput">Relationship</mat-label>
                    <mat-select class="input-group__input cstm_select" formControlName="{{'adultRealtionship' + (i+1)}}"
                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()" id="adultDetails"
                        required placeholder="Select Relationship">
                        <ng-container *ngFor="let item of adultRelationShips; let last = last">
                            <mat-option value="{{item.RelationshipName}}">
                                {{item.RelationshipName}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="adultDetails.value[i]['adultRealtionship' + (i+1)] == '' && formSubmitted">
                        Adult {{i+1}} Relationship is required
                    </mat-error>
                </div>

                <div class="col-sm-3 form-group">
                    <label class="singlelabel vaccineLabel mb-0">Pneumococcal vaccine taken in last 1 year?</label>
                    <div class="group_btn">
                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="custom-toggle-group"
                            formControlName="{{'adultVaccinationStatus' + (i+1)}}">
                            <mat-button-toggle class="custom_togglebtn" (change)="vaccinationDateValidation(i)"
                                *ngFor="let option of hapDataModal.options" [value]="option">
                                {{ option }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <div class="col-sm-3 form-group">
                    <div class="vaccineMr" *ngIf="adultDetails.value[i]['adultVaccinationStatus' + (i+1)] == 'Yes'">
                        <label class="input-group__label" style="top: 13px;" for="myInput">Date of Vaccination</label>
                        <!-- mat date picker starts here -->
                        <input class="input-group__input dob" matInput [matDatepicker]="vaccinationDate"
                            formControlName="{{'adultVaccinationDate' + (i+1)}}" id="adultDetails"
                            placeholder="DD/MM/YYYY" [max]="getMinMaxDateVaccine('max')"
                            [min]="getMinMaxDateVaccine('min')" (click)="vaccinationDate.open()" readonly>
                        <mat-datepicker-toggle matSuffix [for]="vaccinationDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #vaccinationDate touchUi>
                        </mat-datepicker>
                        <mat-error class="error-margin"
                            *ngIf="adultDetails.value[i]['adultVaccinationDate' + (i+1)] == '' && formSubmitted">
                            Adult {{i+1}} Vaccination Date is required
                        </mat-error>
                        <!-- mat date picker ends here -->
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>


        <!-- Premium Details Seection Starts -->
        <h3>Premium Details</h3>
        <div class="fieldsdiv ">
            <div class="row ">
                <!-- Policy Tenure -->
                <div class="col-sm-3 col-lg-3 policyTenureWidth">
                    <!-- Policy Tenure -->
                    <label for="" class="singlelabel mb-2" style="width:100%">Policy Tenure</label>
                    <div class="policyTenureDiv"><br />
                        <mat-button-toggle-group formControlName="policyTenure" name="fontStyle" aria-label="Font Style"
                            class="custom-toggle-group">
                            <mat-button-toggle class="custom_togglebtn"
                                *ngFor="let tenureData of hapDataModal.tenurePeriod; let j = index"
                                [value]="tenureData">
                                {{ tenureData }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <mat-error
                            *ngIf="goldenShieldForm.controls['policyTenure']?.errors?.['required'] && formSubmitted">
                            Policy Tenure is required
                        </mat-error>
                    </div>
                </div>

                <!-- Annual Sum Insured -->
                <div class="col-sm-3 form-group appIncome">
                    <label class="input-group__label" style="top: 13px;">Sum Insured</label>
                    <mat-select class="input-group__input cstm_select" placeholder="Select applicant sum insured"
                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                        formControlName="applicantAnnualSum" id="applicantAnnualSum"
                        (selectionChange)="onSelectingSumInsured($event.value)" required>
                        <ng-container *ngFor="let data of sumInsuedDetails; let last = last">
                            <mat-option value="{{data.SumAmount}}">{{utilityService.easyAmountReader(data.SumAmount)}}
                            </mat-option>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </ng-container>
                    </mat-select>
                    <mat-error class="error-margin"
                        *ngIf="goldenShieldForm.controls['applicantAnnualSum']?.errors?.['required'] && formSubmitted">
                        Sum Insured is required
                    </mat-error>
                </div>
            </div>
        </div>

    </mat-card>
    <!-- Member Detail Card Ends -->

    <!-- Additional Details Start -->
    <mat-card class="innerbox borderinnerbox">
        <h3>Additional Details</h3>
        <div class="fieldsdiv">
            <div class="row mt-2">
                <div class="col-sm-12 customaddons pedAddons">
                    <ul>
                        <li>
                            <table class="waitingTable">
                                <caption class="hidden-caption">Details</caption>
                                <th class="hidden-caption">Details</th>
                                <tr>
                                    <td>
                                        <span>
                                            <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                        </span>

                                    </td>
                                    <td>
                                        <label for="" class="singlelabel">Base Co-payment of 50% will be applicable with
                                            an option of buy
                                            back of up to 20% Co-payment with additional premium</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                        </span>

                                    </td>
                                    <td>
                                        <label for="" class="singlelabel">Lifetime discount of 2.5% if all the insurers
                                            in the policy have
                                            taken pneumococcal vaccine in last 12 months</label>
                                    </td>
                                </tr>
                            </table>
                        </li>
                    </ul>
                    <span> <label for="" class="waitinglabel">Waiting period & listed illnesses / hypertension, diabetes
                            and
                            cardiac conditions duration</label>
                    </span>
                    <ul>
                        <li>
                            <table class="waitingTable">
                                <caption class="hidden-caption">Details</caption>
                                <th class="hidden-caption">Details</th>
                                <tr>
                                    <td>
                                        <span>
                                            <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                        </span>

                                    </td>
                                    <td>
                                        <span class="waitingperiodSpan">General waiting period is 30 days</span>
                                    </td>
                                </tr>
                            </table>

                        </li>
                        <li>
                            <table class="waitingTable">
                                <caption class="hidden-caption">Details</caption>
                                <th class="hidden-caption">Details</th>
                                <tr>
                                    <td>
                                        <span>
                                            <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                        </span>

                                    </td>
                                    <td>
                                        <span class="waitingperiodSpan">PED waiting period is 2 years</span>
                                    </td>
                                </tr>
                            </table>
                        </li>
                        <li>
                            <table class="waitingTable">
                                <caption class="hidden-caption">Details</caption>
                                <th class="hidden-caption">Details</th>
                                <tr>
                                    <td>
                                        <span>
                                            <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                        </span>

                                    </td>
                                    <td>
                                        <span class="waitingperiodSpan">Waiting period for listed illnesses is 2
                                            year</span>
                                    </td>
                                </tr>
                            </table>
                        </li>
                        <li>
                            <table class="waitingTable">
                                <caption class="hidden-caption">Details</caption>
                                <th class="hidden-caption">Details</th>
                                <tr>
                                    <td>
                                        <span>
                                            <img src="../../../../assets/images/miniTick.svg" alt="" class="miniTick">
                                        </span>

                                    </td>
                                    <td>
                                        <span class="waitingperiodSpan">Waiting period for hypertension, diabetes and
                                            cardiac
                                            conditions (unless PED) is 90 days</span>
                                    </td>
                                </tr>
                            </table>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </mat-card>
    <!-- Additional Details End -->

    <!-- Additional Covers Start -->
    <mat-card class="innerbox borderinnerbox" *ngIf="this.goldenShieldForm.controls['applicantAnnualSum'].value != ''">
        <h3>Additional Cover</h3>

        <!-- Non member based addons -->
        <div class="fieldsdiv mt-1 mb-2">
            <div class="row">
                <div class="col-sm-12 customCheckslist">
                    <ul>
                        <li *ngFor="let data of addonCover">
                            <ng-container *ngIf="data.CoverId == 30; else otherAddon">
                                <mat-checkbox class="cstmCheckbox" [(ngModel)]='makeChecked' [disableRipple]="true"
                                    (click)="$event.preventDefault()"
                                    formControlName="{{data.CoverCode}}"></mat-checkbox>
                            </ng-container>

                            <ng-template #otherAddon>
                                <mat-checkbox class="cstmCheckbox"
                                    (change)="checkboxChanged($event,data.CoverId, addonCover, 'addonCover',0)"
                                    formControlName="{{data.CoverCode}}"></mat-checkbox>
                            </ng-template>

                            <label for="cstmCheckbox"> {{data.CoverText}}</label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>
        <!-- Voluntary Deductible addons -->
        <div class="fieldsdiv mb-2 mt-2" *ngIf="!vdAddonCoverDisable">
            <div class="row">
                <div class="col-sm-12 customCheckslist">
                    <ul>
                        <li *ngFor="let data of vdAddonCover">
                            <mat-checkbox class="cstmCheckbox" [checked]="checkbox1"
                                (change)="checkboxChanged($event,data.CoverId, vdAddonCover, 'vdAddonCover',1)"
                                formControlName="{{data.CoverCode}}"></mat-checkbox>
                            <label for="cstmCheckbox"> {{data.CoverText}}</label>
                        </li>
                        <li class="ml-3" *ngIf="VDValueEnable">
                            <label class="input-group__label">VD Values</label>
                            <input class="input-group__input" matInput type="text" readonly
                                value={{utilityService.easyAmountReader(VDValue)}} autocomplete="off">
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>
        <!-- co payment addons -->
        <div class="fieldsdiv mb-2 mt-2">
            <div class="row">
                <div class="col-sm-12 customCheckslist">
                    <ul>
                        <li *ngFor="let data of CoPaymentAddonCover">
                            <mat-checkbox class="cstmCheckbox" [checked]="checkbox2"
                                (change)="checkboxChanged($event,data.CoverId, CoPaymentAddonCover, 'CoPaymentAddonCover',2)"
                                formControlName="{{data.CoverCode}}"></mat-checkbox>
                            <label for="cstmCheckbox"> {{data.CoverText}}</label>
                        </li>
                        <li class="ml-3" *ngIf="CoPayment">
                            <mat-slider class="slider-container" step="10" min="20" max="40"
                                (change)="onChangeSliderValue($event)">
                                <input formControlName="sliderControl" matSliderThumb>
                                <div class="slider-ticks">
                                    <div class="slider-tick" *ngFor="let option of hapDataModal.stepArray">
                                        {{ option }}
                                    </div>
                                </div>
                            </mat-slider>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </mat-card>
    <!-- Additional Covers Ends -->
</form>

<app-error-screen *ngIf="multipleServiceError" (refreshService)="onRefresh($event)">
</app-error-screen>

<app-custom-footer *ngIf="!multipleServiceError" [normalButton]="true"
    [buttonData]="[{'label': 'Generate Quote', 'type': 'primary', 'source': 'quote', 'id': 'f1','disabled': false , 'hide': false}]"
    (buttonClicked)="getQuote($event)">
</app-custom-footer>