import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericService } from '../services/generic.service';
import { FetchCustomerDetailsV1RequestPayload, FetchCustomerDetailsV1ResponsePayload, SearchCustomerRequestPayload, SearchCustomerResponsePayload, SaveEditCustomerRequestPayload, SaveEditCustomerResponsePayload, FetchExistingOrionCustomerRequestPayload, SearchIntermediaryResponsePayload } from '../interface/customer';
import { StorageService } from '../services/storage.service';
import { userEnums } from '../enums/userEnums';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private generic: GenericService, private storageService: StorageService) {}
  
  readonly baseURI = `${environment.baseURL}Customer/`;
  readonly OrionBaseURI = `${environment.baseURL}BancsCustomer/`;
  readonly editCustomer = 'SaveEditCustomer';
  readonly searchCustomer = 'SearchCustomer';
  readonly fetchCustomerDetails = 'FetchCustomerDetailsV1';
  readonly fetchExistingOrionCustomerDetails = 'ExistingKYCCustomerDetails';
  readonly SearchIntermediary = 'SearchIntermediary';

  getParentURI(): string {
    return this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'true' ? this.OrionBaseURI : this.baseURI;
  }

  saveEditCustomer(data: SaveEditCustomerRequestPayload): Observable<SaveEditCustomerResponsePayload> {
    const endpoint = `${this.baseURI}${this.editCustomer}`;
    return this.generic.genericService<SaveEditCustomerResponsePayload>(endpoint, data);
  }

  searchCustomerData(data: SearchCustomerRequestPayload): Observable<SearchCustomerResponsePayload> {
    const endpoint = `${this.getParentURI()}${this.searchCustomer}`;
    return this.generic.genericService<SearchCustomerResponsePayload>(endpoint, data);
  }

  fetchCustomerDetailsV1(data: FetchCustomerDetailsV1RequestPayload | FetchExistingOrionCustomerRequestPayload): Observable<FetchCustomerDetailsV1ResponsePayload> {
    let uri = this.storageService.getAESDecryptedData(userEnums.IsOrionJourney) == 'true' ? this.fetchExistingOrionCustomerDetails : this.fetchCustomerDetails;
    const endpoint = `${this.getParentURI()}${uri}`;
    return this.generic.genericService<FetchCustomerDetailsV1ResponsePayload>(endpoint, data);
  }

  SearchIntermediaryData():Observable<SearchIntermediaryResponsePayload>{
    const endpoint = `${this.baseURI}${this.SearchIntermediary}`;
    return this.generic.genericService<SearchIntermediaryResponsePayload>(endpoint);
  }
}