<form [formGroup]="familyShieldForm">
    <!-- Product Details Card Starts -->

    <mat-card class="innerbox borderinnerbox producttype marginbox">
        <h3>Product Name</h3>
        <div class="fieldsdiv">
            <div class="row">
                <div class="col-sm-3 form-group order-1 order-sm-1 mb0">
                    <mat-label class="input-group__label" for="myInput"> Product
                        name</mat-label>

                    <input type="text" matInput class="input-group__input fam_health planselect"
                        placeholder="Enter product" type="text" formControlName="Product" id="Product" readonly
                        autocomplete="off">

                </div>
                <div class="col-sm-3 form-group order-3 order-sm-3 mwidth">
                    <mat-label class="input-group__label" for="myInput">PIN
                        code</mat-label>
                    <input matInput class="input-group__input" placeholder="Enter pincode" type="text"
                        formControlName="Pincode" id="Pincode" [minlength]="6" [maxlength]="6"
                        (input)="fetchDataAsPerPincode($event,null)" autocomplete="off">

                    <mat-error *ngIf="hasError('Pincode', 'required') && formSubmitted">
                        Pincode is required
                    </mat-error>

                    <mat-error
                        *ngIf="(hasError('Pincode','maxlength') || hasError('Pincode','minlength')) && formSubmitted">
                        Atleast 6 digit pincode is required
                    </mat-error>

                    <mat-error
                        *ngIf="!hasError('Pincode','minlength') && hasError('Pincode','pattern')  && formSubmitted">
                        Please enter only numbers
                    </mat-error>

                    <mat-error *ngIf="invalidPinCodeMessage!== ''">
                        {{invalidPinCodeMessage}}
                    </mat-error>

                </div>

                <div class="col-sm-3 form-group order-3 order-sm-3 stateCititeswidth" *ngIf="this.stateFetched">
                    <mat-label class="input-group__label" for="myInput">State & Cities</mat-label>
                    <!-- If length is 1 -->
                    <input type="text" matInput class="input-group__input" placeholder="Enter pincode" type="text"
                        formControlName="CityState" readonly *ngIf="cityData.length == 1" autocomplete="off">


                    <mat-select formControlName="CityState" id="CityState" required
                        class="input-group__input  cstm_select" placeholder="Select state and city"
                        (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                        (selectionChange)="changeCityName($event.value,true)" *ngIf="cityData.length > 1">
                        <mat-option value={{item.value}} *ngFor="let item of cityData" selected>
                            {{item.stateLabel}} - {{item.cityLabel}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasError('CityState', 'required') && formSubmitted">
                        Please select atleast one option
                    </mat-error>
                </div>
            </div>
        </div>
    </mat-card>

    <!-- Member Detail Card Starts -->
    <mat-card class="innerbox borderinnerbox">

        <h3>Member Details</h3>
        <div class="">
            <div class="row fieldsdiv">

                <!-- Member Panel -->
                <div class="col-sm-6 form-group">
                    <label for="" class="singlelabel" style="width: 100%;">Select Adult</label>
                    <div class="memberbtnrefilling">
                        <button class="refilingbtn" *ngFor="let memberData of fsDataModal.memberButtonData"
                            (click)="memberTabClicked($event)">
                            <div class="refilimg">
                                <span><img src="{{memberData.imgSrc}}" alt="{{memberData.label}}"></span>
                                <strong>{{memberData.label}}</strong>
                            </div>
                            <div class="Numberbtn">
                                <span (click)="removeMember()">
                                    <img src="{{memberData.minusImgSrc}}" alt="minus">
                                </span>
                                <strong>{{adultCount}}</strong>
                                <span (click)="addMember()">
                                    <img src="{{memberData.plusImgSrc}}" alt="plus">
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <!-- {{familyShieldForm.controls['MemberDetails'].value|json}} -->
            <h3 *ngIf="memberDetails.length > 0">Adults</h3>
            <div formArrayName="MemberDetails" *ngFor="let data of memberDetails.controls; let j = index">
                <div [formGroupName]="j">
                    <div class="fieldsdiv "
                        *ngIf="memberDetails.length > 0 && OccupancyType!= undefined && OccupancyType!=null">
                        <div class="row">
                            <div class="col-sm-12 form-group mb-0">
                                <span class="subheading">Adult {{j+1}}</span>
                            </div>
                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label">DOB</mat-label>
                                <input class="input-group__input dob" matInput [matDatepicker]="adultDOB"
                                    [formControlName]="'InsuredDOB'" id="MemberDetails"
                                    [max]="getInsuredMinMaxDate('Adult','max')"
                                    [min]="getInsuredMinMaxDate('Adult','min')" (click)="adultDOB.open()"
                                    placeholder="DD/MM/YYYY" readonly>
                                <mat-datepicker-toggle matSuffix [for]="adultDOB">
                                </mat-datepicker-toggle>
                                <mat-datepicker #adultDOB touchUi>
                                </mat-datepicker>
                                <mat-error class="error-margin"
                                    *ngIf="memberDetails.value[j]['InsuredDOB'] == '' && formSubmitted">
                                    Adult {{j+1}} DOB is required
                                </mat-error>
                            </div>
                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label">Occupation of Insured</mat-label>
                                <mat-select [formControlName]="'OccupationType'" id="MemberDetails" required
                                    class="input-group__input  cstm_select" placeholder="Select Occupation"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                    (selectionChange)="getShieldOccupationType($event,j)"
                                    *ngIf="OccupancyType.length > 1">
                                    <mat-option value={{item.Name}} *ngFor="let item of OccupancyType" selected>
                                        {{item.Name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error class="error-margin"
                                    *ngIf="memberDetails.value[j]['OccupationType'] =='' && formSubmitted">
                                    Occupation type is required
                                </mat-error>
                            </div>
                            <div class="col-sm-3 form-group"
                                *ngIf="familyShieldForm.controls['MemberDetails'].value[j].OccupationType != 'No Active Source Of Income'&& familyShieldForm.controls['MemberDetails'].value[j].OccupationType != ''">
                                <mat-label class="input-group__label">Annual Income in ₹ of Insured</mat-label>
                                <mat-select [formControlName]="'IncomeSlab'" id="MemberDetails" required
                                    class="input-group__input  cstm_select" placeholder="Select Annual Income"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()"
                                    (selectionChange)="getShieldOccupation($event,j,'riskCoverCall')"
                                    *ngIf="AnnualIncome.length > 1">
                                    <mat-option value={{item.Name}} *ngFor="let item of AnnualIncome" selected>
                                        {{item.Name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error class="error-margin"
                                    *ngIf="memberDetails.value[j]['OccupationType'] !='No Active Source Of Income'  && memberDetails.value[j]['IncomeSlab'] ==''">
                                    Annual Income is required
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <h3>Premium Details</h3>
        <div class="fieldsdiv ">
            <div class="row ">
                <!-- Policy Tenure -->
                <div class="col-sm-6 col-lg-4 form-group">
                    <!-- Policy Tenure -->
                    <label for="" class="singlelabel mb-2" style="width:100%">Policy Tenure</label>
                    <div class="group_btn"><br />
                        <mat-button-toggle-group formControlName="Tenure" name="fontStyle" aria-label="Font Style"
                            class="policyToggle_group">
                            <mat-button-toggle class="PolicyBtn"
                                *ngFor="let tenureData of this.fsDataModal.tenurePeriod; let j = index"
                                [value]="tenureData.id" (change)="changePolicyTenure(tenureData)">
                                {{ tenureData.value }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                        <mat-error *ngIf="familyShieldForm.controls['Tenure']?.errors?.['required'] && formSubmitted">
                            Policy Tenure is required
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <!-- {{familyShieldForm.controls['CoverDetails'].value|json}} -->

    <mat-tab-group class="tabInnerdiv marginbox" (selectedTabChange)="memberTypeSelected($event)">

        <!-- Creation of tabs -->
        <mat-tab class="" *ngFor="let tabData of tabGroup">
            <ng-template mat-tab-label>
                {{tabData.tabLabel}}
            </ng-template>

            <div formArrayName="CoverDetails" *ngFor="let data of coverDetails.controls; let k = index">
                <div *ngIf="tabIndex===k">
                    <h3>Risk Category</h3>
                    <div class="informationdiv">
                        <span (click)="openRiskPopup()">
                            <img src="../../../assets/images/Waiting Period.svg" alt="">
                        </span>
                        <span>View your risk category</span>
                    </div>
                    <div>
                        <div [formGroupName]="k">
                            <!-- {{familyShieldForm.controls['CoverDetails'].value|json}} -->

                            <div class="fieldsdiv">
                                <div class="row">
                                    <div class="container CritiecoverDiv">
                                        <div class="categoryScrolldiv">
                                            <div class="category-radio mb-2">
                                                <ul>
                                                    <li *ngFor="let category of riskCategory;let i=index">
                                                        <input type="radio" [id]="'category_'+(i+1)"
                                                            (change)="getShieldOccupation(familyShieldForm.controls['MemberDetails'].value[k].IncomeSlab,k,'riskCoverCall')"
                                                            name="category" formControlName="RiskCategory"
                                                            value='{{category.CategoryId}}'>
                                                        <label [for]="'category_'+(i+1)" id="">
                                                            <i><img src="../../../assets/images/Risk Category.svg"
                                                                    alt=""> </i>Category
                                                            {{i+1}}</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div
                                *ngIf="isEnableCoverPart[k] && this.familyShieldForm.controls['CoverDetails'].value[k].RiskCategory != ''">
                                <h3>Additional Covers</h3>
                                <div class="fieldsdiv webView">
                                    <div class="row">
                                        <div class="container renewalDiv">
                                            <div class="cover-radioDiv ">
                                                <div class="scrollable">
                                                    <ul class="sumInsRadio">
                                                        <li
                                                            (click)="selectedCover(this.bucketDetails[0].coverDetails,0,bucketDetails[0].bucketName,k)">
                                                            <input type="radio" id="pickSumIns_1lacs" name="pickSumIns"
                                                                checked />
                                                            <label for="pickSumIns_1lacs" class="">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="54"
                                                                    height="54" viewBox="0 0 54 54">
                                                                    <g transform="translate(-1806 -2702)">
                                                                        <g transform="translate(1806 2702)" fill="#fff"
                                                                            stroke="#1e1f20" stroke-width="1"
                                                                            class="tabColor">
                                                                            <circle cx="27" cy="27" r="27"
                                                                                stroke="none" />
                                                                            <circle cx="27" cy="27" r="26.5"
                                                                                fill="none" />
                                                                        </g>
                                                                        <g transform="translate(1688.698 2713.271)">
                                                                            <g transform="translate(137.483 0)">
                                                                                <path
                                                                                    d="M140.5,93.03l2.127.726,2.007-5.75H140.46a2.963,2.963,0,0,0-2.956,2.942s-.021,4.2-.021,4.208a1.253,1.253,0,0,0,.845,1.215l.75.256.9-2.635.009,0c.008-1.559,0-.966.015-3.035a.248.248,0,0,1,.5,0Z"
                                                                                    transform="translate(-137.483 -82.002)" />
                                                                                <circle cx="2.589" cy="2.589" r="2.589"
                                                                                    transform="translate(3.748)" />
                                                                                <path
                                                                                    d="M217.407,201.389h0a1.249,1.249,0,0,0,.808-2.365Z"
                                                                                    transform="translate(-211.955 -185.447)" />
                                                                                <path
                                                                                    d="M191.323,90.949a2.963,2.963,0,0,0-2.956-2.942h-1.1L185.1,94.2l.267.091a2.614,2.614,0,0,1-1.689,4.947l-2-.682s0,12.683,0,13.408a1.5,1.5,0,1,0,3,0V100.308a.329.329,0,0,1,.329-.329.319.319,0,0,1,.319.319v11.664a1.5,1.5,0,1,0,3,0L188.3,90.94a.261.261,0,0,1,.523,0c0,.007,0,.015,0,.022l.046,9.178a1.249,1.249,0,0,0,1.249,1.243h.006a1.249,1.249,0,0,0,1.243-1.256Z"
                                                                                    transform="translate(-178.669 -82.003)" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                                <span>{{bucketDetails[0].bucketName}}</span>
                                                            </label>
                                                        </li>
                                                        <li
                                                            (click)="selectedCover(this.bucketDetails[1].coverDetails,1,bucketDetails[1].bucketName,k)">
                                                            <input type="radio" id="pickSumIns_2lacs"
                                                                name="pickSumIns" />

                                                            <label for="pickSumIns_2lacs" class="">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="54"
                                                                    height="54" viewBox="0 0 54 54">
                                                                    <g transform="translate(-2071 -2702)">
                                                                        <g transform="translate(2071 2702)" fill="#fff"
                                                                            stroke="#1e1f20" stroke-width="1"
                                                                            class="tabColor">
                                                                            <circle cx="27" cy="27" r="27"
                                                                                stroke="none" />
                                                                            <circle cx="27" cy="27" r="26.5"
                                                                                fill="none" />
                                                                        </g>
                                                                        <g transform="translate(-29 -164)">
                                                                            <g transform="translate(2111 2884)">
                                                                                <path
                                                                                    d="M.439-407.785a1.122,1.122,0,0,0-.317.317c-.122.2-.122.323-.122,8.287,0,7.775.006,8.1.116,8.3.2.384.409.458,1.4.476l.867.024v.336c0,.287.024.36.177.513l.177.177H5.907l.189-.159c.171-.14.189-.189.189-.519v-.36H25.142v.36c0,.33.018.378.189.519l.189.159h3.173l.177-.177c.153-.153.177-.226.177-.513v-.336l.781-.018c.641-.018.812-.043.982-.146.323-.189.433-.464.433-1.056,0-.281-.018-.5-.049-.488s-.207.1-.409.2l-.366.183H11.869l-.409-.2a1.853,1.853,0,0,1-.958-.989c-.153-.323-.159-.378-.153-1.422v-1.08H9.556l-3.924-2-3.924-2v-3.961c0-3.863,0-3.961-.122-4.156A.857.857,0,0,0,.439-407.785Z"
                                                                                    transform="translate(0 407.901)" />
                                                                                <path
                                                                                    d="M61.115-344.765a2.282,2.282,0,0,0-1.587,1.263,2.235,2.235,0,0,0,.458,2.587,2.283,2.283,0,0,0,3.875-1.239A2.29,2.29,0,0,0,62-344.783,1.51,1.51,0,0,0,61.115-344.765Z"
                                                                                    transform="translate(-55.683 348.689)" />
                                                                                <path
                                                                                    d="M135.981-327.266a1.56,1.56,0,0,0-.879.586,16.943,16.943,0,0,0-.976,2.429c-.4,1.092-.372,1.562.1,2.105a1.811,1.811,0,0,0,.427.342c.2.1,1.5.61,1.562.61.012,0,.024-.043.024-.1s.073-.415.159-.8c.14-.647.146-.708.055-.848-.049-.079-.079-.146-.055-.146s.751.69,1.617,1.538c1.666,1.617,1.788,1.721,2.13,1.819.238.067.4.037,3.234-.586,1.544-.342,1.831-.421,2.014-.568a1.024,1.024,0,0,0,.439-.854,1.07,1.07,0,0,0-1.05-1.1c-.153,0-1.117.183-2.148.4l-1.873.409-.384-.342c-.214-.2-.958-.9-1.66-1.587L137.44-325.2l.269-.488c.14-.262.323-.58.4-.7a.873.873,0,0,0,.14-.281,7.035,7.035,0,0,0-1.739-.647A1.614,1.614,0,0,0,135.981-327.266Z"
                                                                                    transform="translate(-125.681 332.234)" />
                                                                                <path
                                                                                    d="M196.409-321.417c-1.141.067-1.422.116-1.623.287a9.267,9.267,0,0,0-1.172,1.77l-.22.433,1.141.647,1.141.641,1.648-.372c2.008-.452,2.471-.482,3.051-.214a2.166,2.166,0,0,1,.708,3.472c-.421.464-.677.549-3.271,1.123-2.612.574-2.911.6-3.515.342-.452-.2-.708-.476-1.33-1.416l-.58-.885-.079.458a13.137,13.137,0,0,0-.079,2.868c.165.391-.458.366,9.367.366,9.776,0,9.215.018,9.355-.348.037-.092.165-.787.281-1.544a7.316,7.316,0,0,0,.128-2.838,4.071,4.071,0,0,0-2.081-2.594,44.107,44.107,0,0,0-5.919-1.55A29.278,29.278,0,0,0,196.409-321.417Z"
                                                                                    transform="translate(-180.451 326.72)" />
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                                <span>{{bucketDetails[1].bucketName}}</span>
                                                            </label>
                                                        </li>
                                                        <li
                                                            (click)="selectedCover(this.bucketDetails[2].coverDetails,2,bucketDetails[2].bucketName,k)">
                                                            <input type="radio" id="pickSumIns_10lacs"
                                                                name="pickSumIns" />

                                                            <label for="pickSumIns_10lacs" class="">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="54"
                                                                    height="54" viewBox="0 0 54 54">
                                                                    <g transform="translate(-2339 -2702)">
                                                                        <g transform="translate(2339 2702)" fill="#fff"
                                                                            stroke="#1e1f20" stroke-width="1"
                                                                            class="tabColor">
                                                                            <circle cx="27" cy="27" r="27"
                                                                                stroke="none" />
                                                                            <circle cx="27" cy="27" r="26.5"
                                                                                fill="none" />
                                                                        </g>
                                                                        <g transform="translate(707.39 -1655.687)">
                                                                            <path
                                                                                d="M22.211,40.622a2.357,2.357,0,0,1-.634-.1,19.351,19.351,0,0,1-13.23-18.36V14.874a1.981,1.981,0,0,1,1.089-1.763L21.32,7.169a1.981,1.981,0,0,1,1.783,0l11.884,5.942a1.981,1.981,0,0,1,1.089,1.763v7.289a19.351,19.351,0,0,1-13.23,18.459A2.358,2.358,0,0,1,22.211,40.622Zm-9.9-24.52v6.061a15.39,15.39,0,0,0,9.9,14.38,15.389,15.389,0,0,0,9.9-14.38V16.1l-9.9-4.952Z"
                                                                                transform="translate(1636.399 4360.887)"
                                                                                fill="#1e1f20" />
                                                                        </g>
                                                                        <path
                                                                            d="M10.323,42.564a.258.258,0,0,1,.258-.258h1.875a.258.258,0,0,1,.258.258v4.515a.258.258,0,0,1-.258.258H10.581a.258.258,0,0,1-.258-.258ZM9.55,46.542l-.032.009-3.5,1.244a1.564,1.564,0,0,1-1.359-.124L.234,45.123a.529.529,0,0,1,.456-.946h0l3.494,1.133.009,0a1.067,1.067,0,0,0,.4.078H7.183a.212.212,0,1,0,0-.424H4.6a.646.646,0,1,1,0-1.292H5.9a.662.662,0,0,0,.466-.184l.472-.441a.242.242,0,0,1,.176-.069H9.9V46.5a3.5,3.5,0,0,0-.349.039Z"
                                                                            transform="translate(2359.648 2688.013)"
                                                                            fill="#1e1f20" fill-rule="evenodd" /><text
                                                                            transform="translate(2365.519 2730.091)"
                                                                            fill="#1e1f20" font-size="11"
                                                                            font-family="Mulish-Medium, Mulish"
                                                                            font-weight="500">
                                                                            <tspan x="-2.89" y="0">₹</tspan>
                                                                        </text>
                                                                    </g>
                                                                </svg>
                                                                <span>{{bucketDetails[2].bucketName}}</span>
                                                            </label>
                                                        </li>
                                                        <li
                                                            (click)="selectedCover(this.bucketDetails[3].coverDetails,3,bucketDetails[3].bucketName,k)">
                                                            <input type="radio" id="criticalIllness"
                                                                name="pickSumIns" />

                                                            <label for="criticalIllness" class="">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="54"
                                                                    height="54" viewBox="0 0 54 54">
                                                                    <g transform="translate(-2611 -2702)">
                                                                        <g transform="translate(794 -1163)">
                                                                            <g transform="translate(1817 3865)"
                                                                                fill="#fff" stroke="#1e1f20"
                                                                                stroke-width="1" class="tabColor">
                                                                                <circle cx="27" cy="27" r="27"
                                                                                    stroke="none" />
                                                                                <circle cx="27" cy="27" r="26.5"
                                                                                    fill="none" />
                                                                            </g>
                                                                        </g>
                                                                        <path
                                                                            d="M16.652,42.722a.417.417,0,0,1,.416-.416h3.024a.416.416,0,0,1,.415.416V50a.416.416,0,0,1-.415.417H17.069A.417.417,0,0,1,16.652,50Zm-1.246,6.415a.367.367,0,0,0-.052.014L9.706,51.157a2.522,2.522,0,0,1-2.192-.2L.384,46.849a.853.853,0,0,1,.736-1.526l.005,0,5.634,1.828.015,0a1.72,1.72,0,0,0,.645.125h4.171a.342.342,0,1,0,0-.684H7.419a1.042,1.042,0,1,1,0-2.084H9.527a1.067,1.067,0,0,0,.751-.3l.761-.711a.389.389,0,0,1,.285-.112h4.645v5.679a5.646,5.646,0,0,0-.562.063Z"
                                                                            transform="translate(2627.992 2694.043)"
                                                                            fill="#1e1f20" fill-rule="evenodd" />
                                                                        <g transform="translate(0 1)">
                                                                            <path
                                                                                d="M7.1,1.25h9.8a7.287,7.287,0,0,1,2.306.2,3.8,3.8,0,0,1,2.348,2.411l-.713.234.713-.234a7.905,7.905,0,0,1,.2,2.361V20.374a1.713,1.713,0,0,1-2.864,1.3.2.2,0,0,0-.271,0l-.483.442a2.407,2.407,0,0,1-3.263,0,.907.907,0,0,0-1.237,0,2.407,2.407,0,0,1-3.263,0,.907.907,0,0,0-1.237,0,2.407,2.407,0,0,1-3.263,0l-.483-.442a.2.2,0,0,0-.271,0,1.713,1.713,0,0,1-2.864-1.3v-14q0-.073,0-.144a7.9,7.9,0,0,1,.2-2.361A3.8,3.8,0,0,1,4.793,1.454,7.286,7.286,0,0,1,7.1,1.25Zm.146,1.5a6.874,6.874,0,0,0-1.959.121A2.3,2.3,0,0,0,3.871,4.333,7.521,7.521,0,0,0,3.75,6.37v14a.234.234,0,0,0,.135.227.2.2,0,0,0,.109.018.194.194,0,0,0,.107-.055,1.7,1.7,0,0,1,2.3,0l.483.442a.907.907,0,0,0,1.237,0,2.407,2.407,0,0,1,3.263,0,.907.907,0,0,0,1.237,0,2.407,2.407,0,0,1,3.263,0,.907.907,0,0,0,1.237,0l.483-.442a1.7,1.7,0,0,1,2.3,0,.194.194,0,0,0,.107.055.2.2,0,0,0,.109-.018.234.234,0,0,0,.135-.227v-14a7.519,7.519,0,0,0-.121-2.037,2.3,2.3,0,0,0-1.415-1.462,6.874,6.874,0,0,0-1.959-.121ZM17,6.441a.75.75,0,0,1,.06,1.059l-3.571,4a.75.75,0,0,1-1.119,0L10.941,9.9a.75.75,0,0,1,1.119-1l.869.973L15.941,6.5A.75.75,0,0,1,17,6.441Z"
                                                                                transform="translate(2626.241 2710.75)"
                                                                                fill="#1e1f20" fill-rule="evenodd" />
                                                                        </g>
                                                                        <g transform="translate(2632.928 2716.725)">
                                                                            <path
                                                                                d="M9.785,8.653V9.42H.843V6.672H0v5.5H.843V10.912H9.785v1.361h.84V8.652Z"
                                                                                transform="translate(0 0)" />
                                                                            <path
                                                                                d="M5.4,10.595h0a.452.452,0,0,1-.452.452H3.223a.452.452,0,0,1-.452-.452h0a.452.452,0,0,1,.452-.452H4.947a.452.452,0,0,1,.452.452Z"
                                                                                transform="translate(-1.544 -1.934)" />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                                <span>{{bucketDetails[3].bucketName}}</span>
                                                            </label>
                                                        </li>
                                                        <li
                                                            (click)="selectedCover(this.bucketDetails[4].coverDetails,4,bucketDetails[4].bucketName,k)">
                                                            <input type="radio" id="criticalIllnesss"
                                                                name="pickSumIns" />

                                                            <label for="criticalIllnesss" class="">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="54"
                                                                    height="54" viewBox="0 0 54 54">
                                                                    <g transform="translate(-2871.5 -2700)">
                                                                        <g transform="translate(826.5 -1046)">
                                                                            <g transform="translate(2045 3746)"
                                                                                fill="#fff" stroke="#1e1f20"
                                                                                stroke-width="1" class="tabColor">
                                                                                <circle cx="27" cy="27" r="27"
                                                                                    stroke="none" />
                                                                                <circle cx="27" cy="27" r="26.5"
                                                                                    fill="none" />
                                                                            </g>
                                                                            <g transform="translate(413.39 -611.687)">
                                                                                <path
                                                                                    d="M22.211,40.622a2.357,2.357,0,0,1-.634-.1,19.351,19.351,0,0,1-13.23-18.36V14.874a1.981,1.981,0,0,1,1.089-1.763L21.32,7.169a1.981,1.981,0,0,1,1.783,0l11.884,5.942a1.981,1.981,0,0,1,1.089,1.763v7.289a19.351,19.351,0,0,1-13.23,18.459A2.358,2.358,0,0,1,22.211,40.622Zm-9.9-24.52v6.061a15.391,15.391,0,0,0,9.9,14.381,15.389,15.389,0,0,0,9.9-14.381V16.1l-9.9-4.952Z"
                                                                                    transform="translate(1636.399 4360.887)"
                                                                                    fill="#1e1f20" />
                                                                            </g>
                                                                        </g>
                                                                        <path
                                                                            d="M22.225,6.6a1.377,1.377,0,0,1,.11,1.944l-6.557,7.344a1.377,1.377,0,0,1-2.054,0L11.1,12.951a1.377,1.377,0,0,1,2.054-1.834l1.6,1.787L20.28,6.71A1.377,1.377,0,0,1,22.225,6.6Z"
                                                                            transform="translate(2881.783 2715.701)"
                                                                            fill="#1e1f20" fill-rule="evenodd" />
                                                                    </g>
                                                                </svg>
                                                                <span>{{bucketDetails[4].bucketName}}</span>
                                                            </label>
                                                        </li>
                                                        <li
                                                            (click)="selectedCover(this.bucketDetails[5].coverDetails,5,bucketDetails[5].bucketName,k)">
                                                            <input type="radio" id="criticalIllnessss"
                                                                name="pickSumIns" />

                                                            <label for="criticalIllnessss" class="">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="54"
                                                                    height="54" viewBox="0 0 54 54">
                                                                    <g transform="translate(-3143.5 -2700)">
                                                                        <g transform="translate(1326.5 -1165)">
                                                                            <g transform="translate(1817 3865)"
                                                                                fill="#fff" stroke="#1e1f20"
                                                                                stroke-width="1" class="tabColor">
                                                                                <circle cx="27" cy="27" r="27"
                                                                                    stroke="none" />
                                                                                <circle cx="27" cy="27" r="26.5"
                                                                                    fill="none" />
                                                                            </g>
                                                                        </g>
                                                                        <path
                                                                            d="M24.535,14.718c.3,6.1-1.949,9.705-5.943,11.207-3.857-1.409-6.144-4.855-5.967-11.3A8.53,8.53,0,0,0,18.566,12.8c1.711,1.084,3.893,2.11,5.967,1.922ZM13.262,2.557l4.367,4.091H13.262Zm-9.14,9.42a.5.5,0,0,1,.361-.162h8.829a.492.492,0,0,1,.369.169.61.61,0,0,1,0,.791h0a.6.6,0,0,1-.105.089c-.169.032-.338.057-.506.08H4.492a.5.5,0,0,1-.371-.167.606.606,0,0,1,0-.8Zm0,3.53a.5.5,0,0,1,.369-.171h6.146c.019.386.044.764.078,1.133H4.492A.5.5,0,0,1,4.12,16.3a.606.606,0,0,1,0-.8Zm0,3.528a.5.5,0,0,1,.369-.169h6.572q.12.584.272,1.133H4.492a.492.492,0,0,1-.371-.169.6.6,0,0,1-.148-.4.606.606,0,0,1,.15-.4Zm0-10.587a.5.5,0,0,1,.369-.171H9.555a.489.489,0,0,1,.367.167.6.6,0,0,1,.148.4.606.606,0,0,1-.148.4.5.5,0,0,1-.367.169H4.492a.5.5,0,0,1-.371-.167.6.6,0,0,1-.148-.4.6.6,0,0,1,.15-.4Zm0-3.283a.5.5,0,0,1,.369-.171H7.277a.494.494,0,0,1,.365.165h0a.606.606,0,0,1,0,.8.5.5,0,0,1-.367.169H4.492a.5.5,0,0,1-.371-.167.606.606,0,0,1,0-.8ZM20.06,7.234a.726.726,0,0,0-.53-.694L13.1.268A.715.715,0,0,0,12.534,0H1.308A1.308,1.308,0,0,0,0,1.308V23.685a1.293,1.293,0,0,0,.384.926,1.312,1.312,0,0,0,.924.382h12.6a10.933,10.933,0,0,1-1.072-1.46H1.46V1.454H11.8V7.384a.734.734,0,0,0,.734.732H18.6V10.61q.741.468,1.46.854ZM15.347,18.76l1.141-.015a3.426,3.426,0,0,1,1.148.844c.188-.3.382-.587.582-.865q.333-.464.684-.886c.253-.308.517-.6.787-.882a3.914,3.914,0,0,1,.962-.857.057.057,0,0,1,.027,0h1.249a.074.074,0,0,1,.074.074.07.07,0,0,1-.023.053l-.247.268q-.578.633-1.1,1.3c-.352.445-.688.9-1,1.357s-.614.918-.9,1.388-.553.956-.8,1.443l-.158.3a.076.076,0,0,1-.1.032.093.093,0,0,1-.034-.036l-.143-.308a7.661,7.661,0,0,0-.435-.814,6.779,6.779,0,0,0-.519-.736,6.418,6.418,0,0,0-.612-.656,6.1,6.1,0,0,0-.715-.57.074.074,0,0,1-.027-.084l.091-.3a.076.076,0,0,1,.074-.053Z"
                                                                            transform="translate(3158.219 2714.038)"
                                                                            fill-rule="evenodd" />
                                                                    </g>
                                                                </svg>
                                                                <span>{{bucketDetails[5].bucketName}}</span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="addonsCoversec mb-3">
                                        <!-- {{bucketListWeb|json}} -->
                                        <h2 class="critiHead">Base</h2>
                                        <div *ngFor="let cover of bucketListWeb;let i=index;">
                                            <!-- cover {{cover | json}} -->
                                            <div class="row" *ngIf="shouldShowCoverName4(cover,k,i)">
                                                <div class="col-sm-12  customCheckslist familyaddonsCovers "
                                                    *ngIf="familyShieldForm.controls['CoverDetails'].value[k]['isEnhansement_childern'+cover.CvrId]">
                                                    <ul>
                                                        <li>
                                                            <mat-checkbox class="cstmCheckbox"
                                                                (change)="getMaxSI($event,cover,k)"
                                                                [formControlName]="'CoverNameCheck_'+cover.CvrId">{{cover.CoverName}}
                                                                ({{cover.CoverType}}) </mat-checkbox>
                                                        </li>
                                                        <li>
                                                            <div class=" customCheckslist pl-3"
                                                                *ngIf="cover.IsFixedOption && familyShieldForm.controls['CoverDetails'].value[k]['CoverNameCheck_' + cover.CvrId]">
                                                                <mat-label class="input-group__label">Sum
                                                                    Insured</mat-label>
                                                                <mat-select [formControlName]="'CoverSI_'+cover.CvrId"
                                                                    class="input-group__input  cstm_select"
                                                                    placeholder="Select SI">
                                                                    <mat-option value={{item}}
                                                                        *ngFor="let item of cover.fixedSiOption.SI"
                                                                        [ngStyle]="{'display': item<=cover?.MaxSI?'block':'none'}"
                                                                        selected>
                                                                        {{item}}
                                                                    </mat-option>
                                                                    <mat-divider></mat-divider>
                                                                </mat-select>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div class="row"
                                                *ngIf="familyShieldForm.controls['CoverDetails'].value[k]['CoverNameCheck_' + cover.CvrId] && !cover.IsFixedOption ">
                                                <div class="col-sm-6 form-group"
                                                    *ngIf="familyShieldForm.controls['CoverDetails'].value[k]['isEnhansement_childern'+cover.CvrId]">
                                                    <div class="rangeslider">
                                                        <mat-slider class="slider-container"
                                                            step="{{cover.SliderOptionForSI}}" min="{{cover.MinSI}}"
                                                            max="{{cover.MaxSI}}">
                                                            <input matSliderThumb id="custom-slider"
                                                                (focusout)="isAmountInvalid(cover.MaxSI,cover.SliderOptionForSI,k, cover.CvrId)"
                                                                [value]="familyShieldForm.controls['CoverDetails'].value[k]['CoverSI_'+cover.CvrId]"
                                                                [formControlName]="'CoverSI_'+cover.CvrId">
                                                            <div class="slider-ticks">
                                                                <div class="slider-tick">{{cover.MinSI}}</div>
                                                                <div class="slider-tick">{{cover.MaxSI}}</div>
                                                            </div>
                                                        </mat-slider>
                                                        <div class="amount-input">
                                                            <div class="amout-input-wrap">
                                                                <input type="number" class="form-range"
                                                                    placeholder="Enter amount"
                                                                    (focusout)="isAmountInvalid(cover.MaxSI,cover.SliderOptionForSI,k, cover.CvrId)"
                                                                    [value]="familyShieldForm.controls['CoverDetails'].value[k]['CoverSI_'+cover.CvrId]"
                                                                    [formControlName]="'CoverSI_'+cover.CvrId"
                                                                    autocomplete="off">
                                                                <span class="rupee-symbol">&#x20B9;
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <mat-error class="error-margin"
                                                        *ngIf="familyShieldForm.controls['CoverDetails'].value[k]['isValidamtentered'+cover.CvrId]">
                                                        Enter a SI in multiples of {{cover.SliderOptionForSI/1000}}K.
                                                    </mat-error>
                                                    <mat-error class="error-margin"
                                                        *ngIf="familyShieldForm.controls['CoverDetails'].value[k]['CoverSI_'+cover.CvrId]>cover.MaxSI">
                                                        Enter a SI upto {{cover.MaxSI/1000}}K.
                                                    </mat-error>
                                                </div>
                                            </div>
                                            <mat-divider></mat-divider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- {{bucketList|json}} -->
            <!-- Mobile View start here -->
            <mat-accordion class="mobileView" formArrayName="CoverDetails"
                *ngFor="let data of coverDetails.controls; let k = index">
                <div *ngIf="tabIndex===k && isEnableCoverPart[k] &&  this.familyShieldForm.controls['CoverDetails'].value[k].RiskCategory != ''"
                    [formGroupName]="k">
                    <mat-expansion-panel class="sheilddpanel" *ngFor="let bucketdetails of bucketList[k];let v=index">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div *ngIf="v==0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 54 54">
                                        <g transform="translate(-1806 -2702)">
                                            <g transform="translate(1806 2702)" fill="#fff" stroke="#1e1f20"
                                                stroke-width="1" class="tabColor">
                                                <circle cx="27" cy="27" r="27" stroke="none" />
                                                <circle cx="27" cy="27" r="26.5" fill="none" />
                                            </g>
                                            <g transform="translate(1688.698 2713.271)">
                                                <g transform="translate(137.483 0)">
                                                    <path
                                                        d="M140.5,93.03l2.127.726,2.007-5.75H140.46a2.963,2.963,0,0,0-2.956,2.942s-.021,4.2-.021,4.208a1.253,1.253,0,0,0,.845,1.215l.75.256.9-2.635.009,0c.008-1.559,0-.966.015-3.035a.248.248,0,0,1,.5,0Z"
                                                        transform="translate(-137.483 -82.002)" />
                                                    <circle cx="2.589" cy="2.589" r="2.589"
                                                        transform="translate(3.748)" />
                                                    <path d="M217.407,201.389h0a1.249,1.249,0,0,0,.808-2.365Z"
                                                        transform="translate(-211.955 -185.447)" />
                                                    <path
                                                        d="M191.323,90.949a2.963,2.963,0,0,0-2.956-2.942h-1.1L185.1,94.2l.267.091a2.614,2.614,0,0,1-1.689,4.947l-2-.682s0,12.683,0,13.408a1.5,1.5,0,1,0,3,0V100.308a.329.329,0,0,1,.329-.329.319.319,0,0,1,.319.319v11.664a1.5,1.5,0,1,0,3,0L188.3,90.94a.261.261,0,0,1,.523,0c0,.007,0,.015,0,.022l.046,9.178a1.249,1.249,0,0,0,1.249,1.243h.006a1.249,1.249,0,0,0,1.243-1.256Z"
                                                        transform="translate(-178.669 -82.003)" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <!-- <div [innerHtml]="this.svgObjects[v]" class="pr-2"></div> -->
                                    {{bucketdetails.bucketName}}
                                </div>
                                <div *ngIf="v==1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 54 54">
                                        <g transform="translate(-2071 -2702)">
                                            <g transform="translate(2071 2702)" fill="#fff" stroke="#1e1f20"
                                                stroke-width="1" class="tabColor">
                                                <circle cx="27" cy="27" r="27" stroke="none" />
                                                <circle cx="27" cy="27" r="26.5" fill="none" />
                                            </g>
                                            <g transform="translate(-29 -164)">
                                                <g transform="translate(2111 2884)">
                                                    <path
                                                        d="M.439-407.785a1.122,1.122,0,0,0-.317.317c-.122.2-.122.323-.122,8.287,0,7.775.006,8.1.116,8.3.2.384.409.458,1.4.476l.867.024v.336c0,.287.024.36.177.513l.177.177H5.907l.189-.159c.171-.14.189-.189.189-.519v-.36H25.142v.36c0,.33.018.378.189.519l.189.159h3.173l.177-.177c.153-.153.177-.226.177-.513v-.336l.781-.018c.641-.018.812-.043.982-.146.323-.189.433-.464.433-1.056,0-.281-.018-.5-.049-.488s-.207.1-.409.2l-.366.183H11.869l-.409-.2a1.853,1.853,0,0,1-.958-.989c-.153-.323-.159-.378-.153-1.422v-1.08H9.556l-3.924-2-3.924-2v-3.961c0-3.863,0-3.961-.122-4.156A.857.857,0,0,0,.439-407.785Z"
                                                        transform="translate(0 407.901)" />
                                                    <path
                                                        d="M61.115-344.765a2.282,2.282,0,0,0-1.587,1.263,2.235,2.235,0,0,0,.458,2.587,2.283,2.283,0,0,0,3.875-1.239A2.29,2.29,0,0,0,62-344.783,1.51,1.51,0,0,0,61.115-344.765Z"
                                                        transform="translate(-55.683 348.689)" />
                                                    <path
                                                        d="M135.981-327.266a1.56,1.56,0,0,0-.879.586,16.943,16.943,0,0,0-.976,2.429c-.4,1.092-.372,1.562.1,2.105a1.811,1.811,0,0,0,.427.342c.2.1,1.5.61,1.562.61.012,0,.024-.043.024-.1s.073-.415.159-.8c.14-.647.146-.708.055-.848-.049-.079-.079-.146-.055-.146s.751.69,1.617,1.538c1.666,1.617,1.788,1.721,2.13,1.819.238.067.4.037,3.234-.586,1.544-.342,1.831-.421,2.014-.568a1.024,1.024,0,0,0,.439-.854,1.07,1.07,0,0,0-1.05-1.1c-.153,0-1.117.183-2.148.4l-1.873.409-.384-.342c-.214-.2-.958-.9-1.66-1.587L137.44-325.2l.269-.488c.14-.262.323-.58.4-.7a.873.873,0,0,0,.14-.281,7.035,7.035,0,0,0-1.739-.647A1.614,1.614,0,0,0,135.981-327.266Z"
                                                        transform="translate(-125.681 332.234)" />
                                                    <path
                                                        d="M196.409-321.417c-1.141.067-1.422.116-1.623.287a9.267,9.267,0,0,0-1.172,1.77l-.22.433,1.141.647,1.141.641,1.648-.372c2.008-.452,2.471-.482,3.051-.214a2.166,2.166,0,0,1,.708,3.472c-.421.464-.677.549-3.271,1.123-2.612.574-2.911.6-3.515.342-.452-.2-.708-.476-1.33-1.416l-.58-.885-.079.458a13.137,13.137,0,0,0-.079,2.868c.165.391-.458.366,9.367.366,9.776,0,9.215.018,9.355-.348.037-.092.165-.787.281-1.544a7.316,7.316,0,0,0,.128-2.838,4.071,4.071,0,0,0-2.081-2.594,44.107,44.107,0,0,0-5.919-1.55A29.278,29.278,0,0,0,196.409-321.417Z"
                                                        transform="translate(-180.451 326.72)" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <!-- <div [innerHtml]="this.svgObjects[v]" class="pr-2"></div> -->
                                    {{bucketdetails.bucketName}}
                                </div>
                                <div *ngIf="v==2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 54 54">
                                        <g transform="translate(-2611 -2702)">
                                            <g transform="translate(794 -1163)">
                                                <g transform="translate(1817 3865)" fill="#fff" stroke="#1e1f20"
                                                    stroke-width="1" class="tabColor">
                                                    <circle cx="27" cy="27" r="27" stroke="none" />
                                                    <circle cx="27" cy="27" r="26.5" fill="none" />
                                                </g>
                                            </g>
                                            <path
                                                d="M16.652,42.722a.417.417,0,0,1,.416-.416h3.024a.416.416,0,0,1,.415.416V50a.416.416,0,0,1-.415.417H17.069A.417.417,0,0,1,16.652,50Zm-1.246,6.415a.367.367,0,0,0-.052.014L9.706,51.157a2.522,2.522,0,0,1-2.192-.2L.384,46.849a.853.853,0,0,1,.736-1.526l.005,0,5.634,1.828.015,0a1.72,1.72,0,0,0,.645.125h4.171a.342.342,0,1,0,0-.684H7.419a1.042,1.042,0,1,1,0-2.084H9.527a1.067,1.067,0,0,0,.751-.3l.761-.711a.389.389,0,0,1,.285-.112h4.645v5.679a5.646,5.646,0,0,0-.562.063Z"
                                                transform="translate(2627.992 2694.043)" fill="#1e1f20"
                                                fill-rule="evenodd" />
                                            <g transform="translate(0 1)">
                                                <path
                                                    d="M7.1,1.25h9.8a7.287,7.287,0,0,1,2.306.2,3.8,3.8,0,0,1,2.348,2.411l-.713.234.713-.234a7.905,7.905,0,0,1,.2,2.361V20.374a1.713,1.713,0,0,1-2.864,1.3.2.2,0,0,0-.271,0l-.483.442a2.407,2.407,0,0,1-3.263,0,.907.907,0,0,0-1.237,0,2.407,2.407,0,0,1-3.263,0,.907.907,0,0,0-1.237,0,2.407,2.407,0,0,1-3.263,0l-.483-.442a.2.2,0,0,0-.271,0,1.713,1.713,0,0,1-2.864-1.3v-14q0-.073,0-.144a7.9,7.9,0,0,1,.2-2.361A3.8,3.8,0,0,1,4.793,1.454,7.286,7.286,0,0,1,7.1,1.25Zm.146,1.5a6.874,6.874,0,0,0-1.959.121A2.3,2.3,0,0,0,3.871,4.333,7.521,7.521,0,0,0,3.75,6.37v14a.234.234,0,0,0,.135.227.2.2,0,0,0,.109.018.194.194,0,0,0,.107-.055,1.7,1.7,0,0,1,2.3,0l.483.442a.907.907,0,0,0,1.237,0,2.407,2.407,0,0,1,3.263,0,.907.907,0,0,0,1.237,0,2.407,2.407,0,0,1,3.263,0,.907.907,0,0,0,1.237,0l.483-.442a1.7,1.7,0,0,1,2.3,0,.194.194,0,0,0,.107.055.2.2,0,0,0,.109-.018.234.234,0,0,0,.135-.227v-14a7.519,7.519,0,0,0-.121-2.037,2.3,2.3,0,0,0-1.415-1.462,6.874,6.874,0,0,0-1.959-.121ZM17,6.441a.75.75,0,0,1,.06,1.059l-3.571,4a.75.75,0,0,1-1.119,0L10.941,9.9a.75.75,0,0,1,1.119-1l.869.973L15.941,6.5A.75.75,0,0,1,17,6.441Z"
                                                    transform="translate(2626.241 2710.75)" fill="#1e1f20"
                                                    fill-rule="evenodd" />
                                            </g>
                                            <g transform="translate(2632.928 2716.725)">
                                                <path
                                                    d="M9.785,8.653V9.42H.843V6.672H0v5.5H.843V10.912H9.785v1.361h.84V8.652Z"
                                                    transform="translate(0 0)" />
                                                <path
                                                    d="M5.4,10.595h0a.452.452,0,0,1-.452.452H3.223a.452.452,0,0,1-.452-.452h0a.452.452,0,0,1,.452-.452H4.947a.452.452,0,0,1,.452.452Z"
                                                    transform="translate(-1.544 -1.934)" />
                                            </g>
                                        </g>
                                    </svg>
                                    {{bucketdetails.bucketName}}
                                </div>
                                <div *ngIf="v==3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 54 54">
                                        <g transform="translate(-2339 -2702)">
                                            <g transform="translate(2339 2702)" fill="#fff" stroke="#1e1f20"
                                                stroke-width="1" class="tabColor">
                                                <circle cx="27" cy="27" r="27" stroke="none" />
                                                <circle cx="27" cy="27" r="26.5" fill="none" />
                                            </g>
                                            <g transform="translate(707.39 -1655.687)">
                                                <path
                                                    d="M22.211,40.622a2.357,2.357,0,0,1-.634-.1,19.351,19.351,0,0,1-13.23-18.36V14.874a1.981,1.981,0,0,1,1.089-1.763L21.32,7.169a1.981,1.981,0,0,1,1.783,0l11.884,5.942a1.981,1.981,0,0,1,1.089,1.763v7.289a19.351,19.351,0,0,1-13.23,18.459A2.358,2.358,0,0,1,22.211,40.622Zm-9.9-24.52v6.061a15.39,15.39,0,0,0,9.9,14.38,15.389,15.389,0,0,0,9.9-14.38V16.1l-9.9-4.952Z"
                                                    transform="translate(1636.399 4360.887)" fill="#1e1f20" />
                                            </g>
                                            <path
                                                d="M10.323,42.564a.258.258,0,0,1,.258-.258h1.875a.258.258,0,0,1,.258.258v4.515a.258.258,0,0,1-.258.258H10.581a.258.258,0,0,1-.258-.258ZM9.55,46.542l-.032.009-3.5,1.244a1.564,1.564,0,0,1-1.359-.124L.234,45.123a.529.529,0,0,1,.456-.946h0l3.494,1.133.009,0a1.067,1.067,0,0,0,.4.078H7.183a.212.212,0,1,0,0-.424H4.6a.646.646,0,1,1,0-1.292H5.9a.662.662,0,0,0,.466-.184l.472-.441a.242.242,0,0,1,.176-.069H9.9V46.5a3.5,3.5,0,0,0-.349.039Z"
                                                transform="translate(2359.648 2688.013)" fill="#1e1f20"
                                                fill-rule="evenodd" /><text transform="translate(2365.519 2730.091)"
                                                fill="#1e1f20" font-size="11" font-family="Mulish-Medium, Mulish"
                                                font-weight="500">
                                                <tspan x="-2.89" y="0">₹</tspan>
                                            </text>
                                        </g>
                                    </svg>
                                    {{bucketdetails.bucketName}}
                                </div>
                                <div *ngIf="v==4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 54 54">
                                        <g transform="translate(-2871.5 -2700)">
                                            <g transform="translate(826.5 -1046)">
                                                <g transform="translate(2045 3746)" fill="#fff" stroke="#1e1f20"
                                                    stroke-width="1" class="tabColor">
                                                    <circle cx="27" cy="27" r="27" stroke="none" />
                                                    <circle cx="27" cy="27" r="26.5" fill="none" />
                                                </g>
                                                <g transform="translate(413.39 -611.687)">
                                                    <path
                                                        d="M22.211,40.622a2.357,2.357,0,0,1-.634-.1,19.351,19.351,0,0,1-13.23-18.36V14.874a1.981,1.981,0,0,1,1.089-1.763L21.32,7.169a1.981,1.981,0,0,1,1.783,0l11.884,5.942a1.981,1.981,0,0,1,1.089,1.763v7.289a19.351,19.351,0,0,1-13.23,18.459A2.358,2.358,0,0,1,22.211,40.622Zm-9.9-24.52v6.061a15.391,15.391,0,0,0,9.9,14.381,15.389,15.389,0,0,0,9.9-14.381V16.1l-9.9-4.952Z"
                                                        transform="translate(1636.399 4360.887)" fill="#1e1f20" />
                                                </g>
                                            </g>
                                            <path
                                                d="M22.225,6.6a1.377,1.377,0,0,1,.11,1.944l-6.557,7.344a1.377,1.377,0,0,1-2.054,0L11.1,12.951a1.377,1.377,0,0,1,2.054-1.834l1.6,1.787L20.28,6.71A1.377,1.377,0,0,1,22.225,6.6Z"
                                                transform="translate(2881.783 2715.701)" fill="#1e1f20"
                                                fill-rule="evenodd" />
                                        </g>
                                    </svg>
                                    {{bucketdetails.bucketName}}
                                </div>
                                <div *ngIf="v==5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 54 54">
                                        <g transform="translate(-3143.5 -2700)">
                                            <g transform="translate(1326.5 -1165)">
                                                <g transform="translate(1817 3865)" fill="#fff" stroke="#1e1f20"
                                                    stroke-width="1" class="tabColor">
                                                    <circle cx="27" cy="27" r="27" stroke="none" />
                                                    <circle cx="27" cy="27" r="26.5" fill="none" />
                                                </g>
                                            </g>
                                            <path
                                                d="M24.535,14.718c.3,6.1-1.949,9.705-5.943,11.207-3.857-1.409-6.144-4.855-5.967-11.3A8.53,8.53,0,0,0,18.566,12.8c1.711,1.084,3.893,2.11,5.967,1.922ZM13.262,2.557l4.367,4.091H13.262Zm-9.14,9.42a.5.5,0,0,1,.361-.162h8.829a.492.492,0,0,1,.369.169.61.61,0,0,1,0,.791h0a.6.6,0,0,1-.105.089c-.169.032-.338.057-.506.08H4.492a.5.5,0,0,1-.371-.167.606.606,0,0,1,0-.8Zm0,3.53a.5.5,0,0,1,.369-.171h6.146c.019.386.044.764.078,1.133H4.492A.5.5,0,0,1,4.12,16.3a.606.606,0,0,1,0-.8Zm0,3.528a.5.5,0,0,1,.369-.169h6.572q.12.584.272,1.133H4.492a.492.492,0,0,1-.371-.169.6.6,0,0,1-.148-.4.606.606,0,0,1,.15-.4Zm0-10.587a.5.5,0,0,1,.369-.171H9.555a.489.489,0,0,1,.367.167.6.6,0,0,1,.148.4.606.606,0,0,1-.148.4.5.5,0,0,1-.367.169H4.492a.5.5,0,0,1-.371-.167.6.6,0,0,1-.148-.4.6.6,0,0,1,.15-.4Zm0-3.283a.5.5,0,0,1,.369-.171H7.277a.494.494,0,0,1,.365.165h0a.606.606,0,0,1,0,.8.5.5,0,0,1-.367.169H4.492a.5.5,0,0,1-.371-.167.606.606,0,0,1,0-.8ZM20.06,7.234a.726.726,0,0,0-.53-.694L13.1.268A.715.715,0,0,0,12.534,0H1.308A1.308,1.308,0,0,0,0,1.308V23.685a1.293,1.293,0,0,0,.384.926,1.312,1.312,0,0,0,.924.382h12.6a10.933,10.933,0,0,1-1.072-1.46H1.46V1.454H11.8V7.384a.734.734,0,0,0,.734.732H18.6V10.61q.741.468,1.46.854ZM15.347,18.76l1.141-.015a3.426,3.426,0,0,1,1.148.844c.188-.3.382-.587.582-.865q.333-.464.684-.886c.253-.308.517-.6.787-.882a3.914,3.914,0,0,1,.962-.857.057.057,0,0,1,.027,0h1.249a.074.074,0,0,1,.074.074.07.07,0,0,1-.023.053l-.247.268q-.578.633-1.1,1.3c-.352.445-.688.9-1,1.357s-.614.918-.9,1.388-.553.956-.8,1.443l-.158.3a.076.076,0,0,1-.1.032.093.093,0,0,1-.034-.036l-.143-.308a7.661,7.661,0,0,0-.435-.814,6.779,6.779,0,0,0-.519-.736,6.418,6.418,0,0,0-.612-.656,6.1,6.1,0,0,0-.715-.57.074.074,0,0,1-.027-.084l.091-.3a.076.076,0,0,1,.074-.053Z"
                                                transform="translate(3158.219 2714.038)" fill-rule="evenodd" />
                                        </g>
                                    </svg>
                                    {{bucketdetails.bucketName}}
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="fieldsdiv p-0">
                            <h2 class="critiHead">Base</h2>
                            <div class="row" *ngFor="let cover of bucketdetails.coverDetails;let i=index;">
                                <div class="col-sm-12 customCheckslist pl-1" *ngIf="shouldShowCoverName4(cover,k,i)">
                                    <ul
                                        *ngIf="familyShieldForm.controls['CoverDetails'].value[k]['isEnhansement_childern'+cover.CvrId]">
                                        <li>
                                            <mat-checkbox class="cstmCheckbox"
                                                [formControlName]="'CoverNameCheck_'+cover.CvrId"
                                                (change)="getMaxSI($event,cover,k)">{{cover.CoverName}}
                                                ({{cover.CoverType}})
                                            </mat-checkbox>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-sm-2 customCheckslist pl-1"
                                    *ngIf="cover.IsFixedOption && familyShieldForm.controls['CoverDetails'].value[k]['CoverNameCheck_' + cover.CvrId]">
                                    <mat-label class="input-group__label">Sum Insured</mat-label>
                                    <mat-select [formControlName]="'CoverSI_'+cover.CvrId"
                                        class="input-group__input  cstm_select" placeholder="Select SI">
                                        <mat-option value={{item}} *ngFor="let item of cover.fixedSiOption.SI"
                                            [ngStyle]="{'display': item<=cover?.MaxSI?'block':'none'}" selected>
                                            {{item}}
                                        </mat-option>
                                        <mat-divider></mat-divider>
                                    </mat-select>
                                    <!-- <mat-error
                                                        *ngIf="hasError('CoverSI_'+cover.CvrId, 'required') && formSubmitted">
                                                        Please select SI
                                                    </mat-error> -->
                                </div>
                                <div class="col-sm-6 form-group"
                                    *ngIf="familyShieldForm.controls['CoverDetails'].value[k]['CoverNameCheck_' + cover.CvrId] && !cover.IsFixedOption">
                                    <div class="rangeslider"
                                        *ngIf="familyShieldForm.controls['CoverDetails'].value[k]['isEnhansement_childern'+cover.CvrId]">
                                        <mat-slider class="slider-container" step="{{cover.SliderOptionForSI}}"
                                            min="{{cover.MinSI}}" max="{{cover.MaxSI}}">
                                            <input matSliderThumb id="custom-slider"
                                                (focusout)="isAmountInvalid(cover.MaxSI,cover.SliderOptionForSI,k, cover.CvrId)"
                                                [value]="familyShieldForm.controls['CoverDetails'].value[k]['CoverSI_'+cover.CvrId]"
                                                [formControlName]="'CoverSI_'+cover.CvrId">
                                            <div class="slider-ticks">
                                                <div class="slider-tick">{{cover.MinSI}}</div>
                                                <div class="slider-tick">{{cover.MaxSI}}</div>
                                            </div>
                                        </mat-slider>
                                        <div class="amount-input">
                                            <div class="amout-input-wrap">
                                                <input type="number" class="form-range" placeholder="Enter amount"
                                                    (focusout)="isAmountInvalid(cover.MaxSI,cover.SliderOptionForSI,k, cover.CvrId)"
                                                    [value]="familyShieldForm.controls['CoverDetails'].value[k]['CoverSI_'+cover.CvrId]"
                                                    [formControlName]="'CoverSI_'+cover.CvrId" autocomplete="off">
                                                <span class="rupee-symbol">&#x20B9;
                                                </span>
                                            </div>
                                            <mat-error class="error-margin float-left"
                                                *ngIf="familyShieldForm.controls['CoverDetails'].value[k]['isValidamtentered'+cover.CvrId]">
                                                Enter a SI in multiples of {{cover.SliderOptionForSI/1000}}K.
                                            </mat-error>
                                            <mat-error class="error-margin float-left"
                                                *ngIf="familyShieldForm.controls['CoverDetails'].value[k]['CoverSI_'+cover.CvrId]>cover.MaxSI">
                                                Enter a SI upto {{cover.MaxSI/1000}}K.
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-expansion-panel>
                </div>
            </mat-accordion>
            <!-- Mobile View ends here -->
        </mat-tab>

        <!-- Details Of Members -->
    </mat-tab-group>
</form>
<app-custom-footer [normalButton]="true"
    [buttonData]="[{'label': 'Get quote', 'type': 'primary', 'source': 'quote', 'id': 'f1','disabled': false , 'hide': false}]"
    (buttonClicked)="getQuote($event)">
</app-custom-footer>