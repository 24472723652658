import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { healthMembers, healthProducts, healthProposalProductCode, healthToggle, patterns, zoneDetails } from 'src/app/shared/enums/healthEnums';
import { HealthDataStorage, PremiumDetailsData } from '../../health.modal';
import { AddonStructure, HAPModal } from '../hap/hap-modal';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { PopupService } from 'src/app/shared/services/popup.service';
import { AddonDetail, GoldenShieldResponsePayload, SumInsuredDetail } from 'src/app/shared/interface/goldenShield';
import { AgentMasterResponsePayload } from 'src/app/shared/interface/agentV1';
import { SaveEditQuoteV1Request } from 'src/app/shared/interface/transactionGoldenShield';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { HealthDataStorageService } from '../../health.service';
import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { ZoneRequestPayload, ZoneResponsePayload } from 'src/app/shared/interface/health-advantedge';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { EmitButtonData } from 'src/app/shared/interface/custom-footer';
import { HealthDetails } from 'src/app/shared/interface/transactionHealthAdvantege';
import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { RelationshipRequestPayload, RelationshipResponsePayload } from 'src/app/shared/interface/healthMaster';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { GoldenShieldServiceM } from 'src/app/shared/proxy-services/MasterService/golden-shield.service';
import { GoldenShieldServiceT } from 'src/app/shared/proxy-services/TransactionService/goldenShield.service';
import { WindowResizeService } from 'src/app/shared/services/window-resize.service';
import { MatSelect } from '@angular/material/select';
import { StorageService, SessionStorageService, } from 'src/app/shared/services/storage.service';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { ChannelData } from 'src/app/shared/interface/swapPrameters';
import { ConvertSavedQuotesResponsePayload, SavedQuote } from 'src/app/shared/interface/savedQuotes';
import { StepData } from 'src/app/shared/interface/custom-stepper';
import { GoldenShieldModal } from './golden-shield-modal';

@Component({
  selector: 'app-golden-shield',
  templateUrl: './golden-shield.component.html',
  styleUrls: ['./golden-shield.component.scss']
})
export class GoldenShieldComponent implements OnInit {
  @ViewChild('textCreate', { static: false }) textCreateComponent: ElementRef;
  @Output() QuoteValueEmitter = new EventEmitter<{ Quote: boolean, Portability: boolean, StatusCode: boolean }>();
  goldenShieldForm: FormGroup;
  gsFormData: HealthDataStorage;
  hapDataModal: HAPModal = new HAPModal(); //hap data modal 
  gsDataModal: GoldenShieldModal = new GoldenShieldModal(); //gs data modal 
  formSubmitted: boolean = false;
  stateFetched: boolean = false;
  invalidPinCodeMessage: string;
  cityData = [];
  transparentSpinner: boolean = false;
  subscription: Subscription[] = [];
  stateDetails = this.hapDataModal.stateDetails;
  zoneMappedWithPincode: string = '';
  errorPopupData: PopupModal;
  adultCountData = this.hapDataModal.adultCount;
  adult2DateError: boolean = false;
  adultRelationShipError: boolean = false;
  adultRelationShips = [];
  datepickerMobile: boolean = false;
  vaccineMinMaxDate: { maxDate: Date; minDate: Date; };
  loadFormContent: boolean = false;
  refreshPopupActivated: boolean = false;
  agentDetails: AgentMasterResponsePayload;
  gsMasterData: GoldenShieldResponsePayload;
  addonCover: AddonDetail[];
  addonDisable = false;
  sumInsuedDetails: SumInsuredDetail[];
  VDValue: number;
  VDValueEnable: boolean = false;
  CoPayment: boolean = false;
  vdAddonCover: AddonDetail[];
  CoPaymentAddonCover: AddonDetail[];
  vdAddonCoverDisable: boolean = false;
  isChecked: boolean = false;
  tenureDuration: number = 0;
  sliderForm: FormGroup;
  selectedValue: string = '20';
  upgradeZone: boolean = false;
  IsVisible: boolean = false;
  adultData = [];
  saveQuoteCounter = 0;
  success: boolean = false;
  multipleServiceError: boolean = false;
  formValid: boolean = false;
  portability: boolean = false;
  selectedOption: string;
  checkbox1: boolean = false;
  checkbox2: boolean = false;
  @ViewChild('quotePanel') quotePanel: MatExpansionPanel;
  patchingDone: boolean = false;
  initialFormState: {};
  premiumDetails: PremiumDetailsData;
  sliderControlValue: string;
  finalGSAddon = [];
  makeChecked: boolean = true;
  channelData: ChannelData;
  quoteDetails: ConvertSavedQuotesResponsePayload;
  quoteConvertData: SavedQuote;
  steps: StepData[] = [];
  adultValue: number = 1;
  formReset: boolean;

  constructor(private fb: FormBuilder,
    public utilityService: UtilityService,
    private rtoListService: RTOListService,
    private popupService: PopupService,
    private healthAdvantedgeService_M: HealthAdvantedgeService,
    private el: ElementRef,
    private healthService: HealthDataStorageService,
    private goldenShieldService_m: GoldenShieldServiceM,
    private goldenShielsService_T: GoldenShieldServiceT,
    private agentV1Service_M: AgentV1Service,
    private customStepperService: CustomStepperService,
    private customFooterService: CustomFooterService,
    private healthMasterService: HealthMasterService,
    private healthDataStorageService: HealthDataStorageService,
    private sessionStorageService: SessionStorageService,
    private windowResizeService: WindowResizeService,
    private renderer: Renderer2,
    private storageService: StorageService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.windowResizeService.getResizeObservable().subscribe(data => {
      this.datepickerMobile = data.width < 768;
    })
    this.onLoadSteps();
    this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
  }

  onLoadSteps() {
    this.premiumDetails = this.healthDataStorageService.initializePremiumData()
    this.gsFormData = this.healthDataStorageService.getHealthDataStorage();
    this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    if (this.quoteDetails) {
      this.quoteConvertData = this.quoteDetails.Data;
      this.updateSavedQuotesConvertData();
      // this.changeInProductType();
      this.customFooterService.showPremiumDetails(true);
    }
    if (this.gsFormData.quoteDataSet) {
      this.patchingDone = false;
      this.checkbox2 = this.gsFormData.quoteFormData.checkbox2;
      this.checkbox1 = this.gsFormData.quoteFormData.checkbox1;

    }
    this.createGSForm();
    this.getMasterData();
    this.getHealthProposalRelationships();
    if (!this.gsFormData.quoteDataSet && this.gsFormData.quickquoteDataSet) {
      this.patchQuickQuoteData();
    }
  }

  updateSavedQuotesConvertData() {
    this.success = true;
    this.gsFormData.quoteDataSet = true;
    let quoteFormData = this.gsFormData.quoteFormData;
    // let GSTData = this.quoteConvertData.CustomerDetails.GSTDetails;
    quoteFormData.productType = this.quoteConvertData.ProductType;
    quoteFormData.pincode = this.quoteConvertData.Pincode;
    quoteFormData.policyTenure = this.tenureSelectionAsPerNumber(this.quoteConvertData.Tenure);
    // quoteFormData.policyRange = this.quoteConvertData.PolicyRange;
    quoteFormData.applicantAnnualSum = this.quoteConvertData.Members[0].SumInsured.toString();
    quoteFormData.applicantAnnualIncome = this.quoteConvertData.ProposalAnnualIncomeRange;
    // quoteFormData.InstallmentApplicable = this.quoteConvertData.InstallmentApplicable;
    // quoteFormData.InstallmentFrequency = this.quoteConvertData.InstallmentFrequency;
    this.gsFormData.premiumFormData.InstallmentTotalPremium = parseInt(this.quoteConvertData.InstallmentTotalPremium);
    quoteFormData.addonsFormdata = this.quoteConvertData.gsAddonsFormData;
    quoteFormData.zone = this.quoteConvertData.Zone;
    quoteFormData.cityState = this.quoteConvertData.CityState;
    quoteFormData.copaymentPercentTaken = this.quoteConvertData.VoluntaryCopaymentPercentage;
    this.sliderControlValue = quoteFormData.copaymentPercentTaken;
    this.gsFormData.quoteFormData.checkbox2 = this.quoteConvertData.GenericData.gsCheckbox2;
    this.zoneMappedWithPincode = this.gsFormData.quoteFormData.zone;
    quoteFormData.productType = this.quoteConvertData.ProductType;
    quoteFormData.zoneUpgrade = this.quoteConvertData.ZoneUpgrade;
    quoteFormData.reviseZone = this.quoteConvertData.RevisedZone;
    quoteFormData.productName = healthProducts.gs;
    quoteFormData.healthProposalProductType = healthProposalProductCode.gs;
    quoteFormData.adultRelationShips = this.quoteConvertData.AdultRelationShips;
    quoteFormData.childRelationShips = this.quoteConvertData.ChildRelationShips;
    quoteFormData.subProductType = this.hapDataModal.subProductType.GS;
    this.gsFormData.premiumFormData.PfQuoteId = this.quoteConvertData.GenericData.pfQuoteId;
    quoteFormData.corelationId = this.quoteConvertData.CustomerDetails.CorelationId;
    this.healthDataStorageService.setHealthDataStorage(this.gsFormData);
  }

  tenureSelectionAsPerNumber(data: number) {
    let tenureSelect;
    if (data == 1) {
      tenureSelect = this.hapDataModal.tenurePeriod[0];
    } else if (data == 2) {
      tenureSelect = this.hapDataModal.tenurePeriod[1];
    } else {
      tenureSelect = this.hapDataModal.tenurePeriod[2];
    }
    return tenureSelect;
  }

  createGSForm() {
    this.goldenShieldForm = this.fb.group({
      productName: [healthProducts.gs],
      productType: [this.gsFormData.quoteDataSet ? this.gsFormData.quoteFormData.productType : this.hapDataModal.productType[0]],
      pincode: [this.gsFormData.quoteDataSet ? this.gsFormData.quoteFormData.pincode : '', [Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6)]],
      cityState: ['', Validators.required],
      adultDetails: this.fb.array([]),
      policyTenure: [this.gsFormData.quoteDataSet ? this.gsFormData.quoteFormData.policyTenure : this.hapDataModal.tenurePeriod[0], Validators.required],
      applicantAnnualSum: [this.gsFormData.quoteDataSet ? this.gsFormData.quoteFormData.applicantAnnualSum : '', [Validators.required]],
      sliderControl: ['20'],
      voluntaryDeductible: [''],
      zone: [this.gsFormData.quoteDataSet ? this.gsFormData.quoteFormData.reviseZone == 'true' ? 'Yes' : 'No' : 'No', [Validators.required]],
    })
    this.patchExistingMemberData();

    if (this.gsFormData.quoteDataSet) {
      this.fetchDataAsPerPincode('', this.gsFormData.quoteFormData.pincode);
    }

    this.goldenShieldForm.valueChanges.subscribe((data) => {
      if (this.patchingDone) {
        this.showPremiumDetails(false);
        // Here we are resetting the stepper. Just to be sure if the quote is changed.
        // User needs to traverse through each steps
        if (this.quoteDetails) {
          // Below block is written to identify value changes due to save quote
          if (this.saveQuoteCounter == 0) {
            this.customStepperService.saveQuoteDataChanges(true);
            this.sessionStorageService.remove(userEnums.ConverToProposal);
            this.saveQuoteCounter++;
            this.quoteDetails = null;
          }
        }
        this.customStepperService.resetStepper(true);
      }
    })
  }

  get adultDetails(): FormArray {
    return this.goldenShieldForm.get('adultDetails') as FormArray;
  }

  createAdultDetails(index?): FormGroup {
    return new FormGroup({
      ['dobAdult' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultRealtionship' + `${index + 1}`]: new FormControl('', [Validators.required]),
      ['adultVaccinationStatus' + `${index + 1}`]: new FormControl(healthToggle.No, [Validators.required]),
      ['adultVaccinationDate' + `${index + 1}`]: new FormControl(''),
      ['Gender' + `${index + 1}`]: new FormControl('')
    })
  }

  patchExistingMemberData() {
    if (this.gsFormData.quoteDataSet || this.quoteConvertData) {
      // IPAA-176: Patch AdultDetails and ChildDetails for Convert API
      if(this.quoteConvertData && this.quoteConvertData.AdultDetails) {
        // this.hepFormData.quoteFormData.adultDetails = this.quoteConvertData.AdultDetails;
        this.gsFormData.quoteFormData.adultDetails = this.quoteConvertData.AdultDetails.map((data:any, i) => ({
          [`dobAdult${(i+1)}`]: data.dob,
          [`adultRealtionship${(i+1)}`]: data.relationship,
          [`adultVaccinationStatus${(i+1)}`]: data.vaccineStatus,
          [`adultVaccinationDate${(i+1)}`]: data.vaccineDate ? new Date(data.vaccineDate): null,
        }));
      }
      if (this.gsFormData.quoteFormData.adultDetails.length > 0) {
        this.gsFormData.quoteFormData.adultDetails.forEach((data, index) => {
          this.adultDetails.push(this.createAdultDetails(index));
          // Patch values of adults
          // Below block is written because when there is self data that get's patched from kyc is not in date format
          // So to handle it we need to convert in into date type
          for (let adultKeys in data) {
            if (adultKeys.includes('dob')) {
              let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
              this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
            }
            else {
              this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
            }
          }
        })

        // We need to highlight the selected adult also
        let selectedAdultIndex = this.gsFormData.quoteFormData.adultDetails.length - 1;
        this.adultCountData.forEach((adultCount, index) => {
          if (index == selectedAdultIndex) {
            adultCount.selected = true;
          }
          else {
            adultCount.selected = false;
          }
        })
      }

      // IPAA-176: Patch totalMemeber object for insured page Convert API
      if (this.quoteConvertData && (this.adultDetails.length > 0)) {
        this.gsFormData.quoteFormData.totalMemberData = this.getMemberRequest();
        this.healthDataStorageService.setHealthDataStorage(this.gsFormData);
      }
    }
    else if (this.gsFormData.quickquoteDataSet) {
      this.getadultchilddataforquickquote();
    }
    else {
      this.adultDetails.push(this.createAdultDetails(0));
    }
  }

  hasError(controlName: string, errorCode: string): boolean {
    return !this.goldenShieldForm.valid && this.goldenShieldForm.hasError(errorCode, [controlName]);
  }

  fetchDataAsPerPincode(event, existingPincode) {
    if (existingPincode == null) {
      this.gsFormData.quoteDataSet = false;
    }
    this.formReset = false;
    this.stateFetched = false;
    this.invalidPinCodeMessage = '';
    this.cityData = [];
    if ((existingPincode != null ? existingPincode : event.target.value.length == 6) && !this.goldenShieldForm.controls['pincode'].hasError('pattern')) {
      let data: RTOListRequestPayload = {
        "Pincode": existingPincode != null ? existingPincode : event.target.value,
        "CityID": ""
      }
      this.transparentSpinner = true;
      this.invalidPinCodeMessage = '';
      this.goldenShieldForm.controls['cityState'].reset();
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.length > 0) {
              this.stateDetails.stateId = response.Data[0].StateId ? response.Data[0].StateId : '';
              this.stateDetails.stateName = response.Data[0].StateName ? response.Data[0].StateName : '';
              this.stateDetails.pincode = existingPincode != null ? existingPincode : event.target.value;
              response.Data.forEach(cityList => {
                this.cityData.push({ 'cityLabel': cityList['CityName'], 'stateLabel': this.stateDetails.stateName, 'value': cityList['CityID'] });
              });
            }
            if (this.cityData.length == 1) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.getZoneData();
              this.goldenShieldForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
            }

            // IPAA-176: Patch City and State for Convert API
            if (this.quoteDetails && this.cityData.length > 0) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.getZoneData();
              this.goldenShieldForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
              this.gsFormData.quoteFormData.cityId = this.stateDetails.cityId;
            }
            // Need to check below in backward patching
            if (this.gsFormData.quoteDataSet) {
              this.zoneMappedWithPincode = this.gsFormData.quoteFormData.zone;
              this.goldenShieldForm.controls['cityState'].setValue(this.gsFormData.quoteFormData.cityState);
              this.changeCityName(this.gsFormData.quoteFormData.cityId, false);
              // if (this.gsFormData.quoteDataSet && !this.patchingDone && this.cityData.length > 1) {
              //   this.patchingDone = true;
              // }
            }
            this.stateFetched = true;

          }
          else if (response.StatusCode == Constants.statusCode_failure) {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            this.stateFetched = false;
            this.invalidPinCodeMessage = response.StatusDesc;
          }

          else {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          }
        },
        error: error => {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.invalidPinCodeMessage = '';
          this.stateFetched = false;
        }
      }))
    }
    this.utilityService.numberOnly(event);
    this.utilityService.restrictSpace(event);
  }

  onChangeSliderValue(ev) {
    if (this.selectedValue == this.goldenShieldForm.get('sliderControl').value) {

      this.selectedValue = ev.target.value + '%';
    }
    else {
      this.selectedValue = '50%';
    }
  }


  getZoneData() {
    let params: ZoneRequestPayload = {
      "productcode": this.hapDataModal.zoneCode,
      "State": this.stateDetails.stateName,
      "City": this.stateDetails.cityName
    }
    this.subscription.push(this.healthAdvantedgeService_M.getZoneFromCityData(params).subscribe({
      next: (response: ZoneResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          let zoneData = response.Data.zone;
          this.zoneMappedWithPincode = zoneData ? ((zoneData == this.hapDataModal.zoneData[0]) ? zoneDetails.Zone1 : ((zoneData == this.hapDataModal.zoneData[1]) ? zoneDetails.Zone2 : zoneDetails.Zone3)) : '';

          // if (this.gsFormData.quoteDataSet && !this.patchingDone) {
          //   this.patchingDone = true;
          // }

        }
        else {
          this.zoneMappedWithPincode = '';
          this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: error => {

        this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))
  }

  changeCityName(cityValue: string, showSpinner: boolean) {
    this.stateDetails.cityId = cityValue;
    this.stateDetails.cityName = this.cityData.length > 0 ? (this.cityData.find(data => data.value == cityValue).cityLabel) : '';
    if (showSpinner) {
      ;
      this.getZoneData();
    }
  }

  getHealthProposalRelationships() {
    let proposalData: RelationshipRequestPayload = { product: healthProposalProductCode.gs };
    this.subscription.push(this.healthMasterService.getHealthProposalRelationships(proposalData).subscribe({
      next: (data: RelationshipResponsePayload) => {
        if (data.StatusCode == Constants.statusCode_success) {
          this.manageHealthRelationShipsData(data);
        }
      },
      error: (err) => {
      }
    })
    );
  }


  // handles member relationships 
  manageHealthRelationShipsData(response: RelationshipResponsePayload) {
    let adultRelations = [];
    let childRelations = [];
    if (response.StatusCode == Constants.statusCode_success) {
      this.multipleServiceError = false;
      if (response.Data.InsuredRelationship.length > 0) {
        adultRelations = response.Data.InsuredRelationship.filter(x => x.KidAdultType == healthMembers.Adult);

        this.adultRelationShips = adultRelations.length > 0 ? adultRelations.sort(function (a, b) {
          return a.RelationshipName.localeCompare(b.RelationshipName)
        }) : [];
        this.loadFormContent = true;
      }
      else {
        this.adultRelationShips = [];
        this.loadFormContent = false;
        this.multipleServiceError = true;
      }
    }
  }

  setGoldenShieldFormData(premiumData: HealthDetails) {
    let resetFlag: boolean = this.checkMemberDetails();
    let vaccinationtaken = this.adultDetails.value.filter((vaccinationStatus, i) =>
      vaccinationStatus['adultVaccinationStatus' + (i + 1)] == 'Yes'
    )

    if (this.goldenShieldForm.value.productType != 'New') {
      this.gsFormData.portabilityDataSet = true;
    }
    else {
      this.gsFormData.portabilityDataSet = false;
    }
    this.gsFormData.quoteFormData.productName = this.goldenShieldForm.controls['productName'].value;
    this.gsFormData.quoteFormData.productType = this.goldenShieldForm.controls['productType'].value;
    this.gsFormData.quoteFormData.pincode = this.goldenShieldForm.controls['pincode'].value;
    this.gsFormData.quoteFormData.adultDetails = this.adultDetails.value;
    this.gsFormData.quoteFormData.cityId = this.stateDetails.cityId;
    this.gsFormData.quoteFormData.childDetails = [];
    this.gsFormData.quoteFormData.totalMemberData = this.getMemberRequest();
    this.gsFormData.quoteFormData.policyTenure = this.goldenShieldForm.controls['policyTenure'].value;
    this.gsFormData.quoteFormData.policyRange = (this.utilityService.easyAmountReader(this.goldenShieldForm.controls['applicantAnnualSum'].value)).replace('₹ ', '');
    this.gsFormData.quoteFormData.applicantAnnualSum = this.goldenShieldForm.controls['applicantAnnualSum'].value;
    this.gsFormData.quoteFormData.cityState = this.goldenShieldForm.controls['cityState'].value;
    this.gsFormData.quoteFormData.zone = this.zoneMappedWithPincode;
    this.gsFormData.quoteFormData.adultRelationShips = this.adultRelationShips; //empty
    this.gsFormData.quoteFormData.healthProposalProductType = healthProposalProductCode.gs;
    this.gsFormData.quoteFormData.subProductType = this.hapDataModal.subProductType.GS;
    this.gsFormData.quoteFormData.copaymentPercentTaken = this.sliderControlValue;
    this.gsFormData.quoteFormData.voluntaryDeductible = this.goldenShieldForm.controls['voluntaryDeductible'].value;
    this.gsFormData.quoteFormData.quoteSummary = this.finalGSAddon;
    this.gsFormData.quoteFormData.addonList = this.gsMasterData.Data.AddonDetails;
    this.gsFormData.quoteFormData.IsOtherLoadingDiscount = ((this.adultDetails.value.length !== 0) && (this.adultDetails.value.length == vaccinationtaken.length)) ? true : false;
    this.gsFormData.quoteFormData.checkbox2 = this.checkbox2;
    this.gsFormData.quoteFormData.reviseZone = this.goldenShieldForm.controls['zone'].value == 'Yes' ? 'true' : 'false';
    this.gsFormData.quoteFormData.zoneUpgrade = this.goldenShieldForm.controls['zone'].value == 'Yes' ? true : false;
    this.gsFormData.quoteFormData.checkbox1 = this.checkbox1;

    //reset data for quickquote
    this.gsFormData.quickquoteData.pincode = '';
    this.gsFormData.quickquoteData.cityState = '';
    this.gsFormData.quickquoteData.adultDetails = [];
    this.gsFormData.quickquoteData.childDetails = [];
    this.gsFormData.quickquoteData.policyTenure = '';
    this.gsFormData.quickquoteData.applicantAnnualSum = '';
    this.gsFormData.quoteDataSet = true;
    if (resetFlag) {
      this.setOtherFormFlags();
    }
    this.healthService.setHealthDataStorage(this.gsFormData);
    this.constructPremiumData(premiumData);

  }

  checkMemberDetails(): boolean {
    let nonEqualMembers = [];
    let resetFlag: boolean = false;
    // Adult Details
    // If new adult details and child details length are same then we need to check the info
    if (this.adultDetails.length == this.gsFormData.quoteFormData.adultDetails.length) {
      let existingAdultDetails = this.gsFormData.quoteFormData.adultDetails;
      existingAdultDetails.forEach((adultData, index) => {
        let tempAdultDetails = adultData;
        // When there is self detected there is change in format of dob.
        if (this.gsFormData.kycStatus.ovdSelf || this.gsFormData.kycStatus.normalSelf) {
          // convert dob in respected format
          for (let keys in tempAdultDetails) {
            // There are chances date is patched along with orignal time stamp
            if (keys.includes('dob') && !tempAdultDetails[keys].includes('T')) {
              tempAdultDetails[keys] = (new Date(tempAdultDetails[keys]));
            }
          }
        }
        // Looping through keys
        if (JSON.stringify(tempAdultDetails) != JSON.stringify(this.adultDetails.value[index])) {
          nonEqualMembers.push(index);
        }
      });
    }
    // If the length is only not equal that means adult data is been modified
    else if (this.adultDetails.length != this.gsFormData.quoteFormData.adultDetails.length) {
      nonEqualMembers.push(0);
    }

    // If there is mismatch of data in terms of length or details
    if (nonEqualMembers.length > 0) {
      resetFlag = true;
    }
    return resetFlag;
  }

  setOtherFormFlags() {
    this.gsFormData.insuredDataSet = false;
    this.gsFormData.kycDataSet = false;
    this.gsFormData.kycStatus.ekycMandatory = false;
    this.gsFormData.kycStatus.normal = false;
    this.gsFormData.kycStatus.normalSelf = false;
    this.gsFormData.kycStatus.ovd = false;
    this.gsFormData.kycStatus.ovdSelf = false;
    this.gsFormData.kycStatus.isKYCDoneSuccessfully = false;
    this.gsFormData.kycStatus.idType = false;
    this.gsFormData.kycStatus.certNumber = '';
    this.gsFormData.kycStatus.isPilotUser = false;
    this.gsFormData.kycFormData = this.healthDataStorageService.resetKYCdata();
  }

  constructPremiumData(response: HealthDetails) {
    this.gsFormData.premiumFormData.totalPremium = response.TotalPremium;
    this.gsFormData.premiumFormData.basicPremium = response.BasicPremium;
    this.gsFormData.premiumFormData.totalTax = response.TotalTax;
    this.gsFormData.premiumFormData.tenure = this.tenureDuration;
    this.gsFormData.premiumFormData.QuoteId = response.QuoteId;
    this.gsFormData.premiumFormData.SubProductCode = response.SubProductCode;
    this.gsFormData.premiumFormData.PfQuoteId = response.PFQuoteID;
    this.gsFormData.premiumFormData.InstallmentNetPremium = response.InstallmentNetPremium;
    this.gsFormData.premiumFormData.InstallmentTaxAmount = response.InstallmentTaxAmount;
    this.gsFormData.premiumFormData.InstallmentTotalPremium = response.InstallmentTotalPremium;
    if (this.goldenShieldForm.controls['zone'].value == "Yes") {
      this.zoneMappedWithPincode = "Zone_I"
    }
    let c = this.zoneMappedWithPincode == "Zone_III" ? 'C' : '';
    let c1 = this.zoneMappedWithPincode == "Zone_II" ? 'B' : c;
    this.gsFormData.premiumFormData.zone = this.zoneMappedWithPincode == "Zone_I" ? 'A' : c1;
    this.gsFormData.premiumDataSet = true;
    this.formValid = true;
    this.healthService.setHealthDataStorage(this.gsFormData);
    this.customStepperService.emitStepData({ status: true, buttonId: 'f1' });

  }

  getadultchilddataforquickquote() {
    if (this.gsFormData.quickquoteData.adultDetails.length > 0) {
      this.gsFormData.quickquoteData.adultDetails.forEach((data, index) => {
        this.adultDetails.push(this.createAdultDetails(index));
        // Patch values of adults
        // Below block is written because when there is self data that get's patched from kyc is not in date format
        // So to handle it we need to convert in into date type
        for (let adultKeys in data) {
          if (adultKeys.includes('dob')) {
            let dobValue = !data[adultKeys].includes('T') ? new Date(data[adultKeys]) : data[adultKeys]
            this.adultDetails.at(index).get(adultKeys).setValue(dobValue);
          }
          else {
            this.adultDetails.at(index).get(adultKeys).setValue(data[adultKeys]);
          }
        }
      })

      // We need to highlight the selected adult also
      let selectedAdultIndex = this.gsFormData.quickquoteData.adultDetails.length - 1;
      this.adultCountData.forEach((adultCount, index) => {
        if (index == selectedAdultIndex) {
          adultCount.selected = true;
        }
        else {
          adultCount.selected = false;
        }
      })
    }
  }

  patchQuickQuoteData() {
    this.healthDataStorageService.getHealthDataStorage();
    this.goldenShieldForm.patchValue({
      pincode: this.gsFormData.quickquoteData.pincode,
      cityState: this.gsFormData.quickquoteData.cityState,
      // adultDetails: this.gsFormData.quickquoteData.adultDetails,
      childDetails: this.gsFormData.quickquoteData.childDetails,
      policyTenure: this.gsFormData.quickquoteData.policyTenure ? this.gsFormData.quickquoteData.policyTenure : this.hapDataModal.tenurePeriod[0],
      applicantAnnualSum: this.gsFormData.quickquoteData.applicantAnnualSum
    })
    this.fetchDataAsPerPincode('', this.gsFormData.quickquoteData.pincode)
  }

  getMemberRequest() {
    let members = [];
    if (this.adultDetails.length > 0) {
      this.adultDetails.value.forEach((element, i) => {
        this.adultDetails.value[i]['Gender' + `${i + 1}`] = this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips);
        members.push({
          DOB: this.utilityService.formatDate(element['dobAdult' + (i + 1)]),
          MemberType: "Adult", RelationshipwithApplicant: element['adultRealtionship' + (i + 1)],
          VaccinationDate: this.utilityService.formatDate(element['adultVaccinationDate' + (i + 1)]),
          Gender: this.utilityService.setGender(element['adultRealtionship' + (i + 1)], this.adultRelationShips)
        })
      });
    }
    return members;
  }

  memberTabClicked(event) {
    this.stopPropogation(event);
  }

  stopPropogation(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  incrementMember() {
    if (this.adultValue == 1) {
      this.adultValue = 2;
      this.adultDetails.push(this.createAdultDetails(1));
    } else {
      this.utilityService.popUpDialog('Cannot select more than 2 member');
    }
  }

  decrymentMember() {
    if (this.adultValue == 2) {
      this.adultValue = 1;
      this.adultDetails.removeAt(1);
    } else {
      this.utilityService.popUpDialog('Cannot select less than 1 member');
    }
  }

  adultCountSelected(adultValue: string, event) {
    let index = parseInt(adultValue) - 1;
    switch (adultValue) {

      case '1': {
        if (!this.adultCountData[index].selected) {
          this.adultCountData[index].selected = true;
          this.adultCountData[index + 1].selected = false;
        }

        if (this.adultDetails.length > 1) {
          this.adultRelationShipError = false;
          this.adult2DateError = false;
          this.adultDetails.removeAt(this.adultDetails.length - 1);
        }


        else if (this.adultDetails.length == 0) {
          this.adultRelationShipError = false;
          this.adult2DateError = false;
          this.adultDetails.push(this.createAdultDetails(0));
        }
        break;
      }

      case '2': {
        if (!this.adultCountData[index].selected) {
          this.adultCountData[index].selected = true;
          this.adultCountData[index - 1].selected = false;

          if (this.adultDetails.length == 1) {
            this.adultRelationShipError = false;
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(1));
          }


          else if (this.adultDetails.length == 0) {
            this.adultRelationShipError = false;
            this.adult2DateError = false;
            this.adultDetails.push(this.createAdultDetails(0));
            this.adultDetails.push(this.createAdultDetails(1));
          }
        }
        break;
      }
      default : {
        break;
      }
    }

  }

  adultsDateComparison() {
    if (this.adultDetails.length == 2) {
      if (this.adultDetails.value[1]['dobAdult2'] != '' && this.utilityService.dateDiff(this.adultDetails.value[1]['dobAdult2'], this.adultDetails.value[0]['dobAdult1'])) {
        this.adult2DateError = true;
      }
      else {
        this.adult2DateError = false;
      }
    }
    else {
      this.adult2DateError = false;
    }
  }


  getInsuredMinMaxDate(memberType: string, minMaxType: string) {
    if (memberType == 'Adult') {
      let adultMaxDate = this.utilityService.subtractYears(new Date(), 56);

      let yearsdate = this.utilityService.subtractYears(new Date(), 85);
      let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
      let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

      return minMaxType == 'min' ? adultMinDate : adultMaxDate;
    }
  }

  getMinMaxDateVaccine(minMaxType: string) {
    let minYear = 1;
    let maxYear = 0;
    let maxMonth = '';
    let minMonth = '';
    let vaccineMaxYear = new Date().getFullYear() - maxYear
    let vaccineMinYear = new Date().getFullYear() - minYear;
    this.vaccineMinMaxDate = this.utilityService.getMinMaxDate(vaccineMaxYear, vaccineMinYear, maxMonth, minMonth);
    return minMaxType == 'min' ? this.vaccineMinMaxDate.minDate : this.vaccineMinMaxDate.maxDate;
  }

  vaccinationDateValidation(index: number) {
    let adultControls = this.adultDetails.controls[index] as FormGroup;
    if (this.adultDetails.value[index]['adultVaccinationStatus' + (index + 1)] == 'Yes') {
      adultControls.controls['adultVaccinationDate' + (index + 1)].setValidators([Validators.required]);
      adultControls.controls['adultVaccinationDate' + (index + 1)].updateValueAndValidity();
    }
    else {
      adultControls.controls['adultVaccinationDate' + (index + 1)].setValue('');
      adultControls.controls['adultVaccinationDate' + (index + 1)].clearValidators();
      adultControls.controls['adultVaccinationDate' + (index + 1)].updateValueAndValidity();
    }
  }


  getMasterData() {
    this.subscription.push(this.agentV1Service_M.agentAppMasterData().subscribe({
      next: (data: AgentMasterResponsePayload) => {
        if (data.StatusCode == Constants.statusCode_success) {
          this.agentDetails = data;
          this.getHealthGSMasterData();
        }
      },
      error: (err) => {
      }
    })
    );
  }


  getHealthGSMasterData() {
    let data =
    {
      "UserID": this.agentDetails.Data.AgentID.toString(),
      "SubType": this.hapDataModal.subProductType.GS.toString(),
      "isCSC": true,
      "isCHIRefileFlag": true
    }
    this.subscription.push(this.goldenShieldService_m.getHealthGSMasterData(data).subscribe({
      next: (response: GoldenShieldResponsePayload) => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.gsMasterData = response;
          this.sumInsuedDetails = this.gsMasterData.Data.SumInsuredDetails;
          this.onSelectingSumInsured(this.gsFormData.quoteFormData.applicantAnnualSum);
        }

      },
      error: error => {
        //this.patchExistingAddons();
        this.invalidPinCodeMessage = '';
        this.stateFetched = false;
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        this.popupService.openGenericPopup(this.errorPopupData);
      }
    })
    )
  }

  private selectScrollListener: () => void
  @ViewChildren(MatSelect) selects: QueryList<MatSelect>;
  //Apply below function as click fn on mat-select and blur fn should have remove listener
  handleMatSelectClick() {
    //inject renderer as Renderer2 in constructor
    this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
      this.selects.forEach(select => {
        if (select.panelOpen) {
          select.close();
          this.removeMatSelectScrollListener();
        }
      });
    });
  }

  removeMatSelectScrollListener() {
    if (this.selectScrollListener) {
      this.selectScrollListener();
    }
  }

  addonSelector() {
    if (parseInt(this.goldenShieldForm.controls['applicantAnnualSum'].value) < 500000) {
      this.vdAddonCoverDisable = true;
    } else {
      this.vdAddonCoverDisable = false;
      this.vdAddonCover = this.gsMasterData.Data.AddonDetails.filter(x => x.CoverId == 29);
    }
    this.CoPaymentAddonCover = this.gsMasterData.Data.AddonDetails.filter(x => x.CoverId == 28);
    this.addonCover = this.gsMasterData.Data.AddonDetails.filter(x => x.CoverId != 29 && x.CoverId != 28);

    // create addoncovers formcontrol
    if (this.gsFormData.quoteFormData.quoteSummary.length > 0) {
      this.gsFormData.quoteFormData.quoteSummary.forEach((ele, ind) => {
        this.goldenShieldForm.addControl(ele['addonCoverCode'], new FormControl(''));
      })
      //this.patchGSAddonData(this.gsFormData.quoteFormData.selectedAddonsGS);
      this.patchGSAddonData(this.gsFormData.quoteFormData.quoteSummary);
    } else {
      this.addonCoverForm(this.vdAddonCover, this.CoPaymentAddonCover, this.addonCover);
    }

  }

  onSelectingSumInsured(value) {
    if (value >= 500000) {
      this.sumInsuedDetails.forEach(x => {
        if (x.SumAmount == value) {
          this.vdAddonCoverDisable = true;
          this.VDValue = x.VDValues[0].value;
          this.goldenShieldForm.get('voluntaryDeductible').setValue(this.VDValue);
        }
      })
    }
    this.addonSelector();
  }

  checkboxChanged(event, value, addonsArray, addonVarName, checkboxNumber: number) {
    if (event.checked) {
      if (value === 29) {
        this.CoPayment = false;
        this.VDValueEnable = true;
        //this.onSelectingSumInsured(this.goldenShieldForm.controls['applicantAnnualSum'].value)
        this.goldenShieldForm.get("Option_Add_On2_B").setValue(false);
        this.goldenShieldForm.controls['voluntaryDeductible'].setValue(this.VDValue);
        this.goldenShieldForm.addControl('voluntaryDeductible', new FormControl(this.VDValue));
      } else if (value === 28) {
        this.CoPayment = true;
        this.VDValueEnable = false;
        this.goldenShieldForm.get("Option_Add_On3_C").setValue(false);
        this.goldenShieldForm.addControl('sliderControl', new FormControl('20'));

      }

    }
    if (checkboxNumber) {
      this['checkbox' + checkboxNumber] = true;
      let uncheckNumber = checkboxNumber == 1 ? 2 : 1;
      this['checkbox' + uncheckNumber] = false;

    }

    if (!event.checked) {
      if (value == 29) {
        this.VDValueEnable = false;
        // this.VDValue = 0;
        this.goldenShieldForm.get("Option_Add_On3_C").setValue(false);
        //this.goldenShieldForm.controls['voluntaryDeductible'].setValue(0);
      }
      if (value == 28) {
        this.CoPayment = false;
        this.sliderControlValue = '';
        this.goldenShieldForm.get("Option_Add_On2_B").setValue(false);
        this.goldenShieldForm.controls['sliderControl'].setValue('');
      }
    }
    let toBeUnchecked = this.checkbox1 ? 'CoPaymentAddonCover' : this.checkbox2 ? 'vdAddonCover' : '';
    if (toBeUnchecked) {
      addonsArray.filter((addon, index) => {
        if (addon.CoverId == value) {
          return addonsArray[index].IsAddonSelected = event.checked;

        }
        return addonsArray[index];
      })
      this[toBeUnchecked].filter(data => data['IsAddonSelected'] = false);
    }
    this[addonVarName] = addonsArray;
  }

  checkIsGSTRegistered() {
    return this.stateDetails.stateId != this.hapDataModal.stateId ? false : true;
  }


  constructAddonRequest2() {
    let tempAddons;
    this.finalGSAddon = [];
    let payLoadAddonObj = {}
    let addons = [...this.addonCover, ...this.vdAddonCover, ...this.CoPaymentAddonCover];

    let gsFormValue = this.goldenShieldForm.getRawValue();
    addons.forEach((ele, ind) => {
      let addonReq = {};
      for (let key in gsFormValue) {
        if (ele['CoverCode'] == key) {
          let keyName = 'Addon' + ele['CoverCode'].match(/\d+/g)[0];
          if (gsFormValue[key]) {
            payLoadAddonObj[keyName] = gsFormValue[key];
            addonReq['id'] = keyName;
            addonReq[keyName] = gsFormValue[key];
            addonReq["addonName"] = ele['CoverText'];
            addonReq['addonCoverId'] = ele['CoverId'];
            addonReq['addonCoverCode'] = ele['CoverCode'];
            addonReq['CoverId'] = ele['CoverId'];
            addonReq['coverText'] = ele['CoverText'];
            addonReq['coverCode'] = keyName;
            addonReq['isSelected'] = true;
            addonReq['isAddonMemberBasis'] = false;
            addonReq['isMemberVisible'] = false;

          } else {
            payLoadAddonObj[keyName] = false;
            addonReq['id'] = keyName;
            addonReq[keyName] = false;
            addonReq["addonName"] = ele['CoverText'];
            addonReq['addonCoverId'] = ele['CoverId'];
            addonReq['addonCoverCode'] = ele['CoverCode'];
            addonReq['CoverId'] = ele['CoverId'];
            addonReq['coverText'] = ele['CoverText'];
            addonReq['coverCode'] = keyName;
            addonReq['isSelected'] = false;
            addonReq['isAddonMemberBasis'] = false;
            addonReq['isMemberVisible'] = false;
          }
        }
      }
      this.finalGSAddon.push(addonReq);
    })
    return payLoadAddonObj;
  }

  constructRequest(): SaveEditQuoteV1Request {
    let tenure = (this.goldenShieldForm.value.policyTenure).split(' ')[0];
    this.tenureDuration = parseInt(tenure);
    let addOnsRequest;
    let addOnsRequest2;

    let vaccinationtaken = this.adultDetails.value.filter((vaccinationStatus, i) =>
      vaccinationStatus['adultVaccinationStatus' + (i + 1)] == 'Yes'
    )
    let adult1DOB = this.adultDetails.value.length > 0 ? this.utilityService.formatDate(this.adultDetails.value[0]['dobAdult1']) : '';
    let adult1Age = this.adultDetails.value.length > 0 ? this.utilityService.calculateAge(this.adultDetails.value[0]['dobAdult1']) : '';

    addOnsRequest2 = this.constructAddonRequest2();

    this.selectedValue = this.goldenShieldForm.get('sliderControl').value;
    this.sliderControlValue = this.checkbox2 && this.selectedValue ? this.selectedValue.toString() : '50%';


    let requestData: SaveEditQuoteV1Request = {
      "ProductType": "HGS", //this.hapDataModal.prodType,
      "UserType": this.utilityService.isEmptyOrNull(this.channelData.UT) ? "AGENT" : this.channelData.UT,
      "NoOfAdults": (this.adultDetails.length).toString(),
      "NoOfKids": '0',
      "AgeGroup": '',
      "SubLimit": '',
      "SubProductType": this.hapDataModal.subProductType.GS,
      "Tenure": this.tenureDuration,
      "IsJammuKashmir": this.checkIsGSTRegistered(),
      "GSTStateCode": this.stateDetails.stateId,
      "CityCode": this.stateDetails.cityId,
      "SumInsured": this.goldenShieldForm.value.applicantAnnualSum,
      "GSTStateName": this.stateDetails.stateName ? this.stateDetails.stateName : '',
      "isGSTRegistered": this.checkIsGSTRegistered(),
      "CalculateHigherSI": false,
      "SaveQuote": true,
      // "ProductName": 'HEALTH_GOLDEN_SHIELD',
      // "CHIProductName": 'HEALTH_GOLDEN_SHIELD',
      //"IsEmployeeProduct": false,
      //"health_product_type": false,
      "Members": this.getMemberRequest(),
      "IpAddress": this.utilityService.isEmptyOrNull(this.channelData.CIP) ? this.hapDataModal.ipAddress : this.channelData.CIP,
      "IsOtherLoadingDiscount": ((this.adultDetails.value.length !== 0) && (this.adultDetails.value.length == vaccinationtaken.length)) ? true : false,
      "DobOfEldestMember": adult1DOB,
      "EldestMemberAge": adult1Age.toString(),
      "City": this.stateDetails.cityName,
      "Pincode": (this.goldenShieldForm.value.pincode).toString(),
      "ZoneUpgrade": this.goldenShieldForm.controls['zone'].value == 'Yes' ? true : false,
      "VoluntaryCopaymentPercentage": this.sliderControlValue.replace('%', ''),
      "VolDedutible": this.VDValueEnable ? this.VDValue : 0,
      "prodType": this.goldenShieldForm.value.productType == 'New' ? 'N' : 'R',
      //"InsuredAmount": this.goldenShieldForm.value.applicantAnnualSum
    }
    Object.assign(requestData, addOnsRequest2)
    return requestData;
  }

  createQuoteSummaryForm() {

    let arrayDetails = [];
    let addons = [...this.addonCover, ...this.vdAddonCover, ...this.CoPaymentAddonCover];
    addons.forEach(element => {
      let item = {
        coverId: element.CoverId, coverCode: element.CoverCode, coverText: element.CoverText, isMemberVisible: false,//(element.IsForAdult && !element.IsForChild),
        inputType: '', isSelected: false
      }
      item[element.CoverCode] = false;
      let addonValue = `AddOn${element.CoverCode.split('GS_Option_Add_On')[1]}`;
      let request = this.constructRequest();
      item[element.CoverCode] = request[addonValue];
      arrayDetails.push(item);
    })
    return arrayDetails;

  }


  getQuote(event: EmitButtonData) {
    this.formSubmitted = true;

    if (!this.goldenShieldForm.valid || this.adult2DateError || this.invalidPinCodeMessage !== '') {
      for (const key of Object.keys(this.goldenShieldForm.controls)) {
        if (this.goldenShieldForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('#' + key + '.ng-invalid');
          invalidControl.scrollIntoView({ behaviour: "instant", block: "center", inline: "center" });
          invalidControl.focus();
          break;
        }
      }
      this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
    }
    else {
      let count = 0;
      if (this.adultDetails.length > 1) {
        this.adultDetails.value.forEach((data, index) => {
          if (data[`adultRealtionship${index + 1}`] == 'SELF') {
            count++;
          }
        })
      }
      if (count > 1) {
        this.errorPopupData = this.popupService.fetchErrorPopupMsg('SELF can be selected only for one Insured');
        this.popupService.openGenericPopup(this.errorPopupData);
      }
      else {
        this.formSubmitted = false;
        let request: SaveEditQuoteV1Request = this.constructRequest();
        this.subscription.push(this.goldenShielsService_T.saveEditQuoteData(request).subscribe({
          next: response => {
            if (response.StatusCode == Constants.statusCode_success) {
              if (response.Data.HealthDetails.CorrelationId) {
                this.gsFormData.quoteFormData.corelationId = response.Data.HealthDetails.CorrelationId;
                this.healthDataStorageService.setHealthDataStorage(this.gsFormData);
              }
              if (response.Data.HealthDetails.TotalPremium == 0) {
                this.errorPopupData = this.popupService.fetchErrorPopupMsg(response.StatusDesc);
                this.popupService.openGenericPopup(this.errorPopupData);

              }
              else {
                this.setGoldenShieldFormData(response.Data.HealthDetails);
                this.patchingDone = true;
                this.initialFormState = this.goldenShieldForm.value;
              }

            }
            else {
              this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            }
            this.showPremiumDetails(true);
          },
          error: (err) => {
            this.success = false;
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          }
        }))
      }
    }
  }


  changeInProductType() {
    // Check if portability selected reset emi values
    if (this.goldenShieldForm.value.productType != 'New') {
      this.portabilityDataPresent(true);
    }
    else {
      this.portabilityDataPresent(false);
    }
  }

  portabilityDataPresent(data: boolean) {
    this.customStepperService.portabilityPresent(data);
  }

  showPremiumDetails(data: boolean) {
    this.customFooterService.showPremiumDetails(data);
  }

  addonCoverForm(vdAddons, coPayAddons, normalAddons) {
    vdAddons.map((vdAddon, vdInd) => {
      this.goldenShieldForm.addControl(vdAddon.CoverCode, new FormControl(''));
    })
    coPayAddons.map((coPayAddon, coPayInd) => {
      this.goldenShieldForm.addControl(coPayAddon.CoverCode, new FormControl(''));
    })
    normalAddons.map((normalAddon, norAddonInd) => {
      this.goldenShieldForm.addControl(normalAddon.CoverCode, new FormControl(''));
    })


  }

  patchGSAddonData(addonData) {
    this.addonCover = [];
    this.vdAddonCover = [];
    this.CoPaymentAddonCover = [];

    addonData.map((ele, ind) => {
      if (ele['CoverId'] == 29) {
        ele['CoverCode'] = ele['addonCoverCode'];
        ele['CoverText'] = ele['addonName'];
        this.vdAddonCover.push(ele);
        if (ele[ele.id]) {
          this.vdAddonCoverDisable = false;
          this.VDValueEnable = true;
          this.CoPayment = false;
          this.goldenShieldForm.get(ele['addonCoverCode']).setValue(true);
          // this.goldenShieldForm.addControl(ele['addonCoverCode'], new FormControl(true));
          this.goldenShieldForm.get('voluntaryDeductible').setValue(this.VDValue);
          //this.goldenShieldForm.get("sliderControl").setValue('20');
          //this.checkboxChanged(true,ele['CoverId'],this.vdAddonCover,"vdAddonCover",1);

        } else {
          this.goldenShieldForm.get(ele['addonCoverCode']).setValue(false);
          // this.goldenShieldForm.addControl(ele['addonCoverCode'], new FormControl(false));
          this.goldenShieldForm.get('voluntaryDeductible').setValue(0);
          // this.checkboxChanged(false,ele['CoverId'],this.vdAddonCover,"vdAddonCover",1);
          this.VDValueEnable = false;
          // this.vdAddonCoverDisable = true;
          // this.CoPayment = false;
        }
      } else if (ele['CoverId'] == 28) {
        ele['CoverCode'] = ele['addonCoverCode'];
        ele['CoverText'] = ele['addonName'];
        this.CoPaymentAddonCover.push(ele);
        if (ele[ele.id]) {
          this.goldenShieldForm.get(ele['addonCoverCode']).setValue(true);
          // this.goldenShieldForm.addControl(ele['addonCoverCode'], new FormControl(true));
          this.goldenShieldForm.get('sliderControl').setValue(this.gsFormData.quoteFormData.copaymentPercentTaken);
          this.goldenShieldForm.addControl('sliderControl', new FormControl('20'));
          // this.checkboxChanged(true,ele['CoverId'],this.vdAddonCover,"CoPaymentAddonCover",2);
          this.vdAddonCoverDisable = false;
          this.CoPayment = true;
        } else {
          this.goldenShieldForm.get(ele['addonCoverCode']).setValue(false);
          // this.goldenShieldForm.addControl(ele['addonCoverCode'], new FormControl(false));
          this.goldenShieldForm.get('sliderControl').setValue('20')
          // this.checkboxChanged(false,ele['CoverId'],this.vdAddonCover,"CoPaymentAddonCover",2);
          // this.VDValueEnable = false;
          this.CoPayment = false;
        }
      } else {
        ele['CoverCode'] = ele['addonCoverCode'];
        ele['CoverText'] = ele['addonName'];
        this.addonCover.push(ele);
        if (ele[ele.id]) {
          this.goldenShieldForm.get(ele['addonCoverCode']).setValue(true);
          // this.goldenShieldForm.addControl(ele['addonCoverCode'], new FormControl(true));
        } else {
          this.goldenShieldForm.get(ele['addonCoverCode']).setValue(false);
          // this.goldenShieldForm.addControl(ele['addonCoverCode'], new FormControl(false));
        }
      }

    })
    setTimeout(() => {
      if (this.gsFormData.quoteDataSet && !this.patchingDone) {
        this.patchingDone = true;
      }
    }, 0);
  }

  onRefresh(status) {
    if (status) {
      this.getMasterData();
      this.getHealthProposalRelationships();
    }
  }

  unsubscribeSubscription() {
    this.subscription.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  ngOnDestroy() {
    this.unsubscribeSubscription();
  }
}
