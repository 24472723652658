<form [formGroup]="studentMedicalTravelForm" *ngIf="!multipleServiceError && loadFormContent">
   <div class="matstickycard">
      <mat-card class="innerbox borderinnerbox producttype">
         <div class="fieldsdiv">
            <div class="row ">
               <div class="col-sm-3 form-group mwidth">
                  <mat-label class="input-group__label" for="productName"> Product name</mat-label>
                  <input type="text" id="myInput" class="input-group__input" value="Student Medical" readonly autocomplete="off"/>
               </div>
               <div class="col-sm-3 form-group">
                  <mat-label class="input-group__label" for="state">State</mat-label>
                  <mat-select class="input-group__input cstm_select" placeholder="Select State" id="state"
                     formControlName="state" (selectionChange)="changeState($event.value)">
                     <ng-container *ngFor="let item of stateMaster; let last = last">
                        <mat-option value={{item.StateName}}>
                           {{item.StateName}}
                        </mat-option>
                        <mat-divider *ngIf="!last"></mat-divider>
                     </ng-container>
                  </mat-select>
                  <mat-error class="error-margin"
                     *ngIf="this.studentMedicalTravelForm.controls['state'].errors?.['required'] && isSubmitted">
                     State is required
                  </mat-error>
               </div>
            </div>
         </div>
      </mat-card>
      <mat-card class="innerbox borderinnerbox pt-2">
         <h3>Travel Details</h3>
         <div class="fieldsdiv">
            <div class="row ">
               <div class="col-sm-3 form-group">
                  <label for="isAlreadyTraveledFromIndia" class="singlelabel mb-2" style="width:100%">Has the student
                     already traveled from India ? *</label>
                  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="policyToggle_group"
                     id="isAlreadyTraveledFromIndia" formControlName="isAlreadyTraveledFromIndia"
                     (change)="changeIsAlreadyTraveledFromIndia($event.value)">
                     <mat-button-toggle class="PolicyBtn" [value]="true">Yes</mat-button-toggle>
                     <mat-button-toggle class="PolicyBtn" [value]="false">No</mat-button-toggle>
                  </mat-button-toggle-group>
               </div>
               <ng-container *ngIf="studentMedicalTravelForm.value.isAlreadyTraveledFromIndia">
                  <div class="col-sm-3 form-group">
                     <mat-label class="input-group__label">First departure date from India On *
                     </mat-label>
                     <input class="input-group__input dob" matInput [matDatepicker]="departureDateFromIndia"
                        id="departureDateFromIndia" [min]="departureMinMaxDate.minDate"
                        [max]="departureMinMaxDate.maxDate" (click)="departureDateFromIndia.open()"
                        placeholder="DD/MM/YYYY" formControlName="departureDateFromIndia" readonly>
                     <mat-datepicker-toggle matSuffix [for]="departureDateFromIndia">
                     </mat-datepicker-toggle>
                     <mat-datepicker #departureDateFromIndia touchUi>
                     </mat-datepicker>
                     <mat-error class="error-margin"
                        *ngIf="this.studentMedicalTravelForm.controls['departureDateFromIndia'].errors?.['required'] && isSubmitted">
                        departure date from india is required
                     </mat-error>
                  </div>
                  <div class="col-sm-3 form-group">
                     <mat-label class="input-group__label">Policy Start Date On *
                     </mat-label>
                     <input class="input-group__input dob" matInput [matDatepicker]="policyStartDate"
                        id="policyStartDate" (click)="policyStartDate.open()" placeholder="DD/MM/YYYY"
                        formControlName="policyStartDate" (dateChange)="changePolicyStartDate($event.value)" readonly>
                     <mat-datepicker-toggle matSuffix [for]="policyStartDate">
                     </mat-datepicker-toggle>
                     <mat-datepicker #policyStartDate touchUi disabled>
                     </mat-datepicker>
                     <mat-error class="error-margin"
                        *ngIf="this.studentMedicalTravelForm.controls['policyStartDate'].errors?.['required'] && isSubmitted">
                        Policy start date is required
                     </mat-error>
                  </div>
               </ng-container>
               <ng-container *ngIf="!studentMedicalTravelForm.value.isAlreadyTraveledFromIndia">
                  <div class="col-sm-3 form-group">
                     <mat-label class="input-group__label">Leaving India On *
                     </mat-label>
                     <input class="input-group__input dob" matInput [matDatepicker]="leavingIndiaOn" id="leavingIndiaOn"
                        [min]="leavingMinMaxDate?.minDate" [max]="leavingMinMaxDate?.maxDate"
                        (click)="leavingIndiaOn.open()" placeholder="DD/MM/YYYY" formControlName="leavingIndiaOn"
                        (dateChange)="changePolicyStartDate($event.value)" readonly>
                     <mat-datepicker-toggle matSuffix [for]="leavingIndiaOn">
                     </mat-datepicker-toggle>
                     <mat-datepicker #leavingIndiaOn touchUi>
                     </mat-datepicker>
                     <mat-error class="error-margin"
                        *ngIf="this.studentMedicalTravelForm.controls['leavingIndiaOn'].errors?.['required'] && isSubmitted">
                        Leaving date is required
                     </mat-error>
                  </div>
               </ng-container>
            </div>
            <div class="row">
               <div class="col-sm-3 form-group mwidth">
                  <mat-label class="input-group__label" for="policyDuration"> Policy Duration</mat-label>
                  <mat-select class="input-group__input cstm_select" placeholder="Select Policy Duration"
                     id="policyDuration" formControlName="policyDuration"
                     (selectionChange)="changePolicyDuration($event.value)">
                     <ng-container *ngFor="let item of policyDurationDetails; let last = last">
                        <mat-option [value]="item.value">
                           {{item.label}}
                        </mat-option>
                        <mat-divider *ngIf="!last"></mat-divider>
                     </ng-container>
                  </mat-select>
                  <mat-error class="error-margin"
                     *ngIf="this.studentMedicalTravelForm.controls['policyDuration'].errors?.['required'] && isSubmitted">
                     Policy duration is required
                  </mat-error>
               </div>
               <div class="col-sm-3 form-group mwidth" *ngIf="studentMedicalTravelForm.value.policyDuration == 1">
                  <mat-label class="input-group__label" for="policyDurationInDays"> Days </mat-label>
                  <input type="number" matInput class="input-group__input health planselect" placeholder="Enter product"
                     formControlName="policyDurationInDays" id="policyDurationInDays" [minlength]="3" autocomplete="off"
                     (change)="changeCustomDuration($any($event.target).value)" (keypress)="isNumber($any($event))">
                  <mat-error class="error-margin"
                     *ngIf="this.studentMedicalTravelForm.controls['state'].errors?.['required'] && isSubmitted">
                     Days is required
                  </mat-error>
               </div>
               <div class="col-sm-3 form-group">
                  <mat-label class="input-group__label">Policy End Date *
                  </mat-label>
                  <input class="input-group__input dob" matInput [matDatepicker]="policyEndDate" id="policyEndDate"
                     (click)="policyEndDate.open()" placeholder="DD/MM/YYYY" formControlName="policyEndDate" readonly>
                  <mat-datepicker-toggle matSuffix [for]="policyEndDate">
                  </mat-datepicker-toggle>
                  <mat-datepicker #policyEndDate touchUi disabled>
                  </mat-datepicker>
                  <mat-error class="error-margin"
                     *ngIf="this.studentMedicalTravelForm.controls['policyEndDate']?.errors?.['required'] && isSubmitted">
                     Policy end date is required
                  </mat-error>
               </div>
               <ng-container formArrayName="members">
                  <ng-container *ngFor="let item of studentMedicalTravelForm.get('members')['controls'];let i = index"
                     [formGroupName]="i">
                     <div class="col-sm-3 form-group">
                        <mat-label class="input-group__label">Student Date of Birth *
                        </mat-label>
                        <input class="input-group__input dob" matInput [matDatepicker]="dob" id="members"
                           [min]="memberMinMaxDate.minDate" [max]="memberMinMaxDate.maxDate" (click)="dob.open()"
                           placeholder="DD/MM/YYYY" formControlName="DOB" (dateChange)="changeDateOfBirth(i)" readonly>
                        <mat-datepicker-toggle matSuffix [for]="dob">
                        </mat-datepicker-toggle>
                        <mat-datepicker #dob touchUi>
                        </mat-datepicker>
                        <mat-error class="error-margin" *ngIf="this.studentMedicalTravelForm.controls['members']['controls'][i]['controls']['DOB'].hasError('required')
                     && isSubmitted">
                           DOB is required
                        </mat-error>
                     </div>
                     <div class="col-sm-3 form-group mwidth">
                        <mat-label class="input-group__label" for="relationship"> Relationship</mat-label>
                        <mat-select class="input-group__input cstm_select" placeholder="Select Relationship"
                           id="members" formControlName="RelationshipwithApplicant" (selectionChange)="changeRelationship($event.value,i)">
                           <ng-container *ngFor="let item of relationshipMaster; let last = last">
                              <mat-option [value]="item.RelationshipName">
                                 {{item.RelationshipName}}
                              </mat-option>
                              <mat-divider *ngIf="!last"></mat-divider>
                           </ng-container>
                        </mat-select>
                        <mat-error class="error-margin" *ngIf="this.studentMedicalTravelForm.controls['members']['controls'][i]['controls']['RelationshipwithApplicant'].hasError('required')
                     && isSubmitted">
                           Relationship is required
                        </mat-error>
                     </div>
                  </ng-container>
               </ng-container>
            </div>
            <div class="row">
               <div class="col-sm-3 form-group">
                  <label for="isVisitingduringStudies" class="singlelabel mb-2" style="width:100%">Visiting USA / Canada
                     during studies ? *</label>
                  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="policyToggle_group"
                     id="isVisitingduringStudies" formControlName="isVisitingduringStudies"
                     (change)="changeIsVisitingduringStudies($event.value)">
                     <mat-button-toggle class="PolicyBtn" [value]="true">Yes</mat-button-toggle>
                     <mat-button-toggle class="PolicyBtn" [value]="false">No</mat-button-toggle>
                  </mat-button-toggle-group>
                  <mat-error class="error-margin"
                     *ngIf="this.studentMedicalTravelForm.controls['isVisitingduringStudies'].errors?.['required'] && isSubmitted">
                     Visiting USA / Canada during studies is required
                  </mat-error>
               </div>
               <div class="col-sm-3 form-group mwidth">
                  <mat-label class="input-group__label" for="plan">Plan</mat-label>
                  <mat-select class="input-group__input cstm_select" placeholder="Select Plan" id="plan"
                     formControlName="plan" (selectionChange)="changePlan($event.value)">
                     <ng-container *ngFor="let item of studentMedicalTravelForm.value.planDetails; let last = last">
                        <mat-option value={{item.PlanName}}>
                           {{item.PlanName}}
                        </mat-option>
                        <mat-divider *ngIf="!last"></mat-divider>
                     </ng-container>
                  </mat-select>
                  <mat-error class="error-margin"
                     *ngIf="this.studentMedicalTravelForm.controls['plan'].errors?.['required'] && isSubmitted">
                     Plan is required
                  </mat-error>
               </div>
               <div class="col-sm-3 form-group mwidth" *ngIf="studentMedicalTravelForm?.value?.plan">
                  <mat-label class="input-group__label" for="sumInsured"> Sum Insured </mat-label>
                  <input type="text" matInput class="input-group__input health planselect" placeholder="Enter product"
                     type="text" formControlName="sumInsured" id="sumInsured" autocomplete="off">
                  <mat-error class="error-margin"
                     *ngIf="this.studentMedicalTravelForm.controls['sumInsured'].errors?.['required'] && isSubmitted">
                     Sum Insured is required
                  </mat-error>
               </div>
            </div>
            <div class="row">
               <div class="col-sm-3 form-group"
                  *ngIf="studentMedicalTravelForm?.value?.state?.toUpperCase() == 'KERALA'">
                  <label for="isKeralaGST" class="singlelabel mb-2" style="width:100%">Are you registered with GST ?
                     *</label>
                  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="policyToggle_group"
                     id="isKeralaGST" formControlName="isKeralaGST">
                     <mat-button-toggle class="PolicyBtn" [value]="true">Yes</mat-button-toggle>
                     <mat-button-toggle class="PolicyBtn" [value]="false">No</mat-button-toggle>
                  </mat-button-toggle-group>
               </div>
            </div>
         </div>
      </mat-card>
   </div>
</form>

<app-error-screen *ngIf="multipleServiceError" (refreshService)="onRefresh($event)">
</app-error-screen>

<app-custom-footer [normalButton]="true"
   [buttonData]="[{'label': 'Get quote', 'type': 'primary', 'source': 'quote', 'id': 'f1','disabled': false , 'hide': false}]"
   (buttonClicked)="getQuote($event)">
</app-custom-footer>