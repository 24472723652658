import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericService } from '../services/generic.service';
import { ElevateAddonsRequestPayload, ElevateAddonsResponsePayload, ElevateBUIServiceAddonResponse, ElevateMasterResponsePayload } from '../interface/elevate';
import { SaveEditProposalV2HAPRequestPayload, SaveEditProposalV2RequestPayload, SaveEditProposalV2ResponsePayload } from '../interface/max-protect';
import { SaveEditQuoteHapV1Request, TopupAndComboQuoteRequest, TopupAndComboQuoteResponsePayload } from '../interface/transactionHealthAdvantege';

@Injectable({
  providedIn: 'root'
})
export class ElevateService {

  constructor(private generic: GenericService) {

  }
  readonly baseURI = `${environment.baseURL}Elevate/`;
  readonly elevateMasterData = 'ElevateMasterData';
  readonly SaveEditQuote = 'SaveEditQuote';
  readonly elevateAddons = 'GetElevateAddons';
  readonly elevateProposal = 'SaveEditProposal';
  readonly elevateBUIServiceAddon = 'SaveEditQuoteV1';
  readonly elevateComboMasterData = 'ElevateComboMasterData';
  readonly elevateSuperSaverMasterData = 'ElevateSuperTopupMaster';
  readonly saveEditQuoteBooster = 'SaveEditQuoteBooster';
  readonly saverBoosterProposal = 'SaveEditProposalBooster';

  getElevateMasterData(): Observable<ElevateMasterResponsePayload> {
    const endpoint = `${this.baseURI}${this.elevateMasterData}`;
    return this.generic.genericService<ElevateMasterResponsePayload>(endpoint);
  }

  saveEditQuoteData(data): Observable<any> {
    const endpoint = `${this.baseURI}${this.SaveEditQuote}`;
    return this.generic.genericService<any>(endpoint, data);
  }

  fetchElevateAddons(data: ElevateAddonsRequestPayload): Observable<ElevateAddonsResponsePayload> {
    const endpoint = `${this.baseURI}${this.elevateAddons}`;
    return this.generic.genericService<ElevateAddonsResponsePayload>(endpoint, data);
  }

  fetchBUIElevateAddons(data: SaveEditQuoteHapV1Request): Observable<ElevateBUIServiceAddonResponse> {
    const endpoint = `${this.baseURI}${this.elevateBUIServiceAddon}`;
    return this.generic.genericService<ElevateBUIServiceAddonResponse>(endpoint, data);
  }

  saveProposal(data: SaveEditProposalV2RequestPayload | SaveEditProposalV2HAPRequestPayload): Observable<SaveEditProposalV2ResponsePayload> {
    const endpoint = `${this.baseURI}${this.elevateProposal}`;
    return this.generic.genericService<SaveEditProposalV2ResponsePayload>(endpoint, data);
  }

  // Elevate combo and super topup master data api calling
  fetchBoosterSaverMasterData(activateBooster: boolean): string {
    let masterUrl: string = activateBooster ? this.elevateSuperSaverMasterData : this.elevateComboMasterData;
    return masterUrl;
  }

  fetchMasterData(activateBooster: boolean): Observable<ElevateMasterResponsePayload> {
    let masterUrl = this.fetchBoosterSaverMasterData(activateBooster);
    const endpoint = `${this.baseURI}${masterUrl}`;
    return this.generic.genericService<ElevateMasterResponsePayload>(endpoint, '');
  }

  saveComboTopupQuote(data: TopupAndComboQuoteRequest): Observable<TopupAndComboQuoteResponsePayload> {
    const endpoint = `${this.baseURI}${this.saveEditQuoteBooster}`
    return this.generic.genericService<TopupAndComboQuoteResponsePayload>(endpoint, data);
  }

  saveSaverBoosterProposal(data: SaveEditProposalV2RequestPayload | SaveEditProposalV2HAPRequestPayload): Observable<SaveEditProposalV2ResponsePayload> {
    const endpoint = `${this.baseURI}${this.saverBoosterProposal}`;
    return this.generic.genericService<SaveEditProposalV2ResponsePayload>(endpoint, data);
  }

}