export class FamilyshieldModal {
    productType = ['New', 'Portability'];
    zoneCode = '3828';
    zoneData = ['ZONE 1', 'ZONE 2', 'ZONE 3'];
    tenurePeriod = [{id:1,value:'1 Year'},{id:2,value: '2 Years'}, {id:3,value:'3 Years'}];
// Number of adult dropdown
    // by default 1A should be shown
    adultCount = [
        { 'label': '1 Adult', 'value': '1', 'type': 'adult', 'selected': true, 'hide': false },
        { 'label': '2 Adult', 'value': '2', 'type': 'adult', 'selected': false, 'hide': false },
        { 'label': '3 Adult', 'value': '3', 'type': 'adult', 'selected': false, 'hide': false },
        { 'label': '4 Adult', 'value': '4', 'type': 'adult', 'selected': false, 'hide': false },
    ];
    stateId = '62';
    stateDetails = {
        'stateId': '',
        'cityId': '',
        'cityName': '',
        'stateName': '',
        'pincode': ''
    }
    memberButtonData = [
        { 'label': 'Adult', 'imgSrc': '../../../../assets/icons/Single Adult.svg', minusImgSrc: '../../../../assets/icons/minus.svg', plusImgSrc: '../../../../assets/icons/plus.svg', 'selected': false, 'hide': false, 'incDec': true },
        // { 'label': 'Child', 'imgSrc': '../../../../assets/icons/Single Child.svg', minusImgSrc: '../../../../assets/icons/minus.svg', plusImgSrc: '../../../../assets/icons/plus.svg', 'selected': false, 'hide': false, 'incDec': true }
    ]
}
