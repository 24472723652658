import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WrapperService } from 'src/app/shared/proxy-services/wrapper.service';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/proxy-services/customer.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { Constants } from 'src/app/shared/constants/constants';
import { ApiTokenResponsePayload, AryaAPIKycResp } from 'src/app/shared/interface/utilityApi';
import { Subscription, fromEvent } from 'rxjs';
import { environment } from 'src/environments/environment';
import kycDetailsControls from "src/assets/json/kyc-details.json";
import { gender, kycControls, patterns } from 'src/app/shared/enums/healthEnums';
import { FormFields } from 'src/app/shared/interface/formFieldsModal';
import { TravelDataStorage } from '../travel.modal';
import { FetchCustomerDetailsV1RequestPayload, FetchCustomerDetailsV1ResponseStructure, SearchCustomerRequestPayload, SearchCustomerResponseStructure } from 'src/app/shared/interface/customer';
import { KycDetailsResponse } from 'src/app/shared/interface/kycDetails';
import { TravelDataStorageService } from '../travel.service';
import { UtilityApiService } from 'src/app/shared/proxy-services/utility-api.service';
declare var $: any;
declare const AJL: any;

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit, OnChanges {
  customerType: string = 'New';
  showEKYC: boolean = false;
  isKYCDoneSuccessfully: boolean = false;
  showSearchPopup: boolean = false;
  private subscription: Subscription[] = [];

  @Input() kycDetails;
  @Input() isPilotUser: boolean;
  @Output() getKycDetails = new EventEmitter<any>();
  @Output() getExistingCustomerDetails = new EventEmitter<any>();
  @Output() changeCustomerType = new EventEmitter<any>();
  @Input() isKYCDoneSuccessfullyForPilotuser: boolean = true;

  searchForCustomerControls: FormFields[] = [];
  customerTypeDetails: FormFields;
  searchCustomerForm: FormGroup;

  customerListData = [];
  healthFormData: TravelDataStorage;

  ekycMandatory: boolean = false;
  displayCustomerList: boolean = false;

  numberOfRecords: number;
  customerSelected = [];
  travelFormData: TravelDataStorage;
  // isKYCDoneSuccessfullyForPilotuser: boolean = true;

  constructor(private fb: FormBuilder,
    private wrapperService: WrapperService,
    public router: Router,
    private customerService: CustomerService,
    private popupService: PopupService,
    public utilityService: UtilityService,
    private travelDataStorageService: TravelDataStorageService,
    private utilityApiService: UtilityApiService,
    private zone: NgZone) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.popupService.closeAllDialog(true);
    setTimeout(() => {
      this.popupService.closeAllDialog(false);
    }, 0);
    // Below event listener is added to receive data from kyc
    this.subscription.push(fromEvent(window, 'message')
      .subscribe((event) => {
        this.receiveMessage(event);
      }));
    this.showEKYC = true;
    this.onLoadSteps();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.zone.run(() => {
      this.isKYCDoneSuccessfullyForPilotuser = changes['isKYCDoneSuccessfullyForPilotuser']?.currentValue != undefined ? changes['isKYCDoneSuccessfullyForPilotuser']?.currentValue : true;
    })
  }

  onLoadSteps() {
    this.travelFormData = this.travelDataStorageService.getTravelDataStorage();
    if (this.travelFormData.kycDataSet) {
      this.customerType = 'New';
      this.showEKYC = true;
      this.isKYCDoneSuccessfully = this.travelFormData.kycStatus.isKYCDoneSuccessfully;
      this.showSearchPopup = !this.travelFormData.kycStatus.isKYCDoneSuccessfully;
    }
  }

  receiveMessage(event) {
    $('#externalUrlModal').modal('hide');
    if (!this.utilityService.isEmptyOrNull(event.data)) {
      let res = typeof (event.data) == 'string' ? JSON.parse(event.data) : event.data;
      if (!this.utilityService.isEmptyOrNull(res)) {
        this.getKYCDetails(res);
      }
    }
  }

  getKYCDetails(res: KycDetailsResponse) {
    if ((res != null) && (res.StatusCode == Constants.statusCode_success)) {
      let tempResponse: KycDetailsResponse = typeof (res) == 'string' ? JSON.parse(res) : res;
      this.isKYCDoneSuccessfully = tempResponse.data.statusMessage == 'ACCEPTED_FOR_MANUAL_QC' && this.isPilotUser ? false : true;
      let kycDetails = tempResponse?.data?.kyc_details;
      let correspondence = kycDetails?.alternate_address;
      let permanentAddress = kycDetails?.permanent_address;

      let kycMode = kycDetails?.certificate_type ? 'PAN' : kycDetails?.certificate_type ? 'adhar' : '';
      let value = kycDetails?.certificate_number;
      let title, fullName, finalTitle;
      const word = kycDetails?.full_name?.toUpperCase();
      const chars = ['MR', 'MRS', 'MISS', 'MS'];
      for (const char of chars) {
        let isTitleAvailable = new RegExp(`^${char}`).test(word);
        title = isTitleAvailable ? word.split(' ').slice(0, 1).join() : '';
        finalTitle = title == 'MISS' || title == 'MS' ? 'Ms.' : title == 'MRS' ? 'Mrs.' : title == 'MR' ? 'Mr.' : '';
        fullName = isTitleAvailable ? word.replace(`${title} `, "") : word;
        if (title == char) {
          break;
        }
      }
      let gender = kycDetails?.gender == 'M' ? 'Male' : 'Female';
      let insuredTitle = finalTitle == 'Mrs.' ? '0' : finalTitle == 'Mr.' ? '1' : finalTitle == 'Ms.' ? '2' :
        finalTitle == 'Mx.' ? '21' : '';

      let ilKycReferenceNumber = tempResponse?.data.hasOwnProperty('kyc_details') ? tempResponse?.data?.kyc_details.il_kyc_ref_no : '';
      let cKycId = tempResponse?.data.hasOwnProperty('kyc_details') ? tempResponse?.data?.kyc_details.ckyc_number : '';
      let dateOfBirth = kycDetails?.dob ? this.utilityService.getStandardDateFormat(kycDetails?.dob, 'dd-mm-yyyy') : '';
      let obj = {
        panCardNumber: value || '', title: insuredTitle || '', fullName: fullName || '', dateOfBirth: dateOfBirth,
        correspondenceAddressDetails: { address1: correspondence?.address_line_1 || '', address2: correspondence?.address_line_2 || '', landmark: '', pincode: correspondence?.pin_code || '', state: correspondence?.state || '', stateId: '', city: correspondence?.city || '', cityId: '' },
        permanentAddressDetails: {
          address1: permanentAddress?.address_line_1 || '', address2: permanentAddress?.address_line_2 || '', landmark: '', pincode: permanentAddress?.pin_code || '', state: permanentAddress?.state || '', stateId: '', city: permanentAddress?.city || '', cityId: '',
          gender: gender || '', email: kycDetails?.email || '', mobileNumber: kycDetails?.mobile_number || '', maritalStatus: '', isPermanentAddressSame: false
        },
        //need to check
        gstDetails: {
          isGstRegistered: 'No', constitutionOfBusiness: '', panNumber: '', customerType: '', gstStatus: '', isGstApplicable: 'GSTIN',
          gstin: '', uin: ''
        },
        kycDetails: {
          pepFlag: kycDetails?.pep_flag || false, ilKycReferenceNumber: ilKycReferenceNumber, CKYCID: cKycId, EKYCID: '', ekycMode: kycMode, value: value, correlationId: res.data.correlationId || '',
          pfCustomerID: '', kycUserType: res?.data?.customer_type || '', statusMessage: tempResponse?.data.statusMessage
        }
      }

      if (obj.kycDetails?.ilKycReferenceNumber !== "" || this.utilityService.isEmptyOrNull(obj.kycDetails?.CKYCID)) {
        if ((obj.kycDetails?.statusMessage)?.toLowerCase() == Constants.statusMsg_Success) {
          this.customerType = 'New';
        }
      } else {
        this.customerType = 'New';
      }


      let data = {
        isEKYCVisible: this.showEKYC, isSearchPopupVisible: this.showSearchPopup, isKYCDoneSuccessfully: this.isKYCDoneSuccessfully,
        applicantDetails: obj, isEKYCMandatory: this.ekycMandatory, customerType: this.customerType
      };

      this.getKycDetails?.emit(data);
    }

  }

  changeInCustomerType(type) {
    this.customerListData = [];
    this.isKYCDoneSuccessfullyForPilotuser = true;
    let data;
    if (type == 'Existing') {
      this.displayCustomerList = false;
      this.showSearchPopup = true;
      this.customerType = 'Existing';
      this.isKYCDoneSuccessfully = false;
      this.ekycMandatory = false;

      this.utilityService.sortDetails(this.searchForCustomerControls, 'position');
      this.customerTypeDetails = kycDetailsControls.customerTypeData;
      this.searchForCustomerControls = kycDetailsControls.searchForCustomerData;
      this.createSearchCustomerForm();
      data = {
        isKYCDoneSuccessfully: this.isKYCDoneSuccessfully, customerType: this.customerType,
        showSearchPopup: this.showSearchPopup, ekycMandatory: this.ekycMandatory
      }
      this.changeCustomerType.emit(data);
    } else {
      this.displayCustomerList = false;
      this.showSearchPopup = false;
      this.customerType = 'New';
      this.isKYCDoneSuccessfully = false;
      this.ekycMandatory = false;
      this.searchCustomerForm?.reset();
      data = {
        isKYCDoneSuccessfully: this.isKYCDoneSuccessfully, customerType: this.customerType,
        showSearchPopup: this.showSearchPopup, ekycMandatory: this.ekycMandatory
      }
      this.changeCustomerType.emit(data);

    }
  }

  createSearchCustomerForm() {
    if (this.searchForCustomerControls.length > 0) {
      let dynamicControls = {};
      this.searchForCustomerControls.forEach(controls => {
        if (controls['controlName'] != kycControls.or) {
          dynamicControls[controls['controlName']] = new FormControl(controls['defaultValue'], []);
        }
      })
      this.searchCustomerForm = this.fb.group(dynamicControls);
    }
  }

  callKYC() {
    this.isKYCDoneSuccessfullyForPilotuser = true;
    // this.aryaApiKycCalling(); //arya kyc changes
    this.newKycCalling(); //flutter kyc changes
  }

  newKycCalling() {
    this.subscription.push(this.wrapperService.createKYCToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          let kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? res.Data.split("=")[1] : '';
          let source = this.utilityService.identifyEnvironmentType(kycToken);
          let resourceParams = JSON.stringify(source);
          // this.spinner.hide();
          const externalUrlFrame = document.getElementById('externalUrlFrame') as HTMLIFrameElement;
          externalUrlFrame.src = environment.kycUrl + '?rsrc=' + resourceParams;
          $('#externalUrlModal').modal('show');
        } else {
        }
      },
      error: error => {
      }
    }));
  }

  aryaApiKycCalling() {
    const getAryaApiResp = (res, status) => {
      if (status.toLowerCase() == Constants.statusMsg_Success) {
        // setTimeout(() => {
        let kycData: KycDetailsResponse = this.kycResponseMapping(res);
        this.getKYCDetails(kycData);
        // }, 0);
      }
      else {
        this.isKYCDoneSuccessfully = false;
        this.ekycMandatory = false;
        this.isKYCDoneSuccessfullyForPilotuser = true;
        // this.utilityService.popUpDialog('Kindly complete E-KYC/C-KYC to proceed further.');
      }
    }


    this.subscription.push(this.utilityApiService.getApiToken().subscribe({
      next: (res: ApiTokenResponsePayload) => {
        if (res.StatusCode == Constants.statusCode_success) {
          let kycToken = !this.utilityService.isEmptyOrNull(res.Data) ? (res.Data).split(' ')[1] : '';
          let environmentType: string = '';
          environmentType = environment.environment == 'production' ? 'production' : 'uat';
          AJL.aryafns.initMod(kycToken, getAryaApiResp, environmentType);
        }
      }
    }));
  }

  kycResponseMapping(oldKycResp: AryaAPIKycResp): KycDetailsResponse {
    let statusCode: number = 0;
    let alternateAddressPresent: boolean = false;
    alternateAddressPresent = !this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.alternate_address);
    if (((oldKycResp.api_response.statusMessage).toLowerCase() == Constants.statusMsg_Success) || (oldKycResp.api_response.statusMessage == Constants.statusMsg_Manual)) {
      statusCode = 1;
    }
    else {
      statusCode = 0;
    }
    let kycResp: KycDetailsResponse = {
      StatusCode: statusCode,
      StatusMessage: oldKycResp.api_response.statusMessage,
      data: {
        correlationId: oldKycResp.api_response.correlationId,
        customer_type: oldKycResp.api_response.kyc_details.customer_type,
        pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        status: oldKycResp.api_response.status,
        statusMessage: oldKycResp.api_response.statusMessage,
        message: oldKycResp.api_response.message,
        kyc_details: {
          il_kyc_ref_no: oldKycResp.api_response.kyc_details.il_kyc_ref_no,
          certificate_type: oldKycResp.api_response.kyc_details.certificate_type,
          certificate_number: oldKycResp.api_response.kyc_details.certificate_number,
          certificate_type_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_type_poa) ? oldKycResp.api_response.kyc_details.certificate_type_poa : '',
          certificate_number_poa: this.utilityService.isEmptyOrNull(oldKycResp.api_response.kyc_details.certificate_number_poa) ? oldKycResp.api_response.kyc_details.certificate_number_poa : '',
          first_name: oldKycResp.api_response.kyc_details.first_name,
          middle_name: '', //Not present
          last_name: oldKycResp.api_response.kyc_details.last_name,
          full_name: oldKycResp.api_response.kyc_details.full_name,
          gender: oldKycResp.api_response.kyc_details.gender,
          dob: oldKycResp.api_response.kyc_details.dob,
          mobile_number: oldKycResp.api_response.kyc_details.mobile_number,
          email: oldKycResp.api_response.kyc_details.email,
          ckyc_number: oldKycResp.api_response.kyc_details.ckyc_number,
          il_customer_id: '',
          permanent_address: {
            address_line_1: oldKycResp.api_response.kyc_details.permanent_address.address_line_1,
            address_line_2: oldKycResp.api_response.kyc_details.permanent_address.address_line_2,
            pin_code: oldKycResp.api_response.kyc_details.permanent_address.pin_code,
            city: oldKycResp.api_response.kyc_details.permanent_address.city,
            state: oldKycResp.api_response.kyc_details.permanent_address.state,
          },
          alternate_address: {
            address_line_1: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.address_line_1: '',
            address_line_2: alternateAddressPresent? oldKycResp.api_response.kyc_details.alternate_address.address_line_2: '',
            pin_code: alternateAddressPresent?oldKycResp.api_response.kyc_details.alternate_address.pin_code: '',
            city: alternateAddressPresent ? oldKycResp.api_response.kyc_details.alternate_address.city: '',
            state: alternateAddressPresent?oldKycResp.api_response.kyc_details.alternate_address.state: '',
          },
          udf_1: '',
          udf_2: '',
          udf_3: '',
          udf_4: '',
          udf_5: '',
          pep_flag: oldKycResp.user_data.pep_flag == 'No' ? false : true,
        }
      }
    }
    return kycResp;
  }

  // To handle search button click
  searchButtonClick(type: string) {
    if (type == 'close') {
      this.showSearchPopup = false;
      //need to uncomment code
      // this.kycForm.controls[kycControls.customerType].setValue('New');
      this.customerType = 'New';
      //need to uncomment code
      // this.healthFormData.kycFormData.customerType = 'New';
      this.searchCustomerForm.reset();
    }
    else if (type == 'proceed') {
      this.fetchCustomerDetails();
    }
  }

  fetchCustomerDetails() {
    if (this.searchCustomerForm.pristine || (this.searchCustomerForm.value[kycControls.customerName] == '' && this.searchCustomerForm.value[kycControls.customerMobile] == '' && this.searchCustomerForm.value[kycControls.customerEmail] == '' && this.searchCustomerForm.value[kycControls.pfCustomerID] == '')) {
      this.searchCustomerForm.controls[kycControls.customerName].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerName].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerMobile].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerEmail].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.pfCustomerID].clearValidators();
      this.searchCustomerForm.controls[kycControls.pfCustomerID].updateValueAndValidity();
      this.displayCustomerList = false;
      this.customerListData = [];
      this.utilityService.popUpDialog('Please fill all the fields');
    }

    else {
      // Call api service only when pf is not null or
      // either any one combination is not null which is name and email or name and mobile
      this.searchFormValidation();

      if ((!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) || (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerName]) && (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile]) || !this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail])))) {
        if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) {
          let pfCustomerId = this.searchCustomerForm.value.pfCustomerID ? this.searchCustomerForm.value.pfCustomerID : ''

          this.fetchCustomerFromPfId(pfCustomerId);
        }
        else if (this.searchCustomerForm.valid) {
          this.searchCustomer();
        }
      }
    }
  }

  fetchCustomerFromPfId(pfId: number) {
    let request: FetchCustomerDetailsV1RequestPayload = {
      PfCustomerID: (pfId).toString()
    }

    this.subscription.push(this.customerService.fetchCustomerDetailsV1(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          this.patchExistingUserDetails(response.Data);

        }
        else if (response.StatusCode != Constants.statusCode_success) {
          // Check this when to show the popup of completing kyc
          // this.customStepperService.emitStepData({ status: false, buttonId: 'f4' });
          this.showEKYC = true;
          this.isKYCDoneSuccessfully = false;
          this.ekycMandatory = false;
        }
      },
      error: error => {
      }
    }))
  }

  searchCustomer() {
    let request: SearchCustomerRequestPayload = {
      CustomerName: this.searchCustomerForm.value.customerName ? this.searchCustomerForm.value.customerName : '',
      CustomerEmail: this.searchCustomerForm.value.customerEmail ? this.searchCustomerForm.value.customerEmail : '',
      CustomerMobile: this.searchCustomerForm.value.customerMobile ? this.searchCustomerForm.value.customerMobile : '',
      PfCustomerId: this.searchCustomerForm.value.pfCustomerID ? this.searchCustomerForm.value.pfCustomerID : ''
    }
    this.customerSelected = [];
    this.subscription.push(this.customerService.searchCustomerData(request).subscribe({
      next: response => {
        if (response.StatusCode == Constants.statusCode_success) {
          if (response.Data) {
            this.numberOfRecords = response.Data.length;
            this.patchCustomers(response.Data);
          }
          else {
            this.utilityService.popUpDialog(response.StatusDesc);
          }
        }
        else if (response.StatusCode != Constants.statusCode_success) {
        }

      },
      error: error => {
      }
    }))
  }

  // So if the customer list is present it will patch the value in dropdown
  // And it will make it visible
  // or else it will hide the fields
  patchCustomers(data: SearchCustomerResponseStructure[]) {
    if (data.length > 0) {
      let i = 0;
      this.customerListData = data.map((data, i) => {
        let tempCustomerData = {
          'id': i + 1,
          'label': data.CustomerName,
          'value': (data.PfCustomerId).toString(),
          'email': data.CustomerEmail,
          'mobileNumber': data.CustomerMobile
        }
        return tempCustomerData;
      });
      this.displayCustomerList = true;
    }
    else {
      this.displayCustomerList = false;
      this.utilityService.popUpDialog('No record found');
    }


  }

  // logic yet to be discussed from service
  searchFormValidation() {
    // If pf field is present then there should not be require of any field make it empty
    if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) {
      this.searchCustomerForm.controls[kycControls.customerName].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerName].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerName].setValue('');
      this.searchCustomerForm.controls[kycControls.customerMobile].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerMobile].setValue('');
      this.searchCustomerForm.controls[kycControls.customerEmail].clearValidators();
      this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerEmail].setValue('');
      this.searchCustomerForm.controls[kycControls.pfCustomerID].setValidators([Validators.required]);
      this.searchCustomerForm.controls[kycControls.pfCustomerID].updateValueAndValidity();
    }
    // if name is not empty but pf field is empty then we need mobile number field that's a priority
    // But we also need to check if email value is already entered or not
    if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerName]) && this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) {
      this.searchCustomerForm.controls[kycControls.pfCustomerID].setValue('');
      this.searchCustomerForm.controls[kycControls.pfCustomerID].clearValidators();
      this.searchCustomerForm.controls[kycControls.pfCustomerID].updateValueAndValidity();
      // If no email is present then set vathis.utilitylidation for mobile
      if (this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail])) {
        this.searchCustomerForm.controls[kycControls.customerEmail].setValue('');
        this.searchCustomerForm.controls[kycControls.customerEmail].clearValidators();
        this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
        this.searchCustomerForm.controls[kycControls.customerMobile].setValidators([Validators.required, Validators.minLength(10)]);
        this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      }
      // There could be chances that we have entered email so in that case we don't need mobile
      // But we need to check whether we have netered data for mobile or not
      else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail])) {
        this.searchCustomerForm.controls[kycControls.customerEmail].setValidators([Validators.required, Validators.pattern(patterns.email)]);
        this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
        // If user has not eneterd mobile number then no validation needed
        if (this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile])) {
          this.searchCustomerForm.controls[kycControls.customerMobile].clearValidators();
          this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
        }
        // but If user has entered mobile number we need to apply validation
        else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile])) {
          this.searchCustomerForm.controls[kycControls.customerMobile].setValidators([Validators.required, Validators.minLength(10)]);
          this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
        }
      }
      this.searchCustomerForm.controls[kycControls.customerName].setValidators([Validators.required]);
      this.searchCustomerForm.controls[kycControls.customerName].updateValueAndValidity();
    }

    // If mobile field is selected to add value then customer name is mandate
    else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile]) && this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) {
      this.searchCustomerForm.controls[kycControls.pfCustomerID].setValue('');
      this.searchCustomerForm.controls[kycControls.pfCustomerID].clearValidators();
      this.searchCustomerForm.controls[kycControls.pfCustomerID].updateValueAndValidity();
      // If there is no value of email we don't need to check validation
      if (this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail])) {
        this.searchCustomerForm.controls[kycControls.customerEmail].setValue('');
        this.searchCustomerForm.controls[kycControls.customerEmail].clearValidators();
        this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
      }
      // If there is value of email we  need to check validation
      else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail])) {
        this.searchCustomerForm.controls[kycControls.customerEmail].setValidators([Validators.required, Validators.pattern(patterns.email)]);
        this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
      }
      this.searchCustomerForm.controls[kycControls.customerMobile].setValidators([Validators.required, Validators.minLength(10)]);
      this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerName].setValidators([Validators.required]);
      this.searchCustomerForm.controls[kycControls.customerName].updateValueAndValidity();
    }

    // If email is selected then customer name is mandate
    else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerEmail]) && this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.pfCustomerID])) {
      this.searchCustomerForm.controls[kycControls.pfCustomerID].setValue('');
      this.searchCustomerForm.controls[kycControls.pfCustomerID].clearValidators();
      this.searchCustomerForm.controls[kycControls.pfCustomerID].updateValueAndValidity();
      // If there is no value of mobile number we don't need to check validation
      if (this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile])) {
        this.searchCustomerForm.controls[kycControls.customerMobile].setValue('');
        this.searchCustomerForm.controls[kycControls.customerMobile].clearValidators();
        this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      }
      // If there is value of mobile we  need to check validation
      else if (!this.utilityService.isEmptyOrNull(this.searchCustomerForm.value[kycControls.customerMobile])) {
        this.searchCustomerForm.controls[kycControls.customerMobile].setValidators([Validators.required, Validators.minLength(10)]);
        this.searchCustomerForm.controls[kycControls.customerMobile].updateValueAndValidity();
      }
      this.searchCustomerForm.controls[kycControls.customerName].setValidators([Validators.required]);
      this.searchCustomerForm.controls[kycControls.customerName].updateValueAndValidity();
      this.searchCustomerForm.controls[kycControls.customerEmail].setValidators([Validators.required, Validators.pattern(patterns.email)]);
      this.searchCustomerForm.controls[kycControls.customerEmail].updateValueAndValidity();
    }
  }

  getCustomerRecord(row) {
    this.fetchCustomerFromPfId(row.value);
  }

  validationOfInputField(event: Event, controlName: string, alphaCheck: boolean, NumberCheck: boolean) {
    this.utilityService.dynamicControlsAlphaOnly(event, alphaCheck);
    this.utilityService.dynamicControlsNumberOnly(event, NumberCheck);
    let addSpaceControlName = controlName !== kycControls.customerName && controlName !== kycControls.nomineeFullName && controlName !== kycControls.appointeeFullName && controlName !== kycControls.correspondenceAddress1 && controlName !== kycControls.correspondenceAddress2 && controlName !== kycControls.correspondenceLandmark && controlName !== kycControls.permanentAddress1 && controlName !== kycControls.permanentAddress2 && controlName !== kycControls.permanentLandmark && controlName !== kycControls.applicantFullName;
    if (addSpaceControlName) {
      this.utilityService.restrictSpace(event);
    }
  }

  patchExistingUserDetails(response: FetchCustomerDetailsV1ResponseStructure) {
    this.showSearchPopup = false;
    this.isKYCDoneSuccessfully = true;
    let kycMode = response?.PANNumber ? 'pan' : response?.AadhaarNo ? 'adhar' : '';
    let value = kycMode == 'pan' ? response?.PANNumber : kycMode == 'adhar' ? response?.AadhaarNo : '';
    let title = !this.utilityService.isEmptyOrNull(response?.Gender) ? (response?.Gender).toLowerCase() == gender.Male ? 'Mr.' : '' : '';
    let insuredTitle = title == 'Mrs.' ? '0' : title == 'Mr.' ? '1' : title == 'Ms.' ? '2' :
      title == 'Mx.' ? '21' : '';
    let fullname = response?.UserName || '';
    let dateOfBirth = response?.DOB ? this.utilityService.getStandardDateFormat(response?.DOB, 'dd-MMM-yyyy') : response?.DOB;
    let obj = {
      panCardNumber: response?.PANNumber || '', title: insuredTitle || '', fullName: fullname, dateOfBirth: dateOfBirth || '',
      correspondenceAddressDetails: { address1: response?.Address1 || '', address2: response?.Address2 || '', landmark: '', pincode: response?.PinCode || '', state: response?.State || '', stateId: response?.StateID || '', city: response?.City || '', cityId: response?.CityID || '' },
      permanentAddressDetails: {
        address1: response?.Address1 || '', address2: response?.Address2 || '', landmark: '', pincode: response?.PinCode || '', state: response?.State || '', stateId: response?.StateID || '', city: response?.City || '', cityId: response?.CityID || '',
        gender: response?.Gender || '', email: response?.EmailID || '', mobileNumber: response?.Mobile || '', maritalStatus: response?.MARITAL_STATUS || '', isPermanentAddressSame: false
      },
      gstDetails: {
        isGstRegistered: response?.GSTApplicable ? 'Yes' : 'No', constitutionOfBusiness: response?.CONSTITUTION_OF_BUSINESS, panNumber: response?.PAN_NO || response?.PANNumber || '', customerType: response?.CUSTOMER_TYPE || '', gstStatus: response?.GST_REGISTRATION_STATUS || '', isGstApplicable: (response?.IS_UIN_APPLICABLE ? 'UIN' : 'GSTIN') || 'GSTIN',
        gstin: !response?.IS_UIN_APPLICABLE ? response?.GSTIN_UIN_NO : '', uin: response?.IS_UIN_APPLICABLE ? response?.GSTIN_UIN_NO : ''
      },
      kycDetails: {
        pepFlag: response?.PEPFLAG || false, ilKycReferenceNumber: response?.ReferenceNo, CKYCID: response?.CKYCID, EKYCID: response?.EKYCID, ekycMode: kycMode, value: value, correlationId: '',
        pfCustomerID: response?.PfCustomerID || '', statusMessage: ''
      }
    }

    let data = {
      isEKYCVisible: this.showEKYC, isSearchPopupVisible: this.showSearchPopup, isKYCDoneSuccessfully: this.isKYCDoneSuccessfully,
      applicantDetails: obj, isEKYCMandatory: this.ekycMandatory, customerType: this.customerType
    };
    this.getExistingCustomerDetails.emit(data);
  }

  hasError(controlName: string, errorCode: string, groupName: string): boolean {
    if (groupName == kycControls.searchCustomer) {
      return !this.searchCustomerForm.valid && this.searchCustomerForm.hasError(errorCode, [controlName])
    }
    return false;
  }

  ngOnDestroy() {
    this.subscription.forEach(subs => {
      subs.unsubscribe();
    })
  }


}
