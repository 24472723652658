<!-- 404 Page -->
<div class="container errorMessageMainDiv" *ngIf="loadPageNotFoundPage">
  <div class="errorImg">
    <img src="../../../../assets/images/errorimg.gif" alt="">
  </div>
  <div class="errorMessagediv">
    <strong>Oh No! Error 404</strong>
    <span class="lowermsg">Oops! your page could not be found. But now that you've landed here, take a deep breath and
      relax.
    </span>

    <span class="notFnd">Here's what you can do:</span>
    <!-- <span class="lowermsg"> Go back to the page you came from.</span> -->
    <span class="lowermsg"> Click the button below to go back to the login page....</span>
    <button class="primary-button text-center errorbtn" (click)="goToHome()">Go to {{buttonText}}</button>
  </div>
</div>