import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HealthAdvantedgeService } from 'src/app/shared/proxy-services/health-advantedge.service';
import { HealthMasterService } from 'src/app/shared/proxy-services/health-master.service';
import { RTOListService } from 'src/app/shared/proxy-services/rto-list.service';
import { CustomStepperService } from 'src/app/shared/services/custom-stepper.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { HealthDataStorageService } from '../../health.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/services/storage.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { HealthService } from 'src/app/shared/proxy-services/health.service';
import { PopupModal } from 'src/app/layout/popups/popup.modal';
import { Subscription } from 'rxjs';
import { HAPModal } from '../../products/hap/hap-modal';
import { HealthDataStorage } from '../../health.modal';
import { healthMembers, patterns, zoneRefillingDetails } from 'src/app/shared/enums/healthEnums';
import { userEnums } from 'src/app/shared/enums/userEnums';
import { routeEnums } from 'src/app/shared/enums/routeEnums';
import { RTOListRequestPayload, RTOListResponsePayload } from 'src/app/shared/interface/rto-list';
import { Constants } from 'src/app/shared/constants/constants';
import { ZoneRequestPayload, ZoneResponsePayload } from 'src/app/shared/interface/health-advantedge';
import { QuickquotePayload, QuickquoteRequestPayload, QuickquoteResponsePayload, SumInsuredPayload } from 'src/app/shared/interface/health';
import { AgentMasterStructure } from 'src/app/shared/interface/agentV1';


@Component({
  selector: 'app-quick-quote',
  templateUrl: './quick-quote.component.html',
  styleUrls: ['./quick-quote.component.scss']
})
export class QuickQuoteComponent {
  quickquoteForm: FormGroup;
  quickquoteFormData: HealthDataStorage;
  adultCount: number = 0;
  childCount: number = 0;
  subscription: Subscription[] = [];
  hapDataModal: HAPModal = new HAPModal();
  adultRelationShips = [];
  childRelationShips = [];
  cityData = [];
  stateFetched: boolean = false;
  stateDetails = this.hapDataModal.stateDetails;
  invalidPinCodeMessage: string;
  zoneMappedWithPincode: string = '';
  errorPopupData: PopupModal;
  showChooseProduct: boolean = false;
  sumInsuedDetails: any;
  showButton: boolean = false;
  data: QuickquoteResponsePayload[] = [];
  totalPremium = {};
  formSubmitted: boolean = false;
  tenureDuration: number = 0;
  reviseZone = [];
  revisedZone = [];
  quickquote = ['AdultDetails', 'ChildDetails', 'policyTenure', 'pincode', 'cityState', 'applicantAnnualSum']

  constructor(private fb: FormBuilder,
    private rtoListService: RTOListService,
    private healthMasterService: HealthMasterService,
    private customStepperService: CustomStepperService,
    public utilityService: UtilityService,
    private healthAdvantedgeService_M: HealthAdvantedgeService,
    private popupService: PopupService,
    private healthService: HealthService,
    private storageService: StorageService,
    private healthDataStorageService: HealthDataStorageService,
    private router: Router) { }

  ngOnInit() {
    this.createQuickQuoteForm();
    this.addAdult();
    this.quickquoteFormData = this.healthDataStorageService.getHealthDataStorage();
    this.getSumInsured();
  }

  createQuickQuoteForm() {
    this.quickquoteForm = this.fb.group({
      AdultDetails: this.fb.array([]),
      ChildDetails: this.fb.array([]),
      policyTenure: [this.hapDataModal.tenurePeriod[0], Validators.required],
      pincode: ['', [Validators.required, Validators.pattern(patterns.pincode), Validators.minLength(6)]],
      cityState: ['', Validators.required],
      applicantAnnualSum: []
    })

  }

  proceedToQuoteActionButton(productCode) {
    let annualSumInsured;
    this.data.forEach(resp => {
      if (resp.ProductCode == productCode) {
        this.quickquoteForm.controls['applicantAnnualSum'].setValue(resp.SumInsured);
        annualSumInsured = resp.SumInsured;
      }
    })
    this.quickquoteFormData.quickquoteData.pincode = this.quickquoteForm.value.pincode;
    this.quickquoteFormData.quickquoteData.adultDetails = this.quickquoteForm.value.AdultDetails;
    this.quickquoteFormData.quickquoteData.childDetails = this.quickquoteForm.value.ChildDetails;
    this.quickquoteFormData.quickquoteData.cityState = this.quickquoteForm.value.cityState;
    this.quickquoteFormData.quickquoteData.policyTenure = this.quickquoteForm.value.policyTenure;
    this.quickquoteFormData.quickquoteData.applicantAnnualSum = annualSumInsured;

    this.quickquoteFormData.quickquoteDataSet = true;
    this.healthDataStorageService.setHealthDataStorage(this.quickquoteFormData);
    let productData = this.productDetails;
    if (productData) {
      this.storageService.setAESEncryptedData(userEnums.SubProductCode, productCode);

      if (productCode == '42') {
        this.router.navigate([`/health/${routeEnums.HAP}`]);
        return;
      } else if (productCode == '34') {
        this.router.navigate([`/health/${routeEnums.MaxProtect}`]);
        return;
      } else if (productCode == '33') {
        this.router.navigate([`/health/${routeEnums.HealthElitePlus}`]);
        return;
      } else if (productCode == '40') {
        this.router.navigate([`/health/${routeEnums.GoldenShield}`]);
        return;
      } else if (productCode == '20') {
        this.router.navigate([`/health/${routeEnums.HEALTH_BOOSTER}`]);
        return;
      } else if (productCode == '22') {
        this.router.navigate([`/health/${routeEnums.ASP}`]);
        return;
      } else if (productCode == '45') {
        this.router.navigate([`/health/${routeEnums.Elevate}`]);
        return;
      }
    }

  }

  get adultDetails(): FormArray {
    return this.quickquoteForm.get('AdultDetails') as FormArray;
  }

  get childDetails(): FormArray {
    return this.quickquoteForm.get('ChildDetails') as FormArray;
  }


  createAdultDetails(index?): FormGroup {
    return new FormGroup({
      ['dobAdult' + `${index}`]: new FormControl('', [Validators.required])
    })
  }

  createChildDetails(index?): FormGroup {
    return new FormGroup({
      ['dobChild' + `${index}`]: new FormControl('', [Validators.required])
    })
  }

  addAdult() {
    this.showChooseProduct = false;
    if (this.adultCount < 6) {
      this.adultCount++;
      this.adultDetails.push(this.createAdultDetails(this.adultCount));
    }
    if (this.adultCount === 5 || this.adultCount === 6) {
      this.childCount = 0;
      this.childDetails.clear();
      this.removeChildLastMember(this.childCount);
    }
  }

  removeAdult() {
    this.showChooseProduct = false;
    if (this.adultCount > 1) {
      this.adultCount--;
      this.removeLastMember(this.adultCount);
    } else if (this.childCount === 1 && this.adultCount > 0) {
      this.adultCount = 0;
      this.removeLastMember(this.adultCount);
    } else if (this.adultCount === 1 && this.childCount === 0) {
      this.utilityService.popUpDialog('Atleast one member is required');
    }
    if (this.adultCount === 1 && this.childCount > 2) {
      this.childCount = 0;
      this.childDetails.clear();
      this.removeChildLastMember(this.childCount);
    }
  }

  removeLastMember(index) {
    this.adultDetails.removeAt(index);
  }


  addChild() {
    this.showChooseProduct = false;
    if (this.adultCount === 1 && this.childCount < 2) {
      this.childCount++;
      this.childDetails.push(this.createChildDetails(this.childCount));
    }
    else if (this.adultCount === 1 && this.childCount >= 2) {
      const popupString = 'Only 2 children are allowed per 1 adult';
      this.utilityService.popUpDialog(popupString);
    }
    if (this.adultCount === 2 && this.childCount < 3) {
      this.childCount++;
      this.childDetails.push(this.createChildDetails(this.childCount));
    }
    else if (this.adultCount === 2 && this.childCount >= 3) {
      const popupString = 'Only 3 children are allowed per 2 adults';
      this.utilityService.popUpDialog(popupString);
    }
    if (this.adultCount === 3 && this.childCount < 3) {
      this.childCount++;
      this.childDetails.push(this.createChildDetails(this.childCount));
    }
    else if (this.adultCount === 3 && this.childCount >= 3) {
      const popupString = 'Only 3 children are allowed per 3 adults';
      this.utilityService.popUpDialog(popupString);
    } 
    if (this.adultCount === 4 && this.childCount < 3) {
      this.childCount++;
      this.childDetails.push(this.createChildDetails(this.childCount));
    }
    else if (this.adultCount === 4 && this.childCount >= 3) {
      const popupString = 'Only 3 children are allowed per 4 adults';
      this.utilityService.popUpDialog(popupString);
    } 
    if (this.adultCount === 5 && this.childCount < 2) {
      this.childCount++;
      this.childDetails.push(this.createChildDetails(this.childCount));
    }
    else if (this.adultCount === 5 && this.childCount >= 2) {
      const popupString = 'Only 2 children are allowed per 5 adults';
      this.utilityService.popUpDialog(popupString);
    } 
    if (this.adultCount === 6 && this.childCount < 1) {
      this.childCount++;
      this.childDetails.push(this.createChildDetails(this.childCount));
    }
    else if (this.adultCount === 6 && this.childCount <= 1) {
      const popupString = 'Only 1 children are allowed per 6 adults';
      this.utilityService.popUpDialog(popupString);
    }
  }

  removeChild() {
    this.showChooseProduct = false;
    if (this.childCount > 1 || this.childCount === 1) {
      this.childCount--;
      this.removeChildLastMember(this.childCount);
    } else if (this.childCount === 1 && this.adultCount === 0) {
      const popupString = 'At least one member is required';
      this.utilityService.popUpDialog(popupString);
    }
  }

  removeChildLastMember(index) {
    this.childDetails.removeAt(index);
  }

  hasError(controlName: string, errorCode: string): boolean {
    return !this.quickquoteForm.valid && this.quickquoteForm.hasError(errorCode, [controlName]);
  }

  fetchDataAsPerPincode(event, existingPincode?) {
    this.showChooseProduct = false;
    this.stateFetched = false;
    this.invalidPinCodeMessage = '';
    this.cityData = [];
    if ((existingPincode != null ? existingPincode : event.target.value.length == 6) && !this.quickquoteForm.controls['pincode'].hasError('pattern')) {
      let data: RTOListRequestPayload = {
        "Pincode": existingPincode != null ? existingPincode : event.target.value,
        "CityID": ""
      }
      this.invalidPinCodeMessage = '';
      this.quickquoteForm.controls['cityState'].reset();
      this.subscription.push(this.rtoListService.getStatesCityByPin(data).subscribe({
        next: (response: RTOListResponsePayload) => {
          if (response.StatusCode == Constants.statusCode_success) {
            if (response.Data.length > 0) {
              this.stateDetails.stateId = response.Data[0].StateId ? response.Data[0].StateId : '';
              this.stateDetails.stateName = response.Data[0].StateName ? response.Data[0].StateName : '';
              this.stateDetails.pincode = existingPincode != null ? existingPincode : event.target.value;
              response.Data.forEach(cityList => {
                this.cityData.push({ 'cityLabel': cityList['CityName'], 'stateLabel': this.stateDetails.stateName, 'value': cityList['CityID'] });
              });
            }
            if (this.cityData.length == 1) {
              this.stateDetails.cityName = this.cityData[0].cityLabel;
              this.stateDetails.cityId = this.cityData[0].value;
              this.getZoneData();
              this.quickquoteForm.controls['cityState'].setValue(this.stateDetails.stateName + ' - ' + this.stateDetails.cityName);
            }
            this.stateFetched = true;

          }
          else if (response.StatusCode == Constants.statusCode_failure) {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
            this.stateFetched = false;
            this.invalidPinCodeMessage = response.StatusDesc;
          }

          else {
            this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          }
        },
        error: error => {
          this.customStepperService.emitStepData({ status: false, buttonId: 'f1' });
          this.invalidPinCodeMessage = '';
          this.stateFetched = false;
        }
      }))
    }
    this.utilityService.numberOnly(event);
    this.utilityService.restrictSpace(event);
  }

  changeCityName(cityValue: string, showSpinner: boolean) {
    this.stateDetails.cityId = cityValue;
    this.stateDetails.cityName = this.cityData.length > 0 ? (this.cityData.find(data => data.value == cityValue).cityLabel) : '';
    if (showSpinner) {
      this.getZoneData();
    }
  }

  getZoneData() {
    let params: ZoneRequestPayload = {
      "productcode": this.hapDataModal.zoneCode,
      "State": this.stateDetails.stateName,
      "City": this.stateDetails.cityName
    }
    this.subscription.push(this.healthAdvantedgeService_M.getZoneFromCityData(params).subscribe({
      next: (response: ZoneResponsePayload) => {
        if (response.StatusCode === Constants.statusCode_success) {
          let zoneData = response.Data.zone;
          let zoneMappedWithPincode;
          if (zoneData) {
            if (zoneData === this.hapDataModal.zoneData[0]) {
              zoneMappedWithPincode = zoneRefillingDetails.Zone_1;
            } else if (zoneData === this.hapDataModal.zoneData[1]) {
              zoneMappedWithPincode = zoneRefillingDetails.Zone_2;
            } else {
              zoneMappedWithPincode = zoneRefillingDetails.Zone_3;
            }
          } else {
            zoneMappedWithPincode = '';
          }
          this.setZoneData();
        }
        else {
          this.zoneMappedWithPincode = '';
          this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
          this.popupService.openGenericPopup(this.errorPopupData);
        }
      },
      error: error => {

        this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        this.popupService.openGenericPopup(this.errorPopupData);
      }
    }))
  }

  setZoneData() {
    this.reviseZone = [];
    this.revisedZone = [];
    if (this.zoneMappedWithPincode == zoneRefillingDetails.Zone_1) {
      this.revisedZone.push(zoneRefillingDetails.Zone_2);
      this.revisedZone.push(zoneRefillingDetails.Zone_3);
    }
    else if (this.zoneMappedWithPincode == zoneRefillingDetails.Zone_2) {
      this.reviseZone.push(zoneRefillingDetails.Zone_1);
      this.revisedZone.push(zoneRefillingDetails.Zone_3);
    }
    else if (this.zoneMappedWithPincode == zoneRefillingDetails.Zone_3) {
      this.reviseZone.push(zoneRefillingDetails.Zone_1);
      this.reviseZone.push(zoneRefillingDetails.Zone_2);
    } else {
      this.reviseZone = [];
      this.revisedZone = [];
    }
  }

  getSumInsured() {
    this.subscription.push(this.healthService.getSumInsuredQuickQuote().subscribe({
      next: (response: SumInsuredPayload) => {
        this.sumInsuedDetails = response.Data.SumInsured;
      }
    }))
  }

  getDOBDetails() {
    let dobDetails = [];
    this.quickquoteForm.value.AdultDetails.forEach((item, index) => {
      dobDetails.push({ DOB: this.utilityService.formatDate(item[`dobAdult` + (index + 1)]), MemberType: healthMembers.Adult });
    })
    this.quickquoteForm.value.ChildDetails.forEach((item, index) => {
      dobDetails.push({ DOB: this.utilityService.formatDate(item[`dobChild` + (index + 1)]), MemberType: healthMembers.Child });
    })
    return dobDetails;
  }

  getInstantPremium() {
    let tenure = (this.quickquoteForm.value.policyTenure).split(' ')[0];
    this.tenureDuration = parseInt(tenure);
    let params: QuickquoteRequestPayload = {
      "NoOfAdults": this.adultDetails.value.length,
      "NoOfKids": this.childDetails.value.length,
      "Tenure": this.tenureDuration,
      "SumInsured": this.quickquoteForm.value.applicantAnnualSum,
      "StateCode": this.stateDetails.stateId,
      "CityCode": this.stateDetails.cityId,
      "State": this.stateDetails.stateName,
      "City": this.stateDetails.cityName,
      "Pincode": (this.quickquoteForm.value.pincode).toString(),
      "Zone": this.zoneMappedWithPincode,
      "Members": this.getDOBDetails(),
      "IpAddress": "NYSAHEALTH"
    }
    this.subscription.push(this.healthService.getQuickQuote(params).subscribe({
      next: (response: QuickquotePayload) => {
        if (response.StatusCode == 0 || response.Data == null || response.Data.length === 0) {
          this.showChooseProduct = false;
        } else {
          this.data = response.Data;
          this.getProducts();
          this.showChooseProduct = true;
        }
      }
    }))
  }

  getPremium(event) {
    if (this.quickquoteForm.valid) {
      this.getInstantPremium();
    } else {
      let keys = Object.keys(this.quickquoteForm.getRawValue());
      keys.forEach((ele, ind) => {
        if (this.quickquote.includes(ele)) {
          this.quickquoteForm.get(ele).markAsTouched();
        }
      })
      this.formSubmitted = true;
    }
  }

  onDropdownChange(ev) {
    this.showChooseProduct = false;
  }

  changePolicyTenure(ev) {
    this.showChooseProduct = false;
  }

  adultsDateComparison(ev) {
    this.showChooseProduct = false;
  }

  childsDateComparison(ev) {
    this.showChooseProduct = false;
  }

  productDetails = [];
  agentDetails: AgentMasterStructure;
  //health products json for testing
  getProducts() {
    this.agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);

    const isCHIMapped = this.agentDetails?.MappedProduct?.Health?.isHealthCHIMapped;
    const isBoosterMapped = this.agentDetails?.MappedProduct?.Health?.isHealthBoosterMapped;

    this.productDetails = [
      { productName: 'Health AdvantEdge Plus', premium: null, productCode: 42, routePath: `/${routeEnums.HAP}`, isProductMapped: this.agentDetails?.MappedProduct?.Health?.HealthCHISubProducts?.Visible_HealthAdvantedge_Plus, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/health_adv.svg", show: true },
      { productName: 'Max Protect', premium: null, productCode: 34, routePath: `/${routeEnums.MaxProtect}`, isProductMapped: isCHIMapped && this.agentDetails.MappedProduct.Health.HealthCHISubProducts.Visible_HealthElitePlus, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/max_protect.svg", show: true },
      { productName: 'Health Elite Plus', premium: null, productCode: 33, routePath: `/${routeEnums.HealthElitePlus}`, isProductMapped: isCHIMapped && this.agentDetails.MappedProduct.Health.HealthCHISubProducts.Visible_HealthElitePlus, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/Health_Elite.svg", show: true },
      { productName: 'Golden Shield', premium: null, productCode: 40, routePath: `/${routeEnums.GoldenShield}`, isProductMapped: this.agentDetails.MappedProduct.Health.isGoldenShield, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/Golden_Shield.svg", show: true },
      { productName: 'Health Booster', premium: null, productCode: 20, routePath: `/${routeEnums.HEALTH_BOOSTER}`, isProductMapped: isBoosterMapped && this.agentDetails?.MappedProduct?.Health?.HealthBoosterSubProducts?.Visible_SuperTopup, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/Health_Booster.svg", show: true },
      { productName: 'Arogya Sanjeevani', premium: null, productCode: 22, routePath: `/${routeEnums.ASP}`, isProductMapped: this.agentDetails.MappedProduct.Health.isHealthASPMapped, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/Arogya_sanjeevani.svg", show: true },
      { productName: 'Elevate', productCode: 45, isProductMapped: this.agentDetails?.MappedProduct?.Health?.HealthCHISubProducts?.Visible_HealthAdvantedge_Plus , dealId: this.agentDetails?.MappedProduct?.Health?.HealthAdvantedgeDealID,isOrionProduct: false,imagePath: "../../../assets/images/dashboard/elevate.svg", show: true },
      // { productName: 'Family Shield', premium: '23456', productCode: 23, routePath: `/${routeEnums.Familyshield}`, isProductMapped: this.agentDetails.MappedProduct.Health.isFamilyShield, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/Family_Shield.svg", show: true },
      // { productName: 'Criti Shield', premium: '23456', productCode: 38, routePath: `/${routeEnums.CritiShield}`, isProductMapped: this.agentDetails.MappedProduct.Health.isCritiShieldMapped, isOrionProduct: false, imagePath: "../../../assets/images/dashboard/criti_shield.svg", show: true },
      // { productName: 'Health Shield', productCode: 30, routePath: '', isProductMapped: isCHIMapped && agentDetails.MappedProduct.Health.HealthCHISubProducts.Visible_HealthShield, imagePath: "assets/images/products/13_Health-Shield.png" },
      // { productName: 'Health Shield Plus', productCode: 31, routePath: '', isProductMapped: isCHIMapped && agentDetails.MappedProduct.Health.HealthCHISubProducts.Visible_HealthShieldPlus, imagePath: "assets/images/products/14_Health-Shield-Plus.png" },
      // { productName: 'Health Elite', productCode: 32, routePath: '', isProductMapped: isCHIMapped && agentDetails.MappedProduct.Health.HealthCHISubProducts.Visible_HealthElite, imagePath: "assets/images/health_dash.png" },
      // { productName: 'i-Shield', productCode: 44, routePath: '', isProductMapped: agentDetails.MappedProduct.Health.isIShieldMapped, imagePath: "assets/images/health_dash.png", productDetails:["Life Insurance +Health Insurance cover","Guaranteed cumulative Bonus :20% of SI maximum upto 100% (under health cover)","Life cover : till the age of 85/99 years of age"] },
    ]
    let prodData = this.productDetails;
    this.productDetails = prodData.filter((elem) => this.data.find((id) => elem.productCode == id.ProductCode));

    this.data.forEach(item => {
      if (item.ProductCode !== null && item.TotalPremium !== null) {
        this.totalPremium[item.ProductCode] = item.TotalPremium;
      }
    });

    prodData.forEach(data => {
      if (this.totalPremium[data.productCode] !== undefined) {
        data.premium = this.totalPremium[data.productCode];
      }
    });
  }

  getInsuredMinMaxDate(memberType: string, minMaxType: string) {
    if (memberType == 'Adult') {
      let adultMaxDate = this.utilityService.subtractYears(new Date(), 18);

      let yearsdate = this.utilityService.subtractYears(new Date(), 100);
      let monthdate = this.utilityService.subtractMonths(yearsdate, 11);
      let adultMinDate = this.utilityService.subtractDays(monthdate, 25);

      return minMaxType == 'min' ? adultMinDate : adultMaxDate;
    }
    else {
      let noOfAdults = this.adultDetails.length;
      if (noOfAdults > 0) {
        //depedent child
        let depChildMaxDate = this.utilityService.subtractMonths(new Date(), 3);

        let yearDate = this.utilityService.subtractYears(new Date(), 25);
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let depChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? depChildMinDate : depChildMaxDate;
      }
      else {
        //independent child
        let indChildMaxDate = this.utilityService.subtractYears(new Date(), 6);

        let yearDate = this.utilityService.subtractYears(new Date(), 25)
        let monthDate = this.utilityService.subtractMonths(yearDate, 11);
        let indChildMinDate = this.utilityService.subtractDays(monthDate, 25);

        return minMaxType == 'min' ? indChildMinDate : indChildMaxDate;
      }
    }
  }
}
