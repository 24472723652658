// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private sharedData: any;
  private subProductType = new BehaviorSubject<number>(0);
  subProductType$ = this.subProductType.asObservable();

   // Fetch step data from storage
   private sessionoutflag = new BehaviorSubject<boolean>(false);
   sessionoutflag$ = this.sessionoutflag.asObservable();

   emitSessionFlag(data: boolean) {
    this.sessionoutflag.next(data);
  }

  setData(data: any) {
    this.sharedData = data;
  }

  getData() {
    return this.sharedData;
  }
   //Emit subProductType Data
   emitSubProductType(subProductType: number) {
    this.subProductType.next(subProductType);
  }
}
