import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTooltip } from '@angular/material/tooltip';
import { UtilityService } from 'src/app/shared/services/utility.service';
@Component({
  selector: 'app-custom-card',
  templateUrl: './custom-card.component.html',
  styleUrls: ['./custom-card.component.scss']
})
export class CustomCardComponent {
  @Input() reportList: any = [];
  @Input() buttonData = [];
  @Input() buttonDataPreProposal = [];
  @Input() filterString: string;
  @Input() displayedColumns = [];
  @Output() policyNo = new EventEmitter<any>();
  pageSizeOptions: number[] = [10, 25, 100];
  pageSize: number = this.pageSizeOptions[0];
  pageIndex: number = 0;
  pagedList: any = this.reportList;
  length: number = this.pagedList.length;
  selectedRows: any = [];
  isCopied = false; // Track copy status for tooltip message

  constructor(
    public utilityService: UtilityService, private clipboard: Clipboard
  ) { }

  ngOnChanges() {
    this.pagedList = this.reportList;
    this.length = this.pagedList.length;
    this.pageIndex = 0;
  }

  copyText(text: string, tooltip: MatTooltip) {
    this.clipboard.copy(text);
    this.isCopied = true;

    // Manually trigger tooltip refresh to show the "Text copied successfully!" message
    tooltip.show();

    // Reset tooltip message and hide tooltip after 1 seconds
    setTimeout(() => {
        this.isCopied = false;
        tooltip.hide(); // Hide tooltip after 1 seconds
    }, 1000);
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  emitData(data, identifier) {
    data = { data, identifier };
    this.policyNo.emit(data);
    if (identifier == 'Add To Pay') {
      this.pageSize = this.pageSizeOptions[2];
    }
  }

  banColumns(item: string): boolean {
    const colName = ['Action', 'Select']
    if (colName.includes(item)) {
      return false
    }
    return true;
  }

  evalConditionForMyPolicy(data, condition) {
    if (!this.utilityService.isEmptyOrNull(data) && !this.utilityService.isEmptyOrNull(condition)) {
      try {
        const func = new Function('data', `return (${condition});`);
        return func(data);
      }
      catch {
        return false;
      }
    }
    else {
      return false;
    }
    // return eval(condition);
  }

  evalFunction(list, condition) {
    return eval('(' + condition + ')');
  }

  addSpace(inputString: string) {
    if (inputString == 'PendingForUnderWriter') {
      return 'Pending for Approval with UW1';
    }
    return inputString;
  }

  evalConditionForPreProposal(data) {
    const d1 = new Date(data.startDate);
    d1.setHours(0, 0, 0, 0);

    const d2 = new Date();
    d2.setHours(0, 0, 0, 0);

    if (data.StatusMessage == 'SUCCESS' && d1 >= d2) {
      return true;
    }
    else {
      return false;
    }
  }
}
