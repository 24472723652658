import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { PayInSlipRequestPayload, ChequeStatusRequestPayload, ChequeStatusResponseData, DownloadPayInSlipRequestPayload, PayInSlipsResponseData } from "../interface/pay-in-slips";
import { DownloadService } from "./download.service";
import { Constants } from "../constants/constants";

@Injectable({
    providedIn: 'root'
})

export class PayInSlipService {

    readonly baseURI = `${environment.baseURL}`;
    readonly FETCH_PAY_IN_SPLIPS = 'Policy/FetchMyPayInSlips';
    readonly DOWNLOAD_PAY_IN_SLIPS = 'File/DownloadPayinSlip';
    readonly GET_CHEQUE_STATUS = 'Agent/GetChequepaymentStatus';

    constructor(private generic: GenericService,
        private downloadService: DownloadService) { }

    fetchPayInSlipsData(data: PayInSlipRequestPayload): Observable<PayInSlipsResponseData> {
        const endpoint = `${this.baseURI}${this.FETCH_PAY_IN_SPLIPS}`;
        return this.generic.genericService<PayInSlipsResponseData>(endpoint, data);
    }

    downloadPayInSlip(data: DownloadPayInSlipRequestPayload): void {
        this.downloadService.generatePDF(this.DOWNLOAD_PAY_IN_SLIPS, data).subscribe({
            next: (response) => {
                if (response.StatusCode == Constants.statusCode_success) {
                    let fileName = data.PFPaymentID + '.pdf';
                    this.downloadService.downloadPdf(response.Data, fileName);
                }
            }
        })
    }

    getChequeStatus(data: ChequeStatusRequestPayload): Observable<ChequeStatusResponseData> {
        const endpoint = `${this.baseURI}${this.GET_CHEQUE_STATUS}`;
        return this.generic.genericService<ChequeStatusResponseData>(endpoint, data);
    }
}